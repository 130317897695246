import './bootstrap'
import 'jquery-ui/dist/jquery-ui.min'
import 'jquery-ui/ui/widgets/autocomplete'

import 'leaflet'
import 'leaflet.markercluster'
import 'leaflet-search'
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
window.OpenStreetMapProvider = OpenStreetMapProvider
window.GeoSearchControl = GeoSearchControl

import toastr from 'toastr'
window.toastr = toastr

import Papa from 'papaparse'
import locations from './locations'
window.locations = Papa.parse(locations, { header: true })

$('.custom-file-label').each((_, elem) => {
    const $elem = $(elem)
    $elem.text($elem.data('placeholder'))
})

$('.custom-file-input').on('change', (e) => {
    const $input = $(e.currentTarget)
    const $label = $input.next('.custom-file-label')
    const file = $input[0].files[0]

    $label.text(file ? file.name : $label.data('placeholder'))
})


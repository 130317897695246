export default
    `coordinates,suburb
"148.786320441595,  -36.01193158762528",Adaminaby
"146.91346780765934,  -36.07369799464406",Albury
"148.26802074020546,  -32.91425516102453",Alectown
"150.53677066431447,  -34.40869358826243",Alpine
"145.6572484339588,  -34.91797895821861",Argoon
"143.06916606866113,  -33.61913168767607",Arumpo
"152.45286391858434,  -31.194738034790138",Banda Banda
"149.9949258415723,  -34.40979821062745",Bannaby
"151.17441058810465,  -33.49167114135809",Bar Point
"148.28513083190867,  -34.65555946719572",Beggan Beggan
"151.6574718553271,  -32.74383041105676",Berry Park
"149.26054807608702,  -34.595825664579216",Biala
"151.81823584117245,  -32.02599029985981",Bindera
"146.33941563908178,  -34.14319451569004",Binya
"149.47895438698743,  -32.123633211733726",Birriwa
"150.25279865506275,  -31.629968097103884",Blackville
"152.5298070178508,  -32.35031772642125",Blueys Beach
"148.53618835513836,  -33.10172826955712",Bocobra
"148.38381676009703,  -35.52309275112749",Bogong Peaks Wilderness
"151.73378962817472,  -32.924989170549246",Broadmeadow
"141.2506616351251,  -30.888453538551527",Broughams Gate
"152.65537262615868,  -28.463269657941233",Brumby Plains
"150.0492204914189,  -35.40064066042991",Budawang
"152.30040799286917,  -31.9482694254887",Burrell Creek
"148.32626702789148,  -32.05865837039361",Burroway
"150.91361878452247,  -33.892382987278175",Cabramatta West
"151.18195178060378,  -33.88843164836376",Camperdown (NSW)
"151.67246890247426,  -32.93268150285602",Cardiff Heights
"151.19954853453106,  -33.88634510701653",Chippendale
"150.13469801005644,  -35.9568935659713",Congo
"146.686389396677,  -30.983861872759114",Coolabah
"153.4900935614819,  -28.631976961440365",Coorabell
"153.27390899444364,  -28.989538458677462",Coraki
"150.12237057076393,  -32.732416500521346",Coxs Creek
"150.27952250527701,  -29.2992185013265",Crooble
"151.20374056504943,  -33.82574548134614",Crows Nest (NSW)
"148.23766678064442,  -35.05996098909432",Darbalara
"149.0218244018661,  -32.63882489345969",Dripstone
"150.2788927649789,  -35.58829388511519",East Lynne
"151.13591040154483,  -33.81005326207215",East Ryde
"151.06009105529876,  -33.811524464195834",Ermington
"149.1187313803214,  -32.94367741679681",Euchareena
"149.95902650482842,  -36.16815257499258",Eurobodalla
"153.01251030234752,  -28.566282652112896",Fawcetts Plain
"151.84118744232163,  -32.710108485563936",Ferodale
"149.07822834852095,  -33.46445303729769",Forest Reefs
"149.68122273910686,  -32.468975691543214",Frog Rock
"151.73138504059892,  -32.90928402465485",Georgetown (NSW)
"150.82503672793965,  -34.73898729574478",Gerringong
"153.39946436420405,  -28.23200640744341",Glengarrie
"148.18705446075714,  -35.24593228969645",Gocup
"151.29972501732365,  -33.59080979560419",Great Mackerel Beach
"149.86149563894222,  -36.584517359229025",Greendale (Bega Valley - NSW)
"150.89198227980873,  -33.87463669527929",Greenfield Park
"145.20794337813098,  -30.114740382865858",Gumbalie
"151.75464680849515,  -32.92745427429483",Hamilton East
"144.84274342655166,  -34.384210159694774",Hay (NSW)
"151.6664512127828,  -32.72204973920516",Hinton
"151.40495657990814,  -33.40478315373175",Holgate
"153.0006097995371,  -30.612137976452782",Hyland Park
"147.7509802290201,  -34.827931851279395",Illabo
"151.2577714014135,  -33.69248135799203",Ingleside
"149.91609764917095,  -33.98549117985818",Jaunter
"149.2012411551255,  -35.38332452526625",Jerrabomberra
"152.93035600151543,  -29.637222673143068",Junction Hill
"150.08844069032406,  -33.98864258414838",Kanangra
"150.47822979209718,  -34.73444205842573",Kangaroo Valley
"151.4074981966019,  -32.75461559232418",Keinbah
"150.87274014582775,  -34.409317762381896",Keiraville
"151.16053872396571,  -33.949015777497976",Kyeemagh
"151.09998056262873,  -33.98924954230512",Kyle Bay
"149.15774926570154,  -34.850144137405955",Lade Vale
"150.49570204814475,  -34.18490038750064",Lakesland
"146.9345668039966,  -36.03484194385252",Lavington
"150.43409458390312,  -33.719792867909746",Lawson (NSW)
"150.52784490482776,  -34.87078679283108",Longreach (NSW)
"151.54828683250895,  -32.770784618576634",Louth Park
"151.04515675825263,  -33.982525146735014",Lugarno
"145.5255920704488,  -34.90693670843448",Mabins Well
"151.53408197001377,  -33.30052022281516",Magenta (NSW)
"152.0135716006495,  -32.733413653892896",Mallabula
"149.6781731025525,  -35.26931680269",Manar
"149.88011986702102,  -35.27064433874884",Marlowe
"152.7102330478071,  -31.690078827172684",Middle Brother
"151.21247936504923,  -33.792919777531665",Middle Cove
"150.4695237284255,  -35.327710798492724",Mollymook Beach
"151.14772631172903,  -33.9722491197337",Monterey
"149.32657130464588,  -33.62740940971085",Moorilda
"149.65229457880977,  -36.66660370838765",Morans Crossing
"146.22733276202194,  -35.00848985727202",Morundah
"149.60718036214047,  -35.188280337687075",Mount Fairy
"151.84125422242292,  -29.944069905568167",Mount Mitchell
"149.9110877669745,  -31.15017879050096",Mullaley
"150.73716206536028,  -29.781946498606782",Myall Creek
"150.1261729896461,  -36.310274452598755",Mystery Bay
"150.73221392049626,  -34.04284745393004",Narellan
"153.55005523271495,  -28.520171320949455",New Brighton
"152.6808776560391,  -30.267065867508485",North Dorrigo
"149.35483765023568,  -36.18353039553241",Numeralla
"151.15970007469372,  -32.51455676932201",Obanvale
"150.91640938391626,  -33.72511211655299",Parklea
"150.9783600456632,  -33.16399506990872",Perrys Crossing
"153.161946278665,  -29.701621920032284",Pillar Valley
"148.2085871797523,  -36.67808490809655",Pilot Wilderness
"152.8935956316773,  -31.455196032213383",Port Macquarie
"147.39563412325302,  -34.22813787762949",Reefton (NSW)
"149.7369334277093,  -32.684405297486265",Riverlea
"151.13439656274466,  -32.521350182592315",Rixs Creek
"151.91858557013083,  -32.790296949672395",Salt Ash
"150.75761045258574,  -34.03710957887838",Smeaton Grange
"149.6963434698304,  -33.06318266773595",Sofala
"153.00555749973145,  -29.60260191927027",Southgate
"150.86955141614854,  -34.452571612951395",Spring Hill (Wollongong - NSW)
"151.49360015183274,  -32.824757446389484",Stanford Merthyr
"150.97427696278058,  -34.22021825002846",Stanwell Tops
"153.29256239043292,  -29.077810003734196",Swan Bay (Richmond Valley - NSW)
"151.11168617001786,  -34.01857415985036",Sylvania Waters
"151.73235962297267,  -32.422465022143726",Tabbil Creek
"149.81516910097315,  -34.63206163658116",Tarlo
"147.35866597668243,  -35.159939970024595",Tatton
"151.27500531483022,  -30.1718001008009",The Basin (NSW)
"150.56344148973272,  -34.088679461589734",The Oaks
"153.29458465745626,  -29.665150908673798",The Sandon
"150.7057535366666,  -33.532329879725765",The Slopes
"151.73796496379504,  -32.82081422713358",Tomago
"150.18900632329442,  -35.822070409434474",Tomakin
"142.25376538547047,  -34.231444767692786",Trentham Cliffs
"147.71068323710105,  -32.87114828011187",Trundle
"149.31799461491127,  -34.04320731188584",Tuena
"152.8029344695717,  -28.96657200116845",Upper Mongogarie
"152.3732710400598,  -28.562379541073835",Upper Tooloom
"144.35425402682677,  -35.4652754112381",Wakool
"151.73316845589136,  -31.050531132739145",Walcha
"145.89192785794103,  -33.24076121949219",Wallanthery
"148.97722491278398,  -36.18081525358684",Wambrook
"145.92205665820885,  -34.17061978522085",Warburn
"152.90293017533384,  -29.686648487709952",Waterview
"151.12999049405468,  -33.76425653138966",West Pymble
"152.48085859092723,  -32.31511005617584",Whoota
"150.52141355188277,  -34.635292151003625",Wildes Meadow
"148.03790728868768,  -35.65560257636349",Willigobung
"153.43186897075904,  -28.568740445375067",Wilsons Creek
"152.93983050968063,  -28.847204710340318",Woodview
"151.04157071379743,  -34.02524164690046",Woronora
"147.2614721016967,  -35.993979837886684",Wymah
"147.06420916408484,  -35.38864227820753",Yerong Creek
"149.74455373062747,  -36.31488185018929",Yowrie
"146.0216966455927,  -38.29284055404368",Allambee
"145.30108870241452,  -36.380130006997454",Ardmona
"143.85951947097112,  -36.67519786484371",Arnold (Vic.)
"143.2178729701666,  -36.944447131228536",Barkly (Vic.)
"145.156779459725,  -38.20088757278191",Baxter
"143.62504979352343,  -35.62576774700066",Beauchamp
"144.62169969041594,  -38.13614752957329",Bellarine
"145.15220159060186,  -37.82167039870826",Blackburn
"141.90893296763466,  -37.68744555346991",Bochara
"145.8164558470785,  -36.77231079266263",Boho South
"146.0990959516102,  -36.124141198181704",Boomahnoomoonah
"144.93177313499694,  -37.68318606801113",Broadmeadows (Vic.)
"145.95313765202462,  -38.094852226689454",Buln Buln
"146.01136654077737,  -38.072295419347306",Buln Buln East
"143.5604650233482,  -36.639658217221054",Burkes Flat
"144.26028812117107,  -36.7289156176143",California Gully
"144.27280042536069,  -38.16461473797817",Ceres
"145.1252142306961,  -37.943064287758084",Clayton South
"143.8159988961649,  -37.58088801833034",Delacombe
"147.6884297652787,  -37.566178833860484",Deptford
"145.83589508732774,  -37.173474907267206",Devils River
"145.15669465924947,  -37.66789634742857",Diamond Creek
"144.21256971883787,  -36.3651689345974",Dingee
"147.73422551169995,  -37.308652202633006",Doctors Flat
"143.73113965908112,  -37.222023435464656",Dunach
"147.6766992941311,  -37.854995803279394",East Bairnsdale
"143.25849852048705,  -37.17530527911416",Elmhurst
"145.30245921469853,  -37.33181257969438",Flowerdale (Vic.)
"144.3449409908072,  -38.13932121419326",Geelong West
"143.72356688930756,  -36.48782103068642",Glenalbyn
"142.22400254777682,  -37.24621956111273",Glenisla
"141.48901419536475,  -36.67135755042086",Goroke
"146.03667358967408,  -38.363648944623705",Hallston
"142.3313936474206,  -34.71996166750964",Hattah
"144.71116354774387,  -37.011163548091716",Heathcote South
"146.54228974155225,  -38.654934068407776",Hedley
"146.3135635959333,  -38.22177464456959",Hernes Oak
"145.31124108969465,  -37.20148103761484",Homewood
"146.19337231510647,  -37.242649244714364",Howqua
"143.48504634861652,  -38.43076253303349",Irrewillipe East
"145.0435804202166,  -37.76786383965436",Ivanhoe (Vic.)
"144.2950804176684,  -38.34455545275675",Jan Juc
"144.30299728282233,  -36.7712125093181",Kennington
"146.1693989332379,  -37.360173707341005",Kevington
"141.78283660282756,  -36.35747693947173",Kiata
"145.32051283342344,  -37.47082455329482",Kinglake Central
"145.1117462063706,  -36.13515547460066",Kotupna
"143.53784599957245,  -35.514036190619166",Kunat
"144.466873632058,  -37.93199905504136",Little River (Vic.)
"145.11615081078853,  -37.73885516253976",Lower Plenty
"145.2562521915934,  -38.056007661143816",Lynbrook
"145.95859135094136,  -37.025520573609754",Maindample
"144.887727615,  -37.77273305985366",Maribyrnong
"145.03837287618325,  -37.91063393768596",McKinnon
"146.45350019277078,  -36.602504480680544",Meadow Creek
"147.0748442903947,  -37.4711172154097",Miowera
"146.25031181479196,  -38.1786134161229",Moe
"144.06299398280223,  -38.46315514162929",Moggs Creek
"145.4277006188025,  -37.87388727744234",Monbulk
"145.11075817152363,  -37.8033877257617",Mont Albert North
"145.12510470371294,  -37.7190570447193",Montmorency
"145.17950714974052,  -36.62546073874067",Murchison (Vic.)
"144.40595748777287,  -36.93641045017376",Myrtle Creek (Vic.)
"144.79610093173605,  -36.34475816425173",Nanneella
"143.82758495091505,  -37.564035646517105",Newington (Vic.)
"144.93848084563544,  -37.743164860201375",Pascoe Vale South
"145.14328092228627,  -38.069324429600336",Patterson Lakes
"143.59612402199687,  -37.826292240502355",Pitfield
"142.40157642136194,  -37.938369163597464",Purdeet
"147.9469090784978,  -37.40292036046796",Reedy Flat
"144.529442697726,  -36.134571810157205",Roslynmead
"146.36635168606207,  -37.12192952017787",Sawmill Settlement
"145.25497034208772,  -38.50065216601172",Smiths Beach
"145.28846019032892,  -37.627060706428715",Smiths Gully
"144.95073855326353,  -37.63464825118839",Somerton (Vic.)
"142.62641637209938,  -37.60804618453905",Stavely
"147.1827268508476,  -37.82975580413445",Stockdale
"145.09817629781224,  -37.82671758087048",Surrey Hills
"145.9998076364989,  -36.727024844654935",Swanpool
"145.13156129725922,  -37.12797677622703",Tallarook
"144.05488126010192,  -38.02365578763184",Teesdale (Vic.)
"142.7851491823775,  -38.183034433389025",The Sisters
"142.3578441710506,  -38.32670378888373",Tower Hill
"146.15927349036204,  -38.244050104505696",Trafalgar South
"146.19000643169693,  -36.585195312949566",Upper Lurg
"142.62115681118706,  -36.809857144111",Wal Wal
"147.3940633695155,  -37.79430316673299",Walpa
"143.823638054275,  -35.73717020086761",Wandella (Vic.)
"142.58187849207758,  -38.33120705378814",Wangoom
"145.13049098299152,  -38.01475123981863",Waterways
"144.27852238400027,  -38.198307534172706",Waurn Ponds
"142.96721600150363,  -37.676539219302704",Westmere
"145.18684420036257,  -37.907580205452106",Wheelers Hill
"142.71361957440857,  -37.69726356501353",Wickliffe
"142.22354469818373,  -38.15108977113606",Willatook
"143.98915930906972,  -38.32459315811727",Winchelsea South
"144.99320636439964,  -37.85467542727679",Windsor (Vic.)
"145.26987455196493,  -37.73559191553896",Wonga Park
"142.42024290291815,  -37.800116076814255",Woodhouse
"142.81442606869578,  -37.88125156257071",Woorndoo
"149.48610196659453,  -37.34155641114469",Wroxham
"145.06721813622568,  -36.20647713601387",Wyuna (Vic.)
"143.35436309131185,  -36.3978202744578",Yeungroon
"143.3083680375279,  -38.72595111093449",Yuulong
"149.29740896653533,  -23.286714174543178",Alsace
"152.34838636172665,  -25.602044630865223",Aramara
"148.33948294359314,  -23.989322739503237",Arcturus
"152.36789083556798,  -24.889574617014535",Avenell Heights
"149.15805109119609,  -21.214243581210415",Bakers Creek (Qld)
"151.27858944004106,  -24.790846577938808",Bancroft
"152.95561580390887,  -26.85744170590794",Beerwah
"153.17499139816974,  -27.758205255846214",Belivah
"151.69513911747956,  -26.627990535420988",Benair
"151.7326354971743,  -27.55564079676319",Biddeston
"147.4344148594092,  -27.623846031068243",Bindebango
"145.40543412035652,  -24.585741381951138",Blackall
"152.104268823022,  -26.884677800330437",Blackbutt (Qld)
"151.84089394420138,  -28.094204519281817",Bony Mountain
"152.9678665213451,  -27.292418406181216",Bray Park (Qld)
"153.09402795945957,  -28.138941907706464",Cainbable
"145.9535609587205,  -17.618562549261583",Camp Creek (Qld)
"151.2146239172446,  -24.874905551066142",Cannindah
"153.0948309002898,  -27.472423624557067",Cannon Hill
"149.37051467365086,  -21.864658525980193",Carmila
"152.1525004634777,  -27.586995828728085",Carpendale
"151.5912492773035,  -28.378809785079454",Cement Mills
"151.96248818600546,  -27.58681964418003",Centenary Heights
"149.14166501641975,  -21.258082492707643",Chelona
"153.2672718478522,  -27.532920851731923",Cleveland (Qld)
"146.28149548713915,  -20.04972456776835",Columbia
"152.89966690039537,  -26.53779706254934",Cooloolabin
"152.47236468225063,  -24.969966639325875",Coonarr
"147.6485133524023,  -18.213313374450276",Coral Sea
"148.28113209057847,  -22.7865579563203",Cotherstone
"152.7392250675493,  -27.9624526997269",Coulson
"145.43550224704686,  -16.22342771469558",Cow Bay
"146.77944806035214,  -19.27555898023786",Currajong
"151.15111632766835,  -23.6118269054323",Curtis Island
"151.98926253875274,  -28.66064157529455",Dalcouth
"153.0164352002844,  -27.18266818674182",Deception Bay
"146.66854237059076,  -19.250637019936256",Deeragun
"151.98263335078028,  -25.47774529921728",Degilbo
"152.7267805388968,  -26.049659000179336",Downsfield
"149.67932349911757,  -23.68437451804321",Duaringa
"145.96982749966293,  -17.289597862761727",East Russell
"145.74279282821115,  -16.900688502589954",Edge Hill
"151.7673981122239,  -26.269385624966308",Fairdale
"141.29061045483465,  -18.832501383826912",Fielding
"151.91533799747324,  -28.52774402486582",Fleurbaix
"152.07696510874865,  -27.993724554071342",Forest Springs
"146.00239122973255,  -17.457471175739123",Garradunga
"145.18374873022398,  -16.92200089930695",Glen Russell
"152.76794317575082,  -27.73624793743817",Goolman
"152.45366019235257,  -27.676130879152495",Grandchester
"144.3623194315048,  -16.400041978661328",Groganville
"147.56196500949065,  -19.70154497788917",Groper Creek
"150.09047300360234,  -26.259475689095115",Guluguba
"146.7535535786558,  -19.290335810779222",Heatley
"148.6306031399969,  -26.56024830020463",Hodgson
"147.14080257853573,  -19.564749145439272",Horseshoe Lagoon
"146.79652947887692,  -19.276897074457203",Hyde Park (Qld)
"152.93847131375315,  -27.534470732750947",Jindalee (Qld)
"152.10812895404695,  -26.41412011296729",Johnstown
"151.14374222240374,  -25.06598920500357",Kapaldo
"152.56209495412222,  -26.942193928414326",Kilcoy
"151.65102860382356,  -26.17985484731581",Kinleymore
"141.81057328352713,  -15.319525236581379",Kowanyama
"153.056114245926,  -26.657728997938825",Kuluin
"152.9369414995027,  -26.39151682133277",Lake Macdonald
"150.9872011306573,  -26.693999170055893",Langlands
"152.5817302270168,  -26.29719011022154",Langshaw
"145.96223269130508,  -18.608465586720975",Lannercost
"152.93622349304695,  -28.089732957453467",Laravale
"148.43708063422014,  -23.151161451840967",Lilyvale (Central Highlands - Qld)
"153.1159712732804,  -27.78498173502318",Logan Village
"149.3922865529962,  -23.12798361931109",Mackenzie (Central Highlands - Qld)
"153.43579500365448,  -28.06813846166431",Miami
"151.96690186828852,  -27.607084066172014",Middle Ridge
"150.5780103130604,  -23.499373441323463",Midgee (Qld)
"153.0048761384055,  -27.46819642810233",Milton (Qld)
"153.083066048687,  -26.566528357485996",Mount Coolum
"151.55671584177193,  -25.632755660311965",Mount Debateable
"148.34231428710171,  -25.910192228984943",Mount Hutton (Qld)
"151.82731783811224,  -24.529976392249587",Mount Maria
"152.03784867824922,  -27.20469867927413",Mountain Camp
"146.79326971377967,  -19.33267586681391",Murray
"146.7936169555287,  -19.287120608243463",Mysterton
"153.04394010331092,  -27.44820392393447",Newstead (Qld)
"152.9966236528367,  -27.76705916998788",North Maclean
"151.72468332499648,  -28.02232739101255",Old Talgai
"153.0066596542077,  -27.6200122334125",Pallara
"152.83109851840243,  -25.280389684459216",Pialba
"152.42718760885325,  -24.829843605475887",Qunaba
"151.5363408447227,  -25.52342767558649",Reids Creek
"151.1560124321048,  -23.976521769167743",River Ranch
"150.48250776378617,  -26.719659970855655",Rywung
"142.8241373495919,  -12.043758757116661",Shelburne
"148.78671727871247,  -20.288949780775642",Shute Harbour
"146.6972431930571,  -26.409210149561275",Sommariva
"153.40044867441958,  -27.973674678010383",Southport (Qld)
"151.60703771247148,  -28.791777527124303",Springdale (Qld)
"153.003328269081,  -27.499140112464097",St Lucia
"153.00847870034153,  -27.397048251075777",Stafford Heights
"145.97222599642208,  -17.53670339181942",Stoters Hill
"141.39240427242038,  -19.848884834382662",Taldora
"152.9126454899826,  -28.126700670110633",Tamrookum
"152.0986866279244,  -26.77332509948319",Teelah
"150.22175310306707,  -27.317172275621665",The Gums
"152.53621774172296,  -25.93908947085979",Theebine
"146.72816079298758,  -19.31309369430837",Thuringowa Central
"148.88057043942723,  -26.794801342529258",Tingun
"145.46496005874985,  -17.191676969764597",Tolga
"151.88787593592085,  -27.542348832092127",Torrington (Qld)
"145.55932851190332,  -16.66973309764848",Wangetti
"151.6805771482368,  -26.82096834542343",Wengenville
"152.40793189700443,  -26.22031450031804",Widgee
"148.7263007745941,  -20.470872223664276",Wilson Beach
"152.21044616056244,  -27.60737169789417",Winwill
"149.44616643504537,  -24.13593583298653",Woorabinda
"151.84329221069083,  -27.657209240074607",Wyreema
"152.87175587369558,  -27.34565519660247",Yugar
"137.84494821771838,  -33.81679624406518",Alford
"137.76987325262323,  -35.78036515393326",American River
"138.63994009525538,  -33.61767944695251",Andrews
"137.77685574475802,  -34.23474677704981",Arthurton
"139.8470865816386,  -34.5748320798446",Bakara Well
"139.67784179859106,  -34.04514907585395",Beaumonts
"138.54826253361014,  -33.808192483220466",Benbournie
"139.52881903998576,  -34.31682901358274",Blanchetown
"138.65797198261498,  -35.068861896734475",Cherry Gardens
"138.83364824313037,  -34.627718248708085",Cockatoo Valley
"139.74350513475403,  -35.94737202228243",Colebatch
"138.21652186978739,  -33.60469161498394",Collinsfield
"138.3202246966663,  -33.78101462921129",Condowie
"138.6049409773512,  -35.04035783626623",Craigburn Farm
"137.78988049516462,  -32.471231880866135",Davenport (SA)
"138.65318766548586,  -34.90457198745686",Firle
"139.3067008901832,  -30.798127998815943",Gammon Ranges
"140.64018811986378,  -34.30073375151491",Gurra Gurra
"138.47018001801194,  -31.85646728077798",Hawker (SA)
"140.5338658725136,  -36.72087059970698",Keppoch
"138.78839769327132,  -34.548551281739925",Kingsford (SA)
"140.46352184856607,  -36.37615542172524",Kongal
"139.04338924684447,  -35.36283260429154",Lake Plains
"138.64727739081516,  -34.94358030055015",Linden Park
"138.51572302439246,  -35.05063731847244",Marino
"139.98217751902283,  -34.02535366335443",Markaranka
"140.8755537231908,  -37.77321520172903",Mil-Lel
"138.96142454474955,  -35.3829128244003",Milang
"138.57393310177397,  -34.93694808234224",Mile End South
"136.75758003565377,  -33.47170580856275",Miltalie
"140.1524127431342,  -34.76798702722168",Mindarie (SA)
"139.34062617933878,  -35.172617431504364",Monteith
"136.31682495251258,  -32.33460304111003",Mount Ive
"138.79417130327266,  -30.147757504790807",Mount Serle
"135.07609468639626,  -33.36636033170832",Mount Wedge
"138.39101846031417,  -35.38497617157035",Myponga Beach
"138.60617001372017,  -34.884360967539195",Nailsworth
"138.21728862583194,  -34.01188575505199",Nantawarra
"138.49582096729267,  -34.845344733784785",New Port
"138.64334428649263,  -34.852762247755614",Oakden
"140.88301104396757,  -37.394518188017955",Penola
"134.347850890998,  -32.61083220454228",Perlubie
"138.08038651762067,  -34.11474648565759",Port Arthur (SA)
"136.3173212563511,  -34.06484375873597",Port Neill
"139.30685967161114,  -35.16930324332822",Riverglen
"138.5865128898752,  -33.91342246171723",Spring Gully (SA)
"138.71082202885384,  -34.83073671418664",St Agnes (SA)
"138.73667014606528,  -34.329022115285646",Stockport
"138.5869793061495,  -32.62365229523616",Walloway
"140.3042177462857,  -34.78064877834588",Wanbi
"138.64946774910908,  -33.96507786844707",Watervale
"138.4941528831225,  -33.30644691428368",West Bundaleer
"133.29320612011517,  -31.856700953512867",White Well Corner
"138.91973092392078,  -35.280943257790085",Willyaroo
"116.08803485014484,  -33.32612591952153",Allanson
"117.76681067327752,  -20.69747458513606",Balla Balla
"116.74455751713165,  -31.92947255238973",Balladong
"115.84738154075932,  -32.055538023461715",Bateman
"117.88846956196949,  -35.08461336341593",Big Grove
"116.34328111794245,  -33.35339899130587",Buckingham (WA)
"115.93663644258994,  -32.01613062582402",Cannington
"115.77652929184096,  -32.29463832249698",Cooloongup
"117.48377226452997,  -34.248819229974224",Cranbrook (WA)
"117.26529862462208,  -31.65563564560093",Cunderdin
"116.71710941486884,  -31.87039442365233",Daliak
"116.77090282217877,  -33.23268470540422",Dardadine
"116.51377144339418,  -33.741943439504226",Dinninup
"118.61649166111178,  -30.603827329843504",Elachbutting
"114.2478217863039,  -22.609191913801794",Exmouth Gulf
"115.6614610297071,  -32.58246517169848",Falcon
"118.56352461544847,  -20.30158220968027",Finucane
"115.4894329339394,  -33.567133176402955",Forrest Beach (WA)
"116.96680250008292,  -34.33890120873535",Frankland River
"116.78857156594412,  -20.73786776921808",Gap Ridge
"115.18306513301778,  -25.906275087713247",Gilroyd
"115.81951749315914,  -31.919880440321123",Glendalough
"127.67320264128287,  -18.224474201535184",Halls Creek (WA)
"116.16106535885096,  -33.902348566222976",Hester
"115.87027657247432,  -31.93989916665261",Highgate (WA)
"114.42961644280662,  -28.37780638078548",Horrocks
"114.64442584148155,  -28.536789653999833",Howatharra
"115.7956454602377,  -31.892755732390125",Innaloo
"116.64941599719145,  -31.59802040429092",Irishtown (WA)
"117.4257519729519,  -33.17313728680294",Jaloran
"117.04352253927225,  -33.9809307042787",Jingalup
"118.17160845234358,  -30.632971862719707",Karloning
"117.9076648698237,  -34.933745540833655",King River
"117.76510143901552,  -32.653049694648956",Kirk Rock
"122.10240989203535,  -29.637334427116226",Kookynie
"118.025150297399,  -31.13883851952832",Kwelkan
"128.68580780859244,  -16.48475242391121",Lake Argyle
"121.46160256045145,  -30.73446358138955",Lamington (WA)
"115.94161529400083,  -32.04087142186205",Langford
"117.25876372146737,  -34.00562751457113",Lumeah (WA)
"115.9288585516194,  -32.04013400469782",Lynwood (WA)
"113.67746567385407,  -24.90403332300568",Massey Bay
"118.71175338608552,  -26.4455947090537",Meekatharra
"118.59050373354097,  -34.60226748101978",Mettler
"116.45422752007524,  -30.158908706697073",Miamoon
"116.06976562820141,  -31.298116465547768",Mooliabeenee
"116.32808235501464,  -31.640371204771476",Morangup
"117.51900946381635,  -31.87314800057859",Mount Stirling
"117.93068075988282,  -33.33328535270151",Nairibin
"114.75711583897761,  -28.652599957534612",Narra Tarra
"119.89899153147977,  -22.939218636770207",Newman
"115.79203539150679,  -32.5739214277376",North Yunderup
"115.82332959742651,  -32.23324267123218",Orelia
"115.82779840447797,  -32.246494548573445",Parmelia
"118.30812176927489,  -27.99531355127435",Paynesville (WA)
"115.78430949756566,  -34.32285597533551",Peerabeelup
"115.94607397632636,  -32.00323279463301",Queens Park (WA)
"116.08095001127616,  -32.12399754438028",Roleystone
"116.76382986755321,  -31.406211053479204",Rossmore (WA)
"114.944071796114,  -28.810223912739232",Sandsprings
"118.61294089600332,  -31.580262830886674",South Burracoppin
"115.84061166207422,  -32.10728390105742",South Lake
"114.64569314226095,  -28.75177145388363",Strathalbyn (WA)
"116.68885433420343,  -32.12697239766697",Talbot West
"117.48630073701953,  -31.64153175439226",Tammin
"114.62231560879745,  -28.80987521544054",Tarcoola Beach
"123.260599256156,  -21.20867350846969",Telfer
"117.90305799237832,  -34.97387275923146",Walmsley
"116.05480790222971,  -34.04475189745694",Wandillup
"121.84439478761183,  -33.880704194301146",West Beach (WA)
"115.26171013277205,  -28.860292686348846",West Casuarinas
"116.4435006285787,  -31.868004283830604",Woottating
"115.73813617162432,  -31.525452523064693",Yanchep
"116.23944175641529,  -31.106340304517268",Yarawindah
"115.77501720068234,  -30.832027851402092",Yathroo
"146.27857148151804,  -41.3321505611193",Barrington (Tas.)
"147.95073210192558,  -40.04068310399114",Blue Rocks
"147.7477728297121,  -41.174670785112994",Branxholm
"147.23389915975787,  -42.80754590811694",Chigwell
"144.9821773782691,  -40.963905845272755",Christmas Hills (Tas.)
"147.1836017546678,  -41.55083424101284",Devon Hills
"147.1504735560532,  -41.439331228099995",East Launceston
"145.41336407079854,  -40.86744058797346",Edgcumbe Beach
"144.01572693258643,  -39.66000575731595",Egg Lagoon
"146.2373004377033,  -41.2030416577422",Forth
"146.9010390311122,  -41.09103564698387",George Town
"147.36643999746653,  -42.78364029128399",Grasstree Hill
"147.2261776263292,  -41.16413466598356",Lebrina
"147.2345100479077,  -41.246219592226765",Lilydale (Tas.)
"143.9318385272001,  -39.859433831717816",Loorana
"146.41052835014528,  -41.467786692706696",Lower Beulah
"146.91705768694035,  -42.71251653630743",Macquarie Plains
"146.28784803024635,  -41.5667554372247",Mayberry
"146.69017441599038,  -41.9826048931183",Miena
"146.58869824717976,  -41.593458680784714",Montana
"147.29540668295462,  -42.84560118547285",Moonah
"147.961229099502,  -42.32672863554028",Pontypool
"147.43755301209404,  -42.895314182977096",Rokeby (Tas.)
"144.06570553608725,  -39.82814170697198",Sea Elephant
"147.54230923114423,  -42.84087009328055",Seven Mile Beach
"145.91358775523594,  -41.06657348672058",South Burnie
"148.15583637176562,  -41.58696206986268",St Marys (Tas.)
"148.1087971899183,  -40.223384996180364",Strzelecki (Tas.)
"146.3700884502829,  -41.24067642588839",Tarleton
"146.1333453874964,  -41.33396819475694",Upper Castra
"147.942488763167,  -41.20385909962262",Weldborough
"146.89890663893428,  -42.04324420236436",Wilburville
"130.89052147267805,  -12.391225910826828",Anula
"133.9092154632284,  -23.802843390463394",Connellan
"131.16886773189202,  -13.021188834956046",Coomalie Creek
"131.09567649516188,  -12.525259875997351",Girraween (NT)
"133.86477867827946,  -23.646883138145526",Irlpme
"131.82606903163187,  -23.895058817111135",Mereenie
"131.06736824358805,  -25.35068166862416",Mutitjulu
"135.72651857530533,  -14.273848510276116",Numbulwar
"134.05566541263448,  -17.60824014049335",Pamayu
"131.80145991363005,  -16.548517138155876",Top Springs
"130.88812793798542,  -12.37119295729985",Wanguri
"133.7264944983752,  -21.744279883048193",Wilora
"149.05984283325742,  -35.29217335204428",ACT Remainder - Molonglo Valley
"149.0669176157619,  -35.17020400594674",Hall
"149.01189230412305,  -35.2244121849425",Holt
"149.10913451618865,  -35.22779187815025",Kaleen
"149.07698297396763,  -35.21753611472177",McKellar
"149.0908974654753,  -35.34697036582135",Phillip
"148.0698001995756,  -35.30656130840299",Adelong
"147.54143425897382,  -35.153200660317786",Alfredtown
"153.35621335797546,  -29.472950274915295",Angourie
"149.65737359233714,  -34.04066732679613",Arkstone
"150.4963959077288,  -34.42253235769806",Aylmerton
"151.29913081907628,  -30.62032204032927",Balala
"151.12225022075313,  -31.96970785472217",Belltrees
"151.65343456289628,  -33.07471147107355",Blacksmiths
"150.18468353095008,  -28.70270944987564",Boggabilla
"150.88908790632337,  -33.893554034178415",Bonnyrigg
"152.65224029980152,  -28.788316496135298",Bottle Creek
"151.8182843301376,  -31.930540988337246",Bowman
"150.26390206854262,  -35.49007905583847",Brooman
"146.7732310286285,  -36.00339116040563",Bungowannah
"149.63644828747925,  -32.63217450358199",Burrundulla
"151.22298874369088,  -33.41577366912116",Calga
"150.71964515644507,  -34.99070538242738",Callala Bay
"149.93283296443087,  -36.69788151116051",Chinnock
"149.11655682448244,  -33.18308810063914",Clergate
"145.79279055547252,  -31.482895896209723",Cobar
"153.33217208368603,  -28.498910381190274",Commissioners Creek
"151.25453187733635,  -33.919597895615176",Coogee (NSW)
"148.3448550871749,  -30.91901147498354",Coonamble
"148.022806388174,  -34.65201133930137",Cootamundra
"151.43583272105053,  -33.4868679543289",Copacabana
"152.98149401718112,  -28.35630743399424",Cougal
"148.51157683289898,  -36.440047685121534",Crackenback
"153.51489276275612,  -28.38907694136858",Cudgera Creek
"150.00994030610653,  -33.28023087353461",Cullen Bullen
"146.46603810185402,  -35.25148512410559",Cullivel
"147.6148961905394,  -29.644625159086985",Cumborah
"152.4960316018487,  -32.102760014721696",Darawank
"145.86629625875315,  -34.595606993086434",Darlington Point
"150.71238676272515,  -34.18817465951535",Douglas Park
"147.35351668419162,  -34.9843860804654",Downside
"153.35925121730344,  -28.2733601733812",Dungay
"153.58759822113464,  -28.855179790908334",East Ballina
"151.08398126717833,  -33.78878445573061",Eastwood (NSW)
"149.55150990974678,  -33.36461735999736",Eglinton (NSW)
"149.19584466983014,  -33.30206988675151",Emu Swamp
"149.37562477252968,  -33.49304897798118",Fitzgeralds Mount
"141.67865429450075,  -31.36459252644224",Fowlers Gap
"151.4927905189597,  -32.987923850173274",Freemans Waterhole
"148.09153186050006,  -31.900836163768464",Gin Gin (NSW)
"151.64829736396945,  -32.927942816474044",Glendale (NSW)
"148.62993264810524,  -35.82961025109194",Gooandra
"150.73037037723603,  -34.91072294431858",Greenwell Point
"150.83822274996095,  -33.73117608773946",Hassall Grove
"150.47035734248303,  -34.858945713013604",Illaroo
"152.39635616899707,  -31.591491919771986",Innes View
"149.58330314374828,  -35.68301383613226",Jerrabattgulla
"153.02763673112838,  -30.922952003229216",Jerseyville
"147.6938025304352,  -35.90492645682971",Jingellic
"152.69931067125748,  -31.72829434758132",Johns River
"147.27760329425234,  -35.15309483114944",Kapooka
"152.43382732731604,  -31.392190735085784",Kindee
"149.68706413436675,  -34.66963692062859",Kingsdale
"144.9154633849203,  -32.33167852410628",Kulwin (NSW)
"149.56288834668902,  -34.34920283395112",Laggan
"150.8670050092664,  -34.4842978326821",Lake Heights
"151.64978537904443,  -32.961922769736915",Lakelands (NSW)
"149.76894254134618,  -35.32180930551852",Larbert
"150.95633475058793,  -32.48231849512631",Lemington
"152.02962233109605,  -32.73213035666919",Lemon Tree Passage
"152.12011951073617,  -28.634420947141145",Liston
"150.15713211932157,  -33.482787858696895",Lithgow
"151.4865155308448,  -33.3597887073591",Long Jetty
"153.21474928219115,  -29.448594269834484",Maclean
"151.64465080269213,  -32.951115762821146",Macquarie Hills
"151.09031111825155,  -32.53778115151263",Maison Dieu
"148.64849341945757,  -33.212502502222506",Manildra
"151.20303450232532,  -33.84481551246378",McMahons Point
"149.35779680313658,  -32.335499967674835",Mebul
"150.98669815398992,  -33.836109680486935",Merrylands
"146.92806019150868,  -32.40056147211267",Miamley
"149.1914292937694,  -33.45282129498484",Millthorpe
"151.372285986679,  -32.37830859522455",Mirannie
"150.7968084200342,  -32.07214377778362",Moobi
"152.2479050051016,  -29.452751322692787",Moogem
"148.69854958610702,  -33.5611744968651",Moorbel
"150.93774787546002,  -33.94315460147234",Moorebank
"152.6487930985566,  -31.77711529434",Moorland (NSW)
"150.3949845218597,  -34.55027793844244",Moss Vale
"153.19408572050946,  -28.5004849841049",Mount Burrell
"151.3858810840051,  -33.40499125713902",Mount Elliot (NSW)
"145.9089032903319,  -32.76322323169496",Mount Hope (NSW)
"149.55258886317768,  -33.45115718516877",Mount Panorama
"151.27312619575844,  -33.74939971522893",Narraweena
"151.72505418576606,  -32.72388005092486",Nelsons Plains
"149.7772680289815,  -35.755788701893586",Neringla
"150.33295812461418,  -34.508064943929135",New Berrima
"144.30791018545253,  -35.23777266795343",Niemur
"153.28349647886725,  -28.78623753234905",North Lismore
"153.34679783311745,  -29.06018976664437",North Woodburn
"149.07251312426294,  -33.253363561829474",Orange
"142.3904410557843,  -34.2918313324211",Paringi
"149.43084790822718,  -34.11224963192648",Peelwood
"153.4987809698666,  -28.892355759861477",Pimlico (NSW)
"146.78571847987783,  -35.47161331384456",Pleasant Hills
"148.7403787373182,  -31.10236363503323",Quanda
"147.32371339408857,  -34.37218465823263",Quandary
"149.65311299955354,  -33.42198262375702",Raglan (NSW)
"150.53097065080064,  -34.77129244187445",Red Rocks
"151.46965280322766,  -33.29337466471996",Rocky Point (NSW)
"151.45901485908527,  -30.597067554614274",Rocky River (Uralla - NSW)
"153.3956583974814,  -28.668860277034955",Rosebank
"150.87644481139102,  -33.49433556839203",Sackville
"151.5210345988438,  -33.21464243034171",San Remo (NSW)
"148.0365717209989,  -35.294471699065966",Sandy Gully (NSW)
"146.0177205654047,  -35.744031451211185",Savernake
"151.44670534220378,  -32.77707267587791",Sawyers Gully
"152.99074062937842,  -30.711697141350342",Scotts Head
"149.19706981461633,  -33.38999406180095",Shadforth (NSW)
"150.86352910421923,  -34.57706235907056",Shellharbour
"153.17064393458685,  -29.520547180114274",South Arm (Clarence Valley - NSW)
"153.54764392970068,  -28.498772394825323",South Golden Beach
"151.34636343991986,  -33.49581637870912",St Huberts Island
"151.4087694772664,  -32.58326298247553",Stanhope (NSW)
"149.36919195759327,  -36.57900030624915",Steeple Flat
"149.7620356588331,  -34.37454485462518",Stonequarry
"149.15382384256097,  -33.20668947129847",Summer Hill Creek
"150.2627692913527,  -34.574989806302156",Sutton Forest
"151.46626729857593,  -29.77337341739532",Swan Vale
"153.5551924857393,  -28.673540872905214",Talofa
"148.6652692321925,  -35.714833274107896",Tantangara
"151.49565890232708,  -33.34493543951651",The Entrance
"149.93314028353421,  -32.450032054986345",Tichular
"149.72093046571652,  -37.303558744673566",Timbillica
"150.6884376763006,  -28.705183119743307",Twin Rivers
"149.38385191679967,  -32.391274971900465",Two Mile Flat
"150.46115752613628,  -35.35715792436959",Ulladulla
"153.30517470702878,  -28.27014205788911",Upper Crystal Creek
"150.5931151464384,  -34.693276622594915",Upper Kangaroo River
"149.7765543005975,  -33.13006960284334",Upper Turon
"148.73061090290145,  -30.694420316213943",Urawilkie
"152.50713269974565,  -28.459358958630848",Urbenville
"149.28883573178032,  -35.55995954800353",Urila
"151.64292554600945,  -33.014969598823185",Valentine
"151.46217023310146,  -33.22537827374834",Wallarah
"149.97331273839504,  -36.57930471288057",Wapengo
"151.1321436087926,  -33.85774158299832",Wareemba
"150.59937603951516,  -33.89066479279658",Warragamba
"150.61342213666353,  -33.72196201159651",Warrimoo
"151.73502883153307,  -29.414620581065115",Wellington Vale
"151.7178823490112,  -32.97265658377967",Whitebridge
"149.85713788581293,  -30.84674839790678",Willala
"150.8806855559351,  -33.7563560076501",Woodcroft (NSW)
"153.31559453526262,  -29.488369888318115",Wooloweyah
"147.27710886505741,  -35.85775956715044",Woomargama
"151.04501659582772,  -33.30135045350193",Wrights Creek (NSW)
"149.67251926027478,  -33.36459323857333",Yarras (Bathurst Regional - NSW)
"144.78443236431673,  -37.88093885492225",Altona Meadows
"143.86424949522643,  -35.93068087761662",Appin South
"143.85020401740073,  -37.562286777724786",Ballarat Central
"144.31118592965439,  -37.45541441171086",Barrys Reef
"144.27454151613776,  -38.08716937087697",Batesford
"145.0388665711282,  -37.98218051252433",Beaumaris (Vic.)
"144.23630325470958,  -38.33768185145084",Bellbrae
"145.28361107344818,  -37.697874262305106",Bend Of Islands
"148.2961411214218,  -37.599529483729526",Bete Bolong North
"143.78069943576938,  -37.43625191409227",Blowhard
"144.72004307448938,  -36.41206198275773",Bonn
"146.24390215581607,  -36.098819243530414",Boorhaman North
"148.05633161348092,  -37.0092949830495",Brumby
"144.96128905576504,  -37.766345432176486",Brunswick (Vic.)
"144.94296001964457,  -37.76331194945433",Brunswick West
"143.16750833234656,  -37.36444361826473",Buangor
"146.6570731629673,  -36.67191653050004",Buffalo River
"147.0175441994358,  -38.02803342797596",Bundalaguah
"144.837406833749,  -36.53074464864962",Burramboot
"144.93872142750476,  -37.354241168219055",Bylands
"147.51007644526544,  -37.78158326250614",Calulu
"145.3440384196386,  -38.54273358702344",Cape Woolamai
"144.89152504638784,  -36.462890558969654",Carag Carag
"146.61614716467588,  -36.19942669599864",Chiltern
"144.621556401991,  -37.9686893162762",Cocoroc
"144.98812752420278,  -37.80207169030892",Collingwood (Vic.)
"143.48890462776643,  -38.28244630527335",Corunnun
"143.12098340087735,  -35.708462826266455",Culgoa
"144.8697628750377,  -37.42252955384283",Darraweit Guim
"146.68726476718035,  -37.97389681422191",Dawson (Vic.)
"145.9337484420495,  -38.252402921744554",Ellinbank
"145.0070094521705,  -37.88582680732716",Elsternwick
"145.14180241354134,  -37.69789628515518",Eltham North
"145.00907690491022,  -37.63892454773534",Epping (Vic.)
"144.30863584856755,  -36.7025173337702",Epsom (Vic.)
"145.56185258673588,  -36.76863428399425",Euroa
"145.9405083755642,  -38.30727408250464",Ferndale (Vic.)
"145.66980381083658,  -38.10035701927678",Garfield
"145.5261986773101,  -38.45104258980041",Glen Forbes
"145.16822180318732,  -37.88158434821744",Glen Waverley
"144.09935710758182,  -37.030536987331615",Gower
"143.61658153289503,  -35.960126446073225",Gredgwin
"141.39866878322644,  -37.99835541664855",Greenwald
"143.63480024768072,  -37.61583040273791",Hillcrest (Vic.)
"145.18557515078578,  -37.50193375546463",Humevale
"145.72433059280038,  -38.40543149432097",Jeetho
"145.29049283221184,  -38.135234005151894",Junction Village
"144.24619735763162,  -35.855831296396666",Keely
"144.01403297909522,  -35.779836956907666",Kerang East
"145.43186784048424,  -37.18119612863733",Killingworth (Vic.)
"142.37271510335,  -38.27363402657322",Koroit
"144.1387254984058,  -37.442642998597734",Korweinguboora
"144.5247114230494,  -36.19158519325519",Kotta
"144.88102672041038,  -36.23180751888828",Koyuga
"143.80559588623018,  -36.537885149205856",Kurting
"144.50116295896748,  -37.15005229827962",Langley
"146.592814071165,  -37.602758352263365",Licola
"145.26704127737207,  -37.95248932320949",Lysterfield South
"144.55585381382346,  -37.42850941314063",Macedon
"146.14673572059323,  -37.205625968684146",Macs Cove
"144.9666130387005,  -38.400295896883755",Main Ridge
"148.79951140677696,  -37.69236598858869",Manorina
"142.779432259401,  -36.71155383819474",Marnoo West
"145.70962361191076,  -36.97801197442732",Merton
"143.30319785453085,  -36.98664076045562",Moonambel
"146.05627548734356,  -36.734116123272756",Moorngag
"142.90476467812792,  -36.07792260945732",Morton Plains
"144.6247228637375,  -37.787687872126185",Mount Cottrell
"145.97283748400253,  -38.38081771494317",Mount Eccles
"143.65834349690758,  -36.91415672454031",Mount Hooghly
"142.75566449730033,  -35.53649964924221",Myall (Buloke - Vic.)
"141.04620098763525,  -36.7029314216711",Neuarpurr
"147.687869622104,  -37.926395856055095",Newlands Arm
"148.45139369016738,  -37.75232039185068",Newmerella
"144.84012567887834,  -37.59162375211775",Oaklands Junction
"147.6876175117064,  -37.99787469393181",Ocean Grange
"146.90915540144027,  -36.32056007020796",Osbornes Flat
"144.95122655318676,  -37.78666746655697",Parkville (Vic.)
"141.6823582130303,  -37.28824240967997",Pigeon Ponds
"144.40043278319973,  -36.209369043000095",Pine Grove (Vic.)
"144.6429236157513,  -38.26729560412501",Queenscliff (Vic.)
"142.9077600026564,  -36.44497500680612",Rich Avon
"142.81485492134348,  -36.50478406321158",Rich Avon West
"146.7351992005589,  -38.66099893190136",Robertsons Beach
"144.18170961142548,  -38.00425090126537",Russells Bridge
"145.46265263440387,  -38.13954604867074",Rythdale
"143.98280466274716,  -36.42486931120817",Serpentine (Vic.)
"145.3594053322069,  -37.88853635731981",Sherbrooke
"144.971296353354,  -37.85804001289622",St Kilda West
"141.71892781858568,  -37.73971840507592",Tahara
"145.44006219769022,  -36.231326793435386",Tallygaroopna
"146.1676609051478,  -36.392019100906246",Taminick
"145.5784263586861,  -36.52631884914112",Tamleugh North
"146.26783169869523,  -38.10321195110619",Tanjil South
"146.47548627080826,  -36.38296972291777",Tarrawingee
"147.199880723682,  -36.684623762465314",Tawonga
"145.88016706104187,  -37.2078915356208",Taylor Bay
"145.40219854917353,  -37.89187763670889",The Patch
"142.9759847775949,  -38.48917902357842",Timboon
"146.8308546668596,  -37.924766812125185",Tinamba West
"144.67412964898486,  -36.72084053618081",Toolleen
"147.05344418465614,  -37.61644056052311",Toolome
"142.23777943299308,  -38.31532312089996",Toolong
"144.9353814038623,  -37.780643544005216",Travancore
"143.39854010188932,  -35.2123365037734",Vinifera
"146.22127168960114,  -38.14184116994581",Westbury (Vic.)
"144.26871026748492,  -36.650339336615914",Whipstick
"144.39206008447619,  -38.177096906212945",Whittington
"141.5858871819696,  -37.01821831529867",Wombelano
"142.19015742508603,  -36.88549673976173",Wonwondah
"144.00461965364747,  -36.7649244466174",Woodstock On Loddon
"146.74278790494085,  -36.28941153001977",Wooragee
"143.61752403060197,  -36.26686639730576",Wychitella
"145.09075756691914,  -36.24001941374355",Wyuna East
"145.7719881642828,  -36.14715927654543",Youarang
"149.76715536557464,  -24.303339548414222",Alberta
"152.91512584712453,  -27.91383064339448",Allenview
"152.39615316145978,  -24.872744089472096",Ashfield (Qld)
"146.41092076576405,  -19.03986418153409",Balgal Beach
"144.26338411890583,  -16.63905862347772",Bellevue (Qld)
"152.0659363067737,  -28.051362674991623",Berat
"151.68474559419997,  -25.057341258994263",Boolboonda
"151.32359720390147,  -23.934745311914515",Boyne Island
"151.29057139885853,  -24.23146291819292",Boynedale
"152.94201510263727,  -26.53743370003965",Bridges
"153.1121714891094,  -27.390347243821335",Brisbane Airport
"152.33788497772053,  -24.872341445256616",Bundaberg West
"150.3721454383105,  -25.004858475144623",Camboon (Qld)
"146.0075770593235,  -18.275313088296492",Cardwell
"153.01592827803617,  -27.34624411471865",Carseldine
"152.77195352420844,  -26.452462758646323",Carters Ridge
"152.7887577498119,  -26.21044900421683",Cedar Pocket
"149.30864446350205,  -22.71759681606241",Clarke Creek
"146.43574511021438,  -19.116986697576355",Clemant
"151.2279912488288,  -27.971755804736496",Clontarf (Toowoomba - Qld)
"146.06430184723945,  -17.500709422204533",Coconuts
"146.03688401021788,  -17.563807322165424",Comoon Loop
"149.18888122126518,  -21.132587260454745",Cremorne (Qld)
"150.49860631801505,  -26.88703957385063",Crossroads
"153.46278255583272,  -28.154130549074885",Currumbin Waters
"151.88022329279815,  -25.48025881156663",Didcot
"145.81868281396632,  -17.92174752728767",Dingo Pocket
"151.53607206169423,  -25.600848722138238",Dirnbir
"151.9110467939212,  -27.17979083455329",Djuan
"149.9243723039953,  -26.633035957188174",Drillham
"153.4074766010421,  -27.49698447792004",Dunwich
"142.44074200441372,  -14.98265658894703",Edward River
"151.05018107474348,  -25.368108886657957",Eidsvold
"152.21411623841178,  -27.1434535727316",Eskdale (Qld)
"148.4819777736344,  -21.124015652846108",Eungella (Qld)
"145.49390184532703,  -17.514061831230297",Evelyn
"152.10571289123146,  -27.72496204413825",Fordsdale
"152.52822292452043,  -27.938932321970242",Frazerview
"149.9089670385375,  -23.845067068069465",Gainsford
"152.01509566745318,  -26.61699876712028",Glan Devon
"151.4470379152634,  -28.908986918517975",Glenlyon (Qld)
"152.2106987530245,  -27.996551243333045",Goomburra
"148.65146263410847,  -20.482539654111005",Goorganga Plains
"151.96082296746997,  -27.34121159869758",Groomsville
"142.7752078305729,  -9.901188913764154",Iama Island
"152.93488896702254,  -26.6026524490993",Image Flat
"152.3853363701478,  -24.844234837166866",Kalkie
"152.6250428300345,  -26.398821794647812",Kandanga Creek
"152.93794248755773,  -27.516092495255755",Kenmore
"152.37592688688318,  -24.87546235548976",Kepnock
"151.73821557605044,  -26.2151401605989",Keysland
"152.82078775925262,  -27.170166293561735",King Scrub
"152.44840440532954,  -25.025527030142452",Kinkuna
"153.08337644575354,  -27.2242230954618",Kippa-Ring
"146.0841709936001,  -17.755493890508685",Kurrimine Beach
"148.66696037090801,  -20.623682730402003",Laguna Quays
"145.64951538727675,  -17.249483323851994",Lake Barrine
"152.990804264584,  -26.806832295935443",Landsborough (Qld)
"151.66539476972324,  -27.61234430801807",Linthorpe
"153.23780062054593,  -28.05106013017437",Lower Beechmont
"151.70718656755722,  -24.61364404216011",Lowmead
"153.1042194493754,  -27.53537948851558",Mansfield (Qld)
"150.61818442738948,  -22.979742918605748",Maryvale (Livingstone - Qld)
"153.06972262183655,  -27.027119060963713",Meldale
"145.9600026024596,  -17.895484733152685",Midgenoo
"152.54674723278924,  -27.548446124264014",Minden
"150.0681244075517,  -23.197216390459996",Morinish
"148.5034093007111,  -26.796289579800042",Mount Abundance
"152.9324906758463,  -26.816512629480922",Mount Mellum
"143.99085268291563,  -16.273827580015695",Mount Mulgrave
"152.48073418809852,  -27.458174414054067",Mount Tarampa
"153.20536396692282,  -27.731058555416837",Mount Warren Park
"152.37221040277095,  -27.724079498659755",Mulgowie
"151.94571788445376,  -26.23801847664038",Murgon
"145.80590755043377,  -18.129172031562838",Murray Upper
"151.865491666851,  -26.84985482899592",Neumgna
"151.93257922882725,  -27.555991723537737",Newtown (Toowoomba - Qld)
"152.81999753389255,  -25.32467426466975",Nikenbah
"149.5279567299326,  -28.178356376932406",North Bungunya
"152.26431847163994,  -25.17698496242621",North Isis
"152.7894591915403,  -27.57950798159327",North Tivoli
"146.80830232221476,  -19.24885609742253",North Ward
"153.0915081795804,  -27.369119070880586",Nudgee
"151.3382775722638,  -28.42700297888701",Oman Ama
"146.8160723577807,  -19.292591028931465",Oonoonba
"150.73557152802445,  -23.098446008288295",Pacific Heights
"153.31436135582015,  -27.94011976346409",Pacific Pines
"145.7639539259888,  -16.922932001913203",Parramatta Park
"152.71692942563857,  -27.536011384142927",Pine Mountain (Qld)
"152.5677995123101,  -25.665379859307166",Pioneers Rest
"153.07547762953453,  -25.902961590221324",Rainbow Beach
"150.64500876773585,  -26.64399222692253",Red Hill (Western Downs - Qld)
"153.39636871942537,  -28.112462942140322",Reedy Creek (Qld)
"152.70762191803206,  -27.919314528996622",Roadvale
"151.61648523393274,  -24.108355736505473",Rodds Bay
"152.98170567605789,  -27.530842252857383",Sherwood (Qld)
"152.00829803648702,  -28.354423220912075",Silverwood
"149.21220070458864,  -21.08103830679994",Slade Point
"145.6898026261371,  -16.82486171843909",Smithfield (Qld)
"151.0773408411566,  -28.796727143601554",Smithlea
"153.3920100486829,  -27.770946934585773",Southern Moreton Bay Islands
"152.91789691706396,  -27.68540866674893",Springfield Lakes
"146.86001029231366,  -19.32168366148336",Stuart (Qld)
"149.06335457089745,  -27.1539854408411",Surat
"151.88451431435303,  -28.038612943893103",Talgai
"152.55417151197443,  -27.495958180385266",Tarampa
"152.64691033275665,  -27.96943971231024",Templin
"150.46392294988945,  -23.175538570536958",The Caves
"151.92291364760376,  -28.34284263825959",The Glen (Qld)
"147.62098587588545,  -23.077275907368925",Theresa Creek (Qld)
"146.25057798386175,  -20.09036533003911",Towers Hill
"152.87492787380484,  -25.6834406821714",Tuan
"145.92285526413602,  -17.941227699589437",Tully
"152.63177909936672,  -26.16954428141897",Two Mile
"150.7691925044245,  -24.418992072016565",Valentine Plains
"152.74608870335743,  -25.50717815398495",Walkers Point
"152.86815722024573,  -27.029802499997807",Wamuran
"152.0327892758876,  -28.214356437828357",Warwick (Qld)
"151.32475298655925,  -29.11086147846932",Watsons Crossing
"151.96238379339442,  -26.457232323839076",Wattle Camp
"149.0988149733484,  -28.360278366148897",Weengallon
"151.65749197104128,  -26.353067992340776",Wilkesdale
"153.00686521110143,  -27.59665198327464",Willawong
"139.49771867483523,  -20.709910610637845",Winston
"152.8253867283734,  -26.150280730956588",Wolvi
"151.57853162873462,  -25.679065849600768",Woodmillar
"151.91935074525057,  -27.45023535325363",Woolmer
"152.7702714267859,  -27.872729799373104",Woolooman
"152.80577269746667,  -26.786053730378608",Wootha
"148.60265343120125,  -27.50325804120711",Wycombe
"150.34856445056022,  -23.127401435643584",Yaamba
"152.74056076743892,  -27.652695553092315",Yamanto
"145.89716111780186,  -16.99455246253799",Yarrabah
"138.73989608422812,  -35.016938804425",Aldgate
"139.08520503780085,  -33.69162544826644",Baldina
"138.1600512965158,  -32.949933100638674",Bangor (SA)
"138.62098018045845,  -33.745514105551585",Barinia
"139.29320726854738,  -34.06677416346745",Bower
"135.0282846695077,  -33.65055931729145",Bramfield
"135.8538106570958,  -34.04893137024618",Brooker
"138.54472700867055,  -34.93007399728734",Brooklyn Park
"138.5652611760609,  -33.74642729654471",Bungaree (SA)
"138.78620242123216,  -33.17665050083918",Canowie Belt
"140.29045767940718,  -37.70509347653044",Canunda
"139.48189682573238,  -35.98736802693131",Coorong
"138.55684191395034,  -34.932525765652365",Cowandilla
"138.21006836441117,  -35.57793650523374",Delamere (SA)
"139.3950139187373,  -34.8661986157836",Five Miles
"138.76433503222296,  -34.60266002054639",Gawler East
"138.49079008923349,  -34.844175827250496",Glanville
"140.9080464888252,  -37.8277547113526",Glenburnie
"138.6337250830402,  -34.94272781041217",Glenside
"138.8087061838763,  -35.4847063077194",Goolwa North
"138.26765562749628,  -34.082781212754604",Goyder
"138.5757699590816,  -35.07656326754678",Happy Valley (SA)
"138.47279275796498,  -33.75712253143844",Hart (SA)
"138.71705959513648,  -35.02839257706181",Heathfield
"140.9021765706828,  -36.95413671205337",Hynam
"138.669733567042,  -34.97133173341461",Leawood Gardens
"138.37975941050766,  -30.590429960648958",Leigh Creek (SA)
"136.17021948571687,  -34.24828193874824",Lipson
"139.51570998093004,  -35.510589938682685",Malinong
"138.87827505034312,  -34.14021326753868",Marrabel
"138.19232187529263,  -32.81156052681185",Melrose (SA)
"138.5787465092714,  -34.98524965124629",Melrose Park (SA)
"139.12620110131473,  -35.17276132480819",Monarto South
"139.02949722831502,  -33.44040118152293",Mount Bryan East
"138.59770697197663,  -35.36152928919883",Mount Compass
"140.78035997719172,  -37.828422286592264",Mount Gambier
"138.68964904902282,  -34.667494457279595",Munno Para
"138.24440249399782,  -32.918621367246274",Murray Town
"138.47306034598174,  -35.40403153987158",Myponga
"138.4961623995703,  -35.14087314451179",Noarlunga Centre
"131.80839388920873,  -30.960780008896275",Nullarbor
"138.55425179040267,  -35.055874510891336","[""O'Halloran Hill""]"
"138.47629003594548,  -35.12110134834328","[""O'Sullivan Beach""]"
"137.367466859357,  -32.409810442701556",Pandurra
"140.7847230766151,  -34.177165596087946",Paringa
"137.4797294013681,  -34.75151633122635",Parsons Beach
"137.8440025498471,  -34.34986138811213",Petersville
"138.9581618893671,  -35.08403421596947",Petwood
"138.6169021746237,  -34.426938863923276",Pinkerton Plains
"139.82057784252245,  -36.869593326605816",Pinks Beach
"138.87718521630117,  -33.82337447020432",Porter Lagoon
"139.90813137765022,  -34.19451079408352",Ramco Heights
"140.72187623421053,  -34.21206703664619",Renmark South
"138.56212694831632,  -34.93863757522562",Richmond (SA)
"138.484959878197,  -35.33401615925873",Sellicks Hill
"138.53533993446655,  -34.87085964618079",St Clair (SA)
"138.95652687553496,  -34.50046044488829",Stone Well
"138.55464829579185,  -35.02278020437618",Sturt (SA)
"138.72533253318846,  -34.95910458565718",Summertown
"137.82937250192373,  -34.1451890097523",Sunnyvale
"139.20160791852874,  -34.96468933425625",Tepko
"138.57245131423056,  -34.915248675583825",Thebarton
"136.01916813743443,  -34.18519041165588",Ungarra
"138.69115852816074,  -34.48766009352031",Wasleys
"129.8994286090668,  -27.03047071478416",Watarru
"139.32520757182937,  -35.326643869384476",Wellington (SA)
"138.0481672082754,  -33.72086758797478",Wokurna
"131.80812494252802,  -31.465201389894986",Yalata
"137.68676340767854,  -34.44817103444336",Yorke Valley
"117.26898692105956,  -32.31510691849754",Aldersyde
"115.16912303745818,  -33.669179309037894",Anniebrook
"115.10974078825106,  -29.559131342115606",Arrowsmith
"116.01211568532389,  -30.732745654017698",Barberton
"115.73633310739922,  -34.237961935498745",Biddelia
"122.22730204993887,  -17.91344172706787",Bilingurr
"118.38799634397596,  -30.441374076956844",Bonnie Rock
"116.44497384989039,  -34.92311359539599",Broke (WA)
"115.89604828263067,  -33.63317148683226",Brookhampton
"115.54591171244361,  -31.351134074664934",Caraban
"115.98622247914162,  -32.119106522556955",Champion Lakes
"115.76559048956318,  -31.935606461709764",City Beach
"114.33355743213889,  -26.747449860498136",Coburn
"118.16893779158153,  -28.54107620594979",Cooladar Hill
"115.80986400850215,  -31.951453123462557",Daglish
"119.22580253882698,  -20.23975998988937",De Grey
"115.77639570157169,  -31.833034385358193",Duncraig
"116.90409868220046,  -30.560502935297812",East Ballidu
"115.5059974010441,  -31.291997097527872",Gabbadah
"114.77869147753051,  -28.87841538682004",Georgina (WA)
"117.28807063718382,  -29.9927109390832",Goodlands
"115.00780146459518,  -33.8690736034789",Gracetown
"116.18174795320317,  -30.135266789650537",Gunyidi
"115.9323181299426,  -32.117508123802736",Harrisdale
"115.87972635009326,  -31.916587730947764",Inglewood (WA)
"118.76428682856793,  -22.676928915001398",Juna Downs
"115.10118327412108,  -30.28178200190656",Jurien Bay
"115.3712765265928,  -33.720954241008684",Kalgup
"118.13327876153484,  -31.6556325928782",Korbel
"117.53434432398657,  -30.684867522061896",Lake Margarette
"115.3560064272068,  -31.0235936020263",Lancelin
"115.0244819925659,  -29.979422397161837",Leeman
"121.45893672882758,  -28.843483660778094",Leonora
"119.12842782091433,  -32.666531407943545",Little Italy
"115.33534894580632,  -29.182631649686876",Lockier
"115.83771181788593,  -31.827631952101918",Marangaroo
"114.63219664169561,  -26.827216359255083",Meadow
"125.87277570705332,  -14.692252297707649",Mitchell Plateau
"115.10620086544908,  -29.36872999890793",Mount Adams
"118.40118976323444,  -34.165002786079604",Nalyerlup
"118.18260360571962,  -31.519835497709046",Nangeenan
"117.71224098929031,  -34.77383546205111",Narrikup
"118.75745058876183,  -33.99527588605402",Needilup
"116.05355232115596,  -33.816931081002544",North Greenbushes
"116.53732668466124,  -31.48362720001316",Nunile
"115.8186346826138,  -31.781290631471226",Pearsall
"115.71792871627119,  -33.347235565579766",Picton East
"116.69791518400275,  -30.395593691730816",Pithara
"119.17312298123002,  -28.335899539202202",Sandstone
"116.00823139283453,  -32.38547019140951",Serpentine (WA)
"115.75394669721979,  -31.827695986803935",Sorrento (WA)
"114.95409709981308,  -29.287860685331506",Springfield (WA)
"117.13676504364486,  -31.60841102358721",Waeel
"114.65981799851562,  -28.691113637822635",Waggrakine
"116.38718547327124,  -31.539369077588987",West Toodyay
"115.83923333864144,  -31.867449273144064",Westminster
"120.58882177801712,  -26.461752937655714",Wiluna
"121.90667492330978,  -33.819881972893455",Windabout
"116.0740327419381,  -34.779930327052654",Windy Harbour
"115.84973934633953,  -34.506573698104475",Yeagarup
"116.13884951325429,  -34.09464690783728",Yornup
"124.02504450259077,  -30.838136023923894",Zanthus
"146.96674484923875,  -41.30231073514631",Blackwall (Tas.)
"147.65365746577524,  -42.860979040736886",Carlton (Tas.)
"147.52366626420545,  -42.950482850721926",Cremorne (Tas.)
"147.08907126864543,  -43.13350441654616",Cygnet
"147.6280989038943,  -42.85533012159966",Dodges Ferry
"147.66722998907426,  -42.813213237671896",Forcett
"146.7424748745511,  -41.10050911611929",Greens Beach
"147.88752529133913,  -41.084996328372945",Herrick
"147.95155521897922,  -42.32017461137178",Little Swanport
"146.28629499335068,  -41.29011825432634",Lower Barrington
"147.18721383669887,  -42.32390598570455",Lower Marshes
"147.39880730340408,  -42.86137319635765",Mornington (Tas.)
"145.7313150139067,  -41.07116816150337",Mount Hicks
"147.4624588235625,  -42.39001638458317",Mount Seymour
"146.9217013340149,  -41.35896625847305",Notley Hills
"147.70316699487645,  -42.71647739434502",Nugent
"147.83555575131604,  -43.14645657822318",Port Arthur (Tas.)
"147.89497220918307,  -42.665398697119336",Rheban
"147.17649737076056,  -42.99717005370024",Sandfly
"145.13921037188246,  -40.84424626063151",Smithton
"144.8676583540667,  -41.01373604977749",Togari
"145.49947200218278,  -41.159713499114595",West Takone
"146.81943512798554,  -41.51137078058302",Westbury (Tas.)
"146.84588798828935,  -41.2952155591527",Winkleigh
"130.81163939457883,  -12.840438550391395",Charlotte
"133.83552289433342,  -23.713485192001865",Flynn (NT)
"131.12727877151585,  -12.584858133846033",Humpty Doo
"130.899201256317,  -12.373975305094644",Leanyer
"132.55936195309135,  -11.149271156441383",Minjilang
"131.21552741244605,  -16.795315868068553",Pigeon Hole
"134.43502704857772,  -24.00852119982219",Santa Teresa
"130.9533369227445,  -12.730602064348519",Southport (NT)
"129.70500933738643,  -14.203545799076549",Thamarrurr
"149.06328055084956,  -35.25119686857244",Macquarie
"149.04019129976393,  -35.29982316786246",Molonglo
"149.11384555322766,  -35.42782067693344",Richardson
"149.11965139165153,  -35.44963145989997",Theodore (ACT)
"149.03360843512294,  -35.319230623196745",Wright
"105.63648789998743,  -10.485347091905359",Christmas Island
"151.97863091016166,  -30.236305606144622",Aberfoyle
"141.80229904287722,  -33.29634018239729",Anabranch North
"149.2942361594948,  -36.73337510780719",Ando
"146.508544279755,  -31.92564290565643",Babinda (NSW)
"151.5849495675345,  -32.927937359018706",Barnsley
"150.85449003858213,  -34.563109882772544",Barrack Heights
"148.14661045552788,  -35.54503635415476",Batlow
"151.64066493526246,  -32.80357480725542",Beresfield
"146.15918206842,  -34.28165413809747",Bilbul
"151.3332959415043,  -33.50542878797103",Blackwall (NSW)
"151.5748804953405,  -32.71141104411333",Bolwarra (NSW)
"152.04973419995417,  -28.79286778693158",Bookookoorara
"149.43179398644455,  -30.047753336999264",Boolcarroll
"152.59895193528783,  -28.48454756757226",Boomi Creek
"147.1489197297616,  -35.97623504810257",Bowna
"151.34556579571444,  -33.53695202074801",Box Head
"149.4475491217031,  -34.79839526902778",Breadalbane (NSW)
"150.68952427495464,  -34.70187392295687",Brogers Creek
"151.7596219204755,  -32.51764412448872",Brookfield (NSW)
"147.1627087041379,  -31.950974635517543",Buddabadah
"145.08063828280328,  -35.7385058382972",Bullatale
"149.2235436099028,  -35.55937002199853",Burra (Queanbeyan-Palerang Regional - NSW)
"151.5752599851171,  -33.05056624007704",Buttaba
"149.32957253178287,  -35.17206440664034",Bywong
"151.66360079439693,  -32.954869192347644",Cardiff South
"152.91206865344662,  -29.67112402411318",Carrs Island
"150.7575283139972,  -32.23915126140164",Castle Rock
"150.66885090777242,  -34.09681640194327",Cawdor (NSW)
"149.21150899346873,  -36.07383431371131",Chakola
"150.75201197117207,  -33.77586895648392",Claremont Meadows
"151.10423443960596,  -33.856876615657534",Concord
"153.44081837759845,  -28.311336677858318",Condong
"152.48514932203494,  -29.3889569471626",Coombadjha
"151.2037110533375,  -33.619650253840824",Cottage Point
"149.51452299005814,  -33.58017542209511",Cow Flat
"153.4840264269575,  -28.46162072743847",Crabbes Creek
"148.53070989593112,  -36.346308257081205",Creel Bay
"152.5931006635721,  -31.873678544850087",Croki
"152.7091067393264,  -28.37648711374991",Dairy Flat
"152.7905315909074,  -31.670404047487164",Deauville
"151.15287404816806,  -33.85269114585959",Drummoyne
"151.7222154924465,  -32.992088689026346",Dudley
"151.4849114471428,  -29.172237386636606",Dumaresq Valley
"149.47611622145075,  -33.410955611180974",Dunkeld (NSW)
"152.8295490387942,  -29.642875964654333",Eatonsville
"151.31584961960252,  -32.93162217951481",Ellalong
"147.5334903753127,  -34.68130611848965",Erin Vale
"150.29507306175498,  -34.89011671476856",Ettrema
"152.88680933983056,  -30.859294949445513",Eungai Rail
"150.9401655321808,  -33.86666984042867",Fairfield Heights
"151.5023493398004,  -32.73396074887059",Farley
"153.48492696915994,  -28.31859021675001",Farrants Hill
"147.98027697891644,  -33.393330675267286",Forbes (NSW)
"151.7620727276786,  -32.34820839436172",Fosterton
"148.55991305187902,  -34.60788173767411",Galong
"147.34663246793323,  -35.24330415117608",Gelston Park
"151.20327612801498,  -30.14471386356675",Georges Creek (NSW)
"150.79833057525244,  -34.77282247147661",Gerroa
"153.27863710789947,  -28.817683811081174",Girards Hill
"152.96109559532317,  -31.016598682782817",Gladstone (NSW)
"149.38801835437317,  -36.68771977965403",Glen Allen
"151.84710999983793,  -32.5327062784967",Glen Martin
"151.01685631515832,  -32.073531099869264",Glenbawn
"149.5948326821763,  -33.47222066192686",Gormans Hill
"151.08879768196138,  -32.57944651655591",Gouldsville
"149.0743200721274,  -31.42598011093844",Gowang
"146.04553117452085,  -34.28004257474755",Griffith (NSW)
"150.08223441155937,  -32.64287835359287",Growee
"150.2089117148538,  -30.995790216150997",Gunnedah
"149.2656672544454,  -34.78809657139497",Gunning
"150.7938482700326,  -34.54453325294709",Haywards Bay
"150.4556573506453,  -33.728249530190645",Hazelbrook
"151.06686876378095,  -32.33933493992657",Hebden
"150.14564822392586,  -33.47773421803912",Hermitage Flat
"151.22685151354267,  -33.952603931781454",Hillsdale
"151.1752318009564,  -32.539195013434636",Hunterview
"153.24174154052332,  -29.453814015081768",James Creek
"149.59835138782836,  -35.8756013515229",Jinden
"150.80682350264547,  -34.429851189034316",Kembla Heights
"150.9077194047007,  -33.74346386478007",Kings Park (NSW)
"153.53992397423536,  -28.76063196779671",Knockrow
"148.58224698143505,  -34.02117894512262",Koorawatha
"149.61429512316175,  -35.76053059199383",Krawarree
"152.26086284684152,  -31.98759052995142",Kundibakh
"143.52420132636473,  -34.8929917670489",Kyalite
"147.03411686644782,  -36.09335515863996",Lake Hume Village
"151.37142702652795,  -33.13397529421011",Lemon Tree
"150.80047692175265,  -33.73713127626979",Lethbridge Park
"150.13938515129422,  -33.49545430757389",Littleton
"152.88479010901054,  -28.805687376057865",Lower Dyraaba
"151.12853320566703,  -34.080129852463465",Maianbar
"151.42238573412186,  -33.12876242906009",Mandalong
"151.64799343603357,  -33.05922075357541",Marks Point
"151.6139895038327,  -32.98276627931003",Marmong Point
"151.1557434446464,  -33.91119808300966",Marrickville
"151.80320597781866,  -32.42234722298913",Marshdale
"149.78151004926124,  -35.2363971664547",Mayfield (Queanbeyan-Palerang Regional - NSW)
"150.664937346924,  -34.918824118137024",Mayfield (Shoalhaven - NSW)
"148.4136473276756,  -34.70199175126909",McMahons Reef
"147.91564504774828,  -34.33336842854461",Milvale
"147.398955588388,  -34.55718068860964",Mimosa (NSW)
"150.84811309999563,  -34.03024226675427",Minto
"147.0644628833941,  -34.23777559821359",Mirrool
"152.35812098810908,  -31.6952604101999",Mooral Creek
"151.0719142886597,  -33.969420722382125",Mortdale
"148.78733436125762,  -34.03319755155494",Mount Collins
"149.66424034375237,  -32.62088166638058",Mount Frome
"150.8724223140012,  -34.441015876880314",Mount Saint Thomas
"148.8054349135068,  -31.207743772203933",Mount Tenandra
"147.14391960747994,  -35.77936135294353",Mountain Creek (NSW)
"153.1965213528887,  -30.08174076368947",Mullaway
"147.64951304075535,  -31.497254404676593",Mumblebone Plain
"149.56640939518533,  -34.66293555642554",Mummel
"147.41227120070693,  -34.70592858776252",Murrulebale
"149.80114962055984,  -37.02505356212825",Nethercote
"150.8731234232495,  -33.72043519875435",Nirimba Fields
"149.76149556705587,  -31.187574047834733",Nombi
"151.25125764699513,  -33.78690421421275",North Balgowlah
"152.77320990326768,  -31.657359891761732",North Brother
"146.84806329764453,  -33.83023527600384",North Yalgogrin
"149.13837437469246,  -29.987325988212824",Nowley
"151.06005286206877,  -29.641307370461046",Oakwood (NSW)
"153.53452306089915,  -28.510751288028512",Ocean Shores
"152.5675412276465,  -28.946840334419516",Pagans Flat
"151.2144749685103,  -31.711555939217106",Pages Creek
"149.73217305888198,  -34.115349477737716",Paling Yards (Oberon - NSW)
"149.86229469704057,  -33.195075630740014",Palmers Oaky
"153.5097828204492,  -28.94765617493667",Patchs Beach
"148.67350251193662,  -36.65045057793021",Paupong
"151.28463195053075,  -32.90391461206356",Paxton
"151.23159502208526,  -33.34435819433694",Peats Ridge
"141.22328128352987,  -33.67047545742671",Pine Camp
"148.15220860219446,  -31.434319797222233",Pine Clump
"152.55808304486527,  -31.438993692860794",Pipeclay
"151.27924835238593,  -32.76662349515109",Pokolbin
"152.39268629583617,  -32.0351343165051",Possum Brush
"150.88242808432867,  -34.50646735643445",Primbee
"151.28533491242897,  -33.78268481383594",Queenscliff (NSW)
"149.83987330385438,  -35.55392875922009",Reidsdale
"151.29708657015624,  -32.720739950822946",Rothbury
"152.89396990770962,  -28.469122984364777",Rukenvale
"150.11026204247395,  -35.7256658710615",Runnyford
"151.5280177556915,  -32.99015563621383",Ryhope
"151.24184487608267,  -33.792127723941455",Seaforth (NSW)
"150.74174019926184,  -34.85005009613246",Shoalhaven Heads
"150.62708570802215,  -30.939097517250854",Somerton (NSW)
"153.56492195379286,  -28.884407033352513",South Ballina
"151.37279741322715,  -33.42819673801869",Springfield (Central Coast - NSW)
"150.2221907554123,  -35.03108803190395",St George (NSW)
"149.73011568263172,  -32.475116101083145",Stony Creek (Mid-Western Regional - NSW)
"151.45578909927886,  -33.293907481559664",Tacoma South
"151.98613333185918,  -32.74242472057899",Tanilba Bay
"149.54032108442277,  -36.750425851299774",Tantawangalo
"150.90527471490665,  -33.70534649989297",The Ponds
"150.03877369096185,  -36.33549221092571",Tilba Tilba
"152.08592946495827,  -31.850015816656878",Tiri
"149.67626134984542,  -34.87089099072126",Tirrannaville
"150.9049691723993,  -34.38424024066652",Towradgi
"153.22507346843315,  -28.803214345205276",Tuncester
"152.88938415273944,  -30.237999185202153",Ulong
"150.3586884493537,  -30.182853049753046",Upper Horton
"150.255594571762,  -32.96150116841291",Upper Nile
"147.47269072742702,  -34.97488479589006",Wallacetown
"151.66446087348157,  -32.90135652848189",Wallsend
"150.50079066378424,  -35.08154269015167",Wandandian
"149.8619957789251,  -36.316641623515025",Wandella (NSW)
"153.2261533752191,  -29.38556266378929",Warregah Island
"151.30392508742224,  -31.193463211401923",Weabonga
"153.3496217140478,  -28.606098801478375",Whian Whian
"150.7459013984317,  -29.88249086472361",Whitlow
"151.21341023804322,  -32.61639512339525",Whittingham
"148.13556154232737,  -35.36997114619394",Windowie
"150.40554513908853,  -31.624709051572893",Windy
"150.4414211318653,  -33.34287466468917",Wollangambe
"149.93357012287524,  -37.25242106613241",Wonboyn
"151.23857925069294,  -33.498951629972744",Wondabyne
"151.2976787974764,  -33.471243782847395",Woy Woy Bay
"149.0761040953282,  -32.53231143076941",Wuuluman
"150.17649107583705,  -35.39063888615545",Yadboro
"152.76183059915164,  -31.04294399277888",Yarravel
"144.09716550367693,  -38.435097787925606",Aireys Inlet
"144.88142355478374,  -37.72373847916295",Airport West
"142.8523627105357,  -38.43752156474808",Ayrford
"144.60140412182125,  -36.180935249974105",Bamawm Extension
"146.17740286574897,  -38.403029208083026",Baromi
"144.4881135462714,  -38.27472836069397",Barwon Heads
"145.99676570122313,  -36.578879130761955",Benalla
"142.81834877900988,  -36.01324549848255",Birchip West
"145.16946046879875,  -38.33673155137097",Bittern
"146.62796777888522,  -38.41025261475519",Blackwarry
"141.6140396082493,  -38.279114217704176",Bolwarra (Vic.)
"143.74194252410115,  -36.13365927554108",Boort
"144.854836050294,  -37.786674232508",Braybrook
"147.02909789331355,  -36.70256222923991",Bright (Vic.)
"144.79100822583655,  -37.635336651439005",Bulla (Vic.)
"148.71267202320956,  -37.65531489694997",Cabbage Tree Creek
"144.21148254420672,  -37.10558201752701",Campbells Creek
"143.87332070337146,  -36.01042044522839",Canary Island
"141.55503193796605,  -37.51648981464597",Carapook
"144.73721753050057,  -37.734516716956286",Caroline Springs
"145.17581733246527,  -38.09275476181432",Carrum Downs
"146.4236537814463,  -38.31612914531259",Churchill (Vic.)
"145.50814104648853,  -37.9259486068849",Cockatoo (Vic.)
"145.61711626110238,  -38.145776619484955",Cora Lynn
"143.04001504846028,  -38.49916946170124",Cowleys Creek
"144.6091648083509,  -36.89399116284874",Derrinal
"146.38095515414608,  -36.36341571918243",East Wangaratta
"145.77674933409855,  -37.70431847999242",East Warburton
"145.28049670291142,  -37.88436653280936",Ferntree Gully
"144.97825403611085,  -38.458371418045694",Flinders (Vic.)
"144.1402964904932,  -35.717443695169436",Gannawarra
"143.6947896826126,  -38.45295114983327",Gerangamete
"145.06526613423358,  -36.45704097207043",Girgarre East
"141.55179110088525,  -38.236731656459185",Gorae
"145.00906763856597,  -37.937664882067835",Hampton (Vic.)
"147.08275876757435,  -36.22888835931967",Huon
"146.82106787814266,  -36.15609627318838",Huon Creek
"145.19429164913038,  -37.6375502198405",Hurstbridge
"142.29383752732,  -34.42717598997635",Iraak
"143.95598114608455,  -36.25169593297801",Jarklin
"144.24192888495716,  -36.806485803219815",Kangaroo Flat (Vic.)
"145.88038386821344,  -38.41937797301107",Kardella
"147.00657338369794,  -36.27252034018563",Kiewa
"146.29735929023175,  -36.454420895810166",Laceby
"142.8321885805817,  -35.34801873472973",Lake Tyrrell
"147.31763830362482,  -38.09053816760976",Lake Wellington
"143.44429981593024,  -35.65157313367158",Lalbert
"143.50651562333357,  -38.356155416533376",Larpent
"143.378705458146,  -38.66808603446318",Lavers Hill
"145.55793427601108,  -37.276727582005265",Limestone (Vic.)
"143.5510274707738,  -37.68905407866242",Linton
"146.57661085854508,  -38.25134590891559",Loy Yang
"144.2644344106838,  -37.395604226144926",Lyonville
"142.01857988943962,  -38.047898697404705",Macarthur (Vic.)
"146.82149316107933,  -37.87542390424092",Maffra West Upper
"142.90028483736845,  -35.05275931470545",Manangatang
"141.85004369655175,  -34.40126282384737",Merrinee
"142.9501228325798,  -37.604355450340684",Mininera
"142.6223290079522,  -35.15428905634983",Mittyack
"145.52488744750318,  -37.16105785410856",Molesworth (Vic.)
"146.15446208417023,  -36.67516399663104",Molyullah
"145.2512853904028,  -36.3472179787462",Mooroopna North West
"144.17436124995967,  -38.24702963554077",Moriac
"142.06180037996333,  -37.90400939174123",Mount Napier
"146.30829874134656,  -36.74511735753964",Myrrhee
"145.56517103498987,  -38.03539312471408",Nar Nar Goon North
"147.89705953187033,  -37.8583758577457",Nungurner
"145.08131027243152,  -37.99184673148995",Parkdale
"141.3721136859579,  -36.66081527242264",Peronne
"145.11234928730457,  -37.66743769018927",Plenty (Vic.)
"144.66365305576298,  -38.13759716423587",Portarlington
"144.70047010284665,  -38.322778636110584",Portsea
"144.21676058418254,  -36.93728943512414",Ravenswood South
"144.367670682981,  -37.725059759025065",Rowsley
"143.0868052378356,  -38.46143903791555",Scotts Creek (Vic.)
"145.95791822524478,  -38.28170331466646",Seaview
"145.6519890304078,  -36.77273857693973",Sheans Creek
"143.7101941717927,  -38.71312173189683",Skenes Creek
"141.11111873268285,  -37.7338014304004",Strathdownie
"144.22193882574732,  -38.00687555594495",Sutherlands Creek
"142.97356213440767,  -35.73285133152827",Sutton (Vic.)
"143.0191717624494,  -36.51911218786854",Swanwater West
"147.0384308425477,  -36.23951924138703",Tangambalanga
"147.13767156851736,  -36.76615128478322",Tawonga South
"144.63000708469272,  -37.72756655461344",Thornhill Park
"147.6956330204604,  -37.16909294664672",Tongio
"148.0617502655176,  -37.79261109770158",Toorloo Arm
"144.85294241884367,  -37.804689528309886",Tottenham (Vic.)
"143.7889423815536,  -37.354298441780614",Tourello
"142.99117308017276,  -35.43337711511977",Tyrrell
"144.21463556873857,  -37.16036261880889",Vaughan
"146.28445088570248,  -36.31988827644597",Waldara
"144.05319702305275,  -37.56249104820402",Wallace
"143.11415224742706,  -35.78449673020219",Warne
"142.17935692273625,  -37.71684606117931",Warrayure
"145.06691128975524,  -37.30056168140598",Waterford Park
"144.8001669807697,  -37.55885817221602",Wildwood
"144.05143972209052,  -38.28995282899819",Wurdiboluc
"143.81202899959948,  -36.073813956364404",Yando
"145.5290980791575,  -37.84596245261244",Yellingbo
"141.89356371416181,  -37.75645312142383",Yulecart
"144.80092599690366,  -25.92967126333839",Adavale
"151.978453803282,  -28.01721404901738",Allora
"152.98391874013757,  -27.44314514073351",Ashgrove
"152.43390748651413,  -27.421344984572723",Atkinsons Dam
"151.7778194808939,  -27.88765650064868",Back Plains
"153.17157743253188,  -27.738788044739934",Bahrs Scrub
"148.80325419465942,  -21.161683708650777",Benholme
"147.83835448350084,  -20.33769108373972",Bogie
"152.88891857637876,  -25.353858884477408",Booral (Qld)
"153.13142262225657,  -27.93743938120498",Boyland
"148.50743551488105,  -21.16619077133776",Broken River
"152.04499777866448,  -24.945205343013576",Bullyard
"152.83232810899705,  -25.38171009952521",Bunya Creek
"151.56561509406805,  -26.880114539954292",Bunya Mountains
"150.64529044806125,  -22.893573710846564",Byfield
"151.18741182368166,  -24.034038065190366",Calliope (Qld)
"150.47059631808384,  -26.633223580788478",Cameby
"150.1442026499931,  -23.026098295087206",Canoona
"147.73523867892627,  -24.83831046398575",Carnarvon Park
"152.80976060233044,  -26.858165370294792",Cedarton
"151.93251731502855,  -26.36847623418314",Charlestown (Qld)
"152.75034566677556,  -27.637717651057827",Churchill (Qld)
"152.94518287373765,  -26.648423907000975",Coes Creek
"151.2097755309013,  -28.436171323055937",Coolmunda
"145.90108354553843,  -17.524174717097495",Cooroo Lands
"151.4712202028641,  -24.623415962738978",Dalga
"151.97294879916905,  -24.92762312999364",Damascus
"148.89211517973175,  -21.109811670090583",Devereux Creek
"150.64962055112744,  -24.18617902438782",Dumgree
"147.8061412663176,  -21.497139830802947",Eaglefield
"144.10924625524888,  -18.604398661337136",Einasleigh
"151.8592245877534,  -24.493724242671274",Euleilah
"146.07503764780927,  -17.493373039744334",Flying Fish Point
"151.02595923036372,  -27.961288869158118",Forest Ridge
"153.14234034831065,  -25.260310877393714",Fraser Island
"146.63524005572413,  -19.57915379456638",Granite Vale
"150.55632290568508,  -23.03191532731709",Greenlake
"153.04046024998766,  -27.270838999789497",Griffin
"148.6102305055457,  -25.98555773669643",Gunnewin
"152.66536216497866,  -26.18298247952583",Gympie
"146.47747709620472,  -19.455509028849885",Hervey Range
"153.02619108280942,  -27.66847231030983",Hillcrest (Qld)
"148.40951951982942,  -25.686854582634727",Hutton Creek
"149.63275522007638,  -26.644519948689965",Jackson
"153.08308715771884,  -27.634547941170997",Karawatha
"142.2066477219856,  -10.549587065551629",Keriri Island
"152.77110513356135,  -26.64615064586797",Kidaman Creek
"152.29931117358947,  -28.344362539630094",Killarney (Qld)
"152.34997718396502,  -27.51834965285383",Lake Clarendon
"152.9266939849675,  -26.713305455814798",Landers Shoot
"152.2637096704421,  -27.794571263273777",Lefthand Branch
"152.1853768259401,  -28.330784098441846",Loch Lomond
"143.24026626891117,  -13.005726462903615",Lockhart River
"143.40777467583368,  -9.752328182044828",Masig Island
"152.9920524067728,  -27.381680885036044",McDowall
"151.9980110343753,  -25.00147913145636",McIlwraith
"148.7379127356451,  -20.75812026636296",Mentmore
"145.63770956396752,  -17.563484475623472",Middlebrook
"150.39919546197973,  -23.60357998017382",Moongan
"153.02458553609608,  -27.53428412150858",Moorooka
"152.6632293737806,  -27.00128320872905",Mount Archer (Somerset - Qld)
"152.71475743153383,  -27.014734312009743",Mount Delaney
"151.00862763330818,  -23.761404329131324",Mount Larcom
"146.66663983370591,  -19.226251979630174",Mount Low
"150.39658454517004,  -23.643530846291608",Mount Morgan
"149.1363655239115,  -21.368248288006882",Munbura
"153.0715553650821,  -27.89968553815789",Mundoolun
"152.84158527574112,  -26.197999110474512",Neusa Vale
"152.68890436885562,  -26.093264133425972",North Deep Creek
"150.3539746297367,  -24.334082735944353",Orange Creek
"143.61350966612727,  -15.95236232284433",Palmer (Qld)
"151.3959865237684,  -27.79129209166048",Pampas
"153.12211328816954,  -26.711644283617737",Parrearra
"144.89680092298994,  -17.390530502905616",Petford
"152.5639051095974,  -25.605008273196844",Pilerwa
"150.46151490316092,  -23.352786492699497",Pink Lily
"148.95239981468788,  -25.890098048929087",Pony Hills
"153.29096072022207,  -27.64783697988422",Redland Bay
"147.93534532238164,  -22.847937142025764",Retro
"146.26499587835556,  -20.0640331815392",Richmond Hill (Qld)
"151.46125950104528,  -29.06246854328492",Riverton (Qld)
"148.80396931212815,  -26.56314119880514",Roma
"146.78238131020925,  -19.240816366008197",Rowes Bay
"152.9065956202607,  -28.282581686591346",Running Creek (Qld)
"152.86894977186148,  -27.367574218875294",Samford Valley
"149.1879486620105,  -21.437609896808016",Sarina
"153.08097148527457,  -27.328054028723546",Shorncliffe
"139.4897832792277,  -20.70529913245308",Soldiers Hill (Qld)
"151.7354237793323,  -27.68317882114281",Southbrook
"152.3320319112914,  -27.45320478531573",Spring Creek (Lockyer Valley - Qld)
"151.57598601334664,  -27.673201390224246",Springside (Qld)
"153.1382308646075,  -27.620232411422542",Springwood (Qld)
"151.58889300401518,  -26.111126582328",Stalworth
"152.9816312578185,  -27.493525415694442",Taringa
"151.93343951523462,  -28.581091806258932",The Summit
"153.26090441669805,  -27.5676221409715",Thornlands
"151.92875052908772,  -25.00975518873088",Tirroan
"150.97670679729222,  -26.79458541903562",Tuckerang
"153.28564617469502,  -27.876888672332154",Upper Coomera
"145.29812312323742,  -16.21233343475147",Upper Daintree
"152.82153342969488,  -25.297500218617028",Urraween
"151.9626954860155,  -25.087837383040156",Wallaville
"146.0564577869585,  -17.450586409955495",Wanjuru
"153.12618452429538,  -26.98458296937235",Welsby
"153.00579210846752,  -27.48342198731341",West End (Brisbane - Qld)
"152.74860869771973,  -27.62008194617079",West Ipswich
"146.06272205477893,  -18.729237623364376",Wharps
"152.34979729931564,  -25.664072310976",Woocoo
"150.15285792791164,  -23.94722191711966",Wowan
"151.79528097890997,  -27.026885013246464",Wutul
"138.01652505307132,  -32.89940354029016",Baroota
"138.823737802998,  -34.34078108877035",Bethel
"138.71010757579762,  -34.67561904582106",Blakeview
"139.1329239794774,  -33.901671370534885",Bright (SA)
"137.9998609892559,  -33.84001301083772",Bute
"140.26947127353674,  -34.63487039078858",Caliph
"138.8392690067102,  -34.81621674174281",Chain Of Ponds
"140.27301216199916,  -35.98198035153658",Coombe
"136.17814323176316,  -33.432032114897595",Darke Peak
"138.56753096859143,  -34.814889285483964",Dry Creek (SA)
"137.71479870409019,  -35.10044333044322",Edithburgh
"138.5694306084872,  -34.98022563685501",Edwardstown
"138.59521852570973,  -35.572726646944474",Encounter Bay
"138.59128000092863,  -34.89669288574574",Fitzroy (SA)
"138.6487925569768,  -34.95953565371968",Glen Osmond
"138.70651153340867,  -34.85433319077602",Highbury (SA)
"138.64545081328012,  -34.8275678844981",Ingle Farm
"138.79207760229463,  -34.80215805957504",Inglewood (SA)
"140.7422435317435,  -27.744981199319",Innamincka
"137.4711964648196,  -26.855297990756466",Kalamurina
"134.90062813623265,  -32.94348091051393",Karcultaby
"140.15850838120258,  -36.64022942779676",Keilira
"136.5133711489964,  -33.22776743990484",Kelly
"138.55294682598424,  -34.879132142150155",Kilkenny
"135.3978447872939,  -34.550630345642375",Little Douglas
"138.35132939984365,  -34.34646979344867",Long Plains
"139.3308031807886,  -34.85299738068346",Mannum
"138.9376389615888,  -34.49074021382525",Marananga
"138.059542300948,  -29.64775165606122",Marree
"138.48094462464985,  -35.23224536781671",Maslin Beach
"137.70701552442642,  -33.972667332545846",Matta Flat
"140.7051711471908,  -37.4841770390588",Moerlong
"134.46142562468458,  -33.00950395108554",Mortana
"138.9197397604851,  -35.09104040813852",Mount Barker Springs
"138.65916892299987,  -34.96400575443816",Mount Osmond
"135.96823173170947,  -33.6951053043644",Murlong
"138.63124157888836,  -34.85254195122333",Northgate (SA)
"138.6628468021784,  -34.82848936833772",Para Vista
"140.732004498244,  -34.742407754661066",Paruna
"137.74357313793732,  -32.97103055240056",Port Bonython
"139.59703537148428,  -34.6105865353121",Punyelroo
"139.0987585891682,  -34.99272665025647",Rockleigh
"138.34054994100615,  -34.15430711854214",Saints
"137.7845284264083,  -34.52900277421779",Sandilands (SA)
"138.93447991719742,  -34.08252258859772",Tothill Creek
"137.69557592859366,  -33.96793930286059",Wallaroo Mines
"135.58136830791287,  -33.26678167097376",Warramboo
"138.55870602058735,  -34.903806855109124",Welland
"138.55912285195004,  -34.888901318289854",West Croydon
"138.96482830537374,  -35.20766069655539",Woodchester
"135.46838113447012,  -33.057868920025214",Wudinna
"139.82977267608382,  -36.85738012700251",Wyomi
"116.18873358361998,  -31.63605503874411",Avon Valley National Park
"117.59430158616635,  -33.32077001162285",Ballaying
"115.8054191111891,  -31.69623517288084",Banksia Grove
"115.79242777100407,  -32.537940846686794",Barragup
"113.1416343388821,  -24.85830252515634",Bernier Island
"115.26690740301677,  -33.78930477454777",Boallia
"117.55449988743506,  -34.008670055849564",Bobalong
"116.40621565416953,  -33.84567034815994",Boyup Brook
"114.8317437798503,  -28.741922047945227",Bringo
"115.99925732325195,  -32.22287939770922",Byford
"115.7930739387413,  -31.92176544296308",Churchlands
"115.78220070070859,  -31.981259579920295",Claremont (WA)
"126.1278378207434,  -31.766575457692213",Cocklebiddy
"122.50097844322262,  -33.73468151943473",Condingup
"116.59907111919722,  -32.821821828528236",Crossman
"115.53467618353547,  -28.670726088789017",Devils Creek
"115.65917898476948,  -33.33720941260422",East Bunbury
"117.32405874245902,  -32.51197417959635",East Pingelly
"115.9380547606432,  -32.61872165040611",Fairbridge
"116.81618850483896,  -31.63924181555949",Grass Valley
"115.80273683923551,  -31.82720759948586",Greenwood (WA)
"115.7432255976181,  -33.52185923519044",Gwindinup
"115.9749377993904,  -32.154697876271825",Haynes
"121.64352494455915,  -31.773754078576975",Higginsville
"115.76443170341089,  -32.282141137724615",Hillman
"113.7449348390119,  -28.537863952664893",Houtman Abrolhos
"115.53799888730693,  -29.183192378228917",Ikewa
"116.35607721080164,  -34.118974943178486",Kingston (WA)
"118.32269676476072,  -32.429239723413374",Kondinin
"117.14382668265205,  -34.92417516601085",Kordabup
"117.32865926212094,  -30.398926988036504",Kulja
"121.98312871820647,  -18.712589382846623",Lagrange
"116.54112244935384,  -30.774227044483034",Lake Hinds
"117.0282284688519,  -32.74009579692519",Lol Gray
"115.82770401515968,  -31.81080733214602",Madeley
"115.71274608699298,  -31.664915204947135",Merriwa (WA)
"117.38784123112494,  -21.39504352809478",Millstream (WA)
"117.17224901257616,  -32.11253957193928",Morbinning
"116.34794331477268,  -33.42765916347586",Muja
"127.72751825068991,  -31.629012727792805",Mundrabilla
"114.77067127727817,  -28.564590811625273",Nanson
"115.64971374417416,  -31.30722649904001",Neergabby
"117.36495209484266,  -30.664333111551898",Newcarlbeon
"115.95972600342239,  -32.509739641403726",North Dandalup
"118.06051072680481,  -33.10742800860973",North Kukerin
"117.78800878165775,  -31.03843511420855",North Trayning
"117.29920440873967,  -35.004482497658685",Ocean Beach
"115.94648069300986,  -31.938803391991314",Redcliffe (WA)
"116.77452274154996,  -29.225103566863634",Rothsay
"115.88667020287275,  -32.0295055163624",Shelley (WA)
"120.48102005326231,  -27.49278964314623",Sir Samuel
"118.8849236857289,  -31.550204710095286",South Bodallin
"119.29110101345505,  -31.7795414722215",South Yilgarn
"115.95898691282545,  -32.106609951719776",Southern River
"116.64556927753212,  -31.899736672291596",St Ronans
"120.69136010670464,  -31.537311076783922",Victoria Rock
"117.34539580357222,  -33.30172886686792",Wagin
"116.6779349364699,  -32.65501200259941",Wandering
"117.30777474442836,  -32.8348029448439",Wardering
"128.22363351488343,  -17.026512344450776",Warmun
"115.84622313031657,  -32.26629843644489",Wellard
"116.04493402006871,  -32.550821685773634",Whittaker
"121.4863348748609,  -30.737670071541864",Williamstown (WA)
"116.61776866151459,  -31.471016558340057",Wongamine
"117.46053813216085,  -33.57030412547873",Woodanilling
"147.26466288143916,  -43.31291330754627",Alonnah
"146.942274414476,  -41.99066006337214",Arthurs Lake
"145.61468500821718,  -40.92827046826189",Boat Harbour Beach
"143.93196392526056,  -39.78793927706302",Bungaree (Tas.)
"148.2720729574682,  -41.66006357266737",Chain Of Lagoons
"146.48029571054005,  -41.5437801381893",Chudleigh
"145.36719621116805,  -40.855583943743426",Cowrie Point
"147.10166242364696,  -43.246006422529035",Eggs And Bacon Bay
"147.26397389669708,  -42.93262576640253",Fern Tree
"148.262837641411,  -41.993064842825476",Friendly Beaches
"147.17843439391328,  -43.22560168167257",Garden Island Creek
"147.03593721197788,  -42.768199007118",Lawitta
"146.77707430681104,  -42.95109649531379",Lonnavale
"146.86210232549413,  -43.435015683567165",Lune River
"147.52939382446394,  -42.79410729603814",Midway Point
"148.23543011128163,  -40.93410595988551",Mount William
"146.4972986288496,  -41.17100984932765",Northdown
"147.2892722489114,  -42.920687623665366",Ridgeway
"145.48472236010485,  -40.903280088836546",Rocky Cape
"145.016144145687,  -41.053780972547",Roger River
"145.87686731678514,  -41.069963986238534",Shorewell Park
"147.34340603677592,  -42.94382805053429",Taroona
"147.45537418152117,  -42.47017014145757",Tunnack
"146.25041093715683,  -41.90218647930581",Walls Of Jerusalem
"147.67111925239473,  -42.7656022676849",Wattle Hill (Tas.)
"146.80511364606187,  -42.68483386521149",Westerway
"147.72628481527113,  -43.144217674488246",White Beach
"143.99133048012737,  -39.70569733472692",Yambacoona
"147.17229694366114,  -41.48401153382477",Youngtown
"133.85378499785782,  -23.696205110600044",Araluen (NT)
"130.96304007702093,  -12.521160064327367",Archer
"132.27263398913323,  -24.085046048273433",Areyonga
"130.85881383043048,  -12.438097513874945",Bayview (NT)
"133.11508581951549,  -14.558015430938989",Beswick
"130.92296156772304,  -12.665418421309411",Blackmore
"130.90985996248494,  -12.434156733703656",Coonawarra (NT)
"130.71107805379975,  -12.580282494565175",Cox Peninsula
"131.92366411964343,  -14.074340010823523",Edith (NT)
"134.19766063538705,  -14.15941141797855",Flying Fox (NT)
"135.16761668687894,  -23.062049353988126",Hart (NT)
"129.3854164454951,  -23.27301999194533",Kintore
"130.43197326625395,  -18.45968092908567",Lajamanu
"130.82584727111765,  -12.455241103576347",Larrakeyah
"131.06571046123656,  -12.740231227430447",Livingstone
"130.8772946316529,  -12.370608478518946",Nakara
"130.56391527277597,  -22.64922916744493",Nyirripi
"149.09600365917393,  -35.210918809531144",Giralang
"149.0965027390872,  -35.187333993042564",Nicholls
"149.1410617650045,  -35.28243417843729",Reid (ACT)
"149.10726237451306,  -35.14680551108215",Taylor
"151.53389568210247,  -32.69488295816977",Aberglasslyn
"151.43723514647712,  -32.81967620135746",Abermain
"149.38599111486863,  -33.73573576503507",Arkell
"150.99557337189265,  -30.324827619807742",Bakers Creek (Uralla - NSW)
"151.26510839999466,  -33.80683767828128",Balgowlah Heights
"150.40836847640244,  -30.525913329467052",Banoon
"151.2016067982935,  -33.86045822060391",Barangaroo
"152.67122320831146,  -31.440290685190874",Beechwood
"152.16918512952188,  -31.98413269208878",Belbora
"149.2606171804857,  -34.91320549553148",Bellmount Forest
"150.09483342996884,  -35.97850602316836",Bergalia
"146.4616845241196,  -35.394346967728346",Bidgeemia
"151.22625261737062,  -32.57516693336488",Big Ridge
"147.41955653606266,  -35.34675723320168",Big Springs
"149.4404687115338,  -33.28406485745826",Billywillinga
"151.68144953499115,  -30.33094162909925",Black Mountain (NSW)
"150.8399416613141,  -34.5710124859435",Blackbutt (NSW)
"152.53472065762696,  -32.339397234362536",Boomerang Beach
"151.11071577336867,  -33.84282312259877",Breakfast Point
"149.6902812784055,  -33.474226624938474",Brewongle
"151.20322577099915,  -33.58441698771352",Brooklyn (NSW)
"150.15377438226253,  -35.85299626189717",Broulee
"150.81089289786456,  -34.48276664311595",Brownsville
"148.03286500234393,  -31.333200158509886",Bullagreen
"148.00503403103522,  -34.897207636944216",Burra Creek
"149.55338081839778,  -32.57876449449617",Caerleon
"150.70041077402897,  -34.08407775127501",Camden South
"150.40621840856758,  -31.386763190592422",Caroona
"150.82926569206384,  -33.881252876202005",Cecil Park
"149.87913658221862,  -33.84970391441634",Chatham Valley
"151.15995482318127,  -33.79558113441781",Chatswood West
"151.6345466431831,  -32.754678754440356",Chisholm (NSW)
"151.04731980747172,  -33.89226905757129",Chullora
"150.97052894623823,  -34.25921078571608",Clifton (NSW)
"151.69744480094272,  -31.915850407895867",Cobark
"149.42133523465878,  -34.934284629484026",Collector
"147.6539301974153,  -34.508148778487026",Combaning
"148.97159000892142,  -32.37063748272288",Comobella
"151.8159543651187,  -31.85531532683067",Coneac
"142.0986125161123,  -34.088713222069515",Coomealla
"150.10907551957965,  -31.569175845317044",Coomoo Coomoo
"153.5691248281569,  -28.692306595042407",Coopers Shoot
"149.05941529532018,  -37.0924619804818",Craigie (NSW)
"149.21758801587487,  -35.34838936919241",Crestwood
"149.7051441462214,  -32.50487147299741",Cross Roads (NSW)
"152.9191736957491,  -29.627269338123625",Crowther Island
"150.2643161612141,  -31.1789626324808",Curlewis (NSW)
"153.37626139002097,  -29.08647182343977",Doonbah
"151.24355787867466,  -33.87757905695481",Double Bay
"152.87864618653197,  -28.712545494998913",Doubtful Creek
"149.30313400571742,  -32.031624277237235",Dunedoo
"151.64938303003368,  -32.61493622946485",Duns Creek
"153.52829745535558,  -28.30445842558622",Duranbah
"151.18151595512722,  -33.75532979385732",East Killara
"151.22574853248108,  -33.9450274380465",Eastgardens
"149.9557209044093,  -37.13757026222615",Edrom
"151.17103377161362,  -33.90001380404975",Enmore (Inner West - NSW)
"149.18254453556557,  -35.408705409410096",Environa
"147.46749935125587,  -35.08419266274716",Eunanoreenya
"150.7608020002913,  -34.71318096263494",Foxground
"152.7736188922316,  -28.620290078471236",Ghinni Ghi
"146.51433845610782,  -34.80596853014322",Gillenbah
"151.10888244416748,  -32.45575398416906",Glennies Creek
"151.42189585740337,  -33.3521487614273",Glenning Valley
"148.82187416958305,  -34.90237764564425",Good Hope
"145.73684795036166,  -33.947321842471446",Goolgowi
"148.0602306029231,  -32.937446320797115",Goonumbla
"153.0714158443836,  -28.486946200416586",Green Pigeon
"149.52352900085478,  -32.36848674404266",Gulgong
"151.01148598197648,  -33.822213737743425",Harris Park
"153.2471783292026,  -29.413758460317204",Harwood
"150.86360796399248,  -33.91815921992677",Hinchinbrook (NSW)
"148.91306154204835,  -36.62593670374675",Ironmungy
"149.7394242611638,  -30.497702417151892",Jacks Creek
"152.78457515933223,  -31.59081616619322",Jolly Nose
"150.53994801098062,  -31.919474473197727",Kars Springs
"150.80086787723812,  -34.021622661511614",Kearns
"151.631198887661,  -30.582163239031726",Kellys Plains
"152.6179803355729,  -29.101230079453508",Keybarbin
"148.24373945613868,  -35.205805229392205",Killimicat
"151.5568428344316,  -33.17102945511027",Kingfisher Shores
"149.32684470003753,  -33.490474903073604",Kings Plains (Blayney - NSW)
"150.72491284228,  -33.764448005370234",Kingswood (Penrith - NSW)
"153.41021250287966,  -28.541557668366824",Koonyum Range
"151.74212484274446,  -32.86349892394689",Kooragang
"143.44036828146665,  -35.128526716243975",Koraleigh
"150.70108324042437,  -33.55035783939221",Kurmond
"152.56808767865098,  -31.78177965368469",Langley Vale
"151.14680493111848,  -33.89489472696723",Lewisham (NSW)
"151.04804262697886,  -33.86189937281192",Lidcombe
"149.78176011206034,  -36.94701106263696",Lochiel (NSW)
"153.25964665803167,  -28.835979705837563",Loftville
"149.2383360998011,  -33.21028558096511",Lower Lewis Ponds
"153.43713248755517,  -28.51399412556313",Main Arm
"152.17457082996376,  -32.30628475127425",Markwell
"150.989038609653,  -33.820603837908585",Mays Hill
"150.15308316381567,  -33.470194838984796",McKellars Park
"151.5720813893927,  -31.688503685494126",Mernot
"150.79252634981404,  -31.923266450806523",Middle Brook
"150.98143746595116,  -33.93885331017321",Milperra
"150.04766365263998,  -31.1420912514483",Milroy (Gunnedah - NSW)
"151.21133670154055,  -33.847248059801444",Milsons Point
"151.6186104799073,  -31.98301223574261",Moppy
"151.10634795852852,  -33.841454415435415",Mortlake (NSW)
"152.67080514712467,  -31.390653448719892",Mortons Creek
"151.24468442649035,  -33.82916657632496",Mosman
"151.11475310792096,  -32.64368596955619",Mount Thorley
"150.8429368298914,  -34.549717476207185",Mount Warrigal
"149.82448524528942,  -32.388427268131366",Munghorn
"146.26108461820397,  -34.40710870240653",Murrami
"142.38609942314062,  -31.122600215230257",Mutawintji
"150.6761522943988,  -35.02288369684546",Myola (NSW)
"147.6431598626914,  -31.862304136087488",Nevertire
"149.5591594898734,  -36.96110990257273",New Buildings
"151.79096205898202,  -32.924984736066136",Newcastle East
"152.92744915685088,  -30.63158324460454",Newee Creek
"151.35473256770968,  -33.41817383396384",North Gosford
"150.60571257759878,  -34.881959815218075",Nowra
"151.6980396177964,  -32.7228450664463",Osterley (NSW)
"142.17026219466365,  -30.44384927318678",Packsaddle
"153.27260823238714,  -29.469551851675213",Palmers Channel
"152.47750289740878,  -31.356904000107455",Pappinbarra
"151.05480412935404,  -33.975193368342055",Peakhurst Heights
"151.40880897849425,  -33.45620765315998",Picketts Valley
"141.88994451787661,  -34.022065822903095",Pomona (NSW)
"153.49375428157546,  -28.67126430732089",Possum Creek
"148.56165037200765,  -35.95864578893893",Providence Portal
"150.69358019870347,  -34.91750072001977",Pyree
"151.57901943636824,  -33.038306523705536",Rathmines
"148.9801075022488,  -34.135418819586164",Reids Flat
"153.0366026438074,  -30.442666425464527",Repton
"150.75296254727854,  -33.57897990643606",Richmond Lowlands
"152.01829031330533,  -28.625877972159955",Ruby Creek
"149.99968688417096,  -32.755268189559324",Rylstone
"150.8909133674862,  -33.91718089596362",Sadleir
"152.93188116758057,  -30.996554660523813",Seven Oaks
"148.9807006638806,  -35.959149940228926",Shannons Flat
"151.06892353220113,  -33.47021056875544",Singletons Mill
"148.5221313868782,  -36.15195754103413",Snowy Plain
"151.56367669268786,  -32.74669388317027",South Maitland
"149.76254523012622,  -36.86490710927424",South Wolumla
"146.95673674393382,  -36.02546852702712",Springdale Heights
"149.03876828259374,  -33.38633633613429",Springside (NSW)
"150.7846837077509,  -33.797298643365636",St Clair (Penrith - NSW)
"151.9346382257469,  -32.69293063914081",Swan Bay (Port Stephens - NSW)
"147.03589294512622,  -35.946154798623745",Table Top
"149.10301990469134,  -35.609951857178544",The Angle
"152.40415216584117,  -31.94578298879106",Tinonee
"153.12934231002745,  -29.655031439180416",Tucabia
"146.7103716108009,  -33.39364774493698",Tullibigeal
"149.88732318628672,  -30.454472515907888",Turrawan
"153.53547911989284,  -28.178487184771228",Tweed Heads
"152.1822440510838,  -28.622578977939614",Undercliffe
"152.749267863276,  -29.566412357894816",Upper Copmanhurst
"150.70632693946732,  -31.919677749721725",Upper Dartbrook
"152.67445363288846,  -30.77705176530134",Upper Taylors Arm
"153.4763153495409,  -28.871647855553622",Uralba
"151.25584901773524,  -33.90143146080957",Waverley (NSW)
"146.58523907757308,  -33.85867978148354",Weethalle
"153.02901213489636,  -29.897054184898703",Wells Crossing
"151.07713022384843,  -33.82762213429751",Wentworth Point
"150.73217534498693,  -33.74164259955081",Werrington Downs
"151.28786814675527,  -32.440720467920194",Westbrook (NSW)
"151.0713859102874,  -33.71172232120926",Westleigh
"151.7568662035252,  -32.91927160499034",Wickham (NSW)
"152.388805472834,  -30.966363367929603",Willi Willi
"150.73062642998013,  -31.63832573186924",Willow Tree
"150.65391930330304,  -30.68940001991302",Wimborne
"150.05385201947348,  -35.26401742271456",Wog Wog (Queanbeyan-Palerang Regional - NSW)
"150.183371505682,  -35.801217510575974",Woodlands (Eurobodalla - NSW)
"149.60361656556788,  -34.81367439588019",Yarra
"147.57809803914748,  -33.13543182124731",Yarrabandai
"149.17480589026943,  -31.691807877390897",Yarragrin
"153.498203798777,  -28.47900068744267",Yelgun
"153.2573661220932,  -28.318555350449373",Zara
"142.61172317470766,  -38.393522206667065",Allansford
"144.28470027035377,  -37.93803309534666",Anakie
"142.68032166177136,  -36.23824693033058",Areegra
"144.0960627274089,  -36.468721823585504",Auchmore
"145.0780835487808,  -36.752205530798214",Bailieston
"144.66921487766842,  -36.29343675500502",Ballendella
"146.30703481360356,  -37.832076400050795",Baw Baw (Vic.)
"145.58057291703918,  -38.176081355097025",Bayles
"145.3452757890466,  -35.885701405866754",Bearii
"145.35530738370366,  -37.91211741461426",Belgrave
"147.8352361620981,  -37.12059489349357",Bindi
"146.8652147360116,  -36.421600497556774",Bruarong
"148.1447567383617,  -37.41613215314078",Buchan
"144.16773569952872,  -37.53918848222396",Bunding
"145.05961948716904,  -37.69860720347798",Bundoora (Vic.)
"146.00064164501123,  -36.34008383201227",Bungeet West
"144.95947387419923,  -37.66374360744316",Campbellfield
"146.48567711358328,  -36.00263759974291",Carlyle
"145.05553995583423,  -37.89332348214442",Carnegie
"141.33173210798586,  -37.60312196347599",Casterton
"143.13158908652045,  -36.16677834443706",Chirrip
"144.96769799714386,  -37.7269592249747",Coburg North
"144.2257336418424,  -37.531266289533434",Colbrook
"145.20279952671288,  -38.3523877501493",Crib Point
"142.2285558358419,  -37.77680642898519",Croxton East
"143.18319104664403,  -35.942314636254956",Dumosa
"145.15915181299226,  -37.71739835996182",Eltham (Vic.)
"141.81292492489274,  -37.323671041482896",Englefield
"146.38002562023573,  -37.97733765726245",Erica
"146.56809004259833,  -36.38390898499565",Everton Upper
"144.9694290467945,  -37.70481496665689",Fawkner
"144.41012017150933,  -37.36761481531153",Fern Hill
"143.86347345003585,  -36.228179396911855",Fernihurst
"146.62857234283257,  -38.25059070943759",Flynns Creek
"147.09963891119173,  -38.384134760162915",Giffard
"143.00748493435134,  -38.408717569475314",Glenfyne
"142.9671108308773,  -38.1643788546591",Glenormiston South
"142.52359585608912,  -37.69804560095911",Glenthompson
"144.80307577244758,  -37.256078278695746",Goldie
"147.6719408326282,  -37.75694831691343",Granite Rock
"143.06572685791824,  -36.60439881764934",Gre Gre North
"143.89500907571514,  -37.75780949090785",Grenville
"144.3275657875378,  -38.121883028954244",Hamlyn Heights
"146.46251889531854,  -38.3169169986793",Hazelwood South
"144.74172582966673,  -37.69045358268551",Hillside (Melton - Vic.)
"146.0050146680647,  -37.14223995414775",Howes Creek
"144.26375655140518,  -36.7547863694055",Ironbark (Vic.)
"142.16863402004833,  -34.25252715419382",Irymple (Vic.)
"144.96036827091342,  -37.525891901466366",Kalkallo
"142.85012417294118,  -35.92605301514884",Karyrie
"141.4000490070065,  -37.75198390841941",Killara (Glenelg - Vic.)
"145.14854449901756,  -36.73155583235191",Kirwans Bridge
"144.59153915405432,  -37.66087761362118",Kurunjang
"145.0365873462458,  -36.327190393494845",Kyabram
"142.81500999858008,  -38.36807209685167",Laang
"144.78717209158657,  -37.83000440638356",Laverton North
"143.71928701785976,  -37.41936232525291",Learmonth (Vic.)
"145.47430653316232,  -36.343976588306894",Lemnos
"147.46628998913215,  -37.79914275148979",Lindenow
"141.48215728072557,  -37.99728520198598",Lyons (Vic.)
"145.07065043671423,  -37.721129426739346",Macleod (Vic.)
"144.58134122822273,  -37.86698173015529",Manor Lakes
"148.6873658632314,  -37.77167244300656",Marlo
"145.73237453964435,  -36.76263406612593",Marraweeney
"141.85798997431826,  -37.4867841772358",Melville Forest
"142.65167411501693,  -38.43586730461484",Mepunga West
"147.44073580392018,  -37.6546278546653",Merrijig (East Gippsland - Vic.)
"142.33669794931808,  -37.98760949562501",Minhamite
"142.4678344432938,  -38.04972814007693",Minjah
"142.64612403084053,  -36.45659355315887",Minyip
"146.3967593909668,  -37.11523820384023",Mirimbah
"142.39536893043496,  -37.41146486248399",Mirranatwa
"144.12729288190644,  -38.26890486715389",Modewarre
"145.40914354121352,  -36.675453309703315",Molka
"142.53184606658246,  -37.04637079468507",Mount Dryden
"143.0665469605546,  -36.01957332743932",Narraport
"141.6477813564084,  -36.073928580206314",Netherby (Vic.)
"147.7489256540574,  -37.812848352010164",Nicholson (Vic.)
"143.2926568111233,  -37.125258665016766",Nowhere Creek
"145.44684577570982,  -36.411529135411946",Orrvale
"143.1488318350611,  -35.274849085610356",Polisbet
"145.0004687413769,  -37.85178234290696",Prahran
"145.2797725574175,  -38.47882634787751",Rhyll
"142.71205708342754,  -36.833205218586315",Riachella
"145.06961329910013,  -37.739529703012764",Rosanna
"145.1589846190901,  -38.381341819377035",Somers
"143.30738899889695,  -38.330843721123955",Stonyford
"144.91703994098216,  -37.733181765362325",Strathmore (Vic.)
"146.07723247298478,  -36.34466092663739",Thoona
"149.07160721161543,  -37.607389199933785",Tonghi Creek
"145.87424641692735,  -38.25776830583795",Torwood
"148.19089017992414,  -37.780885946039795",Tostaree
"145.19921612654556,  -37.83833693179755",Vermont
"146.06907204043748,  -37.87158967591137",Vesper
"145.8820539879935,  -38.16902324673749",Warragul West
"145.88938270851924,  -36.704577268254546",Warrenbayne
"145.70806900943572,  -38.57576428654544",Wattle Bank
"144.19561532136467,  -36.6529658003676",Woodvale (Vic.)
"146.3823023683093,  -38.60573101201421",Woorarra East
"145.68159855156296,  -36.28070203238726",Yabba South
"142.43402702569233,  -38.28615637289468",Yarpturk
"145.56817862939914,  -35.9472476433551",Yarroweyah
"146.24848224519891,  -20.082135263114257",Alabama Hill
"152.22192152240038,  -25.232479693841913",Apple Tree Creek
"153.11649756393473,  -26.781584076268565",Aroona
"150.5081880732135,  -25.99989311512971",Auburn (Qld)
"147.40648992372346,  -19.56800388917449",Ayr
"151.80549676880514,  -28.86345647643528",Ballandean
"146.18448684708378,  -18.88504610605874",Bambaroo
"149.91746806901114,  -24.313265794886846",Baralaba
"152.97795310682636,  -27.46003496501794",Bardon
"147.23682216337488,  -19.56837822650529",Barratta (Qld)
"145.98837107114775,  -17.67143563221737",Basilisk
"148.85416848815433,  -25.64048877134916",Beilba
"152.55097760642911,  -26.480374308854163",Bella Creek
"152.53828928965464,  -26.129366535560138",Bells Bridge
"152.15047729333082,  -26.852940140524726",Benarkin North
"152.03225465156385,  -27.57831321840895",Blanchview
"151.6641451647348,  -25.577497564811043",Bon Accord
"151.6140080723395,  -27.22650041498407",Brymaroo
"152.61740305998603,  -28.040949395145127",Bunjurgen
"139.63330027623905,  -17.8520796744122",Burketown
"152.97494072731877,  -27.06315845444985",Caboolture
"145.42577085070187,  -16.053281942481895",Cape Tribulation
"151.7664382501377,  -24.282675678407323",Captain Creek
"150.32147001756863,  -24.774826907422014",Castle Creek (Qld)
"153.184444221121,  -27.839638983649625",Cedar Creek (Logan - Qld)
"152.58457441180568,  -27.79804628815885",Coleyville
"149.25374949146743,  -22.069000470214053",Collaroy (Qld)
"153.05034329899834,  -26.885970532145734",Coochin Creek
"146.0335476169198,  -17.697674951886356",Cowley Creek
"146.75286476778285,  -19.305445610465444",Cranbrook (Qld)
"151.82062571063275,  -28.16724765195866",Cunningham (Qld)
"151.81813546054266,  -27.45604094695082",Cutella
"151.92438838438736,  -27.60636924115432",Darling Heights
"151.83319658629958,  -25.138842110186143",Doughboy
"150.93923059247223,  -26.309590188789024",Durah
"153.0897469029684,  -27.432200299270047",Eagle Farm
"145.98293333960723,  -18.237458655500685",Ellerbeck
"152.58946746927657,  -27.957139534570494",Fassifern (Qld)
"145.36926472983612,  -16.243574448736403",Forest Creek
"147.40408010764463,  -19.74205382696787",Fredericksfield
"152.1521503501743,  -28.134805261929987",Freestone
"151.59481079390213,  -24.843246294965997",Gaeta
"151.94672778679384,  -24.97790167888685",Gin Gin (Qld)
"151.77214085214706,  -25.602441742449322",Ginoondan
"153.09860060977184,  -27.08627945445848",Godwin Beach
"149.91471931615015,  -23.551189934005507",Gogango
"148.12798380016824,  -23.31204116407818",Gordonstone
"150.45299838375678,  -23.45563302767909",Gracemere
"152.5966566014723,  -25.573701741422358",Grahams Creek
"146.77571910513888,  -19.285034015862692",Gulliver
"151.58295751111692,  -25.748230903063806",Harriet
"153.05911669150845,  -27.4636572187415",Hawthorne
"139.50135532599933,  -20.747305412318816",Healy
"152.13209358864245,  -27.524660740578625",Helidon
"151.96229730610278,  -28.074031016638806",Hendon (Qld)
"153.02199824740336,  -27.448879341073166",Herston
"153.06095978315284,  -27.52653816319356",Holland Park West
"145.28215297206316,  -27.427732432492885",Humeburn
"151.46288342698435,  -25.602101390952242",Humphery
"152.46740289043896,  -24.86838231119923",Innes Park
"145.57626255691284,  -17.411301975162147",Jaggan
"146.8842516276871,  -19.374047405763115",Julago
"150.19971605572383,  -23.46062856172813",Kalapa
"146.7181706116329,  -19.389780790334513",Kelso (Qld)
"152.9518535505553,  -27.41396022805378",Keperra
"150.02547740372933,  -24.69446805151908",Kianga (Qld)
"153.11258768319897,  -27.659396063312958",Kingston (Qld)
"152.825898996065,  -28.071483446031515",Kooralbyn
"140.38360164167412,  -21.094374186932647",Kuridala
"153.01702026033266,  -28.267623627223003",Lamington (Qld)
"152.73360043137026,  -27.623477590547683",Leichhardt (Qld)
"152.7252460656292,  -27.834044203134372",Limestone Ridges
"153.18545103318337,  -27.45562986333258",Manly (Qld)
"145.7491720964637,  -16.917123263112106",Manunda
"151.75256954321466,  -25.338770835007193",Mingo
"153.1232105352783,  -26.695691870807444",Minyama
"151.8215692855428,  -24.98819418815711",Moolboolaman
"152.9657452008821,  -26.762145950253544",Mooloolah Valley
"145.37376865335162,  -16.45110155824115",Mossman
"148.61611475998842,  -21.420234752071377",Mount Britton
"152.57865971348133,  -26.84252674300571",Mount Kilcoy
"152.41038569456754,  -25.810321345113813",Mount Urah
"153.34864055247033,  -28.09406204208879",Mudgeeraba
"152.46240754478788,  -27.204066793732387",Murrumba
"151.97033461845018,  -27.88362068315148",Nevilton
"145.91019586789707,  -17.49728545945605",Ngatjan
"151.89350418204688,  -27.841921840780376",Nobby
"150.85303257061474,  -26.618952585991302",Pelican (Qld)
"151.6787782383522,  -27.13719747247995",Peranga
"153.16373093155357,  -27.607244739961914",Priestdale
"151.39545139659288,  -27.96741052116439",Punchs Creek
"142.44775375653745,  -10.72356038960811",Punsand
"152.22796762879835,  -27.524485887288872",Ringwood (Qld)
"145.4076586088498,  -16.388477473455776",Rocky Point (Douglas - Qld)
"142.68999170114182,  -9.3960724003208",Saibai Island
"152.81926202275957,  -27.72416684182242",South Ripley
"152.02276371748343,  -27.95719038257299",Spring Creek (Toowoomba - Qld)
"148.59540107698348,  -20.33032175729462",Strathdickie
"138.33543317254123,  -23.395710215727895",Sturt (Qld)
"151.78578239515363,  -24.418152078936057",Taunton (Qld)
"152.36787546105606,  -27.093699167266582",Toogoolawah
"146.04700669029577,  -18.01021129025016",Tully Heads
"151.1982593792972,  -27.821086055827383",Turallin
"147.5436423738456,  -26.261569051621027",Tyrconnel
"151.7214146445442,  -26.969597322264153",Upper Cooyar Creek
"152.21961017178327,  -27.66054678085107",Upper Tenthill
"151.8743567981224,  -28.140736329575148",Upper Wheatvale
"145.9641761469139,  -17.468805078456743",Vasa Views
"152.99028524686432,  -27.921803826924485",Veresdale
"151.91623684686348,  -28.90202720484644",Wallangarra
"153.14213038771607,  -27.708399768336204",Waterford (Qld)
"151.8217408493018,  -27.551937864326433",Wellcamp
"150.84326244115078,  -28.444326289973763",Whetstone
"145.7333084255273,  -16.89514753732948",Whitfield (Qld)
"153.10124141123137,  -27.554839737831237",Wishart (Qld)
"149.8440542799283,  -26.294353590814616",Woleebee
"150.16778950318337,  -24.255732321832742",Woolein
"153.31991978945126,  -27.731469088058866",Woongoolba
"145.77939427769496,  -17.550415695297573",Wooroonooran (Cassowary Coast - Qld)
"149.79911563669054,  -27.02726691539181",Yulabilla
"138.38792660653004,  -33.048880012280776",Appila
"138.82420745988952,  -34.994639003866695",Balhannah
"138.4217593351291,  -30.81099510562845",Beltana
"140.60770759997294,  -34.27702566873184",Berri
"138.06821397828426,  -33.20147179993857",Bungama
"139.3291557734847,  -34.67908234032489",Cambrai
"138.3493061794521,  -35.42267154820898",Carrickalinga
"139.59016523889474,  -34.86822355678847",Caurnamont
"138.7644328750311,  -35.08425970635536",Chapel Hill (SA)
"138.62996138798377,  -35.115489967537286",Clarendon (SA)
"136.52200394400373,  -33.70293658693666",Cleve
"139.22093926876548,  -33.39974937776969",Collinsville (SA)
"140.88303144173713,  -37.292271136539135",Coonawarra (SA)
"138.6936846197683,  -34.69935491665202",Elizabeth Downs
"134.81095309605305,  -33.64774328474749",Elliston
"138.573137585327,  -33.62462846900381",Euromina
"137.61837221101464,  -32.95134447450012",False Bay
"138.80115085323294,  -35.166153409892466",Green Hills Range
"135.92350273786496,  -33.40282803725552",Hambidge
"140.69049873478912,  -37.541601044844455",Kalangadoo
"137.58105122598354,  -34.088492060291145",Kooroona
"140.7661620557715,  -37.48376000228829",Krongart
"140.15210945697018,  -36.181591550531586",Laffer
"136.74438314107198,  -32.941296906494195",Lake Gilles
"138.50079096967573,  -34.81685589558092",Largs North
"140.07361304626497,  -34.18459677449118",Lowbank
"138.60566559513282,  -34.98097405367142",Lower Mitcham
"137.03893534048316,  -33.69616654410285",Lucky Bay
"138.64067762795838,  -34.67001008357818",Macdonald Park
"138.70430578319895,  -34.421498066690916",Magdala
"138.55188266775934,  -34.85653581622167",Mansfield Park
"138.7315635468947,  -35.27189668091363",McHarg Creek
"136.93084029425773,  -33.47122242671045",Minbrie
"140.87546495524788,  -37.71070356060999",Mingbool
"135.41414674514155,  -34.24551970284864",Mount Drummond
"138.96702177063835,  -30.56345762896453",Nepabunna
"139.674959839429,  -34.71800534030653",Nildottie
"137.6314386660343,  -34.06167138630299",North Yelta
"139.6633154352905,  -34.3959087629284",Paisley
"139.3652798474662,  -34.9930903366247",Pompoota
"138.20306604951588,  -34.27209312627336",Proof Range
"138.73047581839322,  -34.59909526620115",Reid (SA)
"140.21913658342018,  -37.530306044694285",Rendelsham
"138.76505950316127,  -34.1675574902333",Riverton (SA)
"137.89491333858044,  -34.496299443056394",Rogues Point
"139.21637139686462,  -34.72434597857451",Sanderston
"138.4866951048155,  -35.17134990997104",Seaford Meadows
"138.5132292134389,  -34.8936323946413",Seaton (SA)
"138.6064994855605,  -34.87619804413939",Sefton Park
"138.458486928338,  -35.33073353765289",Sellicks Beach
"137.5864956795733,  -34.443811535481004",South Kilkerran
"139.6247233963039,  -34.570905733613706",Swan Reach (SA)
"135.71116475388834,  -33.41732589996036",Ulyerra
"137.63244958156477,  -33.944194160748246",Wallaroo (SA)
"137.80372127243103,  -32.44384260589769",Wami Kata
"139.8189480455212,  -36.9648488923167",Wangolina
"138.51711098648119,  -35.26960511850477",Whites Valley
"139.74097009966678,  -35.52869921780499",Yumali
"115.09958742000843,  -29.057966159343668",Allanooka
"115.14290984695424,  -28.686473083303635",Ambania
"117.88397370910285,  -33.608799717937515",Badgebup
"118.86175795062992,  -34.36243268266334",Boxwood Hill
"121.51629685637404,  -30.756353090884886",Brown Hill (WA)
"117.69640635298686,  -33.405222721793756",Bullock Hills
"115.63969581732216,  -33.325320969020005",Bunbury (WA)
"115.17070415090848,  -33.70439671605146",Carbunup River
"115.90636852562214,  -33.58150721723384",Charley Creek
"115.7533621365497,  -32.55164454216416",Coodanup
"115.99188193379385,  -32.19440813500448",Darling Downs
"117.76263413478473,  -34.03033454486616",Dartnall
"116.0831029925792,  -34.25547767255904",Deanmill
"117.42199240898596,  -34.7162477755528",Denbarker
"117.8393279105138,  -31.526527117490158",Doodlakine
"117.53289341429446,  -30.889949120280775",Dukin
"119.55994300204053,  -34.01412138783126",Fitzgerald River National Park
"116.00711641035971,  -31.98348340979828",Forrestfield
"116.207313973955,  -25.234109533528724",Gascoyne River
"115.94218237325,  -34.25585815247409",Glenoran
"118.40877021255967,  -34.68733216121454",Green Range
"115.77943688966846,  -32.08451587874333",Hamilton Hill
"116.13782755034501,  -33.248356505543555",Harris River
"120.8596361522648,  -23.546970203418052",Jigalong
"121.57955990427764,  -31.195159667719068",Kambalda West
"118.27535656647336,  -22.729384856964685",Karijini
"115.83141030711818,  -32.42986784378072",Keralup
"117.02680935020862,  -31.032014507905338",Koomberkine
"116.69464683052003,  -33.77880564256675",Kulikup
"116.63462878518652,  -30.936697383164937",Lake Ninan
"115.86239264298163,  -31.807563633915805",Landsdale
"115.90634209016818,  -31.967522061411312",Lathlain
"117.59239976492097,  -32.719024157291884",Malyalling
"115.73361476370647,  -32.526382984856866",Mandurah
"116.98984055891394,  -31.6056640779381",Meckering
"124.02020496803627,  -17.288339000528286",Meda
"115.78060727984591,  -31.9600507683006",Mount Claremont
"117.12829348573045,  -34.81479840328837",Mount Romance
"117.43163196174845,  -31.0701533789845",Nalkain
"118.20709845330445,  -31.382297364263803",Nokaning
"117.91612302461687,  -31.396479645124703",North Baandee
"115.1443919365294,  -21.672319269904165",Onslow
"121.02589028104447,  -30.314149359422736",Ora Banda
"115.08402540416296,  -33.6335876900507",Quedjinup
"115.85710968706661,  -32.57258784339965",Ravenswood (WA)
"116.98422627616783,  -34.59193118238066",Rocky Gully (WA)
"115.74467564199959,  -32.48986936232284",San Remo (WA)
"115.22874020237916,  -33.65555891260046",Siesta Park
"116.5513527265868,  -32.579322216840175",Springs
"113.82486441952881,  -26.865804274731317",Tamala
"116.43367737233895,  -31.57832320498396",Toodyay
"117.92775302418072,  -35.064586521614814",Vancouver Peninsula
"115.29973272430622,  -28.179726680056486",Wandana (WA)
"122.64033960728501,  -17.453932396195082",Waterbank
"115.7624460649514,  -33.19081076957057",Wellesley (WA)
"119.00901228184668,  -32.02194579820265",West Holleton
"119.62959980151201,  -33.57531831479466",West River
"117.52848181671867,  -32.80562636137907",Wickepin
"117.14391815416323,  -20.67413911304778",Wickham (WA)
"115.99017248351667,  -33.30373221314709",Worsley
"117.36709076650129,  -31.27319424489097",Wyalkatchem
"115.03633425034873,  -33.68691508493078",Yallingup
"115.02471048544759,  -29.228479461441903",Yardarino
"145.21259128605476,  -40.94201427850125",Alcomie
"147.37721965813597,  -42.874006667397495",Bellerive
"147.4128331209461,  -41.144859317581904",Blumont
"147.23368467296993,  -42.697276097575454",Brighton (Tas.)
"147.4491127474854,  -42.88786999418685",Clarendon Vale
"146.6843768600827,  -41.79033806157944",Doctors Point
"145.8901954415602,  -41.082658809191685",Downlands
"147.4013791470039,  -42.788363089733274",Dulcot
"146.30875015791955,  -41.232582251273726",Eugenana
"147.295684125712,  -41.15295863203611",Golconda
"146.90968714255442,  -41.52605608634043",Hagley
"146.26382004006152,  -41.166791776448065",Leith
"145.90911496910226,  -41.31911579386985",Loyetea
"146.29801695109484,  -41.250394537853246",Melrose (Tas.)
"147.362273877503,  -41.27844977682207",Myrtle Bank (Tas.)
"146.5486997472258,  -41.54456739716314",Needles
"147.06500230586536,  -42.7810501415292",New Norfolk
"147.25358231890533,  -41.229934184810546",North Lilydale
"143.90988679566823,  -39.948959556665855",Nugara
"147.26975418293,  -41.68792066082087",Powranna
"147.12831195677362,  -43.237637754202794",Randalls Bay
"147.18250876158615,  -41.4159079092536",Ravenswood (Tas.)
"147.49080194369168,  -42.96890219742719",Sandford (Tas.)
"147.67098893968577,  -42.99520049031546",Sloping Main
"147.10477647173886,  -41.44240224044585",Trevallyn
"147.6619307736862,  -41.184968680128605",Tulendeena
"147.01844303986965,  -43.16735857131309",Wattle Grove (Tas.)
"143.97919368710228,  -39.61518828536043",Wickham (Tas.)
"131.05931186733065,  -12.579493088073031",Bees Creek
"133.82240328369048,  -23.7424470643897",Ilparpa
"133.29284866984705,  -14.96148540199311",Jilkminggan
"131.00621910343816,  -12.492976798343504",Johnston
"132.4746712144229,  -12.866017962432904",Kakadu
"130.84622696874519,  -13.25315650966451",Litchfield Park
"131.4810100510349,  -12.693528580324422",Marrakai
"136.20194312233286,  -13.779379232052847",Milyakburra
"131.09405270992363,  -12.641841905887073",Noonamah
"130.99107613085172,  -12.507017447614897",Rosebery (NT)
"133.88246570181443,  -23.673183624055202",Stuart (NT)
"131.0235315253345,  -12.546837465095113",Virginia (NT)
"133.09103947379694,  -24.124216623051716",Wallace Rockhole
"130.8419132162112,  -16.45255448695743",Yarralin
"149.14475602129104,  -35.178933390930425",ACT Remainder - Gungahlin
"149.06494636469634,  -35.41591508371221",Greenway
"149.1162667780956,  -35.158861658926114",Moncrieff
"149.1502052599634,  -35.297880630093765",Russell
"96.8305261877498,  -12.173164468338358",West Island
"150.89163877588535,  -32.173812891355546",Aberdeen (NSW)
"147.46050375661673,  -33.864594950180035",Back Creek (Bland - NSW)
"150.49336487715047,  -32.574170193828266",Baerami Creek
"150.87122089876092,  -34.386527200882846",Balgownie
"146.51743141353987,  -35.81571321037052",Balldale
"153.5591051425765,  -28.83992916936152",Ballina
"151.76869352165485,  -32.93900599424296",Bar Beach
"153.1191694490557,  -28.533205294452785",Barkers Vale
"152.59862365641226,  -29.21891006784697",Baryulgil
"150.37975574334897,  -35.512021858109804",Bawley Point
"151.25562011695223,  -33.88085044807619",Bellevue Hill
"152.89884652504531,  -31.005831255937597",Bellimbopinni
"150.82225763272336,  -33.73003689470625",Bidwill (NSW)
"148.45438223606513,  -30.630132124390958",Billeroy
"147.94385874057824,  -33.994214673894724",Bimbi
"150.90346050283875,  -33.77408084607299",Blacktown
"150.61675089261556,  -33.743917154345475",Blaxland (NSW)
"151.61121241852626,  -32.996836095221575",Bolton Point
"152.3752176078199,  -31.620758866308822",Boorganna
"152.94980027117575,  -29.813999921746323",Braunstone
"147.89647910971922,  -34.11364539594222",Bribbaree
"151.15358945912794,  -33.958205772507235",Brighton-Le-Sands
"151.19817214155955,  -29.809363179047686",Brodies Plains
"150.41774187187434,  -33.7291759764016",Bullaburra
"145.8941100835646,  -35.14233302063256",Bundure
"149.02633077363052,  -36.68931841876991",Bungarby
"148.24815197741935,  -34.29158943271247",Burrangong
"149.95589766096407,  -32.702979961477965",Camboon (NSW)
"149.5847110718327,  -32.40465444781022",Canadian Lead
"147.7585690328197,  -31.96094140895334",Cathundral
"150.99946285868958,  -33.87887658942104",Chester Hill
"151.25661795319647,  -33.80772911590554",Clontarf (NSW)
"150.94130141190627,  -34.287586173225186",Coledale
"153.0335310264251,  -30.19342853900929",Coramba
"146.338712390774,  -35.62122223476034",Daysdale
"148.92100375002047,  -37.02612531766828",Delegate
"149.97146798278106,  -36.31924393927986",Dignams Creek
"147.74594611390063,  -34.607190363941115",Dirnaseer
"151.76139229636314,  -30.426366095417773",Donald Creek
"151.49732858539727,  -33.075822446589136",Dora Creek
"153.41829625796885,  -28.279073829087057",Dulguigan
"153.45908647168707,  -28.241894526446515",Duroby
"150.845793875915,  -33.8041081582691",Eastern Creek
"147.60745333758055,  -31.601808162997415",Eenaweena
"142.0280316218295,  -33.824128625651745",Ellerslie (Wentworth - NSW)
"151.43085801102345,  -32.86540071622906",Elrington
"149.48246894663598,  -33.4610058472518",Evans Plains
"149.39368339055778,  -33.525257529486716",Fitzgeralds Valley
"149.81433397403273,  -36.76725121608118",Frogs Hollow
"150.5419845184969,  -32.22492055616632",Giants Creek
"150.93489042879247,  -33.73569190873586",Glenwood (NSW)
"149.34718264810013,  -33.15369165493165",Gowan
"150.87307695226923,  -33.66312711594463",Grantham Farm
"150.96477538629333,  -33.84937037911901",Guildford West
"151.74481664113398,  -32.92247996402817",Hamilton (NSW)
"150.02638103979535,  -33.66619193351987",Hampton (NSW)
"150.73498316632688,  -34.02394306065766",Harrington Park
"153.02818548470245,  -31.05765284258549",Hat Head
"149.76375787004935,  -32.630803561899185",Havilah (NSW)
"151.96819716755383,  -30.61200038926148",Hillgrove (Armidale Regional - NSW)
"148.05575866794928,  -36.26846053699873",Indi
"148.23591206025966,  -36.042504356593405",Jagumba
"150.6767963694181,  -33.76797051116948",Jamisontown
"151.36060303402152,  -33.21728638529072",Jilliby
"149.05446246887718,  -33.16840732964029",Kangaroobie
"150.2123875278446,  -33.63231188181831",Kanimbla (NSW)
"153.26992740301975,  -28.716978169575793",Keerrong
"150.3028615154482,  -32.468564678866294",Kerrabee
"150.8440018937911,  -34.67372179142176",Kiama
"146.66036693560275,  -33.65741752515658",Kikoira
"149.62132066165316,  -33.370659852419166",Laffing Waters
"151.50311500964855,  -33.24444203426714",Lake Haven
"153.06920526401228,  -29.714616907775103",Lavadia
"151.5990938941716,  -32.43673808131158",Lewinsbrook
"149.17442285577047,  -36.96619015594708",Lords Hill
"148.97280606723191,  -36.54097162556452",Maffra (NSW)
"152.62622467514836,  -29.220288863552838",Malabugilmah
"151.18372438639744,  -33.32151722324682",Mangrove Mountain
"151.0008245007211,  -32.206945133356946",McCullys Gap
"150.96806297289234,  -33.83706260400319",Merrylands West
"150.87840642371384,  -33.92121253880226",Miller
"151.20434152043003,  -33.85999908612049",Millers Point
"150.31041405780138,  -35.41282880878487",Morton
"147.33960416919447,  -31.820740731119464",Mulla
"148.87503497397165,  -35.14358634423488",Mullion
"150.8689169146545,  -31.83620585018428",Murulla
"153.46508346476674,  -28.727090688119578",Nashua
"150.44594840775753,  -34.05931215224169",Nattai
"151.45357353920656,  -31.443433801017957",Niangala
"153.33840761497657,  -28.293538853355212",Nobbys Creek
"151.20856931717918,  -33.836644868239986",North Sydney
"149.77040557568014,  -37.067011510357794",Nullica
"150.50660492236028,  -34.07808638809698",Oakdale (NSW)
"151.5504121324385,  -32.7090647706579",Oakhampton Heights
"149.2458954964218,  -33.13679567026886",Ophir
"150.7304392718842,  -33.80829253918248",Orchard Hills
"151.08009884578087,  -34.003870137047336",Oyster Bay
"151.32566619167696,  -33.598467308267395",Palm Beach (NSW)
"151.00595010474066,  -33.81414908127032",Parramatta
"151.05603067106657,  -33.961329874059075",Peakhurst
"150.69372978866247,  -33.74545888497169",Penrith
"150.3632527438973,  -35.229860751202665",Pointer Mountain
"147.7747366929871,  -34.01583082655348",Quandialla
"150.8176680722426,  -34.01839929998941",Raby
"151.68194074456184,  -32.92559473768319",Rankin Park
"146.1633005989376,  -33.82311827206829",Rankins Springs
"150.77715810493578,  -33.72871150386149",Ropes Crossing
"150.21288046521215,  -35.81656576026109",Rosedale (NSW)
"150.90524809668798,  -33.48522034961612",Sackville North
"152.52360644269683,  -32.37867357856363",Sandbar
"152.41628889124166,  -32.22388406011319",Shallow Bay
"151.10540048852687,  -33.977446473555496",South Hurstville
"153.13366981739733,  -28.82891227460925",Spring Grove
"151.17629632431502,  -33.91586140638901",St Peters (NSW)
"151.72378128505832,  -29.834473530707868",Stonehenge (NSW)
"152.96601592974625,  -30.986882939291718",Summer Island
"151.04051642797552,  -33.79397104062983",Telopea
"148.79835778836048,  -30.911992779071245",Teridgerie
"150.16073976774177,  -29.785105895102596",Terry Hie Hie
"153.2774522467157,  -28.675918231683518",The Channon
"147.21278341980224,  -34.95423215368601",The Gap (Wagga Wagga - NSW)
"148.07308294632992,  -33.231245132017854",Tichborne
"153.3747160518966,  -28.2550592378502",Tomewin
"151.20370711071146,  -30.44578226511324",Torryburn (Uralla - NSW)
"151.43364207346795,  -33.3758646063581",Tumbi Umbi
"151.8739048300652,  -32.63985657950531",Twelve Mile Creek
"153.2295868284552,  -28.359796957864887",Tyalgum
"151.51245245281274,  -30.65590328254173",Uralla (NSW)
"146.68843309030083,  -35.494803968891496",Urangeline East
"152.89747956397855,  -31.086757566050316",Verges Creek
"150.272803526885,  -30.707504885120006",Wean
"153.11892766957456,  -29.132639814344103",West Bungawalbin
"150.80486335244598,  -33.75593032125254",Whalan
"152.74688076364407,  -30.146370699990165",Wild Cattle Creek
"148.4204133465747,  -34.1129581964618",Wirrimah
"149.81351401641834,  -36.809975050922404",Wolumla
"148.23108664337946,  -34.43483591427461",Wombat
"151.1717709484679,  -33.84192735514868",Woolwich
"151.29011060337808,  -33.50403424634002",Woy Woy
"152.94865510830783,  -30.821002769408384",Yarrahapinni
"149.30948463421151,  -35.46442860213748",Yarrow
"150.63385108673305,  -33.69624105782366",Yellow Rock (Blue Mountains - NSW)
"150.27042589854818,  -34.15279282955088",Yerranderie
"153.06970864550382,  -28.93682867361396",Yorklea
"148.6694156134869,  -32.86000268771525",Yullundry
"144.1542871656297,  -38.38081202771149",Anglesea
"143.51490144111403,  -36.885513023911656",Archdale Junction
"142.2181169699655,  -36.22621084106936",Aubrey
"144.41375512895365,  -36.58636720193718",Bagshot North
"143.82497395507886,  -38.004327068661496",Barunah Park
"148.89402528473667,  -37.2084589315794",Bendoc
"142.9332073219517,  -34.96251016792522",Bolton
"143.01491134429486,  -38.22862284466639",Boorcan
"146.12055970994032,  -36.274770397565455",Boweya
"143.36971308090568,  -37.78671752504143",Bradvale
"145.01666915515986,  -37.917287843439816",Brighton East
"146.01468686303105,  -38.67052453594133",Buffalo
"145.59810093661088,  -36.465056234894654",Caniambo
"149.15885383070497,  -37.58903235322615",Cann River
"144.87407244690598,  -38.37210628055024",Capel Sound
"145.02633647896369,  -37.88404185016841",Caulfield
"146.4222057215196,  -36.9214253835026",Cheshunt South
"143.5432256956211,  -38.33255531736997",Colac West
"144.37492990329787,  -38.06720017460126",Corio
"145.28387028205555,  -37.771858929775746",Croydon North
"145.22084784722628,  -38.027968919258754",Dandenong South
"143.90239748511095,  -38.40255128955546",Deans Marsh
"147.00050942659527,  -36.48226382300167",Dederang
"146.36658049256678,  -36.509497579564204",Docker
"145.89077899405936,  -38.11914411428367",Drouin East
"142.96867167119171,  -37.887540773397625",Dundonnell
"144.98548575849955,  -37.880793439893445",Elwood
"143.5891457359621,  -37.414960871007935",Ercildoune
"143.81308682828902,  -35.683190381192794",Fairley
"149.54375531395945,  -37.48238441123086",Genoa
"146.0637087457029,  -37.148546681966934",Goughs Bay
"144.89679360642504,  -37.704090957872545",Gowanbrae
"141.80943298443287,  -38.134718868188074",Homerton
"145.53721975045175,  -38.330351506639126",Jam Jerrup
"148.4019750944395,  -37.6614928331771",Jarrahmond
"146.4238540205294,  -38.40003791933089",Jumbuk
"144.85757584025058,  -37.739374952810266",Keilor East
"144.7928934043911,  -37.68156775696114",Keilor North
"146.92039081028682,  -38.1218341867391",Kilmany
"141.66955230164643,  -37.48051952654673",Konongwootong
"144.10941782555125,  -35.643642380369585",Koondrook
"144.43638168486277,  -37.28590937116477",Kyneton South
"142.62618920754738,  -37.13501807789677",Lake Fyans
"142.93610960824742,  -34.722127821882985",Lake Powell
"144.3548671191082,  -37.252015078601275",Lauriston
"145.96505544013118,  -36.812161483342365",Lima East
"144.5068865962492,  -37.65585001865395",Long Forest
"144.33370070712658,  -38.13761240825826",Manifold Heights
"142.86291467243646,  -37.428651681133864",Maroona
"142.6758444215797,  -38.45796259032224",Mepunga
"141.54810185153397,  -37.728367260585614",Merino
"143.77985906002883,  -37.506933501828314",Mitchell Park (Vic.)
"141.79055721418564,  -36.723780659050064",Mitre
"147.39820564815045,  -36.63120255109707",Mitta Mitta
"145.72359827097603,  -38.176857731902736",Modella
"144.11800919119844,  -37.76670160063781",Morrisons
"147.63123160290942,  -36.0006814760733",Mount Alfred
"145.07008821044843,  -37.896720147164075",Murrumbeena
"142.6795896606004,  -35.3119160682227",Nandaly
"147.8399609983391,  -36.5069790211996",Nariel Valley
"144.8848360160187,  -37.73979919719835",Niddrie
"145.9731327234329,  -38.16957671980425",Nilma
"143.33573129911554,  -35.18348563165594",Nyah West
"143.81461068535174,  -38.20260595666429",Ombersley
"145.4986231746927,  -38.13835602471457",Pakenham South
"143.6910621676134,  -35.40338524244993",Pental Island
"147.27444631956035,  -37.99109773206173",Perry Bridge
"144.6626991472897,  -37.66451619897848",Plumpton (Vic.)
"145.83825019340657,  -38.61588756765636",Pound Creek
"144.85889184340118,  -37.11357228427878",Pyalong
"144.73646849453365,  -37.77531220643745",Ravenhall
"144.18503606527145,  -36.51752896928365",Raywood
"145.01609412590432,  -38.36354277611725",Red Hill (Vic.)
"144.3557553502128,  -38.123798786292014",Rippleside
"144.68054874314495,  -36.36140213944453",Rochester (Vic.)
"143.74824466588274,  -37.92720304512699",Rokewood
"144.12535356300288,  -37.36125019602538",Sailors Hill
"147.55421096129834,  -36.20306909957174",Shelley (Vic.)
"143.21357482646778,  -38.34368476229124",South Purrumbete
"143.3255703400108,  -36.62609512436283",St Arnaud East
"147.03432510927396,  -38.27738983193827",Stradbroke
"145.2702918521582,  -37.5385263377976",Strathewen
"143.3515226600017,  -38.37531203817576",Swan Marsh
"144.7653388431953,  -37.69972930410018",Sydenham (Vic.)
"146.7427941154835,  -38.6263772806294",Tarraville
"144.35203900512653,  -36.28762700585361",Tennyson (Vic.)
"144.26323644191316,  -36.120140857826854",Terrick Terrick
"145.2688983356935,  -36.48358794440007",Toolamba West
"146.08690869385114,  -37.77551304571451",Toorongo
"145.23904901972412,  -37.12090204910904",Trawool
"142.98245241565792,  -36.58340934365776",Traynors Lagoon
"145.09680033455598,  -38.298682585628356",Tuerong
"145.87557268092806,  -36.16165741330715",Tungamah
"145.18699080345118,  -38.265229130701485",Tyabb
"143.51328844454665,  -35.275939192590585",Tyntynder South
"145.7786631648674,  -36.501911660410755",Upotipotpon
"141.89220724428515,  -37.33360078428872",Vasey
"148.2934063439315,  -37.33766017583176",W Tree
"146.47435091311127,  -37.93398205772496",Walhalla
"144.9639076899856,  -37.4081423482115",Wallan
"146.05837559520344,  -38.80235230564732",Waratah Bay
"142.07341934400105,  -37.9838120166171",Warrabkook
"143.55722520483488,  -38.22514167756546",Warrion
"142.87520283327223,  -36.15161156947259",Watchem
"145.26227422861325,  -37.661650009183916",Watsons Creek (Vic.)
"143.4949156080683,  -38.63388326376733",Weeaproinah
"144.89357570624435,  -37.67502472822678",Westmeadows
"146.16435693916665,  -38.07312491265337",Willow Grove
"146.75423791399896,  -38.07707015536743",Winnindoo
"145.00501547007357,  -37.59094976503585",Wollert
"145.78653303351493,  -37.0561550916279",Woodfield
"143.46909248711245,  -35.26705121437392",Woorinen
"144.1051703209899,  -37.20230210592843",Yandoit
"144.06396546160815,  -36.574606756151546",Yarraberb
"145.13472521676843,  -37.63752122284802",Yarrambat
"143.64661817158213,  -38.36761697263216",Yeo
"144.67265809440352,  -17.391332054741778",Almaden
"145.87101909900522,  -17.135788335775825",Aloomba
"153.143733682017,  -27.040252172513647",Banksia Beach
"145.71107146123535,  -16.865030268505038",Barron
"145.38919624607013,  -19.751543232814196",Basalt (Qld)
"152.30039020230421,  -27.20318244786465",Biarra
"151.90399696653253,  -25.801961514312186",Blairmore
"145.26007414301765,  -16.00362612535979",Bloomfield
"151.4284753539866,  -25.392534503545733",Branch Creek
"153.36939769538057,  -27.079078172905707",Bulwer
"152.23285591209674,  -25.871292960339712",Calgoa
"151.21305203312403,  -23.84334752271119",Callemondah
"146.2602417981714,  -20.07684960728362",Charters Towers City
"152.60944589987065,  -26.140745790066045",Chatsworth (Qld)
"152.7759992462097,  -27.556658159907236",Chuwar
"152.43339777937038,  -27.18011408064026",Coal Creek
"151.56715135689117,  -24.43310054470041",Colosseum
"151.8341442936473,  -26.96538861948283",Cooyar
"153.3670672815353,  -27.12512754179408",Cowan Cowan
"151.2617449212715,  -27.153327014870786",Dalby
"145.62570116130712,  -17.15812858887138",Danbulla
"148.7227217007595,  -26.55552329973276",Dargal Road
"151.06007597225172,  -25.64891050185489",Dykehead
"153.04695685838814,  -27.48366322219431",East Brisbane
"151.8683201923258,  -27.032191948195216",East Cooyar
"152.15772096284155,  -28.40622962374188",Elbow Valley
"152.20376762470735,  -26.435347906065786",Elgin Vale
"152.80400763729662,  -25.27602046313427",Eli Waters
"148.40030979649873,  -21.165440803609727",Eungella Dam
"145.8966148441488,  -18.005038875431808",Euramo
"151.7583479531771,  -27.797731980208518",Felton
"145.99396054253472,  -16.93280325278436",Fitzroy Island
"151.636192534687,  -25.620435552631193",Gayndah
"143.69233222927488,  -19.277990548637256",Gilberton (Etheridge - Qld)
"147.1249445046455,  -19.4813727009393",Giru
"152.3243171451107,  -27.603509190477673",Glen Cairn
"150.13150708620037,  -26.40631780851824",Gurulmundi
"151.73383899640297,  -26.688122065295477",Haly Creek
"141.53678936278982,  -17.186707473726834",Howitt
"150.60145627860533,  -23.26139789266855",Ironpot (Livingstone - Qld)
"152.1765156794427,  -25.194191600388095",Isis Central
"152.52029972125197,  -26.686725210377418",Jimna
"145.38688838011436,  -17.509791440244072",Kaban
"153.37196560453881,  -27.638557697004423",Karragarra Island
"140.87230085510885,  -17.488189557766812",Karumba
"150.7809683776773,  -24.603754416522357",Lawgi Dawes
"152.0708992272469,  -27.507613914520874",Lockyer (Qld)
"153.1325920270137,  -27.676940515649655",Loganlea
"152.86675029661905,  -25.587609309941215",Maaroom
"146.00868248634418,  -24.88867704600124",Macfarlane
"150.7389154316986,  -23.704987491732595",Marmor
"151.74829345019918,  -26.154616309680634",Marshlands
"152.23869547368142,  -24.821542857248392",Meadowvale
"139.4971815728671,  -20.718093461599043",Menzies (Qld)
"149.60903483844052,  -24.26827192026358",Mimosa (Qld)
"151.5650183189149,  -24.33213955430017",Miriam Vale
"152.50540726649817,  -28.11179044649327",Moogerah
"152.02109452297137,  -26.243427426981448",Moondooner
"145.0849335162006,  -17.652105180463675",Mount Garnet
"144.3571356600142,  -18.21739915223203",Mount Surprise
"152.06331084302263,  -28.220435948288227",Mount Tabor
"152.54834740849353,  -27.77541737054216",Mount Walker (Qld)
"153.0321969285171,  -27.748161816826755",Munruben
"153.01059859503872,  -27.26768948645867",Murrumba Downs
"151.29823354885204,  -27.529348739011915",Nangwee
"146.94674070497618,  -19.36246027229653",Nome
"152.62436511097096,  -25.510036481980336",Oakhurst (Qld)
"153.23406428115084,  -27.79899854074261",Ormeau Hills
"153.07506284895484,  -26.623745743890886",Pacific Paradise
"145.66475882045196,  -16.74847951069466",Palm Cove
"148.81918505151052,  -21.330027613322013",Pinevale
"148.6854167289858,  -21.213876327754054",Pinnacle (Qld)
"153.1788051651833,  -27.37491509901641",Port Of Brisbane
"145.5026764878707,  -17.616930430212882",Ravenshoe
"153.041664165791,  -27.676669111685356",Regents Park (Qld)
"152.84170268181472,  -27.591483586037043",Riverview (Qld)
"152.39164603847598,  -24.81585684568362",Rubyanna
"146.75372362965052,  -19.184447685428232",Shelly Beach (Townsville - Qld)
"153.0599146722859,  -27.578141592274253",Sunnybank
"150.26392959176243,  -24.626836686624888",Tarramba
"150.5709420472476,  -24.577456315108382",Thangool
"150.9497378756832,  -23.627618247568503",The Narrows (Qld)
"152.4610614616241,  -25.595450040052224",Thinoomba
"152.59985764017736,  -25.731954203713116",Tiaro
"138.5823854070036,  -22.907992426090615",Toko
"145.9564089235003,  -17.519005972148424",Upper Daradgee
"152.69247621574354,  -26.17495657936277",Victory Heights (Qld)
"153.15836098401618,  -27.482261552407714",Wakerley
"147.45774602494416,  -19.83076154875412",Wangaratta (Qld)
"153.12689471147198,  -26.722744073045344",Warana
"152.57950513069255,  -26.952980231576728",Winya
"152.2032998705885,  -27.77571925412378",Woodbine (Qld)
"151.07639973203845,  -24.099088009545326",Wooderson
"153.03679211985764,  -27.492985723658705",Woolloongabba
"150.63293072196723,  -28.192766066403074",Wyaga
"152.20269100561913,  -28.1986120246658",Yangan
"150.6683839085139,  -28.557029324003604",Yelarbon
"138.56100862330186,  -34.9881164903074",Ascot Park
"138.72724398401573,  -34.810270872926885",Banksia Park
"136.70496623803797,  -33.17448654174382",Barna
"138.81867636423408,  -34.65693989015537",Barossa Goldfields
"138.6594367669336,  -34.94999048485933",Beaumont (SA)
"140.04597091591359,  -36.77802889121422",Blackford
"138.99461559919905,  -33.97023077282044",Brady Creek
"139.2470294291866,  -33.63749347857621",Burra Eastern Districts
"138.49127134346233,  -33.20063864625635",Caltowie
"139.5487276773184,  -35.11539428222696",Chapman Bore
"138.0626013107941,  -33.5070802172608",Clements Gap
"140.67553568142492,  -37.815068960208755",Compton
"137.82346296388664,  -34.44302105783126",Cunningham (SA)
"137.58471103396636,  -35.95399667196132","[""D'Estrees Bay""]"
"139.81918600543924,  -36.134132218888006",Deepwater (SA)
"137.92888079905575,  -34.34646077711288",Dowlingville
"139.0200328742995,  -34.4176035084084",Ebenezer (SA)
"138.80287688526363,  -35.09808667021311",Echunga
"135.669464342944,  -34.41993115521415",Edillilie
"138.60542880845352,  -34.860777978191244",Enfield (SA)
"139.10120965661014,  -34.172229483487406",Eudunda
"138.74856111934764,  -34.629025664684065",Evanston Park
"138.31602818061373,  -33.9251237305816",Everard Central
"139.36278042525748,  -34.8875839717047",Frahns
"138.5132322094607,  -34.91490248992033",Fulham Gardens
"140.33968617783893,  -37.393825727424066",Furner
"140.41982848031103,  -37.78392125102743",German Flat
"138.51496937968923,  -34.98068415981757",Glenelg (SA)
"138.51516585813678,  -34.98821621829349",Glenelg South
"138.76254355407156,  -35.51312145252118",Goolwa Beach
"138.4976408039276,  -34.89560073484353",Grange (SA)
"138.80921052292348,  -35.033657683486474",Hahndorf
"139.06467992034428,  -34.26257756672148",Hansborough
"138.4991191270588,  -34.91587026364943",Henley Beach
"138.51025504232612,  -33.039782643501134",Hornsdale
"139.39331437616536,  -35.26531969898692",Jervois
"135.32086093762524,  -33.66219596833484",Kappawanta
"135.95153928784563,  -34.88158955349081",Lincoln National Park
"138.8738794816084,  -34.899791293825935",Lobethal
"138.6367036704281,  -34.91125244753044",Maylands (SA)
"140.68802867519906,  -37.87318988522612",Moorak
"138.6861706974806,  -35.41775036149711",Mosquito Hill
"140.7902803522836,  -37.00369175586553",Mount Light
"138.93190525564083,  -35.54367731532956",Mundoo Island
"140.67417143566033,  -36.38552243840634",Mundulla
"138.54920807264432,  -34.94936912204757",Netley
"139.90411669127482,  -34.37343814669236",New Well
"138.59738038182866,  -35.00073234692724",Panorama
"140.27583514831005,  -35.481482144600854",Parrakie
"140.54098717451322,  -34.572920009343264",Pata
"134.4526667010379,  -32.33934426729465",Pimbaacla
"134.6739494708724,  -33.153130900627836",Port Kenny
"134.11227821714672,  -32.2365982206923",Puntabie
"140.44121869134617,  -34.43744074362222",Pyap
"139.92753515977046,  -34.16735124711305",Ramco
"138.64293275996445,  -34.78276581122844",Salisbury South
"135.6701441164378,  -34.85923963580562",Sleaford
"133.98702278155483,  -32.396624229259174",Smoky Bay
"138.95068927543545,  -34.373850837831995",St Johns
"137.17703108314544,  -35.661423663269645",Stokes Bay
"138.2740927872265,  -33.10924749197824",Stone Hut
"140.76909505765704,  -37.09590056204094",Struan
"139.30405425240426,  -35.15439890468373",Swanport
"139.95776624222228,  -34.119344569144644",Taylorville
"138.621473825388,  -35.23916340658159",The Range (SA)
"133.6536314881258,  -32.145660617658756",Thevenard
"134.3155365425478,  -26.88830203091314",Tieyon
"135.78988881654004,  -34.644422784737706",Tootenilla
"138.8672080845603,  -33.237700524418784",Whyte Yarcowie
"138.5874565404019,  -35.307085044384685",Willunga Hill
"134.57086888644523,  -33.15041604927322",Witera
"140.27261066542184,  -34.48959131854021",Wunkar
"138.82453774144082,  -32.49973761183066",Yalpara
"137.59623547474922,  -34.97881133911651",Yorketown
"115.86540021784819,  -32.218686620942606",Anketell
"115.93781981941353,  -31.868031037990686",Beechboro
"123.10334499486343,  -33.68816977151121",Boyatup
"117.36612351839109,  -33.495989921906514",Boyerine
"122.23414381625297,  -17.959756849391525",Broome
"118.77241809366424,  -33.00389218262428",Buniche
"115.88371029564607,  -29.72435259943864",Carnamah
"116.83565310799067,  -31.873408914043186",Cold Harbour
"117.53359148637801,  -33.237750787714724",Collanilling
"121.25690447980293,  -33.7123438882851",Coomalbidgup
"115.84859641290353,  -31.365426978664264",Coonabidgee
"115.09051011306896,  -33.85863358775332",Cowaramup
"115.79651322995993,  -33.47001199274043",Crooked Brook
"117.88765574976996,  -27.448467880290625",Cue
"115.9528666747131,  -34.08950651769861",Donnelly River
"115.81568679984751,  -33.55795296100509",Donnybrook (WA)
"115.25979618639059,  -29.824013543324146",Eneabba
"116.1418398585706,  -32.76778940375331",Etmilyn
"116.07121891164282,  -33.52619417868419",Glen Mervyn
"115.76096830886662,  -32.425017372892235",Golden Bay
"115.81250502010563,  -31.85105503144842",Hamersley
"115.83106489539766,  -33.37809600171611",Henty (WA)
"116.14207180965626,  -32.356172917752566",Jarrahdale
"116.25273141349004,  -31.46465997034007",Julimar
"114.81015246095076,  -24.556686474552855",Kennedy Range
"113.69553069143667,  -24.863948874317437",Kingsford (WA)
"117.91703338388365,  -31.11290762441483",Kununoppin
"119.66521939484794,  -32.938563483763616",Lake Camm
"119.66138743070098,  -33.098979509353654",Lake King
"123.37840387179291,  -27.174360774000014",Lake Wells
"115.50414680106417,  -33.608658005710744",Ludlow
"127.02323147665629,  -31.913570353423655",Madura
"115.86968160587129,  -32.01336190605485",Manning
"117.17807781261568,  -31.056258577947318",Minnivale
"122.20232503762303,  -17.98302207909675",Minyirr
"116.57439875225603,  -31.74895460032114",Mokine
"116.0410314619285,  -33.16881831953592",Mornington (WA)
"117.37404542155708,  -34.81837523575684",Mount Lindesay (WA)
"126.83564024408409,  -18.003353689503093",Mueller Ranges
"116.02053661209845,  -26.498345125200505",Murchison (WA)
"117.55978834670617,  -33.75650757013463",Murdong
"117.25058620769151,  -32.96347130270587",Narrogin Valley
"115.7326576554585,  -32.67576875213966",Nirimba (WA)
"115.74716833905543,  -32.03714473419257",North Fremantle
"117.91238366762003,  -33.12348847849244",North Moulyinning
"114.08365352033948,  -21.902938783041247",North West Cape
"116.71566587428471,  -30.20191670168381",Nugadong
"115.98975808216144,  -32.13603759182538",Seville Grove
"116.26251227552233,  -33.38490926945865",Shotts
"116.19844614498551,  -34.369207138196934",Smith Brook
"117.49127769906528,  -31.726765820698",South Tammin
"115.59734743023786,  -33.46947380575859",Stratham
"117.80462099910672,  -30.19417963611734",Tampu
"127.61595700670917,  -20.330113857413632",Tanami (WA)
"117.43599446588861,  -34.39584782548386",Tenterden (WA)
"117.78765668053258,  -22.68734960238462",Tom Price
"115.76540490903565,  -32.3162041404889",Waikiki
"116.11816518928667,  -30.267363879147563",Watheroo
"118.21233847678317,  -30.755001957353738",Wattoning
"118.5960072102185,  -20.36121500231166",Wedgefield
"115.91102013363916,  -33.46271105106177",Wellington Mill
"115.81782258182774,  -31.93310885243521",Wembley
"115.84619790091287,  -31.94546207469811",West Perth
"117.20410514732133,  -35.00116141429333",William Bay
"115.63049778074898,  -33.36804678025079",Withers
"116.69430436322268,  -30.833465580465763",Wongan Hills
"115.79247653510875,  -31.908195036869305",Woodlands (WA)
"117.26503347062305,  -31.79252962233964",Youndegin
"148.26144330637504,  -41.41591878437306",Beaumaris (Tas.)
"145.32289464577875,  -40.870687425968946",Black River (Tas.)
"146.96668525749297,  -41.3824927398046",Bridgenorth
"147.2307935161729,  -42.725107067692484",Bridgewater (Tas.)
"147.37249864783018,  -41.02931020376337",Bridport
"148.2229374393539,  -40.412861920434395",Cape Barren Island
"147.29823793542067,  -42.83392058314415",Derwent Park
"146.77473128094744,  -41.102785876786676",Kelso (Tas.)
"147.21243521590563,  -42.524606284983754",Kempton
"147.2497485979498,  -42.82996063075362",Montrose (Tas.)
"145.8690583225604,  -41.1017762136748",Mooreville
"145.8921676038369,  -41.04744649224244",Parklands (Tas.)
"147.20776150906025,  -41.60871610154995",Perth (Tas.)
"147.66950374011319,  -42.885809595578294",Primrose Sands
"146.87551908650266,  -41.434209740901835",Selbourne
"147.11877025028232,  -42.7856689632541",Sorell Creek
"147.64311333863006,  -42.40924667433639",Stonehenge (Tas.)
"147.86585278393835,  -43.060453989675146",Taranna
"147.3602341711855,  -42.43198836083804",Tiberias
"147.03627573791104,  -41.623008617206224",Toiberry
"145.8807094450936,  -41.236246750884696",Upper Natone
"146.44219856024424,  -41.18983168219326",Wesley Vale
"146.1187457985481,  -41.149235435444744",West Ulverstone
"147.97036632811182,  -39.84211519761199",Wingaroo
"136.46118570374674,  -13.98121251296015",Angurugu
"133.37908623534753,  -16.262024418066524",Daly Waters
"130.82989085846498,  -12.411596860907585",East Point
"130.94596276283585,  -12.485686264154847",Elrundie
"131.24028933745984,  -12.582407149308173",Lambells Lagoon
"130.89298401895664,  -12.400432775780851",Marrara
"130.88086935877615,  -12.388459487101514",Moil
"134.7441085967892,  -14.732526352826541",Ngukurr
"136.76804344496313,  -12.19993497467496",Nhulunbuy
"148.96290124551913,  -35.708287499731526",ACT Remainder - Rendezvous Creek
"149.14056809935576,  -35.307239186576616",Barton
"149.0946683020589,  -35.1670561116742",Casey
"149.17088713891127,  -35.321703541339986",Fyshwick
"149.1382724217697,  -35.415157408127136",Gilmore (ACT)
"149.03657760004197,  -35.247024919426195",Hawker (ACT)
"149.15997559752782,  -35.34890062764922",Symonston
"152.0276751158868,  -26.515703781681836",Sandy Ridges
"149.14273008840786,  -21.009487897593846",Shoal Point
"152.54164936147328,  -27.12047588284465",Somerset Dam
"152.2073965096342,  -24.964021940558855",South Bingera
"152.04713139254036,  -26.737635124633602",South East Nanango
"152.93326509052238,  -27.56550579066789",Sumner
"153.10284511252786,  -26.416712248044774",Sunrise Beach
"151.3830597591218,  -23.996752182129114",Tannum Sands
"152.67819189699443,  -27.94883823625724",Teviotville
"152.3585475576562,  -24.907423327835023",Thabeban
"139.4990956373965,  -20.72597229192483",The Gap (Mount Isa - Qld)
"152.10767383632836,  -28.20938549774796",The Hermitage
"152.94247753658348,  -25.8802068560527",Tin Can Bay
"149.86954605266897,  -28.416808815878966",Toobeah
"145.09153914602692,  -21.218752617882",Torrens Creek
"152.80047219886222,  -25.73271552876231",Tuan Forest
"152.84470288016286,  -27.84603239067027",Undullah
"146.1458865707565,  -25.927735043609413",Ward
"145.79038481699584,  -18.038592767954405",Warrami
"151.9928939677053,  -24.74432619719457",Waterloo (Qld)
"150.75369497701388,  -27.262768106045602",Weranga
"149.16250840877944,  -21.151536040699213",West Mackay
"153.0472016141063,  -26.449532154958817",Weyba Downs
"148.0686814450818,  -22.289313944083553",Winchester
"152.43412939631995,  -24.858661811709833",Windermere (Qld)
"153.17951419045028,  -27.05845468614207",Woorim
"152.07731005852014,  -26.46493726978743",Wyalla
"153.12412165929965,  -27.81618308768522",Yarrabilba
"149.38570915783356,  -26.723294986778438",Yuleba South
"138.60066488821195,  -34.928551664176325",Adelaide
"138.5748484997832,  -33.82494353549011",Armagh
"138.73603417718425,  -34.94107609296054",Ashton
"138.54282868679908,  -34.85606261366811",Athol Park
"138.6434328821912,  -35.00636339976275",Belair
"138.54631807151213,  -34.89508314553091",Beverley (SA)
"139.615984994109,  -34.63710430533227",Big Bend
"140.62033279415834,  -34.35700645643527",Bookpurnong
"138.72571259934443,  -35.071447381063834",Bradbury (SA)
"138.61500342425182,  -34.875933458054256",Broadview
"139.70708080580965,  -36.95825477753992",Cape Jaffa
"138.13303778320912,  -35.5941577049121",Cape Jervis
"133.624277641554,  -32.04616907978966",Ceduna
"140.99299731379966,  -32.0738509012515",Cockburn
"137.75194866580875,  -34.66882068787662",Curramulka
"138.02977253793821,  -35.755205880693495",Cuttlefish Bay
"138.55602145767202,  -35.03303305812302",Darlington (SA)
"139.20904769315067,  -34.294639054028934",Frankton
"140.16113013837173,  -35.44908017990155",Geranium
"138.5151713048922,  -35.14239637188153",Hackham West
"138.6041328874127,  -34.97097480067917",Hawthorn (SA)
"138.5286690477837,  -34.00992448037149",Hoyleton
"137.98265695210557,  -35.74107116835908",Ironstone
"140.215281425829,  -37.339091855326586",Kangaroo Inn
"135.69663708848844,  -34.010038706071484",Karkoo
"136.41215134728367,  -33.135656305591866",Kimba
"137.6051063720041,  -35.65539169991881",Kingscote
"140.31782248975293,  -34.22069995698123",Kingston On Murray
"140.54497462618465,  -37.90911777029493",Kongorong
"138.53079616575846,  -34.925115674321965",Lockleys
"136.58433580672755,  -33.47187510744057",Mangalo
"138.60491695085975,  -34.89733165594444",Medindie
"137.18805695426948,  -33.40808753545545",Midgee (SA)
"137.57565087149877,  -34.799432194816",Minlaton
"138.68109574232426,  -34.823152129892954",Modbury North
"140.3124031605162,  -34.27719449863598",Moorook
"139.6405495874171,  -34.08757453826508",Morgan
"138.3486631446114,  -31.11333849792463",Motpena
"138.69587242819597,  -35.31294867184338",Mount Magnificent
"137.99916013556003,  -32.71089270503644",Nectar Brook
"138.62502641112636,  -34.970456880860574",Netherby (SA)
"139.91991707449168,  -37.321114393980764",Nora Creina
"138.8040672559662,  -33.49983809688116",North Booborowie
"138.64316385522548,  -33.92341659431693",Penwortham
"139.3255292687291,  -34.952375232670576",Ponde
"137.95533983937364,  -33.66690116774455",Port Broughton
"137.5179413326041,  -34.51783514349522",Port Victoria
"139.61762674659207,  -34.81824234111967",Purnong
"138.62829013414168,  -34.93067531221371",Rose Park
"138.52738875993202,  -35.079253764982134",Sheidow Park
"138.9426113618638,  -34.162931459696125",Tarnma
"135.60690385492424,  -34.73072867161189",Uley
"134.0951609993069,  -32.89816301920613",Westall
"138.27436813404162,  -34.347568284021456",Wild Horse Plains
"137.8242058155616,  -33.92233912366172",Willamulka
"138.4233165250747,  -35.56802022587141",Willow Creek
"138.22477219416155,  -32.98027109814852",Wongyarra
"139.36927413787703,  -35.22352736513903",Woods Point (SA)
"138.5360954343647,  -34.888307374989246",Woodville South
"140.92470608694742,  -37.15020513737542",Wrattonbully
"114.62238475160656,  -28.252420562441902",Alma (WA)
"115.79771612692687,  -31.732519575206062",Ashby (WA)
"118.62353114953432,  -33.03799280966057",Beenong
"115.83535602921044,  -33.33144679960176",Burekup
"116.7650055814521,  -31.831442748520946",Burges
"115.89303979453224,  -31.95579085605571",Burswood
"116.0958327270506,  -32.05746909115747",Canning Mills
"115.97589908050432,  -31.875438219895088",Caversham
"115.32345272246435,  -33.78657176076542",Chapman Hill
"117.16420394386859,  -32.824209019045966",Cuballing
"117.59055985424797,  -28.11233080528842",Daggar Hills
"121.58226587556604,  -33.69537709728513",Dalyup
"119.48903965700771,  -33.32347833988397",Dunn Rock
"115.17634859659586,  -34.311578708828975",East Augusta
"115.87685525054029,  -31.95678584547708",East Perth
"115.0676558235661,  -28.775908767704923",Eradu South
"121.78801459660974,  -33.230348602536125",Grass Patch
"115.13792870309248,  -30.662330471272085",Grey
"115.81834209211992,  -31.770135031562205",Hocking
"119.08940153286566,  -31.939996211302176",Holleton
"119.11960350042334,  -32.41920030660584",Hyden
"114.64854595761128,  -28.440276623269146",Isseka
"115.13400129830619,  -34.185388322766904",Karridale
"115.77679748873695,  -31.872319593262084",Karrinyup
"118.16455097110297,  -34.032607821012625",Kebaringup
"115.77220203468242,  -32.46871175321119",Lakelands (WA)
"116.09317429012088,  -31.55535200961718",Lower Chittering
"115.93344759765905,  -32.32265149246109",Mardella
"117.9111116713873,  -35.023550246310116",Middleton Beach
"116.81758850161468,  -20.734711858389524",Millars Well
"117.9010602986056,  -35.01518449347957",Mira Mar
"115.41395766053859,  -29.00910107085936",Nangetty
"115.78176370829497,  -32.19493735797427",Naval Base
"115.0740995038618,  -28.564946703025747",North Eradu
"117.95596800469555,  -31.02898481497999",North Kununoppin
"115.93007878395333,  -32.21022016229709",Oakford
"115.73602119694635,  -31.758626894255087",Ocean Reef
"116.12418058559939,  -31.957715091208396",Paulls Valley
"115.70152323318405,  -33.34699055710813",Picton (WA)
"125.98746256889787,  -29.615273973378567",Plumridge Lakes
"118.60073295580159,  -20.40655283271543",South Hedland
"116.35801853306464,  -31.894744433188173",The Lakes
"116.27357349195437,  -30.800825531681905",Waddington
"118.58842270329043,  -30.962245037252636",Warralakin
"115.1132409163055,  -33.740820845239355",Yelverton
"117.15813740946042,  -32.744712810887165",Yornaning
"147.0233000617598,  -42.69762233919029",Black Hills
"147.83377871275525,  -42.8545668517568",Boomer Bay
"146.87361290687704,  -42.7207272028786",Bushy Park (Tas.)
"146.3450045949082,  -41.17702231569636",Devonport
"146.65813414411065,  -42.75659982568379",Fitzgerald (Tas.)
"146.2710093707048,  -41.22742660093801",Forthside
"146.87155346857193,  -41.34403121507593",Glengarry (Tas.)
"147.20619272338539,  -43.2629181935715",Gordon (Tas.)
"146.96491807689017,  -41.215144011385604",Hillwood
"146.2899919344956,  -41.1682355286256",Lillico (Tas.)
"147.0275405833746,  -41.207519152425924",Mount Direction
"147.11315719220306,  -42.934424958009394",Mountain River
"147.22077957244946,  -43.10907722546537",Oyster Cove (Tas.)
"145.38526001057485,  -40.859771600106136",Port Latta
"146.04648949175424,  -41.10293008968453",Preservation Bay
"146.86558503620526,  -41.48063470880438",Quamby Bend
"146.7233902228556,  -41.590573491811",Quamby Brook
"143.9777466359057,  -39.74874806837951",Reekara
"146.6949234403372,  -41.8590326900666",Reynolds Neck
"147.05128038575467,  -41.42035288881378",Riverside (Tas.)
"147.35706805445827,  -42.858745237019434",Rose Bay (Tas.)
"146.0420279916086,  -41.44629409942216",South Nietta
"145.7020851664201,  -40.95237758741012",Table Cape
"147.5429004576552,  -42.418778371042805",Whitefoord
"144.79432995066975,  -40.6467221122627",Woolnorth
"136.420097173229,  -13.850686181931646",Alyangula
"133.88516961519912,  -23.715268213119906",Desert Springs
"136.70474400560494,  -12.216895761836112",Gunyangara
"130.90402402985595,  -12.392885840827377",Malak
"130.6561988950903,  -11.419155316366776",Milikapiti
"134.91077409095956,  -12.101208689056413",Milingimbi
"133.90032809016785,  -23.704025481291254",Sadadeen
"132.6191110720976,  -21.263178795197817",Willowra
"148.97699878380365,  -35.219038662039736",Macnamara
"149.0358274110071,  -35.27705138256277",Whitlam
"152.79554016251114,  -30.818185524617732",Allgomera
"146.80079226514778,  -35.601011657333366",Alma Park
"148.68450336305372,  -36.01108804937927",Anglers Reach
"151.5992270977346,  -32.77991140475162",Ashtonfield
"151.80430591338154,  -32.658288429229444",Balickera
"151.57881792388497,  -33.045722274166046",Balmoral (Lake Macquarie - NSW)
"143.41702192112052,  -34.33338651093341",Balranald
"151.0325024287082,  -34.01628701047856",Bangor (NSW)
"150.8691150304119,  -34.56315967376364",Barrack Point
"146.1046298863862,  -34.231289927749465",Beelbangera
"147.05534020502844,  -35.11650228980994",Belfrayden
"150.91705612218976,  -34.36837097941167",Bellambi
"151.33055227526512,  -32.84851021415582",Bellbird Heights
"151.42882343269505,  -33.33825813897997",Berkeley Vale
"149.84435437881245,  -36.73470755502946",Black Range (NSW)
"151.57946046911005,  -32.998951222674215",Blackalls Park
"150.00935044069612,  -36.093336904765216",Bodalla
"150.10080939598112,  -32.99434215839558",Bogee
"147.25962639994486,  -32.61706156685397",Boona Mount
"151.2000702781406,  -33.94831300688975",Botany
"149.8731702821983,  -37.10710450519058",Boydtown
"153.1968202195918,  -28.412606801067664",Brays Creek
"146.88957806986494,  -29.996621953571836",Brewarrina
"149.71873718728335,  -34.795110331463846",Brisbane Grove
"149.89347338954062,  -36.973222513555534",Broadwater (Bega Valley - NSW)
"151.58485033881385,  -33.21817420976562",Budgewoi Peninsula
"150.28380851043707,  -34.66439781994793",Bundanoon (NSW)
"152.19880526054368,  -32.18788768523688",Bunyah
"150.73559329043556,  -32.469064421553625",Bureen
"150.44088078906924,  -35.39245735821783",Burrill Lake
"151.5600492127944,  -32.83258918874697",Buttai
"150.73317559399297,  -33.77405756413172",Caddens
"150.91324485600262,  -28.793601600168383",Camp Creek (NSW)
"151.85246746356307,  -32.77573389659465",Campvale
"151.61745985952564,  -33.12574833771479",Cams Wharf
"147.6765343480966,  -33.834071451619224",Caragabal
"153.42713887835833,  -28.22586386309012",Carool
"151.17190407085488,  -32.83722680899925",Cedar Creek (Cessnock - NSW)
"152.42862346721458,  -29.974307528160754",Chaelundi
"150.15732883552604,  -33.4653684883485",Cobar Park
"149.87831577563418,  -36.38964749654121",Cobargo
"152.47848863318367,  -29.254149220901645",Collum Collum
"151.5435719894041,  -33.20678549512544",Colongra
"148.3110708658581,  -31.149168405133015",Combara
"150.64837967568033,  -34.968260267400446",Comberton
"153.4622630849668,  -28.91592595993122",Coolgardie (NSW)
"145.49771505876382,  -35.31276622927089",Coree
"150.867602411209,  -34.47128507803821",Cringila
"151.26922665127407,  -33.73016092467469",Cromer (NSW)
"151.15224619186546,  -34.05417301801116",Cronulla
"153.534585500049,  -28.819461021989188",Cumbalum
"152.52000827272315,  -31.88246949902493",Cundletown
"149.40262669478852,  -31.006130929301676",Dandry
"150.7943707406569,  -34.50486242152897",Dapto
"151.0889567121098,  -33.799538648812515",Denistone
"150.32151378186904,  -35.62635293535613",Depot Beach
"149.7977123899514,  -35.92829507560123",Deua
"152.14225724447797,  -31.69824611396445",Dingo Forest
"148.9324326398982,  -36.091021360816534",Dry Plain
"151.05550155433465,  -33.79309661904053",Dundas Valley
"150.98846904050484,  -33.96093130123409",East Hills
"148.66413466175993,  -36.408440471034204",East Jindabyne
"151.40909208772177,  -33.42574677835917",Erina Heights
"146.95632728198734,  -36.00237987405071",Ettamogah
"152.91177546743523,  -28.65006053892149",Ettrick (NSW)
"152.87984558494549,  -30.824514666392822",Eungai Creek
"153.3050272057185,  -28.348806866018425",Eungella (NSW)
"145.58498059389404,  -35.60612342790408",Finley
"148.80613335222574,  -36.14599879868497",Frying Pan
"146.82761946872986,  -34.98590712623237",Galore
"151.06693077652795,  -33.64736383743226",Galston
"151.69588886029197,  -32.99153119984137",Gateshead
"149.54581260145318,  -33.783587706234194",Gilmandyke
"151.15948688594557,  -32.58878266942036",Glenridding
"148.3556423585053,  -34.9519253583944",Gobarralong
"148.02605994107964,  -35.25382218085409",Grahamstown
"152.80529725024613,  -31.04844767110968",Greenhill (NSW)
"149.44381150132443,  -36.517947887408496",Greenlands (Snowy Monaro Regional - NSW)
"150.9441025068209,  -33.824556266234104",Greystanes
"149.40684451539877,  -34.669883304171144",Gurrundah
"147.55373249856663,  -34.97706369255696",Harefield
"150.15394052661193,  -33.50767789555848",Hassans Walls
"143.68477794732416,  -33.74703970675728",Hatfield
"150.64334719321096,  -33.667166422847394",Hawkesbury Heights
"151.50714970090257,  -32.80758603680464",Heddon Greta
"149.32757405018876,  -33.68901635231657",Hobbys Yards
"149.27114129736992,  -36.6127995088451",Holts Flat
"153.0372174281477,  -28.535356437285607",Horseshoe Creek
"151.14441178402706,  -33.839643282421",Huntleys Point
"152.5997090058413,  -31.47513166590717",Hyndmans Creek
"153.18390214757127,  -28.70178371481464",Jiggi
"148.64351088772153,  -36.33288963026773",Kalkite
"146.7620873523488,  -34.355769406792824",Kamarah
"149.11585219593834,  -33.054937897396",Kerrs Creek
"151.99299725094681,  -31.758896140878505",Khatambuhl
"153.44865052113767,  -28.33814872416946",Kielvale
"152.40333731221372,  -31.718607535045937",Killabakh
"151.40165199224134,  -33.47304081681158",Kincumber
"150.65550832664672,  -33.52245546233533",Kurrajong Hills
"150.86174780045124,  -34.54304538757404",Lake Illawarra
"152.82782048664527,  -31.5034177341753",Lake Innes
"151.70599883479457,  -32.913489259844475",Lambton
"150.22151859592697,  -35.7744254296591",Lilli Pilli (Eurobodalla - NSW)
"149.7459652423596,  -33.24650238638123",Limekilns
"151.38320485393342,  -33.38554186938923",Lisarow
"151.25386765320866,  -33.542389855522224",Little Wobby
"150.22594618191872,  -35.692594278278236",Long Beach
"149.31765696483046,  -34.43610282931038",Lost River
"151.40962971739503,  -33.49447471687655",Macmasters Beach
"148.6471506488157,  -31.022362334296925",Magometon
"145.32609026912905,  -35.47354395826903",Mayrung
"151.51982702933708,  -32.677001342290296",Melville (NSW)
"152.03181736336262,  -32.02844372143595",Mograni
"150.37841299836325,  -34.787012563671766",Moollattoo
"151.21257659042223,  -31.888524726199407",Moonan Flat
"150.1397633896621,  -35.92558359888485",Moruya Heads
"149.57729298489906,  -36.847706304972064",Mount Darragh
"149.61015568030777,  -33.84244310037121",Mount David
"150.55015034327556,  -34.155249564973616",Mowbray Park
"147.82151996665044,  -35.12655356662186",Mundarlo
"149.05417328781235,  -36.100552137659356",Murrumbucca
"153.03693769299767,  -30.474896810296766",Mylestom
"146.3544345700572,  -33.653329755211836",Naradhan
"150.1006544425431,  -35.6668680827613",Nelligen
"152.17682646366453,  -32.53171205271641",Nerong
"150.5744336258093,  -34.857979677295596",North Nowra
"147.6205804368286,  -30.9819485648251",Oxley (Warren - NSW)
"151.36824145591046,  -33.329513080332916",Palmdale (NSW)
"153.2943218133367,  -29.42254936127725",Palmers Island
"152.75139844068073,  -31.374093895331796",Pembrooke
"151.58989660979657,  -32.73309776410878",Pitnacree
"150.83685187552553,  -33.75184560362007",Plumpton (NSW)
"151.31792813624523,  -33.43886030899318",Point Clare
"152.0311148473103,  -32.72106160239486",Port Stephens
"153.49306782337283,  -28.35183390278679",Reserve Creek
"151.01318405506288,  -33.94630433515621",Revesby
"151.08773655853724,  -33.829215081545684",Rhodes
"150.75742510565445,  -33.60484327221974",Richmond (NSW)
"151.03168157322963,  -33.82553359104252",Rosehill (NSW)
"150.8952444416619,  -34.35567025092184",Russell Vale
"153.22130885716905,  -29.575397550861727",Shark Creek
"151.55821071690698,  -33.102901582404385",Silverwater (Lake Macquarie - NSW)
"148.13079235171125,  -35.123388378343016",South Gundagai
"149.09105754546303,  -33.406612265205595",Spring Terrace
"151.27229008196187,  -32.35556685914904",St Clair (Singleton - NSW)
"151.17239395605932,  -33.72500376756382",St Ives (NSW)
"151.16456472278702,  -33.70387580195691",St Ives Chase
"150.19958950542184,  -35.695272232738624",Surfside
"149.21751647616907,  -35.155008805369825",Sutton (NSW)
"151.09978654570324,  -34.01372099792208",Sylvania
"146.87606243028745,  -34.04437664844341",Tallimba
"150.51524162791955,  -34.82843059026081",Tapitallee
"152.4779103918658,  -32.36439649905616",Tarbuck Bay
"151.87028275284047,  -30.408798633832372",Thalgarrah
"144.39128716007238,  -35.684043113479426",Thule
"152.5753593839448,  -30.655742884147024",Thumb Creek
"151.90382307050834,  -31.834314467692373",Tibbuc
"151.0837413904981,  -31.711073748030945",Timor (NSW)
"148.9584573941424,  -31.50240283877785",Tooraweenah
"147.97473393365868,  -34.23773634943522",Tubbul
"153.10764032153438,  -28.49842404856647",Upper Horseshoe Creek
"152.0893433169126,  -32.07940162161628",Wallanbah
"151.426055818079,  -33.26724045329704",Watanobbi
"150.51904219497257,  -34.85528884496472",Watersleigh
"152.73250791951818,  -31.472096916719693",Wauchope
"149.6155045733995,  -34.62266901519109",Wayo
"146.88860175066014,  -36.0747372139987",West Albury
"152.78375722297454,  -31.635171427108876",West Haven
"147.10800070647278,  -33.874985110180226",West Wyalong
"150.72022573318597,  -33.45998133650945",Wheeny Creek
"151.06741786807314,  -33.9242878014339",Wiley Park
"149.7183963261981,  -33.63440002898496",Wisemans Creek
"152.17911078632224,  -28.56078854004831",Wylie Creek
"149.63292615749606,  -36.92252477065267",Wyndham (NSW)
"150.73828723717637,  -34.602224590776466",Yellow Rock (Shellharbour - NSW)
"148.6045260634133,  -32.765957122166306",Yeoval
"153.3316549088869,  -29.530794305885898",Yuraygir
"145.85183638224137,  -37.84223083873772",Ada
"143.56612785840377,  -38.69258688359784",Aire Valley
"144.81615497089652,  -37.776435647852395",Albion (Vic.)
"146.93687095127478,  -36.295992704763066",Allans Flat
"143.49627529739496,  -38.23431903709717",Alvie
"146.403679733941,  -37.94021651223424",Amor
"143.51021461272205,  -36.837344893713464",Archdale
"145.86310866009524,  -36.60408179955455",Baddaginnie
"145.53535985825783,  -37.689196523140325",Badger Creek
"143.5685146823421,  -38.28305206711699",Balintore
"145.1209783712505,  -38.35442150606332",Balnarring
"144.22635743323602,  -38.16662319755345",Barrabool
"147.0585241450083,  -36.096855473495616",Bellbridge
"142.9498868747473,  -35.563672866506955",Boigbeat
"141.74168390459243,  -37.412394047917424",Brit Brit
"143.65181257700706,  -35.7701992739182",Budgerum East
"143.140423471538,  -38.3496482853918",Bullaharre
"146.12928246909632,  -36.05840893669015",Bundalong
"144.003206697492,  -37.55880994601177",Bungaree (Vic.)
"142.08542449094554,  -34.20814089162331",Cabarita (Vic.)
"143.18338698282224,  -37.68440728353339",Carranballac
"147.6052113817444,  -37.20563853941541",Cassilis (Vic.)
"145.04199709536374,  -37.88124947705073",Caulfield East
"145.50044724969752,  -37.05731780811515",Caveat
"144.6418915586174,  -37.39547257414769",Cherokee
"144.26798363698563,  -37.095690308145194",Chewton
"148.09069646996957,  -36.85220732991274",Cobberas
"143.75335777690321,  -37.38491510384461",Coghills Creek
"143.25686571440465,  -35.7546518567327",Cokum
"143.21939006596514,  -36.424754583209726",Coonooer West
"146.5706980661285,  -36.102768416638334",Cornishtown
"144.15738079057817,  -35.783896970056404",Cullen
"142.4947785793887,  -36.89815056487321",Dadswells Bridge
"145.84873962465704,  -38.13542175508369",Drouin
"146.40841468200585,  -36.64996376463378",Edi
"145.435987239101,  -37.94612827310318",Emerald (Vic.)
"143.44453037653375,  -36.73597083802276",Emu
"144.08185947036455,  -38.455009498228726",Fairhaven
"147.63245505589268,  -37.93016934347781",Forge Creek
"144.47927424953795,  -36.69684074327617",Fosterville
"146.08948340874258,  -37.91038106358653",Fumina
"147.0000919379009,  -38.378898704696134",Giffard West
"144.56894214199954,  -37.511246251776726",Gisborne
"146.94782281958163,  -36.43324490376333",Glen Creek
"145.04134615337244,  -37.892360011709705",Glen Huntly
"143.9219628227068,  -37.50680804360253",Glen Park
"143.84441381594283,  -37.3604181972234",Glendonald
"146.51177450350534,  -38.11908723208831",Glengarry West
"143.34031594399522,  -36.132486277068146",Glenloth
"142.64518598745306,  -36.907844119413646",Glenorchy (Vic.)
"143.08006819027,  -38.20121588459389",Gnotuk
"143.86511124459622,  -37.57343418713241",Golden Point (Ballarat - Vic.)
"143.9372959602992,  -37.54892791314085",Gong Gong
"145.69778603825708,  -36.47163668586756",Gowangardie
"144.95050095211607,  -37.710229111043745",Hadfield
"146.44336136000734,  -38.53809264192608",Hiawatha
"145.5938839299024,  -37.85087436654384",Hoddles Creek
"146.29751443895802,  -37.18501621717699",Howqua Hills
"146.13492726207713,  -37.23109673788333",Howqua Inlet
"145.69201840051255,  -36.075351658628385",Katamatite
"145.7527524102289,  -36.03733011239085",Katamatite East
"146.25952796483867,  -37.425708953485284",Knockwood
"142.59363459463688,  -34.98685244317461",Kulwin (Vic.)
"142.84699160475236,  -37.72020275236564",Lake Bolac
"143.1450534676403,  -37.01214523702732",Landsborough (Vic.)
"143.36086768468263,  -38.11484405444289",Leslie Manor
"142.83490074692622,  -36.295975816326965",Litchfield
"145.77184768917363,  -38.122461800980986",Longwarry
"146.62338384011997,  -38.461894095169264",Macks Creek
"145.7808978370543,  -36.36744820416115",Major Plains
"146.01819928686012,  -38.57240865961979",Meeniyan
"142.00751011128193,  -34.16778621698852",Merbein West
"144.04407028838324,  -37.46883038344576",Mollongghip
"145.32557453635422,  -37.7813854238913",Mooroolbark
"143.6951810198174,  -37.308008887359236",Mount Beckworth
"145.6511834101034,  -36.00316300170404",Muckatah
"145.35280573904316,  -36.16414052604536",Mundoona
"145.5088800531145,  -35.8422521407345",Mywee
"143.45924746229161,  -38.32243520057961",Nalangil
"143.65938797056143,  -37.7027495249797",Newtown (Golden Plains - Vic.)
"149.2194994939714,  -37.42690003025843",Noorinbee North
"142.79644551667732,  -38.43794026044932",Nullawarre North
"143.54327566581992,  -35.93007177706718",Oakvale
"147.58460150407754,  -37.11101803900122",Omeo
"143.06349699075642,  -36.822564088796256",Paradise (Vic.)
"147.0301629267837,  -38.13373350539233",Pearsondale
"145.54774416641175,  -36.35516625194024",Pine Lodge (Vic.)
"143.41892505494135,  -38.35337356881887",Pirron Yallock
"141.2466070179398,  -37.22194358765645",Powers Creek
"145.002429049271,  -37.820267066138335",Richmond (Vic.)
"145.59042863242587,  -38.50758053508679",Ryanston
"142.54430986130137,  -36.34317578186005",Sheep Hills
"143.34240484567385,  -37.69342734662072",Skipton
"143.294799893881,  -36.53958017321915",Slaty Creek
"145.15778849524781,  -37.9456527943901",Springvale (Vic.)
"146.51076108234378,  -38.557814066059834",Staceys Bridge
"145.4499789644925,  -35.91153536131368",Strathmerton
"144.83424095459517,  -37.78930530721503",Sunshine (Vic.)
"142.19223449407195,  -37.828639911595666",Tabor
"144.34908748357998,  -37.14132169541057",Taradale (Vic.)
"145.95325111107994,  -38.603770170606005",Tarwin
"142.9941624013668,  -37.50807675134658",Tatyoon
"144.75285468160527,  -37.71519787149597",Taylors Hill
"143.1670105962975,  -38.29045705365391",Tesbury
"147.97103097689785,  -36.129815875142384",Towong
"144.7134663568261,  -37.79920975456897",Truganina
"141.89790570731697,  -38.25618736471901",Tyrendarra East
"142.22218936023958,  -37.50928622143861",Victoria Point (Vic.)
"145.71116192826264,  -36.60963428424538",Violet Town
"143.04948807309634,  -38.57584222612609",Waarre
"146.24735445516671,  -36.39169663874589",Wangaratta South
"141.83146623237735,  -37.651976940112775",Wannon
"142.32121068215602,  -38.19784982284352",Warrong
"143.59644520503153,  -36.419375623569685",Wedderburn (Vic.)
"142.66750403344327,  -34.80426740707444",Wemen
"143.82843356133185,  -37.53079436799776",Wendouree
"144.02329477206177,  -37.24442290424802",Werona
"144.69713152296714,  -37.938068607184455",Werribee South
"144.24702928454352,  -36.753068676644034",West Bendigo
"146.8818487250199,  -36.123070690768394",Wodonga
"148.29925810965307,  -37.027324699077",Wulgulmerang
"142.01520418627234,  -34.128550685805514",Yelta (Vic.)
"152.89220416424234,  -26.744448848105193",Balmoral Ridge
"152.71899420472957,  -27.40944682368475",Banks Creek
"138.47801934012705,  -20.602835404377927",Barkly (Qld)
"149.16544205095727,  -21.091186726227335",Beaconsfield (Qld)
"153.13379792736606,  -27.509103647518987",Belmont (Qld)
"151.9941938863843,  -25.571997089040806",Biggenden
"145.94928858414565,  -17.920418463229534",Birkalla
"152.10695872427385,  -25.66483834058673",Boompa
"152.38264134828782,  -27.038775956773478",Braemore
"151.69566359908444,  -27.722760196047286",Broxburn
"152.59869573008905,  -27.233666599797598",Bryden
"153.40484100714238,  -28.010994605252495",Bundall
"152.8102890975127,  -27.60087792309567",Bundamba
"150.63837478423378,  -23.04889685207913",Bungundarra
"152.48807168614127,  -25.183635009246053",Buxton (Qld)
"146.82413123317056,  -24.922785073974982",Caldervale
"150.98752058684832,  -24.56871556591824",Cania
"148.6513807985729,  -20.317850565851145",Cannon Valley
"150.74574745479782,  -26.737338322092867",Chances Plain
"144.65430052248053,  -17.103040937026787",Chillagoe
"144.59336295548243,  -18.729186834346674",Conjuboy
"152.86164128810648,  -26.80702273339901",Crohamhurst
"147.11070001283835,  -19.400992723002677",Cungulla
"151.02712816971285,  -27.97780352093173",Cypress Gardens
"151.96257265765368,  -28.662360464436517",Diamondvale
"149.20029875201544,  -21.152485292648954",East Mackay
"146.00589645460008,  -17.801352436431593",El Arish
"151.74182290371678,  -26.750619927535723",Ellesmere
"149.42470122497744,  -25.854678254420044",Eurombah
"152.94822142950423,  -27.811671466758533",Flagstone
"146.76641012656003,  -19.25869286998031",Garbutt
"150.25680663393896,  -23.170165586804885",Garnant
"152.14549499825742,  -24.989486665234978",Givelda
"151.88018107825425,  -28.737808069909104",Glen Aplin
"152.46432516205334,  -27.24271568698164",Glen Esk
"145.41984089583718,  -18.007134625345675",Glen Ruth
"151.86670987708044,  -27.460719466754455",Glencoe (Qld)
"148.102643364489,  -21.35961746210466",Glenden
"151.36676079891373,  -25.644197933229147",Glenrae
"150.29153625199436,  -24.23296182052284",Greycliffe
"142.8296282573334,  -10.254387825666623",Guijar Islet
"153.07027746084785,  -27.418137092653186",Hendra
"153.01744135977407,  -27.487411890354174",Highgate Hill
"151.9308761530049,  -26.609930266602717",Hodgleigh
"151.28607102588924,  -26.93123087668565",Jimbour East
"139.48805792436877,  -20.667936474960662",Kalkadoon
"151.2468710091217,  -23.877344148877143",Kin Kora
"147.30375498049975,  -19.858353681161418",Kirknie
"153.0373897586905,  -26.660238994575405",Kunda Park
"150.5695609255295,  -23.368752503688974",Lakes Creek
"152.59286737590728,  -27.524512061602042",Lark Hill
"152.11569403371055,  -25.79571009967724",Malarga
"152.8522635871391,  -26.76249841629462",Maleny
"153.10311568295413,  -27.242893479244685",Margate (Qld)
"149.8229227019378,  -22.834151101794156",Marlborough
"152.92237057403236,  -27.55599032967767",Middle Park (Qld)
"152.94008307040374,  -27.12845554325866",Morayfield
"152.6751017628129,  -27.144019357022284",Mount Byron
"151.29592819184333,  -28.036693055810115",Mount Emlyn
"148.19457922811515,  -25.27925583286161",Mount Howe
"148.60814792887564,  -20.373297332601314",Mount Julian
"151.64181910745427,  -25.544768720584646",Mount Lawless
"148.81843081064034,  -21.10321337391974",Mount Martin
"146.78517222799192,  -19.296339577864348",Mundingburra
"146.84477341148977,  -19.157109472583834",Nelly Bay
"150.53527744897593,  -23.32531354892732",Norman Gardens
"153.07365786958033,  -27.391237471679464",Northgate (Qld)
"151.38300528896454,  -27.533083316121584",Norwin
"145.51504413435458,  -16.592617577499873",Oak Beach
"149.52822413551024,  -24.52092205274669",Oombabeer
"146.2414736599793,  -18.792498539249515",Orient
"150.51256413366397,  -23.35629737188503",Park Avenue
"144.68268618774607,  -21.33879465811974",Prairie (Qld)
"151.97158456915434,  -27.653948946794245",Preston (Lockyer Valley - Qld)
"144.21822219501345,  -26.872199615733813",Quilpie
"150.51013782792586,  -23.37861017596887",Rockhampton City
"153.1276452144965,  -27.075705034811126",Sandstone Point
"150.8341831007392,  -33.682217134416334",Angus
"151.06706411476551,  -33.61073963964219",Arcadia (NSW)
"151.52150172744194,  -30.57008380518582",Arding
"147.1971208028569,  -34.310790268805775",Ariah Park
"151.26191187374826,  -33.79475395540876",Balgowlah
"150.51878943136307,  -34.904329218680175",Bamarang
"150.5515316820158,  -34.84428439046466",Bangalee (NSW)
"151.2132373967817,  -33.957550682860465",Banksmeadow
"149.83004543747583,  -32.57248048886933",Bara
"153.20247322223116,  -29.9002734616466",Barcoongere
"151.0039906316832,  -34.0341073126112",Barden Ridge
"144.5298487232386,  -35.18921051109453",Barratta (NSW)
"152.7507905866846,  -29.36403746672704",Barretts Creek
"151.28377903728475,  -31.59990649722696",Barry (Tamworth Regional - NSW)
"151.08935929638017,  -33.919014089100955",Belmore
"150.77526108389733,  -33.67281230862256",Berkshire Park
"151.79794737203022,  -32.087058850680634",Berrico
"151.11457178264124,  -33.9420401784216",Bexley North
"150.06489681056559,  -33.33802469972714",Blackmans Flat
"150.79681532618952,  -33.47377679368131",Blaxlands Ridge
"149.25717572514756,  -33.54314215549539",Blayney
"149.60525267226672,  -32.57185734048055",Bombira
"146.0087931566761,  -34.019927324437305",Boorga
"148.96032435611255,  -33.233629430514",Borenore
"151.35905295074335,  -32.63750698838246",Branxton
"146.66434632552986,  -35.8125947359997",Brocklesby
"153.59178490739836,  -28.729388476041578",Broken Head
"141.55150258620887,  -32.321251487132976",Broken Hill
"147.87740247333116,  -32.821221698730966",Bruie Plains
"153.19943133635402,  -29.107452950374995",Bungawalbin
"149.64476934142374,  -37.003433315038905",Burragate
"153.36174850765607,  -28.358343748658918",Byangum
"153.60165864511802,  -28.647641184033773",Byron Bay
"151.08562142216118,  -32.4900148859487",Camberwell (NSW)
"150.14019241426342,  -34.50394744434727",Canyonleigh
"150.9635229819352,  -33.88453516405213",Carramar (NSW)
"151.21972621285465,  -33.80065591756067",Castlecrag
"152.39194606234474,  -31.812143024248538",Cedar Party
"149.97052608991274,  -32.886490793117794",Charbon
"151.18080414143085,  -33.79626148562162",Chatswood
"149.11615103774463,  -35.699775423722535",Clear Range
"151.02184351755315,  -33.83321132842998",Clyde (NSW)
"150.97254881489854,  -34.24584045647931",Coalcliff
"150.45404555338345,  -34.38250090756641",Colo Vale
"145.1720276446343,  -35.24272544640994",Conargo
"149.972589927458,  -36.43364164475959",Coolagolite
"150.93223337106622,  -29.885182790165548",Copeton
"152.11385090666408,  -32.7241175323248",Corlette
"153.36334355322631,  -28.705998037794327",Corndale (NSW)
"152.1078472665061,  -32.39125061143025",Crawford River
"150.70741773233618,  -32.42265581776653",Dalswinton
"151.23914779167853,  -33.539043155966446",Dangar Island
"152.5357766398544,  -30.350626537012438",Deer Vale
"151.5466050197173,  -33.184797358927696",Doyalson North
"152.51906145206348,  -30.13810771633774",Dundurrabin
"152.84883939497067,  -28.771455973023773",Dyraaba
"150.91223891468758,  -34.37711426004242",East Corrimal
"150.28742535933756,  -34.61473991277741",Exeter (NSW)
"150.92492645102072,  -33.86887440938824",Fairfield West
"151.27405215913993,  -33.7940601315821",Fairlight
"151.1325516371969,  -32.419071089269615",Falbrook
"151.01111370576857,  -33.19332117061453",Fernances
"151.40108190427986,  -33.3477038321896",Fountaindale
"146.9272643967599,  -35.25830981111476",French Park
"150.90242417241964,  -30.86739992154439",Garthowen
"148.18144799956332,  -35.406793808045215",Gilmore (NSW)
"152.0654783281757,  -32.473791680810734",Girvan
"150.78127847709334,  -34.01197225973221",Gledswood Hills
"147.31073749160535,  -35.063511501844715",Gobbagombalin
"149.49526019241992,  -32.667876407163156",Grattai
"151.58205842037825,  -33.139766146728476",Gwandalan
"153.10207444972286,  -29.935779258586578",Halfway Creek
"151.73801700372738,  -32.91409435117857",Hamilton North
"150.16336511977352,  -33.54098732411185",Hartley (NSW)
"152.6285220396424,  -31.476417682623737",Hartys Plains
"153.0604301266726,  -28.569762490307014",Homeleigh
"151.0957038839167,  -33.698001131674104",Hornsby
"151.72243231213744,  -32.050068624082876",Invergordon (NSW)
"145.75356474274358,  -35.276781662438715",Jerilderie
"150.54135895685187,  -34.54624659585542",Kangaloon
"151.97144169634925,  -31.95841352016135",Kia Ora (NSW)
"151.41370161270547,  -29.60327741398823",Kings Plains (Inverell - NSW)
"151.22306768557402,  -33.84175575856643",Kurraba Point
"149.4266071890387,  -36.38242281831513",Kybeyan
"150.4695623351127,  -35.27134560859441",Lake Conjola
"147.37523280386168,  -33.657190391280565",Lake Cowal
"151.08389659938157,  -33.84116584896957",Liberty Grove
"153.1971189163492,  -28.29975206400782",Limpinwood
"150.91598112210028,  -33.92355018067648",Liverpool
"152.98854054364804,  -28.39059139397416",Loadstone
"151.25366332447138,  -33.96445106230832",Malabar
"152.72080113821576,  -28.906666011912566",Mallanganee
"151.24438550319647,  -33.94405956538963",Maroubra
"146.01033910493473,  -33.7448539439129",Melbergen
"149.25721410968092,  -34.68466959831581",Merrill
"150.29636260795593,  -32.10688008966159",Merriwa (NSW)
"150.8294544980722,  -33.787188751838436",Minchinbury
"150.4895063888741,  -34.462015811484164",Mittagong
"152.40939166067426,  -31.910186910962693",Mondrook
"149.90954130312085,  -35.55529650408995",Monga
"151.3572878912769,  -31.952047046935412",Moonan Brook
"150.9441041081185,  -30.988457397760758",Moore Creek
"147.31250961539797,  -35.11194560098792",Moorong
"153.20221524107595,  -29.33676497785001",Mororo
"150.09240506766386,  -35.907375028780734",Moruya
"152.16376854327692,  -29.410426832027326",Morven (Glen Innes Severn - NSW)
"148.03741697994096,  -35.21483444594995",Mount Horeb
"150.05241550257807,  -32.93903265498222",Mount Marsden
"151.18944973749663,  -33.44969500814",Mount White
"152.93434139409044,  -29.586607131153432",Mountain View (NSW)
"151.47815840377473,  -32.896382515693624",Mulbring
"149.95830641867877,  -36.667720414031024",Nelson (Bega Valley - NSW)
"150.0699295017084,  -35.09682781299435",Nerriga
"149.37418662144515,  -33.58088277786654",Newbridge (NSW)
"151.27315433973672,  -33.774015959039595",North Manly
"150.92838095890002,  -31.062583599897998",North Tamworth
"150.8954934941639,  -34.406755172661285",North Wollongong
"151.21786159692576,  -33.81088947462543",Northbridge (NSW)
"149.41821643678608,  -37.20286591965511",Nungatta
"146.1227348898845,  -35.51453183041813",Oaklands
"151.19906615840372,  -29.898483132853478",Old Mill
"148.04924395244493,  -33.637658929087245",Ooma
"151.30368928811728,  -33.31836312541342",Palm Grove (NSW)
"150.43456673230938,  -31.729757762367818",Parraweena
"150.21408557154618,  -34.663890338614536",Penrose
"150.60325304192656,  -34.17067590184774",Picton (NSW)
"152.68046376988897,  -29.45204925287184",Pulganbar
"152.4888032246256,  -32.03172732348064",Rainbow Flat
"151.76201635606,  -29.54218882056265",Rangers Valley
"151.14024586062075,  -33.95309294704607",Rockdale
"150.1991893741117,  -30.05079085796835",Rocky Creek (Gwydir - NSW)
"151.1994038218387,  -33.77505920705103",Roseville Chase
"151.22795929898442,  -33.87492452564683",Rushcutters Bay
"148.91502317643543,  -34.526144017374115",Rye Park
"146.13934601627568,  -35.668914259769075",Sanger
"151.23527309660778,  -32.544190483772475",Sedgefield
"152.77607663182636,  -28.429507489857645",Sherwood (Kyogle - NSW)
"152.7331456054835,  -31.010355541946648",Skillion Flat
"146.91425710242012,  -36.09715425972208",South Albury
"149.5738866958076,  -33.44356178281933",South Bathurst
"151.0107823646448,  -33.85835823530999",South Granville
"153.41656499922667,  -28.337165493123596",South Murwillumbah
"150.9268296298297,  -33.715867176109235",Stanhope Gardens
"151.6346084370912,  -33.09050271435517",Swansea (NSW)
"151.20963552274176,  -33.86888791066724",Sydney
"152.81772224476057,  -30.88681567601109",Tamban
"151.1233937428267,  -34.01955297572196",Taren Point
"151.6766704447924,  -32.80832707121912",Tarro
"152.61824391559998,  -31.00379883095563",Temagog
"152.03445426179766,  -29.080766611521014",Tenterfield
"152.82481052198085,  -31.361149132657",The Hatch
"151.76027528865873,  -32.937962753984976",The Junction
"152.1157527419482,  -32.034400170508356",Titaatee Creek
"148.77589609347314,  -31.330935235549365",Tonderburine
"152.45269584003844,  -28.587777908028123",Tooloom
"150.11011830502747,  -34.92722891449481",Touga
"149.60991328848274,  -36.25391056037743",Wadbilliga
"150.5192203071816,  -29.409193226382826",Warialda
"148.2498009239639,  -33.719991448573346",Warraderry
"145.74725205962446,  -34.31568888813921",Warrawidgee
"151.92037267847178,  -32.25839657042063",Weismantels
"150.75268800491978,  -33.75919563468939",Werrington
"151.0333739292911,  -33.748291592263556",West Pennant Hills
"151.56211192781805,  -32.90034875848673",West Wallsend
"151.45620568671833,  -32.81164450552317",Weston (NSW)
"149.15722886581,  -35.58816707119306",Williamsdale
"151.1953065388353,  -33.83251230135348",Wollstonecraft
"150.95017114035244,  -34.277182683943906",Wombarra
"150.39516000972498,  -34.41104260575625",Woodlands (Wingecarribee - NSW)
"153.16345071151198,  -30.099553208248317",Woolgoolga
"149.38020942064438,  -32.667173221877",Worlds End (NSW)
"149.54744339690333,  -36.488286273407155",Yankees Creek
"146.2255264891007,  -34.22662855726272",Yenda (NSW)
"145.69932924493025,  -37.268878164344464",Acheron
"146.09947233402815,  -38.35137599789047",Allambee South
"144.91369451874422,  -37.77703345076871",Ascot Vale
"144.38911749425188,  -36.86063993917729",Axe Creek
"144.5039665259847,  -37.10259018564201",Barfold
"146.08525763102355,  -36.02822746821291",Bathumi
"144.32042944773664,  -38.09482600060522",Bell Post Hill
"144.97197296396232,  -37.476165274615745",Beveridge
"142.92558615780038,  -35.99336309608863",Birchip
"144.0064255836021,  -36.55278865412523",Bridgewater North
"148.5914525762184,  -37.69520999839661",Brodribb River
"144.22171855294428,  -37.38960168656333",Bullarto
"143.32862799372006,  -38.4240858741475",Bungador
"144.66694170247243,  -36.477968019065656",Burnewang
"144.75564369797695,  -37.732240136879916",Burnside Heights
"145.85666476660856,  -36.073171737219305",Burramine South
"143.9030998150467,  -37.46402148426849",Cabbage Tree
"144.52100329834983,  -37.30450730535523",Cadello
"143.8186877552411,  -37.6402608105666",Cambrian Hill
"143.61833502357723,  -36.07631047643784",Catumnal
"147.13771400195319,  -38.07604294125708",Cobains
"143.08874183489795,  -38.337870532544535",Cobden
"143.01858873226394,  -38.292566285333734",Cobrico
"147.1831237125485,  -37.3874591074432",Cowa
"143.10925359640433,  -37.124070928331086",Crowlands
"143.4181147023982,  -36.80630351769639",Dalyenong
"144.42629562642207,  -37.637684198624015",Darley
"144.3194082976055,  -36.740998719936215",East Bendigo
"144.09369972414902,  -37.35594641827011",Eganstown
"145.7140867611088,  -37.86340794027401",Gilderoy
"145.65309063419977,  -38.503485014018295",Glen Alvie
"144.25275856699952,  -36.77380210208543",Golden Square
"145.87517105970775,  -36.488624111032586",Goomalibee
"143.19072754910655,  -35.41323478768905",Gowanford
"143.08751840698258,  -36.674285577405826",Gre Gre
"145.03053113135232,  -37.93880517884239",Hampton East
"145.09318689047961,  -37.95608070227556",Heatherton
"145.41925194937068,  -37.08657327854963",Highlands
"141.63156663394656,  -37.62439136064373",Hilgay
"147.34288237640038,  -37.69553720183524",Iguana Creek
"146.7397869032406,  -36.18623753930509",Indigo Valley
"143.8560208438922,  -36.59640122491711",Inglewood (Vic.)
"142.9761361430482,  -35.8218464215935",Jil Jil
"144.2869271630724,  -36.05637365253139",Jungaburra
"146.99342430493758,  -36.35239750467289",Kergunyah
"146.20817719902914,  -36.24794312772523",Killawarra (Vic.)
"144.49128033079867,  -36.92029702176886",Kimbolton (Vic.)
"145.1974264611376,  -38.15454197047232",Langwarrin
"145.57622919623563,  -37.77807083206194",Launching Place
"146.53400685861104,  -37.52357292951156",Licola North
"142.9563802397315,  -36.656891992544246",Marnoo East
"141.28388010583578,  -34.396358441219924",Meringur
"144.60795295050832,  -36.99003974166194",Mia Mia (Vic.)
"143.3230376104572,  -35.1278957595508",Miralie
"145.10777036666306,  -37.817683751031915",Mont Albert
"144.94542684456172,  -36.603967698487466",Moora (Vic.)
"144.95239183813496,  -37.23364955675456",Moranding
"143.24106262414313,  -35.614332632084114",Murnungin
"142.4740025322178,  -36.60305569057243",Murtoa
"145.569011367172,  -38.10585095138148",Nar Nar Goon
"142.7192114100585,  -38.39701034403363",Naringal
"145.30287802101824,  -38.055377735908024",Narre Warren South
"144.00812093436735,  -37.39487181486839",Newlyn North
"145.11534774027393,  -37.9039172447771",Oakleigh East
"141.02010774638504,  -35.12093568290403",Panitya (Vic.)
"145.2148218892134,  -37.77698617552203",Park Orchards
"145.29254068524358,  -37.471192171402556",Pheasant Creek (Vic.)
"146.12674115112478,  -37.17830265942738",Piries
"143.02493541414543,  -38.60961285339039",Port Campbell
"143.5295396657071,  -35.846762063990816",Quambatook
"142.0449818331867,  -36.74991464057014",Quantong
"145.8609108788487,  -38.37159533163926",Ranceby
"144.11286279448316,  -37.39310945763667",Sailors Falls
"147.1699780193365,  -38.32571301281011",Seaspray
"145.47553540957173,  -37.7926194007195",Seville
"143.77483930607937,  -36.998310055822564",Simson
"144.95728935253516,  -37.83393785019109",South Melbourne
"144.887962084673,  -37.82995771566973",Spotswood
"144.09541324299272,  -37.14418132211601",Strangways
"144.59794184088747,  -37.73250852670445",Strathtulloh
"143.854192088214,  -37.46271784510475",Sulky
"144.8110893429922,  -37.79810854953355",Sunshine West
"147.28232122072308,  -36.21419173724031",Tallangatta East
"147.9334447440223,  -37.491165832289425",Timbarra (Vic.)
"145.7134045594804,  -38.0151281859928",Tonimbuk
"146.592135412857,  -38.03590054867704",Toongabbie (Vic.)
"145.45221537623613,  -35.83602815153084",Ulupna
"145.9884669329708,  -38.81010129900423",Walkerville (Vic.)
"142.0458750361819,  -35.099240697108165",Walpeup
"146.98459984116093,  -36.79096199929485",Wandiligong
"149.48587889495806,  -37.391707761382506",Wangarabell
"144.56778794986377,  -37.727939730991935",Weir Views
"144.88997575191016,  -37.86118676450844",Williamstown (Vic.)
"146.82528685140525,  -38.32560811363929",Willung South
"146.25701077404108,  -38.6010584517724",Wonga
"143.659413762268,  -36.32745515870526",Woolshed Flat (Vic.)
"146.02618442246788,  -36.0844436368653",Yarrawonga South
"147.34549810623562,  -19.630872775958633",Airville
"151.62344574284327,  -26.759252979520816",Alice Creek
"153.01107281141302,  -27.568275393078352",Archerfield
"152.24924294185206,  -26.69672768227044",Avoca Vale
"151.8443113712618,  -28.57533349256943",Bapaume
"149.1222271844017,  -25.673807395200352",Baroondah
"145.58111482775047,  -17.556876521552006",Beatrice
"152.1163688505155,  -26.8676672957442",Blackbutt North
"146.5042687902797,  -19.219367963997055",Bluewater Park
"147.32434061048045,  -28.28610270472136",Bollon
"151.44892636154998,  -27.5666398630203",Bongeen
"152.98328551673603,  -26.281025768844966",Boreen Point
"151.8743405651223,  -28.661468804317057",Broadwater (Qld)
"152.87568788785208,  -27.983346195408664",Bromelton
"151.1458711342332,  -28.585597509056992",Brush Creek
"152.10245245907788,  -24.861493124017198",Bucca (Qld)
"148.46857421318822,  -22.98040466695788",Bundoora (Qld)
"151.20598561814091,  -23.946529092074584",Burua
"146.6821233429478,  -19.198991083446774",Bushland Beach
"152.49014974502805,  -27.149525407700242",Caboonbah
"152.64578767341538,  -26.29968718273037",Calico Creek
"142.5860491821669,  -20.366159326306008",Cambridge (Qld)
"153.01758056003675,  -27.880693052865873",Cedar Vale
"151.816877455413,  -26.26228507886165",Chelmsford
"153.03237298865608,  -27.385033413607896",Chermside
"150.62851941699398,  -26.755385088693416",Chinchilla
"151.91655735225808,  -27.937302738725926",Clifton (Qld)
"152.90238434939204,  -26.189519964127513",Coondoo
"148.5628185576018,  -21.26389507280028",Crediton
"152.9854546380524,  -28.03791095660093",Cryna
"153.15774895595052,  -27.630218637502082",Daisy Hill (Qld)
"152.98517976261888,  -27.222697125759172",Dakabin
"151.8968521451268,  -25.018370969406636",Dalysford
"152.95257021167006,  -27.568166326197",Darra
"152.30175644955435,  -25.229782284870012",Doolbi
"146.7511645340358,  -19.325237341326204",Douglas (Townsville - Qld)
"152.49961324484352,  -25.41095558091462",Duckinwilla
"148.3449688766756,  -22.55976765909825",Dysart (Qld)
"146.04544767646644,  -17.503726547534164",Eaton (Qld)
"149.08880745941545,  -21.09597558920618",Farleigh
"140.49199577250081,  -19.339452521775243",Four Ways
"148.50591253544215,  -20.341109840380934",Foxdale
"152.8149930392956,  -26.572536693410054",Gheerulla
"151.5582598995558,  -24.575137814656188",Gindoran
"151.96527188050072,  -28.850441483510394",Girraween (Qld)
"149.14686017476748,  -25.215909639597257",Glenhaughton
"152.7912695650144,  -26.08103549317904",Goomboorian
"151.84596690524128,  -27.30508417699233",Goombungee
"148.56502638074062,  -20.496395569574062",Gunyarra
"146.3097438943796,  -18.585723560170035",Halifax
"147.90285936302016,  -23.088608208970754",Hibernia
"142.46920192468505,  -14.226786375529958",Holroyd River
"150.66272189993614,  -26.887467035460936",Hopeland (Qld)
"152.76048411496114,  -27.622854218701747",Ipswich
"150.433467206755,  -24.209623535086674",Jambin
"141.6481401530781,  -20.660077397619702",Julia Creek
"152.18247722551342,  -27.810620085259988",Junction View
"151.3738611253862,  -24.70270165165741",Kalpowar
"151.83984359841236,  -26.531238378708036",Kingaroy
"153.14254649892047,  -26.801679419328647",Kings Beach
"153.23277167219914,  -27.82573775192354",Kingsholme
"149.5202727832476,  -25.666319014296114",Kinnoul
"146.72579413528635,  -19.303138000197528",Kirwan
"145.5578511714588,  -16.912535776016405",Koah
"153.09362204213275,  -27.606848076063624",Kuraby
"141.99717061386312,  -21.432482920621574",Kynuna
"152.76722984428167,  -27.448639445734354",Lake Manchester
"151.2804132504208,  -28.00027828970931",Lavelle
"150.41133334269048,  -23.609969110896213",Leydens Hill
"147.0082658562017,  -19.633817430695558",Majors Creek (Qld)
"152.01282626483984,  -24.96469312194178",Maroondan
"153.13799726374495,  -26.79255400816886",Moffat Beach
"147.05133476273429,  -19.532068772693815",Mount Surround
"141.86054712025586,  -12.643149456774118",Nanum
"152.83272581041152,  -27.617975219553266",New Chum
"151.40819692373887,  -25.793278938697853",Old Cooranga
"147.36076880761206,  -19.69762730538176",Osborne (Qld)
"153.46310532982778,  -28.11599957782381",Palm Beach (Qld)
"148.70088672431567,  -20.392827162931376",Palm Grove (Qld)
"152.9241958253795,  -26.636502262197105",Perwillowen
"152.03746303609512,  -27.16399428547565",Pierces Creek
"151.75717049021927,  -26.904517270748222",Pimpimbudgee
"143.06935731916087,  -10.050353965874146",Poruma Island
"151.87261003532163,  -28.532428623706835",Pozieres
"143.33577737451128,  -17.440394789441548",Red River
"147.53984514694977,  -19.63595190900738",Rita Island
"152.99963367000927,  -26.630259724371193",Rosemount
"152.865086176727,  -27.20622448793345",Rush Creek
"151.59309612507286,  -27.76968108478603",Scrubby Mountain
"152.4732684124501,  -26.0382513506575",Sexton
"146.0207063903251,  -17.74935128674944",Silkwood
"148.6116614206481,  -25.734602257666985",Simmie
"152.0851382447665,  -28.166914282325724",Sladevale
"153.42497666549977,  -27.838433604003463",South Stradbroke
"149.33157321634465,  -28.60613761443944",South Talwood
"153.10087729844446,  -28.30307597424846",Southern Lamington
"151.38609757829806,  -28.103167008351853",Stonehenge (Toowoomba - Qld)
"153.06713940204045,  -27.62582614586609",Stretton
"146.32856385922278,  -24.905736573607815",Tambo
"150.7512771437906,  -23.17790154237203",Taroomball
"143.7900666778308,  -28.029819994143995",Thargomindah
"149.02617312483156,  -21.0574839887325",The Leap
"150.37317841761546,  -23.63046126356773",The Mine
"150.0803198605006,  -21.711863669307196",The Percy Group
"151.8141460984966,  -26.358142471893867",Tingoora
"146.14706946083254,  -18.71356147376733",Toobanna
"146.74265171543607,  -19.21256788210501",Town Common
"148.18061399780564,  -21.1393758450949",Turrawulla
"143.54520467019017,  -9.507456901184746",Ugar Island
"152.1816826145581,  -27.598335019609312",Veradilla
"152.25920694650205,  -27.46528991164538",Vinegar Hill
"152.68495759963582,  -25.40566596696817",Walliebum
"152.76565021075882,  -25.338310120994425",Walligan
"152.91589745353355,  -25.949551301297532",Wallu
"152.50110957722197,  -26.940548170169315",Woolmar
"153.09305683282454,  -26.552919986212608",Yaroomba
"138.65948306760632,  -34.67495541498239",Andrews Farm
"138.96945215464135,  -35.33487651868064",Angas Plains
"137.76206099980644,  -35.75958592681459",Ballast Head
"138.56977138161008,  -35.02349157402274",Bedford Park
"138.70827264476898,  -33.15859582457293",Belalie North
"138.75112477983419,  -31.10227167483997",Blinman
"137.5004145504553,  -34.73014908023005",Bluff Beach
"139.6826574738174,  -34.950128082039726",Bowhill
"138.87167540841443,  -35.13263076961154",Bugle Ranges
"138.47673663229028,  -33.11176152081173",Caltowie North
"138.61694607410405,  -35.086539907943234",Chandlers Hill
"138.69600402456257,  -34.968383213682614",Cleland
"135.7187344393868,  -34.66186626927613",Coomunga
"139.8890835709438,  -35.68125736720698",Coonalpyn
"138.6764499358715,  -34.86199577300907",Dernancourt
"135.76597855585263,  -34.725999373259754",Duck Ponds
"139.1477833673242,  -34.326447453248946",Dutton
"138.67699791317764,  -34.70085289663797",Elizabeth North
"138.83523829170943,  -35.38250333637788",Finniss
"138.744803818237,  -34.597636813878125",Gawler (SA)
"138.7338546443006,  -34.60435677433682",Gawler West
"138.58971780332467,  -34.787387205481195",Globe Derby Park
"139.9353488430153,  -34.195703558391074",Golden Heights
"138.6280994374704,  -34.86857208747287",Greenacres
"140.16520075960827,  -37.234049180701554",Greenways
"137.64049488533703,  -35.82103534138263",Haines
"139.0128316666622,  -34.15690383708229",Hampden (SA)
"138.62884618795692,  -34.87444083679796",Hampstead Gardens
"134.22186037974018,  -32.46118086558835",Haslam
"138.90429547032727,  -35.00340808788325",Hay Valley
"138.20117651379672,  -33.656654915912526",Hope Gap
"137.80477378709242,  -35.79866836961723",Island Beach
"138.33347034471316,  -33.55070600264088",Koolunga
"135.8528159265744,  -33.186132370584744",Koongawa
"138.92879312082914,  -33.831408024201174",Koonoona
"138.02891984286384,  -34.059771420429236",Kulpara
"138.59185647352658,  -34.60781324773589",Lewiston
"138.76267100512484,  -34.38162364712271",Linwood
"139.30955043804812,  -35.132392176442295",Long Flat (SA)
"140.6128835287217,  -34.747473837869755",Malpas
"138.56161244357295,  -34.9453357496703",Marleston
"138.47427653843596,  -35.20813832491797",Moana
"138.7549731513426,  -35.34961838387621",Mount Observation (SA)
"139.22763172902668,  -32.81681621261548",Nackara
"137.12855517251407,  -31.675154330951003",Oakden Hills
"138.54043807234342,  -35.09684715162507",Old Reynella
"139.2109610401736,  -35.04388992451382",Pallamana
"138.5032875320852,  -32.83423885171276",Pekina
"137.8596244412499,  -34.57837639280323",Pine Point
"135.9266676113088,  -34.61319304438716",Point Boston
"137.5098318272288,  -34.67298330057639",Port Rickaby
"138.52564967662605,  -35.02603122191849",South Brighton
"138.64788135379325,  -34.951988026487854",St Georges
"139.22606678116526,  -34.156640885224185",Sutherlands
"134.85858023069096,  -33.31279140583062",Talia
"139.94944946751028,  -36.57690572767683",Taratap
"137.32981923131115,  -35.020904348017936",Warooka
"138.26185442412066,  -34.447452003416025",Webb Beach
"138.56301921602306,  -34.90522338804759",West Hindmarsh
"140.70188526979976,  -36.567747437270505",Western Flat
"138.09598518272261,  -35.83151615634498",Willoughby (SA)
"115.48841539772829,  -33.683437007384",Abba River
"116.44781843559198,  -31.731871949493765",Bakers Hill
"117.1601778142454,  -31.949702511710647",Balkuling
"116.44600225257251,  -32.679538021291414",Bannister (WA)
"117.85808396665144,  -30.778647480068372",Bencubbin
"118.88779330062033,  -31.351773365356514",Bodallin
"115.04654801611211,  -34.12411947458407",Boranup
"116.48363592554853,  -33.483433496143896",Bowelling
"121.97541620996931,  -30.747646215059337",Bulong
"124.26956151985951,  -18.002944006239062",Camballin
"119.7089197866807,  -23.937838190769334",Capricorn
"116.23219439184703,  -33.4769184210592",Cardiff (WA)
"116.0833759522286,  -32.02154391782314",Carmel
"118.3189888874549,  -34.873856785741665",Cheynes
"115.89984011639784,  -31.813984221749095",Cullacabardee
"115.7548924631033,  -29.389845949642805",Dudawa
"121.68215884056801,  -32.47357745766997",Dundas (WA)
"115.95876578579103,  -32.00909498944086",East Cannington
"115.76966050555012,  -32.26244133531163",East Rockingham
"121.88943763639546,  -33.86068396515023",Esperance
"115.85099007794686,  -33.43941857369659",Ferguson (WA)
"115.67674864094721,  -32.200945942836555",Garden Island (WA)
"116.39792735258709,  -20.83936057301951",Gnoorea
"116.09761699413667,  -31.87500428014767",Hovea
"116.13788366284396,  -32.10523322079223",Karragullen
"117.57679181790382,  -33.69068858773381",Katanning
"115.73825761627036,  -31.7179965719004",Kinross
"116.98140446274513,  -32.205733272360916",Kokeby
"117.51819768300729,  -31.24339857832787",Korrelocking
"115.15944586556037,  -34.247131932622906",Kudardup
"117.86752108574085,  -35.069664518689756",Little Grove
"117.93938078500315,  -34.947078950240204",Lower King
"115.84321562625905,  -31.711036067594037",Mariginiup
"115.75693820476701,  -31.841534003556028",Marmion
"117.17206996590586,  -33.28654735451582",Minding
"117.63443863666505,  -34.61757062630095",Mount Barker (WA)
"120.90934776564744,  -30.58527071799157",Mount Burges
"114.90500273990382,  -28.996724947571927",Mount Hill
"115.55008642443495,  -28.495034556974367",Mullewa
"116.03826369061406,  -30.37391545331744",Namban
"116.09602689869565,  -32.850726141488295",Nanga Brook
"116.66545288855565,  -31.652573461046234",Northam
"116.34844157881088,  -31.122873676094347",Old Plains
"116.83496512213013,  -34.002386073001986",Orchid Valley
"115.78592642789212,  -32.04503133376651",Palmyra (WA)
"115.91630141928462,  -32.13339070650065",Piara Waters
"118.61456197338507,  -20.3380964330003",Redbank (WA)
"115.8677850861323,  -32.03976082104199",Rossmoyne
"117.35354155148022,  -32.15203626863781",South Quairading
"118.12302353939633,  -34.57237251261435",Takalarup
"116.0197409824271,  -32.73687890823435",Teesdale (WA)
"116.90816809199104,  -34.84538232610519",Trent
"127.55067906393141,  -25.287456277701978",Wanarn
"115.80886115493944,  -31.839739740597448",Warwick (WA)
"115.75936334713134,  -31.850992302477998",Watermans Bay
"117.61334184194241,  -35.10002061948865",West Cape Howe
"117.33387994094942,  -33.60499130752663",Westwood (WA)
"116.73077238012381,  -33.058029672372236",Williams
"117.61052533698233,  -32.622667470096395",Yealering
"115.61758243840826,  -33.74709628014009",Yoganup
"146.3359656785788,  -41.284700538594734",Acacia Hills (Tas.)
"146.3723210064705,  -41.203132336660026",Ambleside
"148.18879902824895,  -41.08237282841914",Ansons Bay
"144.68462304587473,  -41.0539403902617",Arthur River (Tas.)
"147.4770257509125,  -41.44716484433156",Burns Creek
"145.96249139902136,  -41.176571247385894",Camena
"147.35811446211628,  -42.52198111122495",Colebrook
"146.9193969091226,  -41.239831047798134",Deviot
"148.14413255769463,  -42.08918651129428",Dolphin Sands
"147.257947729352,  -43.05313725052794",Electrona
"146.85999399706108,  -41.99794341777527",Flintstone
"145.23881370319006,  -40.85119838268919",Forest
"147.13049648028363,  -43.258793141416064",Garden Island (Tas.)
"148.0682282189401,  -41.00498523497308",Gladstone (Tas.)
"147.26476123760872,  -42.75064885305861",Herdsmans Cove
"145.82052913475547,  -41.1991864386652",Highclere
"147.79723033019653,  -39.83168207186132",Killiecrankie
"147.16231515716638,  -42.04631027686954",Lake Sorell
"147.65992160341673,  -42.297281215303336",Lemont
"146.89762226726074,  -41.27088444836753",Loira
"146.58723637251654,  -42.158218863258156",London Lakes
"147.08293727961376,  -41.00326724873659",Lulworth
"147.24741795293227,  -42.64784487663371",Mangalore (Tas.)
"148.08070623075434,  -42.64718552198529",Maria Island
"147.1988207007231,  -42.453398579139794",Melton Mowbray
"145.26370444370002,  -40.937187218202375",Mengha
"147.35836627372552,  -42.86438079916434",Montagu Bay
"145.61263806599237,  -41.00537615644361",Moorleah
"147.39325940375608,  -41.68339153615086",Nile
"146.08981199548418,  -41.202526565718",North Motton
"145.56568617219023,  -41.091675507778504",Preolenna
"144.7782910970369,  -40.96799262681336",Redpa
"146.72277533307036,  -41.44755906784913",Reedy Marsh
"148.24834506797666,  -41.4625079444721",Scamander
"146.5566200554985,  -41.18866669983692",Squeaking Point
"148.30443215051483,  -41.333454704274196",Stieglitz
"146.32963750104298,  -41.196235921659316",Stony Rise
"147.11975031766772,  -41.466229182048096",Summerhill
"145.61375406880788,  -41.1603883547472",Takone
"147.7209972092347,  -41.42715498248207",Upper Esk
"147.08035697215163,  -43.08061549951874",Upper Woodstock
"146.4949810588051,  -41.64670943874888",Western Creek (Tas.)
"147.59996149687018,  -42.47741975838464",Woodsdale
"145.71773301814542,  -41.00956388088653",Wynyard
"130.99342138438917,  -12.496854994940016",Bakewell
"131.37439437499052,  -21.64166701043308",Chilla Well
"133.89760792421123,  -23.690131294593385",East Side
"134.57797966846252,  -25.573133008757406",Finke
"135.79545925828984,  -12.505555820179431",Gapuwiyak
"133.86604060306507,  -23.709155878324193",Gillen
"131.06195125925535,  -12.4715623604787",Howard Springs
"133.83113599268358,  -23.680232272507077",Larapinta (NT)
"130.9731966227853,  -12.50557370610813",Moulden
"131.8262008900948,  -13.823560560011767",Pine Creek (NT)
"134.92592347293024,  -12.328992277913686",Ramingining
"130.80242999348994,  -13.948410541596292",Tipperary (NT)
"131.35058878337568,  -12.10559579153411",Van Diemen Gulf
"133.95262476377755,  -20.02871434071793",Warumungu
"149.10843036868548,  -35.34144357753867",Garran
"149.1906910404555,  -35.31197315326526",Pialligo
"149.0642767063831,  -35.19875083302983",Spence (ACT)
"150.07596148984643,  -36.34805426201031",Akolele
"150.79485796597916,  -34.561815360517045",Albion Park Rail
"150.80886410550585,  -34.208508826512215",Appin (NSW)
"150.78812962484264,  -31.72658961655891",Ardglen
"152.05316871241658,  -31.961685188586497",Back Creek (Mid-Coast - NSW)
"150.46036021069804,  -32.40159621173802",Baerami
"149.4340765189739,  -33.932959698854255",Bald Ridge
"151.4244190361759,  -30.2431035651869",Baldersleigh
"151.19265092391842,  -33.85717908491036",Balmain East
"150.41657073160096,  -29.837099457140773",Bangheet
"150.05206219618717,  -36.50418023766898",Barragga Bay
"150.8070816338589,  -35.04308390144936",Beecroft Peninsula
"147.85476929777877,  -31.676558366973687",Beemunnel
"150.53346027179134,  -35.07884745119201",Bewong
"149.32282709327956,  -34.309831611172335",Binda
"152.27256310372348,  -32.50436496250208",Bombah Point
"148.9642579038175,  -32.980023408836395",Boomey
"147.3667369907637,  -35.07099864805702",Boorooma
"150.88412381384254,  -33.86184886583751",Bossley Park
"150.10788063098948,  -33.480692516783",Bowenfels
"151.12778961338088,  -32.258472777259016",Bowmans Creek
"150.42587313459967,  -34.48472840666905",Bowral
"151.36670474283372,  -30.177703595732694",Briarbrook
"149.0818095416164,  -34.709021111290944",Broadway
"144.47302105664343,  -32.0085204931321",Bulla (NSW)
"153.0583507262099,  -30.41321555195369",Bundagen
"150.43765020041585,  -34.8755121497332",Burrier
"152.6814868615893,  -31.344374403482067",Cairncross
"150.72882203963016,  -34.55839424295099",Calderwood
"148.09926094364408,  -35.24658426392009",Califat
"151.10273752813202,  -33.91256342456655",Campsie
"149.6666402951654,  -36.78810535754452",Candelo
"152.01810231614778,  -32.66066732249313",Carrington (Mid-Coast - NSW)
"152.06288997982088,  -31.580993196219087",Cells River
"149.80726729231736,  -34.53944260086294",Chatsbury
"150.9592410186772,  -33.91804445518696",Chipping Norton
"152.9428512565931,  -29.51431179612636",Clifden
"150.31956247183322,  -35.55354692185406",Cockwhy
"151.21884811486038,  -32.950352659881744",Corrabare
"150.7513807104401,  -34.92800673781146",Culburra Beach
"150.67605878049687,  -31.239178313993964",Currabubula
"150.76488180773262,  -34.62312315193728",Curramore (NSW)
"151.35591376992176,  -33.50534337761294",Daleys Point
"147.96587855413387,  -35.3430863285809",Darlow
"151.93352522179094,  -29.437765866914",Deepwater (NSW)
"150.21133274918682,  -35.75098180010929",Denhams Beach
"149.64905778283247,  -36.875563989244206",Devils Hole
"152.66187125025533,  -28.617117843420058",Duck Creek
"151.18926634727177,  -33.6741359013878",Duffys Forest
"149.87156487096686,  -35.339550116740796",Durran Durra
"152.4187552415426,  -31.481689909749996",Ellenborough
"150.66470836582943,  -33.7465538378764",Emu Plains
"151.01388603619287,  -34.054996486985175",Engadine
"145.94189643115,  -29.253473404119188",Enngonia
"149.71389421436436,  -33.74061898331907",Essington
"150.95244500508448,  -33.871152663685365",Fairfield (NSW)
"152.94509526714256,  -30.476203013155633",Fernmount
"152.16272848536076,  -32.75025642615221",Fingal Bay
"152.0952704889088,  -32.78503957382861",Fishermans Bay
"151.73317958492126,  -31.677451846575675",Giro
"152.88583765228182,  -30.380849661391288",Gleniffer
"148.70147893142337,  -34.177374889793875",Godfreys Creek
"152.82096737945724,  -31.617228207785068",Grants Beach
"150.67008406760488,  -34.057909752731334",Grasmere
"149.68163953632722,  -32.791725029189585",Green Gully (NSW)
"152.5182855434362,  -32.249458667805904",Green Point (Mid-Coast - NSW)
"149.25332615681003,  -35.36703669631762",Greenleigh
"148.07085298999573,  -36.06324738268602",Greg Greg
"152.54244074662392,  -28.6599931067371",Haystack
"146.88009851592685,  -31.835339163197343",Honeybugle
"150.8477446639795,  -33.84018132655785",Horsley Park
"150.73697607171601,  -34.49776919454215",Huntley (Wollongong - NSW)
"153.36288056416495,  -28.537450779748927",Huonbrook
"150.6507999992052,  -35.038543115464854",Huskisson
"145.455164999568,  -32.52232819204778",Irymple (NSW)
"151.06469370081786,  -34.01585787371067",Jannali
"149.5368339473341,  -33.84858879524268",Judds Creek
"148.94785084400607,  -30.71572904639761",Kenebri
"150.8469577408328,  -34.64029879352052",Kiama Downs
"150.7111197154333,  -34.03708367976452",Kirkham
"151.31644769978342,  -33.46855244055986",Koolewong
"151.19514290990395,  -34.0232540136808",Kurnell
"150.70228400925157,  -33.50365718358429",Kurrajong
"150.4107395542195,  -35.430658676429935",Lake Tabourie
"146.020880806976,  -34.23196137986653",Lake Wyangan
"151.1547159050031,  -33.882356793590134",Leichhardt (NSW)
"150.8530499985647,  -33.91340841319246",Len Waters Estate
"153.58202219405297,  -28.788668306003416",Lennox Head
"148.92365664696348,  -33.321378485306525",Lidster
"151.161753748708,  -33.78012334301381",Lindfield
"152.50200898701678,  -29.182209454244546",Lionsville
"151.13598471596003,  -33.3993896189463",Lower Mangrove
"151.54929067293412,  -32.738709568388536",Maitland (NSW)
"149.430444614513,  -32.734940528642404",Maitland Bar
"150.98243892618228,  -33.461737645571475",Maroota
"151.3918151044471,  -33.051248057018235",Martinsville
"152.49918407705945,  -31.812988805755346",Melinga
"151.072635799484,  -33.81481887397058",Melrose Park (NSW)
"153.29060185033228,  -28.85207429803299",Monaltrie
"146.68368332824608,  -34.343259625917085",Moombooldool
"150.16779080015417,  -33.467679772660205",Morts Estate
"151.6701515618676,  -32.97855470314317",Mount Hutton (NSW)
"149.93392393150316,  -33.60523593785707",Mount Olive (Oberon - NSW)
"151.267489431105,  -32.84796998565612",Mount View
"148.32494202020683,  -34.54233935301525",Murrumburrah
"149.82632313682177,  -34.468023527406984",Myrtleville
"152.39530291818488,  -32.119418675338146",Nabiac
"149.772037333569,  -37.21871734279474",Narrabarba
"149.84848951688318,  -36.13177394707127",Nerrigundah
"153.30018972961474,  -29.139273717007406",New Italy
"151.71047314490983,  -32.92742520879039",New Lambton
"151.05486676657125,  -33.83859372738135",Newington (NSW)
"150.27240767656903,  -33.32534503441898",Newnes Plateau
"153.54918472579752,  -28.7271990914165",Newrybar
"152.05030119246783,  -32.618048297990335",North Arm Cove
"150.99417056004748,  -33.78733993180527",Northmead
"150.2385011858683,  -32.74495405575383",Nullo Mountain
"150.6824481376754,  -34.876381610533876",Numbaa
"149.73804506696138,  -33.554711518264874","[""O'Connell (NSW)""]"
"151.55698323603363,  -32.703287888530134",Oakhampton
"149.54439789392896,  -34.76199512732764",Parkesbourne
"151.08610986974952,  -33.96389514958676",Penshurst (NSW)
"148.4012769234952,  -36.405470859634605",Perisher Valley
"148.78469642310316,  -32.479912137214626",Ponto
"150.89446775917492,  -34.471605562963234",Port Kembla
"144.84323596370484,  -35.38850887172417",Pretty Pine
"149.68907542656575,  -32.74343543101082",Queens Pinch
"151.60792142136324,  -32.7262408943868",Raworth
"151.19077195226464,  -32.56350476486367",Redbournberry
"151.70965878743866,  -33.00913811654195",Redhead
"153.40446511493425,  -28.63376233004124",Repentance Creek
"151.05335397438003,  -33.94630265604705",Riverwood
"149.50974294912967,  -36.92263715183733",Rocky Hall
"151.055679457767,  -33.87424362294929",Rookwood (NSW)
"150.80917376519713,  -34.71816151144103",Rose Valley (Kiama - NSW)
"151.51536808845816,  -32.63731512413097",Rosebrook (NSW)
"153.54377560967885,  -28.356806077788118",Round Mountain
"151.1734216283776,  -33.865035679574895",Rozelle
"151.14093552478857,  -33.85891798261722",Russell Lea
"146.88321518854517,  -35.53596279346757",Ryan (NSW)
"153.09613338018232,  -29.766075935284945",Sandy Crossing
"151.29114077189408,  -33.63984009546687",Scotland Island
"150.15257747049404,  -33.49616763509466",Sheedys Gully
"151.1755924368694,  -32.567063407626726",Singleton (NSW)
"150.10866318132858,  -33.51932000459762",South Bowenfels
"150.59851405511668,  -34.9081259478406",South Nowra
"150.97010699169854,  -33.821091073761366",South Wentworthville
"151.07699416730935,  -33.89506595527768",Strathfield South
"149.64122628576277,  -33.61717655012872",Tannas Mount
"151.08601181285607,  -33.32388618441171",Ten Mile Hollow
"153.50633715288149,  -28.23912764173764",Terranora
"151.50646412252206,  -33.33132277992543",The Entrance North
"148.12868569188058,  -34.172505136618675",Thuddungra
"149.30833238123597,  -35.694264923630506",Tinderry
"151.55854408738412,  -32.61763254158722",Tocal
"151.47982361164466,  -31.82410711231974",Tomalla
"153.14225193233045,  -28.86453943298528",Tomki
"152.54591897804735,  -30.899196285382732",Toorooka
"153.35132480120262,  -28.861383751452884",Tregeagle
"147.60804655782184,  -32.56302542401526",Tullamore
"146.91630884920443,  -33.57342957914067",Ungarie
"152.61331093561336,  -31.231362874521157",Upper Rollands Plains
"150.84944245131933,  -33.64322613436731",Vineyard
"150.82476125416477,  -31.040048381424054",Wallamore
"150.5588542560993,  -31.614146595624383",Warrah Ridge
"151.51273016014534,  -32.582395154376385",Webbers Creek
"151.2812351144446,  -33.72797344760915",Wheeler Heights
"152.85504520239684,  -29.56486488040489",Whiteman Creek
"149.01910798660612,  -33.2083452514754",Windera (NSW)
"150.97980907927524,  -33.395392005647835",Wisemans Ferry
"153.40882377091597,  -28.819171862784227",Wollongbar
"150.75192929785746,  -34.47361484191494",Wongawilli
"150.5182479208874,  -30.728460761866838",Wongo Creek
"150.39466512208884,  -35.389547165198735",Woodburn (Shoalhaven - NSW)
"152.59001665856283,  -28.37157648134718",Woodenbong
"150.63910327262477,  -35.01430075284315",Woollamia
"151.47843460671297,  -33.23898328306395",Woongarrah
"152.35480295137984,  -31.429802339604784",Yarras (Port Macquarie-Hastings - NSW)
"146.3880654978697,  -38.681790319108806",Agnes
"144.76865086937033,  -37.745926676083315",Albanvale
"146.05201250262195,  -38.30761454878818",Allambee Reserve
"143.5455544274967,  -38.43212795771053",Barongarook West
"142.6924683802415,  -37.13779222913833",Bellellen
"145.33993566297957,  -38.21400062891921",Blind Bight
"145.12754655759264,  -38.06305099932595",Bonbeach
"146.25725141897107,  -38.56781181546215",Boolarong
"142.44311058126812,  -36.05116979040545",Brim
"142.99484608092408,  -37.12976201907873",Bulgana
"145.72514894240607,  -38.06106561503081",Bunyip North
"146.7705902726641,  -37.490901012002894",Buragwonduc
"141.97846267180554,  -37.86069586425004",Byaduk North
"144.51306439292856,  -37.2877774916311",Carlsruhe
"143.2500390682468,  -38.38756046396966",Carpendeit
"141.51742864615736,  -36.952565254016896",Charam
"146.05140224572415,  -36.41249681995751",Chesney Vale
"143.97466239448522,  -37.70208182419839",Clarendon (Vic.)
"145.4188212732241,  -37.932410184904384",Clematis
"144.997484436296,  -37.79042965904454",Clifton Hill
"145.1574807687618,  -37.3566661663335",Clonbinane
"145.35865269707125,  -38.10191994703733",Clyde North
"145.2353752715878,  -38.470840739741575",Cowes
"146.213061249427,  -38.39370768700185",Darlimurla
"146.61319692094108,  -38.523133052803914",Devon North
"145.9981165642944,  -37.38798557978749",Eildon
"144.01303412131816,  -37.7657309647064",Elaine
"143.01272850842827,  -38.37334452821493",Elingamite
"143.77574121906795,  -37.04724372012151",Flagstaff
"142.5766441073015,  -37.09376780938007",Fyans Creek
"142.15469648311742,  -37.91272136670321",Gazette
"142.75848125418742,  -36.976625707298695",Germania
"143.20318521697993,  -37.1250387318137",Glenlofty
"144.27447747315398,  -37.063352741941934",Golden Point (Mount Alexander - Vic.)
"146.22912181808607,  -36.53785347881304",Greta West
"145.39058560957358,  -38.01482030935599",Guys Hill
"142.52869955544804,  -37.12552400635234",Halls Gap
"146.29207812992587,  -36.61081900716427",Hansonville
"146.80748692335368,  -38.61310074352764",Hunterston
"146.11350832318305,  -37.278741770222396",Jamieson (Vic.)
"144.94975259369602,  -37.30166738087119",Kilmore
"145.75099504088536,  -36.488820012876616",Koonda
"145.81512904664632,  -38.437825944160984",Korumburra
"145.06777449540596,  -36.38495239335631",Kyabram South
"148.06171043264834,  -37.858429710051674",Lake Tyers Beach
"145.58199873330173,  -38.29541158610824",Lang Lang
"141.76101686100859,  -36.502587535627725",Little Desert
"142.07957939143856,  -36.812315164066554",Lower Norton
"145.30083924374026,  -37.935150654127966",Lysterfield
"145.77809897629007,  -37.13115973104414",Maintongoon
"144.39708190964436,  -37.17908453760409",Malmsbury
"146.78916779388916,  -38.66064941623305",Manns Beach
"144.5717162434507,  -38.24022600426058",Marcus Hill
"147.50760783120904,  -37.44427702683552",Marthavale
"145.60423265560533,  -38.034231731253655",Maryknoll
"143.70753195456794,  -35.88554597094349",Meering West
"142.04749125413463,  -34.161343683415524",Merbein
"145.06451062033094,  -37.663977158603416",Mill Park
"141.70873448566152,  -38.050502883688814",Milltown
"143.7503294173851,  -36.021541316174854",Minmindie
"145.31107801719688,  -36.3247858057746",Mooroopna North
"141.33240085651124,  -38.15441197050205",Mount Richmond
"143.4672772066005,  -36.44853860471996",Nine Mile (Vic.)
"145.96933515234647,  -37.84523872196588",Noojee
"141.9195614643495,  -36.847982513896255",Noradjuha
"144.35339774538323,  -38.091984836566176",Norlane
"145.98191316331594,  -36.20587558916265",Pelluebla
"142.69205731743008,  -35.23738228402753",Pier Milan
"142.0859595619854,  -36.613869948761455",Pimpinio
"143.36351329650796,  -35.24889139879682",Pira
"145.78124486913543,  -38.28748618304605",Poowong North
"142.1805730092712,  -38.334604000109245",Port Fairy
"145.3825827314015,  -36.73203433813895",Pranjip
"146.4072781558463,  -37.96137757739084",Rawson
"145.03950733229277,  -38.38773313156045",Red Hill South
"143.83854795980176,  -37.5764901401456",Redan
"142.64527580333228,  -35.95790071515576",Reedy Dam
"146.94487132832066,  -38.00362910947417",Riverslea
"147.05646618592945,  -36.55047458858617",Running Creek (Vic.)
"143.9378590696512,  -36.515566557582005",Salisbury West
"146.73896600970227,  -37.58635561172089",Sargood
"145.28739476040116,  -37.58326504574599",St Andrews (Vic.)
"145.15101615189977,  -36.20782941223235",St Germains
"145.58334056773805,  -36.59035107696039",Tamleugh
"141.74268895359157,  -37.67890528704983",Tarrenlea
"145.21712738573856,  -36.44175173682383",Tatura
"145.56096692506722,  -37.002697603535125",Terip Terip
"144.37893489720759,  -38.16950468669835",Thomson (Greater Geelong - Vic.)
"146.1924995916097,  -38.172282498218344",Trafalgar East
"145.40346993180145,  -36.895479842762086",Upton Hill
"145.09121234961015,  -36.54805675441125",Waranga Shores
"148.32024467174995,  -37.767603464401844",Waygara
"144.03561567577927,  -37.06174583435757",Welshmans Reef
"144.09063270046462,  -36.728978282866514",Wilsons Hill
"146.21950122680295,  -38.592660635029645",Woorarra West
"144.60813454007268,  -37.85905370763168",Wyndham Vale
"146.3435542549355,  -38.19219850279669",Yallourn
"142.07524862209948,  -38.31739037880928",Yambuk
"145.60475476402266,  -37.091553701923715",Yarck
"144.02721734589,  -36.1557004823563",Yarrawalla
"149.09365751227318,  -21.16078425756447",Alexandra (Qld)
"153.36209342578007,  -27.93886348084913",Arundel
"145.48589293918104,  -17.266055044420003",Atherton
"149.6390603280727,  -23.312269035974914",Balcomba
"148.5593905694462,  -27.15433459502389",Ballaroo
"151.5252689250895,  -26.34492262076834",Ballogie
"149.71122235602908,  -23.988671959543023",Barnard
"145.8833647078066,  -17.43908255785443",Bartle Frere
"146.07364758285183,  -25.4035805598778",Bayrick
"145.92822393398137,  -17.276776936836896",Bellenden Ker
"151.80226278376492,  -24.63509837982505",Berajondo
"149.10887936246047,  -23.87998616294218",Blackdown
"152.31577346332674,  -27.65771132891644",Blenheim
"152.88993847361596,  -25.649452726718252",Boonooroo
"152.70628231066948,  -26.51434127646294",Brooloo
"153.16056077553935,  -27.56263369410072",Burbank
"152.66251591091765,  -25.359606309524985",Burgowan
"152.93443103945796,  -26.628555455217697",Burnside (Qld)
"148.7461853552749,  -20.897085828653072",Calen
"152.50961464414777,  -27.671201671502768",Calvert (Qld)
"151.70774323015962,  -25.66629814365879",Campbell Creek
"151.11850773566462,  -27.94073742340368",Captains Mountain
"152.90607832365583,  -27.293769350238293",Cashmere
"151.90766793021024,  -27.461452171420618",Cawdor (Qld)
"153.0539588843988,  -27.4196401030023",Clayfield
"151.8306460796428,  -26.118436009647947",Cloyna
"152.65262142590294,  -28.112942141390903",Coochin
"149.5249742695549,  -24.98097669374782",Coorada
"151.80514365180093,  -26.49745425433515",Crawford
"149.02863443400213,  -28.604967295003075",Daymar
"151.9398296507673,  -24.391948761978544",Deepwater (Qld)
"145.3422747216251,  -15.943472037133718",Degarra
"152.66552606635676,  -27.294233436481306",Dundas (Qld)
"152.7675714725424,  -25.276368479220043",Dundowran Beach
"145.66421609741607,  -17.507992012176313",Ellinjaa
"151.99407765584814,  -27.077006277230183",Emu Creek (Qld)
"153.0316487144589,  -27.338893532238878",Fitzgibbon
"152.36486808031833,  -27.583918784685096",Forest Hill (Qld)
"145.96709236256032,  -17.642763810752317",Germantown (Qld)
"151.78099680589847,  -26.084147913515473",Glenrock (Qld)
"153.0214915965774,  -26.76907235208826",Glenview
"145.74330151932506,  -17.195572395298218",Goldsborough (Qld)
"150.1158592955915,  -28.342146556040056",Goodar
"147.5917056898315,  -19.900781426790576",Gumlu
"153.3374715342677,  -27.900963167966538",Helensvale
"143.29256201514008,  -12.673001654875039",Iron Range
"152.8341131163863,  -27.411781834817983",Jollys Lookout
"151.76074279124333,  -27.389806693573902",Kelvinhaugh
"152.62792475566198,  -27.89967085355221",Kents Lagoon
"150.12388907723826,  -26.49902820673473",Kowguran
"145.5902929113166,  -17.33641605731334",Kureen
"151.16425405825075,  -27.71230597965416",Kurrowah
"148.8970966936515,  -21.03151105849248",Kuttabul
"152.7313071938444,  -27.23186390807434",Laceys Creek
"148.3252032605688,  -20.396191196096826",Lake Proserpine
"148.07960389257352,  -22.84623837736998",Lowestoff
"153.0328437378192,  -27.42187608439283",Lutwyche
"143.575521942978,  -16.947866342008762",Lyndside
"142.18154810146646,  -9.954847101824614",Mabuiag Island
"153.0734479720628,  -27.565366863039458",Macgregor (Qld)
"145.82401767056362,  -17.662501743659085",Mamu
"152.01121102317342,  -26.208706214422918",Manyung
"150.71513870983236,  -27.46166970068985",Marmadua
"152.66481697009849,  -25.499969430348518",Maryborough West
"139.49432897491232,  -20.76464898621974",Mica Creek
"141.66217404070912,  -22.581809484727142",Middleton (Qld)
"151.61166551612646,  -27.54261047432406",Motley
"152.70960347869445,  -28.280679831425942",Mount Barney
"152.35450085019303,  -28.2608084419713",Mount Colliery
"152.433020185185,  -27.776555930215974",Mount Mort
"153.2686274072743,  -27.982718809108732",Mount Nathan
"148.42050496858855,  -20.301084142823484",Mount Pluto
"151.13737640262596,  -24.972896567232677",Mulgildie
"146.01764822463588,  -17.558594504494923",Mundoo
"150.18856581165548,  -26.565411124783488",Myall Park (Qld)
"151.98585274828093,  -26.677637057826146",Nanango
"152.72757695880574,  -25.95666081154705",Neerdie
"148.53992091236805,  -21.125480487470163",Netherdale
"146.06603151576962,  -17.621269750987032",New Harbourline
"153.00787238472955,  -27.435231705014537",Newmarket
"145.66594839112878,  -17.33301089353646",North Johnstone
"151.68280521598248,  -27.029175712826156",North Maclagan
"148.78763551309703,  -26.489725682482508",Orange Hill
"152.80419531904988,  -28.291910461728754",Palen Creek
"146.01075016397658,  -19.118195533515596",Paluma
"151.23446610669106,  -27.04513261163899",Pirrinuan
"144.2750233462538,  -20.099212565440386",Porcupine
"141.8074204239394,  -14.719425079861391",Pormpuraaw
"151.98806686646924,  -27.72376121431843",Ramsay (Qld)
"146.8995811872613,  -20.20753070337456",Ravenswood (Qld)
"152.8735156394418,  -27.602720351002738",Redbank (Qld)
"145.69561035991651,  -16.925357706109818",Redlynch
"151.98945000953987,  -27.565891585513803",Redwood
"153.12299256456444,  -27.570302002213445",Rochedale
"152.08011783829696,  -26.556954380448495",Runnymede (Qld)
"146.0395679596836,  -17.65859068721144",Sandy Pocket
"152.59361802412943,  -27.887747396419993",Silverdale (Qld)
"142.50823930599188,  -10.832847330242997",Somerset (Qld)
"139.46958855457189,  -17.064185166117486",South Wellesley Islands
"139.50148618905382,  -20.761642744348453",Spreadborough
"151.97733846097972,  -27.463035918104353",Spring Bluff
"153.04663420315026,  -27.499409252325858",Stones Corner
"153.05212061171335,  -27.595935412953974",Sunnybank Hills
"139.61709089113936,  -17.102232693376646",Sweers Island
"151.2335542795946,  -24.10617097700315",Taragoola
"145.54817716942424,  -17.158905118627924",Tinaroo
"146.25155627082856,  -20.050976446442444",Toll
"146.4631437865352,  -19.07930474369776",Toomulla
"152.98382580593443,  -27.48122914921017",Toowong
"139.5058522644593,  -20.7314543830521",Townview
"153.01659966707612,  -27.916781634212516",Veresdale Scrub
"146.2084360826501,  -18.642656355331663",Victoria Plantation
"142.7142617649606,  -19.412117408193872",Victoria Vale
"146.00716843164696,  -17.57302101906802",Wangan
"151.83460981649296,  -26.153749938669378",Warnung
"151.99738925991764,  -27.29507384457303",Whichello
"147.4131069930398,  -23.594817802224892",Willows
"152.61722111338852,  -27.434082586324692",Wivenhoe Pocket
"145.43918944394014,  -17.430229025515935",Wondecla
"151.75438889365702,  -26.111026665054336",Wooroonden
"151.8845690090681,  -28.85726983097641",Wyberba
"150.51580550024354,  -28.08121762002458",Yagaburne
"146.4939942725247,  -25.12529633930674",Yandarlo
"131.20604944855327,  -27.078352330271947",Anangu Pitjantjatjara Yankunytjatjara
"138.649889805364,  -34.63856505679764",Angle Vale
"138.93675844925485,  -33.91211601046944",Apoinga
"137.1921746172645,  -31.204211934924732",Arcoona
"137.90454903935105,  -34.431915899554355",Ardrossan
"140.88033367034228,  -36.58152714433205",Bangham
"138.976526193282,  -34.81781823684382",Birdwood (SA)
"135.97624761438323,  -33.56250278263209",Boonerdo
"138.6608969231455,  -34.88134039198618",Campbelltown (SA)
"138.5140050366726,  -32.41596479816549",Carrieton
"140.91171903771223,  -33.95510409843139",Chowilla
"138.58365141334892,  -34.96397113332262",Clarence Park
"139.7132368306219,  -34.82473713838119",Claypans
"140.189124963798,  -37.10921167525737",Conmurra
"139.33232455614979,  -34.92257261847582",Cowirra
"138.56550190361986,  -34.89528772446381",Croydon (SA)
"138.7930962808937,  -35.444938316271966",Currency Creek
"138.66839130579777,  -34.691089046380526",Davoren Park
"139.1383215443606,  -34.65999583828455",Eden Valley
"138.66389068515758,  -34.928908149552825",Erindale
"138.64694140258905,  -34.88900355700962",Felixstow
"139.0409915440792,  -34.61978927229147",Flaxman Valley
"138.81485534427966,  -34.44598377346191",Freeling
"138.53488101377732,  -34.8074224168488",Garden Island (SA)
"139.21540593210048,  -33.977079461601924",Geranium Plains
"138.46803144121225,  -33.467069934373804",Gulnare
"138.51109207842606,  -35.072948187625805",Hallett Cove
"138.66500208684076,  -34.35221221312667",Hamley Bridge
"139.00326192376878,  -35.06542543506161",Kanmantoo
"130.14556831646664,  -26.1566532958898",Kanpi
"138.58563601594395,  -34.85971040265984",Kilburn
"135.31569448924367,  -30.91717530215242",Kingoonya
"140.66674159652226,  -34.26692867285474",Lyrup
"138.61074956159857,  -34.810570523582584",Mawson Lakes
"138.68660744147573,  -33.35998786345507",Mayfield (SA)
"139.20953170005106,  -34.8006389088351",Milendella
"138.7529113649516,  -33.92749516468578",Mintaro
"137.91250783144687,  -32.72345491082212",Miranda (SA)
"139.12077793685754,  -34.468495697651264",Moculta
"138.38433882199175,  -32.333839311894494",Moockra
"139.09212374769103,  -34.56603188448601",Mount Mckenzie
"134.0625258385944,  -32.13567506284976",Mudamuckla
"140.7895325002195,  -34.21100838992908",Mundic Creek
"135.74274472142247,  -33.7314663221792",Murdinga
"137.59872492543738,  -35.580635699888674",North Cape
"138.98328816664844,  -34.46596600762926",Nuriootpa
"138.5445976671264,  -35.006796214605615",Oaklands Park
"140.7761318008028,  -37.890518103010606",Ob Flat
"136.87784324397072,  -30.481427758756332",Olympic Dam
"138.6309899621335,  -34.79661875814089",Parafield
"137.79376035005671,  -35.829845802143545",Pelican Lagoon
"132.91137984933917,  -31.902841291867528",Penong
"140.14961163660683,  -36.36018079312245",Petherick
"129.14710271340007,  -26.170723292661865",Pipalyatjara
"138.55148048513254,  -34.975893557940495",Plympton Park
"137.26617383282706,  -34.928517711990715",Point Souttar
"134.85988376181444,  -32.70202839059242",Poochera
"137.8806688764099,  -33.241511775949945",Port Davis
"137.55529103593472,  -34.0832152422151",Port Hughes
"138.57246696154223,  -34.86257900428458",Regency Park
"138.8393156241928,  -34.5567053296145",Rosedale (SA)
"139.85577028412777,  -36.820790401148926",Rosetown
"137.9457891502434,  -32.46870590033704",Saltia
"138.85815202599974,  -35.32326193481138",Sandergrove
"140.58211045479294,  -36.08286455255765",Sherwood (SA)
"140.5388181794539,  -37.35262426782321",Short
"138.1969128641284,  -33.76819192517337",Snowtown
"140.45875198450398,  -34.342399297242295",Spectacle Lake
"138.87043614851862,  -35.25220559435104",Strathalbyn (SA)
"140.75795908989227,  -37.782093923152196",Suttontown
"140.88647894697064,  -34.58809476613755",Taplan
"138.48581218572988,  -34.885851064502134",Tennyson (SA)
"136.34122857299113,  -33.35085910786424",Waddikee
"137.85214964151018,  -33.67727376201666",Ward Hill
"136.06794835333486,  -31.263969371310118",Wirraminna
"138.91266431185053,  -35.11713683008929",Wistow
"139.5588927502811,  -34.69955886134263",Wongulla
"140.89660881494524,  -34.200074922376935",Wonuarra
"137.97543428727428,  -32.55860485568655",Woolundunga
"135.6411951057423,  -32.311951750078826",Yardea
"118.04201517442496,  -32.14297493094648",Babakin
"116.5329420819369,  -31.393702517220362",Bejoording
"116.8922703787995,  -32.09922430532866",Beverley (WA)
"115.81848581886429,  -32.09920414737303",Bibra Lake
"114.93739876678934,  -29.202766688111975",Bonniefield
"117.57528678515102,  -35.05480984520809",Bornholm
"119.21203355730593,  -34.381529589184254",Bremer Bay
"115.34993772594416,  -33.655381105718874",Busselton
"116.05637716090317,  -31.47944034000047",Chittering
"117.71276519108446,  -33.583316513553704",Coblinine
"115.75052736210084,  -31.74858446196703",Connolly
"113.77382333147683,  -23.144365436960364",Coral Bay
"115.61504848688486,  -33.40841423369827",Dalyellup
"118.2021468581362,  -30.986655084193778",Dandanning
"122.23496700616246,  -17.939812173124768",Djugun
"115.85465009043904,  -34.029971093196586",East Nannup
"117.7119189325747,  -32.77231188010661",East Wickepin
"121.62889272942502,  -30.98060908375901",Feysville
"119.71175695597869,  -32.43478151715856",Forrestania
"115.6829657858819,  -33.34279718197553",Glen Iris (WA)
"116.84285336185638,  -31.29658285503677",Goomalling
"115.97496103339851,  -31.897447148038037",Guildford (WA)
"115.88730058988435,  -32.86795578585437",Hamel
"121.45492375556071,  -30.71911720158632",Hannans
"115.91523930252421,  -33.07862312187153",Harvey
"116.82206277655446,  -34.92003620978235",Hazelvale
"115.81176024788058,  -32.19700501648351",Hope Valley (WA)
"116.63177919428783,  -23.39101560608531",Innawanga
"115.85697444369725,  -31.739044311994633",Jandabup
"114.20301964706908,  -27.68394375359975",Kalbarri
"128.8461593515045,  -15.578569404115504",Kununurra
"122.28782049776518,  -26.176416369060185",Lake Carnegie
"117.89964500793918,  -34.133709602233516",Lake Toolbrunup
"116.1495118798064,  -21.183172659800622",Mardie
"114.9665907598071,  -28.434119357230223",Marrah
"115.79901650825656,  -32.23406239876715",Medina
"115.70921777556067,  -31.693390083861853",Mindarie (WA)
"115.43744818377228,  -29.198755149437666",Mingenew
"116.00115413684192,  -29.206572043092173",Morawa
"115.8375737766044,  -32.06987714141803",Murdoch
"116.2111527017457,  -30.97157323672608",New Norcia
"115.40519387677016,  -30.950434221444706",Nilgen
"116.41247247490402,  -32.4275859987805",North Bannister
"116.02891768554159,  -32.038857211399396",Orange Grove (WA)
"117.08445900223488,  -32.52825289985337",Pingelly
"116.89481003067039,  -32.696232461617875",Pumphreys Bridge
"115.36530741166025,  -34.027738207350204",Schroeder
"116.39366866335158,  -34.73204038686685",Shannon (WA)
"115.7359354564734,  -32.50857295354172",Silver Sands
"116.6675257881072,  -32.023225604015174",Talbot (WA)
"115.22639695572079,  -33.85404402363285",Treeton
"117.02822606124731,  -31.323511700435223",Ucarty
"115.87630268808421,  -32.19427600660995",Wandi
"115.91123204782836,  -33.04651982887382",Warawarrup
"115.76959047283025,  -32.34141100089623",Warnbro
"115.32243766863441,  -30.041445415398673",Warradarge
"116.98960304983818,  -32.68070484592155",West Popanyinning
"116.59900366255356,  -32.298444585285885",Westdale (WA)
"115.01484342465905,  -28.79042808276509",Wicherina South
"116.21154926659263,  -34.151569775163026",Wilgarrup
"123.46438672394301,  -17.576931635305158",Willare
"115.82970246304451,  -32.05216546556384",Winthrop
"116.75397240585758,  -28.186120229516572",Yalgoo
"114.69549285591714,  -28.60813496702566",Yetna
"146.715388043543,  -41.11569925475105",Badger Head
"147.3182581082905,  -43.0076841546905",Blackmans Bay
"147.14744327477564,  -43.26836467710486",Charlotte Cove
"147.28280274344584,  -43.08644453128446",Coningham
"148.3112674677132,  -40.99734437250752",Eddystone
"145.92048605528728,  -41.07619573184287",Emu Heights (Tas.)
"146.72902717060236,  -41.319902964295274",Frankford
"147.02082009921398,  -43.137755776073945",Glaziers Bay
"146.99436984024626,  -42.80301282310856",Glenfern (Tas.)
"145.0650665050312,  -41.80385333151156",Granville Harbour
"148.22151941377865,  -41.64000795169794",Gray (Tas.)
"146.8509735371791,  -42.54951812944103",Hamilton (Tas.)
"147.16299809730262,  -43.029005518993635",Kaoota
"146.96387377207702,  -41.315901141704884",Lanena
"147.48636014361992,  -42.90930932198462",Lauderdale
"146.5885021402408,  -41.98133034311707",Little Pine Lagoon
"147.1850962077638,  -42.97093743544413",Longley
"147.18091631848216,  -41.45943286663745",Norwood (Tas.)
"146.76514544806582,  -41.56721896621342",Osmaston
"146.92561052655296,  -41.802881458125064",Poatina
"146.9567488162596,  -43.155028725793734",Port Huon
"147.75843371279075,  -43.05772932156159",Premaydena
"147.16479909419536,  -41.45738065336666",Punchbowl (Tas.)
"147.0051130800216,  -42.99830269685292",Ranelagh
"145.43696580186355,  -41.78891838051718",Renison Bell
"145.1009428984019,  -40.90770369670633",Scotchtown
"146.03149800275494,  -41.34574357891181",South Preston
"148.19993408225147,  -41.32611023333676",St Helens (Tas.)
"147.24060703733875,  -41.46368308881578",St Leonards (Tas.)
"145.27432947936086,  -40.75904289799528",Stanley (Tas.)
"147.67476256050932,  -41.3254311752531",Trenah
"144.08964280777826,  -40.00020433951219",Yarra Creek
"130.4632484112541,  -12.619843089285169",Bynoe Harbour
"130.9981807716504,  -12.480201005711049",Farrar
"132.38915779174485,  -14.43150418218482",Lansdowne (NT)
"130.70903956184142,  -13.708416849284385",Maranunga
"133.0433123296696,  -14.96986220132518",Mataranka
"132.39995360034104,  -23.306011579188855",Mount Zeil
"130.84491579558522,  -12.43271655475457",Parap
"130.87799676809206,  -12.358744062573086",Tiwi
"132.5395462578962,  -14.555596454249121",Venn
"149.05707248830853,  -35.36121016258602",Fisher (ACT)
"149.09500744961503,  -35.33266151292202",Hughes (ACT)
"149.03145427503586,  -35.21654131004224",Latham (ACT)
"167.95245116067372,  -29.037895308063504",Norfolk Island
"152.57326515026156,  -26.222182575982817",Scrubby Creek (Gympie - Qld)
"151.77687058396995,  -27.328290234066554",Silverleigh
"150.03083954265014,  -25.40222356395426",Spring Creek (Banana - Qld)
"151.47879445406852,  -27.67397824857892",St Helens (Toowoomba - Qld)
"146.02407655737503,  -17.49784863014551",Sundown (Cassowary Coast - Qld)
"151.72169020280336,  -24.893110052312046",Takilberan
"153.13470754890733,  -27.870760361367797",Tamborine
"153.19365172720765,  -27.94216551091399",Tamborine Mountain
"151.7070415484367,  -28.156921349391283",Thane
"150.7960142625665,  -23.465004983229246",Thompson Point
"151.13213137503595,  -24.91576058269856",Three Moon
"146.58510924566542,  -19.147243897360323",Toolakea
"151.96431637118198,  -28.169867466458918",Toolburra
"151.65051117948786,  -24.085105362956746",Turkey Beach
"147.0796184010996,  -19.744797161716303",Upper Haughton
"145.96670237376756,  -18.79284550428313",Upper Stone
"149.0128987483076,  -21.222868846098226",Victoria Plains
"152.94981408286998,  -27.31219378960721",Warner
"151.1316522773885,  -23.91066915280284",West Stowe
"152.9264994240525,  -26.66425047802382",West Woombye
"152.84548743985385,  -27.39439261628174",Wights Mountain
"153.2631069674934,  -27.84494876869997",Willow Vale (Qld)
"151.60933104777772,  -25.077603054676995",Wonbah
"146.89306103456892,  -19.649368172865504",Woodstock (Qld)
"150.0638190484629,  -23.48939660235539",Wycarbah
"138.6447724493146,  -34.25800184553695",Alma (SA)
"139.64033992532137,  -30.13368436260643",Arkaroola
"138.57454609465253,  -34.948594034492174",Ashford (SA)
"137.4830348514656,  -35.78055657821685",Birchmore
"138.7143085087733,  -35.255497537300954",Blackfellows Creek
"137.75419822383944,  -32.69016447674968",Blanche Harbor
"136.7264623442902,  -35.754667747702904",Cape Borda
"138.5777807303523,  -34.97247028862341",Clarence Gardens
"139.70334826460103,  -35.446695129087225",Coomandook
"136.82143953935295,  -33.709492268642954",Cowell
"138.97652877281507,  -34.76611018603875",Cromer (SA)
"136.33767504208654,  -32.88704240363272",Cunyarie
"140.77980803765598,  -33.409768488794775",Danggali
"138.63917163171038,  -35.26451993070316",Dingabledinga
"139.23924988601524,  -34.356083767261374",Dutton East
"138.68152735949474,  -34.72599400538767",Elizabeth East
"137.53354412747518,  -35.592823921963486",Emu Bay
"138.49079250498676,  -34.838359615088955",Exeter (SA)
"138.63113221130115,  -34.947939153169386",Frewville
"138.74989125386372,  -34.611561208515596",Gawler South
"140.49413152242994,  -34.369168623082835",Gerard
"138.52599291690916,  -34.83405606582387",Gillman
"138.76763825113875,  -35.489982207602225",Goolwa
"138.72874795843728,  -34.744047470580306",Gould Creek
"138.62663629487707,  -34.899637870399374",Joslin
"140.34254139638557,  -36.14341910581892",Keith
"138.52690976848712,  -34.912190318221505",Kidman Park
"139.00908197543947,  -34.370419250376756",Koonunga
"138.70415229072404,  -34.64099842026729",Kudla
"140.90315255251215,  -36.85999420047908",Kybybolite
"139.1587758997096,  -35.441777298661165",Lake Alexandrina
"138.64920194684186,  -33.98965407514553",Leasingham
"138.5678240879182,  -35.515901069284276",Lower Inman Valley
"138.60604533718646,  -34.89216109174795",Medindie Gardens
"136.00753133297954,  -34.04448353096089",Moody
"137.5851156076721,  -34.06922491709817",Moonta
"139.67284758146582,  -35.28193884835744",Moorlands
"139.68213175564995,  -34.05307697851577",Morphetts Flat
"140.56524894375758,  -36.42151501347843",Mundulla West
"139.2646793984336,  -35.075290093095255",Murray Bridge North
"138.9118799160748,  -35.03251106456493",Nairne
"138.68242970244364,  -34.882028121991205",Newton
"138.52125283211836,  -35.00501136236815",North Brighton
"137.59183538958436,  -34.05455355745943",North Moonta
"139.67516943103993,  -34.03887243272192",North West Bend
"138.10001988785194,  -33.31761597172375",Nurom
"135.4425558990527,  -33.403743105336645",Palkagee
"135.8779516786138,  -34.56631590457941",Poonindie
"140.69788239534137,  -38.03790490393123",Port Macdonnell
"137.53929304927496,  -35.10219408434157",Port Moorowie
"138.47670267706638,  -35.16427153326566",Port Noarlunga South
"137.82977659557383,  -32.55449360645531",Port Paterson
"137.8293450376126,  -34.76912369827819",Port Vincent
"138.18842717041863,  -34.18188359928648",Port Wakefield
"138.70469827327906,  -34.8212440942994",Ridgehaven
"138.66823502274227,  -34.758640071993064",Salisbury Park
"135.2581901435165,  -33.837355361465484",Sheringa
"138.6929059736858,  -34.92446595911646",Skye (SA)
"138.9799567009195,  -35.11035116152965",St Ives (SA)
"137.76573595116665,  -34.897625916931645",Stansbury
"138.524769378357,  -34.60208207434351",Two Wells
"136.3434771440892,  -33.877300290006914",Verran
"138.9994564165226,  -34.525200536704716",Vine Vale
"138.41751517830198,  -34.06931815159914",Watchman
"138.67465468821385,  -34.961613097738216",Waterfall Gully
"136.43145330760925,  -35.17263865113772",Wedge Island (SA)
"136.4534618027777,  -32.915928438782906",Wilcherry
"138.21489541826247,  -32.21320663243997",Willochra
"137.9382909948689,  -35.848087708785634",Willson River
"138.127671754171,  -32.63736891022467",Wilmington
"139.29456178393806,  -32.51083037730486",Yunta
"115.37500693499858,  -33.76284470753635",Acton Park (WA)
"117.88380641661976,  -35.02598778082664",Albany
"115.802487205215,  -32.02541319830054",Attadale
"115.98922957166268,  -31.78125680828848",Aveley
"115.8193913903446,  -31.871606818235595",Balcatta
"115.88669130243463,  -31.42718868520771",Bambun
"116.31216856133656,  -31.860476534305285",Beechina
"117.92895703287901,  -32.20466272212493",Bilbarin
"115.72377707977147,  -33.13510910050304",Binningup
"115.6386910811767,  -32.68709771440905",Bouvard
"113.65164917168948,  -24.87854659073827",Brockman
"114.62078754731374,  -28.65139851292405",Buller
"117.25868237699355,  -33.727795842011844",Cherry Tree Pool
"115.08228289081913,  -34.2668271664399",Deepdene (WA)
"116.74443667186817,  -33.52229278916204",Duranillin
"114.92705967655301,  -28.490177224271633",East Nabawa
"115.78515555768828,  -31.763766187302934",Edgewater
"126.54213829207794,  -16.269330209168725",Gibb
"115.5125877329051,  -31.34045365605307",Guilderton
"115.60302292247289,  -29.031168210388607",Holmwood
"118.01588823456304,  -32.772563546021715",Jitarning
"125.48043605086902,  -17.10947131182642",King Leopold Ranges
"118.08334312944403,  -33.18730836782886",Kukerin
"118.43004008867531,  -33.09586116263079",Lake Grace
"116.13172125013098,  -31.916703600443906",Mahogany Creek
"116.02280863575004,  -31.956439134660062",Maida Vale
"119.67897767203645,  -21.15542485151319",Marble Bar
"116.47778837955066,  -32.89932122650063",Marradong
"115.93586047895043,  -32.726357233885146",Meelon
"115.86215606251815,  -31.918762931167105",Menora
"122.17248513702297,  -33.82509642340591",Merivale
"116.61606752597615,  -34.33025444435724",Mordalup
"113.78260522784834,  -22.699065870793177",Ningaloo
"120.88116933844476,  -32.23987226083773",Norseman
"115.75910371531137,  -31.860638396734537",North Beach (WA)
"115.7688815541842,  -31.806424535840556",Padbury
"117.96771189390279,  -33.91839671946313",Pallinup
"126.31668730292438,  -24.615621254129803",Patjarr
"118.77020616673164,  -32.66984029570397",Pingaring
"118.6405002250958,  -20.349634611122323",Port Hedland
"115.67021500865827,  -32.86678975539147",Preston Beach
"125.30828991324388,  -15.598074397923527",Prince Regent River
"115.90728121873009,  -33.53685986211633",Queenwood
"121.62906448451606,  -32.866890643266174",Salmon Gums
"117.90736271138903,  -31.67846898313373",South Doodlakine
"115.69031945285624,  -33.56347852276716",The Plains
"116.67717457201388,  -34.200729856961274",Tonebridge
"115.75816688475847,  -31.876138067883456",Trigg
"116.91922360459091,  -31.33017390150639",Ucarty West
"121.47272039603504,  -30.77881175914998",Victory Heights (WA)
"115.37916597133135,  -33.79769187133986",Walsall
"115.83140209246167,  -31.793946244419477",Wangara
"115.879644254756,  -32.83306487824498",Waroona
"115.94628039638773,  -33.434404834791316",Wellington Forest
"115.77728203499483,  -31.918553372148075",Wembley Downs
"115.7706486871481,  -32.060310569056426",White Gum Valley
"116.70612090294497,  -31.786394842054367",Wilberforce (WA)
"117.56841432655362,  -31.98906706806208",Yoting
"147.14278139122925,  -42.40933244688547",Apsley (Tas.)
"146.9267023409386,  -41.65384240457906",Bracknell
"147.11981206842853,  -42.654280300370786",Broadmarsh
"147.4587438125595,  -41.917582032753984",Campbell Town
"147.94075234271327,  -43.1836604435312",Cape Pillar
"147.71127422836938,  -42.86427021981945",Carlton River
"146.11472409202966,  -41.30161162862846",Castra
"147.52181907311893,  -41.76722717287457",Conara
"147.31286721884896,  -42.905387797612306",Dynnyrne
"147.91361935176494,  -43.00458126090836",Eaglehawk Neck
"146.38900813587645,  -41.185340444039625",East Devonport
"148.24525746801376,  -41.51977137973539",Falmouth
"146.81478438938234,  -41.24552379087905",Flowery Gully
"148.1160801746308,  -41.2649854717763",Goshen
"146.22420178383317,  -41.469092150852596",Gowrie Park
"147.05863955395264,  -43.02936834621803",Huonville
"147.22953699167888,  -42.97436476944096",Leslie Vale
"147.3696259692422,  -42.843224813859685",Lindisfarne
"148.0452997011375,  -40.22161299561094",Loccota
"148.0970185697618,  -39.96253595413923",Memana
"146.2010938531058,  -41.68185398334468",Mersey Forest
"146.48821073023927,  -41.2196719025431",Moriarty
"146.88582325603855,  -43.283908334971485",Raminea
"147.99602888066792,  -41.816078905334955",Royal George
"147.38529414720048,  -42.39498705659226",Stonor
"147.32183219426557,  -43.04193481298217",Tinderbox
"147.30502497653865,  -42.91023798272738",Tolmans Hill
"147.78200418810582,  -40.9064411618563",Tomahawk
"147.37263233184805,  -42.12583003322768",Tunbridge
"147.38719814867383,  -42.850611164709434",Warrane
"146.72489424445521,  -41.15611242289919",York Town
"133.2448372561224,  -22.041588724494808",Anmatjere
"130.87793337679403,  -12.44756840784609",Charles Darwin
"133.86868213696468,  -23.69376381117128",Ciccone
"130.45496653291084,  -12.726050007429723",Dundee Forest
"133.26369928119846,  -15.060369664598046",Elsey
"131.19444532330883,  -12.93081849698392",Lake Bennett
"133.91386792642345,  -23.723201373368582",Mount Johns
"132.7227422427205,  -23.947455648436375",Namatjira
"135.70402952892775,  -13.929232523681526",Numburindi
"136.79665208741451,  -13.865400867677797",Umbakumba
"131.53992959591488,  -16.664382579379705",Victoria River
"133.7578650470323,  -23.7518726327761",White Gums
"149.10701753212206,  -35.440434708214646",Calwell
"149.04242404783628,  -35.316739313688124",Coombs
"149.10749096042733,  -35.20276025026586",Crace
"149.16581128933544,  -35.385440056560235",Hume
"149.05805199124794,  -35.38625005763341",Kambah
"149.0985679497549,  -35.36329791524398",Mawson
"151.94506596424603,  -32.541437996049545",Allworth
"152.72771815279208,  -30.610192497936726",Argents Hill
"148.4634521452788,  -31.44796745197445",Armatree
"153.17465476707252,  -29.395789218406005",Ashby Heights
"151.12616159887182,  -33.88873033362729",Ashfield (NSW)
"150.99617810809048,  -33.89913450860432",Bass Hill
"151.47249100038923,  -33.38622580889134",Bateau Bay
"150.15741351147676,  -35.70598022787222",Batemans Bay
"152.58741402511365,  -28.581441231429924",Bean Creek
"150.21880702793652,  -35.62653652693133",Benandarah
"151.7293386425144,  -32.34993707413891",Bendolba
"153.09816872701677,  -28.753214453849623",Bentley (NSW)
"150.5040283142006,  -35.25094874902584",Berringer Lake
"149.44990950741143,  -32.31684579780424",Beryl
"150.00438570644766,  -34.53261929205797",Big Hill (NSW)
"152.77839294061056,  -28.807573179192868",Bingeebeebra Creek
"148.87097604510254,  -31.12009055564151",Black Hollow
"148.25818914942565,  -35.50277545813576",Blowering
"152.1102129907963,  -32.78418581260374",Boat Harbour (Port Stephens - NSW)
"142.10580758288657,  -34.15382569332648",Boeill Creek
"147.54537690300884,  -35.33355261737617",Book Book
"148.16601951306495,  -31.23113747528255",Bourbah
"150.814878255036,  -34.085766628509056",Bradbury (NSW)
"147.0128151965802,  -35.12037107642371",Brookdale (NSW)
"149.48776339191193,  -33.11055891408768",Bruinbun
"150.06373862927148,  -32.513213943453444",Budden
"149.9826219335399,  -31.63294087938508",Bundella
"150.867305567603,  -33.77957354137307",Bungarribee
"148.86403835886858,  -30.05188183453534",Burren Junction
"148.61160401602677,  -34.95647678103688",Burrinjuck
"151.104091120197,  -33.88977510280742",Burwood Heights
"144.51177416871639,  -35.594908987892865",Caldwell
"151.08061853653555,  -33.500529810955726",Canoelands
"151.12065527323102,  -34.05422064114",Caringbah South
"150.83395230580368,  -31.668423181759362",Chilcotts Creek
"150.81109726603998,  -34.04646591019719",Claymore
"153.40668884505112,  -28.72660590283587",Clunes (NSW)
"150.68085790665017,  -33.99119860615524",Cobbitty
"153.23962757432122,  -28.957346131712242",Codrington (NSW)
"153.2179781556435,  -28.648839661628465",Coffee Camp
"146.41007543062662,  -34.8622352704456",Corobimilla
"152.72574839439073,  -31.842754108567377",Crowdy Head
"153.55177038758237,  -28.27323486206012",Cudgen
"148.9137738487784,  -32.856420314053736",Cundumbul
"150.5054145856172,  -35.26250297812697",Cunjurong Point
"151.41495474409012,  -32.62687191793478",Dalwood (Singleton - NSW)
"150.83237693257092,  -33.981398004864914",Denham Court
"150.81562268577204,  -33.74813926267793",Dharruk
"150.87098992911493,  -33.7614713146751",Doonside
"152.69405359561173,  -30.326672419400236",Dorrigo
"151.54093939949186,  -30.422477387051018",Dumaresq
"151.75245693393006,  -32.40470861178472",Dungog
"151.7780425134211,  -32.695714167296316",Eagleton
"153.420404972429,  -28.365824714570767",Fernvale (NSW)
"151.0844347121352,  -33.55829954018753",Fiddletown
"153.56138845273293,  -28.20222445324349",Fingal Head
"152.227653650332,  -32.08749057193497",Firefly
"145.29517462798304,  -29.70221481992586",Fords Bridge
"149.6624765896126,  -33.38860711666486",Forest Grove (NSW)
"148.24806290210276,  -36.34562953878384",Geehi
"146.0718883635591,  -32.44207079799554",Gilgunnia
"150.944483266033,  -33.80019629512925",Girraween (NSW)
"151.34172214249008,  -33.422121231509465",Gosford
"150.86715825720447,  -33.90290113234989",Green Valley (NSW)
"148.18600821943355,  -33.92847090739765",Grenfell
"147.43180246821905,  -35.132051462015085",Gumly Gumly
"149.28582780299206,  -35.038965075663285",Gundaroo
"145.72013162168136,  -30.60818841564697",Gunderbooka
"151.14000121107568,  -33.87792950174617",Haberfield
"151.55222888292116,  -33.21934271151769",Halekulani
"151.74750142898736,  -32.933240924686444",Hamilton South
"151.35501331077938,  -33.52707536786819",Hardys Bay
"145.3493684696838,  -35.34316324632497",Hartwood
"147.35552459342637,  -35.04346953318134",Hillgrove (Wagga Wagga - NSW)
"151.08100164970134,  -33.862771021162935",Homebush (NSW)
"146.46280480915547,  -35.9165840733144",Hopefield
"150.8441387366394,  -33.94635542135478",Horningsea Park
"149.1261187619677,  -33.36946399976825",Huntley (Orange - NSW)
"147.5807471036115,  -34.86549589206322",Junee
"150.444591040262,  -35.3713713749671",Kings Point
"147.5734517707902,  -35.459642307927794",Kyeamba
"148.31587066899732,  -35.31010293896692",Lacmalac
"150.11863668761984,  -33.36013742561016",Lidsdale
"151.1610861618403,  -33.87101712388968",Lilyfield
"151.36010374081164,  -33.23571537433127",Little Jilliby
"151.04525543746217,  -34.04483771042298",Loftus
"150.89304022190245,  -33.992039613278244",Macquarie Fields
"150.87260289800875,  -33.98169015249875",Macquarie Links
"150.94542303030485,  -34.25795303782512",Maddens Plains
"151.10497067721587,  -33.775916745974875",Marsfield
"151.75544062519137,  -32.91128030137818",Maryville
"153.38847891826256,  -28.787227908777034",McLeans Ridges
"153.16189767473205,  -28.449752020141304",Mebbin
"151.6097395012818,  -32.76754165335984",Metford
"150.8504811055614,  -34.622767726670546",Minnamurra
"150.0197693733099,  -32.26177952125538",Mogo (Mid-Western Regional - NSW)
"148.8632925527775,  -33.099940060518875",Molong
"148.5599091591122,  -36.510869515528576",Moonbah
"151.62102807399071,  -33.171141299006415",Moonee
"148.54817764799228,  -34.28982669744352",Murringo
"150.24108074199154,  -32.39029899940519",Murrumbo
"152.6962325116281,  -29.51895826461082",Newbold
"144.0221827106248,  -35.34983695562935",Noorong
"151.6069585117913,  -33.14503228937969",Nords Wharf
"151.09399725617013,  -33.75875248804927",North Epping
"151.2933091547767,  -33.70267684755257",North Narrabeen
"148.2398429431597,  -34.510406910840246",Nubba
"151.86493028672086,  -30.102233270117082",Oban
"151.29509445285132,  -31.353344771497472",Ogunbil
"150.74532232167547,  -34.91133771854941",Orient Point
"151.4204654960969,  -32.69691507100448",Oswald
"151.0329195836089,  -33.97004156164378",Padstow Heights
"150.17350622327984,  -29.394121473946957",Pallamallawa
"142.09240541743026,  -33.65276685893598",Para
"151.28046936264408,  -33.53872683950322",Patonga
"150.95591664228215,  -33.80401164171518",Pendle Hill
"149.40597711674062,  -32.49427951090036",Piambong
"151.3433351911511,  -33.44059799680949",Point Frederick
"151.3474807103051,  -33.52809243104824",Pretty Beach (Central Coast - NSW)
"151.76101491542855,  -30.38579585426893",Puddledock
"150.88997594835632,  -33.72834500816914",Quakers Hill
"151.913504060839,  -29.790693090143613",Red Range
"152.9044450767699,  -28.4920238045174",Roseberry
"141.34052430551714,  -33.99490151359905",Rufus
"151.11194038913195,  -33.81058609120915",Ryde
"151.24564382748395,  -32.603491829195605",Scotts Flat
"151.70992740887684,  -32.67016566991451",Seaham
"152.9591131246083,  -28.905591909848134",Shannon Brook
"149.83903234495358,  -33.92677756270482",Shooters Hill
"153.59031870747765,  -28.65830732990513",Skinners Shoot
"150.9381050227801,  -33.84853605531615",Smithfield (NSW)
"153.25511666065324,  -28.815292643789356",South Lismore
"142.3196506643485,  -32.27313413854138",Sunset Strip (NSW)
"151.16819346180398,  -33.9167827687404",Sydenham (NSW)
"152.04433183571106,  -32.220497655123715",Terreel
"152.83912389566302,  -31.458273289360402",Thrumster
"150.0843269018739,  -28.90025317053339",Tulloona
"153.50459100371663,  -28.182458463673587",Tweed Heads West
"152.4672572709544,  -31.710171416194985",Upper Lansdowne
"150.81126563523438,  -34.00306666182014",Varroville
"148.05618676690017,  -29.97982837881802",Walgett
"150.64367667730536,  -33.88399139802273",Wallacia
"151.6560397451626,  -32.69177877559527",Wallalong
"147.8547440731025,  -31.729419527273215",Warren
"151.29538385806586,  -33.68869495306614",Warriewood
"148.59236751947867,  -33.941792450581424",Wattamondara
"149.58040343111062,  -31.63709870801114",Weetaliba
"148.96103171017927,  -32.56558964992408",Wellington (NSW)
"150.74738701281743,  -33.747087684002786",Werrington County
"146.14162045441054,  -34.547576482222624",Whitton
"146.15182005324388,  -34.35787235404991",Widgelli
"145.99040624531946,  -34.47989409420971",Willbriggie
"150.70295061485166,  -31.09061775262115",Winton (NSW)
"149.74298507655885,  -34.28720903437777",Yalbraith
"152.71137787704282,  -31.455841167586684",Yippin Creek
"147.09742184134436,  -38.03077362266902",Airly
"145.5839230949962,  -38.458644691244416",Almurta
"145.03088202202812,  -37.78043585123913",Alphington
"143.86846500163435,  -35.879557243209916",Appin (Vic.)
"145.11986217168678,  -38.02214444620817",Aspendale Gardens
"147.58713681168183,  -37.859788262265994",Bairnsdale
"143.95505799287804,  -38.36292462055322",Bambra
"143.56340051066033,  -36.76735878667691",Bealiba
"147.44221515213414,  -37.96550736761416",Bengworden
"144.22843110537235,  -37.78968509574307",Beremboke
"143.7526583854677,  -36.92482835436708",Bet Bet
"143.8724404623351,  -37.549911905084734",Black Hill (Vic.)
"146.10721559689802,  -36.23038058403289",Boweya North
"143.705867368825,  -36.559467857392946",Brenanah
"143.78726847321795,  -36.88728317174628",Bromley
"147.8625308124751,  -37.70714855541879",Bruthen
"147.09562882907946,  -37.54590526334321",Budgee Budgee (Vic.)
"144.4964183270622,  -37.47634309670809",Bullengarook
"143.58468348828717,  -37.09493959366788",Bung Bong
"142.5169659203104,  -38.32505686249073",Bushfield
"143.33551025631007,  -36.712773353842195",Carapooee
"141.49489036600215,  -38.307058267799526",Cashmore
"146.82488799505288,  -36.18351206072028",Castle Creek (Vic.)
"145.100173421632,  -37.882109761318574",Chadstone
"141.89058168889096,  -36.93736422927745",Clear Lake
"141.56271491781433,  -37.607829693462186",Clover Flat
"147.84816727228684,  -36.22346432386797",Colac Colac
"144.8291328804812,  -36.59690452340556",Colbinabbin
"144.93465636762548,  -37.656742498628354",Coolaroo
"145.2752203520197,  -38.1128601436626",Cranbourne
"145.9690099212754,  -38.0591656137814",Crossover
"145.28404414830314,  -37.79500301645668",Croydon (Vic.)
"145.42603412897338,  -38.18409941082418",Dalmore
"145.54568878149487,  -38.56892701021151",Dalyston
"146.242970385674,  -38.33420009765542",Delburn
"145.70735304584352,  -36.32515715217893",Dookie
"144.8206012631233,  -36.14008163088543",Echuca Village
"144.46059952489475,  -36.85248304008618",Eppalock
"144.2319425068856,  -37.680361304203124",Fiskville
"145.17093340827282,  -37.83986710793542",Forest Hill (Vic.)
"146.16342678143403,  -38.6060065085138",Foster North
"143.47744984403388,  -38.053692650862104",Foxhow
"145.1456595992945,  -38.1481429820104",Frankston
"145.1295517283702,  -38.176453578878956",Frankston South
"142.79959665000223,  -35.17256770899221",Gerahmin
"147.30253109063446,  -37.75005524663515",Glenaladale
"144.6123762338415,  -37.07431797654311",Glenhope
"144.70113655468583,  -37.07345537673499",Glenhope East
"144.2429683218274,  -37.26653199416759",Glenlyon (Vic.)
"143.9274559208345,  -35.51103216301238",Gonn Crossing
"146.25153859839617,  -36.65166178585598",Greta South
"145.26844398166403,  -38.00720455355484",Hallam
"144.32293932964882,  -36.65727174072216",Huntly
"144.7037193554934,  -38.13705588117115",Indented Head
"145.89120343006624,  -38.02969992591277",Jindivick
"147.80815474588877,  -37.82022246911014",Johnsonville
"141.38672076836758,  -37.168393729337595",Kadnook
"147.96683730019987,  -37.870084402084444",Kalimna
"145.66425873479702,  -36.82845835009598",Kelvin View
"145.49591971892275,  -38.54907542566937",Kilcunda
"146.94254835869717,  -37.72614714904862",Koorool
"143.52421762931394,  -36.11323526234725",Lake Marmal
"144.2911856861588,  -37.36126038547251",Little Hampton
"145.0349946211897,  -37.85686318211933",Malvern (Vic.)
"147.39659969921124,  -38.01698757916651",Meerlieu
"143.47488926772857,  -37.5390575264289",Mena Park
"143.66431654502117,  -36.74850925948401",Moliagul
"145.30419677503295,  -36.63146335062576",Moorilim
"143.40051348227988,  -37.60387028991174",Mount Emu
"141.4765945235152,  -34.709517983703975",Murray-sunset
"147.02640728054018,  -38.067272292047114",Myrtlebank
"145.35941262728832,  -37.96313880645578",Narre Warren East
"146.00819681033673,  -38.5030752994812",Nerrena
"146.29299969940175,  -38.176002770979586",Newborough
"144.94485602648305,  -37.79848331642988",North Melbourne
"143.2781910851111,  -35.33054464818208",Nowie
"146.18692492658417,  -36.16528572260672",Peechelba
"145.94159820252221,  -37.89557677205047",Piedmont
"144.94862649866997,  -36.935678703872455",Puckapunyal
"142.62046298229245,  -38.27076272714059",Purnim
"143.30875779967377,  -36.90915255385766",Redbank (Vic.)
"144.6830120730396,  -37.444585311610666",Riddells Creek
"142.246888170921,  -36.72109429149816",Riverside (Vic.)
"142.85246844239518,  -37.336650038300654",Rocky Point (Vic.)
"145.01461939934583,  -37.95349590087669",Sandringham (Vic.)
"145.2185896038945,  -37.90161792345919",Scoresby
"143.58465581693318,  -36.35325942221972",Skinners Flat
"144.87193638883713,  -37.83096915017523",South Kingsville
"142.4060097115196,  -38.287373337236936",Southern Cross (Vic.)
"144.39043371479215,  -38.1901881803054",St Albans Park
"143.16626549365276,  -36.591673950440374",St Arnaud North
"143.97364632233598,  -37.152897153551855",Strathlea
"143.5118139705341,  -35.3621594816419",Swan Hill
"143.69521059352832,  -37.17866520816749",Talbot (Vic.)
"147.1872594833767,  -36.23164059021205",Tallangatta
"146.22942855377588,  -37.94472668594604",Tanjil
"145.11217187268466,  -37.763745504672585",Templestowe Lower
"147.2335540379046,  -38.33419696844435",The Honeysuckles
"146.8751513727623,  -37.96887836981895",Tinamba
"146.31526861697208,  -38.653324481310776",Toora (Vic.)
"143.3546394433514,  -35.84632742788057",Towaninny
"146.4688512629672,  -38.13534982607157",Tyers
"146.98970908140535,  -37.783259018586506",Valencia Creek
"142.31711067177363,  -37.52613216164543",Victoria Valley (Vic.)
"145.03141191636107,  -37.352122537280366",Wandong
"143.94804704990355,  -37.48089462234567",Wattle Flat (Vic.)
"143.70812399008034,  -36.429413810738886",Wedderburn Junction
"143.62780749006703,  -36.608942530699295",Wehla
"145.61046119161995,  -38.55240382818879",West Creek
"143.47317928481917,  -36.326969794493834",Woosang
"144.97078706781363,  -36.15012588992671",Yambuna
"153.02392747864954,  -27.585381613032826",Acacia Ridge
"142.60487519478448,  -21.32838824552752",Albion (Richmond - Qld)
"145.2819251992619,  -17.06307178966803",Arriga
"153.01785493584464,  -27.36666803885786",Aspley
"152.9948138288062,  -27.47373838528073",Auchenflower
"141.82845543562436,  -13.609370360154518",Aurukun
"150.56416836586277,  -26.702464192443827",Baking Board
"150.6023288353466,  -26.336557175627007",Barakula
"150.53985552489272,  -23.12509720725318",Barmoya
"145.79143979049212,  -28.862857215493914",Barringun
"151.0027155802859,  -28.680029883364416",Beebo
"151.20345282023044,  -23.9103971293041",Beecher
"150.82902269732375,  -27.129301514645903",Beelbee
"151.43843856813552,  -26.922640625825316",Bell (Qld)
"152.82120843472387,  -26.5084129360934",Belli Park
"149.0174480949379,  -20.950248502718587",Belmunda
"153.15668210535975,  -27.687043733364924",Bethania
"139.16277289097718,  -25.406249148839148",Birdsville
"150.52838662332607,  -26.56589913734712",Blackswamp
"153.03371315367198,  -26.611290891957328",Bli Bli
"149.00116327924152,  -26.52472544469714",Blythdale
"150.7230504517456,  -26.80739423117326",Boonarga
"152.08050325126297,  -25.212800188628623",Booyal
"153.019610668143,  -27.688465121094257",Boronia Heights
"152.99433482225024,  -27.35133036089582",Bridgeman Downs
"151.83271768952295,  -26.7297730339277",Brooklands
"147.55928709706282,  -24.55828310876914",Buckland (Qld)
"153.05941946197532,  -27.44870259400036",Bulimba
"152.3437212961092,  -24.84910187357874",Bundaberg North
"152.3595770114708,  -24.872431128364944",Bundaberg South
"150.75128723261903,  -26.580885152611646",Burncluith
"152.59687446570715,  -25.257160093600422",Burrum River
"152.21075868647694,  -27.69642632986787",Caffey
"148.7808716407645,  -20.395508711314637",Cape Conway
"151.88933057518614,  -26.5939662980857",Coolabunia
"145.9846542943022,  -17.578520585519843",Currajah
"146.07117605825044,  -18.495339644289018",Dalrymple Creek
"146.26735998460254,  -19.576810030451185",Dotswood
"149.98626147691152,  -26.7915667325451",Drillham South
"146.03581927548908,  -17.532673524266492",East Innisfail
"148.81088329250318,  -26.167726416325834",Eumamurrin
"148.17655685435662,  -26.690431635746922",Eurella
"150.4654117955453,  -23.405243832160064",Fairy Bower
"139.5321252380761,  -20.749043501421042",Fisher (Qld)
"147.88688539971608,  -25.997550444721288",Forestvale
"150.54581835934758,  -23.34589007123587",Frenchville
"149.76481468607946,  -25.21980706955397",Ghinghinda
"152.6232768816453,  -27.51179342332621",Glamorgan Vale
"150.6294875387218,  -27.275390480061006",Goranba
"151.38595966533018,  -25.45966389860073",Gurgeena
"146.8528502492435,  -19.12440013501049",Horseshoe Bay
"145.68720909687346,  -16.87713198685292",Kamerunga
"145.72224561190686,  -16.921743224735074",Kanimbla (Qld)
"151.56164506706688,  -28.18329546057929",Karara
"153.01188416832886,  -27.44891475606331",Kelvin Grove
"151.26782653932725,  -28.087326837012732",Kooroongarra
"149.07869690996938,  -22.2831932529716",Lotus Creek
"152.8648824371023,  -27.77263403162583",Lyons (Qld)
"152.18036298192905,  -27.63251527358882",Ma Ma Creek
"145.4962357809107,  -17.037898140792468",Mareeba
"151.26729632074415,  -27.882974798701113",Millmerran
"151.89415686930727,  -24.853643645225734",Monduran
"150.86757574107503,  -24.086098148847533",Mount Alma
"152.43061360163557,  -27.126029885214784",Mount Beppo
"153.00727853618187,  -28.32241939670569",Mount Gipps
"148.76872428292867,  -20.26392490332093",Mount Rooper
"152.22526798973433,  -25.962991835687532",Mudlo
"152.5921882648433,  -25.600878056214015",Mungar
"151.14716331350894,  -27.28411713651976",Nandi
"145.87208395927138,  -17.548449606646138",Nerada
"152.70317918090012,  -26.968486731037235",Neurum
"151.25362703085216,  -25.526312573839597","[""O'Bil Bil""]"
"153.25310531709096,  -27.775661888907084",Ormeau
"152.97931052866903,  -26.59548301086605",Parklands (Qld)
"152.52688183547033,  -25.89023637618483",Paterson (Qld)
"151.39721639572653,  -25.55271053122265",Philpott
"151.48754630556832,  -25.756915724820526",Pile Gully
"143.23659056364494,  -20.76224546586333",Richmond (Richmond - Qld)
"152.82945564040924,  -26.46448116023744",Ridgewood (Qld)
"148.64299525238607,  -20.284000267565165",Riordanvale
"146.49219900866538,  -26.850809622795353",Riversleigh
"145.98318352884618,  -18.04484912026673",Rockingham (Qld)
"149.16055416671227,  -21.06397310709281",Rural View
"150.79667497486412,  -34.089294363251554",Ambarvale
"150.88299627477608,  -33.79056683579423",Arndell Park
"152.7145467757098,  -30.826365788197034",Bakers Creek (Nambucca Valley - NSW)
"148.90117775084593,  -32.17874048368598",Ballimore
"151.52770487908947,  -31.958912527926813",Barrington Tops
"151.18615487374228,  -30.061561543689436",Bassendean (NSW)
"149.57746965392178,  -33.41916905921141",Bathurst
"147.27110907030448,  -34.50333798686371",Bectric
"151.21118331988228,  -33.7222280035371",Belrose
"151.39195231227455,  -33.49945618320742",Bensville
"148.81989484047105,  -36.38344240768639",Berridale
"147.84162335102943,  -34.75696095187027",Bethungra
"151.1316454552555,  -33.978182391285074",Beverley Park
"151.18175110553443,  -33.85197755911841",Birchgrove
"149.71859917280386,  -33.85708495068432",Black Springs (NSW)
"153.2322787868295,  -28.767710332442253",Blakebrook
"150.79963988347083,  -33.63858818989635",Bligh Park
"150.221960401438,  -30.825379663750823",Blue Vale
"150.8699754565944,  -33.891161852237886",Bonnyrigg Heights
"152.9172613200932,  -30.515638078273277",Brierfield
"153.29095652374343,  -29.619004739045696",Brooms Head
"153.08815692983075,  -29.561513407157033",Brushgrove
"149.1891654681557,  -29.79407003287662",Bulyeroi
"150.93322577876995,  -33.89783004002983",Cabramatta
"153.07775186755222,  -29.61772290469951",Calliope (NSW)
"151.09815067504425,  -30.336573856922833",Camerons Creek
"151.2498197710487,  -33.160890621732044",Cedar Brush Creek
"150.00283637389836,  -35.36015952740817",Charleys Forest
"151.19285558447018,  -33.510616934286894",Cheero Point
"153.0006082071759,  -29.703668815683724",Clarenza
"151.31024473308287,  -33.636843559505806",Clareville
"152.8101741314595,  -29.397853644253388",Coaldale
"153.4563588932016,  -28.204619118861974",Cobaki
"153.1098120232174,  -29.60126587399624",Coldstream (NSW)
"146.57434897864164,  -34.458162703758795",Colinroobie
"149.7978725912879,  -36.64760734916927",Coopers Gully
"150.55465431641616,  -34.236522835055",Couridjah
"151.06841623885688,  -31.583751951510614",Crawney
"151.6655543504682,  -31.72540108262299",Curricabark
"151.20686536666278,  -33.85677640794882",Dawes Point
"150.69893264191214,  -32.37267839018404",Denman
"144.107931978071,  -35.209477304518884",Dhuragoon
"149.61760500016467,  -33.75874073594054",Dog Rocks
"153.305612991296,  -28.496186133776217",Doon Doon
"152.73097942469548,  -30.375521748450346",Dorrigo Mountain
"149.855966674593,  -32.76717830318596",Dungeree
"151.21247931536908,  -33.93075523116297",Eastlakes
"150.87778153504328,  -33.87967555024699",Edensor Park
"149.77473112730135,  -30.086646750045194",Edgeroi
"153.09001938636263,  -29.032210434488643",Ellangowan (NSW)
"150.79625014167937,  -34.08026729023911",Englorie Park
"150.02313367896673,  -30.35993111422663",Eulah Creek
"149.5990480706194,  -35.523367513666024",Farringdon
"151.8907230432471,  -32.07032676255434",Faulkland
"152.86045870719454,  -31.025828049593468",Frederickton
"152.07414204553086,  -32.01900623166634",Gangat
"148.7551442021194,  -33.12025851927766",Garra
"150.24725451738206,  -33.14936913104876",Glen Davis
"150.62571763110242,  -33.76362871937179",Glenbrook
"151.184183266534,  -33.407242816510234",Glenworth Valley
"142.22560796373392,  -34.168700902226156",Gol Gol
"146.87123262659838,  -30.453351298941726",Gongolgon
"143.9975381682866,  -35.475069815022806",Gonn
"143.37665881595763,  -34.92169320785065",Goodnight
"149.72777202037332,  -34.747905710926354",Goulburn
"150.91480818705008,  -29.437213517842327",Graman
"151.07971813355024,  -34.05791710812957",Grays Point
"153.11606844475895,  -28.899806344495893",Greenridge
"153.22469372680973,  -29.500496730612983",Gulmarrad
"152.98215786403918,  -29.443949798918375",Gurranang
"151.43931275981222,  -33.22906678999985",Halloran
"153.57194757530843,  -28.362552408538896",Hastings Point
"152.4135376663752,  -30.309361624163103",Hernani
"144.61539271855935,  -29.170714856746518",Hungerford (NSW)
"151.37549814489137,  -30.75054130131956",Kentucky
"151.54137593556186,  -32.931119045810796",Killingworth (NSW)
"149.55586092135053,  -35.644256422816184",Kindervale
"149.82214127260414,  -36.74220836280766",Kingswood (Bega Valley - NSW)
"152.3225747885586,  -31.651660567657686",Kippaxs
"150.8079300908302,  -34.50737270708844",Koonawarra
"153.03174307932267,  -29.956062174864115",Kungala
"153.32075722664533,  -28.793546319372336",Lagoon Grass
"151.0764579344285,  -33.91902396333556",Lakemba
"152.57591644680141,  -31.747201845567513",Lansdowne Forest
"150.83796283766225,  -34.053300202281896",Leumeah
"151.68612789773664,  -30.103036417314858",Llangothlin
"148.58051242388325,  -35.661902738047",Long Plain (Snowy Monaro Regional - NSW)
"151.4344492947983,  -32.321735072224804",Lostock
"151.27994931244982,  -33.63192647970611",Lovett Bay
"151.4875372148074,  -32.794065052066244",Loxford
"150.97849171050518,  -34.03970086649479",Lucas Heights
"151.43105487338235,  -32.66302923612848",Luskintyre
"149.3740702938173,  -29.640071894669077",Mallowa
"148.44874619699237,  -33.257117440460675",Mandagery
"149.0785943816869,  -33.211824308594636",March
"149.87348690216263,  -35.73145300656247",Merricumbene
"148.812253152811,  -36.214693567770674",Middlingbank
"153.26675705458015,  -28.49948720150824",Midginbil
"151.69416856422794,  -30.718410191653767",Mihi
"149.50000021670724,  -29.754031230283417",Millie
"149.62114653516556,  -32.59256969979394",Milroy (Mid-Western Regional - NSW)
"150.43923846666505,  -35.319373280317535",Milton (NSW)
"152.77497730543254,  -30.566482996336468",Missabotti
"146.72866125575908,  -35.91895274972764",Moorwatha
"144.14077510525817,  -34.996297570803335",Moulamein
"151.12725415352847,  -33.667053233248296",Mount Colah
"151.04969770614542,  -33.91560953185053",Mount Lewis
"153.4455469913895,  -28.552291116484852",Mullumbimby Creek
"149.9837304482303,  -36.519715511146394",Murrah
"146.59492986366467,  -34.67740293843893",Narrandera
"153.1069037690264,  -28.801313542846657",Naughtons Gap
"151.31504961335793,  -33.65665887683891",Newport (NSW)
"151.17961935657078,  -33.898642855459585",Newtown (NSW)
"153.29356489285692,  -28.55192583456104",Nightcap
"151.09588416433692,  -33.723683864806034",Normanhurst
"150.1000464736212,  -36.20917839372456",North Narooma
"150.56356334837,  -34.930932715495665",Nowra Hill
"150.82188707807322,  -34.56225651027834",Oak Flats
"149.94681640141889,  -35.157578858901175",Oallen
"147.46079189344044,  -33.089969284049815",Ootha
"147.5987066394978,  -35.082319563263454",Oura
"147.84812439769746,  -35.94273482767951",Ournie
"151.95338285373725,  -32.74387415906741",Oyster Cove (NSW)
"153.4876026286876,  -28.373130824981473",Palmvale
"150.33266407027457,  -35.59939819408086",Pebbly Beach
"150.63268236511425,  -34.26967889718221",Pheasants Nest
"150.8460266287509,  -33.58591162400279",Pitt Town Bottoms
"147.78132225896758,  -33.75207148764373",Pullabooka
"151.20651617114487,  -33.89306453791824",Redfern
"149.2654201683831,  -36.9679043145356",Rosemeath
"150.84196503833977,  -34.070388054485136",Ruse
"151.70549046912788,  -32.868042064085444",Sandgate (NSW)
"153.31281034561596,  -29.689322318846752",Sandon (NSW)
"153.37802478163613,  -28.427752084565572",Smiths Creek (Tweed - NSW)
"152.06604727511515,  -32.708064159851304",Soldiers Point
"150.9551060707945,  -33.51127089616072",South Maroota
"149.13693365293355,  -32.39592685869705",Spicers Creek
"151.00749460987095,  -33.25177123662108",St Albans (NSW)
"150.81362519937653,  -34.10470241187917",St Helens Park
"149.8010788762058,  -36.61804416950803",Stony Creek (Bega Valley - NSW)
"152.98464558585695,  -30.824216233743133",Stuarts Point
"150.5403502668387,  -33.55757787061459",The Devils Wilderness
"151.77582085644065,  -32.93295289054864",The Hill
"149.9381631315172,  -33.64705207819806",The Meadows
"149.26086628244215,  -35.3388375925266",The Ridgeway
"150.63590925435287,  -33.99141222948954",Theresa Park
"144.7444457889066,  -35.84568087465988",Thyra
"150.1017389762079,  -34.835674502704926",Tolwong
"150.56999565048213,  -35.051064357651946",Tomerong
"149.75348655077937,  -36.78211206892287",Toothdale
"146.9855334988534,  -35.242774005789784",Tootool
"147.55699992347763,  -34.26264532172449",Trungley Hall
"153.31697191038467,  -28.921562958097137",Tucki Tucki
"152.67970095448365,  -28.819572198413635",Tunglebung
"149.88948784140507,  -32.152199619738965",Turill
"153.42548029003356,  -28.299073048800626",Tygalgah
"152.69844826946598,  -28.521742221398778",Upper Duck Creek
"152.03419377956476,  -30.130199559013477",Wards Mistake
"150.97166535176552,  -30.502226807300772",Warrabah
"150.8236831959789,  -34.141268432556934",Wedderburn (NSW)
"153.52265205243512,  -28.85761210658069",West Ballina
"150.8530537841372,  -31.091102218199325",Westdale (Tamworth Regional - NSW)
"149.94607599143637,  -34.21941557621326",Wiarborough
"150.87272475750544,  -34.52717442528878",Windang
"149.4416812587763,  -37.07619558752488",Wog Wog (Bega Valley - NSW)
"152.13119384153043,  -30.472366983449405",Wollomombi
"148.87851190160742,  -33.7689232422103",Woodstock (Cowra - NSW)
"148.72062697263692,  -34.877853101880035",Woolgarlo
"150.90335888605443,  -34.34663330300563",Woonona
"150.93548247116112,  -34.16351687277731",Woronora Dam
"153.3140445203736,  -28.885390977228788",Wyrallah
"153.34069464107117,  -29.43319913862509",Yamba (NSW)
"146.40186732579048,  -34.62488515144939",Yanco
"148.80505699004854,  -35.79863338309357",Yaouk
"150.6452006641397,  -33.63914498396933",Yarramundi
"152.7686341063217,  -30.72461425754782",Yarranbella
"149.51322781969193,  -30.41036898154613",Yarrie Lake
"152.06912787268092,  -31.199609346238972",Yarrowitch
"147.9412057548904,  -35.476404363156504",Yaven Creek
"141.65622625753147,  -38.27028768208105",Allestree
"144.83476206795638,  -37.83779049678467",Altona North
"144.53619892285383,  -36.53704673306341",Avonmore
"145.0832726376647,  -37.809080196946844",Balwyn
"146.92909945919155,  -36.1367702724101",Bandiana
"143.18072553613572,  -37.303018247885525",Bayindeen
"144.33836827585029,  -38.105452509411535",Bell Park
"142.459200524688,  -37.21716999002485",Bellfield (Northern Grampians - Vic.)
"144.27939362066616,  -36.75707401135484",Bendigo
"145.03844704749565,  -37.922598729087845",Bentleigh
"143.46426243350967,  -36.5009683187468",Berrimal
"148.3007553002981,  -37.68595609030224",Bete Bolong
"141.5665878424069,  -35.155133515302445",Boinka
"146.28500215951098,  -38.44681437343134",Boolarra South
"143.78022667976603,  -36.29418651058895",Borung
"145.12956214737858,  -37.80562725271407",Box Hill North
"146.62390553841408,  -36.046421275031406",Browns Plains (Vic.)
"144.1345243966853,  -37.704459388091394",Bungal
"143.64930526637667,  -37.20417893246401",Caralulup
"142.1253509354706,  -34.3071195080775",Cardross
"145.43400388194405,  -37.519346108076874",Castella
"144.21832248540727,  -37.05874392035519",Castlemaine
"145.02158835227846,  -37.87203610061687",Caulfield North
"148.93199188758715,  -37.53249818322179",Club Terrace
"144.0970030647312,  -37.171689629229206",Clydesdale (Vic.)
"142.33923268045632,  -34.53901668832606",Colignan
"141.67071926761156,  -37.35893877469347",Coojar
"143.52745655870336,  -38.29645888137235",Cororooke
"144.80000845088594,  -36.8734321966093",Costerfield
"144.67540038050055,  -36.616728108661185",Creek View
"146.62143834922665,  -37.484076407543554",Crookayan
"147.79889951780243,  -36.14717896460529",Cudgewa
"145.82145110893123,  -38.19011236592362",Drouin South
"144.6013877006999,  -38.174068243370215",Drysdale
"147.6801673321919,  -37.9042139949054",Eagle Point
"143.94873000475627,  -36.875415906619864",Eastville
"144.5807397280794,  -36.4663144017223",Elmore
"147.85711352466757,  -37.30064551961261",Ensay North
"147.22737308754608,  -36.50146893130396",Eskdale (Vic.)
"144.91940468138168,  -37.786727851820984",Flemington
"144.29269984347403,  -38.12459350157533",Fyansford
"147.47673794154295,  -36.83385521666766",Glen Wills
"141.47418722865103,  -38.24867739374218",Gorae West
"146.37490247084705,  -38.28858218412272",Hazelwood
"145.04644977415973,  -37.03705980669906",Hilldene
"147.15839030240159,  -37.01201845397954",Hotham Heights
"146.41291180099822,  -38.34788880483543",Jeeralang Junction
"141.97788328640985,  -36.09809755003942",Jeparit
"142.9994676608813,  -37.06312298440214",Joel South
"143.2631531937212,  -35.83746223795997",Kalpienung
"141.83586634405938,  -37.1113592693902",Kanagulk
"142.40298462716612,  -36.36678851757295",Kellalac
"143.30479303022025,  -34.83395317673186",Kenley
"147.02839714743322,  -36.4287358295637",Kergunyah South
"145.03328780145944,  -37.80348802814764",Kew (Vic.)
"144.77194116189125,  -37.7337100918583",Kings Park (Vic.)
"147.48495025452377,  -36.14973436442878",Koetong
"146.52535184601678,  -38.34814152130709",Koornalla
"145.03583519071947,  -37.84136776248837",Kooyong
"143.83404363512335,  -37.55012108436469",Lake Wendouree
"144.13963889344015,  -37.41131391366491",Leonards Hill
"142.46543048241728,  -38.28802473998147",Mailors Flat
"142.1628380062817,  -36.807467300504904",McKenzie Creek
"144.97152779283476,  -37.82496129017888",Melbourne
"142.7085644336547,  -38.445830815975846",Mepunga East
"141.3792363266158,  -36.37407866338575",Miram
"147.0607769658361,  -38.03740337705388",Montgomery
"146.78393444473454,  -36.731341467571994",Mount Buffalo
"146.0987595686244,  -37.1973327239404",Mountain Bay
"145.8663780743475,  -38.30225715887137",Mountain View (Vic.)
"143.74810810961708,  -36.735555984241216",Murphys Creek (Vic.)
"143.93905319019274,  -35.54322546379499",Murrabit
"148.21212485963173,  -37.416300683739095",Murrindal
"141.71384511287772,  -38.003857350161674",Myamyn
"143.4353001367165,  -36.156764658684935",Nareewillock
"143.21759754312743,  -35.0542069787756",Piangil
"141.5699170677578,  -38.302002924361226",Portland North
"146.88731834799776,  -36.57515859227137",Rosewhite
"145.00009063167204,  -38.31532268147405",Safety Beach (Vic.)
"147.12124195550618,  -36.36210024030037",Sandy Creek (Vic.)
"142.89443901994167,  -35.49658279668339",Sea Lake
"144.89170668186648,  -37.80662835407663",Seddon (Vic.)
"143.8732712109119,  -38.5919282665668",Separation Creek
"146.94288844127374,  -36.24111309117866",Staghorn Flat
"144.68053010161267,  -38.24799065141016",Swan Island
"141.7599634012189,  -36.86664298347962",Tooan
"143.08354523251768,  -35.180029669035996",Turoar
"146.22711728531837,  -38.549977640848226",Turtons Creek
"141.3999259388867,  -36.92040479207352",Ullswater
"141.8249285984691,  -35.15325937413764",Underbool
"143.51917013960608,  -37.87258545582343",Wallinduc
"145.42626436171398,  -37.78052467708868",Wandin North
"145.20909174056712,  -37.74783218274227",Warrandyte
"144.37946856637734,  -36.722353718004356",Wellsford
"142.99281798639893,  -35.91993396123742",Whirily
"146.04843728947515,  -36.15522568935624",Wilby
"143.19544393926935,  -36.854614101339145",Winjallok
"147.79295123071577,  -37.7117452737284",Wiseleigh
"142.48206311076555,  -38.31602359524912",Woodford (Vic.)
"143.18170827837338,  -36.13630217654798",Wycheproof South
"145.38151960019354,  -37.64677349344099",Yarra Glen
"146.71996950931194,  -38.559198804969604",Yarram
"150.6956941548848,  -23.096724991875035",Adelaide Park
"153.26919696067404,  -27.707845336138195",Alberton (Qld)
"139.33696951457344,  -23.27039059382921",Amaroo (Qld)
"141.80314920598863,  -10.469577881455317",Arafura Sea
"152.95455382965366,  -27.392079556162514",Arana Hills
"143.99540177172335,  -17.061560540016238",Arbouin
"149.2702067365274,  -21.441723468921275",Armstrong Beach
"142.14532810203949,  -10.11639689803632",Badu Island
"152.68534153919893,  -25.87554999493677",Bauple Forest
"152.64124383012737,  -25.29644621455626",Beelbi Creek
"150.46070048963156,  -23.551698217296103",Bouldercombe
"153.0365468729948,  -27.444706403388828",Bowen Hills
"139.53105126703485,  -20.725832955463428",Breakaway
"146.33998509686256,  -20.101539049377426",Broughton (Qld)
"153.0042010565618,  -27.141259388987695",Burpengary East
"150.09187328141448,  -28.535466861909256",Callandoon
"150.39240039532672,  -22.930430162675254",Canal Creek
"149.02878977238498,  -20.91612270145938",Cape Hillsborough
"147.785408264174,  -23.187864026115157",Carbine Creek
"152.53182159815134,  -28.210789746145764",Carneys Creek
"138.3879749754353,  -22.12106725341024",Carrandotta
"153.36603744917866,  -28.019713167210636",Carrara
"151.93596858631776,  -26.28878824702198",Cherbourg
"148.55740830610677,  -23.661838136104482",Comet
"152.77494840067408,  -26.602477348713617",Coolabine
"148.44181579181915,  -26.08168414877543",Cornwall (Qld)
"146.10619090917962,  -17.65163787329663",Cowley Beach
"152.5954936619176,  -28.14769280011037",Croftby
"148.47268915662104,  -20.37525127675052",Crystal Brook (Qld)
"153.1351325395202,  -26.784042289449193",Dicky Beach
"151.035153404466,  -27.276424446645958",Ducklo
"149.06130213124732,  -26.114317639688284",Durham Downs
"152.93709695690995,  -27.339996413469528",Eatons Hill
"153.16856693436662,  -27.70282928674137",Edens Landing
"152.47691480710637,  -24.90931624092106",Elliott Heads
"148.82870600137636,  -21.50025922117842",Epsom (Qld)
"152.15718643810357,  -25.279666713218024",Eureka (Qld)
"151.70470056681555,  -27.872795882268804",Felton South
"143.3244016256997,  -18.245028395883388",Georgetown (Qld)
"151.3428216789654,  -23.814619463578236",Gladstone Harbour
"152.5287345075487,  -26.204068263049383",Glastonbury
"145.8226673926209,  -16.942876112026898",Glen Boughton
"148.6231345579263,  -20.410181794122142",Glen Isla
"149.2266653086456,  -24.317198069329752",Goomally
"146.01572554852288,  -17.527985806228916",Goondi Hill
"146.24513346341556,  -20.07622835311172",Grand Secret
"151.31305010641978,  -27.965519712905238",Grays Gate
"148.5023626168776,  -20.249864583849863",Gregory River (Whitsunday - Qld)
"153.06811760443617,  -27.438373046042155",Hamilton (Qld)
"150.72095112223147,  -23.16806544963178",Hidden Valley (Qld)
"152.93436112899045,  -26.617455059670302",Highworth
"152.64561643237877,  -26.49855734490721",Imbil
"148.5546463192189,  -25.855348233113407",Injune
"151.46850760086147,  -27.153219505300612",Irvingdale
"152.72570204019158,  -25.494866808455523",Island Plantation
"152.9341219039833,  -27.556268352871978",Jamboree Heights
"152.9106527871038,  -27.861248291347525",Kagaru
"153.12342622849238,  -27.834420635429762",Kairabah
"152.586370876783,  -25.938603286957115",Kanigan
"153.03301615883157,  -28.11482014230346",Kerry
"151.53585564826471,  -27.804768492618766",Kincora
"151.6530770278463,  -26.687584276559317",Kumbia
"151.8921367961752,  -26.669922730298108",Kunioon
"145.6474678264475,  -17.295424014022622",Lake Eacham
"152.54458286955915,  -27.27223152145926",Lake Wivenhoe
"144.8038796609791,  -15.970554510989592",Lakeland
"152.33477134102353,  -27.553380415548407",Lawes
"146.01361915439398,  -17.978484114141253",Lower Tully
"146.46833947948014,  -19.26738198431514",Lynam
"151.78651495418444,  -26.838479104197614",Maidenwell
"151.19076987324354,  -25.477267652821023",Malmoe
"151.5894177627232,  -26.628762681997063",Mannuem
"152.0309422369958,  -27.352686877647464",Merritts Creek
"145.97647785701608,  -17.914625385034416",Merryburn
"148.64537489055255,  -22.84031574426329",Middlemount
"145.54636510316024,  -17.47869258537101",Minbun
"151.02563415928913,  -26.000395125012705",Monogorilby
"150.0256654003092,  -23.37302868202487",Morinish South
"148.74159430758098,  -21.00588261714143",Mount Charlton
"147.84025139677266,  -25.16517913623764",Mount Moffatt
"152.93218594367016,  -27.54605055578388",Mount Ommaney
"151.61388008429418,  -26.900068983546703",Mowbullan
"151.87218631288457,  -27.378993754776737",Muniganeen
"153.0594243622188,  -26.82034897154905",Nirimba (Qld)
"152.3181407713387,  -28.02036613759262",North Branch (Southern Downs - Qld)
"151.91219493925558,  -26.198299879455433",Oakdale (Qld)
"152.55214167839264,  -25.26580842681962",Pacific Haven
"151.785517398548,  -28.355652169239054",Palgrave
"139.48861231583206,  -20.73718076421573",Parkside (Qld)
"152.04518981054326,  -27.31317664077767",Pechey
"150.28787804928305,  -26.29193282244826",Pelham (Qld)
"145.77633830588613,  -16.956657489465382",Portsmith
"153.1818976947946,  -27.48634693056109",Ransome
"143.6162012092429,  -17.265296524726185",Ravensworth (Qld)
"152.78386234442652,  -27.671605158606113",Ripley
"152.03051289469724,  -27.67924719001985",Rockmount
"151.34736401187118,  -28.013099189999668",Rocky Creek (Qld)
"148.61783695888954,  -24.471956674110388",Rolleston
"151.9705533561217,  -28.252170682461543",Rosenthal Heights
"151.71419804075714,  -27.352221122960927",Sabine
"152.17938543092487,  -27.463282557935152",Seventeen Mile
"153.14587051631793,  -26.79645993943813",Shelly Beach (Sunshine Coast - Qld)
"152.29978582172373,  -25.26791466328197",South Isis
"152.9101845601754,  -27.65244553040417",Springfield (Ipswich - Qld)
"148.6100909586534,  -27.94758905524624",St George (Qld)
"153.0576572701363,  -27.77677902433028",Stockleigh
"152.96407987643195,  -28.135604958950616",Tabooba
"152.70499769739845,  -25.308236048310217",Takura
"152.81759043823223,  -25.45758651464594",Tandora
"152.6655953757959,  -25.65927103821601",Teddington
"142.21975625523845,  -10.578827780315125",Thursday Island
"145.7309336463554,  -28.529093026869443",Tuen
"151.9572698265693,  -27.222538651788405",Upper Pinelands
"153.00687166280517,  -26.54592066291691",Valdora
"151.7210091478429,  -25.893260183516475",Wahoon
"150.94349216956635,  -26.92148151096066",Warra
"141.92491249618658,  -12.679555595716405",Weipa Airport
"151.40184182614274,  -27.441973902017853",West Prairie
"152.6740425613067,  -27.682144011338327",Willowbank
"139.99653590538784,  -23.01653801418152",Wills
"151.8613793488357,  -26.301143820096787",Wondai
"152.09029725626928,  -24.707039740856626",Yandaran
"145.60086379935268,  -17.27057382535735",Yungaburra
"138.94974603152238,  -35.2878145488458",Belvidere
"138.98560535222103,  -34.54474441936192",Bethany
"138.97762466934307,  -35.25051579967586",Bletchley
"138.34898894025963,  -32.86583828199871",Booleroo Centre
"140.7378379673443,  -34.40624369568331",Bugle Hut
"139.98008209513046,  -36.16398903282914",Bunbury (SA)
"138.66653632734378,  -34.939979738061176",Burnside (SA)
"138.95323276744423,  -33.685219158006035",Burra (SA)
"138.60100486055262,  -34.73706716918903",Burton (SA)
"134.34036625026013,  -33.046174820061026",Calca
"140.92054930310215,  -37.9263504554522",Caroline
"134.52199679193845,  -32.59148351755846",Chilpenunda
"138.6137954004139,  -34.857154181617204",Clearview
"135.3546456999125,  -34.60770225499381",Coffin Bay
"140.6736810785399,  -34.10054953640474",Cooltong
"132.14809650938258,  -31.815699419798683",Coorabie
"137.04910299555894,  -34.95667905998222",Corny Point
"137.13096483734816,  -35.75767996594498",Duncan
"138.68528738053604,  -34.712305841353384",Elizabeth Park
"138.7337363018055,  -34.61659497824034",Evanston
"138.1072056893064,  -30.110987637458603",Farina
"138.543080010667,  -34.9102418428232",Flinders Park
"138.9181051475947,  -34.7814478117861",Forreston
"135.7818334010622,  -34.59537668606297",Green Patch
"138.6386489919438,  -35.025414318097866",Hawthorndene
"138.65615711007308,  -34.939235322319476",Hazelwood Park
"136.00276824021137,  -33.86995333011429",Hincks
"139.80260422622646,  -35.57632477176016",Ki Ki
"140.62296794438788,  -37.62984958513481",Koorine
"133.83245794382432,  -32.22763335601233",Laura Bay
"138.65509921969053,  -34.929715254436026",Leabrook
"140.42267965665098,  -34.29679413387271",Loveday
"136.40305986635417,  -31.720375803114358",Mahanewo
"139.97994699195837,  -32.43137925877926",Manna Hill
"135.12720668141952,  -32.78711539929591",Minnipa
"135.5653478158874,  -31.67812091998745",Moonaree
"137.60660177704383,  -34.08167236137283",Moonta Mines
"138.7644755324597,  -34.9920925787118",Mount George (SA)
"139.1877002732632,  -34.225866733726384",Neales Flat
"139.05818589103896,  -32.88258202570136",Oodla Wirra
"138.55207384814767,  -34.98892919663104",Park Holme
"138.6190645283852,  -34.69130598295442",Penfield
"137.7810200757723,  -32.9929295005574",Point Lowly
"137.3427351324285,  -34.94938737143059",Point Turton
"135.84988693267374,  -34.73048977004408",Port Lincoln
"139.30343990765212,  -34.925432917208546",Port Mannum
"138.02681675256758,  -33.211359818632324",Port Pirie South
"138.17990923778643,  -35.535878082566256",Rapid Bay
"140.7470800994677,  -34.121738893258005",Renmark North
"139.6747968620388,  -36.1229136818042",Salt Creek
"138.61398394811735,  -34.1872760453134",Salter Springs
"138.48479390441227,  -35.204164611262165",Seaford Rise
"138.25044342265713,  -35.540322660663264",Second Valley
"140.48310031098117,  -36.4647346923063",Swede Flat
"139.47681212051432,  -35.279590624859466",Tailem Bend
"139.30159290943882,  -35.077056679391056",Toora (SA)
"138.52794703471557,  -34.787673962668165",Torrens Island
"136.05535558039847,  -34.40811356113304",Tumby Bay
"134.46153488392855,  -33.1031779967735",Tyringa
"138.66073428364004,  -34.839936015056395",Valley View
"133.23903553848015,  -31.95773348900777",Watraba
"139.9143191051509,  -36.74407956989048",West Range
"138.59526456652475,  -34.97161811784723",Westbourne Park
"136.94141756844792,  -35.71431846863255",Western River
"138.98585889371225,  -31.39238024452656",Willow Springs
"138.65432846159388,  -34.8654823789251",Windsor Gardens
"140.20009469997171,  -34.24717413118803",Woolpunda
"116.31011178798026,  -31.739034168338705",Bailup
"116.76727298193529,  -30.60310722985706",Ballidu
"121.97331876281982,  -33.81559145634018",Bandy Creek
"117.93445492983889,  -34.973098856105864",Bayonet Head
"127.52553631021091,  -27.94550359480342",Beadell
"115.85876772145504,  -33.54594090826679",Beelerup
"115.74903861642251,  -31.21670313492816",Beermullah
"116.02718091097151,  -31.90122881171637",Bellevue (WA)
"118.12367546639109,  -29.717341527163764",Bimbijy
"115.85258326657276,  -32.043513359851026",Brentwood (WA)
"113.71835952652735,  -24.89361842300968",Brown Range
"116.48914403610056,  -31.07690154432455",Calingiri
"116.00190854654606,  -32.11309979136866",Camillo
"118.02118802291416,  -21.82481360683779",Chichester (WA)
"115.72590286889856,  -31.686287978488224",Clarkson
"115.86755575264239,  -31.999664322360672",Como (WA)
"116.0312382885572,  -29.885565686241268",Coorow
"119.14049286509986,  -31.125102329339",Corinthia
"116.00179088917001,  -31.219525474925913",Cullalla
"115.774476895895,  -33.404763290180206",Dardanup
"115.87188562344045,  -31.888184407105797",Dianella
"118.01528479996286,  -32.93787320297563",Dudinin
"116.68704507282024,  -32.76595985960352",Dwarda
"128.46705901508284,  -31.605593234267563",Eucla
"116.47768463688499,  -32.05229489288046",Flynn (WA)
"115.13112215010973,  -34.0874900551168",Forest Grove (WA)
"123.73032840601175,  -18.304506001299877",Geegully Creek
"118.42391589965908,  -31.21994377324301",Goomarin
"116.0458798956572,  -33.695407312677794",Grimwade
"118.06920938775241,  -31.5182665891056",Hines Hill
"115.49151067398918,  -33.721681442682346",Hithergreen
"121.70500681873439,  -31.21097231232254",Kambalda East
"118.68585597826686,  -32.418002379598406",Karlgarin
"116.87926056014123,  -20.761331694040873",Karratha Industrial Estate
"115.71907658241958,  -32.84921549188363",Lake Clifton
"117.89416835531772,  -34.99007783105783",Lange
"114.61318935291874,  -28.791984530985506",Mahomets Flats
"116.35867999667678,  -31.968706360796556",Malmalling
"115.05544547045311,  -33.96890194730759",Margaret River (WA)
"127.68676371685285,  -18.242887974093964",McBeath
"116.929876118996,  -34.124966580202596",Mobrup
"115.3480371772671,  -29.278594027070444",Mooriary
"117.95701523224182,  -33.253571930333976",Moulyinning
"115.6548989377306,  -29.194381322683068",Mount Budd
"118.93869292849476,  -30.088943198701198",Mount Jackson
"114.6252867182095,  -28.79853066293719",Mount Tarcoola
"113.948857223229,  -26.576071336091626",Nanga
"117.08290517014818,  -31.864964032538644",Narraloggan
"118.31641773354765,  -33.1038300773738",Neendaling
"115.479490809197,  -27.724767665514737",Nerramyne
"118.9233894305627,  -31.12353023080769",North Bodallin
"115.75948457795246,  -32.09326641703527",North Coogee
"118.22686215475116,  -30.331466692067032",North Wialki
"114.62252791264054,  -28.34975916401344",Northampton
"120.21607339893407,  -21.97223183617014",Nullagine
"115.76398411186209,  -33.374319610223175",Paradise (WA)
"116.97927608755361,  -30.415691160885583",Petrudor
"114.63058864485177,  -28.78656913653322",Rangeway
"115.70919766696228,  -32.29523645268456",Shoalwater (WA)
"113.74453319878697,  -24.84685335996796",South Plantations
"116.2484322744783,  -34.027798983589946",Sunnyside (WA)
"116.05293356347693,  -31.882462755888692",Swan View
"115.76393168239504,  -31.970756485448295",Swanbourne
"117.63349578497176,  -34.04330518351625",Tambellup
"115.7834205177905,  -28.751557933441383",Tardun
"116.30047053871041,  -34.36910840959695",Upper Warren
"115.62918074996689,  -33.37981551324905",Usher
"114.58864843666045,  -28.7794115147979",West End (WA)
"114.98770138623786,  -28.698335551838994",Wicherina
"121.9543489487225,  -31.492069765160632",Widgiemooltha
"118.30527809147198,  -22.250181254335647",Wittenoom
"115.84048317907984,  -33.126147647029136",Wokalup
"115.43692906281008,  -33.61304526738236",Wonnerup
"117.89518307202798,  -34.53608802267841",Woogenellup
"116.57741258344858,  -30.092651028720383",Wubin
"116.25361441078807,  -32.83507444390162",Wuraming
"146.80329481282223,  -41.15995340731031",Beauty Point
"147.42701767924186,  -41.48962781147129",Blessington
"147.88124612879523,  -40.917938753060305",Boobyalla
"146.45606750188122,  -42.103412457685934",Bronte Park
"147.01818968871936,  -41.54361169422366",Carrick (Tas.)
"146.26228013856937,  -41.44174601727902",Claude Road
"146.82965995764667,  -41.609651735017394",Cluan
"148.25715782447529,  -42.073079031618576",Coles Bay
"145.97059110138858,  -41.126938433858896",Cuprona
"147.49258484832202,  -41.6023993495988",Deddington
"145.7803534793902,  -41.02174858479247",Doctors Rocks
"147.1014394811598,  -42.98704014953757",Grove
"145.91072605544008,  -41.08669274545169",Havenview
"146.8832475969777,  -41.19062025764883",Kayena
"147.61314926255844,  -42.82825633743284",Lewisham (Tas.)
"146.33184256173988,  -41.33219288372036",Nook
"146.95758739616488,  -42.75262954418832",Plenty (Tas.)
"146.40484621786382,  -41.3305504060324",Railton
"145.80057661231467,  -41.055285703177624",Somerset (Tas.)
"146.18708066969157,  -41.17036354992257",Ulverstone
"145.8997254478731,  -41.07167070472281",Upper Burnie
"147.1261282995474,  -41.45369480134338",West Launceston
"134.32485912640783,  -21.019854724469766",Ali Curung
"134.37730436944688,  -16.07817720697781",Arnold (NT)
"133.3526988111865,  -16.27658276048086",Birdum
"132.26065749592448,  -14.848706123671217",Manbulloo
"134.1870671593228,  -19.65482032180207",Tennant Creek
"149.0716655170166,  -35.31213633089221",ACT Remainder - Weston Creek
"149.1279892531221,  -35.16954706095788",Amaroo (ACT)
"149.07826288053778,  -35.43328411372002",Bonython
"149.04999031241107,  -35.22585527854406",Florey
"148.98642705506487,  -35.23263989400133",Strathnairn
"151.72554028534307,  -32.93817731693935",Adamstown
"152.82379945406254,  -28.57309512503122",Afterlee
"148.92090672383338,  -33.16437635169757",Amaroo (NSW)
"151.17145433711323,  -33.880730238758474",Annandale (NSW)
"151.63077751384222,  -32.935017194285635",Argenton
"149.7977042870666,  -29.2501035671507",Ashley
"151.81718096760915,  -30.18019335806641",Bald Blair
"149.49129792856777,  -34.606365746050095",Bannister (NSW)
"150.7230742192905,  -34.690180556045846",Barren Grounds
"148.4780322999553,  -34.44559801354725",Barwang
"150.22164458521635,  -34.51222171150491",Belanglo
"149.0245496092019,  -33.11360304702435",Belgravia
"150.49995894963243,  -35.22972435971117",Bendalong
"150.72663556885695,  -34.79238942050235",Berry
"151.12023098342434,  -33.95340441963377",Bexley
"150.6089905797651,  -31.765006982075484",Big Jacks Creek
"153.46711752122704,  -28.22638151851954",Bilambil
"148.61812822640212,  -33.67279896729355",Billimari
"151.47027961696645,  -32.7471000349476",Bishops Bridge
"148.03352690019855,  -35.3287388736574",Black Creek (Snowy Valleys - NSW)
"152.53495159884585,  -32.071699206176866",Black Head
"151.50352054303403,  -33.21105587305786",Blue Haven
"150.64592665537103,  -29.09135149412223",Blue Nobby
"147.7709386750427,  -33.09509337984063",Bogan Gate
"149.6322896144238,  -30.407709787567516",Bohena Creek
"149.2443773226135,  -36.885995229313174",Bombala
"144.74479807517938,  -33.71322226997095",Booligal
"152.52237026958187,  -32.31671308209729",Booti Booti
"150.9994723681886,  -30.124064988489394",Bundarra
"152.0940503374635,  -31.89164453387353",Bundook
"149.5369763977056,  -33.91099833899622",Burraga
"146.79472777127785,  -35.8300890359105",Burrumbuttock
"150.88068806151807,  -33.911091970106185",Busby
"148.5823503351454,  -36.83025658181278",Byadbo Wilderness
"151.1164070818663,  -33.84700888619823",Cabarita (NSW)
"149.1147961439128,  -36.89442171740463",Cambalong
"151.6113072729953,  -32.90400581121681",Cameron Park
"152.233044839291,  -31.721305726526246",Caparra
"150.67901738494513,  -33.68730668916272",Castlereagh
"151.36247227432554,  -32.8344947617245",Cessnock
"153.24728560706927,  -29.385634838914626",Chatsworth (NSW)
"150.14653368748057,  -31.45392109850772",Colly Blue
"147.1926494771611,  -34.815020958169846",Coolamon
"151.82566022104785,  -31.97845535495654",Copeland
"147.75047169035008,  -35.74692145743274",Coppabella (NSW)
"144.4594967658353,  -34.03049452343152",Corrong
"151.16793700106902,  -33.56723427228803",Cowan
"150.8077763516123,  -34.593706441853875",Croom
"146.44671813587144,  -34.68162315497842",Cudgel
"150.25035677330615,  -33.4895878480961",Dargan
"152.6505205385171,  -29.154061629663463",Deep Creek (Clarence Valley - NSW)
"153.37469535672068,  -28.99218561805726",Dungarubba
"151.04068108125912,  -33.684018889370314",Dural (Hornsby - NSW)
"152.85432239096747,  -31.083487270657002",East Kempsey
"150.79426865012323,  -33.50547045957725",East Kurrajong
"150.84822290216553,  -33.90048007303196",Elizabeth Hills
"150.06418166000915,  -30.866696639818716",Emerald Hill
"146.38747250212347,  -33.931538263476384",Erigolia
"149.0386407491468,  -33.53290450883092",Errowanbang
"150.89379874530397,  -34.39349550312506",Fairy Meadow
"152.87274602405355,  -28.35656421293522",Findon Creek
"150.84607015898516,  -34.580783179032586",Flinders (NSW)
"149.8405523872542,  -33.50027172466717",Gemalla
"152.9775188322929,  -28.619492636606722",Geneva
"149.48928507763583,  -33.526970928797276",Georges Plains
"149.24738302749583,  -36.215321650506226",Glen Fergus
"150.99851876491923,  -33.702093456441105",Glenhaven
"151.40996438289682,  -31.65885783370876",Glenrock (NSW)
"153.45789165127303,  -28.989150916213838",Goat Island
"149.22631245529584,  -35.44459617253194",Googong
"152.81968656573247,  -28.446638812438323",Grevillia
"151.62469856266205,  -30.222981974139994",Guyra
"151.08615303788218,  -34.0343710246474",Gymea
"152.58349340439185,  -31.71536100941852",Hannam Vale
"152.66618547774394,  -31.863146781031475",Harrington
"149.7342682339326,  -32.56397041726703",Hayes Gap
"150.99411895443887,  -34.08414627869834",Heathcote (NSW)
"150.74296301085008,  -33.60432559821508",Hobartville
"153.18700946685854,  -29.28062752562563",Jacky Bulbin Flat
"148.1298032077896,  -34.098128562618164",Kikiamah
"152.52165116251808,  -31.234313025989973",Kippara
"150.75562340862405,  -30.816177287768173",Klori
"153.3746459722596,  -28.30781559667958",Kynnumboon
"150.97548761456437,  -33.89615560047181",Lansdowne (Canterbury-Bankstown - NSW)
"149.33292487586576,  -34.884140564265046",Lerida
"151.0231379590221,  -34.17825569248626",Lilyvale (NSW)
"151.91245913109918,  -32.57945943398888",Limeburners Creek (Mid-Coast - NSW)
"149.4739251271399,  -34.21276937026795",Limerick
"145.18495582212924,  -35.40968921083866",Lindifferon
"144.8665577315906,  -30.545842017433532",Louth
"152.57903175482122,  -28.776793806359436",Lower Peacock
"153.0529618645906,  -29.53516267270564",Lower Southgate
"149.8421027723389,  -32.64177003257472",Lue
"151.79659336872538,  -32.3080855164895",Main Creek
"145.74558572749842,  -35.48194481189496",Mairjimmy
"151.80006453845252,  -31.641152391486152",Mares Run
"151.66265314411214,  -32.880187875678786",Maryland (Newcastle - NSW)
"150.01567756173807,  -30.978659604575352",Marys Mount
"152.33826059424098,  -32.39823555050987",Mayers Flat
"143.77299897826674,  -35.32373778617058",Mellool
"149.01966400727045,  -36.84800200831736",Merriangaah
"151.842801445168,  -30.571855359254133",Metz
"149.40185131767615,  -33.279697363137814",Milkers Flat
"150.81590694803006,  -33.76840461802135",Mount Druitt
"147.66616952922118,  -31.28157527568614",Mount Harris
"151.22454798968874,  -32.40714229106546",Mount Olive (Singleton - NSW)
"150.42228486697232,  -33.5425100100481",Mount Tomah
"152.6752411502927,  -30.904330565664797",Mungay Creek
"148.53172118913872,  -33.35262636608328",Murga
"150.90824600704408,  -32.31407930808702",Muswellbrook
"145.4438917352214,  -35.547381049179194",Myrtle Park
"150.92131626850775,  -33.64621683002934",Nelson (The Hills Shire - NSW)
"152.94471654391614,  -30.689946031623748",North Macksville
"150.96398539300185,  -33.72982516816646",Norwest
"148.79542552349113,  -36.61858101937044",Numbla Vale
"149.7194828138355,  -36.62783455446602",Numbugga
"153.4597051889291,  -28.322060817136638",Nunderi
"150.8383944923994,  -33.73881936986765",Oakhurst (NSW)
"148.69450537817798,  -36.040150900199386",Old Adaminaby
"147.49534924751705,  -34.81501719238913",Old Junee
"150.6018470051784,  -34.03106126849198",Orangeville
"151.00224855269803,  -34.206774214613546",Otford
"149.74383416108589,  -33.18282729623019",Paling Yards (Bathurst Regional - NSW)
"151.30790388551165,  -33.48779522204969",Phegans Bay
"148.8002985948185,  -30.383915844510106",Pilliga
"149.60980972860742,  -32.928815209141895",Pyramul
"146.23797141090458,  -35.87477773639781",Ringwood (NSW)
"150.86737502966108,  -33.67868409432086",Riverstone
"150.84292962584615,  -33.771955056740524",Rooty Hill
"149.67015877366458,  -34.76658320687318",Run-o-Waters
"152.01834555641724,  -29.23416385985066",Sandy Flat
"141.14938386315367,  -31.769490127540394",Silverton (NSW)
"152.85878486133026,  -29.188344993202826",Six Mile Swamp
"152.85401171460063,  -28.564115320529837",Smiths Creek (Kyogle - NSW)
"152.94530552324434,  -31.013910907900957",Smithtown
"149.58556396414036,  -35.94317375393821",Snowball
"150.13735938776026,  -33.50060707013853",South Littleton
"143.55422261566872,  -35.19133402250054",Speewa
"150.60582992532204,  -35.11329752821465",St Georges Basin
"151.270237942793,  -33.89860995723425",Tamarama
"150.90068565406148,  -31.08477090119609",Taminda
"150.93197614045658,  -31.0926034520014",Tamworth
"152.15816989326595,  -32.61818826468293",Tea Gardens
"150.35191422029683,  -35.47679164409067",Termeil
"151.08920924268418,  -28.891266795979636",Texas (NSW)
"150.79292133516174,  -33.74561372494037",Tregear
"151.7838082331924,  -30.102461292941612",Tubbamurra
"148.48872995056982,  -35.19369080355234",Tumorrama
"148.27128671367356,  -35.341209076193685",Tumut Plains
"149.59339550898315,  -33.11565834897694",Turondale
"151.12911662603705,  -33.732869545503405",Turramurra
"150.1832763321902,  -32.495867077776374",Upper Bylong
"146.59206717989127,  -35.432525513163",Urangeline
"150.67117159936467,  -35.07426276492686",Vincentia
"152.54266357408017,  -31.684639711710677",Waitui
"150.06088495943902,  -36.37099346496943",Wallaga Lake
"148.1189304182529,  -34.51410666832266",Wallendbeen
"147.03032343697828,  -34.483964455604294",Walleroobie
"151.1474740315792,  -29.669643166755904",Wandera
"152.2815317312768,  -32.14260177207053",Wang Wauk
"152.04388209355548,  -32.107180365678374",Waukivory
"151.51171999727035,  -29.561928873489947",Wellingrove
"150.83451150317072,  -34.73319614068223",Werri Beach
"151.3232612909487,  -33.42105176067385",West Gosford
"147.88371648614262,  -35.56986136964194",Westdale (Snowy Valleys - NSW)
"150.79279372424855,  -33.7240597223331",Willmot
"152.116562928971,  -28.739881686941526",Willsons Downfall
"150.80824383843995,  -33.655942352077",Windsor Downs
"151.7352700393251,  -32.45415063936318",Wirragulla
"153.1344672059174,  -29.513962827907342",Woodford Island
"153.53827345837152,  -28.452713331369196",Wooyung
"149.67505315478272,  -35.789406274382294",Wyanbene
"151.1220538112658,  -32.58272557242486",Wylies Flat
"147.2216741515733,  -35.121447557433115",Yarragundry
"151.54682273137246,  -33.10025480616945",Yarrawonga Park
"147.42649158263302,  -34.91654818753796",Yathella
"149.81300263475916,  -33.4320288810175",Yetholme
"144.89756806741838,  -37.75970860533644",Aberfeldie
"143.66839185543972,  -37.08154384066159",Adelaide Lead
"145.48432414156824,  -37.91183782475499",Avonsleigh
"144.62868032477874,  -36.261222365580565",Bamawm
"143.78816672373222,  -38.50818733185537",Barwon Downs
"145.06700237097843,  -37.92208386324244",Bentleigh East
"142.9675017008538,  -35.64419866246217",Berriwillock
"144.22952888562864,  -36.83527597202803",Big Hill (Greater Bendigo - Vic.)
"143.82199441006375,  -38.333617365459624",Birregurra
"142.76400461812582,  -37.13483666071384",Black Range (Vic.)
"144.90362132140726,  -38.40951689181505",Boneo
"142.68414309654122,  -36.34701562456058",Boolite
"145.28609693773166,  -37.856106320226225",Boronia
"143.1261501644662,  -38.30462659367055",Bostocks Creek
"145.26557029287926,  -38.14155952995705",Botanic Ridge
"145.1260800422159,  -37.821512019087464",Box Hill (Vic.)
"145.06231200582454,  -37.20930472020058",Broadford
"146.36250976722857,  -38.42120365431276",Budgeree
"147.58465873446664,  -37.6425285437015",Bullumwaal
"146.05473507146775,  -36.28060578346001",Bungeet
"143.6241541009534,  -37.49291395133995",Burrumbeet
"145.5390430312049,  -38.24338184287324",Caldermeade
"145.61630512780695,  -38.660388239786236",Cape Paterson
"143.56944331577495,  -35.42528039562052",Castle Donnington
"145.60102723348388,  -37.15465899599757",Cathkin
"145.06054243125035,  -37.96353907195422",Cheltenham (Vic.)
"144.1976061458191,  -37.3306325301745",Coomoora
"143.7690191551434,  -37.107273171953615",Craigie (Vic.)
"144.7715285920614,  -37.7692920157695",Deer Park
"145.466928598054,  -37.98005715346362",Dewhurst
"145.1638153959502,  -37.77878963565134",Doncaster East
"145.43908663267894,  -37.041184008562944",Dropmore
"147.6345623480505,  -37.812765194811156",Eastwood (Vic.)
"142.89988380162492,  -38.38754409762987",Ecklin South
"144.3576147607101,  -37.072111776272976",Elphinstone (Vic.)
"147.83936727990283,  -37.38479058359",Ensay
"144.6243764466473,  -37.54882601676909",Gisborne South
"147.30627465434796,  -36.09638460464982",Granya
"142.8964717475516,  -36.61977812096324",Grays Bridge
"144.4440047953306,  -37.172061184818304",Greenhill (Vic.)
"144.3318456402087,  -38.20280077798459",Grovedale
"143.69591131252227,  -37.589147546044515",Haddon
"145.0500164226375,  -37.83095094928407",Hawthorn East
"145.24402279156848,  -37.830794144935524",Heathmont
"144.1395088370681,  -37.319659000347585",Hepburn Springs
"143.65199491268842,  -37.82047813802846",Illabarook
"145.58496455924924,  -36.14607609371936",Invergordon (Vic.)
"143.87550518657315,  -37.50254702824956",Invermay (Vic.)
"146.22889007932778,  -37.66621829539824",Jericho (Vic.)
"144.79981471190825,  -37.698380320728674",Keilor Lodge
"148.12574207922887,  -37.83939688294877",Lake Tyers
"143.4972959319434,  -37.379750171488",Langi Kal Kal
"144.07609667764302,  -36.68720197282661",Leichardt
"145.94482934088515,  -38.47437305315634",Leongatha
"144.40594370958314,  -37.50067632823735",Lerderderg
"142.53347461362412,  -36.73972687370305",Lubeck
"144.18832065725582,  -37.94662618419334",Maude (Vic.)
"144.83801419608392,  -37.67375369500678",Melbourne Airport
"146.6861311994601,  -36.56462557239514",Merriang
"144.3797552635697,  -36.35713701917",Milloo
"143.69594822356066,  -37.359217532528824",Mount Bolton
"143.2147900263405,  -37.25549248328745",Mount Cole
"145.5607378211929,  -37.71824009693999",Mount Toolebewong
"141.54430201553652,  -37.573359803798866",Muntham
"144.14495790469422,  -37.383519609083514",Musk Vale
"146.02685087450132,  -37.92448034909745",Neerim North
"144.87997223282025,  -37.84368166137825",Newport (Vic.)
"144.0539774379016,  -37.10568759685732",Newstead (Vic.)
"145.19080369577418,  -37.951185586577466",Noble Park North
"142.8626256448362,  -37.253505881408664",Norval
"144.055079657437,  -36.952772714030175",Nuggetty
"144.18324882827838,  -38.297758128661435",Paraparap
"144.54795089873903,  -37.250120229604995",Pipers Creek
"145.7733941261916,  -38.338776894395224",Poowong
"144.9666376137289,  -37.781818319171585",Princes Hill
"142.55463673585015,  -38.296753789512785",Purnim West
"144.50289774816827,  -37.84384660644551",Quandong
"141.96119072677632,  -35.902289680522536",Rainbow
"145.1891359517384,  -37.706690842598015",Research
"142.78129461893892,  -37.221822410664224",Rhymney
"145.76536919611127,  -38.19690445928645",Ripplebrook
"145.0021464034817,  -36.58293005937128",Rushworth
"144.75867116113488,  -37.88094937617663",Seabrook
"143.12865788911535,  -38.20486597622417",Skibo
"144.95181854963565,  -37.82525256935241",South Wharf
"144.14723615146767,  -37.488140691065794",Spargo Creek
"144.06227594473125,  -37.52110717188302",Springbank
"144.83104421558815,  -38.419921276534645",St Andrews Beach
"142.4319892566934,  -36.7856289371576",St Helens Plains
"143.68765696717014,  -37.733936216848825",Staffordshire Reef
"144.31547290742006,  -36.76583580872395",Strathdale
"143.8304462828731,  -36.777494465835915",Tarnagulla
"145.34392813478803,  -37.904752541823946",Tecoma
"146.34512615949467,  -37.755977384175125",Thomson (Baw Baw - Vic.)
"147.93756359765501,  -36.27328600020995",Thowgla Valley
"142.92397946183692,  -38.536269653328034",Timboon West
"142.82588639225517,  -34.65137145499957",Tol Tol
"146.14712581222147,  -38.169432273345684",Trafalgar (Vic.)
"146.5893233990156,  -38.18722877319554",Traralgon East
"145.1385242499662,  -37.21817358366249",Tyaak
"145.76690088466,  -36.24489299685816",Waggarandall
"142.90902785551768,  -36.805571997257076",Wallaloo East
"143.2815470083568,  -35.70511740651598",Wangie
"142.7185645922279,  -36.16429262291552",Watchem West
"143.83597600035628,  -38.3703091780606",Whoorel
"144.86894291208176,  -37.852620251112505",Williamstown North
"148.25529579009304,  -37.762254128458515",Wombat Creek (Vic.)
"146.81523939597892,  -37.13812725157965",Wonnangatta
"145.51626945615905,  -38.50982465738924",Woolamai
"145.53566221087752,  -37.78165594267805",Woori Yallock
"143.43136905635217,  -35.23972967769268",Woorinen North
"145.28424151907257,  -35.92809134652903",Yalca
"153.27676871562704,  -28.072284276729704",Advancetown
"153.03245169759984,  -27.612727178835616",Algester
"151.93303925057887,  -28.615446792612666",Applethorpe
"151.6549967387182,  -25.81531160207556",Aranbanga
"144.64018319361264,  -17.66598503814833",Barwidgi
"153.00192448811114,  -27.988131736980886",Beaudesert
"151.27263364302777,  -25.892427176406848",Beeron
"148.09635261909793,  -20.126995766920924",Bowen
"151.27071568747095,  -25.637229137215243",Boynewood
"152.73632573139608,  -27.59014009066204",Brassall
"152.49920030853448,  -27.498559929926728",Brightview
"151.4289340185401,  -27.716210855269573",Brookstead
"152.25790525654605,  -25.576007824669876",Brooweena
"149.55294834182325,  -26.146343003419968",Bundi
"151.87406977691123,  -28.60570145249273",Cannon Creek (Southern Downs - Qld)
"148.5267124295842,  -20.165342337217876",Cape Gloucester
"150.79902940416952,  -27.676309017717635",Cattle Creek (Toowoomba - Qld)
"152.11813949719254,  -28.094419979827524",Clintonvale
"150.97214917800218,  -24.897533713790597",Coominglah Forest
"150.72960982294248,  -23.307344217766747",Coowonga
"152.82592777896213,  -27.20075014697139",Dayboro
"153.02513535279894,  -26.645199207389673",Diddillibah
"152.01223352244926,  -25.04546526715108",Drinan
"149.1330339469793,  -24.591877673471476",Dromedary (Qld)
"150.27486537124918,  -23.847973961930737",Dululu
"153.09243980198838,  -27.579483090196423",Eight Mile Plains
"151.6895498487423,  -27.938384576653682",Ellangowan (Qld)
"143.00247289804364,  -26.33240116217685",Eromanga
"141.8521750277125,  -12.66129628287514",Evans Landing
"150.43414969537324,  -23.789342143953295",Fletcher Creek
"145.71376126323509,  -16.883082003293207",Freshwater (Qld)
"152.69577599895416,  -26.227890304121903",Glanmire (Qld)
"151.97298892266807,  -28.587110303316198",Glen Niven
"152.64250991074064,  -25.617479178957048",Glenorchy (Qld)
"149.81756910151725,  -23.185145079420654",Glenroy (Qld)
"153.1175088783024,  -26.820969636252084",Golden Beach (Qld)
"152.1831965760391,  -25.432100152175668",Golden Fleece
"151.82365683686743,  -26.658513088060793",Goodger
"151.88039737506733,  -27.493017715532513",Gowrie Junction
"152.09680506346652,  -27.323967050733266",Grapetree
"152.45762334434193,  -25.51199634930354",Gungaloon
"145.38420603553593,  -17.391435227323093",Herberton
"152.09418191055394,  -27.838094024950447",Hirstglen
"144.18904243152633,  -20.868892195942085",Hughenden
"151.06415457538267,  -28.38911251291768",Inglewood (Qld)
"150.7230638071603,  -23.115822858313614",Inverness
"151.44452470954326,  -26.596684800008198",Ironpot (South Burnett - Qld)
"145.10438054730471,  -17.44360753661365",Irvinebank
"144.1640565822886,  -24.571709161591933",Isisford
"147.4962796319933,  -19.57797352197752",Jarvisfield
"152.82548244866126,  -27.560908479520194",Karalee
"145.9546876827154,  -18.192105227482287",Kennedy
"147.5584287390132,  -22.31766223816112",Kilcummin
"148.890416322702,  -21.216929438215615",Kinchant Dam
"150.8451038572039,  -28.108353490465536",Kindon
"151.69455059661266,  -27.181216776310983",Kulpi
"146.34087219982476,  -22.373173368086174",Laglan
"144.1652886029524,  -15.495659851516224",Laura (Qld)
"148.73903592328944,  -20.269724699289284",Mandalay
"153.2788774441489,  -27.93394549293361",Maudsland
"145.62210885160658,  -17.49902975908374",Millaa Millaa
"145.3756469011775,  -17.626724534262934",Millstream (Qld)
"145.03747325966415,  -18.24909508024012",Minnamoolka
"148.86353343381043,  -21.16377406544424",Mirani
"152.97033300590678,  -27.411951357409833",Mitchelton
"152.68576353535101,  -26.2120726546019",Monkland
"153.0250714670129,  -26.691699958504664",Mons
"150.61487687118296,  -27.013388537129792",Montrose (Western Downs - Qld)
"151.54755873306337,  -27.036935639890327",Moola
"152.2389194462558,  -24.709552976408578",Moore Park Beach
"152.78255852412906,  -27.599741813843593",Moores Pocket
"147.8898005218521,  -21.947361847293987",Moranbah
"145.5859366309317,  -17.491816583143525",Moregatta
"150.58169703203419,  -23.326131800562344",Mount Archer (Rockhampton - Qld)
"139.45484930017582,  -20.536004716148927",Mount Isa
"152.76363503946257,  -27.14890066485975",Mount Pleasant (Moreton Bay - Qld)
"151.56575488514721,  -27.57436205888088",Mount Tyson
"144.88992841962252,  -17.6676329141325",Munderra
"153.06717455343616,  -27.069131578770374",Ningi
"151.75551729261542,  -27.050770945620854",Nutgrove
"149.17236141879505,  -21.186724611450916",Paget
"152.75380708495237,  -27.78557084575089",Peak Crossing
"145.60059099531406,  -17.31204667037058",Peeramon
"151.5429984113708,  -28.724783237681034",Pikes Creek
"152.00300294389424,  -27.23291195539715",Pinelands (Qld)
"139.50806476028433,  -20.72034484610319",Pioneer (Qld)
"151.67032855343615,  -26.998454693999637",Rangemore (Toowoomba - Qld)
"144.24725942343434,  -17.017691049787018",Rookwood (Qld)
"145.32700513347024,  -16.50604379277952",Shannonvale
"145.98502040093533,  -17.8147175949062",Shell Pocket
"151.53052616069357,  -26.065492274805628",Speedwell
"152.90576876315794,  -27.68387049289828",Springfield Central
"151.25810518348453,  -23.87520841986058",Sun Valley (Qld)
"152.32607096453876,  -28.141107430426658",Swanfels
"151.8243506267702,  -26.59303120841277",Taabinga
"152.55237207761525,  -27.58945045833762",Tallegalla
"152.2048819986463,  -25.66398729691542",Teebar
"145.43139143945865,  -16.15715079939329",Thornton Beach
"151.2576246372993,  -27.463129664366196",Tipton
"153.0688119553254,  -27.040670860728113",Toorbul
"152.89112076589808,  -25.3045920557767",Urangan
"153.0148549430509,  -26.488312629628183",Verrierdale
"149.06515735746262,  -21.158739459431",Walkerston
"145.9784256652214,  -17.724491146565796",Walter Lever Estate
"144.0374467652306,  -9.948642684407732",Waua Islet
"152.26563112193813,  -24.77155886798726",Welcome Creek
"152.10212671843502,  -27.77917523887247",West Haldon
"149.75705308845633,  -27.88851802115963",Westmar
"152.75317156530025,  -27.605747353205395",Woodend (Qld)
"152.96961574714967,  -27.886831569919178",Woodhill (Qld)
"145.776082506869,  -17.02183129714445",Wrights Creek (Qld)
"151.28446423367023,  -23.967549627256798",Wurdong Heights
"139.0614373105911,  -34.52952213716274",Angaston
"138.1826647419099,  -31.857933306544485",Barndioota
"138.76679628599538,  -33.5642926000295",Booborowie
"139.99493987545497,  -37.24375030391872",Bray
"139.68465315219774,  -34.074059085983144",Brenda Park
"140.7600781495065,  -34.03170076808046",Calperum Station
"140.4181811241943,  -37.88260677261315",Carpenter Rocks
"133.331602680304,  -32.097473664278894",Charra
"138.682496767673,  -34.99560269005656",Crafers West
"138.2035252466495,  -33.3670658503745",Crystal Brook (SA)
"138.42608400773872,  -34.18731911344084",Dalkey
"136.80637630035164,  -35.727943611364886",De Mole River
"138.5797886944093,  -34.887668875374736",Devon Park (SA)
"139.56986831043767,  -34.05308417851984",Eba
"139.93866777142668,  -34.665927705360986",Galga
"135.4195440836241,  -32.58794399881507",Gawler Ranges
"138.64761631161173,  -34.61029139500791",Gawler River
"140.34791256104953,  -27.077136677970092",Gidgealpa
"138.6125795026763,  -34.900426221804764",Gilberton (SA)
"138.92578494876156,  -34.4450123157582",Greenock
"139.05411202442906,  -33.87827613192684",Hallelujah Hills
"139.04340680009892,  -35.20993052408523",Hartley (SA)
"138.68731355517323,  -34.73523581879495",Hillbank
"139.9373024431103,  -35.14453389383385",Karoonda
"138.61376731574475,  -34.97086538061211",Kingswood (SA)
"138.97044962894688,  -34.56582667645209",Krondorf
"139.5340872759592,  -34.87300883198423",Lake Carlet
"136.693215086823,  -31.91893375829113",Lake Macfarlane
"139.07727511694495,  -35.29810347574957",Langhorne Creek
"138.76694792288558,  -33.64770452432584",Leighton
"140.68074231123308,  -36.163724800783946",Lowan Vale
"137.4737242207598,  -35.912566146915374",Macgillivray
"139.63049855157544,  -34.284458690603635",McBean Pound
"140.57110411495697,  -34.191608245987624",Monash (SA)
"138.75279837189169,  -34.886875475062794",Montacute
"138.6337941178034,  -34.96054985217977",Myrtle Bank (SA)
"137.69449786233295,  -33.953825895687615",New Town (SA)
"139.77819949619337,  -34.42139498181672",Notts Well
"130.74087286744285,  -29.401399701794237",Oak Valley (SA)
"138.85784376536023,  -34.98019033468851",Oakbank
"138.5021984485015,  -34.77622939739296",Outer Harbor
"138.81662264207108,  -35.05384805107802",Paechtown
"138.5486724169708,  -35.3341761646227",Pages Flat
"137.62399546259584,  -34.048998460886345",Paramatta
"138.2597709866011,  -34.429746978975324",Parham
"138.02371462962705,  -35.85920304023625",Porky Flat
"138.51216315537707,  -34.83606555251842",Port Adelaide
"136.79404129663865,  -33.80447663939879",Port Gibbon
"137.86853003462846,  -34.647510638346176",Port Julia
"138.67563520533662,  -34.921919109039784",Rosslyn Park
"138.8360269664647,  -34.6026853341861",Sandy Creek (SA)
"137.32165591710776,  -35.961116068535965",Seal Bay
"138.11555568075133,  -34.03233797452725",South Hummocks
"138.23010385555762,  -32.36798746929843",Stephenston
"139.77947889647567,  -33.956365305001334",Stuart (SA)
"140.18815396716388,  -37.42305901845585",Thornlea
"138.8433292793463,  -35.04751554622731",Totness
"138.6621298473792,  -34.90547429265075",Tranmere (SA)
"138.9584491359126,  -32.99460434798034",Ucolta
"139.51021467322144,  -34.80550660288613",Walker Flat
"139.30966202684607,  -34.98446308213644",Wall Flat
"138.53565163775022,  -35.007357554671856",Warradale
"139.2078048682188,  -35.13972154380226",White Hill
"137.5592903903524,  -32.98777151594774",Whyalla Barson
"137.55914731126043,  -33.02891712495157",Whyalla Playford
"140.8308514762615,  -37.80689514376808",Worrolong
"133.62981264951986,  -31.697226619685694",Yumbarra
"115.9178417915828,  -31.917398228786205",Bayswater (WA)
"115.9312712059449,  -31.94980259933518",Belmont (WA)
"115.78592613255067,  -32.026552917198494",Bicton
"115.3609652951714,  -33.680079918987595",Bovell
"116.0727663939108,  -31.77261255071239",Brigadoon
"117.00557995241459,  -32.35063486858601",Brookton
"118.16864661772664,  -31.841557521999874",Bruce Rock
"117.14590273403397,  -30.557765345298424",Burakin
"113.93002770105268,  -22.13803285014625",Cape Range National Park
"115.65304805853232,  -33.35479434560495",Carey Park
"121.05375411592581,  -33.41199108418265",Cascade (WA)
"115.94441633971861,  -31.96276779753455",Cloverdale
"117.92368592409106,  -35.003624099744115",Collingwood Park (WA)
"118.57493949290978,  -34.07246575049772",Cowalellup
"117.8205190054837,  -31.77338008861785",Daadenning Creek
"115.97359486976319,  -31.854032094134993",Dayton
"114.6811176032651,  -28.780702881221607",Deepdale
"127.57576418838221,  -16.34530365283167",Durack (WA)
"114.80312837994762,  -28.67883776641667",East Chapman
"115.94711121424926,  -31.891042738702502",Eden Hill
"117.41640914582091,  -34.613350222751755",Forest Hill (WA)
"118.5406094504919,  -34.37899902632413",Gnowellen
"116.31122572186115,  -31.910480598203463",Gorrie
"115.04862732423119,  -34.230978956225705",Hamelin Bay
"116.79474814486713,  -32.54691754328412",Hastings (WA)
"116.10128840633293,  -32.700183567404935",Holyoake
"122.85862584224411,  -33.69139621974723",Howick (WA)
"115.61735632986776,  -34.008640194228086",Jalbarragup
"115.66295331685524,  -33.79416469364746",Jarrahwood
"115.83718328505789,  -31.910194445849836",Joondanna
"116.55373575924114,  -31.627005946113435",Katrine
"117.17888492126687,  -33.838200161029604",Kojonup
"115.78275894958729,  -32.12922605563126",Lake Coogee
"115.91941785625303,  -31.77900167354879",Lexia
"117.37892625203104,  -33.40188218323027",Lime Lake
"116.01084694143738,  -33.9530641195536",Maranup
"117.0529477046296,  -32.9243415839825",Minigin
"117.50256012235097,  -30.398060481710623",Mollerin
"119.85394200789165,  -33.2329787183088",Mount Madden
"116.02478289927151,  -32.13768402035106",Mount Nasura
"117.76363199595106,  -29.783593774464013",Mouroubra
"115.93983535138278,  -33.75244133904658",Mullalyup
"118.39488288824799,  -32.061649232716945",Narembeen
"115.69055653227325,  -33.320296999331774",Pelican Point (WA)
"117.66996830626309,  -30.04639430836967",Remlap
"115.51266429424655,  -32.00634297941139",Rottnest Island
"115.7585898180849,  -32.40584518062426",Secret Harbour
"121.87481690048553,  -33.86316062707614",Sinclair
"114.62297966127646,  -28.719059049927708",Sunset Beach
"117.75672983281629,  -32.95897978171549",Tincurrin
"118.6988077691776,  -34.5087526672156",Wellstead
"115.32369537142475,  -33.660679085183354",West Busselton
"116.96397064230977,  -32.540196348731776",West Pingelly
"116.37399648071916,  -31.755481900743494",Wundowie
"147.1809074841565,  -41.01998065269996",Bellingham
"147.22389831161698,  -43.18368800396885",Birchs Bay
"144.95651439665784,  -40.939320871597",Brittons Swamp
"148.07939201851826,  -41.96261911961515",Cranbrook (Tas.)
"146.5926802397944,  -42.25181789633166",Dee
"147.13399623500553,  -43.20819681569836",Deep Bay
"145.85708978925828,  -41.15731268181239",East Ridgley
"147.30600910255978,  -41.570459593796855",Evandale (Tas.)
"146.87455539257738,  -42.68887946759153",Glenora
"147.26524136012787,  -42.843490218213844",Glenorchy (Tas.)
"146.53406330464043,  -41.13722795634815",Hawley Beach
"147.32733513326306,  -42.88225247947434",Hobart
"147.1439526960957,  -43.28998758073485",Huon Island
"145.14998731292062,  -40.91754438045289",Irishtown (Tas.)
"146.66970142328722,  -41.69707908176833",Jackeys Marsh
"146.21527695179068,  -41.25623455360111",Kindred
"145.95128452462777,  -41.9699380315491",Lake St Clair
"147.16285958089293,  -41.442916218537526",Newstead (Tas.)
"147.3027969334363,  -42.80072398615411",Otago
"146.0548755382229,  -41.137954980685016",Penguin
"147.07738923967426,  -41.12116718219284",Pipers River
"146.86521777336515,  -43.533776755968255",Recherche
"147.5205172964625,  -41.17066427594223",Scottsdale
"146.76702907218603,  -42.07242658115512",Shannon (Tas.)
"146.34697667808337,  -41.22550779872234",Spreyton
"147.0165033883556,  -41.29348307583671",Swan Bay (Tas.)
"146.43714191082302,  -42.295743383747556",Tarraleah
"144.7409029384981,  -41.22795131293924",Temma
"147.12133942293275,  -41.296768834187645",Turners Marsh
"146.47102003575395,  -41.48153137402819",Weegena
"146.25368987701677,  -41.396477631348404",West Kentish
"135.24692888191188,  -21.664288434139447",Ampilatwatja
"136.9537269742375,  -22.99400420722204",Anatye
"133.8634137339009,  -23.676203419735444",Braitling
"130.97138306039182,  -12.472877314901176",Durack (NT)
"132.83703579013613,  -12.669367246046772",Jabiru
"131.21338161924552,  -12.377532475574199",Koolpinyah
"133.2354886577703,  -15.606301547015457",Larrimah
"131.14578232161543,  -12.666342553654124",Lloyd Creek
"130.76165785661635,  -12.44280981026413",Mandorah
"130.9484387222609,  -12.37915831925962",Micket Creek
"130.68611817533386,  -25.032814261088664",Petermann
"132.61202433221519,  -15.659383752423604",Sturt Plateau
"130.50688014104284,  -20.024378414502888",Tanami (NT)
"132.3792625246295,  -14.53123031181469",Tindal
"130.9235845314431,  -12.756288224684532",Tumbling Waters
"131.00932436813767,  -12.626936955104288",Weddell
"130.98018962521365,  -12.501684718815948",Woodroffe
"130.6244873825191,  -11.757832448166935",Wurrumiyanga
"135.2505081236125,  -20.462290032219375",Wutunugurra
"149.02479229846932,  -35.29654267205624",Denman Prospect
"149.13710476917743,  -35.325252725407864",Griffith (ACT)
"149.09085882453408,  -35.39783106422757",Wanniassa
"147.47689860331494,  -32.38476877410715",Albert
"150.7772637345987,  -34.582213269560285",Albion Park
"152.76218677914693,  -31.07410859005406",Aldavilla
"151.02415208137506,  -33.98665547848459",Alfords Point
"151.39860977553673,  -33.267927642042594",Alison (Central Coast - NSW)
"151.1477170682007,  -33.93941504130771",Arncliffe
"151.42762316932163,  -33.47019667503847",Avoca Beach
"150.48329812091862,  -34.44018887484598",Balaclava (NSW)
"144.2150469971133,  -35.60030463448691",Barham
"149.6385265159265,  -34.719062110394276",Baw Baw (NSW)
"151.28957860451592,  -33.661861068342915",Bayview (NSW)
"151.0858539092551,  -33.90443211677077",Belfield
"152.48461834014756,  -30.75946583126811",Bellbrook
"151.03376044897686,  -33.87454607012407",Berala
"150.80150411380936,  -34.06206485761097",Blair Athol (NSW)
"150.79258335558177,  -34.05155315788126",Blairmount
"151.586711322487,  -32.69235627264819",Bolwarra Heights
"148.31736462120787,  -35.27364922997327",Bombowlee Creek
"152.62498050467082,  -28.71915813229754",Bonalbo
"150.55154592923918,  -28.76068466379921",Boonal
"153.3759602401958,  -28.341310939190365",Bray Park (NSW)
"150.3963834585923,  -34.86717542065271",Buangla
"151.55853099930462,  -33.237858402331774",Budgewoi
"150.1309425693442,  -35.00945120569986",Bulee
"150.57906146073296,  -32.06095150146136",Bunnan
"152.59005247864945,  -31.945958464750763",Cabbage Tree Island (Mid-Coast - NSW)
"152.83095329389872,  -31.64365359184196",Camden Head
"150.80714548669957,  -34.068411753920905",Campbelltown (NSW)
"151.12139164797517,  -33.97102137869044",Carlton (NSW)
"152.80729835126178,  -30.95863713230769",Collombatti
"150.4403085876531,  -35.225983235436516",Conjola
"148.21189725959343,  -34.91038011178957",Coolac
"148.8560977645249,  -36.28763721935506",Cootralantra
"146.95381529970496,  -34.58476740998182",Cowabbie
"148.64543283418104,  -33.82944278643229",Cowra
"149.50711432699597,  -32.49293236153084",Cullenbone
"149.20607104208605,  -33.953010917315446",Curragh
"150.0919802853242,  -35.57080116721445",Currowan
"151.18145184697832,  -33.052192045169235",Dairy Arm
"149.8952766827225,  -33.30049797829086",Dark Corner
"151.36769170181566,  -33.17557810370648",Dooralong
"152.38527567362704,  -28.929887381077386",Drake Village
"150.82247123977606,  -34.61991067989366",Dunmore (NSW)
"149.55124842770988,  -33.254604775466106",Duramana
"151.23581940393407,  -32.49690736205342",Dyrring
"152.58860110016929,  -29.27820368411077",Eighteen Mile
"151.3938143776293,  -33.43726706931569",Erina
"147.37581234315465,  -33.29889281276897",Fairholme
"147.45770412670092,  -32.84102481091981",Fifield
"150.84883257173647,  -34.430820498365726",Figtree
"152.6165813723189,  -31.39938901684862",Frazers Creek
"151.22552646413004,  -33.74790896686787",Frenchs Forest
"147.00040367584373,  -35.84244672204009",Gerogery
"153.10880275852824,  -29.635499393275612",Gilletts Ridge
"150.48406093320847,  -34.528973749143795",Glenquarry
"147.88273148227242,  -35.748597811564586",Glenroy (Snowy Valleys - NSW)
"149.2314916662133,  -32.333229358990764",Goolma
"151.00895850697285,  -33.83521713616929",Granville (NSW)
"149.41947235427915,  -32.40960313926235",Guntawang
"153.58230524400813,  -28.675561664629726",Hayters Hill
"150.2972490547616,  -34.32498236826758",High Range
"152.65363713638317,  -31.470805887126804",Huntingdon
"151.11004494243684,  -29.76261219643307",Inverell
"153.10475885140613,  -28.8635459890835",Irvington
"150.76443436852074,  -34.656050442145585",Jamberoo
"146.8777046694843,  -35.9541168512286",Jindera
"150.38781091948607,  -30.827934606494363",Kelvin
"150.86540500821494,  -34.06024553307299",Kentlyn
"152.52985592791578,  -31.853092331835683",Kundle Kundle
"151.4798523972478,  -32.81530467476168",Kurri Kurri
"147.54253646288143,  -35.21269091736299",Ladysmith
"145.99487828443202,  -33.45214176866366",Lake Brewster
"153.28756690394968,  -28.809129810550225",Lismore (NSW)
"150.9317403400992,  -29.7357510987502",Little Plain
"151.45218737427146,  -32.71190469677891",Lochinvar
"150.96760177780453,  -33.35894345964287",Lower Macdonald
"148.1833933936331,  -34.23371472258159",Maimuru
"151.16847540169834,  -33.468901750848545",Marlow
"148.26205239234866,  -31.50269177299592",Merrigal
"151.2476559754198,  -32.88627619913078",Millfield
"151.567496943037,  -32.64864832161062",Mindaribba
"148.66616538311402,  -32.07104282544907",Mogriguy
"146.91409266788915,  -35.457829521034505",Munyabla
"151.00907467431244,  -32.27668847462514",Muscle Creek
"149.85998105101572,  -37.366256234142014",Nadgee
"151.77741916880498,  -32.92873871981818",Newcastle
"151.43507158492972,  -33.45611019182769",North Avoca
"151.08826079380592,  -33.85864929566229",North Strathfield
"151.23548558294567,  -29.6241171100198",Nullamanna
"152.68031084003496,  -29.951996336956938",Nymboida
"151.33388924516635,  -33.31289828719047",Ourimbah
"148.30462720127758,  -33.564027524054005",Paytens Bridge
"151.31388396399015,  -32.87939021739528",Pelton
"151.0514053314426,  -33.928595085794065",Punchbowl (Canterbury-Bankstown - NSW)
"149.7699577851848,  -34.93667027521186",Quialigo
"151.0571743125746,  -32.429756903160204",Ravensworth (NSW)
"153.34698645672168,  -28.789279008281415",Richmond Hill (NSW)
"149.04924180723953,  -33.85616406390871",Roseberg
"150.80416709422244,  -34.69867813883232",Saddleback Mountain
"149.56997262002142,  -33.0283942525627",Sallys Flat
"151.27717949701503,  -33.37902633449653",Somersby
"151.5015025958001,  -29.83263059070655",Spring Mountain (NSW)
"147.324099791362,  -35.17187403987735",Springvale (NSW)
"151.16612728080668,  -33.89309747322859",Stanmore (NSW)
"150.57262123922933,  -35.186907163421004",Swanhaven
"152.5848274014464,  -31.592962010456322",Swans Crossing
"151.06895152895234,  -33.83970989625989",Sydney Olympic Park
"144.62788407603887,  -35.7069331866463",Tantonan
"153.47129852373757,  -28.506061381360436",The Pocket
"148.65065235330306,  -33.35170503014492",Toogong
"153.2207442948381,  -29.46620619760661",Townsend
"150.1212606456251,  -36.03531423560623",Tuross Head
"149.73861873647112,  -33.678928577529796",Twenty Forests
"151.31116468507037,  -33.518952158917955",Umina Beach
"153.39455692165362,  -28.4907752113637",Upper Main Arm
"151.12125810161,  -33.29087921221544",Upper Mangrove
"146.23191620888954,  -35.32212856001896",Urana
"150.98309277683813,  -33.8838982859755",Villawood
"152.4353934718421,  -32.30941579022589",Wallingat
"151.71328696212603,  -32.900526879513194",Waratah West
"150.85870528945225,  -34.55195838672985",Warilla
"151.92056083182962,  -32.36946980473555",Washpool (Mid-Coast - NSW)
"149.3871629246549,  -30.309822376860875",Wee Waa
"149.67040399193195,  -33.21595694155842",Wiagdon
"151.68156766084002,  -32.99284021484918",Windale
"150.1641639344482,  -33.25136171347598",Wolgan Valley
"150.89320358004937,  -34.42752495036769",Wollongong
"149.63906981938115,  -34.57050726197465",Woodhouselee
"151.0268782140426,  -34.03445643503173",Woronora Heights
"148.94508954751805,  -33.97972867677155",Wyangala
"141.96506001354098,  -35.73961828071281",Albacutya
"146.57699780989574,  -38.60370144780918",Alberton West
"142.88210785516728,  -37.212481292201026",Armstrong
"144.44633206746937,  -37.676345686881795",Bacchus Marsh
"142.6175291769044,  -38.18612988931285",Ballangeich
"143.08270374551753,  -37.425248943515356",Ballyrogan
"146.74800373525986,  -36.08711765843773",Barnawartha North
"143.3450291632412,  -37.44140309687426",Beaufort (Vic.)
"142.42055210287012,  -35.90713331214819",Beulah (Vic.)
"142.99347624874568,  -36.721659541395816",Bolangum
"146.99093199534238,  -36.137021402828864",Bonegilla
"146.46054829801884,  -38.046345486101124",Boola
"146.05333718713504,  -38.449561749885035",Boorool
"146.2599079531467,  -36.05323046869485",Brimin
"143.34512538250402,  -35.30478271040795",Bulga (Vic.)
"143.89726569431997,  -37.651886693180074",Buninyong
"146.46786340279974,  -36.28870117226648",Byawatha
"144.0841637448267,  -36.25308000661472",Calivil
"143.24614530376863,  -36.70730269653013",Carapooee West
"144.36846905122957,  -38.209277149674975",Charlemont
"146.10417154022662,  -38.301499698700056",Childers (Vic.)
"144.03327245854769,  -37.519359170459026",Claretown
"144.9642705694045,  -37.74486509669928",Coburg
"146.67208623016924,  -38.013701312539986",Cowwarr
"142.6548037249624,  -38.340112655382384",Cudgee
"141.64876007504208,  -34.20091671379594",Cullulleraine
"144.30321174166568,  -37.52161814234566",Dales Creek
"146.92944840647755,  -38.41539555286368",Darriman
"141.19076967420912,  -37.35124198015017",Dergholm
"145.89862205143945,  -36.33357768084152",Devenish
"142.98328119706218,  -38.34623472682447",Elingamite North
"148.75060632603305,  -37.464863422574616",Errinundra
"143.88256929281695,  -37.564749821809116",Eureka (Vic.)
"145.931735236299,  -38.40435221178884",Fairbank
"144.36176989571135,  -38.14994731750037",Geelong
"143.20755367776724,  -36.46741872032793",Gooroc
"144.87227897275864,  -37.193604693193976",High Camp
"145.0404212315855,  -37.950423317178824",Highett
"143.49327802766135,  -38.79359120479453",Hordern Vale
"143.1383516499243,  -36.31901263574429",Jeffcott
"144.89121621062029,  -36.13140082736379",Kanyapella
"145.25354141076258,  -37.172451315411465",Kerrisdale
"145.41517378355033,  -36.44939530314141",Kialla
"142.27804248149897,  -38.26800417161881",Kirkstall
"144.60085975785006,  -36.829179822176314",Knowsley
"143.793668255815,  -35.89475242165397",Lake Meran
"146.86837088264977,  -36.20857460346137",Leneva
"146.45512778589418,  -36.13029547667811",Lilliput
"142.57271972621658,  -34.81803032323929",Liparoo
"146.00873995578294,  -37.811674568217754",Loch Valley
"144.43277621853443,  -36.76477363863457",Longlea
"145.16502639469957,  -36.925534340510694",Mangalore (Vic.)
"144.10362335585845,  -36.75140478498752",Marong
"142.01807508764247,  -34.219804170893234",Merbein South
"145.08192169249168,  -37.59208929705351",Mernda
"146.31285685886579,  -37.07806526340379",Merrijig (Mansfield - Vic.)
"141.64084978337135,  -36.859156722386764",Miga Lake
"147.17047258591114,  -37.69922204830574",Moornapa
"145.09606763449585,  -37.999349916202966",Mordialloc
"145.09289320114848,  -38.19498682301889",Mount Eliza
"143.39048329769162,  -36.873244274681895",Moyreisk
"144.58329346636762,  -36.68917228024269",Muskerry
"144.35573930127444,  -37.5979393830581",Myrniong
"145.21530886254462,  -36.783296609352675",Nagambie
"141.10986010981952,  -38.06580705608316",Nelson (Vic.)
"144.6152136365202,  -37.45240011124489",New Gisborne
"143.9188580379652,  -36.744871877269944",Newbridge (Vic.)
"148.58130910237446,  -37.495963502318155",Nurran
"145.41258854843673,  -38.05412270952001",Officer
"142.29543267781455,  -35.048822487791334",Ouyen
"145.50769513501666,  -38.00777900148358",Pakenham Upper
"141.6042061268364,  -37.66035819246415",Paschendale
"144.6660365743298,  -37.31186068278327",Rochford
"144.65446389259944,  -37.737310129489494",Rockbank
"144.10550439935187,  -37.274933036255106",Shepherds Flat
"143.58604076927298,  -37.61469434457287",Snake Valley
"144.34786272674117,  -37.302758883303014",Spring Hill (Vic.)
"143.62909263279843,  -37.74664427495632",Springdallah
"144.1887235541316,  -37.862573256522616",Steiglitz (Vic.)
"145.72436626131747,  -37.34557426306491",Taggerty
"147.4597267621249,  -36.33462262896538",Tallangatta Valley
"146.13409725822763,  -38.33432846116307",Thorpdale South
"145.01755786727352,  -37.8415388182973",Toorak
"144.85011373697716,  -38.377772724441854",Tootgarook
"144.3221727471293,  -38.30815861043895",Torquay (Vic.)
"143.54591795596627,  -35.240334467707356",Tyntynder
"142.93339743528128,  -35.31348542683745",Tyrrell Downs
"143.10704586168305,  -35.378784658822454",Waitchie
"145.45487667690125,  -37.81231489254166",Wandin East
"145.08369012211648,  -37.710455827211234",Watsonia
"146.43241324486712,  -38.66267950977373",Welshpool (Vic.)
"146.59670741215325,  -36.59368079700077",Whorouly South
"142.70975943455457,  -37.519901685778265",Willaura
"143.7938146819559,  -37.579505881805225",Winter Valley
"147.3850022460802,  -37.773414133920646",Woodglen
"146.9492317546054,  -37.59553273190037",Wrathung
"148.27444709167435,  -37.098001976567375",Wulgulmerang East
"146.0026861625916,  -36.037635113044566",Yarrawonga (Vic.)
"142.14061028293332,  -37.84108846106081",Yatchaw
"152.90873860897304,  -26.784783142093108",Bald Knob
"151.8233942530076,  -25.67274778094322",Ban Ban Springs
"153.19325562383477,  -28.118940371658546",Beechmont
"146.58851129946757,  -21.80081257642761",Belyando
"151.46183428321277,  -25.537147426353393",Binjour
"153.21028345802446,  -27.49958608194865",Birkdale
"146.5343353922397,  -19.15988606818011",Bluewater
"150.6840612336374,  -23.18217783390127",Bondoola
"152.79020333399166,  -26.817549526062695",Booroobin
"152.83426499690592,  -27.003289541231407",Bracalba
"152.0958942651792,  -26.611406144212154",Bullcamp
"148.6231733408827,  -26.094268323831194",Bymount
"151.00845781665038,  -26.11166059700759",Cadarga
"152.74512942342002,  -26.140648676429578",Canina
"147.02487636463437,  -19.332427685291567",Cape Cleveland
"146.8000093552402,  -19.255888799099207",Castle Hill (Qld)
"151.3014145517664,  -25.456849436626708",Cattle Creek (North Burnett - Qld)
"150.79631396462233,  -25.624315965601223",Cheltenham (Qld)
"146.82634218137383,  -19.315948267746943",Cluden
"147.34086797213297,  -19.455109809866116",Colevale
"152.23747811901939,  -26.963646878281423",Colinton (Qld)
"153.0695780443401,  -26.530515071766853",Coolum Beach
"151.07941151035521,  -25.54716226987439",Coonambula
"153.211266674822,  -27.66051300684228",Cornubia
"150.30106719638732,  -25.319817998905712",Cracow
"152.42862791386372,  -27.07503784387905",Cressbrook
"151.2853828530363,  -26.644210600461",Diamondy
"148.5020375102046,  -20.09129508937838",Dingo Beach
"151.8105513325222,  -27.17340418779064",Doctor Creek
"138.7437869614531,  -17.710157784157406",Doomadgee
"146.15909934228026,  -17.961373080754736",Dunk
"143.8523462998927,  -20.47320630827364",Dutton River
"151.01420922246842,  -23.903564827363752",East End
"152.9614367321251,  -27.00035436987308",Elimbah
"152.42037263173805,  -27.2592431557323",Esk (Qld)
"145.0168169317342,  -28.212718544988206",Eulo
"153.04819358730296,  -27.371582552449233",Geebung
"150.4688805610231,  -23.286268638185682",Glenlee (Qld)
"152.6310135927544,  -25.928960128591125",Glenwood (Qld)
"150.28446588215948,  -24.107637510953452",Goovigen
"151.4139581006199,  -28.284365309176028",Gore
"145.05708166068254,  -17.895422101066654",Gunnawarra
"152.35093088382345,  -26.962909767908148",Harlin
"148.90156113083788,  -21.364149000188206",Hazledean
"152.03084710397897,  -27.912807922600148",Headington Hill
"146.81394930444316,  -19.306846653990853",Idalia
"149.36366412173948,  -21.709501714698398",Ilbilbie
"152.90224236759735,  -28.177305632986666",Innisplain
"146.08423320497923,  -23.266297903824185",Jericho (Qld)
"146.72052296765133,  -28.805644539914113",Jobs Gate
"150.77459170905675,  -23.374079745927084",Joskeleigh
"145.54352135957978,  -17.21428824042006",Kairi
"152.16094495037464,  -26.217680068784905",Kinbombi
"152.80691527404593,  -28.12752592034658",Knapp Creek
"150.49242929044524,  -28.581970057940325",Kurumbul
"151.89669093685322,  -28.24985427320443",Leslie Dam
"153.1072826591319,  -27.71724276327943",Logan Reserve
"153.15578752589863,  -27.4189873234989",Lytton
"142.6437000799846,  -20.862229968093402",Maxwelton
"149.19168210409433,  -21.23646121754472",McEwens Beach
"151.92096189718293,  -27.401724366189516",Meringandan
"147.25898948390838,  -20.047783193263488",Millaroo
"152.6883493625461,  -27.849299543464543",Milora
"146.09814647750633,  -17.863529267448865",Mission Beach
"152.87717988322737,  -27.579850274158233",Moggill
"147.23585924761318,  -19.69451709826634",Mona Park
"152.48349718876466,  -27.3068081069843",Moombra
"145.72972455055745,  -16.935591684551582",Mooroobool
"146.03110593580277,  -17.63867332171594",Moresby (Qld)
"153.07581553090125,  -27.46232897923225",Morningside
"150.63340772375864,  -23.311112020248046",Mount Chalmers
"139.4928010143635,  -20.725803006256395",Mount Isa City
"144.8818568366484,  -16.780906929315456",Mount Mulligan
"153.1014132699457,  -26.702567794482427",Mountain Creek (Qld)
"149.93667216851355,  -24.476735679675887",Moura
"145.6874279024702,  -17.545494255957784",Mungalli
"153.09291186897337,  -26.398184677224496",Noosa Heads
"151.761228564201,  -28.72457682076957",Nundubbermere
"151.86094791514682,  -24.422846062905997",Oyster Creek
"149.17035404277928,  -27.394748963272942",Parknook
"151.73215107293453,  -25.74402429128838",Penwhaupell
"153.10733852583712,  -27.226621241818844",Redcliffe (Qld)
"153.0019257130269,  -27.55070271268136",Rocklea (Qld)
"152.02317303321223,  -27.609946592307153",Silver Ridge
"151.32655177389174,  -28.828085510424753",Silver Spur
"149.50899724242987,  -22.387459514366835",St Lawrence
"148.63104617549263,  -20.311327432956805",Sugarloaf (Whitsunday - Qld)
"141.49515226359856,  -26.159683366471917",Tanbar
"146.3188601032093,  -18.640911203258113",Taylors Beach (Qld)
"153.20081110155095,  -27.484425490507366",Thorneside
"152.66549580653975,  -25.589395745615082",Tinana South
"152.8612113973642,  -26.00073198812097",Toolara Forest
"145.9203353281666,  -17.633268959388612",Utchee Creek
"145.48360698916193,  -18.382678863295617",Wairuna
"152.00771442145785,  -24.641137166443674",Watalgan
"151.8442764520939,  -25.391154239434318",Wateranga
"145.75778883213712,  -16.978464214902825",White Rock (Cairns - Qld)
"147.80803040767532,  -26.553562553192307",Womalilla
"152.0404918912991,  -28.180567559007013",Womina
"153.15696026875148,  -27.968999280560194",Wonglepong
"152.504283871552,  -27.603463536027387",Woolshed
"150.9745436718955,  -25.131786123072708",Wuruma Dam
"138.45867294922013,  -35.29326085152087",Aldinga Beach
"139.37209664107135,  -34.39695418913832",Annadale
"138.3993596823182,  -35.488261976552835",Bald Hills (SA)
"136.01871086941338,  -32.76456693442339",Buckleboo
"138.25538772527312,  -33.67869975853371",Burnsfield
"138.60501641777412,  -34.990137533292426",Clapham
"138.7929679358309,  -34.579259411033235",Concordia
"140.95401387825552,  -38.005432094475836",Donovans
"138.65652035539773,  -34.74105452158473",Elizabeth South
"139.54305125400052,  -35.02311829446177",Ettrick (SA)
"138.6355263627907,  -34.905436233217195",Evandale (SA)
"138.71993770348573,  -34.62810148381602",Evanston Gardens
"138.65128734286358,  -34.69411711272608",Eyre
"138.71679329528348,  -34.221716152147806",Giles Corner
"138.63912619601925,  -34.950584826705445",Glenunga
"136.92719704052718,  -35.817144967003514",Gosse
"138.53825845695764,  -34.09952922603997",Halbury
"138.84819789733808,  -33.756184408850146",Hanson
"138.32109182210127,  -35.508030904475284",Hay Flat
"138.52315132247952,  -35.01114897227988",Hove
"137.71762874741506,  -33.95725190987414",Kadina
"138.79296219774363,  -34.62497810493686",Kalbeeba
"137.90494958317717,  -35.73606815252547",Kangaroo Head
"138.57772124142676,  -34.94433924487599",Keswick
"133.4505122910934,  -31.87960700338007",Koonibba
"138.62737857414584,  -34.86190805313367",Lightsview
"140.69555422716977,  -37.26799217030982",Maaoupe
"138.6125732087583,  -34.95914772761174",Malvern (SA)
"138.54301635194923,  -34.984554633621975",Morphettville
"140.1454122087991,  -36.07213663641897",Mount Charles
"139.33078359028727,  -35.055410948652316",Murrawong
"140.74309007152004,  -36.946519584240534",Naracoorte
"138.31544802033105,  -33.42254305622191",Narridy
"138.02592609638435,  -33.964044250297256",Ninnes
"138.59531995863307,  -34.90840860833688",North Adelaide
"134.37949677738246,  -32.20621370263039",Nunjikompita
"139.10402292968652,  -32.99427858346007",Parnaroo
"140.90244322059544,  -34.92807963994945",Peebinga
"139.06067953285933,  -34.08084741819916",Point Pass
"138.4640847252951,  -35.259676074161014",Port Willunga
"139.29695990177171,  -34.811705194815445",Punthari
"139.83927218960343,  -37.20272331000014",Robe
"138.65362208411975,  -34.91105216933579",St Morris
"137.86359068769332,  -32.508591378576945",Stirling North
"134.27505750219737,  -32.83233928113338",Streaky Bay
"136.92088307027063,  -29.83326348950872",Stuarts Creek
"137.0270330033021,  -36.004223276752256","[""Stun'Sail Boom""]"
"139.36092429633825,  -35.05386686518804",Sunnyside (SA)
"138.73455535642898,  -34.82335312258056",Tea Tree Gully
"137.74147920665155,  -33.79648184756134",Tickera
"138.74588006724574,  -34.676263959565155",Uleybury
"138.68051494229258,  -35.02488534671286",Upper Sturt
"133.8137791937797,  -31.970557073974984",Wandana (SA)
"139.31904782836406,  -35.19578026503998",White Sands
"138.79456693773759,  -33.4396139910245",Willalo
"136.56022978708074,  -28.497502895607283",William Creek
"140.57729761164967,  -36.203877771260245",Wirrega
"138.54646260884576,  -34.88225345762708",Woodville Park
"135.88908954299131,  -34.32600801474169",Yallunda Flat
"135.6862647346369,  -34.124402260167",Yeelanna
"116.13102737252382,  -31.340080815945445",Bindoon
"114.87443454225264,  -28.025788664180432",Binnu
"117.06792548668463,  -33.68252880241804",Boscabel
"117.51760890281861,  -32.30627746093556",Bulyee
"115.49539915429318,  -29.29729512662137",Bundanoon (WA)
"115.042706354102,  -28.969628394164765",Burma Road
"115.87644923706647,  -22.144095937819323",Cane
"117.92074283808725,  -34.992691763207276",Collingwood Heights
"115.75817830914211,  -31.9942632254715",Cottesloe
"117.33359911990372,  -31.880601620772474",Cubbine
"114.93005608062423,  -29.241937308215533",Dongara
"113.10018760000568,  -25.119240301732674",Dorre Island
"115.73054664478474,  -32.5527940600121",Dudley Park (WA)
"114.72859180035599,  -28.350506675644944",East Bowes
"116.80488740566652,  -30.478926717648108",East Damboring
"118.13340009320908,  -31.251407646794803",Elabbin
"117.68361933607352,  -30.73298033306766",Gabbin
"116.25439830562834,  -30.90491484810499",Glentromie
"117.42284271333428,  -34.92392129649395",Hay (WA)
"117.21312930651887,  -33.08637285670569",Highbury (WA)
"115.24611366263277,  -33.73531243764681",Jindong
"121.4694447686656,  -30.753159895482064",Kalgoorlie
"118.67363060975138,  -26.12515356577442",Karalundi
"116.02533669813786,  -32.11508733851151",Kelmscott
"116.03031662240416,  -32.43698108599531",Keysbrook
"118.52255907638232,  -32.87055951645301",Kuender
"118.2360548610228,  -32.68851790761366",Kulin
"118.03461839013171,  -32.61477444482957",Kulin West
"116.5021571666224,  -29.752410068531358",Latham (WA)
"115.13252089765069,  -34.33967489962148",Leeuwin
"116.1074417925433,  -33.46269830672123",Lyalls Mill
"116.69536184277749,  -21.04858197610454",Maitland (WA)
"116.6387747127571,  -33.24857884453961",Meeking
"115.80120476867693,  -32.040395262134055",Melville (WA)
"121.13310578554554,  -29.71094993591656",Menzies (WA)
"117.86537780798686,  -34.987283725340355",Milpara
"121.76930819121402,  -33.78934527935211",Monjingup
"118.26080614354579,  -30.874654187675254",Mukinbudin
"116.2069025165307,  -22.68814524548264",Nanutarra
"114.8628772940372,  -28.27715188878124",Nolba
"115.75680329877672,  -31.63862057816501",Nowergup
"119.80563597246376,  -20.16091853061839",Pardoo
"115.91505184320289,  -32.047846919223126",Parkwood (WA)
"116.31450329281381,  -29.469601018272105",Perenjori
"117.22922240592395,  -33.19247353497243",Piesseville
"118.76078400301995,  -20.451925762866885",Pippingarra
"115.69753823032462,  -31.665157131505154",Quinns Rocks
"116.18234243364455,  -31.996893821194668",Reservoir (WA)
"117.65686501965385,  -22.986652000824304",Rocklea (WA)
"115.2703095385208,  -34.26249822944876",Scott River
"117.72289939038417,  -21.056890083479544",Sherlock (WA)
"115.63322588790209,  -33.348525034532244",South Bunbury
"115.81106447273557,  -31.885196073872102",Stirling (WA)
"118.52731171639047,  -31.674710397009427",Tandegin
"115.97275059964763,  -33.62060640672047",Thomson Brook
"116.4417960509982,  -30.923687028330487",Yerecoin
"146.32390015312083,  -41.24345568279784",Aberdeen (Tas.)
"148.17333316539438,  -41.959692182717816",Apslawn
"147.82184608180077,  -41.00438655122691",Banca
"147.76335312372206,  -43.20104859656434",Cape Raoul
"145.9600241440919,  -41.74604627563266",Cradle Mountain
"146.68850087362642,  -42.59822167050048",Ellendale (Tas.)
"146.9397818946002,  -42.6304835514217",Gretna
"145.42565558401964,  -40.88167810795195",Hellyer
"147.6660479535756,  -41.13517025725702",Kamona
"146.55756122546842,  -42.75589728613224",Maydena
"144.96185068502814,  -40.80986632390677",Montagu
"147.30833454176872,  -42.85632001352134",New Town (Tas.)
"147.11664461054718,  -41.38867570999969",Newnham
"147.15040063281594,  -43.1378274707303",Nicholls Rivulet
"147.71960230017783,  -43.088655225033506",Nubeena
"147.6016284598292,  -42.71508246845431",Pawleena
"146.9414584953326,  -41.26554095057346",Robigana
"145.52975025782362,  -41.79319743460544",Rosebery (Tas.)
"145.94681882931908,  -41.072454440493296",Round Hill (Tas.)
"146.33277122839385,  -41.385942949957844",Sheffield
"147.8967765991724,  -42.5943690357554",Spring Beach
"145.37667506396207,  -42.16687978763937",Strahan
"145.10408152199722,  -41.060874442329734",Trowutta
"147.20834766529217,  -41.320290953742195",Underwood (Tas.)
"147.29999975904667,  -41.50895796491618",White Hills (Tas.)
"132.18418222525565,  -14.55007875077112",Binjari
"132.24339551997258,  -11.352024422813027",Cobourg
"131.0691867516198,  -12.79667710243341",Fly Creek
"131.86232811216547,  -23.452494910052394",Haasts Bluff
"134.8960729086388,  -15.211212911605386",Limmen
"130.4217742217752,  -11.3920487602267",Pirlangimpi
"131.0504021740904,  -12.370757658462953",Shoal Bay (NT)
"133.9992036845802,  -21.48331402454725",Tara (NT)
"130.81745035954802,  -11.560051573465527",Tiwi Islands
"133.92248370811183,  -23.684793141755872",Undoolya
"149.1435579091163,  -35.24392773432264",Downer
"149.0497653574239,  -35.25011099946082",Weetangera
"153.44399809573434,  -28.793905802586988",Alstonvale
"147.9287584191347,  -29.121618088833664",Angledool
"149.81602266292015,  -35.63987126842214",Araluen (NSW)
"151.03914789872923,  -29.830873277253218",Auburn Vale
"151.70145088469098,  -32.270617867694654",Bandon Grove
"149.98110933436337,  -32.51873356410885",Barigan
"152.67456624306644,  -31.670201443513946",Batar Creek
"149.4136515810439,  -33.49366027690294",Bathampton
"150.61911158491597,  -34.758866113731166",Bellawongarah
"148.44957065122043,  -34.18085283896588",Bendick Murrell
"149.45712990627257,  -31.540224631851025",Binnaway
"152.32490179985876,  -31.350589048307018",Birdwood (NSW)
"148.80127863917517,  -34.74576550665634",Bowning
"150.48219447850002,  -34.428883925169835",Braemar
"148.6073488786664,  -32.135908129761674",Brocklehurst
"150.5192017031429,  -34.78986397314484",Browns Mountain
"153.54351433661643,  -28.55578298977314",Brunswick Heads
"149.66601955154277,  -32.567223626108806",Buckaroo
"151.15167989840612,  -33.11379246453776",Bucketty
"150.032674266312,  -30.25910042706497",Bullawa Creek
"149.95505887536297,  -34.88036568625391",Bungonia
"153.4568642304679,  -28.98026448131893",Cabbage Tree Island (Ballina - NSW)
"150.71918056126162,  -34.09426351125191",Camden Park (NSW)
"150.94769519422564,  -33.88624352903488",Canley Vale
"147.43633625414634,  -31.325169356789868",Canonba
"151.1244045127374,  -34.03618149039975",Caringbah
"149.9056306570273,  -34.7057260988473",Carrick (NSW)
"150.88951582502665,  -33.92501585870774",Cartwright
"153.03105979542724,  -28.84720003078683",Casino
"150.18530021056768,  -35.7303639299713",Catalina
"151.23965368809817,  -33.96951174294435",Chifley (NSW)
"151.13855850034867,  -33.85000191447717",Chiswick
"151.28253534417212,  -33.65040409918262",Church Point
"148.55481682559494,  -29.38326928671131",Collarenebri
"150.73609836631516,  -34.885146976447835",Comerong Island
"149.98030816724778,  -30.03944071022723",Couradda
"151.2260126522951,  -33.82700978890729",Cremorne (NSW)
"151.2277322542504,  -33.840962645893555",Cremorne Point
"152.71540649061467,  -31.43932695940563",Crosslands
"152.14178881976125,  -28.484006837542715",Cullendore
"149.60805941194164,  -32.36255407445681",Cumbandry
"150.89494063218336,  -33.46474616673903",Cumberland Reach
"151.22568426272625,  -33.92881272113325",Daceyville
"148.14059248672385,  -33.311924108572995",Daroobalgie
"153.27350538816347,  -29.81377716484493",Diggers Camp
"152.40934067315436,  -28.915385623264097",Drake
"150.14198540573895,  -32.91483053365553",Dunville Loop
"153.31280398292353,  -29.008625463151027",East Coraki
"150.95327163793175,  -31.095959490091058",East Tamworth
"151.2740892407952,  -33.70062404385557",Elanora Heights
"151.2944199359401,  -29.826075458994424",Elsmore
"151.27734473322096,  -33.641868793680466",Elvina Bay
"151.0960766595671,  -33.89025102748349",Enfield (NSW)
"153.16899319676736,  -28.796621045645928",Fernside
"150.44627801675793,  -34.64907562116388",Fitzroy Falls
"149.50559209456102,  -35.42367702626104",Forbes Creek
"150.90782464673467,  -33.62749807175795",Gables
"151.12721017742626,  -33.8281726842105",Gladesville
"149.70601669474664,  -33.419636978755825",Glanmire (NSW)
"151.1865928074813,  -33.87803615713566",Glebe (NSW)
"152.11621616142654,  -29.571110816540244",Glen Elgin
"146.568487376341,  -35.741520759036376",Goombargana
"149.9935543497917,  -37.21087209390049",Green Cape
"148.48289612537383,  -31.285252671020636",Gulargambone
"148.65212270408372,  -33.08335260131696",Gumble
"150.98361293965738,  -31.99619101362929",Gundy
"150.48659021145485,  -34.34684622077965",Hill Top (Wingecarribee - NSW)
"153.35720301105877,  -29.396158963320616",Iluka (NSW)
"152.73135114561322,  -30.499625636405742",Kalang
"149.23277650173023,  -35.37588949408023",Karabar
"151.95708361528494,  -32.63223446393546",Karuah
"151.22913874639957,  -33.92526850258259",Kingsford (NSW)
"152.9439730770498,  -29.982074351749375",Kremnos
"148.08910122585792,  -35.546577803911006",Kunama
"152.76552198052454,  -31.634994272090804",Lakewood
"151.46402745516434,  -32.570449594037406",Lambs Valley (Singleton - NSW)
"151.2455232997937,  -33.98263920061752",Little Bay
"150.73600916143985,  -33.65324754923957",Londonderry (NSW)
"152.53139437803816,  -28.735915819857404",Lower Duck Creek
"152.9169805714346,  -30.721596522327403",Macksville
"150.82977691529297,  -33.70684040226294",Marsden Park
"147.2956450402304,  -35.28492329220475",Maxwell
"150.7412795773007,  -33.11079675556326",Mellong
"149.61129859413896,  -33.18074173412532",Millah Murrah
"153.26888594593296,  -29.747453685743274",Minnie Water
"150.19059194790466,  -35.49179888756981",Mogood
"149.93325534756949,  -35.41610920903036",Mongarlowe
"151.04810572198843,  -30.949536009346755",Moonbi
"145.44532322858254,  -35.123340273591346",Moonbria
"152.45163285478546,  -31.044872989419073",Moparrabah
"151.28531890070087,  -33.62742432118021",Morning Bay
"149.70932390063734,  -32.62538674329387",Mount Knowles
"150.82971625015324,  -33.627074887585806",Mulgrave (NSW)
"149.8906829879413,  -36.53554747526986",Mumbulla Mountain
"150.56151536748658,  -34.88835226779233",Mundamia
"151.00416607425905,  -31.116957544448947",Nemingha
"151.01690912283402,  -33.772467250249505",North Rocks
"146.07478976821872,  -32.13735332374531",Nymagee
"152.5630021404319,  -31.961503852950376",Old Bar
"149.57826730773948,  -37.061410382036215",Pericoe
"150.52773672973197,  -31.136118434868084",Piallaway
"152.65480446626685,  -29.048896705238377",Pikapene
"151.2759331172942,  -29.468651310804706",Pindaroi
"151.19380204810673,  -33.868713347376016",Pyrmont
"149.2465197900167,  -35.345477218355086",Queanbeyan East
"149.04946930957777,  -36.94473225660523",Quidong
"151.14031104770285,  -33.982275220285096",Ramsgate
"149.7944619548969,  -34.33635976333896",Richlands (NSW)
"149.53466421349992,  -31.0846870479264",Rocky Glen
"152.30680977083978,  -29.185506182798147",Rocky River (Tenterfield - NSW)
"149.95053387432867,  -33.07946551031783",Round Swamp
"151.59392768053212,  -30.794728843892358",Salisbury Plains
"147.2894413429745,  -35.1248318445243",San Isidore
"151.48527563921553,  -33.36994714961066",Shelly Beach (NSW)
"152.7690737981056,  -28.84069582772407",Simpkins Creek
"152.72991835667477,  -29.50132981057466",Smiths Creek (Clarence Valley - NSW)
"150.72635076909626,  -34.070698007308174",Spring Farm (NSW)
"150.82956360243665,  -34.022452494274305",St Andrews (NSW)
"149.6369055193869,  -32.46244374167608",St Fillans
"151.79077243045919,  -29.333019133410296",Stannum
"149.55822999322953,  -35.10749919787526",Tarago
"149.88435828529703,  -33.54555884548337",Tarana
"152.45465402939118,  -31.936192271145853",Taree South
"152.7840635690579,  -31.329032604067667",Telegraph Point
"151.61355606544407,  -32.741021602065956",Tenambit
"152.84013077512316,  -28.411008971720083",Terrace Creek
"152.04316449782098,  -32.55087463398018",The Branch
"150.55358673516653,  -34.205371682502836",Thirlmere
"152.77504878288227,  -30.43449035941818",Thora
"150.3147658058948,  -35.140262270189474",Tianjara
"152.23026398046122,  -29.056533525134316",Timbarra (NSW)
"150.7807210295319,  -34.76092741658512",Toolijooa
"148.0157240244109,  -35.94526887378699",Tooma
"147.91202328665312,  -32.06281030928712",Trangie
"153.42257838040967,  -28.451933746158357",Upper Burringbar
"153.43493085482044,  -28.25203910759216",Upper Duroby
"151.74455754673636,  -32.16523798301769",Upper Karuah River
"150.974169512565,  -33.95872262589557",Voyager Point
"150.42739361927372,  -34.28317599513775",Wattle Ridge (NSW)
"152.93973991674767,  -30.78036924132111",Way Way
"150.43058880312788,  -34.439219653408536",Welby
"149.56323247495826,  -33.4085208820688",West Bathurst
"152.95555975157242,  -28.5373189782036",West Wiangaree
"150.17968034746488,  -34.69961683851435",Wingello
"152.3557328348743,  -31.858098387794378",Wingham
"149.52396795916286,  -34.859999185499355",Wollogorang
"149.90894444373805,  -37.20199588145581",Wonboyn North
"151.6244982871947,  -32.66830117829071",Woodville (NSW)
"151.14253746944584,  -31.312760469700205",Woolomin
"152.44546123467276,  -32.44992618458822",Yagon
"150.79099489907273,  -34.533509096529734",Yallah
"146.0606895940164,  -36.22496351833476",Almonds
"142.8267063609832,  -34.86594822956861",Annuello
"144.371115117629,  -38.23388897194868",Armstrong Creek (Vic.)
"143.4699547052982,  -37.08208210691027",Avoca (Vic.)
"141.31268370114935,  -37.69587627879002",Bahgallah
"143.86575977171225,  -37.563453186011685",Bakery Hill
"144.154248188836,  -38.044973372467574",Bannockburn (Vic.)
"143.5344693603956,  -36.01758182902218",Barraport West
"145.46583623557967,  -38.476712167388186",Bass
"145.2836393967345,  -37.82696450046755",Bayswater North
"145.3829797229163,  -38.04162771638255",Beaconsfield (Vic.)
"143.67495996817016,  -37.773221280447835",Berringa
"147.1054688584621,  -36.148715104023566",Bethanga
"144.67906488441233,  -37.69418797600758",Bonnie Brook
"145.12296445727125,  -38.00048512238801",Braeside
"144.3881639379092,  -38.294034102717305",Breamlea
"143.53342376808874,  -37.497997032331355",Brewster
"141.4057649508695,  -37.39330306694309",Brimboal
"143.91211745273287,  -37.54840243843261",Brown Hill (Vic.)
"147.3690101047641,  -36.17398953489969",Bullioh
"142.25464721352242,  -36.80623932540966",Bungalally
"143.363561593767,  -36.056105389655414",Bunguluke
"143.33564853224948,  -36.28783329801418",Charlton (Vic.)
"145.13423317361918,  -38.04084216188018",Chelsea Heights
"146.54820154106588,  -36.146287408661166",Chiltern Valley
"143.97899066017294,  -37.495903699708144",Clarkes Hill
"144.48667793132162,  -37.5909773613888",Coimadai
"143.75398131664588,  -37.86764737008232",Corindhap
"145.29892738610516,  -38.077496832579406",Cranbourne North
"143.7589817605131,  -37.821742083827324",Dereel
"144.69754128773423,  -37.62075521978213",Diggers Rest
"143.84205427313066,  -35.81116433922379",Dingwall
"145.69980606118966,  -36.39397971658735",Dookie College
"144.2658448912744,  -36.70632582058997",Eaglehawk
"145.6816371313654,  -36.53115524095575",Earlston
"144.04185786002537,  -38.45025079529288",Eastern View
"146.11867970094616,  -37.41854018204751",Enochs Point
"144.9011870948343,  -37.73844296874356",Essendon North
"146.07367066370207,  -38.7274102527466",Fish Creek
"142.6939615592316,  -38.260306500601956",Framlingham
"142.71216887458888,  -38.20057183404688",Framlingham East
"144.09626205341434,  -37.242052807100265",Franklinford
"143.8584387006115,  -37.73156699693695",Garibaldi
"148.23899943769726,  -37.19271404784704",Gelantipy
"149.69062985977394,  -37.4865961917925",Gipsy Point
"141.83252357259857,  -36.23333410219281",Glenlee (Vic.)
"145.60446282230333,  -36.89832254615572",Gooram
"142.94451369421472,  -36.93244020377228",Greens Creek (Vic.)
"145.36009878119108,  -37.994409678751424",Harkaway
"145.57799047159523,  -38.65680985909611",Harmers Haven
"145.1841676950499,  -38.296791942964305",Hastings (Vic.)
"145.0319821852705,  -37.82467531249022",Hawthorn (Vic.)
"145.55029065645866,  -37.65996453115662",Healesville
"145.04271164813275,  -37.38220485645189",Heathcote Junction
"144.50163480454322,  -37.69499016769834",Hopetoun Park
"144.33944970731298,  -36.59048219035025",Huntly North
"143.9879364305866,  -37.08839563908942",Joyces Creek
"144.36421317866768,  -36.77504438587486",Junortoun
"145.38346587236182,  -37.817240753430255",Kalorama
"145.46542764612147,  -35.999741436515464",Katunga
"144.67441113743013,  -37.38229718713475",Kerrie
"142.71789919090622,  -35.89833745790523",Kinnabulla
"145.66624925475136,  -38.447474554558966",Krowera
"142.82981751761164,  -36.37860829157916",Laen North
"142.42347046672506,  -36.12853700515171",Lah
"148.03621962288048,  -37.86334278940198",Lake Bunga
"144.55837698555243,  -36.88556623244484",Lake Eppalock
"143.1531934044541,  -37.60891617861465",Lake Wongan
"142.60654222562542,  -35.55600257465601",Lascelles
"143.7989007744705,  -35.98160530886472",Leaghur
"142.55407299420648,  -36.984832824888684",Ledcourt
"141.01402359918814,  -34.11874261634376",Lindsay Point
"145.48893982112736,  -37.878480109999614",Macclesfield (Vic.)
"143.8470111358327,  -37.63169206749061",Magpie
"142.85769278680903,  -35.81509515716587",Marlbed
"142.17797995812725,  -36.96017329456259",Mockinya
"142.64479529299788,  -37.092867834045016",Mokepilly
"145.3502164804699,  -37.83617744568174",Mount Dandenong
"142.24480019819154,  -36.449070352869576",Murra Warra
"141.53684602792654,  -37.38624232062999",Nareen
"142.79212544219024,  -38.40840231690947",Naringal East
"145.3248548395172,  -38.513746699137194",Newhaven
"143.7322221150559,  -35.799995983706665",Normanville (Vic.)
"147.41571861461009,  -38.193684785301855",Paradise Beach
"142.18751876247958,  -35.4352781931238",Patchewollock
"143.6648025340463,  -35.8086420945386",Pine View
"142.61855214543567,  -37.23398786870049",Pomonal
"143.55304060503065,  -36.98085479109164",Rathscar
"145.0068855599227,  -37.71224593096351",Reservoir (Vic.)
"144.92532737411236,  -37.625735809184405",Roxburgh Park
"147.7363783259104,  -37.75626124083764",Sarsfield
"145.27345400237243,  -38.45324241814347",Silverleaves
"143.68484845876307,  -37.224243088007825",Stony Creek (Central Goldfields - Vic.)
"145.27630315567984,  -38.50497575109161",Sunderland Bay
"147.2817669308074,  -36.32450283787949",Tallangatta South
"144.01471374766027,  -37.0049273090769",Tarrengower
"143.32404250278273,  -36.195062587532796",Teddywaddy
"145.32104202240646,  -37.85570085517115",The Basin (Vic.)
"147.9127505523542,  -36.059638689355715",Tintaldra
"143.6318152377548,  -35.507661393062016",Tresco West
"144.41334600879517,  -37.328638012966266",Tylden
"141.79374105798112,  -38.21073210390959",Tyrendarra
"143.27936568561714,  -35.481706756494034",Ultima
"148.16605572156297,  -37.69140619334985",Wairewa
"141.49457407413726,  -37.48619975359706",Wando Vale
"142.657843076219,  -36.047128804309416",Wilkur
"143.71776018480963,  -37.482795682911416",Windermere (Vic.)
"144.52627377836777,  -37.3623472430752",Woodend (Vic.)
"143.62982557998498,  -36.20497917919231",Wychitella North
"146.1989787092357,  -38.8044791540903",Yanakie
"146.07452238066017,  -38.18694122719468",Yarragon
"147.47948801983574,  -19.526710683339374",Airdmillan
"152.5349467533636,  -27.98083800917343",Aratula (Qld)
"148.99160746026428,  -20.930533192954204",Ball Bay
"146.79246167973255,  -19.247516599684147",Belgian Gardens
"146.67589094223112,  -19.294626514285806",Bohle Plains
"151.82703100304198,  -27.37814926246691",Boodua
"139.91599081531606,  -22.878245876401177",Boulia
"151.49871606554305,  -26.71596654471144",Boyneside
"147.3536392921577,  -19.54349910198968",Brandon
"152.35111444911942,  -24.866773774107145",Bundaberg Central
"143.047343930343,  -20.274076077154923",Burleigh
"151.84765215220833,  -26.184532164546436",Byee
"146.77837054101775,  -19.652953870980767",Calcium
"141.5669106591151,  -28.442860783006754",Cameron Corner
"148.02270058862382,  -23.083377406058634",Capella
"145.37342226438116,  -17.06725748096609",Chewko
"153.24236436952702,  -27.99387735530974",Clagiraba
"153.06146036327874,  -27.49895016290326",Coorparoo
"145.46267753943383,  -16.534288903719705",Craiglie
"151.92305093295525,  -27.515033280125337",Cranley
"145.71171168702742,  -28.06017126306468",Cunnamulla
"146.00307848659992,  -17.47705760047235",Daradgee
"152.05064522052066,  -25.017345972110753",Delan
"152.7720553215076,  -27.014186197044253",Delaneys Creek
"148.04224056760805,  -28.58790479869139",Dirranbandi
"143.27225801518043,  -15.416035717192946",Dixie (Qld)
"152.34434060627606,  -25.47147330192198",Doongul
"152.77343284552865,  -25.295584106141067",Dundowran
"150.95536236202858,  -27.661835811667057",Dunmore (Qld)
"152.27965246249755,  -27.89060445376746",East Haldon
"152.77639989086805,  -27.607706592420218",East Ipswich
"145.8241011941111,  -16.946555801973968",East Trinity
"152.3423218510087,  -28.20413170873355",Emu Vale
"153.15635371572722,  -28.075976468455647",Ferny Glen
"152.9274193465029,  -27.403394579913893",Ferny Grove
"151.42066417893582,  -27.37391115151664",Formartin
"145.72147445609517,  -17.26630096001309",Gadgarra
"152.18635048196808,  -27.541468715011778",Grantham
"146.68473880758955,  -19.352496664419665",Gumlow
"147.22247942272355,  -19.45536493112583",Jerona
"151.40867770500617,  -27.036825343780013",Kaimkillenbun
"145.37217179966407,  -17.440473563987286",Kalunga
"152.3182806015877,  -24.917098812737994",Kensington (Qld)
"152.3777084842817,  -26.54052961738524",Kingaham
"151.23535535280277,  -23.903456690244028",Kirkwood
"151.86257136225265,  -26.058108437303765",Kitoba
"145.6780446417968,  -17.9441896515156",Kooroomool
"152.89091832645886,  -26.606752247625323",Kureelpa
"152.4048151530015,  -27.610515700157695",Laidley North
"148.86223941837028,  -23.06835521810949",Mackenzie River
"153.07881583885555,  -26.593554868308924",Marcoola
"152.6964952599599,  -25.52324586583133",Maryborough (Qld)
"152.58383253227194,  -26.246811562791905",Marys Creek
"153.43672710587433,  -28.047877433212033",Mermaid Beach
"145.3697937615909,  -16.40576770099362",Miallo
"152.15638881901123,  -24.673416422404856",Miara
"151.04357252753826,  -28.015903013116528",Millmerran Woods
"152.88508838660508,  -26.694063057419196",Montville
"152.45160314261713,  -27.920878114944095",Moorang
"148.46661525394208,  -26.5067958920483",Mount Bindango
"145.10524115474735,  -16.516966151312737",Mount Carbine
"147.2675076224782,  -20.746672995158026",Mount Wyatt
"151.72395273373883,  -26.28716962607753",Mp Creek
"147.04164268553612,  -19.95869624522985",Mulgrave (Qld)
"138.6118260361696,  -17.42267499945935",Nicholson (Qld)
"153.0624610645559,  -27.479652605964176",Norman Park
"153.2346772370127,  -28.145166283157586",Numinbah Valley
"150.31408653094195,  -23.72887573966628",Oakey Creek
"149.1437795237646,  -21.180177517532215",Ooralea
"152.23250697725902,  -27.561269655142898",Placid Hills
"152.41914196892802,  -27.578169135816218",Plainland
"148.56539649776144,  -20.411078993848083",Proserpine
"151.58918858661855,  -26.178258545677366",Proston
"152.71647412737371,  -27.703617284153868",Purga
"151.98643670541065,  -27.584859635082978",Rangeville
"152.84600909351823,  -27.112210364572245",Rocksberg
"146.38081359525816,  -19.040490672079923",Rollingstone
"151.8156710941461,  -27.97418071430613",Sandy Camp
"147.72632967560784,  -23.48108914070302",Sapphire Central
"147.82156375665372,  -20.574173481690796",Scottville
"147.0684579708432,  -19.569228272735707",Shirbourne
"152.64801567118707,  -26.208986807265887",Southside
"150.00301391486647,  -27.843781788895942",Southwood
"153.02459599196092,  -27.45934335660244",Spring Hill (Qld)
"150.753087970492,  -22.836211531841723",Stockyard (Livingstone - Qld)
"151.7052696957192,  -26.036803116320492",Stonelands
"147.89326357606373,  -21.335713427508434",Suttor
"153.06634210555418,  -27.991689423287426",Tabragalba
"143.8825380640812,  -18.05610228847337",Talaroo
"152.65930852834722,  -26.134890204134646",Tamaree
"151.2546059483979,  -25.049275309243143",Tellebang
"140.17997534197391,  -19.875235390364228",Three Rivers
"153.4906287515783,  -28.148564029574327",Tugun
"152.90074948286156,  -27.114008459984042",Upper Caboolture
"152.47879041934362,  -26.437848262381134",Upper Kandanga
"147.31896634153875,  -25.15422159009862",Upper Warrego
"149.23846830588909,  -26.115902696968732",Waikola
"149.90458627655502,  -26.13245107305132",Wandoan
"146.05232493541203,  -17.531074545044444",Webb
"150.16842530997337,  -23.67377237485681",Westwood (Qld)
"152.1114540009434,  -27.44375874926303",White Mountain
"152.84929168009967,  -27.694409430068678",White Rock (Ipswich - Qld)
"146.3145497176372,  -28.358439836356688",Widgeegoara
"152.02538467490157,  -28.13512317450523",Willowvale (Qld)
"153.19340663802427,  -27.74578318251624",Windaroo
"143.0402239840562,  -22.38345173080961",Winton (Qld)
"153.1024953893854,  -27.25521644829207",Woody Point
"152.97661387344021,  -26.660772165637056",Woombye
"145.80587911956667,  -17.344328680305214",Wooroonooran (Cairns - Qld)
"153.01339756067074,  -27.513739170868035",Yeronga
"149.46391018395738,  -26.45631678744845",Yuleba North
"146.1252634880963,  -18.86117361567109",Yuruga
"150.79537286854628,  -23.289804197653655",Zilzie
"138.5308052793678,  -34.94610873140397",Adelaide Airport
"138.52168927780193,  -34.876225958816335",Albert Park (SA)
"140.70495995230468,  -38.00314436004386",Allendale East
"139.2941623658253,  -34.756315508302734",Angas Valley
"138.5769809387744,  -33.551759180501314",Broughton River Valley
"138.61349397375002,  -33.84140061004004",Clare (SA)
"138.58670357703352,  -34.9720239466602",Cumberland Park
"140.92904832385227,  -36.46534547404846",Custon
"138.63503001089958,  -34.71912042122794",Edinburgh
"138.67031670110373,  -34.736196841915834",Elizabeth Grove
"138.4901015932497,  -34.84883513523158",Ethelton
"138.622873150661,  -34.49617531000126",Fischer
"138.65905237483932,  -34.8500420873026",Gilles Plains
"138.64299402049372,  -34.86253760290398",Hillcrest (SA)
"138.59897391953098,  -35.47608039070176",Hindmarsh Valley
"138.6724228942707,  -34.85154763595848",Holden Hill
"137.89345040962974,  -34.48670939676205",James Well
"138.3034854076487,  -34.21766265148029",Kallora
"140.61872913000278,  -34.41002222248563",Loxton North
"138.54680216677798,  -35.21017270244446",McLaren Vale
"132.7053588206727,  -27.026316440590293",Mimili
"138.56233213208625,  -35.00502293222235",Mitchell Park (SA)
"140.34882461278994,  -34.30846676416699",Moorook South
"138.7560734779066,  -34.41534219426906",Morn Hill
"138.49859685524385,  -35.15673219869143",Noarlunga Downs
"139.1561775273739,  -34.87526436140956",Palmer (SA)
"139.22744072336576,  -32.66912406527197",Paratoo
"139.06747740404887,  -34.47712661006741",Penrice
"138.5928475800181,  -34.88268136271832",Prospect (SA)
"134.45838060933528,  -32.01779429613607",Pureba
"135.41738033605665,  -32.93217081010796",Pygery
"137.964264221483,  -33.19426788744414",Risdon Park
"139.30053223831325,  -35.099702907792945",Riverglades
"139.07258703776634,  -33.9907377755708",Robertstown
"139.20201916712045,  -35.10890159296185",Rocky Gully (SA)
"136.90034111732274,  -30.563860129429806",Roxby Downs
"138.68380058034057,  -34.7628695674482",Salisbury Heights
"138.66064065207112,  -34.767409246771656",Salisbury Plain
"137.28008670836613,  -35.858925959155506",Seddon (SA)
"140.84965313025296,  -36.14330839220639",Senior
"138.52025262085024,  -34.996446559294675",Somerton Park
"140.5585342691691,  -37.08610017783479",Spence (SA)
"138.60514037760248,  -33.794246429965504",Stanley Flat
"138.59153058369466,  -34.944833008695035",Wayville
"137.04128559444223,  -35.044214698195084",White Hut
"140.34220748499823,  -36.359991823919444",Willalooka
"140.71508611056345,  -34.568182189580824",Woodleigh (SA)
"138.55225956357884,  -34.86740505977541",Woodville Gardens
"138.54157386638735,  -34.866104498650685",Woodville North
"138.47629619520222,  -33.56786328982799",Yacka
"134.70755370222773,  -32.467110001412905",Yantanabie
"138.5478833009583,  -32.32421461566566",Yanyarrie
"117.97419374988435,  -31.526400752477578",Baandee
"116.10212296929164,  -32.00109228210654",Bickley
"116.97433079254084,  -34.97969183528606",Bow Bridge
"116.62587495238363,  -31.538282810324123",Buckland (WA)
"115.91693084314332,  -32.08139943226545",Canning Vale
"115.91813549067088,  -31.979429092881396",Carlisle
"117.51887021882277,  -33.463225685705886",Cartmeticup
"117.01300169993395,  -20.660354596707258",Cleaverville
"115.68513279656143,  -33.373959639064466",Davenport (WA)
"117.35319061590889,  -34.96612330057002",Denmark
"114.75782671408219,  -28.443551178514475",Dindiloa
"115.71659025456981,  -33.312312116467055",Eaton (WA)
"115.74314323275448,  -30.042902055365772",Eganu
"114.61507666183422,  -28.777385405572335",Geraldton
"126.57791310755802,  -22.496870995932525",Gibson Desert North
"115.83976815351673,  -31.841007080295046",Girrawheen
"116.9393975635856,  -31.503037861877274",Greenwoods Valley
"116.04165799984652,  -31.920411344026384",Helena Valley
"115.97855251990421,  -32.17394632664873",Hilbert
"117.2100606174461,  -32.892443287062505",Hillside (WA)
"116.45844738019264,  -31.645725189166235",Hoddys Well
"114.4213333694584,  -27.705106861754732",Kalbarri National Park
"115.81486572807148,  -32.065632558540855",Kardinya
"115.79798990261143,  -31.967563402169663",Karrakatta
"116.81756669560555,  -30.706202184488088",Kondut
"115.8942674864291,  -31.858576556236077",Malaga
"118.69578532793415,  -33.22247340559086",Mallee Hill
"115.82611329049283,  -29.183967741052122",Merkanooka
"115.86587280185537,  -31.859627325364723",Mirrabooka (WA)
"117.54746063104629,  -33.60554214813847",Moojebing
"115.80571584285796,  -31.97983790873492",Nedlands
"116.6609514080788,  -34.75506076415014",North Walpole
"118.2183403104268,  -31.306512028934897",Nukarni
"121.8126360743692,  -33.84709085451174",Pink Lake
"116.16120755645707,  -29.082603436184264",Pintharuka
"117.12611579495119,  -32.654296536255714",Popanyinning
"117.90651240933833,  -35.03328987206202",Port Albany
"125.38348390891164,  -30.763276860322776",Rawlinna
"117.6913236619604,  -34.883879189593685",Redmond
"115.91586623424313,  -31.960452800273732",Rivervale
"114.66674278755644,  -28.84817816144575",Rudds Gully
"115.42718263863453,  -33.746588166536704",Sabina River
"115.80554893203161,  -31.95721211900315",Shenton Park
"116.50513986166098,  -27.263759640506002",South Murchison
"116.040601132913,  -31.86829374300907",Stratton
"116.88003162119422,  -34.96280108312701",Tingledale
"115.83489743825703,  -31.89812025350948",Tuart Hill
"119.56121258602637,  -32.84779693060485",Varley
"115.26780635109047,  -33.69336647880761",Vasse
"128.28487925513056,  -24.99883579621107",Warakurna
"115.11223513127209,  -34.01398273063422",Witchcliffe
"122.17835004081941,  -33.46104497638159",Wittenoom Hills
"115.04465412738472,  -22.933706133196093",Yannarie
"117.66258253139691,  -31.167587920297027",Yelbeni
"147.47936710740743,  -42.869862193132626",Acton Park (Tas.)
"147.49631634284867,  -42.34003984782716",Andover
"147.29028142297338,  -43.16476794778755",Apollo Bay (Tas.)
"145.96349237237615,  -41.0703011904221",Chasm Creek
"146.78913846589637,  -41.12932705457046",Clarence Point
"147.12537872809963,  -42.54180595787308",Dysart (Tas.)
"146.99534730957117,  -43.24951823802666",Glendevie
"146.96365330826697,  -41.28258705530456",Gravelly Beach
"147.00519960112626,  -41.35139842631262",Grindelwald
"145.5588596618698,  -41.9981424000572",Lake Margaret
"147.10499155380796,  -41.212084686184646",Lower Turners Marsh
"146.82717506859672,  -42.632438282126806",Meadowbank (Tas.)
"146.59173876254485,  -41.67352756267398",Meander
"146.5851233021661,  -42.6657805984894",Mount Field
"145.5610190858833,  -40.98890332441528",Myalla (Tas.)
"145.8593636954891,  -41.0443492082642",Ocean Vista
"147.40736874077982,  -42.989673776314994",Opossum Bay
"147.29330283862572,  -43.279764908249525",Simpsons Bay
"147.14752466006627,  -41.4556429595491",South Launceston
"146.53566967271738,  -41.20817836664654",Thirlstane
"146.63201445359974,  -41.45697900960561",Weetah
"145.99557274260414,  -41.15020152435238",West Pine
"146.91031279114347,  -41.58803678181263",Whitemore
"131.48308227325273,  -13.818146592937262",Douglas-Daly
"131.0031572702358,  -13.127239154710892",Eva Valley
"131.0780813549482,  -12.185525456023488",Glyde Point
"130.90371147694745,  -12.449777341711275",Hidden Valley (NT)
"130.8728088663761,  -12.38894919798091",Jingili
"135.48212228736534,  -21.983899421159755",Sandover
"131.16958577358784,  -13.090845160269357",Tortilla Flats
"130.85180878367137,  -12.43476547401884",Woolner
"149.14786124335194,  -35.26225859341045",Ainslie
"149.07759867452017,  -35.324646586053646",Curtin
"149.13620647475258,  -35.186733564398494",Gungahlin
"150.86123744382346,  -33.87002358494706",Abbotsbury
"151.7108801484357,  -32.948778281352006",Adamstown Heights
"152.58971730972056,  -29.053359911005376",Alice
"151.5485421913396,  -32.35155915548543",Allynbrook
"153.44965282862228,  -28.840565235235317",Alstonville
"150.8081297960202,  -33.92348359245525",Austral
"150.6686747872825,  -34.425471984310974",Avon (NSW)
"150.74533667636837,  -34.52392434418226",Avondale (NSW)
"150.76444964299031,  -29.470189605266597",Balfours Peak
"148.60112007062475,  -31.875937782058674",Balladoran
"146.5956672964616,  -34.18294151375605",Barellan
"150.5554774021913,  -35.091231044034345",Basin View
"151.3165181917136,  -32.853622827836844",Bellbird
"151.17648035045212,  -30.803564269905443",Bendemeer
"150.33097861874884,  -34.46863944746812",Berrima
"151.61817198695516,  -32.37695987653593",Bingleburra
"152.83096406287416,  -31.38957390500159",Blackmans Point
"147.63401899511578,  -34.014590752430195",Bland
"150.35659649219915,  -33.89584256723535",Blue Mountains National Park
"152.96992716088042,  -29.765166328288675",Bom Bom
"150.8523160587375,  -34.65367000241603",Bombo
"149.21354101896543,  -37.15331597290457",Bondi Forest
"153.01711401959884,  -30.37487763277945",Bonville
"151.45707243053326,  -30.345752075556113",Boorolong
"149.80487680811146,  -32.524235260124314",Botobolar
"148.762910804811,  -32.74972982203317",Bournewood
"151.2698592251191,  -33.76329124669095",Brookvale
"147.42908293377087,  -35.00849867830751",Brucedale
"149.66444943576812,  -32.517680759498724",Budgee Budgee (NSW)
"152.4174514500123,  -32.36924728660657",Bungwahl
"151.64557485064307,  -32.649646828557344",Butterwick
"149.24511023306502,  -33.34774186443189",Byng
"153.24020641990197,  -28.437666282577805",Byrrill Creek
"153.5721271765761,  -28.33740633310161",Cabarita Beach
"151.54696728959203,  -33.275848671802386",Canton Beach
"149.7110749407722,  -32.855744308087374",Carcalgong
"150.26606672601864,  -31.77014440205842",Cattle Creek (NSW)
"153.2623093705485,  -28.41832073860957",Cedar Creek (Tweed - NSW)
"152.6473977904161,  -30.07763914356618",Clouds Creek
"151.61113499285585,  -33.03921754305083",Coal Point
"150.74266827327992,  -29.231819547910312",Coolatai
"152.69298375446607,  -29.102546702920392",Coongbar
"147.52978410619536,  -33.52642288426078",Corinella (NSW)
"150.10722662532444,  -36.28156088640628",Corunna
"152.92457343243112,  -31.21495495161623",Crescent Head
"151.28934633644903,  -33.768616717732684",Curl Curl
"147.30913911949867,  -35.01272982495701",Dhulura
"152.08631617077236,  -29.706277445791912",Diehard
"150.44352322918118,  -35.40171356015675",Dolphin Point
"153.35616231866211,  -28.38795548474",Dum Dum
"150.29592793641316,  -35.626895731162435",Durras North
"152.30758195416348,  -32.08221340374275",Dyers Crossing
"151.5862433517278,  -32.761115089385164",East Maitland
"147.4024574002382,  -35.12958979485372",East Wagga Wagga
"151.65972526798794,  -32.91690952464553",Elermore Vale
"152.53819355359275,  -32.33211485889826",Elizabeth Beach
"147.21026611237983,  -35.04917792504461",Euberta
"147.79985310473523,  -34.93142749038768",Eurongilly
"153.48305166839623,  -28.30604274550019",Eviron
"152.50817379148867,  -29.09011218231131",Ewingar
"150.88927654277086,  -34.38375273680524",Fernhill
"146.98176978780842,  -32.16566949565956",Five Ways
"151.0557583818966,  -32.709125530911656",Fordwich
"145.43627906661752,  -34.81956646051755",Four Corners
"151.61761245520387,  -33.18401264520856",Frazer Park
"147.93410037261867,  -33.58477374428682",Garema
"150.04961699390353,  -32.59052468145671",Ginghi
"148.42817374625116,  -33.66352337310113",Gooloogong
"151.14762905025805,  -32.56453281781185",Gowrie (Singleton - NSW)
"151.37797984950387,  -33.453284596586435",Green Point (Central Coast - NSW)
"149.81991797046317,  -29.732264554084445",Gurley
"150.88295314866258,  -34.414992158023686",Gwynneville
"150.23323174574907,  -33.53312529769678",Hartley Vale
"152.3401910116856,  -31.980773835629023",Hillville
"148.8583582772804,  -34.06809889511656",Hovells Creek
"147.7164900210426,  -33.44690182563106",Jemalong
"150.06725367348267,  -33.80822381082899",Jenolan
"149.53150277987567,  -33.98537324086294",Jeremy
"150.44187270228224,  -35.074687775393244",Jerrawangala
"151.6853620456586,  -33.009172862048096",Jewells
"148.79142910355978,  -34.60441669935445",Kangiara
"152.28987813316678,  -31.91649707906577",Karaak Flat
"150.831567019012,  -32.20697517301396",Kayuga
"150.5116348280246,  -30.84848705206168",Keepit
"152.2107344124707,  -31.92520845820519",Kimbriki
"152.10936699146848,  -31.835513986178405",Knorrit Flat
"151.13656384367005,  -33.96729369453675",Kogarah
"152.051952418645,  -30.02599916000527",Kookabookra
"153.2607987512062,  -29.804783845354457",Lake Hiawatha
"146.70940377085608,  -34.53720425652147",Landervale
"151.04968011605803,  -29.21327651269687",Limestone (NSW)
"151.64343227272792,  -33.080314985595244",Little Pelican
"153.442547001866,  -28.871986483065832",Lynwood (NSW)
"150.44251027780163,  -31.806809543405475",Macdonalds Creek
"150.25419484458322,  -35.69943310761874",Maloneys Beach
"150.21064640877202,  -35.790583595308284",Malua Bay
"148.97328848570595,  -33.65079386284834",Mandurama
"150.63510695014173,  -32.18067840293228",Manobalai
"151.7515451806404,  -32.8910532777888",Mayfield North
"151.7244112667513,  -32.88499647354937",Mayfield West
"151.73260313109125,  -32.94682360291399",Merewether Heights
"149.89791800474822,  -36.89252665635287",Merimbula
"149.6229505423137,  -32.758361009622284",Meroo
"152.61789310761515,  -31.902128675602018",Mitchells Island
"142.28623506231943,  -34.28978274291751",Monak
"149.77354809240978,  -32.36518683550796",Moolarben
"150.93860050019728,  -29.659102099888756",Mount Russell
"147.37226586522002,  -33.17831195427161",Mulguthrie
"151.17785259506374,  -31.143218147783188",Mulla Creek
"151.6225324440329,  -33.11685904912645",Murrays Beach
"148.13427324382604,  -34.80202003800729",Muttama
"151.20048695220254,  -33.81695119223996",Naremburn
"152.81937593817764,  -30.355828212667877",Never Never
"152.99440439387314,  -28.60033241155621",New Park
"150.94842232664337,  -33.682835382816776",North Kellyville
"151.34864753175117,  -32.688649232794",North Rothbury
"147.38501838891068,  -35.095700532172",North Wagga Wagga
"152.05665753153056,  -31.720900424434625",Number One
"151.11284717951185,  -31.458626928221626",Nundle
"152.8801467926415,  -28.44617136085331",Old Grevillia
"152.56948732992828,  -31.91579123926384",Oxley Island
"149.64572445396396,  -33.29551124671571",Peel
"151.15498100351033,  -33.89458189781191",Petersham
"153.49397557337923,  -28.916973553738774",Pimlico Island
"150.32516674415777,  -35.25810947723454",Porters Creek
"149.23093289445882,  -35.35284492862933",Queanbeyan
"148.0419615680655,  -35.004581509702845",Reno
"151.49719825915778,  -32.865170489749396",Richmond Vale
"150.60082594773084,  -34.60803783616963",Robertson (NSW)
"151.2690003965026,  -33.87392433786812",Rose Bay (NSW)
"147.8517314944024,  -35.65286392477712",Rosewood (Snowy Valleys - NSW)
"149.4964247567473,  -35.487911528854276",Rossi
"151.09955367783195,  -32.190709235317144",Rouchel Brook
"153.34689504622756,  -28.467196052059478",Rowlands Creek
"150.98492237825684,  -31.83078856991198",Sandy Creek (Upper Hunter Shire - NSW)
"151.34073340174828,  -29.6776503308775",Sapphire
"153.13766312772927,  -30.226515369281884",Sapphire Beach
"150.95848633375812,  -31.713522009213186",Scotts Creek (NSW)
"150.80653296189465,  -33.72757524558681",Shalvey
"150.83739297540657,  -34.564781432863164",Shellharbour City Centre
"150.28622796728473,  -35.65492596918896",South Durras
"149.15028308908043,  -33.40345549558435",Spring Hill (Orange - NSW)
"151.24139967402522,  -29.877230447374505",Stannifer
"152.63433340767304,  -31.71403600093735",Stewarts River
"151.79072056941118,  -32.89778975574838",Stockton (NSW)
"151.93709837585135,  -32.316806171548464",Stroud Road
"144.94879614448817,  -35.37442521178271",Stud Park
"153.6069748303833,  -28.687825828762843",Suffolk Park
"148.82894952167098,  -32.56676046605666",Suntop
"149.12082177198394,  -33.503701323635134",Tallwood
"149.86336787919743,  -31.398845178764653",Tambar Springs
"149.7873654843567,  -34.40032693053532",Taralga
"149.96713634803365,  -36.739695973467384",Tathra
"150.62940488958873,  -34.86825508593529",Terara
"152.25663946302538,  -30.05484369361624",The Gulf
"149.62228403377262,  -33.54305049327568",The Lagoon
"152.92358772860953,  -29.54465794964499",The Pinnacles
"151.63682248238916,  -32.782191312275025",Thornton (NSW)
"151.9923603370273,  -32.758947051941696",Tilligerry Creek
"150.99828026894747,  -31.075773542120196",Tintinhull
"147.35161001831898,  -35.14520932552732",Tolland
"143.39077723657206,  -35.03347129108772",Tooleybuc
"148.57755565769997,  -32.48020598910742",Toongi
"149.91060514783373,  -32.511384882402425",Totnes Valley
"148.23075433779053,  -32.78723514586996",Trewilga
"149.29984415923417,  -32.93567899651194",Triamble
"151.42763385385223,  -33.307025738198014",Tuggerah
"153.26220982592068,  -28.62915473057679",Tuntable Creek
"152.85606641552033,  -28.5165542817164",Upper Eden Creek
"152.98760237153041,  -30.286070618705324",Upper Orara
"152.64675708618233,  -31.63041626179837",Upsalls Creek
"150.58448487724587,  -33.70998686164629",Valley Heights
"151.114448837419,  -33.72181718222151",Wahroonga
"150.91086459571682,  -29.260152064891756",Wallangra
"144.76050264168546,  -35.1023515903973",Wanganella
"147.72066054481954,  -35.00634321061193",Wantabadgery
"152.85144075926218,  -29.69251196426626",Waterview Heights
"150.3690213545743,  -32.64145448687142",Widden
"149.5629696918988,  -32.482774541117344",Wilbetree
"147.0523610890311,  -36.04509924020603",Wirlinga
"152.19968799568966,  -30.262498449909362",Wongwibinda
"150.9604335721217,  -33.841304810359915",Woodpark
"151.21091792645424,  -32.00409463639366",Woolooma
"151.139599541845,  -34.04499459240648",Woolooware
"146.76911449414342,  -37.019406307936336",Abbeyard
"146.65641462692824,  -38.61894298388946",Alberton (Vic.)
"145.7367493093471,  -37.19808415958723",Alexandra (Vic.)
"143.80076737473811,  -37.556030567135224",Alfredton
"145.35733462736422,  -37.942366364177126",Belgrave South
"148.92751092515982,  -37.71384014374061",Bemm River
"147.99436136141261,  -36.35680765849331",Biggara
"143.67452717317306,  -37.53840145605864",Bo Peep
"145.96367573755063,  -38.205395198516776",Bona Vista
"146.3308024105859,  -36.163565011379525",Boorhaman East
"144.54091333998338,  -37.69964897008312",Brookfield (Vic.)
"145.7107673526688,  -37.43791722123612",Buxton (Vic.)
"143.49842549787647,  -35.751012407518054",Cannie
"142.52264722425284,  -37.95275098157736",Caramut
"149.30902672677252,  -37.32374160786339",Chandlers Creek
"143.29754298176425,  -38.63662242807273",Chapple Vale
"142.1634721508368,  -37.132632759561076",Cherrypool
"144.56995593888232,  -38.15513328463522",Clifton Springs
"145.40859688217657,  -37.70359481156013",Coldstream (Vic.)
"142.87400070859735,  -37.04624136771004",Concongella
"144.4229576045095,  -38.25761664015463",Connewarre
"146.7700094158301,  -37.87101981490573",Coongulla
"144.92731024259655,  -37.58611017103722",Craigieburn
"143.02989748005,  -37.33426709249834",Dobie
"144.31103541494963,  -37.19294834771254",Drummond North
"142.3603201091079,  -37.67549125805343",Dunkeld (Vic.)
"141.3784506139531,  -37.519585452081095",Dunrobin
"143.51539366384216,  -38.624612324268384",Ferguson (Vic.)
"144.2496195116602,  -38.270282702052675",Freshwater Creek
"144.26467879009272,  -37.13932493988451",Fryerstown
"147.2582562732927,  -36.15089824459831",Georges Creek (Vic.)
"145.3526717117987,  -37.14248049674616",Ghin Ghin
"146.73530363942436,  -37.753531687976505",Gillum
"145.06507460776217,  -37.85577842380955",Glen Iris (Vic.)
"141.6457814570232,  -37.82530987214925",Grassdale (Vic.)
"142.54665608409533,  -38.24791988422317",Grassmere
"145.10713261268583,  -37.69869762689969",Greensborough
"141.79788182415717,  -37.56677437949485",Gritjurk
"146.14387600056068,  -38.01238397220369",Hill End (Vic.)
"147.5160450992548,  -37.84070819427939",Hillside (East Gippsland - Vic.)
"145.10847507059137,  -37.907637080118775",Huntingdale (Vic.)
"142.6914612427635,  -37.067434112775445",Illawarra
"144.22937589921307,  -37.14915507274139",Irishtown (Vic.)
"147.19528631241317,  -36.18164095327489",Jarvis Creek
"145.75921547509938,  -38.4717519486097",Jumbunna
"143.23431792149805,  -38.09796041559169",Kariah
"141.8147155295938,  -38.09120224554197",Lake Condah
"147.99966759828794,  -37.85340126846656",Lakes Entrance
"146.67762641804865,  -38.64962255939582",Langsborough
"144.76707758814058,  -37.85936237911011",Laverton (Vic.)
"143.4839598156319,  -36.627795388145124",Logan
"141.77233295999852,  -36.146256865500305",Lorquon
"144.32233948622886,  -38.040604419235144",Lovely Banks
"143.65516120480729,  -38.77833896317618",Marengo (Vic.)
"145.15019739992994,  -36.3682304080887",Merrigum
"144.85895939384525,  -36.802801679826",Moormbool West
"144.11020779327387,  -37.64828956661306",Mount Egerton
"145.12849289843382,  -37.87837975488066",Mount Waverley
"142.73735043045392,  -37.29093452269771",Moyston
"143.88256561190016,  -35.50999734865726",Murrabit West
"143.5131895020994,  -35.29934321220065",Murrawee
"144.01995714952508,  -35.600726453091724",Myall (Gannawarra - Vic.)
"144.1828177248488,  -36.705388051380964",Myers Flat
"145.6341969070614,  -37.530857674301075",Narbethong
"142.5437340161048,  -37.834424204088734",Nareeb
"145.31383587452606,  -37.981948537820045",Narre Warren North
"145.9608786302358,  -37.955033379215834",Neerim
"144.33507509073644,  -38.15390788904781",Newtown (Greater Geelong - Vic.)
"149.2153081749433,  -37.51545776617029",Noorinbee
"147.6165805843438,  -36.94446711757548",Omeo Valley
"142.96790404580634,  -38.553736917072506",Paaratte
"144.58982265399587,  -37.2142762669206",Pastoria
"145.24778111226829,  -38.19783470942532",Pearcedale
"145.05049682343676,  -35.96287073522822",Picola West
"146.26902151761303,  -38.684859293153494",Port Franklin
"143.47537854826203,  -36.3969562594344",Richmond Plains
"147.07723361977403,  -38.10376234803463",Sale
"143.68943899888995,  -35.738573137969304",Sandhill Lake
"143.66695913336483,  -37.67946664818208",Scarsdale
"145.16217347136936,  -37.0159727984082",Seymour (Vic.)
"143.93048467152622,  -37.96798722112851",Shelford
"145.03159633272773,  -38.4211262854041",Shoreham
"143.24848680756605,  -36.61783875716633",St Arnaud
"145.88765929286555,  -36.257438277232545",St James (Vic.)
"144.98176825127064,  -37.86360422700774",St Kilda (Vic.)
"144.98194373962747,  -36.46266613609578",Stanhope (Vic.)
"144.36137185243018,  -36.98777494020188",Sutton Grange
"147.86876317223445,  -37.814270513245134",Swan Reach (Vic.)
"145.16996914487154,  -36.844537660197865",Tabilk
"147.8324615699725,  -37.49813451180934",Tambo Crossing
"146.56240258424884,  -38.46880300301271",Tarra Valley
"145.90383259953677,  -38.76481333326",Tarwin Lower
"141.1078448036926,  -36.01988842255417",Telopea Downs
"145.6898342454263,  -37.848808759392874",Three Bridges
"141.88896315195473,  -37.01954794142907",Toolondo
"148.5949034482789,  -37.05774598435252",Tubbut
"143.8880127980229,  -37.27627484710761",Ullina
"145.1950552057919,  -38.492306517916774",Ventnor (Vic.)
"145.79030884491803,  -38.67624856299133",Venus Bay (Vic.)
"145.31200384011038,  -36.04564694876674",Waaia
"141.29410235985523,  -37.430453806199594",Warrock
"141.56221779681,  -34.40887235989036",Werrimull
"146.82184194917963,  -36.110942113916614",West Wodonga
"145.62090010056565,  -37.21752511484627",Whanregarwen
"146.41343857845874,  -36.75267595907482",Whitfield (Vic.)
"143.9561127439242,  -38.23579895526819",Winchelsea
"143.32968955205953,  -35.10112011712378",Wood Wood
"147.04462573378837,  -37.697832913824634",Woolenook
"151.14773451697832,  -25.136771504313376",Abercorn
"152.96932923454145,  -27.354446786397173",Albany Creek
"152.52162349189985,  -26.377045756378084",Amamoor Creek
"145.4113695472454,  -22.44428620786304",Aramac
"152.79070110226579,  -27.22142204243991",Armstrong Creek (Qld)
"152.5565678103734,  -27.62541397641358",Ashwell
"151.7708279159648,  -27.613531238816005",Athol
"153.309230005037,  -28.154504303706634",Austinville
"152.30587094377202,  -24.880568015453083",Avoca (Qld)
"151.94561562956073,  -24.486571309122496",Baffle Creek
"153.19212804155217,  -27.75983046613359",Bannockburn (Qld)
"151.948371537538,  -26.64804701138573",Barker Creek Flat
"151.88755186649047,  -26.202607910063897",Barlil
"149.32268109900235,  -24.565882278990628",Bauhinia
"146.64782620773315,  -19.20446464359961",Beach Holm
"143.15921200473292,  -19.36228555088007",Bellfield (Qld)
"153.16503203749795,  -27.99030346640727",Benobble
"145.43321153047668,  -16.866974690784954",Biboohra
"146.09035677924163,  -17.826227545545493",Bingil Bay
"146.1449055019908,  -20.164349664812903",Black Jack
"146.59815906429105,  -19.262057753316096",Black River (Qld)
"141.72589933287674,  -17.86871626277779",Blackbull
"146.21214628345308,  -18.708468310362175",Blackrock
"146.01555393276738,  -17.608708012107083",Boogan
"149.85000892506025,  -23.692747824647657",Boolburra
"153.0247319391849,  -27.468981232255317",Brisbane City
"152.89755100990405,  -27.66036623812891",Brookwater
"152.2973283491302,  -27.36845564151521",Buaraba
"152.58513605772595,  -25.32585555830963",Burrum Town
"145.6993291349137,  -17.336080870740027",Butchers Creek
"153.09122972974598,  -27.50711022379337",Carina Heights
"152.45067446624805,  -28.078621335512473",Clumber
"152.6926244979035,  -26.741556754817843",Conondale
"146.26143292219535,  -18.90568207143505",Coolbie
"147.06460874282595,  -19.465624160214034",Cromarty
"152.59715395735478,  -26.06960312867697",Curra
"147.2931539535934,  -20.221130176952446",Dalbeg
"151.08310804072377,  -24.249766542263604",Diglum
"145.07137273385987,  -17.180013403850324",Dimbulah
"151.2694595045481,  -27.932306850562135",Domville
"152.98561141433254,  -27.613201430116213",Doolandella
"150.03552159336266,  -23.99279857277944",Dumpy Creek
"151.2577541672504,  -26.38316296620266",Durong
"152.27556718899845,  -25.006634846190817",Elliott (Qld)
"143.7701031604101,  -9.587093911281066",Erub Island
"152.34531160663016,  -24.768852730026932",Fairymead
"145.95596428653297,  -17.877979916364684",Feluga
"153.0356819670868,  -27.456787691556368",Fortitude Valley
"152.91351962476372,  -27.61184710216674",Gailes
"152.93791356501578,  -26.90543068000246",Glass House Mountains
"152.89313626577388,  -27.611177810862728",Goodna
"145.97504006421136,  -16.75981906830446",Green Island
"153.0479727513329,  -27.50910489329448",Greenslopes
"152.63321872090117,  -27.564433062415198",Haigslea
"142.89710646322132,  -16.305351971529298",Highbury (Qld)
"145.99445208350232,  -17.783309267102222",Jaffa
"151.1677686440018,  -26.736229318905885",Jandowae
"152.36590638937744,  -27.630553832784027",Laidley Heights
"152.51423724384483,  -26.530044065637615",Lake Borumba
"152.1416675396353,  -27.610920986450438",Lilydale (Qld)
"153.09223431514226,  -26.78434898300557",Little Mountain
"145.6721874163737,  -25.286744525031065",Lumeah (Qld)
"152.6892168370922,  -28.190495150288147",Maroon
"141.2185315430833,  -21.36882337811298",McKinlay
"150.74569483119564,  -23.114811379281647",Meikleville Hill
"146.26023686611046,  -20.09927555622872",Mosman Park (Qld)
"145.8573331681498,  -18.82109826332002",Mount Fox
"152.73559039031022,  -28.330351403574305",Mount Lindesay (Qld)
"148.63019568674338,  -20.3522826408085",Mount Marlow
"152.84225703737377,  -27.302998160905606",Mount Samson
"146.77179835370532,  -19.37641552337056",Mount Stuart (Qld)
"150.78741000192323,  -23.189003996925216",Mulambin
"151.45250005514103,  -25.26242657532164",Mungy
"150.6835611544715,  -23.41769188780467",Nankin
"153.06263609586486,  -27.402952340150854",Nundah
"152.99737465073727,  -27.460592322134236",Paddington (Qld)
"153.0624076014177,  -27.70544232797187",Park Ridge
"152.58191367876483,  -27.418448354526134",Patrick Estate
"152.10499603108258,  -27.371175681903217",Perseverance
"149.93928884058548,  -26.896160531965283",Pine Hills
"150.54056770278697,  -23.419931046956886",Port Curtis
"152.85035391131808,  -27.65117714123268",Redbank Plains
"151.93804858151162,  -27.536062625764373",Rockville
"151.8326659193622,  -28.222390611881156",Rodgers Creek
"153.39768252994327,  -27.913963622115567",Runaway Bay
"149.11740088403238,  -21.55328242889876",Sarina Range
"152.5284493259341,  -26.009990473022288",Scotchy Pocket
"140.4864941264765,  -21.87657299353496",Selwyn (Qld)
"151.96168310749724,  -25.044888949999073",Skyring Reserve
"153.13403139292657,  -27.641484961110127",Slacks Creek
"145.9936677557702,  -17.597411212506714",South Johnstone
"151.29575844787342,  -23.88259485456176",South Trees
"153.0103211628641,  -27.409523357459605",Stafford
"150.3073417722114,  -23.510804790230853",Stanwell
"151.97972443133145,  -28.72706365768308",Storm King
"153.42328573929603,  -28.00255492704694",Surfers Paradise
"152.3330175005761,  -24.88642317380306",Svensson Heights
"147.161346525584,  -20.096400813349792",Swans Lagoon
"153.04671982757117,  -27.342413728886175",Taigum
"153.3263239428051,  -28.06424573341291",Tallai
"149.72050679289023,  -28.09710560941995",Tarawera
"150.49560027555043,  -23.39270211646003",The Range (Qld)
"146.81693005125106,  -19.258984860683512",Townsville City
"151.77125599985104,  -27.660782839000536",Umbiram
"145.32481571937723,  -17.315318649284382",Watsonville
"150.6692842739728,  -22.78826870709614",Weerriba
"148.44050669548034,  -25.440351585824484",Westgrove
"151.80387725509,  -26.00928493729367",Windera (Qld)
"152.567159960492,  -27.3684402957324",Wivenhoe Hill
"145.74638470931274,  -16.959318672450518",Woree
"138.3513149636648,  -32.60532711333651",Amyton
"137.1649884233322,  -30.453583752853394",Andamooka
"136.56820167171998,  -33.866828127855065",Arno Bay
"134.3749868178667,  -33.14063422023256",Baird Bay
"140.46705364347991,  -34.23333585653244",Barmera
"138.65636777544876,  -30.747371242978023",Beltana Station
"138.59458084740896,  -35.16862844106516",Blewitt Springs
"137.5146700083387,  -34.864372043610686",Brentwood (SA)
"138.39106190044697,  -33.15461055981934",Caltowie West
"134.19792505875,  -32.32496749473391",Carawa
"138.76551610107964,  -34.87160923402163",Castambul
"138.92291222425783,  -34.91388881198697",Charleston
"138.4777993980048,  -35.134866204527235",Christies Beach
"138.59466650666883,  -34.983548592458156",Colonel Light Gardens
"140.92505563433406,  -37.219729658092476",Comaum
"139.53726908216646,  -35.40799447433181",Cooke Plains
"135.23918289483467,  -33.45119907626336",Coolillie
"136.33949201838658,  -33.03677238986879",Cortlinye
"139.92556994186495,  -35.80381001065612",Culburra
"140.17933011744807,  -34.11157755364722",Devlins Pound
"138.7279902689712,  -34.64865797100606",Evanston South
"138.58673536813538,  -35.04918176013874",Flagstaff Hill
"135.63659800558997,  -34.59856989247821",Fountain
"138.72702301892716,  -34.572745005352836",Gawler Belt
"140.7793209219313,  -37.21005985691584",Glenroy (SA)
"140.28918523765722,  -37.48828396657327",Hatherleigh
"140.0694173049739,  -35.41305192551001",Jabuk
"138.75356864966585,  -35.11805527302432",Jupiter Creek
"137.95377799106194,  -34.14412868407374",Kainton
"133.64254174660087,  -31.9107767869063",Kalanbi
"135.52013064814713,  -34.151780008678095",Kapinnie
"138.63857800356772,  -34.87898707862472",Klemzig
"140.74188415289407,  -34.917203285089684",Kringin
"138.47303156831825,  -30.542748921999618",Leigh Creek Station
"138.3498946065791,  -30.288229602620927",Lyndhurst (SA)
"135.93843014353885,  -27.022724525981896",Macumba
"138.64603483059457,  -34.928541023223026",Marryatville
"137.97786677003813,  -34.075486482789195",Melton (SA)
"137.5700712181152,  -34.057947124848155",Moonta Bay
"138.95094024692565,  -34.413698526011856",Moppa
"136.49912435305316,  -33.05196512320915",Moseley
"137.5335321092481,  -33.0576179185758",Mullaquana
"138.09959979040735,  -33.61894166025511",Mundoora
"137.5456504784388,  -34.16327665357647",Nalyappa
"138.67892179422202,  -35.34517556367751",Nangkita
"138.52328500887117,  -34.25045104629679",Owen
"140.520081453578,  -36.59498865124314",Padthaway
"136.09940895313682,  -33.19878797546741",Panitya (SA)
"138.3378459903021,  -35.562917619438736",Parawa
"138.6422040650313,  -34.8989420922524",Payneham
"138.49914556272495,  -34.83121599304553",Peterhead
"140.76704530087812,  -34.27463174408802",Pike River
"139.26802864172916,  -33.21174561567339",Pine Creek (SA)
"138.43319755774914,  -34.299075940018774",Pinery
"140.7478338127388,  -34.16781124584774",Renmark
"138.4986317358446,  -33.69923464019348",Rochester (SA)
"139.12055444593307,  -34.72165549569957",Springton
"138.76222062598245,  -33.88593457486703",Stanley (SA)
"139.34728449415525,  -34.33524829833509",Steinfeld
"137.75065215979063,  -35.10916803486125",Sultana Point
"138.80109600775285,  -33.031521108442746",Sunnybrae
"137.17396416611032,  -34.94000063398906",The Pines (SA)
"138.59681888155728,  -34.89735047194825",Thorngate
"137.9402850842367,  -34.40137424262812",Tiddy Widdy Beach
"140.06764059002043,  -35.87529267743515",Tintinara
"139.07212985752992,  -34.86761818298655",Tungkillo
"133.35812377241314,  -31.96513694406788",Uworra
"138.612053156678,  -35.54792174529193",Victor Harbor
"137.68488930650727,  -33.87037422013095",Wallaroo Plain
"138.50935057260176,  -34.94496419697885",West Beach (SA)
"138.5503648107929,  -34.93938843109025",West Richmond
"138.8960891581275,  -34.66894978985523",Williamstown (SA)
"118.27286504391353,  -34.25720739787813",Amelup
"117.08001310288138,  -20.648495733751652",Antonymyre
"116.00688867179366,  -32.15116342459927",Armadale (WA)
"116.23229716080851,  -34.2031638414513",Balbarrup
"115.6656748489768,  -33.90136178382415",Barrabup
"114.60186403102031,  -28.786085811280312",Beachlands
"114.61990620104127,  -28.75633885389363",Beresford
"118.84718855077776,  -30.953697475696824",Boodarockin
"115.97354896702666,  -31.827016297527877",Brabham
"122.9004133573259,  -32.933588128928584",Buraminya
"115.88949459749463,  -28.88399945515107",Canna
"121.90803184951369,  -33.833403433823875",Castletown
"121.88504385362263,  -33.837997452778914",Chadwick
"115.86200187957212,  -33.00106783755851",Cookernup
"117.0719855777242,  -20.966004820366496",Cooya Pooya
"117.44525577150998,  -31.005716414791973",Cowcowing
"116.73782855084242,  -32.23062141723399",Dale
"115.63338046559151,  -32.633259533806935",Dawesville
"117.06767538797271,  -31.212864595573365",Dowerin
"117.77025285048477,  -34.93781787616929",Drome
"117.2716364406456,  -24.30663008119387",East Lyons River
"115.18996691545593,  -28.397457734736683",East Yuna
"119.54325820284119,  -31.21860241265947",Ghooli
"116.8356421814503,  -32.003958852614865",Gilgering
"117.82844534242261,  -35.007179149889765",Gledhow
"116.72178178621915,  -31.53629335069805",Jennapullin
"115.80075618065518,  -31.81031776893315",Kingsley
"117.61488259070978,  -35.03747351549216",Kronkup
"116.11323875465862,  -34.161667365903845",Linfarne
"119.28088459789655,  -33.41292515337319",Magenta (WA)
"117.83006268606708,  -34.985294566315794",McKail
"116.87896034398251,  -33.61501843310132",Mokup
"114.76771063432312,  -28.78009566106387",Moonyoonooka
"119.15254007639908,  -31.357692615019452",Moorine Rock
"116.30860794788441,  -32.608015960194855",Mount Wells
"115.72290430600503,  -33.02200504929725",Myalup
"115.81549441226893,  -32.041342002147736",Myaree
"114.77364194713326,  -28.497036323792436",Nabawa
"120.87122498603988,  -32.933020572698645",North Cascade
"115.7924746755397,  -32.05961834463353","[""O'Connor (WA)""]"
"116.39627857779716,  -30.811870069403955",Piawaning
"116.07634606032212,  -31.95967869250511",Piesse Brook
"117.16747765056962,  -20.6240738296554",Point Samson
"115.02473706093623,  -34.01976029315427",Redgate (WA)
"115.191811233267,  -33.94929323653423",Rosa Brook
"115.48352123121158,  -33.650469687372095",Ruabon
"115.73099636087908,  -32.30447970470571",Safety Bay
"115.80641912221789,  -31.741248834154764",Sinagra
"121.5014727064348,  -30.797419155054374",South Boulder
"117.86357463467284,  -33.50451338782755",South Datatine
"117.66258271608159,  -33.52584343434407",South Glencoe
"116.82277692926797,  -31.709274611080897",Throssell
"121.53025589559225,  -30.781732663436163",Trafalgar (WA)
"115.61705447613224,  -31.485508618946984",Two Rocks
"118.45281806230575,  -32.010951014669146",Wadderin
"116.76351177115272,  -34.973720229010034",Walpole
"118.7279478102679,  -30.994225918721128",Warrachuppin
"115.76251403532049,  -33.32802745183025",Waterloo (WA)
"119.38590941421468,  -33.71943679843149",West Fitzgerald
"115.8042212701668,  -32.665895267522814",West Pinjarra
"118.7059332504605,  -31.239159526129388",Westonia
"116.01138425921266,  -32.27188594908413",Whitby
"118.3936735751306,  -30.764203716747183",Wilgoyne
"115.79642435869941,  -31.789369757805673",Woodvale (WA)
"116.51438770547558,  -31.153673508351154",Wyening
"114.22394883880936,  -27.365178417096402",Zuytdorp
"147.36317257611813,  -43.12107661286116",Barnes Bay
"146.82602946583242,  -41.873623805188394",Cramps Bay
"147.08718204954556,  -41.7837016893228",Cressy (Tas.)
"146.54291216545235,  -41.493830406885394",Dunorlan
"146.47522700843186,  -42.58388893959596",Florentine
"147.45320776188225,  -41.1027717454989",Jetsonville
"146.92943976745633,  -42.96358477989775",Judbury
"146.4938747320115,  -41.4177099908965",Kimberley (Tas.)
"148.23766036594384,  -40.21296225159685",Lady Barron
"147.18067819589493,  -41.25585233065675",Lalla
"147.82167356707723,  -39.893849331161164",Leeka
"147.1552292289743,  -42.97212889172326",Lower Longley
"143.99654379424183,  -40.02970358432036",Lymwood
"146.06327742512462,  -41.30028028696877",Preston (Tas.)
"145.55782840443638,  -40.96503220285341",Sisters Creek
"147.20944305208008,  -43.077778429647935",Snug
"146.35240693327634,  -41.256607575244914",South Spreyton
"147.7772006640542,  -43.18537967659234",Stormlea
"146.01304397629656,  -41.10961346087681",Sulphur Creek
"146.80473638965876,  -41.976147590565155",Tods Corner
"147.1087211066118,  -41.02585488858278",Weymouth
"131.1459375030621,  -12.747729427731798",Acacia Hills (NT)
"133.0893350780173,  -14.483860399577626",Beswick Creek
"130.8680667089271,  -12.37051811895507",Brinkin
"130.80211766265856,  -17.419248127112606",Daguragu
"130.84365888418446,  -12.461810978180086",Darwin City
"131.05108111467047,  -12.897233244851439",Darwin River Dam
"135.66234691903253,  -12.845982028870402",East Arnhem
"132.7623675413675,  -22.54342221600932",Laramba
"130.8494831341888,  -12.414633551499408",Ludmilla
"130.8885156616366,  -12.359112012112098",Lyons (NT)
"133.90395985027658,  -23.744259563293628",Ross (NT)
"149.158729149764,  -35.365918655211146",ACT Remainder - Jerrabomberra
"148.9667571582711,  -35.44471843478576",ACT Remainder - Paddys River
"149.14918551068962,  -35.335672231760505",Narrabundah
"149.11284707082888,  -35.352538844112054","[""O'Malley""]"
"149.08344655103838,  -35.36225768776998",Pearce
"149.08778834890688,  -35.372081280797005",Torrens
"149.05880964838167,  -35.33257354841788",Weston (ACT)
"149.55660150187595,  -33.390016371682414",Abercrombie
"151.4022677326242,  -32.88756557598544",Abernethy
"152.06639740955828,  -28.624098618579968",Amosfield
"150.9476010898147,  -33.657574889168856",Annangrove
"151.32693753550112,  -33.62959767507548",Avalon Beach
"151.98810791631092,  -29.678700780286878",Bald Nob
"149.64016984023274,  -35.61870736448223",Ballalaba
"150.58707823890023,  -34.28404720844662",Bargo
"150.57866850563033,  -34.780530195586856",Beaumont (NSW)
"148.71071249181747,  -36.54822715774079",Beloka
"147.24652955250176,  -34.70681576392966",Berry Jerry
"151.60771564652106,  -32.821065253515485",Black Hill (NSW)
"148.97706425864345,  -36.460005088620385",Bobundara
"148.84667131826845,  -35.92225238722491",Bolaro
"146.04220529130808,  -30.207249845129994",Bourke
"150.7014673221457,  -31.573608431033747",Braefield
"150.6245859271631,  -31.952098393816485",Brawboy
"153.4261281753933,  -29.03504539611372",Broadwater (Richmond Valley - NSW)
"151.0924983158798,  -32.77583082382234",Broke (NSW)
"150.6528394751528,  -34.047410256815034",Brownlow Hill
"152.01709483957725,  -31.919302814695218",Bulliac
"149.02472668808997,  -33.59025639862152",Burnt Yards
"150.51331773150412,  -34.582645806856725",Burrawang
"149.0031723431075,  -33.35543591606034",Canobolas
"153.53720624451717,  -28.254526868421426",Chinderah
"152.9151108096762,  -29.153392169731873",Clearfield
"149.17830997481144,  -33.245010996085675",Clifton Grove
"150.57306345457638,  -30.19689194432981",Cobbadah
"150.094741581909,  -36.00959850644506",Coila
"150.7966991949016,  -33.78277426868839",Colyton
"151.06444882544827,  -34.002964923053774",Como (NSW)
"148.0896022430151,  -35.339602707711876",Cooleys Creek
"149.22136781069895,  -33.876898056711376",Copperhannia
"151.1154661069611,  -33.88031885294406",Croydon (NSW)
"149.3564100508184,  -34.780909139062054",Cullerin
"152.4591328171937,  -29.801454498233177",Dalmorton
"151.2368551775599,  -33.872335707706924",Darling Point
"150.8209003160437,  -32.15118373563823",Dartbrook
"150.8125194148,  -34.03760836274951",Eagle Vale
"151.61992791161143,  -32.923013573665614",Edgeworth
"151.73377258804788,  -30.169507778356472",Falconer
"151.46693648552588,  -33.40677686585672",Forresters Beach
"148.8539162542125,  -32.37490511454764",Geurie
"150.49844685856195,  -29.73450120609965",Gineroi
"146.86092598457734,  -31.235284184951674",Girilambone
"151.79534929886725,  -32.51145035356999",Glen William
"151.28506996398602,  -32.58686368735478",Glendon
"151.61965410108078,  -32.066169922256904",Gloucester Tops
"146.2819828993599,  -34.58499062462526",Gogeldrie
"151.15356340726126,  -33.7555125790332",Gordon (NSW)
"152.985777182468,  -30.784420597602942",Grassy Head
"151.16216019941956,  -34.03704176116225",Greenhills Beach
"148.46206819624476,  -36.56363600238114",Grosses Plain
"145.29688827021764,  -33.93617583516448",Gunbar
"150.47556177675284,  -32.264392461615806",Gungal
"152.50150510959207,  -32.066447068707106",Hallidays Point
"150.87410943648246,  -31.011067300407426",Hallsville
"151.2481821371621,  -31.503500657146798",Hanging Rock
"151.71732925127625,  -32.95584463964932",Highfields (NSW)
"149.44656609369463,  -33.02595883359127",Hill End (NSW)
"151.0387075386101,  -33.999192296520874",Illawong
"149.65893142813655,  -33.94964527586318",Isabella
"149.88122935540244,  -36.72425446328735",Jellat Jellat
"149.84500039133624,  -34.160156059822036",Jerrong
"149.9307496180258,  -36.72998835099778",Kalaru
"152.73751577057473,  -31.643766915200793",Kew (NSW)
"149.42546789111483,  -33.1871694302687",Killongbutta
"148.33628644505646,  -34.44966366188389",Kingsvale
"151.364677524342,  -32.88068907141963",Kitchener
"146.10259650812733,  -34.42543113701208",Kooba
"153.11666808671797,  -30.24958138493117",Korora
"151.57328702085155,  -33.1902880748457",Lake Munmorah
"152.51465728310728,  -31.761273797845814",Lansdowne (Mid-Coast - NSW)
"150.1976981432717,  -33.58564251551308",Little Hartley
"151.1690616904188,  -33.82921619517626",Longueville
"150.66138774064584,  -34.56238752380141",Macquarie Pass
"150.87272422966237,  -34.432771404897636",Mangerton
"151.40139528373933,  -33.29364152306094",Mardi
"149.54452371744355,  -32.53420029664398",Menah
"149.15982038469735,  -37.050899467476206",Mila
"151.1783318615131,  -33.515785656960226",Milsons Passage
"152.90550705759605,  -29.57111643941426",Moleville Creek
"150.46960164258817,  -35.34111348277277",Mollymook
"149.16628444312576,  -32.792121772140355",Mookerawa
"150.61202985494666,  -34.09922498220597",Mount Hunter
"150.57034891132665,  -34.45145380330458",Mount Lindsey
"150.38811832032644,  -33.504104722330936",Mount Wilson
"142.16172984369433,  -34.141540130323314",Mourquong
"147.92934696279275,  -35.02166922783358",Nangus
"151.3485476049839,  -33.376409946129776",Niagara Park
"151.56124345340749,  -33.28527102924408",Norah Head
"151.36055918646903,  -32.80196584191341",Nulkaba
"150.96993239921306,  -33.78774809697275",Old Toongabbie
"148.27269929715047,  -33.1018918099476",Parkes (NSW)
"149.53654078802046,  -34.53355476337593",Pejar
"152.09418534446303,  -32.656764199372944",Pindimar
"147.94309621773124,  -33.74001633553512",Pinnacle (NSW)
"149.97286097471135,  -33.3565236058861",Portland (NSW)
"151.03245353284083,  -33.89270640961444",Potts Hill
"151.14621580637322,  -33.98215959762801",Ramsgate Beach
"149.2598133189164,  -36.12406097659705",Rose Valley (Snowy Monaro Regional - NSW)
"145.35259642057366,  -32.99185194330753",Roto
"150.9943926352689,  -32.14832063721495",Rouchel
"151.24762142766178,  -32.57245849502127",Roughit
"149.01227088083044,  -34.38511818455033",Rugby
"153.17896148099487,  -30.14429452460102",Sandy Beach
"151.04621113557778,  -33.8341644420648",Silverwater (Parramatta - NSW)
"152.9387688195375,  -29.72803983279078",South Grafton
"149.60561068740247,  -32.67057837003128",Spring Flat
"151.65917776574938,  -33.09336203473536",Swansea Heads
"147.43872023480938,  -35.93968855592825",Talmalmo
"149.86950257454154,  -36.67232203638008",Tarraganda
"147.51269670781082,  -34.44814541790034",Temora
"148.36180802470616,  -36.52903703017333",Thredbo
"150.9485792085505,  -33.79034754326366",Toongabbie (NSW)
"152.76097976093732,  -28.52972445963584",Toonumbar
"149.50561180960185,  -31.261263742171977",Ulamambri
"152.32091267577587,  -32.451162894372",Violet Hill
"152.49010512624042,  -32.25258476785443",Wallis Lake
"149.76003428562248,  -33.50459404448083",Wambool
"145.0296987602234,  -35.42339806631156",Wandook
"153.38162889854632,  -28.56658807117397",Wanganui
"152.97548299159692,  -29.56291235685706",Warragai Creek
"150.93998493216216,  -33.953417103365425",Wattle Grove (NSW)
"147.9647266895661,  -36.0113598424495",Welaregang
"151.1639797424806,  -33.4559043420444",Wendoree Park
"150.36439409741345,  -34.61045483675743",Werai
"150.98079553756241,  -33.7765657804913",Winston Hills
"152.7574185542425,  -29.515987993675573",Wombat Creek (NSW)
"153.31949551221163,  -28.7638491656312",Woodlawn
"153.36676891960772,  -29.36725126730417",Woody Head
"151.3215495767735,  -30.958388233141193",Woolbrook
"151.5076732104308,  -33.14582482380486",Wyee Point
"150.57141225095754,  -34.31736516638772",Yanderra
"145.3733643622503,  -36.53837382765234",Arcadia (Vic.)
"145.38112553984774,  -36.59376694029169",Arcadia South
"142.75039641840237,  -35.97215497325687",Ballapur
"145.12037760406722,  -38.39177933014015",Balnarring Beach
"146.00356633306194,  -36.91876608596334",Barjarg
"146.67334254389732,  -36.078904140177734",Barnawartha
"143.664617972778,  -38.18126861820799",Beeac
"145.34357856302768,  -37.926245112812914",Belgrave Heights
"145.73584730956577,  -38.44015380132552",Bena
"147.80701119621477,  -36.88464530297877",Benambra
"142.75977797947317,  -35.340380966247466",Bimbourie
"144.10954519734744,  -37.511968836386146",Bolwarrah
"147.08708790696974,  -37.80482536309041",Briagolong
"143.90699274140493,  -37.38436264725309",Broomfield
"142.0867952668843,  -37.858883901512804",Buckley Swamp
"143.52091312822432,  -36.269445017844234",Buckrabanyule
"144.33829339894785,  -35.82996010912149",Burkes Bridge
"145.07371522466963,  -37.83839806069382",Camberwell (Vic.)
"143.73914354654718,  -37.539677768039915",Cardigan
"146.33805973414,  -37.947900900979285",Caringal
"145.12795464913296,  -38.076764097193305",Carrum
"143.0434381083506,  -35.27373935725583",Chillingollah
"141.25467891282054,  -37.540798059628344",Corndale (Vic.)
"145.2519180234259,  -38.15440857635375",Cranbourne South
"145.21192033681191,  -37.985017861695326",Dandenong
"145.21425768924823,  -37.955883683141174",Dandenong North
"146.7035245656096,  -36.839715164133466",Dandongadale
"144.15003848802618,  -37.34467979990635",Daylesford
"144.29178832013037,  -37.28449133617111",Denver
"144.94035628899263,  -37.81830040036869",Docklands
"145.59227772406823,  -37.73637352825936",Don Valley
"143.2010252284927,  -36.378751146593274",Dooboobetic
"141.04813295686412,  -37.31190260611067",Dorodong
"141.89523879627484,  -36.62110979341148",Duchembegarra
"143.97220904871654,  -37.5940719921376",Dunnstown
"147.03393452321825,  -36.16142668567378",Ebden
"146.52164134639298,  -36.75188315950364",Edi Upper
"145.63836280330932,  -37.61611248771108",Fernshaw
"143.81055280252826,  -36.41137595404838",Fiery Flat
"144.98436770518853,  -37.783916657095496",Fitzroy North
"143.29467405336936,  -37.239015551256216",Glenlogie
"144.35497679142813,  -35.94940946298863",Gunbower
"142.71377395487949,  -34.70168152850358",Happy Valley (Swan Hill - Vic.)
"141.62530617761573,  -38.21290010071946",Heathmere
"147.6537562139811,  -37.012519880918006",Hinnomunjie
"142.35200638210878,  -35.70895702841803",Hopetoun (Vic.)
"143.18857784944103,  -38.41833691248819",Jancourt East
"147.93940361248295,  -37.81448737338663",Kalimna West
"144.3137576152607,  -36.404022393744164",Kamarooka North
"145.49185153128113,  -36.530540314087254",Karramomus
"143.61280517371293,  -38.496831968267344",Kawarren
"145.25016447213676,  -37.47868588954931",Kinglake West
"143.1195979025822,  -34.857850226961865",Kooloonong
"142.0457829136772,  -34.31500819902496",Koorlong
"144.04408741310033,  -37.30051120098956",Kooroocheang
"145.793318934442,  -38.02987095774042",Labertouche
"142.82775507075746,  -36.435754925295115",Laen
"142.62526935900368,  -37.03348935018665",Lake Lonsdale
"145.0128368587316,  -37.66672482137792",Lalor
"147.56250180883274,  -38.05956475439063",Loch Sport
"142.34198629116256,  -36.58488908509153",Longerenong
"147.09250532248595,  -38.21711116738075",Longford (Vic.)
"145.4559351488433,  -36.83824466528666",Longwood East
"147.6083491046259,  -36.30288406126944",Lucyvale
"145.23075880097625,  -38.068547461534216",Lyndhurst (Vic.)
"145.85016204631668,  -37.70023922979333",McMahons Creek
"144.11499831601267,  -35.81084457126659",Mead
"144.91857130214854,  -37.651163156268936",Meadow Heights
"145.0693990412568,  -37.98158738309305",Mentone
"144.96203742923856,  -37.851136568315425",Middle Park (Vic.)
"143.32222684594817,  -37.84431619516756",Mingay
"145.04696288748008,  -36.83579746506102",Mitchellstown
"144.23971741887672,  -36.21545057026005",Mitiamo
"146.35334521115453,  -38.047837655192055",Moondarra
"144.28334248083925,  -38.04906937416943",Moorabool
"145.11901472267155,  -38.24700072909648",Moorooduc
"142.80892625543237,  -38.05769549240248",Mortlake (Vic.)
"146.43561394811076,  -37.26082996168615",Mount Buller
"145.50842766275898,  -37.974239830439295",Mount Burnett
"144.7468486752522,  -36.80776751126324",Mount Camel
"144.20970592136266,  -37.73030338221414",Mount Wallace
"146.8650287313251,  -36.49479482833455",Mudgegonga
"141.0887483192666,  -37.92109071220905",Mumbannar
"146.74000516843492,  -36.57300606089064",Myrtleford
"143.7320735622692,  -35.56488999941856",Mystic Park
"143.066933190502,  -38.25224329003693",Naroghid
"141.93585818247922,  -36.73529216258919",Natimuk
"143.9281167858151,  -36.918488893660324",Neereman
"146.05362262030482,  -37.99618532991153",Neerim East
"143.44599835050346,  -37.497917635848424",Nerring
"143.01048269290897,  -38.56253279526324",Newfield
"142.21457840812602,  -34.21194958359195",Nichols Point
"146.344166209126,  -36.32588468129436",North Wangaratta
"145.17648638898538,  -37.81745413514367",Nunawading
"145.094278928919,  -37.92441582098494",Oakleigh South
"141.35654203800672,  -36.83011856724234",Ozenkadnook
"142.72232332409908,  -38.33162408404563",Panmure
"147.83275820061732,  -36.01191211332346",Pine Mountain (Vic.)
"145.54947511473767,  -38.38066717852907",Pioneer Bay
"144.60197858548335,  -38.26802225972241",Point Lonsdale
"141.60370471228103,  -38.36125544103191",Portland (Vic.)
"143.87576736342828,  -35.66386222889379",Reedy Lake
"142.45618983349348,  -36.974182830043524",Roses Gap
"142.67182787812953,  -36.632442248636416",Rupanyup
"145.41067568107968,  -38.52806862309651",San Remo (Vic.)
"143.83568840388338,  -37.60021440452997",Sebastopol (Vic.)
"143.95657273905985,  -37.30371470841821",Smeaton
"143.85859696621753,  -37.549533984750056",Soldiers Hill (Vic.)
"144.36128702652294,  -38.16680027798403",South Geelong
"144.99191391379802,  -37.839281693526964",South Yarra
"144.82021088617975,  -37.33139676912169",Springfield (Macedon Ranges - Vic.)
"144.80506663054683,  -37.7451057573405",St Albans (Vic.)
"147.71010630236694,  -37.45186500721713",Stirling (Vic.)
"143.08617516306464,  -37.640237451877496",Streatham
"143.79739394534758,  -38.69099266708533",Sugarloaf (Vic.)
"145.28970338750494,  -38.50962198802908",Surf Beach (Vic.)
"143.40385613660357,  -35.365971527257805",Swan Hill West
"147.22386465653148,  -36.400627685428674",Tallandoon
"147.8704082008502,  -37.765126269879325",Tambo Upper
"144.21165294044619,  -36.43325982422954",Tandarra
"142.69704020352998,  -38.490334571549",The Cove
"144.87806519400831,  -36.34787055468781",Timmering
"143.68980154548112,  -35.504268148116196",Tresco
"145.09331116660377,  -37.739792029136396",Viewbank
"144.1620229217167,  -36.97491487229934",Walmer (Vic.)
"145.22204918281028,  -37.87391760832726",Wantirna South
"143.92389349549154,  -37.574608527342406",Warrenheip
"147.69333542699232,  -37.647955966083536",Waterholes
"143.96827457292116,  -35.6396669530679",Westby
"142.76755905925023,  -34.9605049930716",Winnambool
"146.09824545285676,  -36.459340803489454",Winton North
"142.4632152909726,  -38.14814305013374",Woolsthorpe
"146.85108744677385,  -37.68418489987111",Wrixon
"143.8493730427907,  -38.62493722565431",Wye River
"146.86798819562406,  -36.33565857214847",Yackandandah
"144.16855175513578,  -37.11739423054881",Yapeen
"144.88198653354067,  -37.81790515575576",Yarraville
"144.8639505480124,  -37.57998980271624",Yuroke
"151.0611166185897,  -23.836014453166477",Aldoga
"146.6204247348633,  -19.34550490075411",Alice River
"152.75911760564762,  -28.00836973969677",Allandale (Qld)
"152.88029980731352,  -27.66218201147299",Augustine Heights
"151.81035461294053,  -25.69407782070309",Ban Ban
"153.04455797950104,  -27.107866271132163",Beachmere
"151.95711430469268,  -27.498221327967293",Blue Mountain Heights
"144.04167371699435,  -17.440931560664982",Bolwarra (Qld)
"153.16574720164965,  -27.079262721495226",Bongaree
"150.60480693355578,  -27.922062214378315",Boondandilla
"150.3131855362101,  -23.66987955011435",Boulder Creek
"150.91379849655644,  -23.923972575510938",Bracewell
"148.67335038589854,  -20.3351628608213",Brandy Creek (Qld)
"152.27253172222768,  -24.91350494568877",Branyan
"149.14981259382137,  -21.035209877092722",Bucasia
"150.1961892524334,  -25.902951048608017",Bungaban
"151.19474617820106,  -23.874069201263328",Byellee
"151.849814718875,  -27.720288908135654",Cambooya
"152.72930646730697,  -28.078710708904236",Cannon Creek (Scenic Rim - Qld)
"153.20287223762918,  -27.54330075577957",Capalaba
"153.09768429387657,  -26.429934187826476",Castaways Beach
"151.1722716620315,  -27.527255553372466",Cecil Plains
"153.0796488275231,  -27.74704972367183",Chambers Flat
"152.57714825030817,  -28.007996619934445",Charlwood
"152.4793262513159,  -25.28278945041637",Cherwell
"151.90921648363008,  -26.054775792804733",Cobbs Hill
"152.84259823646354,  -26.872156466789495",Commissioners Flat
"153.03536483066674,  -26.04099387165178",Cooloola
"146.27448226244613,  -19.017148471633348",Crystal Creek (Qld)
"148.52225745895055,  -21.052108668415208",Dalrymple Heights
"151.93438016718844,  -28.450788640656512",Dalveen
"152.23104806558854,  -28.25270840220766",Danderoo
"142.53604243694656,  -9.421394982834833",Dauan Island
"153.05272756727183,  -27.004153509182498",Donnybrook (Qld)
"149.1816262191795,  -21.263086356132618",Dunnrock
"152.77419730336175,  -27.62637551089218",Eastern Heights
"152.1007772418913,  -27.677074668097827",Egypt
"151.91762058418493,  -28.048447167869323",Ellinthorp
"148.1319580597051,  -23.541528366600932",Emerald (Qld)
"150.80204295033357,  -23.25356009262244",Emu Park
"152.9396154902981,  -26.73025872851212",Eudlo
"151.98200361693873,  -28.78582608361371",Eukey
"153.00372967281885,  -27.663294973837132",Forestdale
"144.76385090975782,  -17.968848009339222",Forty Mile
"147.86102118910776,  -22.451391743408553",Gemini Mountains
"152.01023466361386,  -26.9018315512857",Gilla
"152.67961494046705,  -26.29954146488438",Gilldora
"153.00153426748372,  -27.834137867414896",Glenlogan
"145.79608275726858,  -17.083153318993784",Gordonvale
"151.84622640166134,  -27.431509136530046",Gowrie Little Plain
"151.17122255753506,  -25.43591843587083",Grosvenor
"148.41219906181902,  -21.44582734628686",Hail Creek
"150.95899890578153,  -27.458226002526676",Halliford
"145.73115877527792,  -16.844651133339482",Holloways Beach
"152.472106011786,  -27.532785270005377",Kensington Grove
"151.9377362163085,  -26.93330867118851",Kooralgin
"152.68143593903613,  -27.931151635890526",Kulgun
"150.89855408911737,  -27.262166167753964",Kumbarilla
"152.3542847349345,  -27.67049825120837",Laidley Creek West
"151.78779195338427,  -24.818263746731017",Lake Monduran
"151.9165769170522,  -28.172539471286274",Leslie
"152.45999463279017,  -27.488999355771018",Lockrose
"150.116596000624,  -24.905036538754153",Lonesome Creek
"147.3986941345039,  -19.61565652183757",McDesme
"146.07279226381712,  -17.805998319408708",Midgeree Bar
"152.2751468193028,  -26.884782661170764",Moore
"151.48580886209578,  -27.47565245215668",Mount Moriah
"145.73563071875103,  -17.06253125836287",Mount Peter
"146.7398301175659,  -19.251918379133688",Mount St John
"150.6168756567261,  -23.201791724610928",Mulara
"152.65286583751728,  -27.880307737262164",Munbilla
"147.44840763538213,  -26.763608279322423",Mungallala South
"153.10802968295374,  -27.454022794112753",Murarrie
"153.3121345935668,  -27.98133008105044",Nerang
"153.04595091805143,  -27.466664186494565",New Farm
"153.065566273161,  -28.054374525369283",Nindooinbah
"150.44481044404088,  -23.722604547379756",Nine Mile Creek
"145.3491038769729,  -16.180188325455486",Noah
"140.8988462099965,  -17.877307677188593",Normanton
"153.01398537624044,  -27.22209600244249",North Lakes
"143.17201829498072,  -18.720367711243963",Northhead
"152.3296731588561,  -26.11972168700543",Oakview
"152.38234574396606,  -27.149327024284695",Ottaba
"153.35517347692064,  -27.49834610779385",Peel Island
"153.10079831565662,  -26.83223666411046",Pelican Waters
"146.6749046197937,  -19.441546230269918",Pinnacles
"153.52915466884815,  -27.430965271886983",Point Lookout
"152.87022024911116,  -26.36530025404099",Pomona (Qld)
"151.61441020551914,  -27.519741420447925",Purrawunda
"152.29319867368088,  -27.293231048457752",Redbank Creek
"150.49498914480856,  -23.050075860581895",Rossmoya
"153.20055516468227,  -27.584356957559706",Sheldon
"145.26403808861002,  -17.54563009192594",Silver Valley
"151.802684425049,  -26.176166153275837",Silverleaf
"153.0807852162317,  -26.722500475051348",Sippy Downs
"146.18035324306086,  -20.026127539570407",Southern Cross (Qld)
"148.07322292545433,  -24.0968439207394",Springsure
"142.91908377629662,  -16.651978083401765",Staaten
"145.30924230410545,  -16.2904088688165",Stewart Creek Valley
"148.9146671034296,  -23.76976090093877",Stewarton (Qld)
"151.8870976168234,  -26.115336469747625",Sunny Nook
"152.58425103569746,  -26.186428807154254",The Palms
"145.6945776293572,  -16.795656909568372",Trinity Beach
"152.761402217245,  -26.4052918853135",Tuchekoi
"145.43395264347268,  -17.563526768789814",Tumoulin
"145.51247411157095,  -17.382206992245138",Upper Barron
"153.0630500316727,  -27.377333048672874",Virginia (Qld)
"139.0658059419033,  -21.527191185859294",Waverley (Qld)
"152.9139984882544,  -27.249560556513266",Whiteside
"152.06266965033782,  -28.33634684300774",Wildash
"153.19159101836203,  -28.039123989934787",Witheren
"150.72321328187985,  -22.98250417764554",Woodbury (Qld)
"153.15233399642895,  -27.452129342013183",Wynnum West
"151.36474004823063,  -27.852084721211316",Yandilla
"151.98623593104352,  -26.82817759106597",Yarraman (Qld)
"151.54523850256066,  -27.721015364777482",Yarranlea
"138.91739747107792,  -34.601747057720125",Altona (SA)
"131.1447366520888,  -26.15657833621239",Amata
"138.56546643743079,  -33.686736240808806",Anama
"140.02776167784705,  -37.44034811675485",Beachport
"138.7111322747686,  -33.274721687889894",Belalie East
"138.88652577972357,  -35.044820557359536",Blakiston
"140.4114380179128,  -34.23543329769763",Cobdogla
"140.51502986037346,  -37.249792459845985",Coles
"133.42118058060495,  -28.790573723749688",Commonwealth Hill
"135.4550839118389,  -34.40538162810098",Coulta
"138.21694679689267,  -35.632914151676715",Deep Creek (SA)
"137.627742668841,  -34.08197462088462",East Moonta
"134.27241965660485,  -32.67939250668421",Eba Anchorage
"140.8020864283369,  -38.020614299527765",Eight Mile Creek (SA)
"138.788588112779,  -33.81395711024657",Farrell Flat
"139.5137489741298,  -34.523220015101316",Fisher (SA)
"138.8182386129069,  -35.13860188704472",Flaxley
"140.61307887211498,  -37.68544920742014",Glencoe (SA)
"138.67287663825647,  -33.43338104892157",Hacklins Corner
"138.6444456091088,  -35.53235341576677",Hayborough
"138.6217385182599,  -34.961617168609216",Highgate (SA)
"137.64000400226436,  -35.11018750743514",Honiton
"138.47892626163775,  -35.48036124888059",Inman Valley
"138.59359022812075,  -33.163025312122116",Jamestown
"138.8605426210466,  -34.75065860630613",Kersbrook
"138.67951304347298,  -35.271758861995885",Kyeema
"137.96123718016688,  -33.319420378415124",Lower Broughton
"134.47926450969476,  -32.87282749263175",Maryvale (SA)
"138.80811562556835,  -34.823951256842655",Millbrook (SA)
"140.50759444377732,  -31.60840821047547",Mooleulooloo
"138.89253211037516,  -34.44572380885192",Nain
"140.3923742090969,  -34.35997440803983",New Residence
"137.63290231350652,  -33.90288917460145",North Beach (SA)
"140.30335912980155,  -32.27914629800704",Olary
"138.50115392408608,  -34.79780468482375",Osborne (SA)
"138.6763422177949,  -34.87104699592366",Paradise (SA)
"139.94974741474104,  -35.373438839238396",Peake
"139.161626822699,  -34.15124172006545",Peep Hill
"139.38995368479578,  -34.86214929791927",Pellaring Flat
"137.99914633269205,  -33.26177843539618",Pirie East
"140.9072359083551,  -37.64373380932824",Pleasant Park
"138.46844606079338,  -34.64704110167339",Port Gawler
"140.41237682615824,  -34.55606415009567",Pyap West
"138.57519388516496,  -34.481478559298026",Redbanks
"140.00930536557976,  -36.98467778929098",Reedy Creek (SA)
"138.68172102553098,  -34.1401240533602",Rhynie
"138.7512448313538,  -34.52880203052756",Roseworthy
"138.6301615229844,  -34.89740699096074",Royston Park
"138.79159413943827,  -34.74900442916079",Sampson Flat
"138.53834753108137,  -35.042131162305616",Seaview Downs
"138.4857407622047,  -34.855611576185574",Semaphore Park
"140.8515926086791,  -35.92881963541629",Shaugh
"139.0794820651921,  -34.359105112018206",St Kitts
"140.70622838458544,  -36.70248987018028",The Gap (SA)
"139.90526176906653,  -36.35248538794833",Tilley Swamp
"138.5713781127363,  -34.662642337316946",Virginia (SA)
"135.6869885139112,  -34.520454741940355",Wanilla
"138.4948242765221,  -34.87329535644099",West Lakes
"138.74127305618782,  -34.58650639140089",Willaston
"138.70826925062684,  -34.90478028548403",Woodforde
"138.74276807775058,  -34.793998879852055",Yatala Vale
"115.68632085429631,  -31.614364609208017",Alkimos
"115.77069957736514,  -33.54583066455668",Argyle (WA)
"116.00957281828781,  -31.781522832405823",Belhus
"117.01743979677941,  -33.761351279437925",Boilup
"118.52593874399497,  -20.505591801800495",Boodarie
"116.20830924046065,  -34.67176647018816",Boorara Brook
"114.59431056980569,  -28.453272786429938",Bowes
"115.86246252255603,  -32.05626296178442",Bull Creek (WA)
"116.02895231983608,  -31.663070646949667",Bullsbrook
"115.02408632621831,  -33.928398954779645",Burnside (WA)
"115.70440604280321,  -31.643115208671023",Butler (WA)
"117.58848665067305,  -33.120019085456896",Cancanning
"116.14305065014238,  -33.834762413243084",Catterick
"116.26980737732043,  -31.8500556821886",Chidlow
"123.50971740754605,  -28.10272472301671",Cosmo Newbery
"115.27223037897733,  -34.184948650316564",Courtenay
"115.56528959889786,  -31.09852426002668",Cowalla
"116.17318370986317,  -34.53481206405693",Crowea
"116.05839057165745,  -34.201804740929276",Dixvale
"114.61620237887131,  -28.673329846261833",Drummond Cove
"117.25812695041942,  -31.998882138219063",Dulbelling
"115.06189991298896,  -33.56183267525838",Eagle Bay
"117.25096878814867,  -32.631533924889986",East Popanyinning
"122.37352152546788,  -31.02897829731901",Emu Flat (WA)
"117.9371646922573,  -34.99529531299186",Emu Point
"116.45162466760269,  -32.20797264715443",Flint
"116.10724656455434,  -31.919116624374286",Glen Forrest
"117.93179213879002,  -35.0867162448923",Goode Beach
"115.03034453247969,  -30.067059191604315",Green Head
"117.49626736208799,  -33.332972570652665",Gundaring
"115.79482570150596,  -31.871481525023345",Gwelup
"116.57250432388943,  -31.843230390263958",Inkpen
"117.18286254701783,  -30.296332522527862",Kalannie
"115.20822289993892,  -33.78277488946541",Kaloorup
"126.64128740833137,  -14.294505814154974",Kalumburu
"115.79452342393601,  -32.417473986746806",Karnup
"116.84511447333207,  -20.73456211747856",Karratha
"117.7040270884959,  -31.635783430924135",Kellerberrin
"115.93063330664269,  -33.70486908270469",Kirup
"115.96491206024221,  -33.53420194908966",Lowden
"113.7159552970292,  -24.20872068032386",Macleod (WA)
"127.67554396003406,  -25.859728757523044",Mantamaru
"118.20209795200144,  -33.31450314870243",Merilup
"116.04726831311584,  -31.015530011401573",Mogumber
"115.76557892891287,  -32.01473164005373",Mosman Park (WA)
"114.85641419647877,  -28.570461406480806",Mount Erin
"119.57185167082679,  -32.120340265181134",Mount Holland
"118.57323717956407,  -31.746920025684368",Muntadgin
"117.6863802591435,  -33.263310055193735",Nippering
"116.1411791794848,  -34.63059430444828",Northcliffe
"117.85925074718561,  -34.995988066915864",Orana
"118.28813923517654,  -25.288424310405166",Peak Hill (WA)
"117.8800374999823,  -34.663864199481836",Porongurup
"115.45576106060211,  -31.264504306829863",Seabird
"115.75484828780523,  -32.071866154770895",South Fremantle
"121.47909087162633,  -30.763567123677138",South Kalgoorlie
"117.91793051686983,  -31.221056514824255",South Kununoppin
"119.3482454385231,  -31.253133935998928",Southern Cross (WA)
"119.00278879692199,  -20.492372758948264",Strelley
"118.21569728965478,  -33.07732591480943",Tarin Rock
"119.37645124983386,  -30.95381995172958",Turkey Hill
"114.8532121111801,  -28.946450571051138",Walkaway
"117.56564449596016,  -32.1348126674213",Wamenusking
"115.81282619771487,  -32.16762645586032",Wattleup
"115.25474619901249,  -30.802743409031315",Wedge Island (WA)
"117.58288934020804,  -27.06414464449605",Weld Range
"115.78355243878174,  -32.75992985035351",West Coolup
"116.05026312958351,  -24.274440731196403",West Lyons River
"116.02370594094982,  -34.17597358305263",Yanmah
"147.32542360684602,  -43.35136384142303",Adventure Bay
"146.86624094950858,  -41.127611066819604",Bell Bay
"147.661868959615,  -41.56561791620638",Ben Lomond (Tas.)
"146.37804775992637,  -41.43665226026808",Beulah (Tas.)
"147.01894212738006,  -43.235459775963925",Brooks Bay
"146.94468605509195,  -43.112759204046135",Castle Forbes Bay
"147.29823978294482,  -42.821667005431735",Dowsing Point
"145.06684247349318,  -40.95877115576765",Edith Creek
"147.35004711926618,  -42.83097844576001",Geilston Bay
"147.16031139956965,  -42.1593026917193",Interlaken
"147.28878598322618,  -42.38386823592858",Jericho (Tas.)
"148.01511818158536,  -41.223352594592555",Lottah
"147.25130087682686,  -43.08757712195883",Lower Snug
"145.38102042701667,  -41.46079538012173",Luina
"147.23667434607142,  -43.02920107137277",Margate (Tas.)
"144.69841493044407,  -40.93231251349103",Marrawah
"147.94493798790666,  -41.44764085966336",Mathinna
"147.30333268694972,  -42.87160843301984",Mount Stuart (Tas.)
"145.13262284036983,  -40.996075813921934",Nabageena
"147.23097741392837,  -42.949446546973554",Neika
"147.53084765516405,  -42.720662976575994",Orielton
"146.3363547789554,  -41.458521388455694",Paradise (Tas.)
"146.33589875076703,  -41.208449995144456",Quoiba
"147.82409773842073,  -42.19699314873415",Tooms Lake
"146.31003177964215,  -41.20742667480562",Tugrah
"145.93064829937487,  -41.07241857465973",Wivenhoe
"147.26912480785253,  -41.1860803754009",Wyena
"135.57286617774926,  -12.024491088417124",Galiwinku
"130.91619137083026,  -12.400079379368739",Karama
"132.27035672606675,  -14.451791515784011",Katherine
"131.07403618970957,  -12.542509737066485",McMinns Lagoon
"133.40098806897004,  -17.372136744311064",Newcastle Waters
"130.08283551094868,  -14.145711500785698",Peppimenarti
"130.44529472859455,  -13.060245054350833",Rakula
"130.92318088581177,  -12.553894465032798",Wickham (NT)
"149.11244780265292,  -35.28720177275094",Acton (ACT)
"149.1031368033598,  -35.319209075522515",Deakin
"149.22807524357435,  -35.34013681445896",Oaks Estate
"151.24819627548936,  -33.76936450183311",Allambie Heights
"151.11455960807564,  -33.972905831645534",Allawah
"153.06303216339882,  -30.92198497185243",Arakoon
"151.57707817350015,  -33.05998137615098",Arcadia Vale
"146.8545296584821,  -34.343561917868975",Ardlethan
"147.33170505437397,  -35.12489944312804",Ashmont
"153.17027905737064,  -28.409098268629048",Back Creek (Tweed - NSW)
"152.10122874178583,  -31.972846661999682",Bakers Creek (Mid-Coast - NSW)
"153.53663255875708,  -28.221767660469073",Banora Point
"150.9397324489496,  -33.69766100957049",Beaumont Hills
"151.68910494401837,  -32.999219751267766",Bennetts Green
"151.13646192857485,  -33.55831165031042",Berowra Waters
"152.57729972899995,  -30.16150414917304",Billys Creek
"151.02071558299983,  -33.892428917058496",Birrong
"151.50016949080626,  -33.354099950045224",Blue Bay
"152.56519424476232,  -31.946064924402428",Bohnock
"151.52432249737413,  -33.109466173828785",Bonnells Bay
"148.62524126168924,  -34.84364079014021",Bookham
"152.27299527276247,  -32.394718391206375",Boolambayte
"147.34705103785157,  -35.15589975867685",Bourkelands
"150.4499178098748,  -31.263365867581147",Breeza
"152.63286345473063,  -31.457068840471536",Brombin
"148.23218966008554,  -35.65886549341589",Buddong
"146.95280562888541,  -35.017996690835915",Bulgary
"151.70541640172075,  -32.89206175117025",Callaghan
"150.7207520951991,  -33.73799309084537",Cambridge Gardens
"152.94140267158986,  -29.21538864427888",Camira (NSW)
"152.65133784703718,  -28.548979376539922",Capeen Creek
"150.91431699191008,  -33.54568316583272",Cattai
"153.49634432743716,  -28.331731007827738",Clothiers Creek
"151.2628592251231,  -33.91168495030415",Clovelly
"149.26559554584244,  -33.77871098447176",Colo (Bathurst Regional - NSW)
"151.29323037592738,  -32.966556237050725",Congewai
"152.7921210467539,  -31.274671937450304",Cooperabung
"151.46386245399995,  -33.05012249416727",Cooranbong
"146.32451285746896,  -35.74149567150123",Coreen
"146.35852883663014,  -35.99380580432039",Corowa
"150.39425916569706,  -35.32146589871635",Croobyar
"149.45738138086057,  -34.45328265685893",Crookwell
"148.7389381760665,  -33.31662426641561",Cudal
"148.5784364009018,  -31.528024265547906",Curban
"149.05659574756243,  -36.21367612959091",Dairymans Plains
"151.190876007978,  -33.73958698460511",Davidson
"150.76419585816183,  -34.4547754999923",Dombarton
"151.04327658318203,  -33.80239510998991",Dundas (NSW)
"151.16270561107686,  -31.22789726303704",Dungowan
"151.3951972743076,  -33.18196365893622",Durren Durren
"151.35411678860922,  -33.43495985555485",East Gosford
"149.8928701765118,  -37.02307153877613",Eden
"152.28277956459607,  -31.591672100157584",Elands
"153.33078923153005,  -29.247486247683142",Esk (NSW)
"153.42211016956227,  -29.11254397919333",Evans Head
"153.54900096534507,  -28.634231557658804",Ewingsdale
"150.59341907543924,  -34.99769464498787",Falls Creek (NSW)
"153.45496464144213,  -28.654513550543793",Federal (NSW)
"152.65527412370585,  -29.384557577661315",Fine Flower
"151.02649908842207,  -33.54484584606265",Forest Glen (NSW)
"150.13221842015648,  -33.71028547779792",Ganbenang
"150.74189385382144,  -32.92448371416804",Garland Valley
"147.44216574428776,  -34.308308942559975",Gidginbung
"151.51815027752954,  -32.763324661308374",Gillieston Heights
"147.3328693507621,  -35.13718242719816",Glenfield Park
"153.24428924108983,  -28.727327442412257",Goolmangar
"151.48973570715765,  -32.65237666981973",Gosforth
"149.38315251090995,  -34.53086832797106",Grabben Gullen
"152.97481610415338,  -29.65051644776521",Great Marlow
"153.36225415144924,  -28.97029656624532",Green Forest
"148.02922652377595,  -35.54630989730925",Green Hills (Snowy Valleys - NSW)
"151.05833074716662,  -33.90240182871314",Greenacre
"147.4319028787641,  -35.239111072714074",Gregadoo
"149.79423774647486,  -34.838430932407796",Gundary
"147.741997795374,  -33.25385391082224",Gunning Gap
"149.26767190492396,  -33.397523059248826",Guyong
"152.5852835003367,  -29.456688975684678",Heifer Station
"147.04411583111244,  -35.52953163781176",Henty (NSW)
"146.62926987018884,  -31.611302340559856",Hermidale
"150.9138211657244,  -34.06861911670231",Holsworthy
"152.9261563285762,  -30.41491653013675",Hydes Creek
"149.83536402153396,  -32.95472545994336",Ilford
"150.86332300758667,  -34.004615324715516",Ingleburn
"152.55460094612673,  -29.605801388030834",Jackadgery
"152.5622891093851,  -28.83149585988417",Jacksons Flat
"149.2286329582723,  -36.70714081399092",Jincumbilly
"150.2254280584859,  -34.43035946582109",Joadja
"153.0548399224161,  -30.24040146398922",Karangi
"151.40090071548897,  -32.848499666757405",Kearsley
"150.9353674882001,  -33.750001105413475",Kings Langley
"151.09933336103379,  -33.93940218890335",Kingsgrove
"151.0374101300602,  -33.04554894665078",Laguna
"152.83566277157098,  -31.54795527844519",Lake Cathie
"149.41935607707777,  -35.12271162150014",Lake George
"151.16695533380124,  -33.81753108540931",Lane Cove
"151.0294093500046,  -33.43780822346195",Laughtondale
"148.87483038199736,  -34.65096263865496",Laverstock
"146.3888929814057,  -34.498406952987274",Leeton
"152.65944089990754,  -29.549613089301673",Lilydale (NSW)
"148.31713860133448,  -35.3379022569994",Little River (NSW)
"151.23590035259053,  -29.765308561570773",Long Plain (Inverell - NSW)
"148.99320434696253,  -33.68300499363369",Lyndhurst (Blayney - NSW)
"152.31734641120534,  -31.769497799853667",Marlee
"150.14209659046443,  -33.42721311972871",Marrangaroo
"147.86088132620424,  -31.24839927404617",Marthaguy
"151.57176234188134,  -29.756311213027647",Matheson
"146.91132022479826,  -34.763357596246465",Matong
"153.18508358633397,  -28.877105115400916",McKees Hill
"152.60695681682998,  -30.791366998782415",Millbank (NSW)
"152.37120020559118,  -32.16180878249761",Minimbah
"151.06932355401074,  -33.145019370283315",Mogo Creek
"150.62627182081474,  -33.437614462511306",Mountain Lagoon
"149.82982037065406,  -33.822940790598885",Mozart
"151.76029268643234,  -32.92821421111842",Newcastle West
"151.3858723472113,  -29.823652393864165",Newstead (NSW)
"148.6275667675243,  -36.17873778813287",Nimmo
"148.46427016826814,  -32.6919096785674",Obley
"151.3115891331105,  -33.033027944755624",Olney
"150.4415633560342,  -29.97537413023023",Pallal
"149.89739305170303,  -36.94525920192808",Pambula Beach
"151.48152032330606,  -32.837772807628234",Pelaw Main
"151.64671859032245,  -33.070387343981835",Pelican (NSW)
"151.1236999937987,  -34.067531935229844",Port Hacking
"150.12165249817895,  -36.086836767072235",Potato Point
"150.1563115578963,  -33.48748209644468",Pottery Estate
"152.77411777933852,  -29.463501575388424",Punchbowl (Clarence Valley - NSW)
"152.46730118311652,  -31.942804554090067",Purfleet
"152.70653081102762,  -29.662380061558178",Ramornie
"151.76721351068446,  -32.75478221005342",Raymond Terrace
"152.7392441660229,  -31.424157616594766",Redbank (NSW)
"151.66618282464887,  -29.58196332977496",Reddestone
"151.35193779594934,  -29.2685999954243",Rocky Creek (Inverell - NSW)
"148.67117140110847,  -36.244366511235896",Rocky Plain (NSW)
"151.14197217964437,  -33.86578799908448",Rodd Point
"150.73312055182492,  -32.14759558404377",Rossgole
"153.38223306362815,  -28.879949802084877",Rous Mill
"152.89868698168263,  -29.71466744077604",Southampton (NSW)
"151.13717414238087,  -33.89274894400979",Summer Hill (Inner West - NSW)
"150.5907465376502,  -34.22878701995189",Tahmoor
"147.0599659724553,  -29.60126593155563",Talawanta
"150.88917284236396,  -33.69158320546948",Tallawong
"152.72407456305052,  -30.26111150813417",Tallowwood Ridge
"149.96031669068802,  -36.62524014962818",Tanja
"147.16072372190644,  -34.49353902717334",Tara (NSW)
"151.87175635954236,  -28.967077740132723",Tarban
"147.26582725493643,  -30.81207453775593",The Marra
"150.90608152978035,  -31.226792081329627",Timbumburi
"151.66707833060264,  -32.99693156298328",Tingira Heights
"153.5115512851468,  -28.792312148633748",Tintenbar
"152.1583032220679,  -32.057477509508885",Tipperary (NSW)
"150.694515931992,  -34.582433320813244",Tongarra
"153.08804189481512,  -30.35511787018179",Toormina
"149.47343937804544,  -36.27570048044301",Tuross
"153.05339836267686,  -29.64382122723488",Ulmarra
"153.11820936616147,  -30.052174162939117",Upper Corindi
"151.19263264060157,  -32.09829711246383",Upper Rouchel
"153.38262329249164,  -28.52374445794486",Upper Wilsons Creek
"147.22012500903796,  -35.202305608414896",Uranquinty
"151.2769641019676,  -33.855822005946465",Vaucluse
"147.35977806895303,  -35.108227828220365",Wagga Wagga
"149.04579174146852,  -33.443032638214184",Waldegrave
"151.0028152051375,  -30.69741352355697",Watsons Creek (NSW)
"150.37973012967086,  -33.72162506249882",Wentworth Falls
"151.19987862799704,  -33.806177504384145",Willoughby (NSW)
"149.4351146137925,  -33.53913689330309",Wimbledon
"150.61223561091276,  -33.676831110808486",Winmalee
"150.72747030312343,  -34.958991038643845",Wollumboola
"150.7849940482049,  -30.433418620762904",Woodsreef
"151.34949471654136,  -29.735704019205436",Woodstock (Inverell - NSW)
"153.28709415636752,  -29.313995550554633",Woombah
"152.3171049277842,  -32.30168528042867",Wootton
"147.30014791933073,  -33.9176597609705",Wyalong
"152.8697132375034,  -29.06128327884611",Wyan
"151.36355973265765,  -33.40465188724527",Wyoming
"145.04133856169145,  -29.289917418847292",Yantabulla
"149.2889215991647,  -32.627398848452934",Yarrabin
"151.35614826481373,  -30.45173949486399",Yarrowyck
"150.4194551441087,  -35.266836024581686",Yatte Yattah
"150.4807982135284,  -34.98735768894375",Yerriyong
"146.09909122856223,  -34.31176936552755",Yoogali
"148.33970604855907,  -34.29814625757722",Young
"144.9609812913343,  -37.845149948768785",Albert Park (Vic.)
"144.8133782478888,  -37.86007196391906",Altona (Vic.)
"145.1023261042224,  -37.866741353646766",Ashwood
"144.88486539163534,  -37.667011805694315",Attwood
"142.58004325939993,  -36.20388996309662",Bangerang
"144.2299587362038,  -37.01494098731392",Barkers Creek
"144.3421418964047,  -38.17813242640095",Belmont (Vic.)
"143.49037829652198,  -37.97129453800533",Berrybank
"145.3485752040961,  -38.039941825179504",Berwick
"145.1465607130821,  -37.83931701359827",Blackburn South
"143.81368024878347,  -37.60331213237857",Bonshaw (Vic.)
"145.79161766686366,  -36.050486398832966",Boosey
"145.12335386585494,  -37.83660602132944",Box Hill South
"141.8189999385602,  -38.03209141613523",Breakaway Creek
"142.89645819665097,  -38.45977670494843",Brucknell
"145.8834403840401,  -36.009501441628565",Burramine
"145.1503825685244,  -37.85358246022893",Burwood East
"144.90330931452877,  -38.460019250821624",Cape Schanck
"143.59020230848054,  -37.56091384414768",Carngham
"146.7386175234441,  -38.39231463224449",Carrajung Lower
"142.75006438469507,  -36.27982544843162",Carron
"145.12141588971323,  -38.04965658730362",Chelsea
"144.29198273198975,  -37.077513700869034",Chewton Bushlands
"147.1919075232868,  -38.04542410263993",Clydebank
"145.73645273755707,  -35.97460932064988",Cobram East
"144.2429280899236,  -35.77606049294021",Cohuna
"149.01504848550954,  -37.371144058708005",Combienbar
"147.1053532921993,  -36.61993178173694",Coral Bank
"142.93941401130166,  -37.34080136520349",Denicull Creek
"145.3088843599961,  -38.17594928980299",Devon Meadows
"146.1783052431508,  -38.554332596917746",Dollar
"143.47931264705744,  -38.19915646975635",Dreeite South
"143.72098552860007,  -36.86144393662576",Dunolly (Vic.)
"147.56463104788656,  -37.77935716293487",Ellaswood
"143.77651649937937,  -37.749619733647414",Enfield (Vic.)
"147.75738049992464,  -37.68874310414937",Fairy Dell (East Gippsland - Vic.)
"144.1425722570554,  -38.1564004548548",Gnarwarre
"144.49848415125825,  -36.614970942342715",Goornong
"145.17462545017327,  -36.715450035032745",Goulburn Weir
"141.81057166766152,  -37.40983983612738",Gringegalgona
"145.4654961659498,  -37.73007480282553",Gruyere
"147.04642328925638,  -36.900805074141495",Harrietville
"142.2865678241141,  -38.08708327590424",Hawkesdale
"146.38436321801836,  -38.634280475224195",Hazel Park
"145.05347671860756,  -37.74328340284749",Heidelberg Heights
"147.4695385865107,  -38.052472407414456",Hollands Landing
"142.39883729963154,  -38.33614232549506",Illowa
"145.7277606549239,  -38.617282143916015",Inverloch
"145.68743835122268,  -38.152372572059186",Iona
"142.2226130830093,  -36.550115836126466",Kalkee
"142.44939076782867,  -36.46126332602024",Kewell
"146.94975491053583,  -36.14048996306859",Killara (Wodonga - Vic.)
"145.01122985957682,  -37.27825586969681",Kilmore East
"145.56222450247427,  -35.8635912918005",Koonoomoo
"146.02124919811,  -36.839236048321474",Lima South
"143.83088981835118,  -36.72545237864385",Llanelly
"144.25786371928675,  -36.74305837968072",Long Gully
"144.2078564080696,  -36.75633283663394",Maiden Gully
"144.29418637549193,  -36.82838161806267",Mandurang
"144.27680004791404,  -36.86233989370319",Mandurang South
"143.67766221299803,  -36.68944862666205",McIntyre
"145.10625370005184,  -38.398754861070415",Merricks Beach
"147.84332122183187,  -37.868528165623765",Metung
"141.2039081445385,  -36.69913614540311",Minimay
"141.3394866093335,  -37.4789254754712",Nangeela
"141.70708554882438,  -38.23885829734137",Narrawong
"145.93235339455657,  -37.913756909367464",Nayook
"143.89584493061608,  -37.53784756301265",Nerrina
"145.68360387266694,  -38.308653423671494",Nyora (Vic.)
"144.53834557771248,  -38.25955176793249",Ocean Grove
"145.37960513812962,  -37.85240045914656",Olinda (Vic.)
"145.76550888991682,  -37.857459331228455",Powelltown
"147.7478988875106,  -37.921149221840146",Raymond Island
"145.91695720062017,  -38.439849536197144",Ruby
"141.45198869736365,  -37.64714501091157",Sandford (Vic.)
"143.84777010975913,  -37.66510186745456",Scotchmans Lead
"143.93519960734068,  -37.6769387271713",Scotsburn
"144.84404854673934,  -37.864235284750684",Seaholme
"145.5031951742582,  -37.77858754020297",Seville East
"143.08010268381335,  -37.070074469345286",Shays Flat
"143.71752244019973,  -38.69170825255731",Skenes Creek North
"146.5274889333928,  -38.75274269976532",Snake Island
"145.1295403613051,  -37.6895317800763",St Helena
"145.81420098903033,  -36.414603621360236",Stewarton (Vic.)
"143.19341594531252,  -38.31222358869076",Tandarook
"141.56297258397564,  -37.2980738432011",Tarrayoukyan
"143.07597439217403,  -36.09926955327191",Thalia
"144.95777922386677,  -36.24556239396781",Tongala
"144.79327071383113,  -37.04435409632963",Tooborac
"143.97246291292947,  -35.84484392573827",Tragowel
"142.57600916745812,  -35.32439343029809",Tyenna (Vic.)
"143.28986216159075,  -37.06990971340035",Warrenmang
"144.87080243155498,  -37.80050224820722",West Footscray
"143.77932470659556,  -38.67561798461708",Wongarra
"146.97522987630313,  -38.54559242633523",Woodside Beach
"141.74181777177643,  -37.51748365010429",Wootong Vale
"146.7419611986551,  -37.66887474340304",Worrowing
"144.0525444055032,  -37.21774457072393",Yandoit Hills
"142.4360298825963,  -38.316901557985304",Yangery
"146.56776083477448,  -37.82197112299309",Yangoura
"145.62550040825752,  -37.79388607417455",Yarra Junction
"145.39681642111736,  -36.2739960626684",Zeerust
"151.64126268265818,  -26.10502807550974",Abbeywood
"153.04386365855564,  -27.432891041947105",Albion (Brisbane - Qld)
"152.35982757341603,  -24.95682693956222",Alloway
"147.4383001027614,  -19.45709278067533",Alva
"152.59102715916,  -25.633973331487166",Antigua
"153.0197995164864,  -27.30645888481612",Bald Hills (Qld)
"151.26747244196255,  -23.84466810153816",Barney Point
"145.61811587846557,  -17.229502378451514",Barrine
"153.06030104192607,  -26.828345880327017",Bells Creek
"149.18860141831786,  -21.064707196404104",Blacks Beach
"152.73452209397342,  -26.459623929179102",Bollier
"152.69061458329224,  -27.99266025727367",Boonah (Qld)
"150.8576973557043,  -28.004227959063833",Bulli Creek
"152.9583127219336,  -27.158465939893087",Burpengary
"151.04933381461768,  -26.422129793883027",Burra Burri
"150.26736700825793,  -23.592942486179616",Bushley
"151.99404386431863,  -27.43617269762838",Cabarlah
"145.7749643328262,  -16.921840321460685",Cairns City
"150.33066643401986,  -27.999836331902454",Calingunee
"152.9208701493373,  -27.634513562548666",Camira (Qld)
"145.69752055139816,  -17.836197519248888",Cardstone
"152.18042004380166,  -26.14801355365911",Cinnabar
"140.44738837337658,  -20.525193377112306",Cloncurry
"153.0822918126093,  -27.24711645839372",Clontarf (Moreton Bay - Qld)
"151.8886592828168,  -25.616576257660267",Coalstoun Lakes
"150.28079308325417,  -25.68474301717979",Cockatoo (Qld)
"147.8764163176174,  -24.23673093742475",Cona Creek
"152.47573156411804,  -27.382540970731252",Coominya
"145.40551564969786,  -16.444574527023995",Cooya Beach
"151.9402947374006,  -28.520281501796486",Cottonvale (Qld)
"145.3928610087364,  -28.70492647997854",Cuttaburra
"145.42003760930194,  -16.19095389976684",Diwan
"144.02668787502728,  -9.945049962731435",Dowar Islet
"149.76669195278077,  -26.70293897502244",Dulacca
"152.71638972012707,  -26.198459513221923",East Deep Creek
"152.7788232942355,  -26.72040282502862",Elaman Creek
"152.97382970901918,  -27.42839976703782",Enoggera
"148.94780155761183,  -21.305721525912983",Eton
"152.65070105848235,  -27.484758816443293",Fairney View
"145.97630453521657,  -17.49195515075421",Fitzgerald Creek
"152.09914946925505,  -27.630491844606453",Flagstone Creek
"151.87047174804164,  -28.770243700860906",Fletcher (Qld)
"147.94642319041122,  -23.358023056416968",Fork Lagoons
"152.63361329490303,  -28.022186185131964",Frenches Creek
"146.09726791495336,  -17.814343157728395",Garners Beach
"152.2799660147505,  -27.56356096861828",Gatton
"152.1783030870371,  -28.064801195199387",Gladfield (Qld)
"152.73020700264826,  -25.53883867939855",Granville (Qld)
"145.8328165525807,  -17.0308456543935",Green Hill
"151.298388796974,  -28.61842399518223",Greenup
"151.72808093092152,  -28.244280703956132",Greymare
"148.9552315063519,  -21.080374156697566",Hampden (Qld)
"142.2610061026564,  -11.056403296944916",Injinoo
"152.38908030055381,  -25.26853532872345",Isis River
"148.72938439650176,  -20.288041557356543",Jubilee Pocket
"152.78132067641167,  -27.511852442941702",Kholo
"151.79765435536922,  -27.49667809217799",Kingsthorpe
"150.7996774588584,  -27.08329172003179",Kogan
"145.58119414268998,  -17.190591476249445",Lake Tinaroo
"153.00937886671886,  -27.641557549635596",Larapinta (Qld)
"138.54432372418933,  -18.6464133618836",Lawn Hill
"145.5969378719905,  -17.608585425136134",Maalan
"153.35992374275855,  -27.610783380366062",Macleay Island
"142.1842885438055,  -11.86775981599432",Mapoon
"151.77919314722286,  -28.156544860331536",Montrose (Southern Downs - Qld)
"150.36097189729682,  -27.730548972368666",Moonie
"153.4064521042059,  -27.149454735035345",Moreton Island
"152.04299656946225,  -28.260530446085973",Morgan Park
"145.39957432735577,  -16.695158610998277",Mount Molloy
"145.73220583642555,  -16.988867824431566",Mount Sheridan (Qld)
"151.67613968022377,  -24.331623939185317",Mount Tom
"146.0987814078374,  -17.594270433433074",Mourilyan Harbour
"145.7772777104656,  -17.975295843007235",Munro Plains
"145.27639843780668,  -17.191368562968954",Mutchilba
"152.61900970355947,  -26.20637581974179",Nahrunda
"150.30512224441816,  -26.837617529047456",Nangram
"151.71966329581016,  -27.09890454549284",Narko
"152.06848097075914,  -26.864243969950742",Nukku
"148.88755613721054,  -27.0170965887921",Oberina
"152.6554677004122,  -27.920039111198786",Obum Obum
"152.80522644308908,  -27.12701521473133",Ocean View
"146.58041942211725,  -18.706609463780506",Palm Island
"149.09608989113866,  -26.462248938060224",Pickanjinnie
"152.07116106905895,  -27.874665898713364",Pilton
"153.1341958867567,  -27.40069466428456",Pinkenba
"153.0982510457386,  -26.546448331254222",Point Arkwright
"152.90960599580777,  -25.715502745332163",Poona
"142.1883336097786,  -10.685792160454364",Prince Of Wales
"149.1372671447766,  -21.163756723259624",Racecourse
"146.62762134886808,  -19.3006420276531",Rangewood
"152.4405136979434,  -27.533577045931853",Regency Downs
"152.89885989730425,  -25.399298549810485",River Heads
"150.13735503142547,  -26.057694287704457",Roche Creek
"152.28365858440048,  -27.702220218923916",Rockside
"152.26245721073892,  -27.655611165231935",Ropeley
"153.1191298808677,  -28.11869487731478",Sarabah
"153.01976564449757,  -27.479054508852844",South Brisbane
"147.76766854340187,  -20.560465470616226",Springlands
"151.1953918760167,  -27.358736365437785",Springvale (Qld)
"151.90526191340246,  -27.07683676988548",St Aubyn
"151.27897480328778,  -27.3247787410032",St Ruth
"153.35717811210264,  -28.18928395310517",Tallebudgera Valley
"153.04559075339884,  -27.527080854389745",Tarragindi
"151.932708375176,  -28.552787319379394",Thulimbah
"148.6134425484345,  -25.38957817587442",Upper Dawson
"151.898522935744,  -27.660591137744106",Vale View
"151.30936506455623,  -24.90132033692183",Ventnor (Qld)
"149.18634008664822,  -26.5910818174002",Wallumbilla
"150.49478116230063,  -23.367886278206797",Wandal
"152.76541997190122,  -27.838787602403883",Washpool (Qld)
"151.8335601006223,  -27.601213846446996",Westbrook (Qld)
"151.92687760120464,  -27.532679188932427",Wilsonton Heights
"147.81035048603485,  -22.64313888748328",Wolfang
"152.8025496760298,  -26.945731390845808",Woodford (Qld)
"148.65331142677147,  -20.235864271705374",Woodwark
"152.36254628866547,  -26.015128434424952",Woolooga
"140.46588330883404,  -34.7458898752352",Alawoona
"138.76795034635745,  -34.64113149453186",Bibaringa
"138.4089679171095,  -34.39410816227342",Calomba
"140.48668152814764,  -36.29318371289211",Carew
"138.71467318819035,  -34.988308191671344",Crafers
"134.67927307314037,  -32.6047800109723",Cungena
"133.55796394899744,  -32.10761147999072",Denial Bay
"137.9689531684134,  -35.789249877627164",Dudley East
"138.99814523247957,  -33.89213991754518",Emu Downs
"139.22448022278314,  -35.16366827189101",Gifford Hill
"138.62824157228746,  -35.01190079138684",Glenalta
"138.53355621528112,  -34.98730547241684",Glengowrie
"140.549319897166,  -34.282132672132725",Glossop
"138.66937879724208,  -34.79549781086199",Gulfview Heights
"138.87542342245786,  -35.51663104998546",Hindmarsh Island
"138.55642609567926,  -35.417843170384906",Hindmarsh Tiers
"138.51413794933154,  -35.15451113160764",Huntfield Heights
"138.6029241312778,  -34.9566507424218",Hyde Park (SA)
"136.68795902176828,  -31.45769072127323",Island Lagoon
"139.9154169696942,  -36.83840581253185",Kingston Se
"135.9440543922906,  -31.64041448803699",Lake Gairdner
"139.59018832368082,  -34.58629638234564",Langs Landing
"140.55013290554538,  -36.825020622073126",Lochaber
"137.0889489139255,  -33.18140525717369",Middleback Range
"139.12325455799004,  -35.08244107747524",Monarto
"138.6859158201175,  -34.649503668371906",Munno Para Downs
"138.7577111815159,  -35.04806381358855",Mylor
"140.52547709149925,  -37.97257825595397",Nene Valley
"138.4910136750413,  -34.78750703569214",North Haven (SA)
"138.63205791081916,  -34.92133783176234",Norwood (SA)
"138.68990115143487,  -32.69529574911359",Orroroo
"138.7334118694675,  -34.97818200324823",Piccadilly (SA)
"135.45677800403692,  -33.52685784656031",Polda
"139.91766826875403,  -34.108300332206404",Qualco
"140.407808272473,  -37.57888839355222",Rocky Camp
"138.5074516918817,  -34.87067094469481",Royal Park
"138.67275773219728,  -34.781063043793644",Salisbury East
"138.68646636432254,  -34.684728292514826",Smithfield (SA)
"136.3426547403792,  -33.22322997524322",Solomon
"138.61710171536077,  -33.49183834552067",Spalding (SA)
"138.60447791809594,  -34.306056191349114",Stockyard Creek (SA)
"139.80896342292345,  -34.25660494607077",Stockyard Plain
"140.23373512737663,  -33.15784621015686",Sturt Vale
"138.5096853590231,  -35.23697288182042",Tatachilla
"138.28147671503666,  -34.48535983201957",Thompson Beach
"138.63760863085628,  -34.93322873095723",Toorak Gardens
"140.71702799892125,  -37.747352738118245",Wandilo
"138.0296652731414,  -33.102926447277426",Weeroona Island
"138.48534448026268,  -34.86531491237652",West Lakes Shore
"138.54509875078975,  -35.297286405935665",Willunga South
"139.67115846366673,  -34.116761177666625",Wombats Rest
"138.56247385739664,  -35.10312708364295",Woodcroft (SA)
"138.35543856803858,  -35.467615700939504",Yankalilla
"132.73797837932568,  -31.650062469522435",Yellabinna
"139.4793256171739,  -34.90991708652749",Younghusband
"139.2959632727425,  -34.968264743425706",Zadows Landing
"115.25639268653265,  -33.66366084891081",Abbey
"115.83654286006374,  -32.01357380642313",Applecross
"117.5008595528432,  -32.00098835661681",Badjaling
"116.04365546614946,  -31.797741184236315",Baskerville
"115.76275313171318,  -31.774704024163018",Beldon
"115.84692543446585,  -33.180628004619884",Benger
"115.84511243833266,  -32.24452935142292",Bertram
"128.5626724348212,  -15.167970427918897",Cambridge Gulf
"114.39785812132891,  -26.18842853649905",Carbla
"118.69870816602706,  -31.441566867403758",Carrabin
"115.97523354591407,  -34.405703907951555",Channybearup
"115.17983387532409,  -28.00453769904146",Dartmoor (WA)
"113.05366398743661,  -25.793690281374655",Dirk Hartog Island
"116.9734646521199,  -32.77736500090597",Dryandra
"115.09409974411254,  -33.60808437946051",Dunsborough
"115.76819349228754,  -32.03789602686824",East Fremantle
"116.96303676348013,  -31.900833256972767",Greenhills
"116.84407073134007,  -22.091766238280265",Hamersley Range
"116.03156867716453,  -31.830188262305118",Herne Hill (WA)
"116.08918933371295,  -33.926703573917706",Hester Brook
"116.82220186183692,  -31.362922339230792",Hulongine
"115.72928144112937,  -31.735701342064864",Iluka (WA)
"123.84547179073914,  -33.422892535496565",Israelite Bay
"116.71962978912374,  -31.463958949596314",Jennacubbine
"116.7183115600736,  -31.225491391619485",Karranadgin
"115.86652412790345,  -31.84143386734784",Koondoola
"118.09712265853096,  -32.29859529711078",Kurrenkutten
"117.73330971136201,  -31.988807100398187",Kwolyin
"115.79929593427936,  -32.26763922116896",Leda
"115.95029174724868,  -31.88163801497967",Lockridge
"114.51326280394994,  -23.326735843640773",Lyndon
"115.8435034207843,  -32.19293809052542",Mandogalup
"116.03668379966994,  -31.807736326802896",Millendon
"116.88701111798277,  -30.87405639447205",Mocardy
"117.64181161091886,  -31.763592395719083",Mount Caroline
"117.90222000944985,  -35.02517867768988",Mount Clarence
"116.06593028141452,  -33.39597314394206",Mungalup
"117.57348452977615,  -31.127820779204203",Nembudding
"114.41072510820493,  -27.06528881696223",Nerren Nerren
"113.72726832391166,  -24.83377565695304",North Plantations
"118.15585108732633,  -31.109617844967122",Nungarin
"116.19475819143747,  -32.04956198894859",Pickering Brook
"116.14285240212428,  -33.41784873101972",Preston Settlement
"116.82590669629467,  -33.86854761387863",Qualeup
"116.4924219670683,  -32.793959023891084",Ranford
"120.02652622700153,  -33.52041766797738",Ravensthorpe
"115.72263250433232,  -31.661493627100526",Ridgewood (WA)
"115.73897267626998,  -32.282269728267394",Rockingham (WA)
"117.90012484203199,  -35.00518694530668",Spencer Park
"115.84971038179022,  -32.142477473695045",Success
"117.79214305412867,  -31.111710961115303",Trayning
"113.40283544661914,  -26.325247142283267",Useless Loop
"116.63028563091547,  -30.578958473420244",West Ballidu
"147.4737213521056,  -42.42800099721232",Baden
"147.08123544588503,  -41.463501194906826",Blackstone Heights
"147.1026757649799,  -42.76452561740916",Boyer
"146.6719905697272,  -41.82931891544759",Brandum
"147.17439542491866,  -41.5170627535291",Breadalbane (Tas.)
"146.70492545497908,  -41.78407473417862",Breona
"147.4365712768649,  -42.61745721463347",Campania
"145.40047150559107,  -40.86518288619398",Crayfish Creek
"147.8593990856581,  -42.88520774896354",Dunalley
"148.2824259281978,  -41.57915446415616",Four Mile Creek (Tas.)
"147.1501504142612,  -43.18052164952775",Gardners Bay
"146.92534923774699,  -43.04211258361681",Glen Huon
"148.06214292730584,  -41.18968068054003",Goulds Country
"147.2801765258172,  -42.72613101628822",Honeywood
"147.04454983310606,  -42.83849628303026",Lachlan
"147.04466376977442,  -41.37293597116445",Legana
"147.01500925446817,  -43.15254294759868",Lower Wattle Grove
"145.17751039300006,  -42.20896688799994",Macquarie Heads
"147.85309716980407,  -41.57942608383663",Mangana
"145.47252092974276,  -40.94978893747345",Montumana
"146.72114244499326,  -42.707768616667046",National Park
"146.76284962142134,  -42.323637114336464",Osterley (Tas.)
"146.26697095308734,  -41.265163273479885",Paloona
"147.01233131472011,  -43.184805962217574",Petcheys Bay
"147.20127956729465,  -41.07153610196287",Pipers Brook
"146.5376439390295,  -41.17151705967431",Port Sorell
"147.20957726740863,  -41.50401123840354",Relbia
"147.51091779040715,  -42.0817283071478",Ross (Tas.)
"148.01113956493055,  -40.8570710894931",Rushy Lagoon
"146.9077330752513,  -42.09639904941596",Steppes
"146.41291455230774,  -41.39336619897341",Stoodley
"146.98546172323904,  -43.22682631778436",Surges Bay
"147.7107594018435,  -41.28612369554601",Talawa
"145.71949138859466,  -41.22133316077585",Tewkesbury
"147.21159517549455,  -41.55242641608338",Western Junction
"131.05738052724908,  -13.089712856898853",Batchelor
"130.8518768926635,  -12.396019509040693",Coconut Grove
"130.92412680464173,  -12.383960547335846",Holmes
"130.83264303418792,  -17.444467693487148",Kalkarindji
"132.26001064528745,  -14.477332523924426",Katherine South
"133.88734225268257,  -23.765609409568416",Kilgariff
"131.26975040420677,  -23.256135528200787",Mount Liebig
"131.0682487394759,  -12.274505641417925",Murrumujuk
"130.9598516979858,  -12.457614420237297",Pinelands (NT)
"130.85921154631495,  -12.380440507667425",Rapid Creek
"149.06761277421057,  -35.23561410392012",Belconnen
"149.0374963947712,  -35.35614518334443",Chapman
"149.0319503937542,  -35.33453635517259",Duffy
"149.14594988799055,  -35.16855845730845",Forde
"149.04614811006974,  -35.334473814722095",Holder (ACT)
"149.01099522747313,  -35.20979967286438",Macgregor (ACT)
"149.09063020828293,  -35.415774968342056",Monash (ACT)
"149.1114575178044,  -35.17280138229058",Ngunnawal
"149.03903038183037,  -35.23465404860371",Scullin
"150.6911626167728,  -35.152336149093216",Jervis Bay
"148.44037306190117,  -35.041488276789146",Adjungbilly
"148.7379330009042,  -32.5559428306768",Arthurville
"151.1188044078186,  -33.90015318343622",Ashbury
"148.7204263775135,  -36.39806109590924",Avonside
"152.7929324876654,  -28.722330916521024",Babyl Creek
"151.18009328990763,  -33.85822126999961",Balmain
"151.12539192459158,  -33.935058163653494",Bardwell Park
"151.13212056377776,  -33.937332782730564",Bardwell Valley
"150.95351639847703,  -33.7392980394088",Bella Vista
"151.6735216464926,  -33.0301673270946",Belmont (NSW)
"151.64970390138745,  -30.009469024125366",Ben Lomond (NSW)
"151.10963902803368,  -33.989688086276956",Blakehurst
"147.49155150579665,  -32.025931825357624",Bogan
"151.25093200268867,  -33.893064600573325",Bondi Junction
"150.3289002410657,  -35.05752136043935",Boolijah
"153.0567921399489,  -28.684760535859517",Boorabee Park
"151.54568785044384,  -33.111352300173195",Brightwaters
"146.44366173697634,  -34.376718878481604",Brobenah
"151.48599607550474,  -30.178188728986356",Brushy Creek
"150.14438665024335,  -34.32816618853898",Bullio
"149.11007646002605,  -35.83197681131948",Bumbalong
"152.7727704769919,  -29.06307533720661",Busbys Flat
"150.12128257540235,  -32.40445966867883",Bylong
"150.56156344619805,  -34.815153811671586",Cambewarra
"152.44163517147769,  -29.48716313930355",Cangai
"150.4612509161047,  -31.012536599403692",Carroll
"150.7650672957834,  -33.991710637740205",Catherine Field
"151.62480741717727,  -33.14730695757094",Catherine Hill Bay
"150.84183684925665,  -33.88663361380255",Cecil Hills
"150.07775644973958,  -36.304776311744995",Central Tilba
"151.69393114938816,  -32.96666695432125",Charlestown (NSW)
"149.63710153164914,  -33.6709429733482",Charlton (NSW)
"151.04612841646008,  -33.7219228093829",Cherrybrook
"153.3739348438295,  -28.453741128525998",Chowan Creek
"153.10768470355515,  -30.298235755641425",Coffs Harbour
"151.30076250641133,  -33.73811511326125",Collaroy (NSW)
"152.87341026816244,  -30.75382852395855",Congarinni
"151.91444168027527,  -31.58466740555608",Cooplacurripa
"152.89572528352323,  -29.858061617883003",Coutts Crossing
"149.67300551249647,  -32.98291115962654",Crudine
"146.43821294080766,  -33.257833099302374",Curlew Waters
"149.53080530936535,  -34.969125849901836",Currawang
"151.12577902537578,  -34.06409820709096",Dolans Bay
"151.28106587725082,  -33.873117846034994",Dover Heights
"151.1397086343946,  -33.90411223552442",Dulwich Hill
"150.83638397842665,  -32.35909058773373",Edderton
"152.95358380087546,  -28.690388427222278",Edenville
"150.80642761794377,  -33.74405269868907",Emerton
"151.36928808086313,  -33.504113432337306",Empire Bay
"146.3951301213941,  -33.056845984377944",Euabalong West
"150.7023665851441,  -34.82570417367529",Far Meadow
"149.36169456446908,  -33.275950648120734",Freemantle
"151.83774189776486,  -32.840543094494514",Fullerton Cove
"149.9120298400921,  -30.951199882858198",Ghoolendaadi
"152.64223772614446,  -30.561904374115077",Girralong
"150.7736674970804,  -34.08864666080467",Glen Alpine
"152.228890763921,  -29.916190854860616",Glen Nevis
"151.70815795867176,  -32.60566517339392",Glen Oak
"150.89352475389217,  -33.971477239381784",Glenfield (NSW)
"152.95549870865898,  -30.08227327278081",Glenreagh
"150.91268584107632,  -31.291510468139105",Goonoo Goonoo
"151.50604650993841,  -33.25500419504646",Gorokan
"149.84954788183535,  -36.97431092722714",Greigs Flat
"147.73281384609842,  -34.31924189429489",Grogan
"148.97606014641568,  -30.565356301618785",Gwabegar
"151.53837307777917,  -32.29886769940213",Halton
"151.4708651135243,  -33.25405789638179",Hamlyn Terrace
"151.20498985537805,  -33.880980664912876",Haymarket
"150.82385656139073,  -33.74571377168626",Hebersham
"151.674122060003,  -32.8501298941314",Hexham (NSW)
"151.63641051870164,  -32.49819517607704",Hilldale
"151.06835560577377,  -33.86273658176857",Homebush West
"151.137998033864,  -33.837933632028424",Huntleys Cove
"148.35040331328418,  -36.744108237435576",Ingeegoodbee
"152.86122830787556,  -28.637012799722317",Iron Pot Creek
"148.97193498366482,  -35.056266863045124",Jeir
"150.13926840990786,  -35.81436999566809",Jeremadra
"150.74244474652073,  -33.72733690701939",Jordan Springs
"150.3001467369609,  -33.70616221201774",Katoomba
"153.54362369138207,  -28.894223454148673",Keith Hall
"143.98370760049363,  -34.77431400755392",Keri Keri
"145.27820097571123,  -31.00913454302345",Kerrigundi
"151.0584329998091,  -31.059747201500215",Kootingal
"152.4469055199174,  -28.37005400218262",Koreelah
"152.82228619329933,  -31.205189104610838",Kundabung
"151.6061227748432,  -32.704652386703636",Largs
"150.79510258385312,  -33.97042202893086",Leppington
"151.57252755213568,  -32.726992488023285",Lorn
"152.60532946779878,  -31.66202391060129",Lorne (NSW)
"152.60407021856423,  -28.81810711386273",Lower Bottle Creek
"151.55384863987726,  -32.67523789805893",Maitland Vale
"142.53276709585774,  -34.23518267441449",Mallee
"150.28078097987503,  -34.43890785656512",Mandemar
"151.26653887816718,  -33.7822834205385",Manly Vale
"150.64661911406182,  -32.59610040740192",Martindale
"151.42717465156997,  -33.40657906669004",Matcham
"151.23183036727988,  -33.96349177800579",Matraville
"151.73814545740578,  -32.89771945242896",Mayfield (Newcastle - NSW)
"150.75190909285567,  -34.100928248220704",Menangle Park
"149.55672935046005,  -33.427279727068004",Mitchell (NSW)
"144.75134040905712,  -36.006863838694926",Moama
"149.9787793343227,  -36.693763872465496",Mogareeka
"150.03258144102534,  -35.85865046875549",Mogendoura
"148.3448570272886,  -34.16515834201716",Monteagle
"151.2222270384755,  -33.463805271755945",Mooney Mooney Creek
"150.34196984314897,  -35.3390704063027",Mount Kingiman
"150.86819218519219,  -34.395794444223334",Mount Pleasant (NSW)
"153.27606724985088,  -28.38977260852357",Mount Warning
"151.67106277205093,  -32.31797245749037",Munni
"149.10641017053348,  -36.44753711071542",Myalla (NSW)
"153.23809107870233,  -28.58023505783432",Nimbin
"151.2914043484074,  -33.762830738933395",North Curl Curl
"150.4253994745126,  -28.918468199030656",North Star
"151.178452637328,  -33.82820923117429",Northwood (NSW)
"145.97604584919614,  -35.535343305690944",Nyora (NSW)
"148.53989183286473,  -33.53400153012691",Nyrang Creek
"152.91500444398082,  -31.05482942796335",Old Station
"151.21719220469436,  -33.9380329402491",Pagewood
"151.47070240951567,  -29.88946955087106",Paradise (NSW)
"151.03301891307876,  -32.90305171411166",Paynes Crossing
"151.30555552455561,  -33.54224112390974",Pearl Beach
"151.00605805275498,  -33.973728883198426",Picnic Point
"153.43699207996502,  -28.198246402008085",Piggabeen
"149.20705391517865,  -35.357989564050165",Queanbeyan West
"150.69513014752806,  -31.415751777033694",Quipolly
"150.41623468789567,  -30.674464338799083",Rangari
"147.267908978204,  -34.61286321566394",Rannock
"146.13071915492665,  -35.8169396699973",Rennie
"150.47356072754212,  -34.449873419862136",Renwick
"149.39137158399566,  -33.35743236087835",Rock Forest
"151.0754308770057,  -33.93354872946012",Roselands
"152.73142888514244,  -31.66709627829729",Rossglen
"150.91154677983556,  -33.67675851784918",Rouse Hill
"148.91468924095017,  -29.74907676441787",Rowena
"151.04021156089493,  -33.81269619457478",Rydalmere
"152.7972080182877,  -31.445166773906582",Sancrox
"152.26525828266065,  -28.951399008766867",Sandy Hill
"151.58940480108512,  -30.57627421861391",Saumarez
"150.86829141839922,  -33.70323860235919",Schofields
"152.50611228377016,  -32.42270543760158",Seal Rocks
"152.83331921948638,  -29.80118718189355",Shannondale
"152.4921317881221,  -32.38149169470831",Smiths Lake
"152.8717588908057,  -30.54723900751629",Spicketts Creek
"150.9017759702859,  -33.88290948932522",St Johns Park
"151.0685794533662,  -29.986897803935022",Stanborough
"150.5969612179074,  -33.70793262491622",Sun Valley (NSW)
"151.2120424257564,  -33.885541109250994",Surry Hills
"151.18238832439746,  -32.88908639630203",Sweetmans Creek
"145.8055389539482,  -34.10927687861096",Tabbita
"151.45815932305047,  -33.285159076876",Tacoma
"147.71227495259583,  -35.287015766836504",Tarcutta
"151.3137932933034,  -33.45021017574443",Tascott
"147.88515564798396,  -31.577301350179802",Tenandra
"153.39818614371137,  -29.173952379543977",The Gap (Richmond Valley - NSW)
"151.20833295750228,  -33.859238725719656",The Rocks (Sydney - NSW)
"152.86307029819636,  -29.59954063713577",The Whiteman
"151.75089399190014,  -32.90717038805812",Tighes Hill
"152.52101119068485,  -32.29936988218012",Tiona
"143.66037708301965,  -35.01947005486031",Tooranie
"151.47543424755352,  -33.28267537132766",Tuggerawong
"153.29192346805428,  -28.752309199157093",Tullera
"152.48562821935107,  -32.160626541429224",Tuncurry
"151.60967161162475,  -32.28427944097803",Underbank
"150.6608328613268,  -30.600962891498096",Upper Manilla
"152.99201133144243,  -30.515130374616817",Urunga
"151.56256842867688,  -32.53478548813922",Vacy
"153.00835316876848,  -30.58922023849349",Valla Beach
"149.91807263244556,  -36.76684737497832",Wallagoot
"151.47068306710662,  -30.015307150650287",Wandsworth
"153.44999912146275,  -28.95414161363853",Wardell
"151.65172858034228,  -32.971064444228695",Warners Bay
"153.037935698036,  -28.445377993118406",Warrazambil Creek
"149.68468560473409,  -33.14061408402019",Wattle Flat (NSW)
"149.4178649486754,  -33.31151848834576",Watton
"150.97017404021125,  -33.8070215047714",Wentworthville
"148.21980963865414,  -35.387888280776025",Wereboldera
"150.57982254210268,  -33.98610859632735",Werombi
"153.2093150650456,  -28.998765900573005",West Coraki
"150.83236248299835,  -33.93386683071048",West Hoxton
"143.11916511686778,  -30.26325583937033",White Cliffs
"151.2062895273545,  -33.799083330564784",Willoughby East
"149.8779892618394,  -32.32678567950995",Wilpinjong
"149.9137039048639,  -35.01376147482852",Windellama
"149.95834266892,  -34.308489989294245",Wombeyan Caves
"151.24412818172505,  -33.88693961136293",Woollahra
"149.10502489117195,  -32.59631076338196",Yarragal
"150.96824439954656,  -33.861748907135635",Yennora
"146.3785204898876,  -37.699794103329765",Aberfeldy
"144.66365398402274,  -37.71717977861765",Aintree
"145.0202965727958,  -37.85665876524626",Armadale (Vic.)
"146.77200539172978,  -36.515458067364705",Barwidgee
"145.26467703931874,  -37.84517120472908",Bayswater (Vic.)
"145.63353614691474,  -37.898660929148846",Beenak
"146.82801516908918,  -37.25881316505358",Billabong
"147.5642188709766,  -37.00885241551503",Bingo Munjie
"141.16909351329298,  -36.8358855599345",Bringalbert
"141.3196659784134,  -36.1509806772603",Broughton (Vic.)
"144.97744372059017,  -37.76886790932495",Brunswick East
"148.10385794702054,  -37.5647387343825",Buchan South
"144.7611344978179,  -37.676251321046635",Calder Park
"143.55940300983343,  -38.79753308529054",Cape Otway
"143.91636994602246,  -35.60898031827347",Capels Crossing
"145.4181678524964,  -38.142739191210595",Cardinia
"141.0951205489524,  -35.214193293501474",Carina (Vic.)
"143.78826198696598,  -37.28829587503986",Clunes (Vic.)
"146.26642047379877,  -38.239155898365894",Coalville
"145.2975429818316,  -36.2736842800969",Coomboona
"143.2127872525635,  -37.489463814801844",Cross Roads (Vic.)
"145.42748848689877,  -37.59359570761027",Dixons Creek
"144.2566912443808,  -36.68817205521115",Eaglehawk North
"143.87063345722873,  -36.90453873489267",Eddington
"144.13292612574665,  -37.294910468984014",Elevated Plains
"143.73054413778624,  -35.45158299749228",Fish Point
"146.67599275243123,  -38.19647315411541",Flynn (Vic.)
"146.9701449433369,  -38.0813641044862",Fulham (Vic.)
"144.9558784248735,  -37.11632377097774",Glenaroua
"147.362715652287,  -38.2296077202739",Golden Beach (Vic.)
"145.43616901298537,  -36.348331401364426",Grahamvale
"143.8213762575797,  -38.685167988287894",Grey River
"147.07249850575948,  -36.3773631539206",Gundowring
"143.78514101878199,  -36.96739606254041",Havelock
"142.19427553943143,  -36.76071169131256",Haven
"143.8834779856551,  -38.08221980375796",Hesse
"143.09123265503558,  -38.367008797305864",Jancourt
"143.42603519990826,  -36.01395286822379",Jeruk
"144.80472774730703,  -37.72055807478412",Keilor Downs
"144.852010035325,  -37.719139493666596",Keilor Park
"145.38974717762784,  -36.47896454914161",Kialla West
"142.3192827688081,  -38.344425250340066",Killarney (Vic.)
"143.95678046470448,  -37.37822251095915",Kingston (Vic.)
"143.79128310124634,  -36.35486217955466",Kinypanial
"145.5381835928334,  -38.14134834331836",Koo Wee Rup North
"144.71289602997618,  -37.2602814065576",Lancefield
"143.89821523143857,  -37.325484438274685",Lawrence (Vic.)
"145.94792339301563,  -36.75124224563868",Lima
"143.35093053170294,  -37.96909628736487",Lismore (Vic.)
"147.03765920710114,  -37.90617947048058",Llowalong
"143.9950496079655,  -35.92554272761116",Macorna
"144.0818053408236,  -35.84472730217088",Macorna North
"146.503566806193,  -38.49836471119815",Madalya
"144.41954911461386,  -37.69738924601791",Maddingley
"144.55415913968324,  -37.899611390855924",Mambourin
"144.1632338933524,  -35.848934133336705",McMillans
"144.57033609519493,  -37.706661870990686",Melton South
"144.47717653331532,  -37.65708283192826",Merrimu
"144.90061988860396,  -37.53979618931947",Mickleham
"145.53389773536014,  -38.21164510742966",Monomeith
"145.34689910971883,  -37.812739529055676",Montrose (Vic.)
"142.11765883632808,  -37.39404365372421",Mooralla
"141.83774319057653,  -37.728855114849104",Morgiana
"143.12430601795984,  -37.219969888224554",Mount Cole Creek
"144.3075000770835,  -38.246122552263735",Mount Duneed
"143.74398740056958,  -38.6339363297067",Mount Sabine
"145.30549585595512,  -38.018854511784056",Narre Warren
"144.76467995420072,  -37.17197856720768",Nulla Vale
"145.09573048050564,  -37.9005917725353",Oakleigh
"146.3915507478884,  -36.398420080899726",Oxley Flats
"143.7262922015407,  -36.79471842781338",Painswick
"144.93804236090926,  -37.72491831880694",Pascoe Vale
"144.634421646076,  -37.177604364639016",Pastoria East
"143.73118861178452,  -38.713457624447884",Petticoat Creek
"145.09065235085285,  -35.95747255128447",Picola
"141.0840905785448,  -37.192299247481394",Poolaijelo
"144.1996070436204,  -36.89190574793631",Ravenswood (Vic.)
"143.75452514874618,  -37.17055483578711",Red Lion
"142.02882589917758,  -37.25839174960458",Rocklands
"146.49242967422472,  -36.058930686657014",Rutherglen
"144.19817170832386,  -36.59445991836884",Sebastian
"145.19892870456633,  -38.232230097180604",Somerville (Vic.)
"143.19455884167093,  -36.532772477702295",Sutherland (Vic.)
"149.2058040301613,  -37.69510830135349",Tamboon
"146.15959363743664,  -37.85357174693396",Tanjil Bren
"145.3712158035573,  -36.978346697667604",Tarcombe
"145.44361953746818,  -37.65587924127324",Tarrawarra
"145.88862788703227,  -38.27758439698169",Tetoora Road
"146.4393906534835,  -37.72123866931471",Toombon
"144.87248153522512,  -37.704959014210885",Tullamarine
"142.5840544757914,  -35.447575803454555",Turriff East
"145.31014816808067,  -37.89777415775691",Upper Ferntree Gully
"146.4213063336536,  -36.00472763185792",Wahgunyah
"142.49823881145542,  -38.36884786069886",Warrnambool
"144.29140273102107,  -35.862049249844375",Wee Wee Rup
"147.48997097886098,  -37.31077308552745",Wentworth (Vic.)
"146.3830668385235,  -38.54539892574309",Wonyip
"145.6106229943033,  -38.39320171898987",Woodleigh (Vic.)
"147.42670277101286,  -37.74506138705389",Wuk Wuk
"147.0338724132711,  -38.10946515775793",Wurruk
"143.45473637495917,  -38.65577701861611",Wyelangta
"153.06763730712194,  -27.45627878160808",Balmoral (Qld)
"145.39180741183856,  -16.351461260856624",Bamboo
"152.76774731345637,  -28.23226205279538",Barney View
"150.09483532077408,  -27.069787926712838",Barramornie
"146.79619175242556,  -19.53772434536967",Barringha
"151.3036502052027,  -24.043966253524655",Benaraby
"153.38583285631267,  -28.00761718163754",Benowa
"153.082773671196,  -27.658979615250754",Berrinba
"149.15657339792344,  -23.13063911328729",Bingegang
"152.21067418742643,  -27.89854930201387",Black Duck Creek
"146.72022897315188,  -19.245668378080833",Bohle
"153.4149021754158,  -28.02693709764675",Broadbeach Waters
"153.4331715474722,  -28.104830651745566",Burleigh Heads
"151.1614178128388,  -28.227385855146707",Canning Creek
"153.25653395323977,  -27.674236163437573",Carbrook
"152.99145586657016,  -28.179193876264453",Christmas Creek
"152.89267811390843,  -27.320084766629158",Clear Mountain
"152.35345162461135,  -27.55475425030247",College View
"152.50700840286453,  -27.104937694429466",Cooeeimbardi
"152.95086659201758,  -26.42945141311225",Cooroy Mountain
"145.91243069799026,  -17.56919402617173",Coorumba
"146.06834179528497,  -17.530490498930455",Coquette Point
"152.98207278319376,  -27.543807121199716",Corinda
"152.07467052212283,  -27.256378335596757",Crows Nest (Qld)
"151.5821094114357,  -26.47378753246524",Dangore
"144.86969404674596,  -16.416571708132803",Desailly
"139.77927345705083,  -21.28393257504806",Duchess
"152.82473398397244,  -27.608234617316562",Ebbw Vale
"147.23091049357035,  -20.28263265208067",Eight Mile Creek (Qld)
"152.98749515902503,  -27.40049686281562",Everton Park
"152.05442122691088,  -27.418729071337697",Fifteen Mile
"149.5684695015742,  -27.86406622540067",Flinton
"146.87285665493633,  -19.118114862079185",Florence Bay
"152.9637236718606,  -27.621805974002573",Forest Lake
"146.27412517526278,  -18.70832146954819",Forrest Beach (Qld)
"144.26816159003067,  -17.873465445421353",Fossilbrook
"149.29227505219623,  -21.41659821466708",Freshwater Point
"138.987572799671,  -16.96481070542037",Gangalidda
"146.43172842067563,  -26.155112823407734",Gowrie Station
"152.98181668666064,  -27.521593268948386",Graceville
"153.15802689319787,  -27.49646309471293",Gumdale
"152.0692647424915,  -27.370657034996032",Hampton (Qld)
"146.11635361229295,  -18.575352998563506",Hawkins Creek
"153.17096996524225,  -27.71551206977403",Holmview
"152.3305718580232,  -25.230109312386702",Horton
"152.9783222598261,  -27.506319164604793",Indooroopilly
"151.09853120798593,  -26.888148064054782",Jimbour West
"152.92798398208646,  -27.49551293964518",Kenmore Hills
"151.90900402991844,  -27.89874058082005",Kings Creek
"145.5538613462409,  -17.81669318379108",Koombooloomba
"150.61998608769588,  -23.112682746807206",Lake Mary
"153.37993381673252,  -27.624832663525613",Lamb Island
"150.5646834001209,  -23.28848051714108",Limestone Creek
"145.88719409876873,  -18.326002522036344",Lumholtz
"151.39146849667148,  -29.11901161117415",Maidenhead
"153.16645760852361,  -27.46697930483696",Manly West
"146.0387358595722,  -17.817063932471033",Maria Creeks
"149.8649352859246,  -27.303229087346246",Meandarra
"151.0666215987943,  -27.985802525174506",Millmerran Downs
"146.67131735715535,  -19.772374791352732",Mingela
"151.52922271295895,  -28.940964631169354",Mingoola (Qld)
"147.98585648942216,  -26.45286613632883",Mitchell (Qld)
"152.43904217691497,  -24.806400473126914",Mon Repos
"152.52812345655138,  -28.017960928310444",Mount Edwards
"152.62515758092178,  -27.999405806751696",Mount French
"148.9694652060716,  -20.982582410025596",Mount Jukes
"149.1598062017607,  -21.11797654448115",Mount Pleasant (Mackay - Qld)
"150.61011757889668,  -23.412248001976582",Nerimbera
"142.43867292214657,  -10.787593695047159",New Mapoon
"146.00945248524127,  -17.723849641426472",No 5 Branch
"146.21758603277513,  -28.730419277226748",Noorama
"149.22011711501904,  -27.081178444018814",Noorindoo
"152.34348824760625,  -24.893199090314134",Norville
"153.13612520313728,  -28.21231995429191","[""O'Reilly""]"
"152.74116470776949,  -27.634719343624855",One Mile (Qld)
"152.6237100403077,  -25.67235151200167",Owanyilla
"145.28256074034286,  -16.91759273800914",Paddys Green
"153.39484011091412,  -27.879758424171918",Paradise Point
"151.8151942981878,  -28.520965835357828",Passchendaele
"148.41829178694974,  -20.479080357560925",Pauls Pocket
"152.88409855494734,  -27.518075005194447",Pullenvale
"152.77801026480483,  -27.63905861082084",Raceview
"150.77496759957108,  -23.831398328302964",Raglan (Qld)
"152.36124158027533,  -25.169855453937252",Redridge
"153.13156983555817,  -27.599553453243484",Rochedale South
"152.74414021212417,  -26.097419051026087",Ross Creek (Qld)
"146.80267265248798,  -19.29790524898564",Rosslea
"151.58364515862775,  -27.627074920092394",Rossvale
"152.02129223059723,  -24.493133785080794",Rules Beach
"145.72048755179065,  -25.548337716540747",Scrubby Creek (Blackall Tambo - Qld)
"152.2467111154035,  -24.87253950412508",Sharon
"152.9310291128759,  -27.781124814668992",Silverbark Ridge
"151.26671202403259,  -23.86412549733164",South Gladstone
"146.04564397973147,  -17.54582840603017",South Innisfail
"149.18205434827988,  -21.16540234226429",South Mackay
"150.3396550050587,  -23.196927170389475",South Yaamba
"152.48324961209332,  -25.692202013372984",St Mary
"150.89768747343464,  -24.333765718690984",Tablelands (Gladstone - Qld)
"153.1704829992411,  -27.671782448353223",Tanah Merah
"150.97015556807975,  -27.972383980398146",The Pines (Qld)
"151.26866693270233,  -23.883603882025398",Toolooa
"152.36021539241423,  -28.06671033173227",Tregony
"152.6182076651754,  -27.467595605095866",Vernor
"145.41722686615364,  -17.13673309720845",Walkamin
"145.84559856727105,  -18.674294407049906",Wallaman
"149.52212145811995,  -23.66122924765689",Wallaroo (Qld)
"149.15941249367182,  -26.756018768073744",Wallumbilla South
"150.64870387692508,  -27.712520457766736",Weir River
"146.7941844406754,  -19.26537401501115",West End (Townsville - Qld)
"152.91291900661147,  -27.548662177504347",Westlake
"151.91680408424781,  -27.542995864926997",Wilsonton
"153.337358893983,  -28.04078455390057",Worongary
"152.8114667235829,  -27.92498781834862",Wyaralong
"152.36029103753276,  -27.028441557644157",Yimbun
"145.71712504197276,  -16.82766967845342",Yorkeys Knob
"144.6071489667462,  -27.79192389118761",Yowah
"136.6151699572155,  -30.776765388604673",Andamooka Station
"137.56666446953034,  -35.60780909579724",Bay Of Shoals
"138.21303242665252,  -33.18757478633587",Beetaloo Valley
"140.8672922810956,  -36.78375769673171",Binnum
"138.5766446169645,  -34.960331240739265",Black Forest
"138.6128355673377,  -35.02250692365785",Blackwood (SA)
"138.57417290427372,  -34.76994458184938",Bolivar
"138.40213241090237,  -33.671874201558346",Brinkworth
"137.84246121501192,  -35.79825005883876",Brown Beach
"139.00393668679948,  -34.20968076727696",Buchanan (SA)
"136.11669467632893,  -33.276735768839",Caralue
"138.0436441066806,  -33.19209777113547",Coonamia
"137.61110774023302,  -34.057750847788334",Cross Roads (SA)
"138.58769427430914,  -34.98547329297274",Daw Park
"138.5369246288699,  -35.0239712805642",Dover Gardens
"137.8736687750805,  -35.818817128774235",Dudley West
"138.66762903248744,  -34.74885877830532",Elizabeth Vale
"137.97057016177175,  -33.51553912057735",Fisherman Bay
"138.26345014342746,  -31.55100181427952",Flinders Ranges
"140.3373980419485,  -37.25278608566616",Fox
"139.79805213609654,  -32.74641229947887",Grampus
"138.51525513698888,  -34.874587920638916",Hendon (SA)
"138.57002017338797,  -34.90660918125583",Hindmarsh (SA)
"139.16307354149464,  -34.56075612745705",Keyneton
"139.81095284397074,  -30.731242476892984",Lake Frome
"138.67496783865025,  -34.909092403877544",Magill
"137.96549823376097,  -32.81291139326054",Mambray Creek
"138.74975729270722,  -32.54405519538956",Minburra
"137.1391538384257,  -33.58275758782575",Mitchellville
"135.0561187991377,  -33.52140569413262",Mount Joy
"133.49833863978475,  -32.0952876317842",Nadia
"137.67769149616817,  -35.744251526919356",Nepean Bay
"138.74930819029092,  -34.71708571516997",One Tree Hill
"139.86708603692986,  -34.94546363783027",Perponda
"140.98320692234378,  -32.08759071246612",Pine Creek Station
"138.20122390635012,  -33.527846308059665",Redhill
"140.26966295608293,  -34.98890683745702",Sandalwood
"138.7013607521717,  -35.05878060846203",Scott Creek
"138.47768875758356,  -35.18580659479819",Seaford (SA)
"138.88306787831831,  -34.05994117387141",Steelton
"138.41384603991474,  -34.004722874231796",Stow
"138.77951587818578,  -34.263984737818625",Tarlee
"138.53986538767228,  -35.07166881152392",Trott Park
"138.9370493327556,  -33.31390747172783",Ulooloo
"138.6862506521592,  -34.078598324774795",Undalya
"138.62713055044142,  -34.88608004109365",Vale Park
"134.618404601047,  -32.321135230834486",Wallala
"138.6732013600736,  -34.58140814059279",Ward Belt
"134.55102744991572,  -32.406978953938605",Wirrulla
"140.89594567683713,  -38.01790222648422",Wye
"137.90347335529512,  -31.92111882370317",Yadlamalka
"117.01582204719958,  -33.351574740517854",Arthur River (WA)
"116.1881992846027,  -32.211756069677826",Ashendon
"113.6379326529619,  -24.88107745561177",Babbage Island
"115.89587825871953,  -31.839993218175305",Ballajura
"116.13688671576045,  -32.62918859684877",Banksiadale
"115.44991728030831,  -33.86476755340123",Baudin
"116.41575157607686,  -30.61728184946152",Bindi Bindi
"116.46082686174975,  -32.81371414100335",Boddington
"114.73018774772736,  -28.850015768445747",Bootenal
"117.39999273541233,  -34.053416252096596",Borderdale
"115.10989617692321,  -33.915383397053866",Bramley
"116.00202880351958,  -32.16708092150813",Brookdale (WA)
"115.78250561581905,  -31.852249946668884",Carine
"118.4213993636255,  -31.064241590829663",Chandler (WA)
"115.65897584063683,  -33.37582447319729",College Grove
"115.81924143104222,  -31.98051092700331",Crawley
"115.78121131734707,  -33.8505422153843",Cundinup
"117.85111963245558,  -33.420158442429724",Datatine
"113.59861834518398,  -25.862959058044527",Denham
"116.36677572325613,  -31.3967985860805",Dewars Pool
"116.07968302156873,  -34.39458177711442",Eastbrook
"115.8650604680822,  -31.775286956512765",Gnangara
"117.86439321929633,  -32.981165203113",Harrismith
"115.74350370426147,  -31.8087226295393",Hillarys
"128.60140972380447,  -18.80824509125511",Kundat Djaru
"115.90004121227014,  -31.93681429130174",Maylands (WA)
"116.7396393119927,  -33.607761400937335",Moodiarrup
"117.85362682697757,  -35.01971012248053",Mount Elphinstone
"115.15545138180396,  -29.1527328969718",Mount Horner
"119.21520453989234,  -32.88376960169111",Mount Sheridan (WA)
"118.83644558695892,  -22.21008874652196",Mulga Downs
"118.18647261119989,  -20.605833319304562",Mundabullangana
"116.11699692302051,  -33.60532526618244",Noggerup
"121.53922291700518,  -30.707384390125366",Parkeston
"115.77294445260173,  -32.49536894069653",Parklands (WA)
"115.75890959292819,  -33.60571101935224",Paynedale
"116.05053992312948,  -34.44174582309109",Pemberton
"115.50879376179091,  -33.524637892755074",Peppermint Grove Beach
"117.40931206421463,  -31.988245933476442",Quairading
"117.54945876577251,  -34.88857271933414",Redmond West
"115.7512447617402,  -30.952718249401194",Regans Ford
"116.64265217892677,  -33.97384346820363",Scotts Brook
"119.01649778806461,  -33.21840550950284",South Newdegate
"114.6363780890208,  -28.735012946360502",Spalding (WA)
"115.79420613795875,  -32.478088411333935",Stake Hill
"127.88096585431006,  -19.214342770545898",Sturt Creek
"116.07524812193698,  -32.000656751180145",Walliston
"116.14315174832757,  -31.162271174622145",Wannamal
"115.98960507684343,  -31.84547766135138",West Swan
"114.65316550126416,  -28.629392883900103",White Peak
"115.94091047267659,  -31.82526452443341",Whiteman
"116.26352120823722,  -33.68696399321458",Wilga
"117.82417310858185,  -32.79668983680747",Wogolin
"115.2586706966599,  -26.094028066809496",Yalardy
"115.76134566498517,  -31.423676906804584",Yeal
"117.5241688974505,  -34.98716725194078",Youngs Siding
"147.75664651601394,  -42.540286622882796",Buckland (Tas.)
"147.4433463447758,  -42.824263594391944",Cambridge (Tas.)
"148.0246591392155,  -40.77799235425602",Cape Portland
"147.22251058420403,  -42.78799519548599",Claremont (Tas.)
"147.59593587525654,  -41.21994146169039",Cuckoo
"147.3476020983872,  -43.075384147044325",Dennes Point
"146.1163604447944,  -41.44230621718584",Erriba
"146.7662174728285,  -42.65370589679251",Fentonbury
"147.26859228728614,  -42.74453004211521",Gagebrook
"147.18992726458782,  -42.76252759418681",Granton
"145.74720304971834,  -41.30279638507699",Hampshire
"146.88790504359272,  -43.40000308392761",Hastings (Tas.)
"147.14459426350683,  -41.43345682390499",Launceston
"145.2185640737226,  -41.00507368275768",Lileah
"146.9532796096341,  -41.16750451322253",Long Reach
"146.46503410267238,  -41.39367774534978",Merseylea
"146.94306662792832,  -42.02364641533329",Morass Bay
"146.2493960356318,  -41.48936320373503",Mount Roland
"148.13764909878043,  -40.875444338810794",Musselroe Bay
"147.42193878151804,  -42.359020859115994",Parattah
"147.03935922859026,  -43.2533593590283",Police Point
"146.5825223025593,  -41.52904789065547",Red Hills
"147.41377971073888,  -42.7387183925538",Richmond (Tas.)
"145.83313881523196,  -41.13717337471174",Ridgley
"147.73332671091939,  -41.26210633277464",Ringarooma
"147.37237420111722,  -42.80897831698234",Risdon Vale
"148.00439782804906,  -42.21713407975347",Rocky Hills
"147.35696469768604,  -42.87054097125547",Rosny
"146.94320789364863,  -43.494709524552164",Southport Lagoon
"146.95404513039546,  -43.36141601413835",Strathblane
"147.06561726127998,  -43.27767408970318",Surveyors Bay
"147.28141555430352,  -42.84953228643259",West Moonah
"147.81633075684567,  -41.07816992328531",Winnaleah
"133.88091875191145,  -23.69845980148217",Alice Springs
"135.5865331785785,  -20.640181151630006",Canteen Creek
"130.88242029402508,  -12.374006479087784",Casuarina (NT)
"130.51066201342684,  -12.763859872855681",Dundee Downs
"130.94240072040822,  -13.029434579319846",Finniss Valley
"131.10078360986375,  -12.686369078244278",Hughes (NT)
"130.16509883656255,  -22.687475851022974",Lake Mackay
"134.23724825098455,  -12.056687965468443",Maningrida
"135.9252892111992,  -16.279857705708167",McArthur
"130.73113566413392,  -13.721627901118175",Nauiyu
"132.1994239235896,  -22.261703112791242",Yuelamu
"149.04946376848306,  -35.61605018616534",ACT Remainder - Booth
"149.08042470108694,  -35.25818925034172",Aranda
"149.03414520068557,  -35.20021924911246",Charnwood
"149.06640585185772,  -35.26011858958602",Cook
"149.08497486664746,  -35.45682732948824",Gordon (ACT)
"149.04940657074263,  -35.34962527753294",Stirling (ACT)
"151.12933737243864,  -33.85067103586454",Abbotsford (NSW)
"151.2363899089249,  -30.31806350069075",Abington (NSW)
"145.28687540034892,  -35.774228688070096",Aratula (NSW)
"148.96700867438182,  -34.75679245131211",Bango
"151.89559917670846,  -31.994965960825915",Barrington (NSW)
"151.25925221029888,  -33.75189293901902",Beacon Hill
"149.68339308757592,  -36.101920197619336",Belowra
"150.03619041450497,  -33.230464718306756",Ben Bullen
"148.6700023488738,  -32.508413021359594",Benolong
"150.11109488928025,  -29.960605576752958",Berrigal
"151.3152511129084,  -33.644514501785565",Bilgola Plateau
"153.55974320387702,  -28.33101767548286",Bogangar
"151.05431412693014,  -34.01077421639174",Bonnet Bay
"147.68663525075723,  -35.1535219277909",Borambola
"152.60194461371339,  -30.279323736186726",Bostobrick
"149.88304933888813,  -36.814426423160526",Bournda
"151.93329128828123,  -31.911413936842628",Bowman Farm
"150.8965791397932,  -33.652509053051546",Box Hill (NSW)
"148.67407689029844,  -36.09856852768283",Braemar Bay
"149.1720587986118,  -35.958670509864746",Bredbo
"150.72449732803693,  -33.94409177550049",Bringelly
"150.7088262512518,  -34.737518044740206",Broughton Vale
"152.15040714494162,  -32.10258339600695",Bucca Wauka
"152.18757194698867,  -31.58377561826171",Bulga Forest
"148.26272610512717,  -34.116509174212844",Bulla Creek
"149.13565696678205,  -29.414741005935625",Bullarah
"149.67945373963087,  -32.15915639172351",Bungaba
"150.53347759943935,  -34.25656916546535",Buxton (NSW)
"153.11736949496128,  -29.837450149075497",Calamia
"150.92396578751686,  -33.88260095845925",Canley Heights
"147.71732725256138,  -35.61216172065355",Carabost
"151.65788765301693,  -32.94178415096066",Cardiff (NSW)
"148.82414762645152,  -33.441222672342334",Cargo
"151.04543825874993,  -33.775974619817596",Carlingford
"153.09026255866706,  -28.602856565613234",Cawongla
"149.95965056730952,  -32.927819555229824",Clandulla
"150.21977785805075,  -33.47393809437449",Clarence
"149.7158670578967,  -33.33007251920908",Clear Creek
"152.37839223193296,  -30.729976771164043",Comara
"148.45475288762228,  -30.35623509230696",Come By Chance
"151.09522760405392,  -33.98585092769047",Connells Point
"150.97523924636968,  -33.793640328786",Constitution Hill
"149.3390649575419,  -36.91919125573431",Coolumbooka
"152.61746745674873,  -31.828503754999044",Coopernook
"150.77507986951522,  -34.38384586908247",Cordeaux
"153.0877966433154,  -29.577376746585013",Cowper
"149.37880343464838,  -36.75392145546069",Creewah
"149.78909217840388,  -32.75943419174754",Cudgegong
"148.4200029615571,  -34.57006708572352",Cunningar
"143.90623896148952,  -35.2460877710632",Cunninyeuk
"153.41267104286771,  -28.89415032621438",Dalwood (Ballina - NSW)
"148.85432631163843,  -33.904632208836986",Darbys Falls
"151.09762416637366,  -33.79609320892015",Denistone East
"152.93746753428687,  -28.80156128220216",Dobies Bight
"151.1445973614495,  -33.99349647965801",Dolls Point
"148.60157307464496,  -32.27950032389853",Dubbo
"152.51057479876832,  -31.908984594623",Dumaresq Island
"151.7649379606601,  -32.63763384335894",East Seaham
"151.52912052302378,  -33.064114219441755",Eraring
"151.18576853756542,  -33.902558479356976",Erskineville
"142.87789795207848,  -34.35544985443251",Euston
"152.44170867790072,  -32.09071105688675",Failford
"151.59657450007396,  -32.98424359996047",Fennell Bay
"152.6168804048569,  -30.34485577926063",Fernbrook
"151.53939363845552,  -32.490634169782496",Fishers Hill
"151.2103235155035,  -33.760379822929494",Forestville (NSW)
"151.40844889577778,  -32.48961214054413",Glendon Brook
"148.9934343624179,  -31.146672556479626",Goorianawa
"150.7769997272788,  -34.030182460171694",Gregory Hills
"149.14986057680986,  -36.7403122081779",Gunningrah
"151.12468546760977,  -32.59922653137148",Hambledon Hill
"151.13663119772016,  -33.84191552676837",Henley
"148.6925406453821,  -36.33303997531742",Hill Top (Snowy Monaro Regional - NSW)
"151.47647676201316,  -32.632718371682515",Hillsborough (Maitland - NSW)
"152.82649568522575,  -28.90538202923066",Hogarth Range
"147.31359388931006,  -35.697035560839204",Holbrook
"147.75230337695038,  -35.48128547098273",Humula
"151.1007727290703,  -33.96162970191445",Hurstville
"150.6857742773157,  -35.10932762087986",Hyams Beach
"150.80080754376644,  -30.2926352003275",Ironbark (NSW)
"148.34175292847624,  -36.14926089944116",Jagungal Wilderness
"149.4648158972323,  -29.872362741285468",Jews Lagoon
"148.3360035763048,  -34.7945942918725",Jugiong
"151.45971831238262,  -30.80398222192925",Kentucky South
"151.5920603588979,  -33.02391784325445",Kilaben Bay
"153.37431139261307,  -29.030482780983316",Kilgin
"151.4608522378418,  -33.36780507014603",Killarney Vale
"153.54894435973955,  -28.299323495598454",Kings Forest
"150.9283194861418,  -31.160882494363573",Kingswood (Tamworth Regional - NSW)
"150.37424874535554,  -35.54855939385803",Kioloa
"153.23851548050055,  -28.50132392719029",Kunghur Creek
"145.72190280983762,  -35.80914964594129",Lalalty
"148.09792082357083,  -35.60309359337878",Laurel Hill
"151.11575252830846,  -34.06768246252986",Lilli Pilli (Sutherland Shire - NSW)
"147.324045037855,  -35.14934322919497",Lloyd
"151.12076212344832,  -32.56522552982141",Long Point (Singleton - NSW)
"151.36162665216273,  -32.748419160707314",Lovedale
"149.7862338614499,  -35.1388690627343",Lower Boro
"150.89705805689786,  -33.935784504778816",Lurnea
"147.94633187472343,  -35.836963939029",Mannus
"148.93725010551134,  -34.90279812358613",Marchmont
"149.99973687373543,  -34.729455978509435",Marulan
"152.04580848003516,  -28.539397809005095",Maryland (Tenterfield - NSW)
"152.77315999291193,  -30.301472328881466",Megan
"150.7210709168333,  -34.13758710136984",Menangle
"145.66180568624523,  -33.78874471167159",Merriwagga
"146.92154484562138,  -31.58653601088265",Miandetta (NSW)
"148.14101760673162,  -35.218140115237205",Minjary
"151.55558770494736,  -33.11178274046579",Mirrabooka (NSW)
"148.9390052803657,  -32.530342826138",Montefiores
"147.35573560598425,  -35.13690782744812",Mount Austin
"151.13552763647323,  -33.64629251879653",Mount Kuring-Gai
"152.86369767830132,  -29.268273300972623",Mount Marsh
"150.88057939308905,  -34.400613626178234",Mount Ousley
"153.4969775369294,  -28.546905709663733",Mullumbimby
"149.07790328901783,  -32.71883692736986",Mumbil
"147.22421062034502,  -31.180991797215604",Murrawombie
"153.50604293434048,  -28.595951921885167",Myocum
"153.02117075029537,  -29.152444279632853",Myrtle Creek (NSW)
"152.98472036882424,  -30.646507880935836",Nambucca Heads
"150.05142524691087,  -36.23770142636675",Narooma
"151.06947145647598,  -33.94640732492019",Narwee
"151.41627408527324,  -32.82221248942005",Neath
"146.07567436508577,  -29.744732141059934",North Bourke
"153.06741707248895,  -28.80190467830444",North Casino
"152.81394577019478,  -31.633671951917403",North Haven (NSW)
"150.72040845759892,  -33.568037109143965",North Richmond
"148.29249852593847,  -35.796522929348065",Nurenmerenmong
"148.17165053391327,  -35.7599823633317",Paddys River (Snowy Valleys - NSW)
"151.03210073700004,  -33.95050436604402",Padstow
"150.99705569003743,  -33.95355498568215",Panania
"150.90322016542876,  -33.81716308885734",Pemulwuy
"148.68676881447502,  -30.890614772202966",Pine Grove (NSW)
"151.6356725861001,  -33.118021599781365",Pinny Beach
"151.21645194086952,  -33.97602347043652",Port Botany
"151.1403483359128,  -33.744501409122414",Pymble
"151.2472981650889,  -33.89943973481598",Queens Park (NSW)
"149.54345763951815,  -33.69970177259847",Rockley
"151.06773729150248,  -34.11140958687262",Royal National Park
"149.85380402304554,  -33.05700667021449",Running Stream
"150.6007475666276,  -30.84274769914416",Rushes Creek
"151.51204819681436,  -32.71246200666332",Rutherford
"150.57030859736747,  -32.34652054701569",Sandy Hollow
"151.55674958289143,  -30.500275012896893",Saumarez Ponds
"147.5393585772888,  -34.575123116942315",Sebastopol (NSW)
"149.85616085698666,  -36.948178077101986",South Pambula
"151.62790673292645,  -32.962168399740065",Speers Point
"151.69128089378808,  -32.38123641616974",Sugarloaf (NSW)
"150.20777904942634,  -35.74562833132399",Sunshine Bay
"151.0568437162206,  -34.0308899306296",Sutherland (NSW)
"150.16156771131244,  -34.75902181157127",Tallong
"153.53566088625115,  -28.336005936040753",Tanglewood
"151.16323006813812,  -33.92510159967897",Tempe
"150.73753643854607,  -33.53295383086112",Tennyson (NSW)
"152.38535083616355,  -31.883041222073626",The Bight
"149.0782403919972,  -36.34220148686241",The Brothers
"146.99136609688128,  -36.05590242963432",Thurgoona
"144.3705686444495,  -31.012046903477916",Tilpa
"145.50729292167776,  -35.780283504001424",Tocumwal
"148.15807649437264,  -30.999858046469946",Tooloon
"149.4530137798949,  -33.79163202815142",Triangle Flat
"153.4767013764797,  -28.283145691565842",Tumbulgum
"152.51295787059618,  -30.247204241792662",Tyringham
"152.15545054756032,  -32.25186918510112",Upper Myall
"149.34998769136274,  -33.41743853524037",Vittoria (NSW)
"151.4209949910081,  -30.9268554914596",Walcha Road
"151.58429432385262,  -33.06998094677121",Wangi Wangi
"151.71516288989312,  -32.88524155430489",Warabrook
"151.1226872866077,  -33.72721737525239",Warrawee
"150.9370883036524,  -33.91272738076062",Warwick Farm
"152.21134160303788,  -31.250117053610442",Werrikimbe
"150.62435475547824,  -31.351774972612045",Werris Creek
"153.35398474804572,  -29.0999592755007",Woodburn (Richmond Valley - NSW)
"153.23907049200696,  -29.81621377259306",Wooli
"151.6045638968363,  -33.192338776483226",Wybung
"151.0201549280122,  -33.90469515878524",Yagoona
"148.91590533500346,  -34.822837250713164",Yass
"142.97564593258485,  -37.26243116186075",Ararat
"144.21472376419078,  -37.60680066042055",Ballan
"142.79462046446503,  -35.618260847020444",Banyan
"143.77267769001406,  -38.04457438180623",Barunah Plains
"145.15434985386273,  -37.80537265668314",Blackburn North
"146.20597420518772,  -37.15109191654243",Boorolite
"144.7879357129893,  -37.759371401055056",Cairnlea
"146.62798451915015,  -38.310957433146996",Callignee North
"146.67061950923645,  -38.49989610702765",Calrossie
"144.11675820223292,  -36.6131429589596",Campbells Forest
"141.41491882601912,  -38.32699372958209",Cape Bridgewater
"143.83937708203817,  -37.02969061994538",Carisbrook
"146.808616931525,  -38.393543621536885",Carrajung South
"147.15352287370462,  -36.308705352531895",Charleroi
"146.4381093249428,  -36.84611806617989",Cheshunt
"145.3143273597097,  -37.7386043846706",Chirnside Park
"145.10599636382787,  -37.943088701969394",Clarinda
"147.66488801267747,  -37.70721489216053",Clifton Creek
"145.6271784515926,  -36.34727667689068",Cosgrove (Vic.)
"145.2231492829957,  -37.6071117398757",Cottles Bridge
"143.59235883378696,  -38.14635890736639",Cundare
"142.71043568518223,  -35.8364242419009",Curyo
"146.1608462404008,  -37.12957007112144",Delatite
"144.18678895267664,  -37.82079008990143",Durdidwarrah
"144.34963020215815,  -36.84596132789025",Emu Creek (Vic.)
"146.18446351608628,  -36.06391114347373",Esmond
"144.9110379988771,  -37.749830318324065",Essendon
"145.24685580858895,  -37.9982849372146",Eumemmerring
"146.5540701231822,  -36.43896310339197",Everton
"143.53110602787362,  -36.556158399667446",Fentons Creek
"144.85462710539193,  -38.4164838681024",Fingal (Vic.)
"145.36489603928405,  -38.349891218213756",French Island
"146.2796196051093,  -37.49137090649334",Gaffneys Creek
"145.42853992025795,  -37.40409880312889",Glenburn
"146.1649185053201,  -36.51354523768349",Glenrowan West
"148.70123122038743,  -37.346679540146866",Goongerah
"146.29155796407233,  -38.54022902911818",Gunyah
"141.6209706716117,  -36.75092399414715",Gymbowen
"144.12762932854105,  -37.31423706656592",Hepburn
"144.21473927848623,  -35.88998774757018",Horfield
"144.5139373138281,  -36.431187797973614",Hunter
"143.06804944738857,  -36.262821401493184",Jeffcott North
"142.96216821540818,  -36.77373301560662",Kanya
"145.87362152235633,  -38.457828672645654",Kardella South
"145.55848419285547,  -36.243749700310964",Katandra West
"143.75416473332794,  -36.62335765770847",Kingower
"145.7217277041494,  -36.806936955573995",Kithbrook
"146.02524590461246,  -38.46516401165908",Koorooman
"144.34313664538678,  -37.58128927063165",Korobeit
"143.6142489669709,  -36.51994911340204",Kurraca
"145.14256944906944,  -36.31014934929268",Lancaster
"141.0611230714294,  -37.09904750866666",Langkoop
"141.45039041348986,  -36.45350798261989",Lawloit
"146.23771792906263,  -38.47926574971315",Mirboo
"146.2466531154528,  -38.21483783265449",Moe South
"145.06035598307992,  -38.23042552928191",Mornington (Vic.)
"143.3780862070979,  -37.268159622960184",Mount Lonarch
"147.57627065920943,  -37.723973135676154",Mount Taylor
"144.12371406143987,  -37.10551090997569",Muckleford South
"145.5768781597393,  -37.386464844828794",Murrindindi
"143.42133548329076,  -35.957420393737976",Ninyeunook
"142.7704504741381,  -38.50606982138798",Nirranda
"145.00052198819517,  -37.772533532933984",Northcote
"144.91703161151094,  -37.71756877273678",Oak Park
"143.39658550362407,  -38.29131601172575",Pomborneit East
"144.28022844182493,  -36.77641913922545",Quarry Hill
"143.49272607958125,  -37.01146266497283",Rathscar West
"143.70288615224226,  -36.65228770336893",Rheola
"143.68820094385285,  -37.84855072518146",Rokewood Junction
"145.48501773098357,  -36.953659740592585",Ruffy
"146.6839972475907,  -37.93856676009083",Seaton (Vic.)
"145.48308908388822,  -36.39705374271161",Shepparton East
"145.57507914772984,  -38.59369528657091",South Dudley
"146.09113203009406,  -38.59313059887869",Stony Creek (South Gippsland - Vic.)
"142.5763665013376,  -35.379751741655085",Straten
"145.02756148674484,  -37.12554747419296",Sugarloaf Creek
"144.71499738635274,  -37.54705764153477",Sunbury
"142.8710491786912,  -38.33084491245042",Taroon
"142.10065237737328,  -36.22347630375202",Tarranyurk
"146.55115826588633,  -37.0330797948537",Wabonga
"146.0002312884106,  -38.840059360348754",Walkerville North
"145.24978071949727,  -37.77265192213408",Warranwood
"142.69000122569113,  -35.76939779107971",Watchupga
"145.08498923476748,  -37.695973940677746",Watsonia North
"146.57833156826302,  -36.51680891461882",Whorouly
"142.45856934009515,  -38.24112763667012",Winslow
"144.98207607115967,  -36.770427414584425",Wirrate
"143.41076638045726,  -38.22854904511729",Wool Wool
"147.60651896966658,  -37.79123610513753",Wy Yung
"141.40837414436484,  -36.08580697240479",Yanac
"143.4280739990961,  -36.355556591232144",Yeungroon East
"145.93121720946218,  -18.438177032958528",Abergowrie
"150.36763649167892,  -23.320151290221652",Alton Downs
"149.18591931589637,  -21.09515461699046",Andergrove
"142.5266220165977,  -13.347909510673455",Archer River
"153.37660283634028,  -27.988778564216652",Ashmore
"147.43294303958874,  -27.232045446112384",Bargunyah
"151.64327360969918,  -25.738028579106523",Barlyne
"153.12856394943083,  -26.77888077837307",Battery Hill
"152.88343677082338,  -27.559129873630734",Bellbowrie
"151.90528678165063,  -27.2547478180198",Bergen
"150.5278621416582,  -23.36435592391467",Berserker
"151.40747710404497,  -27.209352601951114",Blaxland (Qld)
"152.79266909227104,  -27.61373721911277",Booval
"145.71556387476377,  -16.8992449813419",Brinsmead
"153.13834959401242,  -27.74017564541627",Buccan
"152.01622809346327,  -27.78842042406448",Budgee
"152.94332388298085,  -27.369310781513843",Bunya
"152.60217886907526,  -25.210598997456994",Burrum Heads
"145.76107562032308,  -16.904832358354632",Cairns North
"148.6888551787488,  -20.286430644713",Cannonvale
"140.16077854118745,  -17.978908091086954",Carpentaria
"145.90748955577757,  -18.243977945250435",Carruchan
"146.2961267800131,  -26.39544684632203",Charleville
"147.1316357067432,  -22.859031424583847",Clermont
"145.66686483540016,  -16.77010140664266",Clifton Beach (Qld)
"152.98724730739482,  -26.200496116571298",Como (Qld)
"152.5532581631428,  -27.515847663946357",Coolana
"141.81084704581016,  -18.20503894028657",Coralie
"143.33494120603245,  -22.038757262953077",Corfield
"151.879380571923,  -26.443655131900986",Corndale (Qld)
"150.9504558739445,  -23.719898772466856",Darts Creek
"153.05959982331058,  -27.327726306177073",Deagon
"145.18695879009056,  -16.27076527588002",Dedin
"151.94622638806325,  -28.097347822659543",Deuchar
"145.7406811473876,  -17.01745276928531",Edmonton
"152.91239707823829,  -26.476391893358105",Eerwah Vale
"151.2490276512842,  -25.324705948555707",Eidsvold East
"149.17442334070577,  -21.046994928093717",Eimeo
"151.7354039468409,  -27.151013695790898",Evergreen
"152.9716609031075,  -27.388937478937397",Everton Hills
"153.02338579769363,  -27.506904231648452",Fairfield (Qld)
"152.9618836133512,  -27.527702614629096",Fig Tree Pocket
"152.86414755993016,  -26.657099151545122",Flaxton
"149.14640236863144,  -21.137953887316716",Foulden
"150.9219478209936,  -28.592472736517244",Glenarbon
"152.05393211390088,  -26.966029418547162",Googa Creek
"151.80119033320517,  -25.51889903645538",Gooroolba
"151.13924723959138,  -27.413079674233014",Grassdale (Qld)
"151.73989598838287,  -27.33541736613169",Greenwood (Qld)
"139.2626535704717,  -19.85918161823612",Gunpowder
"148.98514274695302,  -20.903298378891535",Haliday Bay
"149.27370645240507,  -21.29639318983341",Hay Point
"152.54044161156855,  -27.032110345016708",Hazeldean
"153.13173969239514,  -27.447073684046252",Hemmant
"151.7726870207531,  -27.11569824442173",Highgrove
"144.5453240686866,  -23.66096343409705",Ilfracombe
"147.49424877346726,  -19.7105459101401",Inkerman (Qld)
"152.10584035910193,  -27.581247066687695",Iredale
"143.17360341821205,  -25.04837599122651",Jundah
"152.70472061103408,  -27.596172971865293",Karrabin
"152.65194279735516,  -26.631613533836113",Kenilworth
"152.95256250829542,  -26.587792731399947",Kulangoor
"152.68145333994204,  -26.277008741364885",Lagoon Pocket
"151.59463447970762,  -28.021286896284543",Leyburn
"151.863047586048,  -28.83075522768013",Lyra
"152.68951082739272,  -25.663826107084645",Magnolia
"145.7366452146237,  -16.917086803987086",Manoora (Qld)
"142.28732331988016,  -16.03696790039515",Maramie
"153.09834108344614,  -27.674266284316964",Marsden
"153.14466991010255,  -27.6640893626707",Meadowbrook
"145.91705900522652,  -17.66908044263089",Mena Creek
"151.8891677660106,  -27.41536789146258",Meringandan West
"150.1531019709167,  -26.713987943473043",Miles
"140.65848635316908,  -22.896429319557566",Min Min
"145.85108543103678,  -25.08557281687655",Minnie Downs
"152.4725423672529,  -25.95705302226177",Miva
"142.2651515026939,  -10.182474669346815",Moa Island
"152.91773742082714,  -27.813943318558973",Monarch Glen
"152.37641337647793,  -26.721356783387336",Monsildale
"145.9929789791656,  -17.938572955918225",Mount Mackay
"148.84106529972644,  -20.9624791161417",Mount Ossa
"149.2148751160108,  -24.847444537892883",Mungabunda
"148.7769049893749,  -28.894868886853214",Mungindi (Qld)
"152.11631561219892,  -28.308632146791453",Murrays Bridge
"145.80631413377088,  -26.940038145617077",Murweh
"149.02912789348656,  -21.329296363277336",Oakenden
"142.44036048295587,  -23.06055133604006",Opalton
"148.38654829422606,  -24.206559150587374",Orion
"153.36478978866268,  -27.95434212875836",Parkwood (Qld)
"152.6127466832523,  -26.23153060991348",Pie Creek
"149.02312316839902,  -21.15405571459322",Pleystowe
"147.4099466441619,  -25.939952868627408",Redford
"150.26570582258742,  -23.276032610244926",Ridgelands
"152.95259880180942,  -26.336479569985165",Ringtail Creek
"151.887352469069,  -24.660207780081183",Rosedale (Qld)
"139.49981393051593,  -20.70051030084503",Ryan (Qld)
"152.63771294333117,  -26.850551138428067",Sandy Creek (Qld)
"151.91506655217276,  -28.703812267204096",Severnlea
"152.49183295166327,  -26.849249253993953",Sheep Station Creek
"151.95180087548647,  -27.575890949937488",South Toowoomba
"152.79424646909908,  -26.888473649271436",Stanmore (Qld)
"151.93762251448914,  -28.659902197499044",Stanthorpe
"153.3442089634746,  -27.739142544430365",Steiglitz (Qld)
"152.7078234401123,  -26.915395996368304",Stony Creek (Qld)
"152.14155652471518,  -28.197543815157523",Swan Creek (Qld)
"153.41959272439178,  -28.151727505910365",Tallebudgera
"146.06285499798338,  -17.909313612664874","[""Tam O'Shanter""]"
"152.76034202310439,  -26.279866292333864",Tandur
"149.86802295780535,  -25.679490154584727",Taroom
"151.25349931696758,  -23.888192278114108",Telina
"152.44110026833806,  -28.264276970068817",The Head
"152.5759488111981,  -25.353648473754298",Torbanlea
"150.37505930420957,  -23.69218200031866",Trotter Creek
"153.0865143156711,  -26.630593111084192",Twin Waters
"153.11131701890938,  -27.60881989382542",Underwood (Qld)
"147.7998589064057,  -26.884688927357992",V Gate
"145.80352599044355,  -17.82274461532174",Walter Hill
"149.89688139600915,  -24.691128566101007",Warnoah
"142.62538351342033,  -12.498698377559018",Wenlock
"151.70749431633,  -25.505142758243064",Wetheron
"145.87839652941972,  -17.469653821404687",Woopen Creek
"148.3487609292255,  -23.376717790439418",Wyuna (Qld)
"153.0259448710539,  -26.530960620812795",Yandina Creek
"143.25296027207534,  -14.608480606129726",Yarraden
"138.60119613530668,  -35.070507717984896",Aberfoyle Park
"138.515966864394,  -34.85903625833637",Alberton (SA)
"138.06040251379778,  -35.78919172786494",Antechamber Bay
"139.20514977753334,  -34.86089171542436",Apamurra
"138.68692890301793,  -34.917935763125186",Auldana
"138.7814588232709,  -35.07269980133036",Biggs Flat
"138.64545077964925,  -32.846004694304774",Black Rock (SA)
"139.24311840731917,  -35.23661382407145",Brinkley
"138.20001618915668,  -32.4720463023101",Bruce (SA)
"138.22656113663652,  -33.90639631808305",Bumbunga
"138.78019344968982,  -34.90866048443526",Cherryville
"138.91024596485428,  -35.470879840011165",Clayton Bay
"137.9181678420277,  -34.22391362358078",Clinton Centre
"135.85414850394534,  -34.22556794586714",Cockaleechie
"137.75517663742298,  -32.58387956746106",Commissariat Point
"138.66936213203144,  -34.72021591228257",Elizabeth
"138.60442686419225,  -34.84238925325801",Gepps Cross
"135.7591584825028,  -30.967867219329495",Glendambo
"138.69148781320007,  -34.94980703096062",Greenhill (SA)
"139.26784957145196,  -32.99384761702151",Hardy
"140.0568445457739,  -34.324374493379075",Holder Siding
"138.26014702861895,  -34.28131229125883",Inkerman (SA)
"136.3996956233788,  -33.435894721387086",Jamieson (SA)
"138.27926857003413,  -32.06229137211334",Kanyaka
"138.62005122255252,  -34.92153718331001",Kent Town
"138.82299673594295,  -34.924304061676274",Lenswood
"138.13884097712636,  -33.919318700550974",Lochiel (SA)
"140.5187689929604,  -35.978657577411504",Makin
"138.6219798173548,  -34.87718914721145",Manningham
"138.6126341533099,  -35.19942581589871",McLaren Flat
"138.57094183865414,  -34.926397178222295",Mile End
"140.34409000242567,  -37.61014381114543",Millicent
"139.901355284308,  -37.097672838504884",Mount Benson
"139.1053147764634,  -27.20806640809401",Mungeranie
"139.5426418025656,  -34.87160222800071",Old Teal Flat
"140.69620113774536,  -35.31442194146713",Parilla
"138.61665498696516,  -34.94619067270179",Parkside (SA)
"134.38290468524522,  -32.462775556692385",Petina
"140.87328267962317,  -35.32564746376866",Pinnaroo
"137.97689635080386,  -33.16623232672097",Port Pirie West
"138.73145149520175,  -35.22765743938488",Prospect Hill
"138.51035775344565,  -34.86103462704758",Queenstown (SA)
"136.21414344972126,  -33.71241838444298",Rudall
"139.06028204895247,  -34.44292572675106",Stockwell
"139.08968554691634,  -34.6870492165605",Taunton (SA)
"138.54899284170253,  -34.919429238250764",Underdale
"139.435306731348,  -35.62631028565771",Waltowa
"138.23752454768024,  -33.04002505867239",Wirrabara
"139.3308732042401,  -35.00330780297563",Woodlane
"137.7299702522399,  -34.97597582959204",Wool Bay
"136.81926903415302,  -31.165636114366244",Woomera
"114.69078716798037,  -27.877223764209383",Ajana
"118.40817745252328,  -23.839346673528457",Angelo River
"115.83546966946417,  -32.0269699808889",Ardross
"118.11572951002665,  -30.899335067532974",Barbalin
"115.9453183595959,  -31.906480698511896",Bassendean (WA)
"116.2915966766374,  -33.79771546021852",Benjinup
"116.14800386414815,  -30.558221411978746",Berkshire Valley
"116.51687912119301,  -31.241415829728016",Bolgart
"122.29103947677092,  -33.942357412479446",Cape Le Grand
"115.72214330385088,  -31.596388772209096",Carabooda
"115.0769008822157,  -30.502159789950973",Cervantes
"116.52445888547624,  -31.709317985781603",Clackline
"115.84772238570048,  -32.12124854527073",Cockburn Central
"116.20483150675082,  -33.39275539549142",Collie Burn
"116.045250465308,  -30.46209908887691",Coomberdale
"123.66752672737081,  -17.37836041061368",Derby (WA)
"117.74453378695645,  -33.13427481566886",Dongolocking
"116.08296858704108,  -32.73322898414667",Dwellingup
"115.97737386793239,  -31.768152199805332",Ellenbrook
"127.56661344130293,  -30.775452961301472",Forrest (WA)
"115.75877345638918,  -32.05328675936464",Fremantle
"115.91710525682288,  -31.293738605577012",Ginginup
"116.0479348661285,  -31.948274331308927",Gooseberry Hill
"115.99203510790707,  -32.08474572211235",Gosnells
"116.11729917636947,  -31.982829725352286",Hacketts Gully
"120.08955957293107,  -33.85906699969837",Hopetoun (WA)
"115.87220600492466,  -32.10238670699637",Jandakot
"121.40375496025283,  -31.01529002703871",Karramindie
"117.5927997667021,  -34.477318157473256",Kendenup
"116.04225630625574,  -31.906581492160317",Koongamia
"114.02528488003081,  -22.19745570239921",Learmonth (WA)
"122.74158052902955,  -24.77149121149679",Little Sandy Desert
"118.19792628398345,  -34.831677838603326",Manypeaks
"117.45937969583252,  -33.66681896230913",Marracoonda
"116.17901636493734,  -34.306600900932196",Middlesex (WA)
"115.21065120688864,  -34.26873384239926",Molloy Island
"117.01189890619791,  -20.72096542212868",Mount Anketell
"115.83510732573956,  -31.919980021164633",Mount Hawthorn
"117.86968214932116,  -28.06709344916396",Mount Magnet
"117.66823854008331,  -22.413521265178233",Mount Sheila
"117.97606135773167,  -34.784083819049485",Napier
"117.17205174250354,  -32.9364613715792",Narrogin
"118.03853757681793,  -34.195485880140794",North Stirlings
"115.86096649635732,  -31.950172222793658",Perth (WA)
"118.7870064332212,  -33.54607234471395",Pingrup
"117.0862251746343,  -31.460228302332407",Quelagetting
"115.39006317619172,  -33.649975170228956",Reinscourt
"117.82867038826816,  -35.028972359041475",Robinson
"122.61837801528863,  -18.0268950376344",Roebuck
"115.86631033192558,  -33.29426276885716",Roelands
"117.78638667240385,  -35.0483895075698",Sandpatch
"119.50901120357895,  -31.891545278579265",Skeleton Rock
"117.79015028776787,  -31.23484492885209",South Trayning
"115.78379584351983,  -32.10430992506544",Spearwood
"125.01170713007649,  -18.9813070575087",St George Ranges
"115.53668506750712,  -33.51741043914441",Stirling Estate
"116.16540117363648,  -31.858860802629156",Stoneville
"115.31730273155605,  -28.58984344798684",Tenindewa
"117.63902322973489,  -32.95594635658915",Toolibin
"117.06832803265367,  -31.730745580860578",Warding East
"115.21579447930674,  -34.12569570032347",Warner Glen
"117.24582025446416,  -31.441243583160322",Watercarrin
"118.08758257266611,  -30.421822355403265",Wialki
"115.86892369301248,  -29.482303869766604",Womarden
"116.32060621323029,  -31.804266886095565",Wooroloo
"114.65814851379197,  -28.766702056683346",Woorree
"116.77757086465463,  -31.8732669398224",York
"146.941168328435,  -41.03989067888228",Beechford
"147.17372895159332,  -42.841567543709246",Collinsvale
"145.87259835429956,  -41.0448175069106",Cooee
"143.85359714659893,  -39.921397833065015",Currie
"147.01475524263319,  -43.2979772940691",Dover
"147.89279271038237,  -40.02210387229948",Emita
"147.68048193741814,  -41.05352215400869",Forester
"146.86580107781379,  -43.15314437788846",Geeveston
"147.7752415096166,  -43.15333987180164",Highcroft
"146.83555486389943,  -42.68318460276191",Karanja
"147.14199997208735,  -41.24417917306197",Karoola
"147.0557539038683,  -43.195990928038995",Lymington
"147.07468786309383,  -42.735513460358895",Magra
"147.13114139495715,  -41.38759853795349",Mayfield (Tas.)
"145.9723134982343,  -41.532438700778805",Middlesex (Tas.)
"145.9302860899876,  -41.16913649581176",Natone
"147.3649245148937,  -43.155427336314126",North Bruny
"147.37536611577065,  -42.47290605297128",Rhyndaston
"146.98800221805996,  -41.33523658112624",Rosevears
"145.03267438233152,  -40.800978744644816",Scopus
"145.24734069191155,  -40.89141942833485",South Forest
"145.9308011414147,  -41.11070813087341",Stowport
"147.5812891199727,  -41.4459238667366",Upper Blessington
"145.49985680287318,  -41.460068371525644",Waratah (Tas.)
"146.99775007787028,  -41.4743826774671",Westwood (Tas.)
"131.2071254853762,  -12.523268599306208",Black Jungle
"130.58312324917233,  -15.112741482324585",Bradshaw
"129.62081448831992,  -17.809449591511324",Buchanan (NT)
"131.04018864474364,  -12.520030581976494",Coolalinga
"132.1745042084438,  -14.455384878226074",Cossack (NT)
"135.80779431782017,  -17.82159354285771",Creswell
"131.2437687049354,  -12.741612537028786",Daly
"130.83945098543757,  -12.426243686765625",Fannie Bay
"130.5057775275333,  -16.2835648422831",Gregory (NT)
"134.99537499642483,  -24.08526646020168",Hale
"131.4064017486312,  -12.344522103393956",Hotham
"130.95810641656135,  -12.496553204679078",Marlow Lagoon
"130.98453445242154,  -12.478466066922316",Palmerston City
"137.3869211466226,  -20.00589731885636",Ranken
"149.09616582772003,  -35.274658018029314",ACT Remainder - Canberra Central
"149.14666875772437,  -35.31524254174738",Kingston (ACT)
"149.1307412535692,  -35.239795361267014",Lyneham
"151.88600720712367,  -24.17917166516444",Seventeen Seventy
"152.78908811455318,  -27.622476162383208",Silkstone
"145.94932157930725,  -17.974625918574333",Silky Oak
"152.99148733859494,  -27.794545722607943",South Maclean
"145.27431616739102,  -16.77109910778602",Southedge
"152.70452876971228,  -25.48564422408946",St Helens (Fraser Coast - Qld)
"153.24448679849894,  -27.730612718212615",Stapylton
"152.46036338013798,  -27.609094941900228",Summerholm
"152.8466728482197,  -28.168372514859655",Tamrookum Creek
"150.74646896390868,  -23.22088028340033",Tanby
"151.8690640081379,  -26.774643677896883",Tarong
"148.81514368099693,  -28.542705020043567",Thallon
"151.82992218079258,  -27.074348239228996",Thornville
"151.4116400589233,  -25.929404197238455",Toondahra
"152.36941271526757,  -27.897409428200397",Townson
"146.08012143934897,  -18.64556532825096",Trebonne
"141.8748606904766,  -12.635889743717193",Trunding
"145.45935360793396,  -18.758615467490866",Valley Of Lagoons
"146.8169242686405,  -19.139226655074356",West Point
"152.6130082887465,  -27.02584120361963",Westvale
"153.127332476515,  -27.030132580038558",White Patch
"150.40590783657098,  -26.968931158598657",Wieambilla
"142.42494222591344,  -25.472111830093482",Windorah
"153.23439897493517,  -27.887668666685162",Wongawallan
"151.67563404497486,  -27.08629182439221",Woodleigh (Qld)
"152.72611060003743,  -26.2525119742928",Woondum
"145.31309713745478,  -15.968318582860674",Wujal Wujal
"151.81741872398192,  -27.418612421406575",Yalangur
"151.6181259647174,  -25.40341609162902",Yenda (Qld)
"152.5936634916596,  -25.54243843133181",Yengarie
"138.76358392882253,  -35.29973309705765",Ashbourne (SA)
"137.86649608725565,  -35.78157290063101",Baudin Beach
"138.5831877507159,  -35.03018547792008",Bellevue Heights
"138.55834869820427,  -33.86068728984011",Boconnoc Park
"137.70059001881276,  -34.043287758802435",Boors Plain
"138.5757571406403,  -34.89842900028142",Brompton
"137.60702465731592,  -35.67140396159065",Brownlow Ki
"140.5673968788226,  -36.33812065627842",Buckingham (SA)
"140.64962981308813,  -36.267525851669724",Cannawigara
"137.72201345805084,  -35.036024763897565",Coobowie
"137.7671405281371,  -34.06432076135406",Cunliffe
"140.73362085072353,  -37.69550393644833",Dismal Swamp
"138.86708151857553,  -34.3930833562546",Fords
"138.62627753170923,  -34.954004758758806",Fullarton
"138.69509905167837,  -34.62333233394538",Hillier
"139.00793080955202,  -34.12446578196865",Julia
"140.05843846594732,  -34.24520097919723",Kanni
"135.3494543690232,  -33.99554388890493",Kiana
"136.1843305572547,  -33.57510530107541",Kielpa
"139.28171761630477,  -35.620118888487895",Lake Albert (SA)
"140.33511054072397,  -36.56416208043658",Marcollat
"137.0401874127545,  -35.161041163542606",Marion Bay (SA)
"138.7554066199795,  -35.17428920233413",Meadows
"133.8347290935754,  -32.141541279121576",Merghiny
"138.46903064623564,  -32.70836441896858",Morchard
"138.32935641697253,  -34.02103779345137",Mount Templeton
"138.9748471139101,  -34.87175379538701",Mount Torrens
"139.31321520646097,  -35.11725996632071",Murray Bridge East
"138.9099958042577,  -35.35704642140177",Nurragi
"138.7612113746209,  -34.848626942704996",Paracombe
"138.64447938210392,  -34.90478969377952",Payneham South
"140.42157840199644,  -37.923428669626674",Pelican Point (SA)
"138.6228851494304,  -34.825760631852994",Pooraka
"139.44282115845567,  -34.85416271049757",Rocky Point (SA)
"138.62435976932227,  -34.77179065323665",Salisbury Downs
"138.52942770171612,  -35.04262936023944",Seacliff Park
"139.80305819512833,  -35.313732999585554",Sherlock (SA)
"138.0312095766977,  -33.18247705041871",Solomontown
"140.8026457632696,  -37.871241363047474",Square Mile
"138.5002946560806,  -34.806669168743326",Taperoo
"137.82472011264193,  -33.99097787578835",Thrington
"135.75667396719072,  -34.77925831194328",Tulka
"138.33969122824084,  -35.61631354182113",Tunkalilla
"137.13345980708303,  -35.97671680588468",Vivonne Bay
"138.61787634499012,  -34.89184463073153",Walkerville (SA)
"134.6266697158188,  -30.782623248070777",Wilgena
"139.31939664772096,  -31.728222305615244",Willippa
"139.06738243491247,  -33.256033103899554",Wonna
"138.64248503360622,  -34.11594816853861",Woolshed Flat (SA)
"140.2748190388022,  -34.3400709233408",Yinkanie
"115.86500694810978,  -31.8275424016543",Alexander Heights
"115.85340666932709,  -33.229605046039374",Brunswick (WA)
"115.80299852487147,  -32.24647413457851",Calista
"115.79965359205238,  -34.12634697221443",Carlotta
"115.8693862680622,  -32.24132573488124",Casuarina (WA)
"115.4930609152418,  -30.72671637491138",Cataby
"117.02228551824733,  -32.82652633448282",Contine
"115.76976916806618,  -31.786954996895187",Craigie (WA)
"116.713527140881,  -20.672620507893676",Dampier
"116.74595856324798,  -33.346326337930456",Darkan
"117.77675330485725,  -33.30143853413896",Dumbleyung
"115.91118876279805,  -31.902286027803463",Embleton
"117.71205847766757,  -33.70957885328402",Ewlyamartup
"115.92477661328248,  -32.029550985560796",Ferndale (WA)
"121.50498460643884,  -30.77844185707357",Fimiston
"116.03911196113313,  -30.900652598542532",Gillingarra
"117.81760300925887,  -34.8738706025187",Green Valley (WA)
"115.76319973960878,  -32.52407330515945",Greenfields
"114.71953863355148,  -28.923422460481177",Greenough
"115.846748106494,  -32.17128675335868",Hammond Park
"115.96610300403279,  -32.08317914875141",Huntingdale (WA)
"114.25053581447335,  -24.96825878522944",Inggarda
"118.39784200533342,  -34.51137690682875",Kojaneerup South
"116.07300293781476,  -30.80765927033188",Koojan
"116.23564198392508,  -29.205448023441853",Koolanooka
"115.84115635903974,  -31.931863664560133",Leederville
"115.70645230931949,  -33.24337899567947",Leschenault
"116.73946352604993,  -31.60635333983287",Malabaine
"116.3154180188234,  -33.615750542295565",McAlinden
"115.12963263906092,  -33.787664242113735",Metricup
"115.9533049235732,  -31.086259992228893",Mindarra
"114.87033302347868,  -28.849968929291958",Minnenooka
"117.53214064399323,  -34.10113365675894",Moonies Hill
"124.87567547786732,  -17.955859541228055",Mount Hardman
"122.33081562157048,  -33.33871635750659",Mount Ney
"118.7452522658717,  -32.03980512190436",Mount Walker (WA)
"121.49131532127845,  -30.67961025837198",Mullingar
"115.9752007549235,  -32.28222707673782",Mundijong
"120.52590791914538,  -33.46306292064089",Munglinup
"122.20370214920045,  -33.619064982388274",Neridup
"119.05472321076365,  -33.08220286845392",Newdegate
"121.87573615871104,  -33.84925217963749",Nulsen
"127.66025227585246,  -14.865085799476624",Oombulgurri
"116.17509446902376,  -34.17509822861387",Palgarup
"115.48836292038479,  -21.73391685125039",Peedamulla
"116.83444049254939,  -20.731109609030902",Pegs Creek
"115.9718398565244,  -31.944445475943965",Perth Airport
"115.98399564986818,  -28.532449868637727",Pindar
"115.89244631088006,  -32.120759832721866",Treeby
"116.87925181732425,  -31.16822226007912",Walyormouring
"115.59452112481091,  -31.209952252885458",Wanerie
"116.00194750073545,  -32.00635072938095",Wattle Grove (WA)
"121.43442282351745,  -30.780405952405793",West Kalgoorlie
"117.84824255516891,  -34.94198661461984",Willyung
"115.99329683359129,  -31.890859570754444",Woodbridge (WA)
"118.97493268931673,  -32.16251990229053",Woolocutty
"115.1015938822727,  -33.69779602199807",Yallingup Siding
"119.81164356908306,  -31.06567145002824",Yellowdine
"147.10741085979956,  -43.22949624457774",Abels Bay
"148.33395823460268,  -41.2930865923636",Akaroa
"147.26265463895672,  -43.04561413570624",Barretta
"147.85996981732143,  -42.76496171658916",Bream Creek
"145.07870871088954,  -40.86504034243936",Broadmeadows (Tas.)
"147.83046073559657,  -41.163995515907544",Derby (Tas.)
"145.45242026722474,  -40.87839376799885",Detention
"146.93347762308142,  -41.30397238065818",Exeter (Tas.)
"145.89448800484584,  -41.06307039121849",Hillcrest (Tas.)
"146.1514790204852,  -41.532507234863985",Lorinna
"147.03225941729912,  -42.96329161434444",Lucaston
"147.2962711589914,  -42.759544262392495",Old Beach
"147.1260759126997,  -43.05709683265659",Pelverata
"147.33003115046307,  -42.796341410734634",Risdon
"148.0130658177883,  -42.10106373976006",Swansea (Tas.)
"147.5190997081379,  -41.349608826768296",Tayene
"147.92866562733863,  -42.46612470808123",Triabunna
"145.8956007536115,  -41.15842001288496",Upper Stowport
"147.14577849881272,  -42.88932372843113",Wellington Park
"148.02748595304794,  -40.07877784290025",Whitemark
"129.49959694065996,  -15.982812601185934",Baines
"136.3086674496654,  -16.070327965466145",Borroloola
"133.4503305507038,  -23.367228471751478",Burt Plain
"132.27660073207372,  -14.407609490845832",Emungalan
"132.79602257984538,  -23.938011333427266",Hermannsburg
"130.89591793706882,  -12.358108123380234",Muirhead
"132.53636332058014,  -14.114338041017374",Nitmiluk
"134.07511722536023,  -24.68021713464882",Titjikala
"130.94998500028225,  -12.467362380841555",Tivendale
"130.8860234662511,  -12.429237391208625",Winnellie
"149.0558666347882,  -35.1675145557855",ACT Remainder - Hall
"149.31351842806916,  -35.31077070308535",ACT Remainder - Kowen
"149.02722643946802,  -35.23235441430713",Higgins
"149.0499130387916,  -35.23856765125855",Page
"149.03796963132834,  -35.34709410021417",Rivett
"153.02574774458338,  -28.7569132016956",Backmede
"150.5153122378601,  -34.302561793400294",Balmoral (Wingecarribee - NSW)
"151.1456385298058,  -33.946474011647304",Banksia
"149.5042406132726,  -32.17730143857756",Barneys Reef
"149.84183591306223,  -36.68856604915797",Bega
"149.90970494466484,  -36.88039752563749",Berrambool
"152.28318349536397,  -31.698263517966943",Bobin
"152.00217904693523,  -32.77792922760908",Bobs Farm
"149.65468127929066,  -35.4337556990815",Bombay
"151.34575190722074,  -33.51366689852306",Booker Bay
"144.79268389524128,  -34.859689720293204",Booroorban
"149.80288024991376,  -35.434805358718975",Braidwood
"152.55768682891576,  -31.278569200895696",Bril Bril
"151.8571800284565,  -30.261826951814985",Brockley
"150.7519103512415,  -34.7430945454497",Broughton Village
"151.4867471781409,  -32.95347772214097",Brunkerville
"150.6658115097475,  -34.7508620160872",Bundewallah
"153.47384165733376,  -28.246978955098054",Bungalora
"142.1911288772618,  -34.1579014611445",Buronga
"148.08994508724496,  -35.82822417952054",Burra (Snowy Valleys - NSW)
"153.21099275437044,  -28.832601100950928",Caniaba
"152.5876293987645,  -29.345307915349885",Carnham
"151.38511265504,  -32.190466168047784",Carrabolla
"149.32152653918055,  -35.37832144907854",Carwoola
"153.5706748966861,  -28.300487118435743",Casuarina (NSW)
"150.77265216979976,  -33.42431652340108",Central Colo
"151.24830146583363,  -33.28894164887955",Central Mangrove
"153.3436848120074,  -28.833182331667004",Chilcotts Grass
"146.69174238544875,  -29.511776588200192",Collerina
"148.30181795806786,  -31.681431802879068",Collie (NSW)
"151.09137016251057,  -33.84326332507006",Concord West
"150.44077927222978,  -35.264026212317596",Conjola Park
"148.29738001878005,  -33.2421388282619",Cookamidgera
"147.2463750387002,  -35.55346732150174",Cookardinia
"148.97796848827275,  -36.25920959083097",Coolringdon
"152.69593709150973,  -31.787520993185925",Coralville
"152.67783439678863,  -31.000778749918307",Corangula
"145.2594363911398,  -31.438721127975704",Cubba
"141.98547871068442,  -34.09762828390321",Curlwaa
"150.77861818672972,  -29.635330726062712",Delungra
"151.75982909958864,  -31.786078558541615",Dewitt
"152.53620415745638,  -32.02817585367457",Diamond Beach
"143.70140975506823,  -35.18586882528297",Dilpurra
"153.32558486275698,  -28.66878691019475",Dunoon
"153.28862943313254,  -28.8244615042501",East Lismore
"147.9089710914761,  -35.30888455257104",Ellerslie (Snowy Valleys - NSW)
"150.67818932360356,  -34.03298298761139",Ellis Lane
"153.10043759655014,  -29.28068381609128",Gibberagee
"150.99223031165354,  -33.57186351661474",Glenorie
"150.08493372469164,  -34.32672067750919",Goodmans Ford
"149.73080239746238,  -31.013411678114974",Goolhi
"152.941550623452,  -28.403190785708446",Gradys Creek
"151.7869219643522,  -30.267260642545896",Green Hills (Armidale Regional - NSW)
"152.72788978263918,  -31.258045423047765",Gum Scrub
"149.38992875828427,  -32.81620327620599",Hargraves
"150.05410957791776,  -30.548790459903035",Harparary
"149.6285908550566,  -32.4248586555773",Home Rule
"151.5684105072757,  -32.735266243111084",Horseshoe Bend
"151.27521385780562,  -33.45343740811044",Kariong
"150.76212043975218,  -30.042890328549188",Keera
"149.62282997967998,  -33.419399360517275",Kelso (NSW)
"153.00526962407707,  -30.99912211548203",Kinchela
"151.4694314428907,  -29.7038200807465",Kingsland
"149.15713924402203,  -32.666607887666565",Lake Burrendong
"151.20757199146217,  -33.84373453452653",Lavender Bay
"151.39553000408813,  -32.648751462255376",Leconfield
"151.6312442960083,  -32.8483882041298",Lenaghan
"149.28164675896411,  -33.28148006338173",Lewis Ponds
"151.01876968666963,  -31.28272855381208",Loomberah
"150.70992559714853,  -33.864544379369754",Luddenham
"150.89281294263455,  -33.74829599952153",Marayong
"142.71921355758317,  -32.44166147315293",Menindee
"149.20598476811915,  -30.183179593241228",Merah North
"151.149126174219,  -32.442261376756875",Middle Falbrook
"150.87434629150496,  -34.03449753528757",Minto Heights
"153.30008517124475,  -28.718836643172313",Modanville
"152.53424477771165,  -29.0083767102589",Mookima Wybra
"150.63712917557282,  -33.72829962739994",Mount Riverview
"151.47500508881265,  -32.930017707108014",Mount Vincent
"143.09676660427996,  -33.76042936128602",Mungo
"143.64456996914208,  -35.308802393720114",Murray Downs
"148.09820494244386,  -32.301671778208124",Narromine
"149.38683731965915,  -31.73944783152558",Neilrex
"146.03552302912587,  -34.17806237318316",Nericon
"151.68953846582704,  -32.926542738344594",New Lambton Heights
"151.70416420460572,  -32.90411420881032",North Lambton
"152.9083459883653,  -31.382387369979583",North Shore (NSW)
"150.78423452745392,  -33.75491722062968",North St Marys
"153.32864389532878,  -28.72288811638851",Numulgi
"152.59374255922103,  -28.644951334443338",Old Bonalbo
"152.11600191070593,  -32.76381493611236",One Mile (NSW)
"144.0596462294587,  -34.02962171695597",Oxley (Balranald - NSW)
"153.4059691704936,  -28.514109805936116",Palmwoods (NSW)
"148.9373293004286,  -33.51550783829709",Panuara
"152.69953638358243,  -28.67387298844523",Peacock Creek
"148.05033275136736,  -32.71161872772996",Peak Hill (NSW)
"149.39944047838307,  -36.03167596353314",Peak View
"151.2323142149916,  -33.97887944138924",Phillip Bay
"145.42760703825297,  -35.66097022076358",Pine Lodge (NSW)
"150.8698985389639,  -33.58218516942646",Pitt Town
"149.1507917969837,  -36.23147387416515",Polo Flat
"147.88170422303327,  -30.885901601562896",Quambone
"151.38412585748188,  -32.93271341904491",Quorrobolong
"152.99582174135693,  -30.45537589586428",Raleigh
"148.372335360045,  -35.158444367610684",Red Hill (Snowy Valleys - NSW)
"148.89808804925235,  -36.17548196945244",Rhine Falls
"153.19369733961867,  -30.093184385159844",Safety Beach (NSW)
"141.48893440100255,  -33.150147209534566",Scotia
"151.5824870034128,  -32.883635130391006",Seahampton
"150.86685747770244,  -34.599345876112075",Shell Cove
"151.69111248270895,  -32.87888425048904",Shortland
"153.51848206845546,  -28.4162822753788",Sleepy Hollow
"152.84520139269674,  -31.122170784207846",South Kempsey
"151.10842938163182,  -33.752361667332806",South Turramurra
"150.2186358717018,  -31.387637879938314",Spring Ridge
"151.19268708923383,  -33.822717080564416",St Leonards (NSW)
"147.86468729736703,  -34.465977932119216",Stockinbingal
"151.95477779364103,  -32.11482546865667",Stratford (NSW)
"152.36386763105463,  -31.76954021839851",Strathcedar
"148.32031460604676,  -35.60152260628502",Talbingo
"149.99678873003307,  -30.43485609888496",Tarriaro
"148.73497356911653,  -32.439734167741086",Terrabella
"151.21982679552016,  -33.689289597832506",Terrey Hills
"149.46218625860132,  -30.68123957918179",The Pilliga
"151.69943170121377,  -30.4169888586854",Tilbuster
"148.2156112003364,  -32.553353779849274",Tomingley
"149.70154859289244,  -37.113876481552765",Towamba
"149.33783441080044,  -33.80057810729685",Trunkey Creek
"150.74235291845784,  -34.57945377420223",Tullimbar
"148.23335545030108,  -35.321156624677386",Tumut
"150.07372993963534,  -36.0295630004458",Turlinjah
"153.53585843368322,  -28.201493639732135",Tweed Heads South
"153.55535936898866,  -28.599683746575465",Tyagarah
"150.84538623862076,  -34.457595624406096",Unanderra
"150.91660710616753,  -33.25050682527543",Upper Macdonald
"151.106406164226,  -33.70916161449187",Waitara
"150.87277977896093,  -31.549170804910425",Wallabadah
"148.5008389446757,  -32.56141258847404",Wambangalang
"150.53101489523564,  -29.638328052389795",Warialda Rail
"150.68182157201952,  -31.77184166234794",Warrah Creek
"151.28188147895105,  -33.841096227762236",Watsons Bay
"151.1915882643391,  -32.52161526089966",Wattle Ponds
"143.80999556727994,  -34.509347222067525",Waugorah
"147.8829458228574,  -34.19973840693862",Weedallion
"149.27866278420865,  -34.51333217276121",Wheeo
"149.8009050003785,  -33.31396494974267",Winburndale
"150.50836249160758,  -33.11339335074012",Wirraba
"151.60658688634,  -32.985888287785464",Woodrising
"143.71661434128788,  -34.73950668090841",Yanga
"151.2563947660898,  -33.2105460327776",Yarramalong
"152.43427251856716,  -31.778684292839632",Yarratt Forest
"151.20855979776258,  -33.907435312358174",Zetland
"144.80106680073726,  -37.77603313425239",Ardeer
"143.83381916723144,  -37.44304356461519",Bald Hills (Vic.)
"143.99406545890434,  -37.93781691549004",Bamganie
"142.79762455877074,  -34.73956100648715",Bannerton
"143.97602107298118,  -36.97131113257958",Baringhup
"144.99104920926587,  -35.95631125817045",Barmah
"146.0673361392405,  -38.414930892095164",Berrys Creek
"145.02169362136888,  -37.97110434555331",Black Rock (Vic.)
"144.84150991063166,  -37.81604968444803",Brooklyn (Vic.)
"147.6531237546123,  -37.352233117788224",Brookville
"146.89447795944352,  -36.86914918544879",Buckland (Vic.)
"141.91938308862427,  -37.57601861122081",Bulart
"145.087075849775,  -37.7686615940413",Bulleen
"146.17486931861558,  -36.106983150750104",Bundalong South
"143.74598977253757,  -37.57153820438124",Bunkers Hill
"147.53549908933945,  -36.035682211096685",Burrowye
"142.94822530297276,  -35.18517857502881",Chinkapook
"144.83189331604797,  -37.393087582865306",Chintin
"147.27092554560528,  -37.63787166243198",Cobbannah
"143.13624642602252,  -38.55965807264163",Cooriemungle
"145.25003861207247,  -38.09877572710836",Cranbourne West
"143.89089370408936,  -37.4241276693031",Creswick
"143.72376243235584,  -37.11110280970108",Daisy Hill (Vic.)
"147.6252266544446,  -36.59777248577598",Dartmouth
"142.43359814050962,  -38.355147833492765",Dennington
"145.12394046343505,  -37.784789603061235",Doncaster
"146.3087607271279,  -38.26925774030151",Driffield
"143.05083516208506,  -37.20588038680301",Dunneworthy
"144.88514770577547,  -37.75407812211158",Essendon West
"144.54288625286947,  -37.78831693939279",Eynesbury
"144.73249281163544,  -36.32404816010592",Fairy Dell (Campaspe - Vic.)
"144.30106207396182,  -37.04662688602431",Faraday
"144.10340853606465,  -38.311267772902234",Gherang
"145.20740516557237,  -36.334261612139464",Gillieston
"146.55551860743333,  -38.08480294119578",Glengarry North
"144.87129516779453,  -36.677698282708526",Gobarup
"143.6555373695034,  -36.82012255177135",Goldsborough (Vic.)
"144.1122147414985,  -37.56653201102286",Gordon (Vic.)
"146.39196493944561,  -38.48069041616671",Grand Ridge
"144.87984005916712,  -37.636618685246916",Greenvale (Vic.)
"145.83962345508047,  -38.258081981714334",Hallora
"142.01760353019733,  -37.74583322305241",Hamilton (Vic.)
"141.6015915086127,  -37.14439994060085",Harrow
"146.4786589298408,  -38.27047263487444",Hazelwood North
"145.0617981068714,  -37.773346016730805",Ivanhoe East
"145.67833321658125,  -37.05422054301591",Kanumbra
"142.1878910369027,  -37.60119953111214",Karabeal
"145.49888460541712,  -36.46486088086361",Kialla East
"145.2493769668508,  -37.89058108235919",Knoxfield
"142.90225561399217,  -36.39814139740386",Laen East
"143.81655059343913,  -37.54637063384179",Lake Gardens
"143.51825620440871,  -37.149891619581844",Lamplough
"145.87343095881025,  -38.21760066534269",Lardner
"144.0965051670252,  -37.95597172529812",Lethbridge
"141.06099920419138,  -37.6115936053953",Lindsay
"145.3946327843693,  -36.80153695472712",Longwood (Vic.)
"144.95580789184658,  -36.08627016813679",Lower Moira
"143.51068726332397,  -37.77319842716926",Mannibadar
"144.04338999148058,  -37.861781498665195",Meredith
"145.4806893023993,  -36.60944136724862",Miepoll
"144.0483537817385,  -37.60418235977191",Millbrook (Vic.)
"144.12915745975792,  -35.91439865966034",Mincha West
"143.40546894979934,  -37.87265002726411",Mount Bute
"142.2167466631815,  -37.674801651451986",Moutajup
"143.20028959086721,  -34.95433379242608",Natya
"141.59118884930308,  -36.30080154452651",Nhill
"145.18553497223095,  -37.60216087319472",Nutfield
"142.67074088462735,  -35.38651477484942",Nyarrin
"142.882237972153,  -38.588026410144025",Peterborough (Vic.)
"146.4768950013441,  -38.68733052335173",Port Welshpool
"144.21100021436982,  -36.29958357773966",Prairie (Vic.)
"143.172003738325,  -38.651346990560015",Princetown
"145.23343882387098,  -37.81144379462404",Ringwood (Vic.)
"145.25364473316523,  -37.81323896404673",Ringwood East
"142.27018018962764,  -38.34483531793778",Rosebrook (Vic.)
"147.42644021833172,  -38.13668464228307",Seacombe
"141.0182348210313,  -36.37648607248245",Serviceton
"144.14588578589286,  -37.8979282360876",She Oaks
"142.43089486031937,  -35.40987938641729",Speed
"146.46295644867524,  -36.2075569380604",Springhurst
"148.32796060597772,  -36.924304570696016",Suggan Buggan
"145.13826977903472,  -38.50987777844696",Summerlands
"141.63020020783532,  -37.737020211736514",Tahara West
"142.1205591087182,  -37.767944670713405",Tarrington
"147.1724268071984,  -38.11794463955893",The Heart
"145.81086069134489,  -37.26545279782819",Thornton (Vic.)
"143.73445039038631,  -36.979211975777346",Timor (Vic.)
"143.14489581872257,  -37.860094027373904",Vite Vite
"141.82278855020792,  -37.93281512652017",Wallacedale
"145.31959093059913,  -38.225608756161726",Warneet
"143.61814018927575,  -37.333917766578836",Waubra
"143.25894122502837,  -38.22654660712634",Weerite
"144.64198220776086,  -37.906943199968794",Werribee
"145.9419398741687,  -38.368057585586946",Wild Dog Valley (Vic.)
"143.4633371440787,  -37.84908997205497",Willowvale (Vic.)
"149.48882660332058,  -37.63657566906388",Wingan River
"143.18843062106075,  -36.249257175530886",Wooroonook
"143.9792940382914,  -37.640962475525534",Yendon
"142.37827101090969,  -37.11913522077455",Zumsteins
"151.89978663299956,  -24.227546106812408",Agnes Water
"146.09996123627977,  -26.730258228905125",Bakers Bend
"151.69437801919054,  -27.251724924791652",Balgowan (Qld)
"150.16160032113137,  -24.488964847209274",Banana
"153.14945104028575,  -27.06000308914859",Bellara
"152.88664168554476,  -27.636768550575077",Bellbird Park
"153.1299123346865,  -26.73854931001951",Bokarina
"153.05625375936904,  -27.661830988018757",Browns Plains (Qld)
"152.6858335667952,  -28.05855521002332",Bunburra
"152.67512679127879,  -26.651387403792363",Cambroon
"152.80587887158885,  -27.07894001982574",Campbells Pocket
"152.92961963883377,  -27.621241934262507",Carole Park
"153.01256712988547,  -27.383501350788098",Chermside West
"149.4548284973752,  -22.05882181754451",Clairview
"150.85673371891798,  -24.761676436883683",Coominglah
"149.5921314015364,  -27.573072665305514",Coomrith
"150.7475799808964,  -23.27561521316521",Coorooman
"148.75330082384582,  -24.553737855564624",Coorumbene
"152.9384689648046,  -26.28761997144099",Cootharaba
"146.71412253209405,  -19.271386552805783",Cosgrove (Qld)
"153.08506230079215,  -27.688134594514217",Crestmead
"151.93946528736558,  -26.10589907659255",Crownthorpe
"146.02230752094547,  -17.511412060286965",Cullinane
"153.48302231216724,  -28.136634572163775",Currumbin
"153.39405997275696,  -28.208163607999587",Currumbin Valley
"145.32979426941904,  -16.25849635422105",Daintree
"152.04052732381732,  -25.361350983102117",Dallarnil
"151.1507092614197,  -26.51863611120535",Darr Creek
"149.25679896702934,  -23.605961771299995",Dingo
"152.90659197859446,  -27.35277064814116",Draper
"153.0546258280727,  -27.647643455477432",Drewvale
"151.93402144599048,  -25.659146845080457",Dundarrah
"145.65540968270912,  -16.725333614251795",Ellis Beach
"148.21497551601428,  -21.5062946049895",Elphinstone (Isaac - Qld)
"151.8925100888548,  -27.983008651147404",Elphinstone (Toowoomba - Qld)
"151.89064388188405,  -26.261864930662572",Ficks Crossing
"148.61924512376748,  -21.12199465209245",Finch Hatton
"152.95949961752436,  -27.94091151016063",Gleneagle
"152.6035356628692,  -26.961796083809997",Glenfern (Qld)
"152.4087762988817,  -27.523217027512977",Glenore Grove
"146.0309583638689,  -17.722182264621765",Goolboo
"152.00932324987613,  -26.13649862287826",Goomeribong
"149.28583250121378,  -21.349592311715732",Grasstree Beach
"151.8914468058254,  -27.776937288285627",Greenmount (Toowoomba - Qld)
"145.01644110686257,  -19.00279948057891",Greenvale (Qld)
"152.21334384503092,  -25.101929832283886",Gregory River (Bundaberg - Qld)
"149.55084779005128,  -25.306470054356765",Gwambegwine
"139.49067885922906,  -20.7479269127865",Happy Valley (Qld)
"152.4815156522682,  -27.56236266288282",Hatton Vale
"146.12672269301405,  -18.789193834932608",Helens Hill
"148.48253002891516,  -20.079767944755453",Hideaway Bay
"151.94734278066076,  -27.464274260909956",Highfields (Qld)
"153.33200509909577,  -28.01417807177631",Highland Park
"152.68117696919742,  -27.97161094609243",Hoya
"152.6985754938505,  -26.39059061169269",Kandanga
"152.84590370819498,  -25.305862241209628",Kawungan
"150.7774416710088,  -23.33764067066371",Keppel Sands
"149.78051749567,  -28.219326592171715",Kioma
"152.71183168767516,  -26.29112239934313",Kybong
"151.98511397539315,  -28.694843800077006",Kyoomba
"145.71979307857737,  -26.035214138073435",Langlo
"151.87473779014044,  -27.43287758076746",Lilyvale (Toowoomba - Qld)
"152.2369625053903,  -27.60378382453005",Lower Tenthill
"146.250782682701,  -18.539448196700953",Macknade
"151.99867766989794,  -27.856911942274923",Manapouri
"153.01846662659352,  -26.577219797837977",Maroochy River
"148.8865966559512,  -22.622389843677272",May Downs
"153.3726214829447,  -28.049224664035684",Merrimac
"151.10852585937124,  -24.84315379823736",Monto
"151.03825271312945,  -24.758253078501948",Moonford
"152.2009976937843,  -24.75475239635742",Moorland (Qld)
"152.77647664119243,  -27.318783531534383",Mount Glorious
"152.02920255094415,  -28.07899872191931",Mount Marshall
"150.57717934796364,  -24.33201994251503",Mount Murchison
"152.2086362509285,  -27.735260107125903",Mount Sylvia
"147.48520755505163,  -26.475328906487704",Mungallala
"152.6595318856906,  -27.751125767077756",Mutdapilly
"148.55199299289868,  -20.32926042345579",Myrtlevale
"149.12804042604728,  -21.065811641958756",Nindaroo
"152.97506715102858,  -26.54088582506105",Ninderry
"152.32730363005297,  -25.551827320646215",North Aramara
"151.62664578027042,  -27.845882172972992",North Branch (Toowoomba - Qld)
"153.45340687717157,  -27.551507981763475",North Stradbroke Island
"146.82125252581238,  -19.40837720263457",Oak Valley (Qld)
"151.4986637114694,  -26.161531330007787",Okeden
"149.0789604738047,  -21.20560309492678",Palmyra (Qld)
"153.07863374397152,  -26.47192624148162",Peregian Beach
"152.90836822155217,  -27.538409113209255",Pinjarra Hills
"146.72276302166867,  -19.35248229050816",Rasmussen
"152.03660395072853,  -26.27883872734365",Redgate (Qld)
"153.05707770694517,  -27.565027512484598",Robertson (Qld)
"146.80216400743268,  -19.46825393090089",Ross River
"152.65348445556475,  -26.922693751813217",Royston
"153.1099134963411,  -27.203932808316125",Scarborough (Qld)
"151.79866730733718,  -28.76966149468815",Somme
"149.09978874507772,  -21.368094240269677",Sunnyside (Qld)
"151.6715814947014,  -28.11524595493872",Thanes Creek
"152.5325814608954,  -27.61385410121058",The Bluff (Ipswich - Qld)
"152.68288817040025,  -26.247652785107114",The Dawn
"152.38365514203014,  -28.315997486848993",The Falls
"150.0764693905488,  -24.943976674287708",Theodore (Qld)
"146.86975416911577,  -19.50470340181609",Toonpan
"151.93750922688375,  -27.634228779258613",Top Camp
"145.70538888932575,  -16.80887306534009",Trinity Park
"153.0815684828836,  -27.553741092650032",Upper Mount Gravatt
"151.8851057842333,  -26.900930001985945",Upper Yarraman
"152.66367395059308,  -27.602711758675348",Walloon
"140.2914984384642,  -22.45745536664565",Warenda
"142.82401692110741,  -10.20738123205362",Warraber Islet
"152.60440605784018,  -27.829323960753214",Warrill View
"146.01638649163613,  -17.680281412999015",Warrubullen
"151.0197581046388,  -28.06338839898642",Wattle Ridge (Qld)
"150.92284469372527,  -27.855280172266514",Western Creek (Qld)
"148.9523109591637,  -20.231435634916465",Whitsundays
"145.35279815969048,  -16.34510866870368",Whyanbeel
"146.40316798522375,  -24.56715109514625",Windeyer (Qld)
"153.12807462474126,  -26.75651254242206",Wurtulla
"152.95722124240393,  -26.564487976495954",Yandina
"151.14865250326548,  -23.8172350639034",Yarwun
"138.4835858195951,  -35.27061012734101",Aldinga
"135.75279712421622,  -29.482541718312405",Anna Creek
"138.67856826140596,  -34.02920806870622",Auburn (SA)
"140.6868445030954,  -37.13642474715501",Bool Lagoon
"140.77547672354254,  -36.32176118180628",Bordertown
"138.65307742680392,  -34.7758907508602",Brahma Lodge
"138.59933788277004,  -34.82577408608276",Cavan (SA)
"140.82455356155177,  -37.95321683662091",Caveton
"133.60154746178904,  -32.263444308127184",Ceduna Waters
"138.5243147102509,  -34.86747949286636",Cheltenham (SA)
"135.94676395607812,  -33.266263542353876",Cootra
"139.91195925460153,  -34.80438794680687",Copeville
"138.8356410423593,  -34.862005643324785",Cudlee Creek
"137.5396086285,  -35.70535108086959",Cygnet River
"138.35098448966568,  -34.46455942183184",Dublin
"138.62901612101956,  -34.93680655492186",Dulwich
"139.56338289134428,  -34.735801549078545",Forster (SA)
"138.5258783623192,  -34.981561819909324",Glenelg East
"138.88737244941703,  -34.8217575193073",Gumeracha
"137.68373123324412,  -33.966148774027815",Jericho (SA)
"139.54530518533,  -34.873145391760666",Julanka Holdings
"134.87628490896572,  -32.58340901471766",Kaldoonera
"137.4165435609938,  -35.77072863552347",Kohinoor
"135.74089005951387,  -33.58622745741903",Lock
"138.84264652450955,  -35.17713458079349",Macclesfield (SA)
"137.6782458873375,  -34.34402476164288",Maitland (SA)
"137.08006396200835,  -35.69586485459741",Middle River
"138.62300124550845,  -34.984189041269076",Mitcham (SA)
"135.42291571972493,  -34.52281754332757",Mount Dutton Bay
"138.66991638875157,  -34.6582000361474",Munno Para West
"140.5268424122381,  -32.56915706090677",Mutooroo
"140.88310822891603,  -37.53842334429761",Nangwarry
"138.85158248265847,  -32.966991328324454",Peterborough (SA)
"138.7915130493451,  -34.076474226733644",Saddleworth
"138.54582282431193,  -35.02341800607724",Seacombe Gardens
"139.3370578912105,  -34.55278276122883",Sedan
"140.47431852293684,  -37.69805949736727",Tantanoola
"138.74352087982317,  -34.47499330425206",Templers
"139.2477216082683,  -34.523222526041835",Towitta
"139.20728380963283,  -34.430796249963855",Truro
"137.93194879624835,  -33.40162198172688",Wandearah West
"138.59452501695185,  -33.37081909882114",Washpool (SA)
"140.5070354978432,  -37.42734640316945",Wattle Range
"140.9035377273905,  -36.37898757836746",Wolseley
"115.92947858613861,  -31.934090163499413",Ascot (WA)
"115.72290714974349,  -33.28295860648087",Australind
"122.15868544557284,  -27.87732789036691",Bandya
"115.76860317512286,  -32.06934867123959",Beaconsfield (WA)
"122.78971179419223,  -33.389657767677846",Beaumont (WA)
"115.96053613938481,  -32.02418171321723",Beckenham
"114.90450362895571,  -29.09419586552759",Bookara
"116.13123006172749,  -33.95442394267314",Bridgetown
"116.67525423532386,  -34.11516944467418",Chowerup
"116.14888620334791,  -33.36061458533127",Collie (WA)
"115.80598541973089,  -32.081253015779794",Coolbellup
"115.37318749734074,  -30.668621740218814",Cooljarloo
"116.42791588480512,  -31.490127776329878",Coondle
"117.1839458322032,  -20.690487833079047",Cossack (WA)
"116.53560088235547,  -31.5611395729347",Dumbarton
"115.66866353260467,  -31.584407617553367",Eglinton (WA)
"119.45488428349907,  -33.74772893062894",Fitzgerald (WA)
"115.93425014518967,  -32.15506877879512",Forrestdale
"116.54625884230637,  -21.622992064376643",Fortescue (WA)
"115.77341080603536,  -32.56012073810007",Furnissdale
"115.91115747532862,  -31.346423282360703",Gingin
"120.13240471346232,  -32.88526057456448",Hatter Hill
"118.24338559230486,  -29.975958976341946",Karroun Hill
"117.48276449111633,  -30.815068035008032",Koorda
"117.70326324436988,  -32.36278173360059",Kunjin
"117.39607396208433,  -32.348295635488206",Kweda
"116.0453286002942,  -32.0073187229866",Lesmurdie
"117.85583074844695,  -35.00608768444023",Lockyer (WA)
"116.37809430394861,  -32.93314133203657",Lower Hotham
"116.14431328121533,  -34.24551724898713",Manjimup
"117.74843328014897,  -34.97557324737574",Marbelup
"115.9201880198792,  -31.71531974178999",Melaleuca
"116.01988672033129,  -31.858929759557597",Middle Swan
"118.56743816291223,  -34.26559637475821",Monjebup
"115.8746054797744,  -31.930204988800583",Mount Lawley
"119.84865309713494,  -31.50088272759168",Mount Palmer
"116.12580593876619,  -33.524585263150215",Mumballup
"116.66679071243695,  -31.390937102963914",Mumberkine
"115.8058007749696,  -32.14745805160787",Munster
"115.85564459730733,  -32.4991762376132",Nambeelup
"116.83016092367578,  -35.011942946809924",Nornalup
"117.40511609954852,  -35.024433034208734",Nullaki
"117.50592035424964,  -29.148005487690664",Paynes Find
"117.23391211524287,  -34.611312477444486",Perillup
"117.53935532241331,  -33.66358583027692",Pinwernying
"115.12391118911924,  -33.657617972612684",Quindalup
"115.80717748444967,  -31.05177935299854",Red Gully
"117.26917080249585,  -34.96382599373434",Shadforth (WA)
"118.4595357773877,  -32.1938456723225",South Kumminin
"115.0170919888953,  -28.365515135263674",South Yuna
"117.8975754205759,  -35.09232973808392",Torndirrup
"119.8963908645841,  -29.387161245835607",Ularring
"115.80422914622233,  -33.665700859092546",Upper Capel
"118.58463092173513,  -31.3376753529687",Walgoolan
"115.94890763157198,  -31.9926134043289",Welshpool (WA)
"128.49914214723194,  -15.748996372360214",Wijilawarrim
"116.2900694732009,  -33.95325644704049",Winnejup
"115.76569791255247,  -27.7138624030307",Woolgorong
"115.81621760255551,  -32.120584841940364",Yangebup
"115.40641397274484,  -29.11952281007871",Yarragadee
"115.05724696627834,  -28.25310259615081",Yuna
"145.63188686209568,  -41.072760971689505",Calder
"146.1429135969495,  -41.479257457793345",Cethana
"147.77165067965703,  -42.82804047536988",Copping
"147.0759284640233,  -42.596267722851984",Elderslie (Tas.)
"147.92889845953607,  -43.10607256289662",Fortescue (Tas.)
"147.19950375701256,  -42.81705134887524",Glenlusk
"146.99999638267877,  -42.73925263182961",Hayes
"146.00214054515683,  -41.09568461072249",Howth
"147.92306864839637,  -39.944029426898624",Lughrata
"147.15057416424918,  -41.4023413845117",Mowbray (Tas.)
"147.9487567123604,  -41.300281639359035",Pyengana
"146.89614318217943,  -41.1705662180449",Rowella
"147.56352643266766,  -42.63845229118846",Runnymede (Tas.)
"147.57776424179798,  -42.778054226618956",Sorell
"146.1658942999196,  -41.28344843267101",Sprent
"146.177562763184,  -41.45456612425174",Staverton
"146.8545029564049,  -42.77245655037377",Uxbridge
"146.96804080627822,  -43.210182787059466",Waterloo (Tas.)
"145.83352995282348,  -41.09673472888607",West Mooreville
"147.03814071239208,  -43.07767994227654",Woodstock (Tas.)
"131.6691841386604,  -15.401869034361516",Delamere (NT)
"129.0944222745561,  -24.872490148702006",Kaltukatjara
"130.947333654088,  -12.424351846839985",Knuckey Lagoon
"130.83514609971664,  -12.445687946310551",The Gardens (NT)
"130.8854045320979,  -12.380688105459736",Wagaman
"130.9337114844827,  -12.457942250889051",Wishart (NT)
"149.14321269303244,  -35.19955569822607",Franklin (ACT)
"149.0453446315703,  -35.19169136374176",Fraser
"149.1089747360782,  -35.41180193966622",Gowrie (ACT)
"149.15609192549883,  -35.199022991392106",Harrison
"149.1304499755865,  -35.14947729060415",Jacka
"149.0541243688094,  -35.2102350635093",Melba
"151.77248277418312,  -32.42856814748025",Alison (Dungog - NSW)
"150.81743561384332,  -30.911241882724728",Attunga
"151.55194572767752,  -33.09586878272346",Balcolyn
"152.70529246849023,  -31.317727988946306",Ballengarra
"153.5219952341208,  -28.68325067650936",Bangalow
"146.99514118133462,  -34.30252473762186",Beckom
"150.54914390180255,  -35.2055598722591",Berrara
"149.3131644280163,  -36.814528474811944",Bibbenluke
"148.78493238911759,  -31.559942372984914",Biddon
"149.11450475881432,  -36.01939351485475",Billilingra
"148.6609063115055,  -34.67214898919039",Binalong
"150.78108699421733,  -31.13569056512127",Bithramere
"150.81547211213154,  -33.7382592695172",Blackett
"150.89785675012013,  -31.754106907027282",Blandford
"149.03357756055132,  -32.442141987863295",Bodangora
"151.9093202602908,  -29.286220732634657",Bolivia
"151.27257087515125,  -33.8895963007929",Bondi Beach
"153.22985016678024,  -29.721939654112184",Bookram
"151.60977461021005,  -32.97487054759935",Booragul
"148.73494229746885,  -34.39576509179759",Boorowa
"150.463427027619,  -34.805878572279575",Budgong
"152.4951689780906,  -29.03552318185417",Bulldog
"146.41285998885795,  -30.57027032999854",Byrock
"149.9054357533595,  -36.15832753913928",Cadgee (NSW)
"151.11537868445689,  -33.86540811015497",Canada Bay
"148.64069716339742,  -33.53372827525614",Canowindra
"150.89953574611488,  -33.95218361973962",Casula
"147.3584250771586,  -35.06047304434217",Charles Sturt University
"149.25717874859507,  -32.05392212869777",Cobbora
"150.85669687110055,  -33.72424558511129",Colebee
"151.89730938070923,  -32.14296236310977",Craven
"148.6218957550041,  -29.999791782022562",Cryon
"147.01533939703876,  -35.67449871824517",Culcairn
"148.82681856096178,  -36.51695533153653",Dalgety
"149.19512865084306,  -34.723347103021126",Dalton
"149.26779153247534,  -36.3271746744914",Dangelong
"152.63768279495065,  -30.429298838483522",Darkwood
"150.97072023117812,  -31.037428845496127",Daruka
"153.39202410940672,  -28.377884089231713",Dunbible
"151.20288598321315,  -31.38693307571712",Duncans Creek
"150.8183729324735,  -31.231731151464857",Duri
"149.93731270564302,  -33.778789283242666",Edith (NSW)
"149.0809938123695,  -32.14940994489535",Elong Elong
"151.0764095605694,  -33.7726500513789",Epping (NSW)
"149.49961600468885,  -32.57357688413941",Erudgere
"148.67137991514778,  -31.967846920932917",Eumungerie
"150.55103259043133,  -33.68532097820761",Faulconbridge
"151.11576186804876,  -33.06634202035335",Fernances Crossing
"152.99836879570472,  -30.868868997038337",Fishermans Reach
"148.1278820111731,  -35.310726037990634",Gadara
"150.1555808446351,  -33.06294194746633",Glen Alice
"151.71389159229665,  -29.92866855242503",Glencoe (NSW)
"150.85408841856307,  -33.748309434738395",Glendenning
"150.6033295316885,  -34.07391369497344",Glenmore (NSW)
"152.4788502360122,  -31.92788425249444",Glenthorne
"149.67302408387147,  -34.25574368874855",Golspie
"153.30774101521737,  -29.380739860208404",Goodwood Island
"151.19769613272626,  -32.28724456102623",Goorangoola
"151.50039728662063,  -32.4127709914119",Gresford
"151.38668515188655,  -32.68893850130799",Greta (NSW)
"150.99093872080493,  -33.85620193515258",Guildford (NSW)
"151.06131272829046,  -33.38877599916702",Gunderman
"148.36973952025184,  -34.546415264503636",Harden
"144.81169251481023,  -34.65521161393841",Hay South
"149.89796533644218,  -33.655720796583175",Hazelgrove
"151.7353854908194,  -32.788933354398644",Heatherbrae
"152.54326278171203,  -31.388340830730584",Hollisdale
"150.6217868881276,  -32.324472884770515",Hollydeen
"147.6664238549774,  -34.71569484522756",Junee Reefs
"152.81882507719206,  -30.53065867941345",Kennaicle Creek
"151.22213045370017,  -33.77337147861972",Killarney Heights
"152.26370184695975,  -32.03852501749199",Krambach
"153.23468795027765,  -28.463716021591683",Kunghur
"153.0768954506589,  -29.4510291003723",Lawrence (NSW)
"142.39877226987159,  -31.738516810426884",Little Topar
"152.896997104901,  -30.177735393106843",Lowanna
"147.95511715152685,  -35.56139455186816",Lower Bago
"146.3368891449332,  -35.84295444982998",Lowesdale
"143.76138588288734,  -35.10469500548171",Mallan
"153.36366343407315,  -28.917544321760104",Marom Creek
"151.64451489266483,  -32.555299800198746",Martins Creek
"148.8998273290615,  -32.458891026520696",Maryvale (NSW)
"153.4136805283529,  -28.926568371201157",Meerschaum Vale
"153.46805351511367,  -28.48681854456814",Middle Pocket
"146.87039177011795,  -35.252033181315106",Milbrulong
"152.89693757118195,  -28.9504035169274",Mongogarie
"151.85324001584266,  -32.24988488711495",Monkerai
"153.46198272594987,  -28.591563006627887",Montecollum
"152.64172699864054,  -30.2218387953612",Moonpar
"151.49122632285395,  -33.117541961854826",Morisset
"151.5329896449712,  -33.121466996740075",Morisset Park
"148.8999967774741,  -32.551376059168575",Mount Arthur
"152.5834532271397,  -28.418209048958197",Muli Muli
"152.33877221640762,  -32.50348239955901",Mungo Brush
"152.3867132845469,  -32.424839648969744",Myall Lake
"149.7502256658771,  -33.38882940870877",Napoleon Reef
"147.61757133270413,  -34.328796243649016",Narraburra
"152.13918903593247,  -32.73540246899448",Nelson Bay (NSW)
"150.6142492378913,  -30.7312468327019",New Mexico
"144.79447702950304,  -31.592596817516693",Noona
"151.88216758225184,  -32.37706899505097",Nooroo
"150.1883629294041,  -35.68618679291751",North Batemans Bay
"151.12987388504732,  -33.79675103121876",North Ryde
"153.47115585550165,  -28.26098535492035",North Tumbulgum
"149.9117001236677,  -35.46915966580801",Northangera
"149.1745566128986,  -34.79221691294392",Oolong
"150.7426636177928,  -33.99240234261732",Oran Park
"150.70390700558983,  -32.05484781012586",Owens Gap
"151.24469029745413,  -33.73111595894645",Oxford Falls
"152.51022187219408,  -31.93694075599724",Pampoolah
"143.20851602857186,  -33.049761599195655",Pan Ban
"151.60404950991133,  -32.59067666634005",Paterson (NSW)
"151.07411790824122,  -33.73841658869232",Pennant Hills
"152.88329412300286,  -28.85903683694263",Piora
"147.3873460284084,  -34.40031949555189",Pucawan
"149.88609942671016,  -36.46583449210407",Quaama
"150.1954668945002,  -34.93244124306397",Quiera
"151.24097360110744,  -33.915465499372786",Randwick
"151.71709359976845,  -31.9986839895837",Rawdon Vale
"148.46615659828635,  -32.156615104345875",Rawsonville
"153.16255455039698,  -28.72825784080157",Rock Valley
"150.04590807533907,  -33.523758012563675",Rydal
"152.66041327059665,  -28.89660330837148",Sandilands (NSW)
"145.43680289586285,  -31.919558420956893",Sandy Creek (Cobar - NSW)
"152.81418252285687,  -28.37222142731505",Sawpit Creek
"152.71404531839175,  -31.064279578579455",Sherwood (Kempsey - NSW)
"147.75566062400802,  -31.776863795694904",Snakes Plain
"150.98160609612796,  -34.22866267067857",Stanwell Park
"152.52183376111824,  -28.86765982674585",Tabulam
"152.06845707960932,  -32.74263309928442",Taylors Beach (NSW)
"153.49183497462153,  -28.81521211379648",Teven
"152.7926890818442,  -28.782593009627625",Theresa Creek (NSW)
"150.90725079975374,  -30.33222045966655",Thirldene
"145.9675138435484,  -31.05097430590938",Tindarey
"152.83195673625588,  -30.083272480181698",Towallum
"153.4680024408398,  -28.81429709797402",Tuckombil
"144.16109650011234,  -35.38444695392023",Tullakool
"150.49546582209635,  -35.12353960925747",Tullarwalla
"148.05507124937046,  -35.73241199962382",Tumbarumba
"150.3974957448805,  -35.15353321557241",Twelve Mile Peg
"149.73617153518836,  -32.03802183953913",Uarbry
"150.70331588074467,  -33.41355846747164",Upper Colo
"153.4028042691615,  -28.603888294142873",Upper Coopers Creek
"152.56140757326014,  -31.991737362041658",Wallabi Point
"148.99348714373158,  -35.153300692154964",Wallaroo (NSW)
"147.49574262682862,  -35.80053141741495",Wantagong
"147.67659731029565,  -34.884745232415845",Wantiool
"150.85794802726642,  -31.157777545206486",Warral
"152.12809000435968,  -32.18972223408754",Warranulla
"151.08580409994187,  -31.872335158869333",Waverly
"149.28829872056176,  -29.0444481778078",Weemelah
"152.83184831799045,  -31.058691602129244",West Kempsey
"150.5833580707808,  -34.882088777772935",West Nowra
"151.44628529563923,  -30.848741460590695",Wollun
"150.6555438967973,  -32.271613943749315",Wybong
"148.49311468851658,  -35.68050898729218",Yarrangobilly
"145.4492445218516,  -38.521743068657706",Anderson
"145.87806181301934,  -38.392792479568335",Arawata
"144.7168446289136,  -36.96734508292403",Argyle (Vic.)
"143.86621042910608,  -37.5391589574514",Ballarat North
"144.43680706431329,  -37.80869393903237",Balliang East
"146.6576591148292,  -36.33814674937133",Beechworth
"143.8266222400636,  -35.49293230047385",Benjeroop
"144.2115603500017,  -37.4788079623162",Blakeville
"146.9412993205803,  -37.85201035094043",Boisdale
"146.62421260116545,  -36.46822848174379",Bowmans Forest
"145.33142237918884,  -36.23556870232396",Bunbartha
"143.5876760304287,  -37.22959162360371",Burnbank
"146.6035780873858,  -38.380460744813796",Callignee South
"143.15295135334975,  -38.23925705256366",Camperdown (Vic.)
"148.72635980363145,  -37.78982211516598",Cape Conran
"146.54743298864628,  -36.65412740018589",Carboor
"143.4903199407366,  -37.59684443782217",Chepstowe
"145.1302098566345,  -37.918193768750115",Clayton
"145.46749339927385,  -38.42310990284567",Corinella (Vic.)
"141.36526253757702,  -35.18819090652646",Cowangie
"146.9923565231989,  -37.38783770767499",Crooked River
"142.94594609750163,  -38.44297799946095",Curdies River
"144.02390023571772,  -36.65219635045826",Derby (Vic.)
"143.1821763412536,  -37.96196209506349",Derrinallum
"145.12562835267136,  -37.97556520280666",Dingley Village
"141.26451124983421,  -38.01101436120772",Drik Drik
"147.32024027747613,  -38.205987089303804",Dutson Downs
"144.3801170997682,  -38.155281463210194",East Geelong
"144.98264860123237,  -37.81435517897106",East Melbourne
"145.06713962743967,  -37.4956679013518",Eden Park
"142.65383789558717,  -38.128137481130175",Ellerslie (Vic.)
"146.84659298100541,  -36.63878068274034",Eurobin
"147.48025784736458,  -37.753457099722866",Flaggy Creek
"147.31337821955782,  -38.27021849902143",Flamingo Beach
"146.69909741628032,  -36.50351444737961",Gapsted
"144.22762249616792,  -38.07846959420217",Gheringhap
"144.89450386963534,  -37.689881438697014",Gladstone Park
"144.32230059697693,  -37.54028235636467",Greendale (Vic.)
"144.25834819565293,  -36.99344325966413",Harcourt
"144.72249098407508,  -36.91534797304174",Heathcote (Vic.)
"141.52670584763644,  -37.658964333354426",Henty (Vic.)
"144.31154882748814,  -38.170200911905475",Highton
"143.41745439039244,  -38.45395336466952",Irrewillipe
"146.55587084606807,  -38.568184960693905",Jack River
"143.3944133823078,  -38.73426440193108",Johanna
"145.23160967030077,  -37.689134336367026",Kangaroo Ground
"142.20484491255993,  -35.93442114296355",Kenmare (Vic.)
"145.35816264906018,  -37.51070772645494",Kinglake
"145.4886755299052,  -38.18744704533946",Koo Wee Rup
"142.34147109815606,  -36.91419683225438",Laharum
"141.08102426664718,  -37.49917560909367",Lake Mundi
"142.92567259172588,  -37.39216324331027",Langi Logan
"142.76001882034606,  -36.36319565881536",Lawler
"146.44402635593715,  -36.3432111517174",Londrigan
"145.78221975873197,  -38.08031656003553",Longwarry North
"147.6715999865717,  -37.80779069595115",Lucknow (Vic.)
"149.7995489146021,  -37.510143829776325",Mallacoota
"144.603449988789,  -38.218361285423505",Mannerim
"142.86358974019362,  -36.21981972689376",Massey
"146.88917173505226,  -38.60722185970286",McLoughlins Beach
"142.12415616629568,  -34.20847303166733",Mildura
"144.08242956538962,  -35.78603307541222",Milnes Bridge
"146.15034341443484,  -38.395017285558396",Mirboo North
"147.12182801981672,  -36.589120666328526",Mongans Bridge
"146.82488790081482,  -37.78474571946369",Monomak
"143.88525125373098,  -37.62862846909815",Mount Helen
"144.0463747489497,  -37.39407883816082",Mount Prospect
"145.1757822167067,  -37.92565235549369",Mulgrave (Vic.)
"145.2527319647498,  -36.61480035067528",Murchison East
"143.5654356523664,  -35.281256220402874",Murraydale
"143.8075291312114,  -37.69009229554349",Napoleons
"143.93429148086122,  -37.61277291233876",Navigators
"147.3594155936006,  -36.836289940285006",Nelse
"142.97738642771654,  -38.201536721015884",Noorat East
"141.68342927489263,  -36.62194564722789",Nurcoung
"143.3632486699913,  -35.1470204691116",Nyah
"147.91915591909256,  -37.891484746963116",Nyerimilang
"145.42302740922577,  -38.100931315194394",Officer South
"146.37718055802873,  -36.44870633282101",Oxley (Vic.)
"145.24647514673097,  -37.64278146181178",Panton Hill
"143.63537373364414,  -37.724080017103255",Piggoreet
"142.24704919020724,  -34.34269751285192",Red Cliffs
"145.15402664693647,  -37.25581224905353",Reedy Creek (Vic.)
"146.62476749644856,  -37.35634111412626",Reynard
"145.08350180921502,  -37.631560708276744",South Morang
"145.3809456110353,  -37.57967404675401",Steels Creek
"147.1626985277225,  -36.07464069126312",Talgarno
"143.7141913992913,  -38.6565831833325",Tanybryn
"144.78625316832677,  -37.69858147286923",Taylors Lakes
"144.38001688844233,  -36.11635625300545",Terrick Terrick East
"147.4283042264069,  -35.990966322111085",Thologolong
"146.58076983374497,  -37.93254524854035",Walhalla East
"142.87647916732706,  -36.747147922561076",Wallaloo
"144.6307338540278,  -36.09348903046707",Wharparilla
"144.24334868997136,  -37.33895890703048",Wheatsheaf
"145.13119556631833,  -37.465564984237254",Whittlesea
"142.74540341198133,  -37.387246774741946",Willaura North
"144.7430322980165,  -37.862017759382475",Williams Landing
"143.38681951687613,  -36.47151674460421",Yawong Hills
"143.7068643582435,  -38.39497100781515",Yeodene
"146.3548794022021,  -38.36714962348841",Yinnar South
"152.01057103371105,  -24.831031195463382",Abbotsford (Qld)
"148.39739816044255,  -24.43963913345223",Albinia
"153.22895860910117,  -27.53248589806503",Alexandra Hills
"150.50392325494553,  -23.39395856107636",Allenstown
"147.8101913667915,  -23.597786786280377",Anakie Siding
"147.4795040072807,  -23.298672972069138",Argyll
"151.66498849946205,  -27.508962477762378",Aubigny
"152.14975782661318,  -24.767829711706817",Avondale (Qld)
"148.62587632154768,  -25.601664840155795",Baffle West
"150.58867541597073,  -23.692626304245007",Bajool
"145.33645029842333,  -23.61736976310229",Barcaldine
"152.89002914294144,  -27.08432691931528",Bellmere
"148.5706416004343,  -20.702289239001164",Bloomsbury
"146.87275989596992,  -27.118962055963483",Boatman
"151.94181704978374,  -26.53677694849709",Booie
"153.0696033081054,  -27.349023232606125",Boondall
"153.1127610871931,  -26.9140759717051",Bribie Island North
"150.8099949758129,  -26.894363442285524",Brigalow
"145.93142627645489,  -17.895403410770335",Bulgun
"143.90165852814152,  -17.619270092368964",Bulleringa
"152.4058289111624,  -24.77795611974071",Burnett Heads
"153.11325416170325,  -27.511609217844555",Carindale
"146.9854602095871,  -25.670560119852887",Caroline Crossing
"145.4522914837204,  -17.296724582268826",Carrington (Qld)
"147.45457192214135,  -19.651065035094618",Carstairs
"152.8183011518653,  -27.333714221183072",Cedar Creek (Moreton Bay - Qld)
"151.10464950703553,  -25.26783622526324",Ceratodus
"147.82760992423044,  -22.828710345876285",Cheeseborough
"152.98535166339425,  -28.287214948122095",Chinghee Creek
"147.82383695081433,  -20.56097934321224",Collinsville (Qld)
"150.32747786470384,  -26.690377133271014",Columboola
"148.72194152414082,  -20.453366292019158",Conway
"153.33066155067547,  -27.569834795030534",Coochiemudlo Island
"145.53466560466038,  -26.65718891804849",Cooladdi
"146.24733391614913,  -18.606191966451277",Cordelia
"151.47601961707724,  -26.278985573977675",Coverty
"139.5920270487547,  -21.817335615409455",Dajarra
"150.23233209851247,  -23.359234439207544",Dalma
"145.93795804910818,  -17.211173440965464",Deeral
"146.07044934310395,  -17.85330590988868",Djiru
"149.11777824534101,  -21.132271663711983",Erakala
"152.60698336368347,  -27.97189307590456",Fassifern Valley
"151.8914826750301,  -27.626662153853914",Finnie
"146.17944439119773,  -18.60139154836017",Gairloch
"153.27263224160203,  -27.743907910572936",Gilberton (Gold Coast - Qld)
"150.15888887434608,  -25.542706119154655",Glebe (Qld)
"151.90068237215468,  -25.249728012805985",Good Night
"152.42057030833928,  -26.982890423289412",Gregors Creek
"153.23921985382646,  -27.938048793435932",Guanaba
"151.95455496965866,  -27.53126302996492",Harlaxton
"153.06148059988084,  -27.68225222036762",Heritage Park
"152.54203615847499,  -25.331708972779666",Howard
"146.15441066959295,  -18.655095201834357",Ingham
"151.37433734991748,  -24.124030991085053",Iveragh
"142.6178826260384,  -11.239004865592175",Jardine River
"151.09079035269426,  -26.642179984720794",Jinghi
"146.03673548446673,  -17.476875582877955",Jubilee Heights
"152.1470157519334,  -28.24877249472832",Junabee
"152.99237840041548,  -27.248098785779955",Kallangur
"150.50511402262717,  -23.338927542847877",Kawana
"152.77001629136973,  -26.01617748385758",Kia Ora (Qld)
"152.26250272915945,  -25.341741031354864",Kullogum
"151.2990066331113,  -27.78833994674642",Lemontree
"146.46194614011202,  -21.32174606186111",Llanarth (Qld)
"146.0790303746081,  -17.695818468596023",Lower Cowley
"153.2017938278536,  -27.79072904457082",Luscombe
"150.93633732034104,  -23.833354041163883",Machine Creek
"153.42578927007045,  -27.957080786749334",Main Beach
"145.58783917795762,  -17.375227902464164",Malanda
"152.28554866494346,  -26.37333859472557",Manumbar
"148.68927371512385,  -20.659898997163598",Midge Point
"139.4875966943512,  -20.720902389634414",Miles End
"151.52450406111151,  -24.774832777178563",Molangul
"151.1427307517147,  -24.601130681492286",Monal
"152.5798998527862,  -27.970159096052758",Morwincha
"152.59191952334615,  -28.078267533545382",Mount Alford
"151.93904172288472,  -27.006517077911447",Mount Binga
"153.22584594641194,  -27.620327635128113",Mount Cotton
"147.31152072701735,  -19.6686278237795",Mount Kelly
"148.37137260802328,  -26.64165517722803",Muckadilla
"153.09382200299154,  -26.61903383180311",Mudjimba
"152.73349680720074,  -27.570915334002006",Muirlea
"152.45173047748594,  -25.880052272159183",Munna Creek
"152.95831140811978,  -26.624198420096718",Nambour
"148.78333347342618,  -21.66144025765021",Nebo
"152.5322601257644,  -25.782071578493568",Netherby (Qld)
"148.51338014193246,  -26.29332920461233",Orallo
"153.25514292078805,  -27.509957173661224",Ormiston
"145.6823431253516,  -17.615177745920032",Palmerston (Qld)
"152.14803702488115,  -27.405274109431552",Palmtree
"147.45764909057092,  -21.790470675773012",Pasha
"145.47766044372565,  -20.975936272438858",Pentland
"152.8173321663707,  -25.25705568982206",Point Vernon
"145.46075197675546,  -16.505476641440517",Port Douglas
"151.7553407705663,  -28.090832157535722",Pratten
"152.63601929669358,  -27.852034939797",Radford
"152.78781136967564,  -26.759414486861164",Reesville
"146.88684095210218,  -19.74803684543013",Reid River
"149.52333538552924,  -24.726976708317707",Rhydding
"152.53252163330282,  -27.453344065570228",Rifle Range
"151.7121287438313,  -27.22325013793472",Rosalie Plains
"153.07640621768914,  -27.597977796632847",Runcorn
"152.74515039298265,  -27.61326649286764",Sadliers Crossing
"151.4136680186419,  -32.726732639463094",Allandale (NSW)
"148.95853384875952,  -32.61322739236469",Apsley (NSW)
"153.19119731942303,  -30.05285321374625",Arrawarra
"150.90000785890763,  -33.91606399437156",Ashcroft
"151.7290030896972,  -28.96173611386982",Back Creek (Tenterfield - NSW)
"153.41305594995137,  -28.976076462677366",Bagotville
"151.0317269531113,  -33.92056964530631",Bankstown
"149.05828846084384,  -30.929099488841953",Baradine
"148.89587389011905,  -31.666612104483175",Bearbong
"147.6998872141429,  -33.333427852754085",Bedgerabong
"149.75892499519307,  -29.93546886901522",Bellata
"152.8886168566168,  -30.452906955462385",Bellingen
"145.8451963690664,  -34.430440054734056",Benerembah
"150.4492716516663,  -33.53504877551429",Berambing
"150.84321015347635,  -34.47870188362095",Berkeley
"150.0394099769352,  -36.43170122959837",Bermagui
"152.6702995881722,  -30.368372167101136",Bielsdown Hills
"152.79805625233334,  -29.88306925031782",Blaxlands Creek
"145.31105728231188,  -35.59935718042596",Blighty
"152.75412072313392,  -31.591687225716424",Bobs Creek
"150.04645434394251,  -30.69187394370477",Boggabri
"150.60369364512928,  -34.84670105506277",Bomaderry
"148.2446353748664,  -35.26230338101841",Bombowlee
"151.6276792830559,  -32.94779876548636",Boolaroo
"153.20404957994484,  -29.064313540652034",Bora Ridge
"153.0696196783514,  -28.39505809565201",Border Ranges
"151.40258384719374,  -33.516416982623326",Bouddi
"151.2640319026057,  -33.90425786155137",Bronte
"146.58486936438098,  -35.302296399180356",Brookong
"150.65767392580975,  -34.89236060080432",Brundee
"148.71983620316774,  -36.16171827833432",Buckenderra
"149.48031745985648,  -35.294029490409415",Bungendore
"152.63816259960313,  -30.72708132004232",Burrapine
"148.45798086290307,  -36.31370386868258",Burrungubugge
"151.10380226316894,  -33.87802371007066",Burwood (NSW)
"150.697327421401,  -35.00278933515298",Callala Beach
"150.55874297950535,  -34.82246332266292",Cambewarra Village
"152.05280577827904,  -29.389060462630574",Capoompeta
"150.84831085985806,  -33.93791662945363",Carnes Hill
"152.2335918554223,  -30.83290117844937",Carrai
"152.78371412300694,  -30.22834140315239",Cascade (NSW)
"150.03595627065445,  -31.968595366830733",Cassilis (NSW)
"150.99982005769778,  -33.727920390720655",Castle Hill (NSW)
"150.82477774965082,  -34.293704791013596",Cataract
"151.64363554387793,  -33.10624407183897",Caves Beach
"152.7444044544403,  -29.76142469758792",Chambigne
"151.44512977699569,  -33.321656412228165",Chittaway Point
"150.77086305714252,  -34.50288304127113",Cleveland (NSW)
"149.4495551882602,  -32.601857865657216",Collingwood (NSW)
"150.69334944251662,  -33.2968027299271",Colo Heights
"147.0081896525191,  -32.90035479320481",Condobolin
"151.77001822591077,  -31.87254132003141",Craven Plateau
"151.68479975514262,  -30.629047331485467",Dangarsleigh
"150.859901664109,  -33.7348604208984",Dean Park
"151.28894251618658,  -33.752510738931456",Dee Why
"147.3519537137917,  -33.10092390626534",Derriwong
"152.97915350776395,  -29.49797585878065",Dilkoon
"150.17773026487646,  -33.49167961790272",Doctors Gap
"152.29827678455143,  -31.866664593423916",Dollys Flat
"149.9565586792512,  -33.68445899602168",Duckmaloi
"153.15782951208962,  -30.163984741338503",Emerald Beach
"150.24813185785217,  -35.24072992990431",Endrick
"151.83428591811568,  -30.731167386603623",Enmore (Armidale Regional - NSW)
"150.97265349199867,  -33.874143753668555",Fairfield East
"151.19920346405226,  -32.54932768242167",Fern Gully
"153.4909107315598,  -28.768080576629416",Fernleigh
"151.59479227090725,  -33.05256221122414",Fishing Point
"149.54777445874183,  -33.623302782696534",Fosters Valley
"153.430655319715,  -28.604398233220163",Goonengerry
"150.8251059787854,  -31.34481652140404",Gowrie (Tamworth Regional - NSW)
"150.6621700977543,  -33.92788119369701",Greendale (Liverpool - NSW)
"150.65563124710746,  -33.58538637429708",Grose Vale
"149.55900534121903,  -35.57962963102752",Harolds Cross
"150.7744834095821,  -34.489282283152164",Horsley
"151.1289232215954,  -33.91016928725129",Hurlstone Park
"151.0958938711401,  -34.002807820037",Kangaroo Point (NSW)
"150.91945077035658,  -33.703884851655424",Kellyville Ridge
"150.79821750730218,  -34.467715334323316",Kembla Grange
"148.41125114295863,  -31.901033354749334",Kickabil
"151.15687333313357,  -33.76811824351564",Killara (NSW)
"152.2924639932899,  -31.894985252269",Killawarra (NSW)
"149.8509125821423,  -33.42240375392348",Kirkconnell
"150.6938221332657,  -34.619923231133285",Knights Hill
"152.97494626430515,  -29.589983468673037",Kyarran
"151.1500623924744,  -33.81149647201877",Lane Cove West
"147.63180237612116,  -35.83215034797858",Lankeys Creek
"153.1096616838412,  -28.663131229459164",Larnook
"150.33793573927906,  -33.706849939813324",Leura
"152.93490967373697,  -29.825035035664936",Levenstrath
"147.51259506478854,  -35.620288394132245",Little Billabong
"150.37388621963348,  -35.29321637827549",Little Forest
"146.76750326641115,  -35.17948166910445",Lockhart
"152.29047949824846,  -30.682271536564137",Lower Creek
"150.7288517777369,  -32.31820705718166",Mangoola
"151.29134630998394,  -33.804863409375386",Manly (NSW)
"151.53984914885356,  -33.166473481419665",Mannering Park
"152.6799696682412,  -31.1895037250372",Marlo Merrican
"150.84168811377597,  -33.61272780811398",McGraths Hill
"151.74341743254394,  -32.94786141830438",Merewether
"150.1382136637044,  -35.981307852902596",Meringo
"149.56959035297558,  -32.158570268191035",Merotherie
"149.7333376213105,  -34.597686057882974",Middle Arm
"153.49713158565302,  -28.435663069594707",Mooball
"152.19990132554875,  -31.86749121087013",Mount George (NSW)
"146.04822057931332,  -35.924035667759114",Mulwala
"148.107891102946,  -36.44342371608744",Murray Gorge
"149.02284513098562,  -34.97302462525798",Murrumbateman
"152.84192317841337,  -29.617720152322043",Mylneford
"150.81527336359042,  -30.590084356369914",Namoi River
"150.46385181981853,  -35.31256159995294",Narrawallee
"149.2836363500923,  -36.50139903711184",Nimmitabel
"153.34049611969792,  -28.329703269072816",North Arm (NSW)
"153.07069197251704,  -30.309948575659774",North Boambee Valley
"151.74328511625234,  -31.458628732362484",Nowendoc
"151.31058063894227,  -31.881155209556585",Omadale
"151.22848898608913,  -33.88378151425432",Paddington (NSW)
"149.06254012911558,  -36.24921327301641",Pine Valley
"150.98390024741295,  -33.96986006812757",Pleasure Point
"153.55797586310118,  -28.387616341653647",Pottsville
"150.87207969352596,  -33.94164549986633",Prestons
"150.3518354423709,  -35.57791299782703",Pretty Beach (Shoalhaven - NSW)
"150.63231740063048,  -31.496034721415125",Quirindi
"151.30660231214324,  -33.1136499304407",Ravensdale
"151.1601722174143,  -33.823963373657406",Riverview (NSW)
"151.00486200652585,  -29.717022060664753",Rob Roy
"152.824020617601,  -28.472720903457407",Roseberry Creek
"152.09004654987442,  -32.7329682235158",Salamander Bay
"151.53707198842253,  -32.16949560547687",Salisbury (NSW)
"153.24511008430525,  -28.891449209657452",South Gundurimba
"149.098803660607,  -33.34281031946879",Spring Creek (NSW)
"150.57579912617402,  -33.69411916386925",Springwood (NSW)
"145.28166805004977,  -34.98319846083",Steam Plains
"152.94682087963932,  -28.75050484941982",Stratheden
"149.87693850996135,  -33.37726943941573",Sunny Corner
"151.23675850780202,  -29.718030733748165",Swanbrook
"149.01878731122397,  -34.27619143273076",Taylors Flat
"151.4153635958248,  -30.11330437430754",Tenterden (NSW)
"147.2502260293798,  -32.26474202759614",Tottenham (NSW)
"152.95796443596132,  -29.614900798908536",Trenayr
"145.30737717802097,  -35.682202200119605",Tuppal
"149.92381836029108,  -36.860630216914956",Tura Beach
"147.3619674231003,  -35.12735108869826",Turvey Park
"153.32821593906937,  -28.41454729096386",Uki
"151.46076465576564,  -32.14598963935066",Upper Allyn
"150.66478165591192,  -30.069838195094132",Upper Bingara
"153.40503600812775,  -28.2577057081955",Urliup
"146.87497420557142,  -35.73605448515719",Walla Walla
"151.70090462803495,  -32.50265948506638",Wallarobba
"153.45459397322256,  -28.36619905249083",Wardrop Valley
"151.02047388977397,  -32.558624061533756",Warkworth
"150.88253485888367,  -34.48673049761143",Warrawong
"148.3046272239814,  -35.37849194092951",Wermatong
"150.90740050305612,  -31.100948879890275",West Tamworth
"152.63084620082537,  -30.938291660589016",Willawarrin
"150.68563252266782,  -34.26592450509484",Wilton (NSW)
"151.45487147108722,  -32.67674609908476",Windermere (NSW)
"149.5714625784303,  -32.79974112295392",Windeyer (NSW)
"149.36634654670286,  -36.48857086772731",Winifred
"147.73073426456634,  -33.62717050776214",Wirrinya
"150.52084259044662,  -29.159050854823892",Yallaroi
"149.6548725909315,  -32.36707455013345",Yarrawonga (NSW)
"151.36594805564272,  -33.46812294097385",Yattalunga (NSW)
"143.91509330719617,  -37.36194654424663",Allendale
"142.9279352025507,  -36.54382243657125",Avon Plains
"143.73821277182222,  -35.68720834217039",Bael Bael
"144.34502365473367,  -37.818428394445675",Balliang
"145.172190638805,  -38.048381438334474",Bangholme
"144.2564001985563,  -38.37509219028613",Bells Beach
"146.4478990954373,  -38.58914777700377",Binginwarri
"143.9797166941761,  -38.42874829913943",Boonah (Vic.)
"146.39756245361139,  -36.236877035009144",Boralma
"141.8055240386315,  -37.84597798286953",Branxholme
"144.373559688603,  -38.182283973543825",Breakwater
"146.10380598174999,  -36.908486133998096",Bridge Creek
"149.11927956508063,  -37.24436098794334",Buldah
"144.22225013556405,  -37.427816584462754",Bullarto South
"147.40409053279342,  -36.06902319847723",Bungil (Vic.)
"144.75300052984883,  -37.749298541248464",Burnside (Vic.)
"143.4183027246099,  -38.5657646540472",Carlisle River
"147.37719491288462,  -37.08629282029539",Cobungra
"145.26694189447002,  -37.77562930068859",Croydon Hills
"142.93106476991372,  -38.30528167112457",Dixie (Vic.)
"142.9853666591019,  -36.33222744989507",Donald
"145.1913108122632,  -37.78982347393196",Donvale
"145.23835896254576,  -37.98851276582178",Doveton
"145.06356024825124,  -37.76356239980343",Eaglemont
"145.33535928968365,  -37.876655133908905",Ferny Creek
"144.29567791837906,  -36.78109601418927",Flora Hill
"145.16384086727342,  -38.12390795059855",Frankston North
"145.97600627493162,  -38.227390983566906",Gainsborough
"147.07646871670917,  -36.73802141686502",Germantown (Vic.)
"146.62964633257212,  -37.69369594519955",Glenfalloch
"146.90814984350934,  -36.62222464544571",Havilah (Vic.)
"142.0568939990481,  -37.624501186230205",Hensley Park
"146.77490056897935,  -37.97803473758138",Heyfield
"143.6749524760881,  -38.27149037586307",Irrewarra
"142.99085986589384,  -37.0147715566272",Joel Joel
"145.61239205417323,  -38.43891049546539",Kernot
"145.31842026517785,  -37.81431270513636",Kilsyth
"141.87156520324314,  -37.9888498619531",Knebsworth
"143.38723146609382,  -37.53481575683323",Lake Goldsmith
"144.02945555270844,  -37.6730821549735",Lal Lal
"145.19099501670493,  -38.18906184983939",Langwarrin South
"143.81963966555222,  -37.12870860802387",Majorca
"149.62028918381688,  -37.397350874039",Maramingo Creek
"143.35540209338475,  -35.57201404101288",Meatian
"145.0810119445252,  -38.390853744477795",Merricks
"145.07674274911415,  -38.34516558513469",Merricks North
"143.79846499973826,  -37.48034917422522",Miners Rest
"143.17178449582542,  -36.64365954736776",Moolerr
"146.93884006189217,  -37.489878375100936",Moroka
"146.41460197622976,  -38.22755593933374",Morwell
"146.56190300125775,  -38.38866219571477",Mount Tassie
"146.23578185454926,  -38.27080675552746",Narracan
"145.9838430001007,  -38.01296790311696",Neerim South
"144.36317479212204,  -37.41762608072979",North Blackwood
"145.21572250214646,  -37.72582037898939",North Warrandyte
"143.38770659765643,  -37.07255948893139",Percydale
"143.95807684775127,  -37.528796765600354",Pootilla
"145.83302586720217,  -38.330045816195074",Poowong East
"145.231199449706,  -37.79313499788777",Ringwood North
"146.582341319271,  -36.90829820053896",Rose River
"145.3977654644139,  -36.3722956909438",Shepparton
"143.28081235733978,  -36.796107306117754",Stuart Mill
"144.6459767758985,  -38.2208890938198",Swan Bay (Vic.)
"143.10321876907088,  -36.5410443109421",Swanwater
"148.08059166522773,  -36.59564163263759",Tom Groggin
"146.54618683848406,  -38.28991093446621",Traralgon South
"144.40275701504135,  -37.41000341604403",Trentham East
"145.97326582057354,  -38.324424728307164",Trida
"143.04849013581824,  -36.94376818184286",Tulkara
"145.32903711214348,  -37.905441229157496",Upwey
"149.74187693538929,  -37.433802350082956",Wallagaraugh
"145.2258793192173,  -37.8523551243396",Wantirna
"145.93000341970367,  -38.15372516668654",Warragul
"143.67240869186807,  -37.43385687478232",Weatherboard
"144.0535690464862,  -38.37375243113289",Wensleydale
"144.92517516728827,  -37.80935005168919",West Melbourne
"147.04513924168566,  -37.2556157395928",Wongungarra
"145.06114843939235,  -37.54438302046378",Woodstock (Vic.)
"143.44847056012895,  -35.29404649018739",Woorinen South
"142.0838331517038,  -35.67899197172367",Yaapeet
"145.69217733676481,  -36.16895687774906",Youanmite
"143.439573576457,  -17.799784595906946",Abingdon Downs
"152.6600917115611,  -25.47451331377152",Aldershot
"146.6982786030889,  -23.619189281604427",Alpha
"145.92762867551102,  -17.340785274862426",Babinda (Qld)
"152.6713751925383,  -26.151264656960496",Banks Pocket
"139.5265607432099,  -24.397725117802555",Bedourie
"148.2009767365154,  -23.02991616560525",Belcong
"150.51594635693098,  -24.397255955014778",Biloela
"152.0817688350185,  -26.907700718726556",Blackbutt South
"148.99857680538437,  -21.487666906565345",Blue Mountain
"152.81470113374039,  -25.58717923593098",Boonooroo Plains
"139.8630579916745,  -16.6642479193751",Bountiful Islands
"153.03423057575472,  -27.320030135379483",Bracken Ridge
"146.26673706313304,  -18.647668500117835",Braemeadows
"152.2332102847408,  -27.41646957091809",Buaraba South
"153.0779151600845,  -27.495852207890565",Camp Hill
"149.31381064299717,  -21.378013142113275",Campwin Beach
"150.914362396593,  -26.658756789726098",Canaga
"153.1365309236505,  -28.03831721512714",Canungra
"150.77736120977445,  -23.19466614197052",Causeway Lake
"150.67317975752078,  -23.251062103178384",Cawarral
"143.1762447968992,  -13.758693410455363",Coen
"146.71663134432504,  -19.329643224439092",Condon
"150.75898059865952,  -23.14361191488941",Cooee Bay
"153.53364821660375,  -28.169806820446468",Coolangatta (Qld)
"151.9506776765786,  -25.36706127019806",Coringa
"151.89516633358167,  -27.522395034992584",Cotswold Hills
"148.270407615628,  -23.227077880097575",Crinum
"150.08706309538377,  -26.57318514412675",Dalwogon
"146.03786393311293,  -17.779523598364236",Daveson
"151.47326088159366,  -25.65581508808174",Deep Creek (Qld)
"150.31733648344783,  -23.967239332932788",Dixalea
"149.1846394131288,  -21.040282352885022",Dolphin Heads
"153.0145564395837,  -26.45060268238857",Doonan
"152.6731331667661,  -28.01911417183672",Dugandan
"152.05008161724416,  -25.107432166499258",Duingal
"152.54629519990112,  -25.503780095245258",Dunmora
"152.61676132758487,  -27.68059588277935",Ebenezer (Qld)
"142.6395398839915,  -18.852086239425404",Esmeralda
"145.34986763928657,  -16.458623591144313",Finlayvale
"152.59793787305557,  -26.166488082678054",Fishermans Pocket
"147.1125900826258,  -22.245591995015573",Frankfield
"145.840591161519,  -18.517349834412467",Garrawalt
"149.87989446196661,  -24.97692559111284",Glenmoral
"152.57554371479372,  -25.876526701032002",Gootchie
"152.98846917246377,  -27.639797158012065",Heathwood
"151.7616769032769,  -27.268006649225974",Highland Plains (Toowoomba - Qld)
"152.81124038831936,  -27.373651696191942",Highvale
"153.35963452193596,  -27.86825729785368",Hope Island
"150.3843866293079,  -23.66668744338789",Horse Creek
"148.92730154886414,  -24.26452982189516",Humboldt
"144.62558929729934,  -16.531357983552514",Hurricane
"149.79084438914543,  -27.622653758581922",Inglestone
"153.04468903458874,  -27.83443358671367",Jimboomba
"150.4123401964196,  -23.623946078814217",Johnsons Hill
"152.66184283706957,  -26.237544072674726",Jones Hill
"145.37629887323584,  -16.586158434084467",Julatten
"150.39234798578892,  -23.50601213947185",Kabra
"153.04751045995252,  -27.41004193831742",Kalinga
"141.84500466402378,  -17.575024261692327",Karron
"148.50908396048607,  -20.435924808702943",Kelsey Creek
"145.42961159880048,  -16.488900926505373",Killaloe
"145.5990142557068,  -18.192343569290824",Kirrama
"152.38701035796677,  -27.67365445365622",Laidley South
"143.7022541717726,  -23.34863180953701",Longreach (Qld)
"150.0192112375335,  -28.066736295193593",Lundavra
"152.59018534611118,  -27.559734567025167",Marburg
"146.02042782854517,  -17.541639514586088",Mighell
"139.49613335773682,  -20.734503288168312",Mornington (Qld)
"152.38190315455637,  -27.487082504886864",Morton Vale
"145.3572052061758,  -16.46949479769426",Mossman Gorge
"152.9485648960181,  -27.474880889277397",Mount Coot-tha
"152.81129284226472,  -27.52845279452858",Mount Crosby
"153.0868515082026,  -27.530031644154686",Mount Gravatt East
"146.7337218216061,  -19.277063399198262",Mount Louisa
"151.29826572444816,  -25.583030451979994",Mundubbera
"146.31842887568942,  -18.97127134745676",Mutarnee
"153.08992509020712,  -27.210507907888687",Newport (Qld)
"152.77665373989515,  -27.616341431814003",Newtown (Ipswich - Qld)
"150.50513138677977,  -23.304871939269997",Parkhurst
"152.16486277625634,  -25.02881055145381",Pine Creek (Qld)
"152.51487219253866,  -27.52728840129982",Prenzlau
"146.2822682278322,  -20.0753563920295",Queenton
"146.81871158593484,  -19.276265906526728",Railway Estate
"151.08089724554586,  -27.148635181210217",Ranges Bridge
"141.88537731289134,  -12.623840734214044",Rocky Point (Weipa - Qld)
"150.78582112497628,  -23.174423955583396",Rosslyn
"151.80846423925286,  -27.93360111105276",Ryeford
"152.88414143680478,  -27.37396592068185",Samford Village
"146.60549064386035,  -19.162835817922875",Saunders Beach
"141.88128711451566,  -19.20813489160675",Savannah
"153.17747947245178,  -27.649681664889847",Shailer Park
"151.93016421673224,  -25.137029590140035",St Agnes (Qld)
"148.80612802014937,  -20.85079504095945",St Helens Beach
"150.43457654384508,  -27.254357134957754",Tara (Qld)
"150.75491955204674,  -23.150004389832397",Taranganba
"153.01673897392004,  -26.39377332864019",Tewantin
"151.85688858517753,  -28.703960130879803",Thorndale
"152.97598705912208,  -26.40242542627788",Tinbeerwah
"148.65380205749972,  -23.9937250863143",Togara
"151.4881877565302,  -27.911905421080952",Tummaville
"150.6989359862718,  -23.32892749532321",Tungamull
"150.50960824073556,  -23.943160794151982",Ulogie
"148.63588889316452,  -22.1973187213616",Valkyrie
"151.41589883235036,  -28.632799078226302",Warroo (Qld)
"148.0209469362852,  -24.471173520017093",Wealwandangie
"139.40038136094202,  -16.55639134383309",Wellesley Islands
"152.82664732090743,  -26.70909528025764",Witta
"151.61034710817532,  -24.989201878026087",Wonbah Forest
"152.86126270647605,  -25.317865535491688",Wondunna
"145.46532224437684,  -17.330271342583057",Wongabel
"152.72752850778028,  -27.61317873548499",Wulkuraka
"153.16701009029887,  -27.43956737724518",Wynnum
"151.4108210665061,  -25.01503062343233",Yarrol
"138.34085889977936,  -34.281991207975516",Avon (SA)
"140.461013278359,  -37.92841701786368",Blackfellows Caves
"135.0485545740138,  -32.5852783489804",Bockelberg
"138.4132501376312,  -33.93457585421",Bowillia
"138.65025950572652,  -34.984204795245866",Brown Hill Creek
"138.95217641986815,  -35.00886926578716",Brukunga
"138.5064110789736,  -34.68386463282089",Buckland Park
"138.59417954895872,  -33.279492323250544",Bundaleer North
"140.71104825946713,  -36.78372348894169",Cadgee (SA)
"139.24514430380984,  -34.97963946302703",Caloote
"140.70646923773265,  -34.0934313223386",Chaffey
"137.99804102683922,  -34.21247426281843",Clinton (SA)
"138.61986381833142,  -34.91174113589106",College Park
"138.64637667129742,  -35.04764244177386",Coromandel East
"138.39727937591584,  -34.22356019476094",Erith
"140.88096702165805,  -36.70317594334522",Frances
"138.51801902258262,  -34.96869494487728",Glenelg North
"138.52508170726813,  -35.155089069002344",Hackham
"138.9980710533862,  -34.97051287921366",Harrogate
"138.56583799058853,  -34.93213376825992",Hilton (SA)
"138.70443303898665,  -34.935104342371964",Horsnell Gully
"132.0285374715761,  -26.773105964629124",Kaltjiti
"138.67021460060138,  -35.152501952353774",Kangarilla
"138.57952184891192,  -34.93621932160172",Keswick Terminal
"138.56698993063728,  -34.95144123211555",Kurralta Park
"138.49287173962972,  -35.10666217854144",Lonsdale
"138.89010485733152,  -34.58560835637618",Lyndoch
"140.09091529402025,  -37.416981275127995",Magarey
"138.4928232394659,  -34.436763504586295",Mallala
"138.6347978880642,  -34.892611789868646",Marden
"139.1947718730168,  -35.653201488850364",Meningie West
"140.033495868139,  -34.63687615993197",Mercunda
"138.44326302563863,  -34.59869506208733",Middle Beach
"139.09721346755177,  -33.57334146938153",Mongolata
"134.86637474259368,  -28.25871943141331",Mount Willoughby
"139.20244049312907,  -35.30664115422375",Mulgundawa
"137.88299870565677,  -32.440216716935204",Mundallio
"140.87612227599757,  -34.08086616611837",Murtho
"135.84989460611888,  -34.61285060870679",North Shields
"138.62615809369086,  -34.84822051237413",Northfield
"138.53161478453472,  -34.85755933044778",Pennington
"135.88643726658788,  -32.973510743640766",Pinkawillinie
"138.95609548351948,  -35.46283156173354",Point Sturt
"140.82384565665714,  -36.4418390816228",Pooginagoric
"138.03072827773084,  -32.996700414229245",Port Germein
"138.5214924090877,  -35.096657172110255",Reynella
"138.57045702959877,  -34.89416281909488",Ridleyton
"138.6275977784094,  -34.74584175645475",Salisbury North
"140.37607766213836,  -37.516574368386415",Sebastopol (SA)
"138.48085629745285,  -34.84720931961948",Semaphore South
"137.8652409252626,  -34.69455600530325",Sheaoak Flat
"138.57984957384025,  -35.00422444951076",St Marys (SA)
"138.67675138749297,  -34.93421446858413",Stonyfell
"140.88872880924617,  -34.42278522816171",Taldra
"138.74512048071054,  -34.96059547147537",Uraidla
"137.6032663759959,  -34.59184471896088",Wauraltee
"138.5610770488014,  -34.8410353335655",Wingfield
"138.53807288483148,  -34.877060296873196",Woodville (SA)
"138.64482720893199,  -34.48613396768633",Woolsheds
"137.6753402210117,  -32.1300605579752",Yarrah
"139.54951428455792,  -34.87457946894111",Younghusband Holdings
"115.31800932188914,  -33.71195977290318",Ambergate
"115.56015323785743,  -29.467214175056984",Arrino
"116.99876956379167,  -31.806499935243952",Badgin
"115.59515808175897,  -30.424977468591813",Badgingarra
"115.83061617735945,  -32.329001466590725",Baldivis
"116.06684161733925,  -32.17996077937575",Bedfordale
"115.90364539326224,  -33.24456435873639",Beela
"116.26348447932119,  -29.356740635825854",Bowgada
"116.05722058203682,  -31.91748655704685",Boya
"116.56902233557629,  -29.969204728673127",Buntine
"115.72050986960751,  -31.720603675547018",Burns Beach
"122.21036640965153,  -17.950584436005546",Cable Beach
"115.94135694710158,  -34.590501973533485",Callcup
"115.977616135264,  -32.25093711014982",Cardup
"114.62141039657212,  -28.69252135199611",Glenfield (WA)
"116.0129388974924,  -28.991217419754967",Gutha
"115.77885346766661,  -32.1582360868609",Henderson
"115.78577983807071,  -32.068073139169485",Hilton (WA)
"116.07580364990119,  -33.024478546119276",Hoffman
"119.43823176858649,  -32.67046727301003",Holt Rock
"128.9220596806706,  -26.07204093614988",Irrunytju
"115.06861796149369,  -29.223406224993834",Irwin
"126.10328321255828,  -24.954338292440625",Kanpa
"115.46569253879302,  -31.057635539841723",Karakin
"115.22973079067566,  -33.66640189247823",Kealy
"119.57857884720875,  -25.07306671806472",Kumarina
"119.60533329336121,  -30.461244092543037",Lake Deborah
"116.56562242083174,  -34.500879563780295",Lake Muir
"122.56529481986212,  -28.70841281895743",Laverton (WA)
"116.24297847059886,  -32.13601479938251",Lesley
"115.9958091456462,  -32.04760587583417",Maddington
"118.35511460564611,  -33.88862413497683",Mindarabin
"115.76786060504784,  -31.33039311354264",Muckenburra
"115.74261305522576,  -31.77837270889951",Mullaloo
"118.48732874856456,  -33.96457136569703",Ongerup
"116.29904644109418,  -34.45007486351676",Quinninup
"116.0745512055466,  -31.827567750811383",Red Hill (WA)
"115.7654514021876,  -31.896487686383974",Scarborough (WA)
"116.82774697059914,  -20.77558030566927",Stove Hill
"116.28645856805684,  -32.98923633806194",Upper Murray
"118.24610517952205,  -32.86845696462241",Walyurin
"115.81580642495034,  -31.757102362198275",Wanneroo
"116.48423931512636,  -31.320590886179026",Wattening
"115.91178549317057,  -32.020510875405876",Wilson
"114.63336226818721,  -28.766880546334683",Wonthella
"117.3210801365657,  -31.552560284559046",Wyola West
"147.20141020671636,  -43.0150880799053",Allens Rivulet
"146.6464669483888,  -41.17381267863247",Bakers Beach
"146.8107904344839,  -41.40457235902353",Birralee
"147.33202528022503,  -42.968012422547275",Bonnet Hill
"146.4830014041687,  -42.227010872810006",Bradys Lake
"145.84663628400185,  -41.042078279102086",Camdale
"146.5883623234807,  -41.845041331345456",Central Plateau
"147.52853674075658,  -42.98001813389199",Clifton Beach (Tas.)
"144.71375636837195,  -41.1773763165454",Couta Rocks
"147.0489262187821,  -42.932910954115385",Crabtree
"146.24735205131026,  -42.133872410212966",Derwent Bridge
"148.18926833356304,  -41.784599543695776",Douglas-Apsley
"148.3033029286129,  -42.20508372341069",Freycinet
"147.32629151657218,  -42.87328708373509",Glebe (Tas.)
"147.29327596288212,  -42.826841392410934",Goodwood (Tas.)
"145.69202352491143,  -41.45548590428281",Guildford (Tas.)
"146.017306605295,  -41.28764300623624",Gunns Plains
"146.77133946170034,  -41.27753729914063",Holwell
"147.41183318615543,  -42.88483082844521",Howrah
"146.36153742532113,  -41.5559415179494",Mole Creek
"146.5289063087615,  -41.44714518910313",Moltema
"147.316943285926,  -42.87155769390934",North Hobart
"144.04287476701515,  -39.92912321131722",Pegarah
"146.20487197667708,  -41.42505965035117",Promised Land
"147.11055934479248,  -41.482886846004725",Prospect Vale
"145.5439801196038,  -42.085335057782274",Queenstown (Tas.)
"146.22609950963002,  -41.411418351595536",Roland
"147.46278483987055,  -41.24720378763228",Springfield (Tas.)
"147.60641944698511,  -41.17474358075892",Tonganah
"148.19023406081746,  -41.4531341962314",Upper Scamander
"144.89567806794784,  -40.779199616883375",West Montagu
"145.3484976270927,  -41.89040012825012",Zeehan
"134.31350683755392,  -13.659263842457941",Bulman Weemol
"131.84476416372166,  -13.501693897065602",Burrundie
"130.86706968095615,  -12.552708435360614",Channel Island
"130.9188436442896,  -12.909709145369654",Collett Creek
"133.70702004957397,  -24.300741727513667",Hugh
"135.5132738001283,  -18.92836284069758",Tablelands (NT)
"133.87369451240065,  -23.714783694215118",The Gap (NT)
"133.41395621069074,  -22.133808241782038",Ti Tree
"130.4960366714324,  -15.661541744415125",Timber Creek
"131.23633823122535,  -12.66033749029068",Wak Wak
"131.79287139404315,  -22.25583454194775",Yuendumu
"149.2123444555024,  -35.26932621262936",ACT Remainder - Majura
"149.1939334321158,  -35.30440971298385",Canberra Airport
"149.04399062519573,  -35.205999946736725",Flynn (ACT)
"149.1318739349796,  -35.40527702771593",Macarthur (ACT)
"149.13638567697475,  -35.218878750823386",Mitchell (ACT)
"149.06212937874818,  -35.35293112037694",Waramanga
"152.31904269758115,  -28.376131961885143",Acacia Creek
"151.49502575209405,  -32.67914611698303",Anambah
"149.44800845576128,  -35.77530333841244",Anembo
"152.07629830502023,  -32.76624933214224",Anna Bay
"150.82279592345645,  -30.962716657677753",Appleby
"153.18370697422566,  -29.442077786875046",Ashby (NSW)
"151.02612016199032,  -33.85472629466855",Auburn (NSW)
"149.92908548761292,  -30.572361048917895",Baan Baa
"150.98864935369943,  -33.92263462123389",Bankstown Aerodrome
"147.4187890125192,  -34.107715059553946",Barmedman
"150.46262965151897,  -34.91174201180237",Barringella
"150.1983623985976,  -35.73758322561496",Batehaven
"151.20075577439096,  -33.911023272936745",Beaconsfield (NSW)
"148.48628168043328,  -34.804712953695",Berremangra
"153.48428328133423,  -28.209881120569733",Bilambil Heights
"150.58414017058627,  -29.826088052649073",Bingara
"145.18461412415326,  -35.51833670012319",Birganbigil
"151.68996148752288,  -32.89385915333119",Birmingham Gardens
"146.6839450598662,  -32.27190190753396",Bobadah
"149.15584589943126,  -36.607212644265175",Boco
"145.84251256934687,  -35.89121266966191",Boomanoomana
"152.35894717871847,  -31.92445946067814",Bootawa
"152.49543546949116,  -30.467930683871522",Brinerville
"152.89125940988225,  -30.292745834577936",Brooklana
"150.73031432100183,  -34.75679296425026",Broughton (NSW)
"152.68952894761807,  -30.646255458481484",Buckra Bendinni
"148.4400289443485,  -35.94863241437348",Cabramurra
"149.00890928372934,  -33.44289923492509",Cadia
"146.26575658439273,  -31.510304488188854",Canbelego
"149.30930966385475,  -36.28534273206659",Carlaminda
"151.78086388975407,  -30.634627764904042",Castle Doyle
"151.57562734870078,  -33.165623107154794",Chain Valley Bay
"151.62276564156636,  -32.16531383878927",Chichester (NSW)
"143.9680791149775,  -33.50494703856541",Clare (NSW)
"151.1041119881836,  -33.92619174724442",Clemton Park
"149.19029714971504,  -35.86027214754565",Colinton (NSW)
"149.46036597630982,  -36.99970127771392",Coolangubra
"152.32279444605518,  -32.22289839705379",Coolongolook
"150.1050521285256,  -35.048056231368676",Coolumburra
"150.1775890619575,  -33.475390245596344",Corney Town
"147.08059026690378,  -34.9622201129723",Currawarna
"150.05423654951136,  -32.84726645002817",Dabee
"152.74247106278702,  -31.139643427171805",Dondingalong
"152.12257376841035,  -31.451455554393004",Doyles River
"152.52334990471633,  -29.299995775243538",Dumbudgery
"150.71480793752468,  -34.056961299996026",Elderslie (Camden - NSW)
"153.41133006080926,  -28.76070870777046",Eltham (NSW)
"148.35363408558194,  -33.41169285353554",Eugowra
"147.46330398796852,  -35.146100017388896",Forest Hill (NSW)
"149.55202322422932,  -34.1614001011235",Fullerton
"149.61898374119815,  -29.0026407895272",Garah
"149.0374769134352,  -33.75700163638885",Garland
"152.54837336275324,  -31.883206816347908",Ghinni Ghinni
"150.77733417617614,  -34.13758189847825",Gilead
"148.87836408885275,  -32.01452582807814",Goonoo Forest
"152.93374634473986,  -29.680859070000043",Grafton
"151.03462933223324,  -31.78784590380966",Green Creek
"148.38875310030076,  -34.02929664289345",Greenethorpe
"150.92724296987402,  -30.7673442054588",Halls Creek (NSW)
"151.4091462004071,  -32.699188988127815",Harpers Hill
"151.67401752302382,  -32.961285166779135",Hillsborough (Lake Macquarie - NSW)
"150.91199675997834,  -31.12913764394703",Hillvue
"153.2353581639478,  -28.28755792226034",Hopkins Creek
"152.95197743788424,  -28.614460265350623",Horse Station Creek
"151.09316251943505,  -33.97822442590692",Hurstville Grove
"150.8172312317177,  -34.67754519367152",Jerrara
"149.44563666196132,  -35.67287039891946",Jingera
"151.71178209942752,  -32.96258644099646",Kahibah
"149.70490973263787,  -36.72132123677264",Kameruka
"150.78807669087317,  -33.8666820936095",Kemps Creek
"152.5426600956113,  -31.631187575126706",Kerewong
"151.44402674009814,  -33.207480217753194",Kiar
"150.7698974272721,  -34.98015486411928",Kinghorne
"151.12559275725658,  -30.501722715674944",Kingstown
"148.20338026412762,  -36.49024824778132",Kosciuszko
"151.6907651488645,  -32.95131618126258",Kotara South
"153.01542762417364,  -28.6395296587909",Kyogle
"150.93105568117437,  -33.761102221861805",Lalor Park
"149.58797628454914,  -32.03765793991195",Leadville
"153.30068581068696,  -28.803102872861718",Lismore Heights
"150.54157270093106,  -31.79170037637128",Little Jacks Creek
"149.79999941674444,  -33.51369053604566",Locksley (NSW)
"145.5064645053047,  -35.464097794270245",Logie Brae
"152.0443621214182,  -30.356418310033966",Lyndhurst (Armidale Regional - NSW)
"147.6305357803092,  -30.69565687518427",Macquarie Marshes
"151.12702646363743,  -33.780283720406146",Macquarie Park
"150.29082458382734,  -33.67785923126817",Medlow Bath
"148.12848516316132,  -34.29479798813325",Memagong
"149.067473678281,  -31.820355424424253",Mendooran
"151.01384018361824,  -33.63748458114658",Middle Dural
"150.84277194054184,  -33.91452544879067",Middleton Grange
"151.10333805848654,  -34.0326170417782",Miranda (NSW)
"149.56459589852247,  -36.70800005576876",Mogilla
"150.50759391733416,  -35.179211142502176",Mondayong
"149.90439678832732,  -32.69570213737144",Monivae
"151.5403232518561,  -32.7408474957249",Mount Dee
"147.56766871748025,  -31.190758044345593",Mount Foster
"149.94507130882604,  -34.09166366820085",Mount Werong
"149.57311211178376,  -32.61512582197298",Mudgee
"148.16159950510266,  -33.566433166188446",Mulyandry
"146.37111223191607,  -33.20672499098236",Murrin Bridge
"150.82515079912983,  -31.776150025976733",Murrurundi
"151.21785163426904,  -33.83472557981635",Neutral Bay
"151.13116350795977,  -33.69776590708326",North Wahroonga
"149.7953071688839,  -33.77136809682187",Norway
"150.18291448650612,  -33.46432973745725",Oakey Park
"147.84944214973726,  -35.43510608679886",Oberne Creek
"149.87758954214888,  -36.929543621842626",Pambula
"152.8740239241995,  -31.054463893096333",Pola Creek
"149.52621044220166,  -34.68781900402852",Pomeroy
"150.62621007934698,  -35.10321676171332",Sanctuary Point
"149.98227093197156,  -33.56159888481893",Sodwalls
"150.80498155378498,  -33.63008798240034",South Windsor
"150.2051607103904,  -33.448082847147255",State Mine Gully
"152.80887823037273,  -29.51637917192087",Stockyard Creek (NSW)
"151.08171189848323,  -33.8785622224337",Strathfield (NSW)
"152.84399802595354,  -30.70244454965215",Talarm
"149.44241220363,  -32.22978318542106",Tallawang
"149.4113369104578,  -32.96376973357367",Tambaroora
"147.93711528893974,  -35.61359584263426",Taradale (NSW)
"148.49902857843227,  -32.077897418386506",Terramungamine
"145.9464003629191,  -34.26438794302652",Tharbogang
"149.89808566732898,  -36.237896150430984",Tinpot
"150.9097612616513,  -33.87422669815717",Wakeley
"150.6344590729548,  -34.724857429576204",Wattamolla
"151.19760443929567,  -33.83984413707449",Waverton
"151.08384411854328,  -33.807330130111055",West Ryde
"150.86751591769632,  -34.42358637739986",West Wollongong
"150.8992238542633,  -33.84265184716007",Wetherill Park
"151.33179878751082,  -33.61254497472573",Whale Beach
"152.97840093346704,  -28.511408858901643",Wiangaree
"152.26939825203817,  -32.17841544313707",Willina
"150.79203608682482,  -34.739747057867696",Willow Vale (Kiama - NSW)
"148.28385878291806,  -30.52192350067272",Wingadee
"151.15542225544456,  -32.94928948992872",Wollombi
"150.80027315679598,  -33.23586881074383",Womerah
"150.82072839072526,  -34.04747037603962",Woodbine (NSW)
"152.83707728099617,  -28.684016727287176",Woolners Arm
"151.46662859098274,  -33.17281491232358",Wyee
"150.14912543449293,  -31.665458598125866",Yarraman (NSW)
"144.99976344570837,  -37.80457663162312",Abbotsford (Vic.)
"143.67810055511183,  -37.02204186078556",Alma (Vic.)
"143.65321231431145,  -37.13593670245508",Amherst
"141.08412189592437,  -36.96671790480918",Apsley (Vic.)
"145.28274656927258,  -36.92496793783064",Avenel
"144.99492803877564,  -37.87032981743288",Balaclava (Vic.)
"144.5262164615183,  -36.6478146903277",Barnadown
"143.45225979391302,  -36.26366198011438",Barrakee
"146.25785401326283,  -37.84154143872624",Baw Baw Village
"141.48481791123038,  -35.620982118495256",Big Desert
"143.09460102309492,  -34.738313496628344",Boundary Bend
"144.0597482815202,  -36.91862252551071",Bradford
"145.92265665088036,  -38.08299294986934",Bravington
"144.96847037283885,  -37.78655666503911",Carlton North
"146.68225485774528,  -38.37630068552476",Carrajung
"143.89461703740756,  -37.47797263232264",Chapel Flat
"145.9948512375799,  -38.24031490112054",Cloverlea
"145.35385098660035,  -38.14824642977317",Clyde (Vic.)
"145.6416335257411,  -35.9352558867811",Cobram
"143.02624181288957,  -36.1842467731268",Corack
"143.5366577232929,  -38.262406285952856",Coragulac
"144.78027501842774,  -36.46135139187825",Corop
"145.30540069625587,  -38.11000124769655",Cranbourne East
"143.56095339870467,  -38.106645750432776",Cundare North
"144.9353526256488,  -37.67078891332225",Dallas
"143.03021144588146,  -37.987715201505324",Darlington (Vic.)
"143.98359508364723,  -37.45540885938067",Dean
"145.21315000849773,  -36.53184230398839",Dhurringile
"145.01040883023202,  -38.33190105765997",Dromana
"145.86376573522972,  -38.082000196726966",Drouin West
"144.30471724531807,  -37.24042206512082",Drummond
"147.14337033823656,  -38.10412505632811",East Sale
"143.17789836090964,  -37.203676216000304",Eversley
"144.8971413655148,  -37.798195422891325",Footscray
"143.73090705587805,  -37.34679833705912",Glendaruel
"143.017159571653,  -36.678626196650725",Gre Gre South
"145.26860316498474,  -38.03856689734678",Hampton Park
"143.53275341770825,  -37.03748545579413",Homebush (Vic.)
"144.69507885907112,  -37.866665341555716",Hoppers Crossing
"142.19623039332785,  -36.71892036928242",Horsham
"146.09728387843228,  -37.88436223119103",Icy Creek
"144.3718406808424,  -36.49681397396563",Kamarooka
"145.16734389948374,  -38.0054236919668",Keysborough
"145.3109912168526,  -37.8314824533218",Kilsyth South
"144.87938171518854,  -37.8088586288911",Kingsville
"143.23888553330278,  -38.292199936736324",Koallah
"145.71252860743195,  -38.51146418999935",Kongwak
"145.9520255352754,  -38.55979674325381",Koonwarra
"145.65695258643777,  -38.27184288234919",Lang Lang East
"144.06620604495262,  -37.4129778849582",Langdons Hill
"145.97320402020696,  -38.42973604714361",Leongatha North
"144.1693140191659,  -36.811487231289775",Lockwood
"144.07133284347367,  -36.998727345385404",Maldon (Vic.)
"146.1270639119776,  -38.47107197670417",Mardan
"144.45596964989747,  -37.1464888052558",Metcalfe East
"141.20627896636623,  -35.19764082014755",Murrayville
"143.80871031337716,  -38.4206731330087",Murroon
"145.19538348837486,  -36.05747321269341",Nathalia
"143.98401785674093,  -37.41670480706086",Newlyn
"142.72471817926464,  -35.44219145350519",Ninda
"144.34924947107038,  -38.11592136947908",North Geelong
"145.1007852895347,  -36.94880367220926",Northwood (Vic.)
"145.04163078769946,  -37.90390164783212",Ormond
"144.1956030250873,  -37.27273890189299",Porcupine Ridge
"144.13198510247105,  -36.04938552175267",Pyramid Hill
"142.89294885029446,  -36.5174223795652",Rich Avon East
"143.76414374249924,  -37.64620007058726",Ross Creek (Vic.)
"142.86165797764633,  -37.518596917337725",Rossbridge
"143.1807681490988,  -36.76075269648954",Rostron
"146.07735719598182,  -36.80386900084836",Samaria
"146.12750882460958,  -38.81130624320744",Sandy Point (Vic.)
"145.348906217808,  -37.86422199498679",Sassafras (Vic.)
"144.56022649504794,  -37.16358863179891",Sidonia
"145.21632338656593,  -38.105027699479095",Skye (Vic.)
"144.28609072661033,  -36.8002044288195",Spring Gully (Vic.)
"144.76255625860574,  -36.25960673041914",Strathallan
"144.8969683392334,  -37.713421805827856",Strathmore Heights
"145.04487600169114,  -37.24783249398404",Sunday Creek
"143.22268395184437,  -36.19662794827285",Teddywaddy West
"145.1486330972961,  -37.75404092825428",Templestowe
"142.45046719991598,  -35.29257666872899",Tempy
"145.56363956530143,  -38.38722091424371",The Gurdies
"145.00798220669049,  -37.68373304364172",Thomastown
"143.33400965080818,  -35.94186243988993",Towaninny South
"141.4239151024776,  -37.43926530409772",Wando Bridge
"143.11445111332532,  -37.28429960259135",Warrak
"145.26486636143952,  -37.057540056079695",Whiteheads Creek
"145.77601866390637,  -38.43464436481418",Whitelaw
"146.345203896984,  -36.8225020765422",Whitlands
"145.03959351258976,  -36.683716265360864",Whroo
"146.84159225148613,  -38.267957538547265",Willung
"144.53193625578726,  -37.32042779551421",Woodend North
"146.0076692475931,  -38.41475694562601",Wooreen
"145.6822554207619,  -36.23099263294153",Yabba North
"145.6439795040696,  -38.225300197674315",Yannathan
"145.18645314855007,  -35.902915634162966",Yielima
"146.31903924879273,  -38.33253509270357",Yinnar
"152.29537909687056,  -27.51267170003881",Adare
"145.75667003726846,  -16.876829247064034",Aeroglen
"151.81264943723482,  -28.601548822082766",Amiens
"152.66205078593913,  -26.15857113087752",Araluen (Qld)
"151.99680690565415,  -27.82540877224378",Ascot (Toowoomba - Qld)
"150.38974702272552,  -23.619531780365033",Baree
"145.98914461170963,  -17.525006847294087",Belvedere
"151.9253998569171,  -27.493295568847397",Birnam (Toowoomba - Qld)
"153.11825000775198,  -26.74402646334868",Birtinya
"152.84945109504574,  -26.421649128599455",Black Mountain (Qld)
"152.80297498216797,  -27.629871977652055",Blackstone
"148.79122104193615,  -23.485434242456012",Blackwater
"145.95804855454497,  -17.686347518094017",Bombeeta
"148.60493965219106,  -20.434892417682565",Breadalbane (Qld)
"151.13692985465582,  -28.076548334037785",Bringalily
"149.53744074578495,  -25.502578678564166",Broadmere
"151.1333275723579,  -25.895690457146433",Brovinia
"139.5282922159394,  -22.207169272588917",Buckingham (Qld)
"153.06292250733114,  -26.686089937075998",Buderim
"148.61083744741933,  -26.46467897544143",Bungeworgorai
"146.69460077898518,  -19.23023749300828",Burdell
"152.43641306099033,  -24.9345649653619",Calavos
"152.9748358672477,  -27.512928030249018",Chelmer
"148.07642864140968,  -23.155723370508053",Chirnside
"141.72756198615645,  -18.564245470891073",Claraville
"153.39732372786372,  -28.041963595971804",Clear Island Waters
"151.2179413174815,  -23.875782634202597",Clinton (Qld)
"152.74376727550313,  -27.60610606768835",Coalfalls
"153.3396487199127,  -27.84689951483943",Coomera
"152.16792551159807,  -27.241250505705796",Cressbrook Creek
"152.74964726792763,  -26.678632879243967",Curramore (Qld)
"145.22729700205792,  -16.16794259827422",Dagmar
"149.07878909235978,  -21.131680057107122",Dumbleton
"152.73078381329037,  -25.464952116148943",Dundathu
"152.98576504964998,  -27.589312709478303",Durack (Qld)
"153.21927517204685,  -27.698529175307385",Eagleby
"151.95196696125,  -27.772961698787373",East Greenmount
"150.68914986710462,  -25.37096325381033",Eidsvold West
"152.2287462363705,  -25.7669444962255",Gigoomgan
"148.1198824891506,  -23.766880458475388",Gindie
"149.92782486223788,  -26.485561825963707",Glenaubyn
"152.06459606944716,  -28.106433580573988",Glengallan
"146.00894291914332,  -17.519100889023616",Goondi Bend
"149.49025544329683,  -23.519350790862447",Goowarra
"152.59749072702795,  -25.999665376755296",Gunalda
"149.08131872503517,  -21.02633777416808",Habana
"152.67011226693953,  -27.80909567595029",Harrisville
"153.0238866383467,  -28.22904428503833",Hillview
"145.65826001857843,  -20.363176020671833",Homestead
"150.33021202831236,  -26.52164773713426",Hookswood
"151.8824313114397,  -25.074933554460635",Horse Camp
"145.99995168506965,  -17.525459034844456",Hudson
"151.72394221962566,  -26.184761958791064",Kawl Kawl
"153.42063598164782,  -27.350557853401742",Kooringal (Qld)
"150.76761394701546,  -23.168143588294264",Lammermoor
"151.17923999295598,  -28.737539831503245",Limevale
"145.44458098319345,  -14.682907356421248",Lizard
"153.18569254257986,  -27.469609808514434",Lota
"144.32032045172107,  -19.22574488852932",Lyndhurst (Qld)
"151.58597999994834,  -27.314698939688316",Malu
"146.06132364037242,  -17.72334686818528",McCutcheon
"144.05134956635357,  -9.916685173516983",Mer Island
"151.97074063754778,  -27.90815808306514",Missen Flat
"145.54300586932973,  -16.74292642715881",Mona Mona
"151.77503669943118,  -26.19314213220689",Mondure
"152.8729562768693,  -27.14908619708065",Moorina (Qld)
"152.77372724058785,  -26.249861859974548",Mothar Mountain
"151.94811231657553,  -27.510648347509676",Mount Kynoch
"152.74096328401194,  -27.068814249679914",Mount Mee
"152.77935297919132,  -27.373303019346668",Mount Nebo
"151.79212315766227,  -25.454195649272858",Mount Steadman
"151.68006472089903,  -27.272571787216368",Muldu
"144.4873488770068,  -22.519301796263747",Muttaburra
"151.72274800448446,  -25.020408457681864",New Moonta
"145.95012078962506,  -17.594908892098243",No 6 Branch
"142.61014085401166,  -27.785145054262955",Noccundra
"153.04413589904053,  -26.26392580508971",Noosa North Shore
"149.18282916331592,  -21.119812988805965",North Mackay
"153.30876707611702,  -27.77615047278246",Norwell
"145.9850508179143,  -17.514695216840373","[""O'Briens Hill""]"
"153.30295810489338,  -27.905686389364718",Oxenford
"148.82386493000573,  -21.880440573176234",Oxford
"153.31413267287974,  -27.81294783133461",Pimpama
"151.99590083803,  -27.552841578824907",Prince Henry Heights
"147.403886414681,  -19.98968566876466",Rangemore (Burdekin - Qld)
"153.0025949203579,  -27.452509170326866",Red Hill (Brisbane - Qld)
"148.4243150637331,  -24.9362854922318",Rewan
"152.95308823046523,  -27.58894793898886",Richlands (Qld)
"152.97470086594322,  -27.826679955711125",Riverbend
"150.5214447229551,  -23.251568527306894",Rockyview
"153.03285242927072,  -27.549818258375836",Salisbury (Qld)
"153.06366039639408,  -27.317563402238235",Sandgate (Qld)
"152.17002120169636,  -24.92629991288552",South Kolan
"153.27578665322804,  -28.187649184957877",Springbrook
"143.6782626805175,  -21.249265068335376",Stamford
"151.94950807291752,  -25.716008810027134",Stockhaven
"145.73045016461717,  -16.878663031529154",Stratford (Qld)
"150.53965889274457,  -25.74119633235718",Sujeewong
"152.78948730430102,  -25.359604027559847",Sunshine Acres
"153.10662220211978,  -26.404293256128984",Sunshine Beach
"152.81535299953865,  -25.415085370833626",Susan River
"152.67845004578155,  -25.780265159265394",Talegalla Weir
"153.0307273458887,  -26.719504241826648",Tanawha
"145.59391971500972,  -17.439101994379115",Tarzali
"149.11740205164088,  -21.176825154372473",Te Kowai
"152.63180068660424,  -27.62647986920234",Thagoona
"150.94687347641516,  -23.154817985302447",The Keppels
"152.92327836701912,  -26.64921840777979",Towen Mountain
"142.39325060063854,  -10.920939574700501",Umagico
"152.06968811143548,  -27.486139013818217",Upper Lockyer
"152.14168170082533,  -27.917840255928244",Upper Pilton
"153.4109853362407,  -28.08881679170086",Varsity Lakes
"152.63147589482145,  -26.95209561413904",Villeneuve
"152.67029357526303,  -27.51223537644739",Wanora
"150.47531471678124,  -23.123587336640767",Wattlebank
"152.00745483459423,  -24.566036416291517",Winfield
"152.01424969957554,  -27.535713814993027",Withcott
"152.28390867825212,  -27.60790509515309",Woodlands (Qld)
"152.41555337630217,  -24.895090341860843",Woongarra
"141.74764411676585,  -16.434717596954272",Yagoonya
"148.65158446587375,  -20.837926391132335",Yalboroo
"149.38277255369283,  -26.612540353101384",Yuleba
"140.17840691865342,  -36.94309323961942",Avenue Range
"138.5037508033853,  -35.542061354772066",Back Valley
"138.64519704530957,  -34.917660052668005",Beulah Park
"137.8806355356489,  -34.614846390616826",Black Point
"138.7675098443209,  -35.01518642206607",Bridgewater (SA)
"139.4096793449022,  -35.037779561722125",Burdett
"136.16745066508136,  -34.07765065392824",Butler (SA)
"138.54140808054325,  -34.96710551948789",Camden Park (SA)
"138.6569943513134,  -35.530096097675944",Chiton
"134.90393292404815,  -33.45608660933903",Colton
"136.94839290584045,  -32.283668114130926",Corunna Station
"140.74514156485247,  -34.20468103982515",Crescent
"135.64314094263855,  -34.27065532082451",Cummins
"138.99478728051977,  -32.81579702892343",Dawson (SA)
"138.80088751104753,  -34.92985698941223",Forest Range
"138.58100340485115,  -34.95141836422944",Forestville (SA)
"138.04842515605645,  -33.137721966061235",Germein Bay
"138.37012597366166,  -33.26753759339653",Gladstone (SA)
"138.5683333478052,  -34.96152604674163",Glandore
"138.7309406462697,  -34.77702242627092",Golden Grove
"138.59238158775352,  -34.95094208979896",Goodwood (SA)
"138.7107292556411,  -34.765391665396564",Greenwith
"140.1597709321078,  -34.87450848223897",Halidon
"138.50176980356014,  -34.92982305273531",Henley Beach South
"140.85482418354428,  -37.10999192166121",Joanna
"129.15565757627846,  -26.114668483600312",Kalka
"140.7825402620996,  -35.05261968812023",Karte
"139.43445823131833,  -35.15544350889963",Kepa
"140.90347542709333,  -37.048681556782405",Koppamurra
"135.8541984518741,  -34.42605317860794",Koppio
"137.74779253991932,  -30.994588283562653",Lake Torrens
"139.6137181520632,  -33.886124225651855",Lindley
"138.86682487655148,  -35.039980014194",Littlehampton
"140.04110389933854,  -34.488207615496314",Maggea
"138.8046936263338,  -33.99002484161818",Manoora (SA)
"140.12603156505327,  -34.60219097280297",Mantung
"138.75585638897536,  -34.92484226972903",Marble Hill
"138.6312492614789,  -35.53577845064194",McCracken
"138.14383168934074,  -33.438782305937345",Merriton
"138.5276742250631,  -35.12096783425096",Morphett Vale
"140.463620018416,  -37.54824361380692",Mount Burr
"140.66871531303318,  -37.0418565164599",Moyhall
"130.19189313203435,  -26.155347641661006",Murputja - Nyapari
"137.736465265115,  -35.8137277407776",Muston
"139.31114457870677,  -35.03130538699539",Mypolonga
"139.56694438003015,  -35.205571825025245",Naturi
"139.9674287117932,  -35.50907840745319",Netherton
"135.71104933782726,  -34.59856178554126",Pearlah
"140.75256320061527,  -38.056164456906195",Racecourse Bay
"138.70703891100248,  -34.811516252292705",Redwood Park
"138.61009998264953,  -34.541820848849085",Reeves Plains
"138.57726920880498,  -34.89055219382822",Renown Park
"137.97414732105986,  -33.21774305017788",Risdon Park South
"130.9624828730501,  -29.349802219504888",SA Remainder
"138.48359550603433,  -34.839100260000194",Semaphore
"138.90616024831266,  -34.49574908994207",Seppeltsfield
"138.63249711561312,  -34.97818346627219",Springfield (SA)
"139.61226878439336,  -34.65606604185037",Sunnydale
"135.83211620327464,  -34.66725089417607",Tiatukia
"135.60428685455636,  -33.85101586775209",Tooligie
"138.71120734510305,  -35.37863216625225",Tooperang
"138.55977076581033,  -34.919798116080955",Torrensville
"138.79119880406444,  -35.006920478236786",Verdun
"139.9679840537951,  -34.21622990532396",Waikerie
"140.29743190466718,  -34.39313949226283",Wappilka
"137.59967624496483,  -34.00924115000599",Warburto
"140.64619353031148,  -37.38944652068188",Wattle Range East
"140.2305713922742,  -34.179615188203954",Wigley Flat
"138.5689803530566,  -35.26551067126648",Willunga
"137.49155782548667,  -35.62706766911576",Wisanger
"138.9059742121004,  -34.95355514635029",Woodside (SA)
"138.52655419374622,  -34.88509253784947",Woodville West
"115.20045088215207,  -34.164984721408935",Alexandra Bridge
"115.8398987868468,  -31.854988621041958",Balga
"115.85256153094532,  -32.68527377320004",Blythewood
"115.28423057927517,  -33.66300395367265",Broadwater (WA)
"117.76762141965403,  -33.87781578303837",Broomehill East
"117.68349378452416,  -30.389711663436763",Cleary
"116.81966600282196,  -32.69250101860713",Codjatotine
"118.61213462530311,  -31.848009156604125",Cramphorne
"116.87756524291324,  -31.44843912084176",Cunjardine
"115.7455064614525,  -31.733737485189952",Currambine
"116.64393531383438,  -30.260008083404305",Dalwallinu
"117.35352467630548,  -32.053621028000705",Dangin
"115.8451658464442,  -31.812672162054703",Darch
"119.13602397873302,  -31.560067472184247",Dulyalbin
"114.9971806893542,  -28.88221299311085",Ellendale (WA)
"115.69975854045218,  -32.559253888027136",Erskine (WA)
"125.56892264647999,  -18.18332434890515",Fitzroy Crossing
"115.85114174377814,  -31.30787777670285",Granville (WA)
"116.71879239893401,  -32.41256151286924",Jelcobine
"115.74451928985988,  -31.790677350589803",Kallaroo
"114.64220826895834,  -28.79981027725041",Karloo
"121.4590841839056,  -27.829970686028908",Lake Darlot
"115.94431057347963,  -31.380432582512668",Lennard Brook
"116.90166627818492,  -31.8377115130846",Malebelling
"117.15807329693682,  -30.885825597941444",Manmanning
"118.3044486614346,  -31.480623196417902",Merredin
"116.37804213833395,  -30.41977476609237",Miling
"118.58526345321667,  -33.85698700806939",Mills Lake
"115.99076684882662,  -31.32015470013669",Moondah
"115.67275983430021,  -31.11412189057934",Moore River National Park
"116.2097898285834,  -31.859078889351746",Mount Helena
"121.96193844307756,  -33.76427705972192",Myrup
"118.39320274059521,  -31.58138294780726",Norpa
"115.8554703908835,  -31.946074880125988",Northbridge (WA)
"115.21839072219909,  -33.91032619207433",Osmington
"117.69379831502997,  -23.19278075942143",Paraburdoo
"116.90604332584643,  -35.036653980623214",Peaceful Bay
"115.76704958651358,  -31.999241268835185",Peppermint Grove
"115.81232072354946,  -32.219001722316726",Postans
"114.99490344232466,  -33.97842395772031",Prevelly
"117.17127733120039,  -20.75847752110417",Roebourne
"115.86597312826399,  -32.02381514615629",Salter Point
"116.25220877617113,  -31.960718795178835",Sawyers Valley
"115.86244666221063,  -31.9786934749691",South Perth
"116.63078280049635,  -31.733717727545617",Spencers Brook
"117.3430480057965,  -32.65170135845374",Stratherne
"128.72529754648374,  -24.36378724181744",Tjukurla
"116.5751755259868,  -33.62886510075868",Trigwell
"115.99754050617912,  -31.877639090932945",Viveash
"116.20235544786269,  -29.857165350795956",Waddy Forest
"117.71552393089272,  -34.16769820173414",Wansbrough
"117.8325172859276,  -20.85934239916053",Whim Creek
"145.89177927375212,  -41.07179242065495",Acton (Tas.)
"147.40361743924603,  -42.21969277728978",Antill Ponds
"145.6271663355646,  -40.945224146200914",Boat Harbour (Tas.)
"146.9938540886235,  -42.32169163144532",Bothwell
"146.25947296164378,  -42.249123206381014",Butlers Gorge
"147.03818501244135,  -43.109033280378924",Cradoc
"147.07689231379473,  -41.32069270034072",Dilston
"145.7755095173415,  -41.10445503870323",Elliott (Tas.)
"145.6617967653966,  -40.96872767798156",Flowerdale (Tas.)
"147.31368515880428,  -42.83777760787641",Lutana
"145.03853333138517,  -40.84947561057822",Mella
"147.14296391169466,  -42.81002517053229",Molesworth (Tas.)
"147.442429399347,  -42.85652054467001",Mount Rumney
"146.68209189653385,  -42.43860300943656",Ouse
"146.6331877700783,  -41.38224210427496",Parkham
"143.91124534490058,  -40.04839451459929",Pearshape
"147.0107418963453,  -42.56984471325282",Pelham (Tas.)
"147.2746819166464,  -42.67239940092522",Pontville
"145.8944576318572,  -41.09758109278501",Romaine
"147.70583253069535,  -43.03422915394676",Saltwater River
"147.34073895975735,  -42.91210614759297",Sandy Bay
"148.27245174319836,  -41.741728721367224",Seymour (Tas.)
"145.57079937480833,  -40.9231702684011",Sisters Beach
"147.43691135068602,  -43.031840513150065",South Arm (Tas.)
"146.98464543141262,  -43.41255269645351",Southport (Tas.)
"146.06372694009943,  -42.82573257267396",Southwest
"146.18555868560227,  -41.23806319919685",Spalford
"146.4489426010517,  -41.381093339543675",Sunnyside (Tas.)
"147.41703153204034,  -42.921796444611466",Tranmere (Tas.)
"146.65896794694532,  -42.71861956149261",Tyenna (Tas.)
"145.26862633535367,  -41.556825038671185",West Coast
"130.87318899204038,  -12.379524042863764",Alawa
"136.59831436154784,  -13.989322301198014",Anindilyakwa
"130.68318775741258,  -12.538168227873733",Belyuen
"130.58258241696672,  -13.474701848236387",Daly River
"130.9078036479256,  -12.475515372711836",East Arm
"131.61853486762377,  -23.350046227030667",Kunparrka
"131.18094575847323,  -12.811098005890472",Manton (NT)
"130.86253420845568,  -12.391835338064704",Millner
"134.07969479902525,  -15.225401247106538",Miniyeri
"129.51633293312113,  -14.244089206659616",Wadeye
"133.71990045649045,  -12.400059374412667",West Arnhem
"149.16347590859777,  -35.24947299892606",Hackett
"152.95639153062217,  -29.643928336614174",Alumy Creek
"150.2755951017969,  -30.112249791307764",Back Creek (Gwydir - NSW)
"150.68117193554608,  -34.84059404802607",Back Forest
"151.90420028193188,  -31.707281664867576",Baxters Ridge
"148.0955636420509,  -34.38897497740081",Berthong
"149.04387043315577,  -34.642137885192405",Blakney Creek
"147.43335912407278,  -35.06168845589524",Bomen
"151.264790641693,  -33.89401056977774",Bondi
"153.25567621268607,  -28.777880408063208",Booerie Creek
"152.09941831033225,  -28.891604001793077",Boonoo Boonoo
"149.6964744262248,  -35.20193232220046",Boro
"150.6611719121007,  -35.10895684692804",Bream Beach
"148.04936174124543,  -36.13394448802908",Bringenbrong
"153.0957684987106,  -30.15735222699405",Bucca (NSW)
"149.76063746733473,  -36.69279970341203",Buckajo
"150.6653229377371,  -34.67357465550919",Budderoo
"151.15191582783308,  -34.085471147458826",Bundeena
"150.40797880403596,  -34.508411667115475",Burradoo
"153.46310184952694,  -28.42116027023227",Burringbar
"152.54792246858926,  -31.52941174788471",Byabarra
"149.44288863482114,  -33.639833984434475",Caloola
"150.69441075332932,  -34.057530397147254",Camden
"149.42859624364965,  -35.57962232781079",Captains Flat
"149.42989402856367,  -36.8577769558595",Cathcart (NSW)
"153.00217379627543,  -28.69011475004399",Cedar Point
"151.21188679796745,  -32.56997657640686",Clydesdale (NSW)
"149.7116794803279,  -32.355686537819466",Cooks Gap
"149.77436933563783,  -32.44484060586085",Cooyal
"150.1290060279447,  -35.21056927360182",Corang
"150.31131668349153,  -29.107328496212617",Croppa Creek
"148.55697602023236,  -34.11869449928409",Crowther
"151.10299540110796,  -33.897214812823606",Croydon Park (NSW)
"148.6958347455183,  -32.93915689176526",Cumnock
"150.82173018955305,  -35.0158581704893",Currarong
"142.03441638935618,  -34.097467632258514",Dareton
"151.19310107330952,  -33.89063351103062",Darlington (Sydney - NSW)
"151.1985596552166,  -32.17536098774419",Davis Creek
"149.8978363617529,  -36.66452130300173",Doctor George Mountain
"150.78130598310656,  -32.62370971806569",Doyles Creek
"150.86220745653242,  -32.57981960962806",Dural (Singleton - NSW)
"150.60256888120787,  -34.52586911342178",East Kangaloon
"150.8863622406034,  -33.51980033017313",Ebenezer (NSW)
"150.80191970731678,  -34.032661133210375",Eschol Park
"148.82755397130651,  -32.838941753897004",Eurimbla
"146.7447404288865,  -35.123447316973895",Fargunyah
"151.66335271627597,  -33.01195725724759",Floraville
"152.33756664595572,  -31.242998577436857",Forbes River
"152.1356898172574,  -29.261334068667047",Forest Land
"153.33302610992462,  -29.402685182087772",Freeburn Island
"150.9880193697653,  -33.9089744719412",Georges Hall
"152.28886428748805,  -29.544659431828894",Gibraltar Range
"146.90373657370264,  -35.88466874057632",Glenellen
"150.09866621294208,  -33.59412547624607",Good Forest
"153.33076250753584,  -28.822012136151933",Goonellabah
"152.96569500874602,  -30.71990384611888",Gumma
"148.10368064245753,  -35.042792156348796",Gundagai
"147.99060670925877,  -30.507163422501815",Gungalman
"151.08689226433674,  -34.04902700531612",Gymea Bay
"151.68357793146137,  -32.41769216818312",Hanleys Creek
"149.4306535772753,  -35.40389681975572",Hoskinstown
"153.1755640153332,  -29.48423085306148",Ilarwill
"151.7472671054693,  -32.91364446152966",Islington
"150.13378280516974,  -30.304801686172507",Kaputar
"150.97279029666416,  -33.64348139575042",Kenthurst
"149.8461724639009,  -37.16115726243164",Kiah
"150.07548053894226,  -36.18372393351088",Kianga (NSW)
"151.3637783022126,  -33.52109290605766",Killcare
"151.3819127993161,  -33.48422783179161",Kincumber South
"153.54352617363637,  -28.79070680219868",Kinvara
"151.2338168821557,  -33.63829240646616",Ku-ring-gai Chase
"147.392934208434,  -35.16858311567594",Lake Albert (NSW)
"151.59174878553372,  -33.0951342482115",Lake Macquarie
"150.29264085452738,  -30.34215812575496",Lindesay
"150.74956290747338,  -33.701866189792824",Llandilo
"150.5501572865878,  -30.49239169701475",Longarm
"152.19910350382114,  -28.47916029476662",Lower Acacia Creek
"152.58911174571918,  -31.416951530566358",Lower Pappinbarra
"150.12942918969324,  -33.61631442312753",Lowther
"147.62443352167998,  -34.810797309303474",Marinna
"150.73089704777982,  -34.542608637312604",Marshall Mount
"149.77309126982905,  -33.666289218218196",Mayfield (Oberon - NSW)
"149.9180564680716,  -33.43811849989448",Meadow Flat
"150.8018613617058,  -33.691337852801595",Melonba
"143.76152776261162,  -34.9235182977112",Moolpa
"153.14952775179697,  -30.192248019531004",Moonee Beach
"144.71917580406515,  -35.346193322875614",Morago
"147.71432610887024,  -34.1766881425286",Morangarell
"150.1772793717433,  -35.831301539245565",Mossy Point
"147.9037601139249,  -35.21827195871473",Mount Adrah
"151.47282014449,  -32.36575932836892",Mount Rivers
"150.24732309526843,  -33.57632070482758",Mount Victoria
"153.17972391272286,  -28.617124232251157",Mountain Top
"150.64264723058167,  -33.82682791947125",Mulgoa
"149.18337387877435,  -33.08797086422425",Mullion Creek
"149.60881332067473,  -35.29818275593376",Mulloon
"152.79169502076303,  -28.87003891747639",Mummulgum
"150.74477635825846,  -34.05280181953389",Narellan Vale
"151.3324148926837,  -30.055795521332648",New Valley
"151.15325227412225,  -33.688283440065355",North Turramurra
"151.07452163454994,  -33.98241075544743",Oatley
"150.143172068837,  -32.8229065163694",Olinda (NSW)
"150.4163116275177,  -30.93503870572123",Orange Grove (NSW)
"153.44766960017833,  -28.771789806459793",Pearces Creek
"148.39759313617776,  -35.70592289814694",Pinbeyan
"153.00641020092746,  -30.927317348961363",Rainbow Reach
"152.94816545930703,  -29.06844429356147",Rappville
"150.65311550193115,  -34.15236925041232",Razorback
"150.63410836729898,  -30.49370394769581",Red Hill (Tamworth Regional - NSW)
"151.02615745456376,  -33.88318284680496",Regents Park (NSW)
"151.06570899184072,  -30.610018604904862",Retreat (NSW)
"150.77259670085098,  -33.935839910521615",Rossmore (NSW)
"153.4067691011305,  -28.863406082224596",Rous
"152.85718575366266,  -29.742862435648533",Rushforth
"152.8941623391638,  -29.621904408642088",Seelands
"150.92565535248647,  -32.0721864501257",Segenhoe
"153.04544017738448,  -30.03079812636785",Sherwood (Clarence Valley - NSW)
"151.67298391001424,  -30.238829348674038",South Guyra
"149.286568026465,  -29.92673657647632",Spring Plains
"149.1239823754129,  -36.532473702281585",Springfield (Snowy Monaro Regional - NSW)
"143.5453385060121,  -35.084032753310176",Stony Crossing
"151.56560428172116,  -33.14093569946814",Summerland Point
"153.2585752434515,  -29.20098789107312",Tabbimoble
"152.7264797128131,  -30.762031360902217",Taylors Arm
"147.11869619437516,  -35.270455418623605",The Rock
"151.54837253723588,  -33.259357481771204",Toukley
"152.67614507019053,  -31.035951083952057",Turners Flat
"153.16225492391817,  -28.33882181642554",Tyalgum Creek
"151.19789511634448,  -33.880125187066334",Ultimo
"152.44146131318135,  -31.278730335077043",Upper Pappinbarra
"152.96408435765213,  -30.3969370553039",Valery
"151.6709731558158,  -32.4544809316021",Wallaringa
"148.74290621868877,  -32.651726859171205",Walmer (NSW)
"152.9062542128019,  -30.772570192366953",Warrell Creek
"152.38128911302405,  -29.295814101967686",Washpool (Clarence Valley - NSW)
"148.80276310780465,  -32.29863534358241",Wongarbon
"150.40769906428397,  -35.35366271670239",Woodstock (Shoalhaven - NSW)
"150.64065635665648,  -35.076985983572186",Worrowing Heights
"151.4323098743023,  -33.27669619412992",Wyong
"149.58813946223648,  -37.2303950939503",Yambulla
"150.57628928654137,  -32.441247518591425",Yarrawa
"149.83535017381936,  -36.85817296836551",Yellow Pinch
"152.67637982655071,  -31.110694335659314",Yessabah
"150.80054460470652,  -28.958508218418597",Yetman
"145.53194997808873,  -38.42517956356956",Adams Estate
"143.78561547992643,  -36.666616997401356",Arnold West
"144.86257338393085,  -37.76155005473298",Avondale Heights
"145.08435884026227,  -37.79178995231457",Balwyn North
"144.64976809157505,  -37.13427555228443",Baynton (Vic.)
"143.95147803002968,  -36.331645077025314",Bears Lagoon
"143.59254643313906,  -38.634719666365726",Beech Forest
"147.21962332410314,  -36.799201749613005",Bogong
"145.7836427947381,  -36.679302666583126",Boho
"146.3165421850096,  -36.225261523662255",Boorhaman
"142.48183486437162,  -37.53357939595084",Bornes Hill
"145.80668478388677,  -36.32467724649265",Boxwood
"143.94038122405584,  -36.60391516243772",Bridgewater On Loddon
"148.2570351541139,  -37.262470888510336",Butchers Ridge
"142.79406665562365,  -36.822826571772964",Callawadda
"146.01326245292515,  -37.61050226444447",Cambarville
"145.3028795610441,  -38.22171782194918",Cannons Creek
"145.07556381957966,  -37.82385590747045",Canterbury (Vic.)
"144.96809003355784,  -37.80043915467706",Carlton (Vic.)
"143.6154877546726,  -38.338136638707134",Colac East
"141.70905028722007,  -37.94467263369924",Condah
"148.44850949493505,  -37.78796214266023",Corringle
"147.90164369336307,  -36.18316195736856",Corryong
"144.99307283109934,  -37.82904540721807",Cremorne (Vic.)
"142.2410597111287,  -36.15218841687006",Crymelon
"148.47238668018744,  -37.05855549707766",Deddick Valley
"141.52836390877707,  -37.810898600665794",Digby
"146.3360616291462,  -36.28540380133864",Dockers Plains
"145.14310522699898,  -37.59687941324688",Doreen
"144.3539930506839,  -38.13018770459977",Drumcondra
"146.10388365180788,  -38.5264401619901",Dumbalk
"143.58898193321355,  -36.88083215360598",Dunluce
"143.72983040356237,  -38.153205350237606",Eurack
"147.05930997408842,  -36.771238504134296",Freeburgh
"145.67916347603435,  -38.040459462234985",Garfield North
"146.60389123753646,  -38.6258159376997",Gelliondale
"147.5212982391475,  -36.90965812028779",Glen Valley
"142.99435021290466,  -38.151231504473486",Glenormiston North
"145.6191396016221,  -37.014804332927454",Gobur
"144.63637997742134,  -37.70028539548582",Grangefields
"144.9418742675849,  -36.826545532689664",Graytown
"146.28862925286128,  -36.53995264361155",Greta (Vic.)
"144.16552452769864,  -37.1880989699302",Guildford (Vic.)
"147.1694358462257,  -37.44486872277155",Hawkhurst
"145.06987531744764,  -37.75242938273327",Heidelberg
"144.63094938843238,  -37.35164669165988",Hesket
"144.8280654226704,  -37.7083401807528",Keilor
"145.05253168471796,  -37.79066824271681",Kew East
"143.40282737829028,  -36.64957992256568",Kooreh
"143.64738873569783,  -35.44394602410965",Lake Boga
"141.11853574994373,  -36.46853416141522",Lillimur
"145.52637202011218,  -36.263686580808205",Marionvale
"146.4272322175007,  -38.18589269601274",Maryvale (Vic.)
"144.1295181664968,  -36.1608710211425",Mologa
"144.77646356164624,  -37.4010975798313",Monegeetta
"145.05775880744093,  -37.94145456935497",Moorabbin
"145.099770804127,  -37.97743233250267",Moorabbin Airport
"147.22529279300934,  -36.751253096965335",Mount Beauty
"144.1545679720215,  -37.25964924488809",Mount Franklin
"144.59320093275377,  -37.39298981738016",Mount Macedon
"146.36443359452446,  -36.59468039852864",Moyhu
"144.27437541814706,  -36.56475076604456",Neilborough
"144.2856136480169,  -37.421430750156304",Newbury
"142.8004508462842,  -38.543501323698976",Nirranda South
"145.59416147004632,  -38.579506874481254",North Wonthaggi
"145.43488098434406,  -36.08615187542872",Numurkah
"146.2612924357288,  -36.15392741917053",Peechelba East
"143.8765890236811,  -38.44442517152912",Pennyroyal
"145.06945402974503,  -38.41494895420885",Point Leo
"144.92220725248077,  -37.83327251236851",Port Melbourne
"144.48418968983165,  -37.0091276999417",Redesdale
"144.66696868341364,  -36.55097610455287",Runnymede (Vic.)
"147.47451378831832,  -37.551930977646364",Ryans
"145.20868769327157,  -38.08210232588162",Sandhurst
"144.03343421877705,  -37.16942538432879",Sandon (Vic.)
"146.06984932219845,  -38.09955021368263",Shady Creek
"144.0647616356747,  -36.8456711339527",Shelbourne
"145.43333409118492,  -37.83680259633987",Silvan
"143.25328904729113,  -38.50475965051009",Simpson (Vic.)
"147.07073126100835,  -36.81884861555697",Smoko
"143.68196924418598,  -37.63916338862965",Smythesdale
"143.93616211883867,  -37.41295021691075",Springmount
"143.29792243694934,  -37.53705209116162",Stockyard Hill
"145.94833280886792,  -36.10237735440374",Telford
"145.0058603672855,  -37.75817078041118",Thornbury
"143.67883771552422,  -36.956511087986804",Timor West
"146.28482510253068,  -36.93312227366575",Tolmie
"143.10324337865055,  -36.76879685716427",Tottington
"145.2139166239613,  -36.24537684156373",Undera
"145.18787945812144,  -37.85437822121566",Vermont South
"145.9934423222527,  -38.85509581859278",Walkerville South
"146.31598834454866,  -36.36935927908956",Wangaratta (Vic.)
"145.69815692252644,  -37.72840450354382",Warburton (Vic.)
"145.244119093547,  -37.759581148524454",Warrandyte South
"144.30153439570654,  -36.7247299059755",White Hills (Vic.)
"144.8672463944811,  -37.24856795683677",Willowmavin
"146.73950230408767,  -38.46261981659459",Won Wron
"146.37239101910168,  -38.132712625186876",Yallourn North
"145.12704458828208,  -37.550292011413596",Yan Yean
"151.68479822722165,  -27.31353770351453",Acland
"146.76968800109356,  -19.299536492134045",Aitkenvale
"148.81408005754832,  -25.029835490435612",Arcadia Valley
"146.57988412642186,  -25.804498932992527",Augathella
"152.8435478238279,  -27.57189916471202",Barellan Point
"152.46086090223758,  -24.829714810225124",Bargara
"152.61187017974137,  -25.815181216483133",Bauple
"153.1113669373469,  -27.99955098039498",Biddaddaba
"142.21639800193105,  -9.26549509219805",Boigu Island
"153.0553080057494,  -27.296524373045383",Brighton (Qld)
"152.9037189115667,  -27.4837334238477",Brookfield (Qld)
"148.15034420680635,  -24.270815935250123",Cairdbeign
"153.10278800370642,  -26.802642237059832",Caloundra West
"145.42438525764297,  -16.524378481585728",Cassowary
"151.39184518119345,  -26.472392391676703",Chahpingah
"149.3935785863469,  -26.153627397717443",Clifford
"150.10327656230135,  -26.969731797154644",Condamine
"144.97944151480834,  -15.362808503926017",Cooktown
"149.51651219860528,  -23.838428514848744",Coomoo
"152.37619679556948,  -27.544688800586805",Crowley Vale
"151.73867453419322,  -26.348118301596102",Cushnie
"152.75957781080803,  -27.68885722951302",Deebing Heights
"151.25017016012117,  -25.71086594347014",Derri Derra
"151.906456675135,  -27.319709929822412",Douglas (Toowoomba - Qld)
"152.94392022791965,  -27.615302156200393",Ellen Grove
"146.08027832849797,  -17.56637677904117",Etty Bay
"151.72304712373148,  -24.134673857417212",Eurimbula
"141.4269692008142,  -25.137665612628037",Farrars Creek
"152.6214322029782,  -25.613076759289566",Ferney
"152.66376241237697,  -27.459560621177303",Fernvale (Qld)
"153.1721254993275,  -28.09976047831089",Flying Fox (Qld)
"146.2048671729763,  -18.610931908704544",Foresthome
"148.75645495470954,  -21.151848136407853",Gargett
"139.22170242368847,  -22.480935607509398",Georgina (Qld)
"151.71441231946193,  -28.486496162609942",Goldfields
"146.0299308828525,  -17.86591656598313",Granadilla
"151.78873536204867,  -28.65107316420971",Greenlands (Qld)
"149.03415890986656,  -21.183853745081127",Greenmount (Mackay - Qld)
"150.4604472222235,  -26.797076942954394",Greenswamp
"151.80116451960785,  -26.310235285636757",Greenview
"151.92638189219187,  -27.57814486702735",Harristown
"148.75320302960728,  -25.869102410766768",Highland Plains (Maranoa - Qld)
"149.05519567133516,  -21.273921444915803",Homebush (Qld)
"152.9108545144868,  -26.67995890257054",Hunchy
"153.14960097329725,  -28.12526854228436",Illinbah
"151.75866978307363,  -26.580888956454352",Inverlaw
"149.03672023878826,  -23.27514225512076",Jellinbah
"152.94264567147061,  -27.275149309602646",Joyner
"152.61223369895328,  -27.937747988425123",Kalbar
"152.65801366609662,  -27.98906548824766",Kents Pocket
"148.02185998397786,  -22.99505995555749",Khosh Bulduk
"152.9038049793558,  -26.577181107173597",Kiamba
"151.82596984792582,  -27.23440256392883",Kilbirnie
"152.8683435184496,  -26.25598149138385",Kin Kin
"151.78991536238448,  -27.45374830132852",Kings Siding
"152.41025203771406,  -27.641192339570956",Laidley
"152.55129735101454,  -27.654071810735324",Lanefield
"152.24900681315012,  -26.822066915191204",Linville
"152.40584911247961,  -27.453801841531085",Lockyer Waters
"145.566159312338,  -16.38567674339019",Low Isles
"145.99180957665195,  -17.831581412470012",Maadi
"145.74474377963372,  -16.858430399304854",Machans Beach
"151.67252777854742,  -27.055024248648216",Maclagan
"151.93306540275384,  -28.1355520507714",Massie
"151.8176369718026,  -26.460033355418936",Memerambi
"151.87902048147484,  -26.152634586982813",Merlwood
"152.32031799149888,  -24.87226440572485",Millbank (Qld)
"150.42458656092052,  -23.106783876424917",Milman
"147.93313965197925,  -23.971397818248306",Minerva
"152.02121460829184,  -26.314611143350543",Moffatdale
"152.6139285974496,  -26.30026673566226",Mooloo
"147.10604124602222,  -26.50998031583208",Morven (Qld)
"147.33437604964962,  -21.290614421223346",Mount Coolon
"146.05599952050383,  -24.67149033566483",Mount Enniskillen
"151.58001510963987,  -27.514379781780967",Mount Irving
"152.9461123346232,  -27.75218714079344",New Beith
"153.0609366438746,  -26.42318428682198",Noosaville
"149.36379513186205,  -28.381507019794142",North Talwood
"152.94201529006367,  -28.20769764708431",Oaky Creek
"153.05941310641285,  -26.745827535813117",Palmview
"153.0132420289549,  -27.463421830983496",Petrie Terrace
"146.78885261962716,  -19.281656485346453",Pimlico (Qld)
"148.7233655905495,  -20.85612844450685",Pindi Pindi
"150.7594404590444,  -23.575511200993393",Port Alma
"152.0528154057782,  -27.530921400369948",Postmans Ridge
"151.9544006905048,  -25.026463895342978",Redhill Farms
"152.90932353600533,  -27.5618197737886",Riverhills
"153.3931598449516,  -28.07076158713273",Robina
"149.14727524816635,  -21.239163229734523",Rosella
"146.82472328338758,  -19.355873521566423",Roseneath
"146.16358031855,  -18.480549919172944",Rungoo
"151.14008975624057,  -25.009103008158135",Selene
"152.8819923974538,  -27.705133734633623",Spring Mountain (Qld)
"144.51569382548652,  -17.932309715517896",Springfield (Mareeba - Qld)
"145.05747891608362,  -16.209154288457366",Spurgeon
"142.70267375128742,  -24.2501201185222",Stonehenge (Barcoo - Qld)
"150.4474029471344,  -23.615455053995678",Struck Oil
"152.03074980015958,  -28.710179609938386",Sugarloaf (Southern Downs - Qld)
"139.5119777181288,  -20.71160759270137",Sunset
"152.08650816018283,  -25.978332022302975",Tansey
"152.94144652029476,  -27.445781785531164",The Gap (Brisbane - Qld)
"151.6636793910447,  -25.676974515855605",The Limits
"152.66592652622506,  -25.551962853539393",Tinana
"142.31937383406614,  -9.921888209486553",Torres Strait
"153.28692165811975,  -27.587029938384877",Victoria Point (Qld)
"146.76464564277217,  -19.283494568104135",Vincent
"151.24657914025153,  -23.85833543311648",West Gladstone
"151.85229557722852,  -26.223362225264907",Wheatlands
"152.60774577712687,  -26.19836144951585",Widgee Crossing South
"151.85092204448483,  -25.55314366828492",Wilson Valley
"153.03016861045464,  -27.434396257414573",Windsor (Qld)
"152.2099072487996,  -28.27668204174093",Wiyarra
"150.5923281554837,  -28.375100078913825",Wondalli
"146.08995164864018,  -17.89906980868377",Wongaling Beach
"143.4427726879941,  -19.97456880221813",Woolgar
"153.04214243615078,  -27.419372814604262",Wooloowin
"146.07262584535545,  -27.277486285505695",Wyandra
"151.59786555521833,  -27.481045971516036",Yargullen
"138.70540057171544,  -34.871499231084805",Athelstone
"138.5755338913095,  -34.35922862718219",Barabba
"139.4416601707986,  -34.01811557155126",Beatty
"140.53949122790343,  -34.9415470517848",Billiatt
"138.875494525907,  -33.902599797473435",Black Springs (SA)
"138.46087925194067,  -33.842547258263",Blyth
"138.52281824380293,  -35.018375544100444",Brighton (SA)
"138.70573053209546,  -34.60955215299051",Buchfelde
"140.59840787734515,  -38.006270680728285",Cape Douglas
"138.7693892210749,  -34.967707602401575",Carey Gully
"134.66252340146,  -32.76504236054445",Chandada
"135.2514110775357,  -33.25540065376104",Cocata
"138.61223961776653,  -34.8872918725023",Collinswood
"138.62353357325193,  -35.04500794467975",Coromandel Valley
"138.5697530765385,  -32.10029518364711",Cradock
"138.56755601527894,  -34.88028575062864",Croydon Park (SA)
"138.66090707863285,  -34.70555995739203",Edinburgh North
"138.58985788848466,  -33.858944515273926",Emu Flat (SA)
"138.5322347884061,  -34.89952399317147",Findon
"140.0051092936202,  -32.14980257898709",Florina Station
"138.6163475919423,  -34.91378600240001",Hackney
"138.87858136330837,  -33.38674992194872",Hallett
"138.33554406622207,  -32.51169560113588",Hammond
"137.47267519192957,  -34.90223799484478",Hardwicke Bay
"138.64629666476552,  -34.9315484343477",Heathpool
"138.280691755334,  -33.31945106223783",Huddleston
"138.81436226370175,  -34.720961840983",Humbug Scrub
"134.07049541494857,  -29.76824497446608",Ingomar
"136.89667511140394,  -35.23742806168152",Inneston
"138.6932286042098,  -34.548985499341654",Kangaroo Flat (SA)
"138.9003092176824,  -34.32784806923899",Kapunda
"138.6456183126313,  -34.92374581080487",Kensington (SA)
"138.66318655046334,  -34.91972491494279",Kensington Gardens
"138.59286296964166,  -34.96382731906686",Kings Park (SA)
"138.5162900584723,  -34.53064316088141",Korunye
"138.51669954468457,  -33.931581526394396",Kybunga
"135.9081209435869,  -34.53011359669796",Louth Bay
"133.94158180848675,  -32.023463932608585",Maltee
"139.44196727582877,  -33.886802103400214",Maude (SA)
"140.88180329257892,  -34.75093863567097",Meribah
"139.2753682235491,  -35.10021322391941",Mobilong
"138.68221247729429,  -34.812063357154386",Modbury Heights
"138.8687596372683,  -35.02534906717201",Mount Barker Junction
"138.921489048595,  -35.06919729057337",Mount Barker Summit
"134.77803009961073,  -33.00879440254521",Mount Cooper (SA)
"138.63104245182444,  -35.41818222835611",Mount Jagged
"138.5315932558466,  -34.96591496865309",Novar Gardens
"140.68031991821528,  -34.217825150037434",Old Calperum
"140.3267716354243,  -34.1342401022889",Overland Corner
"138.58525302163952,  -34.89880113813493",Ovingham
"137.89311251001942,  -34.041618373786896",Paskeville
"138.9702665797923,  -34.63693558568958",Pewsey Vale
"140.8964950272993,  -36.28878561243209",Pine Hill
"140.09881009833552,  -34.11718562211837",Pooginook
"137.78351738503127,  -35.7913761527004",Sapphiretown
"134.25915240884763,  -33.07244864884813",Sceale Bay
"138.83435594789947,  -34.50847945305938",Shea-Oak Log
"138.63821226386432,  -33.84993188489055",Spring Farm (SA)
"138.72149031808814,  -35.00346931795277",Stirling (SA)
"138.10409934942268,  -33.05417972745216",Telowie
"138.93415271570606,  -33.14248723519062",Terowie
"138.61366695536552,  -34.98520332697359",Torrens Park
"138.93720740400786,  -34.00639137957392",Tothill Belt
"138.49765694375233,  -35.59951441017134",Waitpinga
"138.13885537356035,  -33.24688516609153",Warnertown
"140.03334110692762,  -33.65973338841439",Warnes
"138.5662502341719,  -34.71163412964322",Waterloo Corner
"137.62543409801629,  -34.23441918095971",Weetulta
"137.57437116007793,  -33.037346816773365",Whyalla
"138.35446833572763,  -32.69309144849965",Willowie
"138.31472895057044,  -34.409843417794086",Windsor (SA)
"134.23951726139316,  -32.93211328733784",Yanerbie
"135.27669985556966,  -32.936326378264184",Yaninee
"138.63160635668567,  -35.31303333847789",Yundi
"115.81727512255607,  -32.03264057176437",Alfred Cove
"115.13474873346199,  -34.30728750912492",Augusta
"115.8218868747823,  -32.14081502029903",Beeliar
"115.83389962817489,  -32.039539041621744",Booragoon
"121.64122429758973,  -30.803975264651918",Boorara
"115.65987274341457,  -30.245851367381576",Boothendarra
"115.47862391004352,  -31.191771052597083",Breton Bay
"119.13343749893414,  -30.89036021583012",Bullfinch
"118.0123103705086,  -31.266083630893412",Burran Rock
"116.80078185178631,  -20.59004845024004",Burrup
"115.56962963740719,  -33.55700704425158",Capel
"113.67124061402104,  -24.882215929034302",Carnarvon
"115.64027765960297,  -32.75555925980218",Clifton (WA)
"115.86966773816889,  -32.7566607036463",Coolup
"116.4747393179517,  -31.79533434056716",Copley (WA)
"117.88980474933429,  -32.33564986503314",Corrigin
"122.86557385420552,  -16.93246525099148",Dampier Peninsula
"116.11282172481589,  -34.34684127415541",Diamond Tree
"116.24659966529879,  -34.27671369519683",Dingup
"115.7817350765942,  -31.89639322175257",Doubleview
"120.94225078687427,  -33.69001923202068",East Munglinup
"121.31144867479523,  -19.55684720291282",Eighty Mile Beach
"115.79191746042335,  -31.939037886378877",Floreat
"122.94094288657362,  -31.980424030455186",Fraser Range
"116.38737416299632,  -30.712934133078242",Gabalong
"125.50090741472638,  -24.915435397476738",Gibson Desert South
"116.18746235762677,  -31.754402376962265",Gidgegannup
"114.99803716589773,  -33.9946866363043",Gnarabup
"116.04127096791403,  -33.85799365137304",Greenbushes
"115.29280113037845,  -30.258348005605217",Hill River (WA)
"116.06261754277067,  -31.85888128117648",Jane Brook
"115.93891233757488,  -31.881475507165494",Kiara
"117.50492974742843,  -35.05627468569682",Lowlands
"116.15860783315482,  -30.019175951813946",Marchagee
"115.18729639026827,  -29.270047656318894",Milo
"116.21475476409856,  -31.5789469615963",Moondyne
"114.67240982104661,  -28.73505231972528",Moresby (WA)
"115.21242460967592,  -30.518682165000623",Nambung
"115.8681835787942,  -33.67622274703779",Newlands (WA)
"115.89898466098086,  -31.873355143186625",Noranda
"114.92284306085321,  -28.666727366255078",Northern Gully
"115.91828397547874,  -32.26568623364496",Oldbury
"115.81691023539081,  -31.905108561570085",Osborne Park
"116.25056985012773,  -33.29227387059833",Palmer (WA)
"128.27942966220365,  -25.99145635421145",Papulankutja
"117.1055288626613,  -35.00675789855288",Parryville
"121.46411859400952,  -30.742803643962734",Piccadilly (WA)
"115.86886994242575,  -32.61648551549154",Pinjarra
"114.9339590404404,  -29.28635183101702",Port Denison
"118.32294621786362,  -27.23090819730844",Reedy
"115.2051907133486,  -33.993866300729245",Rosa Glen
"117.28374749029567,  -34.90564790962643",Scotsdale
"118.4456075084287,  -33.21285902029818",South Lake Grace
"115.73363422286457,  -31.706071539928473",Tamala Park
"115.83873133567636,  -32.22011020172373",The Spectacles
"115.55628231782246,  -33.65655826026885",Tutunup
"115.91021401814973,  -32.90737692906276",Wagerup
"120.4076278082205,  -30.617875322506833",Wallaroo (WA)
"117.19040137690544,  -33.41848851170076",Wedgecarrup
"118.02617229369547,  -30.766974613567037",Welbungin
"114.42316081216187,  -27.97783021753569",West Binnu
"116.14858194334842,  -33.720578602106876",Wilga West
"115.8053409238196,  -32.05195803180187",Willagee
"115.88781165270036,  -32.052472220158215",Willetton
"115.03608808081565,  -33.770992884511635",Wilyabrup
"115.50984933536978,  -28.83592039636052",Wongoondy
"115.41449946166009,  -33.67494306813503",Yalyalup
"116.30598582337369,  -33.13814517645154",Yourdamung Lake
"147.79362936209202,  -41.313460673097296",Alberton (Tas.)
,Corinna
"146.6551738159305,  -41.533758279089305",Deloraine
"146.56069729939614,  -41.42641798233729",Elizabeth Town
"145.98171113374363,  -41.084777880577164",Heybridge
"146.9240781937105,  -42.495022884518384",Hollow Tree
"146.41501899695587,  -41.251171765834165",Latrobe
"147.27400470426255,  -42.87118066528361",Lenah Valley
"146.66104288115645,  -41.903941524664965",Liawenee
"146.21411271540165,  -41.541561348672545",Liena
"147.44968373779975,  -41.15493392445931",Lietinna
"146.81484994259463,  -41.68747698209657",Liffey
"145.36883494560655,  -40.99665113601023",Mawbanna
"146.84256032692852,  -42.832104105503284",Moogara
"147.37267995411202,  -41.17321284894451",Nabowla
"147.45597594787165,  -42.90169232547758",Oakdowns
"145.67508440191978,  -41.06023617344432",Oldina
"147.86210802887678,  -42.56927739206345",Orford (Tas.)
"147.3104439144627,  -41.316893711061844",Patersonia
"147.51546418423146,  -42.29523495745549",Pawtella
"147.32650296204727,  -42.86602145946971",Queens Domain
"147.24896928173797,  -42.8217936644511",Rosetta
"147.71631499627594,  -41.6566647365393",Rossarden
"148.28558731793552,  -42.31689718637388",Schouten Island
"147.26054149332913,  -43.412820202336796",South Bruny
"147.95243378157068,  -41.006814227566025",South Mount Cameron
"147.74814337388898,  -42.33825315683397",Swanston
"145.1880789490636,  -41.91804012270004",Trial Harbour
"146.22860859849735,  -41.17009134253601",Turners Beach
"147.72774750227023,  -41.10791514678575",Warrentinna
"147.30425482008764,  -42.88360319249828",West Hobart
"130.98290848146763,  -12.515487063717783",Bellamack
"130.63306037992612,  -12.793781252653892",Bynoe
"134.83708907276,  -20.947349996046434",Davenport (NT)
"130.38252299156457,  -12.733813612007955",Dundee Beach
"133.93763180523618,  -25.26294038510464",Ghan
"132.56877096444367,  -25.117542451087417",Imanpa
"131.3296154389736,  -12.594426044134273",Middle Point
"129.8835050986193,  -14.334957837485103",Nganmarriyanga
"131.91313446565283,  -23.204786127577393",Papunya
"130.84533187662598,  -12.446783588381736",Stuart Park
"130.8595681602816,  -12.426389558713472",The Narrows (NT)
"133.3782732656519,  -11.6578658759689",Warruwi
"130.98836190335481,  -12.472520532160454",Yarrawonga (NT)
"149.14175376194328,  -35.252803836482855",Dickson
"149.0198072502243,  -35.19397472393771",Dunlop
"149.11934931140098,  -35.194403756007155",Palmerston (ACT)
"96.88438357049121,  -12.101128403875034",Home Island
"152.42682865624832,  -27.021825106526084",Scrub Creek
"142.36943462895815,  -10.850115831676138",Seisia
"146.69912725341848,  -19.27770500477266",Shaw
"148.4777984549816,  -20.472390161405507",Silver Creek
"151.96567970339248,  -26.740287994105707",South Nanango
"151.9195355878581,  -25.058852855388256",St Kilda (Qld)
"145.28980951159207,  -16.42458708928346",Syndicate
"144.24148917380188,  -21.584896765997566",Tangorin
"149.36827603165014,  -27.525489589320365",Teelba
"152.99998806432063,  -27.5273987200546",Tennyson (Qld)
"148.35196670323836,  -23.033570111056722",Tieri
"152.77365733715035,  -27.58665600520034",Tivoli
"152.76382590696326,  -26.324980067938817",Traveston
"152.0049828705851,  -27.6309232752301",Upper Flagstone
"139.1409605650164,  -16.76065228242924",West Wellesley Islands
"145.75300498623378,  -16.930391114317402",Westcourt
"151.58251426686368,  -25.9651444978247",Wigton
"152.7999159154372,  -26.12310960156929",Wilsons Pocket
"147.81082131857949,  -25.570467410671146",Womblebank
"153.10570098862047,  -27.62849428301587",Woodridge (Qld)
"150.8583092776631,  -26.774131788048837",Wychie
"153.21700838656858,  -27.75392528423814",Yatala
"138.55433334708727,  -34.90133034443665",Allenby Gardens
"138.91170245318776,  -34.27530889885709",Allendale North
"139.42389936476786,  -35.54554823080155",Ashville
"137.51833390917528,  -34.31978594161329",Balgowan (SA)
"138.18609926568553,  -34.08818393274998",Beaufort (SA)
"138.27270233383854,  -34.15449774929771",Bowmans
"138.54106778090636,  -33.34801813698649",Bundaleer Gardens
"138.74687355040524,  -33.37475512254592",Canowie
"138.49636161638952,  -35.13004416203096",Christie Downs
"140.13598902732735,  -37.32619890297105",Clay Wells
"134.65597230658096,  -33.06471264902983",Colley
"138.5036693228127,  -32.650397979342785",Coomooroo
"138.70955723442043,  -34.70064195970984",Craigmore
"138.87843607312564,  -34.472965070498994",Daveyston
"138.70479166390965,  -35.1049393525472",Dorset Vale
"137.48320364561135,  -33.0412703070487",Douglas Point
"138.62175545052213,  -34.942359585359696",Eastwood (SA)
"139.5575103358932,  -35.30798116120523",Elwomple
"138.72866407489846,  -34.799945799113935",Fairview Park
"139.10538795839642,  -33.145307423264505",Franklyn
"138.5136760167808,  -34.92733129229472",Fulham (SA)
"140.16071448566078,  -34.17611493993945",Good Hope Landing
"138.6960004414534,  -34.84537797123497",Hope Valley (SA)
"134.66874447011529,  -32.91439705313056",Inkster
"135.4734115428376,  -34.58990762445275",Kellidie Bay
"140.46090303993438,  -35.28357385828557",Lameroo
"139.02617071296555,  -34.47040582326036",Light Pass
"138.40024907651465,  -34.52827050244184",Lower Light
"140.66636444823524,  -35.979868988913374",McCallum
"137.43858397640068,  -35.68376777365273",Menzies (SA)
"140.7368417533038,  -37.396685278617724",Monbulla
"138.6156868302732,  -35.26294092562893",Montarra
"138.86342218073642,  -35.08093728753006",Mount Barker (SA)
"139.07676302482525,  -34.77071674174238",Mount Pleasant (SA)
"138.1224792556355,  -33.166149278416874",Napperby
"139.15100988317965,  -35.57894540783748",Narrung (SA)
"138.30464225179986,  -35.471244609087904",Normanville (SA)
"138.7356080461964,  -34.91834056514067",Norton Summit
"138.61078691146918,  -34.78252884021754",Parafield Gardens
"138.58862170976272,  -35.00271948399944",Pasadena
"134.4228304994691,  -32.72359673439353",Piednippie
"138.67244010014414,  -35.50732960946618",Port Elliot
"132.1351215926464,  -26.282464571966102",Pukatja
"138.55267002828728,  -35.0880701640588",Reynella East
"138.6917835906885,  -34.893858960116944",Rostrevor
"139.87852497027032,  -34.13774238885789",Sunlands
"138.71154287822867,  -34.79946166878673",Surrey Downs
"134.55511535484013,  -30.693495977947357",Tarcoola
"138.57099887641323,  -35.00970842025752",Tonsley
"140.46769164852054,  -34.66672412961766",Veitch
"134.75608585689736,  -33.19985886641656",Venus Bay (SA)
"138.73103959285595,  -34.83581296131283",Vista
"138.6373291931601,  -34.8400533953201",Walkley Heights
"139.38212820981738,  -35.393768334140134",Wellington East
"137.50816947354062,  -33.0271572409584",Whyalla Jenkins
"139.70433683074168,  -35.132451834036516",Wynarka
"136.7045389555293,  -33.275971776567104",Yalanda
"137.61828536065744,  -34.06463243958059",Yelta (SA)
"117.14099539442553,  -32.20779961370199",Bally Bally
"115.40909032378138,  -20.76324878826613",Barrow Island
"117.04939683300827,  -33.56518208533594",Beaufort River
"121.38529032744596,  -30.798658678202614",Binduli
"114.62222813199534,  -28.736791002982645",Bluff Point
"120.14849409369639,  -30.916256528127153",Boorabbin
"121.48471184334232,  -30.777441315802037",Boulder
"115.71496820473712,  -33.4824952463376",Boyanup
"117.48637184272513,  -33.879288452215995",Broomehill West
"116.64295026567788,  -29.632962628982074",Bunjil
"117.11013842592573,  -30.720207634769825",Cadoux
"125.13261634658646,  -32.05206993398659",Caiguna
"123.39459972637677,  -33.63881162610595",Cape Arid
"115.06624727393428,  -27.650859363197227",Coolcalalaya
"117.84009320399164,  -33.71104435044614",Coyrecup
"115.79922089041519,  -31.99654744258893",Dalkeith
"117.10102374737176,  -33.004203960761416",Dumberning
"114.94753203066286,  -28.575354233267245",Durawah
"118.91313329882986,  -30.65394828331207",Ennuin
"116.15591767683333,  -34.00840271058624",Glenlynn
"115.80540343281177,  -31.920167577642626",Herdsman
"116.08105274759008,  -34.30192260752375",Jardee
"116.9076548617924,  -29.956114291488404",Jibberding
"118.43363303211557,  -32.69368847462297",Jilakin
"115.59940175447842,  -29.64257657945572",Kadathinni
"118.03172476958125,  -34.898075198169714",Kalgan
"115.98105147826371,  -32.02719056024838",Kenwick
"114.87443116244087,  -28.71919533732726",Kojarena
"115.81457409311756,  -32.247870617446075",Kwinana Town Centre
"115.3920911565696,  -31.101120628126523",Ledge Point
"115.86717669909092,  -32.07578895189852",Leeming
"120.6391871433819,  -28.083811135980692",Leinster
"118.15212090200347,  -34.143106844819",Magitup
"116.04557308011445,  -32.07552765507969",Martin
"116.56381286687669,  -29.85615933509192",Maya
"116.90283982854265,  -31.654321850518635",Meenaar
"116.02006470310253,  -34.65606201667241",Meerup
"116.0106751022105,  -31.889078533274965",Midland
"117.8725860101497,  -34.90677389152205",Millbrook (WA)
"115.50961727945723,  -30.85882110069782",Mimegarra
"115.2618521171679,  -34.123577830522656",Nillup
"117.50375026729198,  -33.01078605937112",Nomans Lake
"118.77038529158611,  -32.846408759162045",North Burngup
"115.18973014037407,  -33.73227228734048",North Jindong
"115.85296112073047,  -31.926953889861817",North Perth
"115.60511566856532,  -28.162492277749045",Nunierra
"118.17199639186451,  -33.55965875818307",Nyabing
"117.64929029081206,  -31.974151992077676",Pantapin
"116.42380100741933,  -34.27316386721517",Perup
"116.82792298571579,  -31.787252632203078",Quellington
"115.48402625850352,  -34.263262177362755",Scott River East
"117.86209265123857,  -31.965781640950528",Shackleton
"113.87014233251358,  -25.90157322812583",Shark Bay
"116.17873875724398,  -32.523102291737025",Solus
"121.45469548562495,  -30.769646822285704",Somerville (WA)
"113.6567524909948,  -24.89544580796217",South Carnarvon
"117.66936570853595,  -31.26694798294394",South Yelbeni
"116.83174740053329,  -31.55775659269228",Southern Brook
"118.04494529886799,  -34.39763801908295",Stirling Range National Park
"115.82420558305283,  -31.948824565220864",Subiaco
"114.92676563020949,  -28.74560998458566",Tibradden
"118.40455733221691,  -34.04223843985596",Toompup
"117.31452501971823,  -32.7041993311771",Townsendale
"114.64866551582949,  -28.78132079213815",Utakarra
"126.71138057724119,  -26.12942683768743",Warburton (WA)
"117.86518570178936,  -34.97175424154971",Warrenup
"114.6321421337637,  -28.75223882699812",Webberton
"121.451088197893,  -30.74772634619557",West Lamington
"115.61556769655516,  -31.40494476787798",Wilbinga
"114.8080692663723,  -26.172149037750636",Woodleigh (WA)
"147.24880580981858,  -42.77493041950341",Austins Ferry
"148.25284218995083,  -41.90111734137151",Bicheno
"148.2798438716905,  -41.24941072572962",Binalong Bay
"145.9073847769575,  -41.07230366890178",Brooklyn (Tas.)
"145.90596366486662,  -41.05399733688676",Burnie
"148.1412153959938,  -41.55690339306842",Cornwall (Tas.)
"145.85006690265442,  -41.06093050030534",East Cam
"147.23934578520647,  -43.201421328338704",Flowerpot
"146.98498221495507,  -43.08199514504195",Franklin (Tas.)
"146.85094715752356,  -42.259715129273566",Hermitage
"147.29889668869055,  -43.020475467454496",Howden
"147.295856671966,  -43.001603400712426",Huntingfield
"147.79154622478333,  -42.76341145589935",Kellevie
"147.22939152177528,  -43.127815287577704",Kettering
"147.3270019677136,  -41.23464365532621",Lisle
"147.24277727367328,  -43.366092485329325",Lunawanna
"146.97154023638532,  -41.58024381493579",Oaks
"147.32434752802877,  -42.286806254470235",Oatlands (Tas.)
"145.88204360660018,  -41.05481595458157",Park Grove
"145.56204452243205,  -41.298835373218004",Parrawe
"145.22588197244008,  -41.479921613645445",Savage River
"146.53172229006864,  -41.15696286695681",Shearwater
"147.0163504122616,  -41.01991849421213",Stony Head
"143.92094167074558,  -40.10735016418132",Surprise Bay
"147.34202488903452,  -42.684413397063004",Tea Tree
"147.7585121933103,  -41.127410231720255",Telita
"147.09154672831096,  -41.49637026859281",Travellers Rest
"147.15980831559554,  -43.27324359688149",Verona Sands
"146.70875785913992,  -42.25572588720277",Victoria Valley (Tas.)
"147.63941305040652,  -40.939696551608215",Waterhouse
"147.01645230033517,  -41.31070374661759",Windermere (Tas.)
"147.21405091720376,  -43.158017722506244",Woodbridge (Tas.)
"134.9278077235629,  -22.99135341304398",Atitjere
"130.91877380174367,  -12.353558209122719",Buffalo Creek
"131.5841311831345,  -14.40994781240958",Claravale
"130.9697418001759,  -12.8192896184634",Darwin River
"133.54374270932206,  -17.555192678063488",Elliott (NT)
"133.04922394374404,  -12.346865144858834",Gunbalanya
"132.28894709919604,  -14.463907637850166",Katherine East
"131.75196837285077,  -13.02954163033225",Mount Bundey
"131.8322441755106,  -12.540192337626596",Point Stuart
"136.92246115497412,  -25.0115199267955",Simpson (NT)
"132.2983481945889,  -14.491121023327613",Uralla (NT)
"130.74758577443512,  -12.44001038702886",Wagait Beach
"149.10275132600432,  -35.48137634097144",ACT Remainder - Tuggeranong
"149.09964840652827,  -35.47188434182258",Banks
"149.14298842904154,  -35.155983700709974",Bonner
"149.15815569644104,  -35.29126182648685",Campbell
"149.1244976046799,  -35.308131849231565",Capital Hill
"149.06897831008416,  -35.21185239160757",Evatt
"149.12480944975349,  -35.31707323205351",Forrest (ACT)
"149.0940287637332,  -35.42804007542862",Isabella Plains
"149.07404985383195,  -35.34062335967534",Lyons (ACT)
"149.0785999201494,  -35.409458589949274",Oxley (ACT)
"149.06344181230867,  -35.510849022428204",Tharwa
"149.34764617728436,  -33.91028901147545",Abercrombie River
"141.68989079376445,  -33.7139919126446",Anabranch South
"148.43684828418995,  -35.29423323637244",Argalong
"151.66779532889566,  -30.50313481573936",Armidale
"151.18598610445255,  -33.81088912444492",Artarmon
"151.11634471101746,  -33.687103837386296",Asquith
"150.86157547186335,  -33.97739554595969",Bardia
"150.9818829088872,  -33.756435904768935",Baulkham Hills
"151.2880812526944,  -32.669368021998935",Belford
"150.29399578788914,  -33.49737918325335",Bell (NSW)
"149.6866703262855,  -35.678998478749406",Berlang
"153.51483066698884,  -28.506566662618937",Billinudgel
"150.11865037840582,  -35.842046923797355",Bimbimbie
"152.6386693247467,  -31.58585309078576",Black Creek (Port Macquarie-Hastings - NSW)
"152.86377856275269,  -30.634120737818176",Bowraville
"152.4723145455721,  -31.85058105133423",Brimbin
"149.97829877643665,  -32.99097121473715",Brogans Creek
"149.69406153715698,  -36.47667466834468",Brogo
"148.22913464070078,  -35.153376829798674",Brungle
"148.29185900731756,  -35.15662375887379",Brungle Creek
"151.53234911135,  -32.82498237297914",Buchanan (NSW)
"149.13471552544723,  -31.16066428042746",Bugaldie
"150.9017105576908,  -34.33030962646376",Bulli
"148.41415300735915,  -33.87836941695149",Bumbaldry
"150.97508974688893,  -31.16432845055641",Calala
"144.5649548002877,  -35.42502105062373",Calimo
"150.72679065817525,  -33.74967280788756",Cambridge Park
"151.21417146028554,  -33.822164350333956",Cammeray
"147.3920108008461,  -35.074819771290734",Cartwrights Hill
"151.0785738368602,  -33.757064796352005",Cheltenham (NSW)
"151.29065806041524,  -33.730336788500736",Collaroy Plateau
"152.46740588453895,  -31.598218777839005",Comboyne
"151.01098341348248,  -33.92453686301488",Condell Park
"152.4385871189094,  -32.26816012810502",Coomba Bay
"152.95413914494418,  -29.019743896266622",Coombell
"149.64652909192134,  -32.27239582768888",Cope
"151.97175348696373,  -28.52427073285322",Cottonvale (NSW)
"147.9883407820218,  -35.70239951377942",Courabyra
"148.49268686873165,  -35.10643862307627",Couragago
"150.77458435784874,  -34.04556599849723",Currans Hill
"150.0389476082074,  -36.48150971878871",Cuttagee
"151.2194781718632,  -33.877881697246586",Darlinghurst
"151.07701623249213,  -33.800839122434304",Denistone West
"152.77002043823904,  -31.701122351171534",Diamond Head
"153.14428990910622,  -29.992921915425885",Dirty Creek
"149.01305981719182,  -30.203449700653778",Drildool
"151.90884421394412,  -29.576446972925446",Dundee
"151.637302681809,  -30.415135608303505",Duval
"151.22752524085593,  -33.87138116390697",Elizabeth Bay
"150.64968299403972,  -33.73305302022702",Emu Heights (NSW)
"146.39796392718446,  -32.62434688246337",Eremerang
"146.36407954045845,  -33.01720494693165",Euabalong
"152.80053995467463,  -31.07698460732186",Euroka
"151.5777127953675,  -32.9820637682041",Fassifern (NSW)
"151.8141317860528,  -32.44835101538406",Flat Tops
"151.95965822504317,  -32.076238665054454",Forbesdale
"152.87299993388476,  -29.47413629350809",Fortis Creek
"150.79930921560842,  -33.56253495882215",Freemans Reach
"151.65007882470408,  -29.815530326205586",Furracabad
"150.96134792914395,  -31.42223452094999",Garoo
"153.16066391208713,  -28.644017485302122",Georgica
"151.1231667688609,  -29.882488741713715",Gilgai
"147.1459152272941,  -33.66470801661355",Girral
"150.68056315309516,  -33.791844240469395",Glenmore Park
"149.04596275459588,  -32.253773738365346",Gollan
"148.50409765486353,  -35.442183717805904",Goobarragandra
"150.3236124433013,  -29.630860976123248",Gravesend
"151.28255726440915,  -32.88883303759538",Greta Main
"150.88985422284688,  -33.90819563112869",Heckenberg
"152.60112559482076,  -30.87826638135103",Hickeys Creek
"151.04263140366885,  -29.934148332064886",Howell
"150.85694016708644,  -33.92947374698678",Hoxton Park
"151.96945795518832,  -28.9404057144578",Jennings
"152.13669632829772,  -30.668896727073637",Jeogla
"150.85237613587412,  -32.46902184482049",Jerrys Plains
"149.97056258584402,  -32.85981138278498",Kandos
"149.77447231242786,  -36.74648615524729",Kanoona
"146.7723511219068,  -33.050849474046544",Kiacatoo
"150.84214268907485,  -34.70123340774988",Kiama Heights
"152.56106748404824,  -30.567974473703813",Killiekrankie
"152.7566182856084,  -31.49228526261027",King Creek
"152.0893002259905,  -29.80055831420405",Kingsgate
"152.93160094143946,  -29.618122790822625",Koolkhan
"153.22696465879363,  -28.696970083121123",Koonorigan
"151.6975877598916,  -32.943401218383514",Kotara
"151.16142834899287,  -33.80584304162977",Lane Cove North
"150.63571607531705,  -33.774194604326766",Lapstone
"152.79517096520533,  -31.64438012270773",Laurieton
"148.00650182074116,  -29.317291606420756",Lightning Ridge
"149.5512641217706,  -33.3968967108425",Llanarth (NSW)
"152.48964733312073,  -31.43903884217515",Long Flat (NSW)
"159.07686887970144,  -31.552954713764482",Lord Howe Island
"150.39601790641984,  -34.60056605102405",Manchester Square
"149.01566751948556,  -34.84964180673616",Manton (NSW)
"150.51711199765847,  -35.25455692931856",Manyana
"144.8624779585673,  -35.809179499331975",Mathoura
"151.57446935565417,  -29.90460955625774",Maybole
"150.22154073248672,  -33.721708033081505",Megalong Valley
"147.11226053843447,  -34.602364938341736",Methul
"149.20189566212005,  -35.74268978150544",Michelago
"149.19189384616863,  -36.235744338384315",Middle Flat
"150.9501608366147,  -32.71589028876967",Milbrodale
"149.9205236789855,  -36.877319565416826",Mirador
"151.70827138498012,  -29.079102598029948",Mole River
"142.65315181034518,  -33.02883480444418",Moorara
"151.22155803530325,  -33.89525561388854",Moore Park
"150.90325136099554,  -33.90297937025714",Mount Pritchard
"149.48367886204517,  -33.295608821961395",Mount Rankin
"149.6688809475224,  -32.68670662352751",Mullamuddy
"148.28048512219985,  -35.290699034283044",Mundongo
"148.72867738941414,  -35.02245099359802",Narrangullen
"149.19451070351687,  -33.73830394565593",Neville
"151.5552848373006,  -33.27092412342169",Noraville
"151.27835137851585,  -33.8844808164316",North Bondi
"151.0121691122864,  -33.79533460851246",North Parramatta
"153.26488111255594,  -28.2631374972273",Numinbah
"149.84931238064195,  -33.69268089135244",Oberon
"146.73390375684482,  -35.35616720772086",Osborne (NSW)
"149.56770091769957,  -35.42901099814251",Palerang
"150.88234233197048,  -31.970519523107143",Parkville (NSW)
"148.0815596332987,  -31.54073494863413",Pigeonbah
"150.3990286175445,  -31.525178762781653",Pine Ridge
"147.94206230142828,  -33.86139788887758",Piney Range
"151.96180708660629,  -29.89213108078375",Pinkett
"151.22547737484132,  -33.86710049297904",Potts Point
"150.91640869480065,  -33.798835870884126",Prospect (NSW)
"147.840288253684,  -31.697178801364053",Ravenswood (NSW)
"149.31186161572268,  -37.16302070228848",Rockton
"151.2048846668169,  -33.91886237107519",Rosebery (NSW)
"150.79283396206276,  -34.10400742627814",Rosemeadow
"149.63735270147933,  -34.49718473050213",Roslyn
"147.32921895140382,  -35.204743174067836",Rowan
"150.86044327108624,  -32.05419090149307",Scone
"151.01060130204374,  -33.8875589161602",Sefton
"152.7843682420356,  -28.674162189565116",Sextonville
"151.16179795780056,  -32.54026142952086",Singleton Heights
"151.18316090450193,  -32.69658217005801",Singleton Military Area
"148.42677170798538,  -36.392892059237795",Smiggin Holes
"151.12336762192922,  -33.44202740183971",Spencer
"146.84988200268134,  -36.052773101263604",Splitters Creek
"147.7437915567747,  -34.4593206778755",Springdale (NSW)
"151.3222904220198,  -32.03032561771132",Stewarts Brook
"151.58048340592393,  -32.86336799273392",Stockrington
"153.1621089851827,  -28.583133075494494",Stony Chute
"151.8123337822393,  -32.379312167354115",Stroud Hill
"150.56194422880336,  -35.14249125955381",Sussex Inlet
"152.51043212070851,  -32.05533762479974",Tallwoods Village
"151.42632007323294,  -33.44340486691046",Terrigal
"152.90257798609244,  -30.644468853587146",Tewinga
"152.66040902099695,  -28.40557394279579",The Glen (NSW)
"151.22886547817973,  -29.970159221818577",Tingha
"151.58077507710962,  -32.47304691508892",Torryburn (Dungog - NSW)
"149.82801953448472,  -34.694373569228155",Towrang
"151.142046496637,  -33.93045997653738",Turrella
"152.82798688757927,  -30.744452903616608",Utungun
"151.46495877361804,  -33.27228033961701",Wadalba
"153.1344525853817,  -28.562433524291183",Wadeville
"145.13731453128906,  -35.6049224704733",Warragoon
"147.54814107583528,  -33.33008133274047",Warroo (NSW)
"150.97294560727698,  -34.119266912326616",Waterfall
"151.47905424360613,  -32.691617525308274",Windella
"149.54506723472267,  -33.40531584277206",Windradyne
"152.9259861862236,  -30.670946252442715",Wirrimbi
"150.45178786989425,  -32.94947587598509",Wollemi
"151.21947683563346,  -33.87110163948644",Woolloomooloo
"146.89087366868807,  -35.36406205687979",Wrathall
"152.9254074188632,  -28.532872873414284",Wyneden
"150.06586788655784,  -31.463441857102044",Yannergee
"151.03124689480893,  -34.05480892722681",Yarrawarrah
"151.7479268085276,  -29.65176979999552",Yarrowford
"143.41480840705552,  -37.18793913813407",Amphitheatre
"141.9709332539272,  -36.26745945145437",Antwerp
"146.20905393432608,  -36.86887315293998",Archerton
"145.56401145900008,  -38.5482061673333",Archies Creek
"144.9597390673641,  -38.363116831296",Arthurs Seat
"145.78651003461206,  -38.24237465346207",Athlone
"144.45171061923347,  -38.050934059768835",Avalon
"143.88696843120223,  -37.56479185131598",Ballarat East
"145.65952201984865,  -36.70729557503302",Balmattum
"142.81433131444277,  -36.60277872930974",Banyena
"143.89910639510762,  -36.95869144534176",Baringhup West
"144.102410316445,  -37.47482791907507",Barkstead
"143.67153468498282,  -36.001159859032235",Barraport
"146.21766487163725,  -37.010164186779924",Barwite
"143.79449419315415,  -36.917870727611",Betley
"145.76265670017062,  -37.763694518456994",Big Pats Creek
"144.77226496995104,  -38.36866245837084",Blairgowrie
"146.27113912944546,  -38.38246275592467",Boolarra
"144.99596129795313,  -37.90849788539097",Brighton (Vic.)
"146.57876339917487,  -38.323084094292106",Callignee
"142.65859355827192,  -37.847947802770484",Chatsworth (Vic.)
"145.3136846824941,  -37.65823710044126",Christmas Hills (Vic.)
"144.5283146882722,  -38.17871075426131",Curlewis (Vic.)
"147.29395176816266,  -37.33431084658536",Dargo
"145.06596601724883,  -37.81162202170615",Deepdene (Vic.)
"146.8631594628177,  -38.03217990871105",Denison
"147.27781745288064,  -37.030323241913585",Dinner Plain
"145.48689385048047,  -36.16926882078944",Drumanure
"144.4075972501602,  -36.43590068541351",Drummartin
"147.23948787934103,  -38.17550740923802",Dutson
"141.3050820663556,  -37.06693259928122",Edenhope
"145.36882946695218,  -38.4165670344068",Elizabeth Island
"147.32173524718766,  -37.86169419234419",Fernbank
"144.89034659883458,  -37.313506930870425",Forbes (Vic.)
"144.716786913612,  -37.68873377001356",Fraser Rise
"145.00906588594,  -37.89809283078647",Gardenvale
"141.8783763486277,  -36.38178198117897",Gerang Gerung
"146.6351354873348,  -38.12767943360548",Glengarry (Vic.)
"143.3314749881383,  -37.14617996194627",Glenpatrick
"147.5467264386222,  -37.98311625965646",Goon Nure
"144.54632455942954,  -37.65451996784725",Harkness
"142.90334721521998,  -38.5672774040108",Heytesbury Lower
"145.60594451735383,  -36.25076748941697",Katandra
"144.82840087132348,  -37.73832692950106",Kealba
"143.25355347821593,  -38.60060730113457",Kennedys Creek
"143.70286581489313,  -36.35748890154162",Korong Vale
"143.5632942921477,  -36.510540570043844",Kurraca West
"145.67430266752734,  -38.54813150019008",Lance Creek
"147.42260821803208,  -37.86834629458968",Lindenow South
"144.35622262460524,  -38.19722767338344",Marshall
"145.79516193855673,  -37.51795310835147",Marysville
"144.61183972868164,  -37.67564089000873",Melton (Vic.)
"146.6655446695168,  -36.601517179907155",Merriang South
"143.24150243756304,  -37.398896694498575",Middle Creek
"145.92715323412955,  -38.66468845063478",Middle Tarwin
"142.8949794429843,  -36.866373245429514",Morrl Morrl
"143.8129181794843,  -37.20110594521591",Mount Cameron
"144.15362705287046,  -37.05206868736817",Muckleford
"145.72381234857667,  -36.422628034731986",Nalinga
"146.8494097491789,  -38.08412052376012",Nambrok
"145.5359376959573,  -37.87749865077228",Nangana
"146.84588326680623,  -37.57859962309471",Nap Nap Marra
"142.63568976537448,  -37.7529007078831",Narrapumelap South
"141.38928690580443,  -34.21288829686274",Neds Corner
"144.39531166010954,  -38.163112397489314",Newcomb
"142.93169772888214,  -38.19338804218727",Noorat
"146.3494919297425,  -36.09984619709641",Norong
"144.3733568413486,  -38.09505857101525",North Shore (Vic.)
"148.02017953665174,  -37.69329117761801",Nowa Nowa
"142.04615518269716,  -36.94848494167722",Nurrabiel
"146.66076390410072,  -38.69044449850678",Port Albert
"145.59373234690784,  -36.65286553214606",Riggs Creek
"146.8278719751156,  -38.181954119707584",Rosedale (Vic.)
"145.24379995133373,  -37.92426613872031",Rowville
"145.8466145921143,  -37.39395638779776",Rubicon
"148.36340490093679,  -37.76588083582627",Simpsons Creek
"145.14791300455508,  -37.97087400252492",Springvale South
"145.0021734394345,  -37.8668048282175",St Kilda East
"146.77487020911497,  -36.39055604520266",Stanley (Vic.)
"147.11058907262336,  -37.95631663111343",Stratford (Vic.)
"147.37098547768971,  -37.556550201391715",Tabberabbera
"146.71004826606432,  -37.43346193265268",Tamboritha
"145.29331728532586,  -36.43962236710897",Tatura East
"144.59080133831046,  -37.60558222585767",Toolern Vale
"141.93559271329258,  -35.13686271788618",Torrita
"148.008176337222,  -36.211033376241254",Towong Upper
"146.208844855504,  -36.67441023806872",Upper Ryans Creek
"143.20452484596305,  -37.7896283098074",Vite Vite North
"147.73155250852517,  -35.99249146163895",Walwa
"146.2267902046143,  -36.32527245508507",Wangandary
"143.0777010981901,  -36.96394342805781",Wattle Creek
"142.22542450511347,  -36.053178503115724",Willenabrina
"146.89209081754836,  -38.52913759060272",Woodside (Vic.)
"145.36890565331905,  -37.687245657095794",Yering
"151.95138224389183,  -28.195603705437392",Allan
"152.86145009490028,  -27.53795570821276",Anstead
"150.76389284249,  -23.07467354062548",Bangalee (Qld)
"153.1936881421685,  -27.715111999410784",Beenleigh
"153.3914293592147,  -27.929836004742928",Biggera Waters
"145.94926840624726,  -18.117654507985932",Bilyana
"152.70640557463653,  -27.577886600484106",Blacksoil
"151.3264653845277,  -29.00520037584053",Bonshaw (Qld)
"152.7034927922061,  -27.49673687801322",Borallon
"151.5071103731045,  -24.24126677724638",Bororen
"151.45951178293217,  -27.283026789563973",Bowenville
"151.34875945803927,  -27.616136924257503",Branchview
"151.37674178647342,  -26.232205942867726",Brigooda
"148.77436280237413,  -26.652688027031413",Bungil (Qld)
"142.81950779080955,  -10.146036349815892",Burrar Islet
"153.0481631543932,  -27.622629616249387",Calamvale
"153.12762421620505,  -26.798486516002743",Caloundra
"152.87883968775049,  -27.40006007557865",Camp Mountain
"152.9934973719389,  -26.70059875039468",Chevallum
"152.74202856897423,  -26.355701816205656",Coles Creek
"150.96295340907875,  -27.936224361740482",Condamine Farms
"153.3709600049446,  -27.910696279488405",Coombabah
"153.06180635497162,  -26.79234099791005",Corbould Park
"151.11850777976707,  -25.20298340162353",Cynthia
"151.6801562619624,  -27.37102619814071",Devon Park (Qld)
"141.11499312588532,  -24.013390545711992",Diamantina Lakes
"152.89598443869554,  -26.642134709618656",Dulong
"148.03820007471202,  -27.14255136665755",Dunkeld (Qld)
"141.74355061490732,  -19.080698269238415",East Creek
"145.84527752025505,  -17.595725872036287",East Palmerston
"153.44919863752432,  -28.13581833617417",Elanora
"146.68516075432234,  -22.22534860433771",Elgin (Qld)
"150.4221057159942,  -23.221825227667548",Etna Creek
"148.42252493290184,  -20.922736695475518",Eungella Hinterland
"148.82754576473567,  -26.436277642909754",Euthulla
"151.49604010193,  -24.103046763156115",Foreshores
"143.68060940546636,  -18.743223633233455",Forsayth
"152.98189965795768,  -27.41637486305457",Gaythorne
"142.73918178498454,  -18.270158977313745",Gilbert River
"153.30466783421957,  -28.029660579490987",Gilston
"151.06149564911672,  -25.038635834627954",Glenleigh
"152.07926365273337,  -26.1929898729167",Goomeri
"151.82170492809803,  -27.51522381056645",Gowrie Mountain
"152.75367182681086,  -26.1704983777252",Greens Creek (Qld)
"149.76858339149567,  -25.958517581450643",Grosmont
"147.82046988741556,  -19.907641281987544",Guthalungra
"150.69245881906318,  -24.782136904382007",Harrami
"152.09375892192043,  -27.548179451436845",Helidon Spa
"146.23448126420837,  -18.351912830877676",Hinchinbrook (Qld)
"142.28155353363042,  -10.606460871777129",Horn
"152.99771801247653,  -26.730999581527634",Ilkley
"152.27115383228096,  -27.73578348568566",Ingoldsby
"150.38995409462748,  -23.05417363442298",Jardine
"151.58681521262642,  -27.398228063153017",Jondaryan
"153.03670788737924,  -27.47680714865527",Kangaroo Point (Qld)
"148.17947178549358,  -26.118557458511223",Kilmorey Falls
"151.95297505260623,  -27.420234208136616",Kleinton
"149.96041523157197,  -24.131712480085223",Kokotungo
"139.49584001645783,  -20.687967718395456",Lanskey
"148.68709434279157,  -24.338658139825842",Lowesby
"146.0384433875084,  -17.6146616400851",Martyville
"151.61752443001112,  -26.27120167327947",Melrose (Qld)
"153.08346272573607,  -26.761907337895128",Meridan Plains
"152.4850236871446,  -27.7958498703141",Merryvale
"152.73080226163447,  -27.882681949869003",Milbong
"145.4149146683719,  -17.36343326886724",Moomin
"151.30181949313047,  -28.252503175992107",Mosquito Creek
"148.7966353998672,  -20.939783554315213",Mount Pelion
"151.91430156088143,  -27.634955615051446",Mount Rascal
"153.05339872071204,  -27.54858548640495",Nathan
"152.28304054657954,  -25.051445469965703",North Gregory
"151.95479643629793,  -27.54672629742737",North Toowoomba
"153.09596542912857,  -27.352525893465458",Nudgee Beach
"146.76671810344803,  -19.194093049723598",Pallarenda
"153.03028630064293,  -27.642902377221972",Parkinson
"152.87702688527156,  -26.845953335155965",Peachester
"152.72237591837325,  -25.438301570236018",Prawle
"148.65699153616544,  -20.39068848782784",Preston (Whitsunday - Qld)
"152.14457203051748,  -25.091565680751035",Promisedland
"150.41883204543188,  -24.51173363718179",Prospect (Qld)
"152.15939912417295,  -27.336100760356683",Ravensbourne
"152.5928661950797,  -27.638665273894826",Rosewood (Qld)
"149.1060104268472,  -21.24591874532978",Sandiford
"150.8537325543418,  -32.512975488648394",Appletree Flat
"151.04759255708078,  -29.06814938738956",Atholwood
"150.9291346476115,  -34.301742764059725",Austinmer
"149.47873690543864,  -32.747136044333544",Avisford
"147.98483469563038,  -35.273285918227515",Bangadang
"145.70969715279264,  -35.90073632929352",Barooga
"152.5818397931452,  -31.3279284935247",Bellangry
"151.14959293384445,  -33.62818619561329",Berowra
"150.64986830532774,  -34.76631326619349",Berry Mountain
"153.35366332496952,  -28.755834755071945",Bexhill
"149.13022358880434,  -34.06299326312616",Bigga
"150.14356184255925,  -36.01181998252291",Bingie
"150.27861716742348,  -33.638389042481556",Blackheath
"153.0777109881145,  -30.345321789867494",Boambee East
"151.22380014742527,  -29.050937327458357",Bonshaw (NSW)
"149.63092655174117,  -28.700843873729617",Boomi
"153.4480583490231,  -28.74736794755906",Booyong
"146.5374740511472,  -35.0779627298151",Boree Creek
"148.84045794252296,  -33.31888792413686",Bowan Park
"150.98287107088922,  -32.638371013960544",Bulga (NSW)
"144.55494524422514,  -35.80312253236081",Bunnaloo
"149.16791698369516,  -36.15108568645411",Bunyan
"152.80266534597985,  -31.108822752420004",Burnt Bridge
"151.11608818506096,  -33.91213305335121",Canterbury (NSW)
"147.57276202172798,  -30.384448139751832",Carinda
"150.97299428975768,  -33.33548836265685",Central Macdonald
"151.49401765653295,  -33.22590703435825",Charmhaven
"150.79046337590572,  -33.61259040699189",Clarendon (NSW)
"150.0881731573903,  -32.370905946506745",Coggan
"147.1042827830749,  -35.09177477464319",Collingullie
"150.80712412821487,  -33.41635212508137",Colo (Hawkesbury - NSW)
"151.76783155630014,  -32.93303099682572",Cooks Hill
"150.89234028790202,  -34.36803199779548",Corrimal
"148.76966691840346,  -36.902624606854445",Corrowong
"150.70940611857998,  -33.7061100292823",Cranebrook
"151.59511635142644,  -33.16857053654101",Crangan Bay
"153.31509529341992,  -28.31657829395109",Crystal Creek (NSW)
"152.2479546797049,  -31.47414915818357",Debenham
"152.70709966806015,  -30.967812904505347",Deep Creek (Kempsey - NSW)
"149.92771947406143,  -35.790826878702944",Deua River Valley
"153.35945596958445,  -28.658282883976852",Dorroughby
"151.56035436719776,  -32.40740306509549",East Gresford
"151.3505967830145,  -32.58204124485874",Elderslie (Singleton - NSW)
"151.64938180385428,  -32.99323755593824",Eleebana
"152.897349746525,  -29.774207007707087",Elland
"151.55452281314706,  -29.386200744966143",Emmaville
"150.79775456861987,  -33.81299385793786",Erskine Park
"146.3021515169258,  -34.71005127093404",Euroley
"151.1924035938722,  -33.89566485789774",Eveleigh
"151.12883818247022,  -33.86747870338662",Five Dock
"151.67885062324606,  -32.94625385994013",Garden Suburb
"150.83752833881834,  -30.996342899371175",Gidley
"148.60240570749016,  -31.716038280010718",Gilgandra
"149.95805961909969,  -33.887724775927815",Gingkin
"146.89936828352364,  -36.05150442475858",Glenroy (Albury - NSW)
"153.03890983666741,  -29.801452772302852",Glenugie
"152.71591209698212,  -28.733302207374614",Gorge Creek
"151.60614819262724,  -30.69987893630799",Gostwyck
"151.16674420306978,  -32.36142513165753",Greenlands (Singleton - NSW)
"146.78482669135985,  -34.68841189339413",Grong Grong
"149.84777519788562,  -34.045482199192215",Gurnang
"152.82529226207524,  -31.33032662451157",Hacks Ferry
"146.8939008231652,  -36.027395231640426",Hamilton Valley
"149.54562129639822,  -35.775015412222075",Hereford Hall
"152.71859503326513,  -31.559833129409736",Herons Creek
"151.58381821216798,  -32.916802528696685",Holmesville
"151.10088250439077,  -33.65712035410997",Hornsby Heights
"150.83479360987008,  -32.811469682362116",Howes Valley
"150.88229783606772,  -33.7978116668947",Huntingwood
"148.52767936814993,  -36.63388239827636",Ingebirah
"149.76663871505593,  -35.52158036242617",Jembaicumbene
"149.0759343470534,  -34.780620121617446",Jerrawa
"148.2550310446075,  -35.375109406140524",Jones Bridge
"149.85063733184177,  -32.47289797633946",Kains Flat
"151.08297199472383,  -34.017509567027226",Kareela
"150.95816258450253,  -33.71025650233718",Kellyville
"152.13160139273933,  -31.78900021390473",Knorrit Forest
"150.6484411561004,  -33.76631241812189",Leonay
"151.20646172777126,  -31.047176293531372",Limbri
"149.7008173802579,  -32.42364761774774",Linburn
"150.51012929174382,  -33.716764640845874",Linden (NSW)
"151.14954025558797,  -33.82715356702091",Linley Point
"153.0458266527124,  -28.613420071832177",Little Back Creek
"151.29963239600943,  -32.620531960498234",Lower Belford
"150.88515986828006,  -33.43930725749595",Lower Portland
"152.99216404127856,  -28.457750130282985",Lynchs Creek
"152.6702854783667,  -31.889704942812333",Manning Point
"147.34275345213283,  -34.833394360779515",Marrar
"144.19004512275842,  -34.489854763312856",Maude (NSW)
"151.7496753015975,  -32.90029252403629",Mayfield East
"150.41079904675325,  -30.4095920424247",Mayvale
"150.350750329839,  -34.69358869444613",Meryla
"149.8564278603353,  -36.88626603436812",Millingandi
"151.5471734994208,  -29.003484715883925",Mingoola (NSW)
"148.44834433196505,  -32.27083044152786",Minore
"151.29659687116785,  -32.55499569729665",Mitchells Flat
"150.10166861118358,  -35.79413630855182",Mogo (Eurobodalla - NSW)
"149.2014572030042,  -31.58475603484668",Mollyan
"150.45928891823976,  -33.48560571347132",Mount Irvine
"150.82153035160684,  -34.42879547538139",Mount Kembla
"147.48017708715474,  -31.71184939149446",Mullengudgery
"151.16420073654962,  -33.077522604089005",Murrays Run
"153.38276859464705,  -28.332248578253335",Murwillumbah
"146.13552103288495,  -34.106324203784624",Myall Park (NSW)
"153.01042152963365,  -30.130388436450296",Nana Glen
"151.29218952015017,  -33.71744034004921",Narrabeen
"147.98144118214017,  -33.1791755319495",Nelungaloo
"151.0269987878499,  -33.79579305048383",Oatlands (NSW)
"150.64962609397355,  -35.088171186969916",Old Erowal Bay
"150.7950968246731,  -33.77070162189325",Oxley Park
"149.7746066366079,  -34.003703457993524",Porters Retreat
"149.59592568888561,  -31.35268532560911",Purlewaugh
"149.92764588394948,  -32.6090079237577",Pyangle
"146.52797475124376,  -35.57584675000084",Rand
"152.78386619162617,  -31.41903506645164",Rawdon Island
"149.90310917175765,  -36.70089983431188",Reedy Swamp
"150.83908235051928,  -33.66628446195421",Richards
"153.39085437204415,  -29.03129702140583",Rileys Hill
"149.1690073701128,  -35.492520300994045",Royalla
"150.93855912222034,  -33.77675739515323",Seven Hills (NSW)
"150.59168119282742,  -33.92876302886003",Silverdale (NSW)
"151.2544954556686,  -33.93241469293941",South Coogee
"149.0853441127986,  -35.0889503845046",Springrange
"149.04688535863562,  -32.83233155851868",Stuart Town
"150.20348582874888,  -35.7623056228601",Surf Beach (NSW)
"151.99879624879625,  -32.659615784431324",Tahlee
"153.16600377177943,  -28.937607404924826",Tatham
"148.93078125758456,  -36.90257400057929",Tombong
"151.57792591315857,  -33.01525303501112",Toronto
"153.32744545406965,  -28.951621125253954",Tuckurimba
"149.80203004881366,  -32.25875292249206",Ulan
"152.7170266386731,  -28.431282057472483",Unumgar
"152.71790306626488,  -29.275612769692877",Upper Fine Flower
"149.80988838124256,  -36.446030959658145",Verona
"151.34083560586853,  -33.52352368289207",Wagstaffe
"149.97894496543205,  -35.976805571278646",Wamban
"149.6784250675034,  -35.32686294565146",Warri
"148.97539508868323,  -31.305374601593844",Warrumbungle
"142.02214481729393,  -33.92164110429309",Wentworth (NSW)
"143.5993609318815,  -31.28777499947642",Wilcannia
"151.83704690586455,  -32.80340708821696",Williamtown
"152.68700715244273,  -29.548060599881147",Winegrove
"151.15284471733546,  -33.93141728552098",Wolli Creek
"148.0981562564701,  -35.39453994222782",Wondalga
"151.67690958483422,  -32.793072389971016",Woodberry
"150.47812786198784,  -33.73765725974166",Woodford (NSW)
"151.840421875092,  -29.066898420911475",Woodside (NSW)
"150.66694285001122,  -35.11632906058139",Wrights Beach
"150.3614907670379,  -34.95626583372614",Yalwal
"149.12530769146625,  -34.92747044510532",Yass River
"151.10282521236755,  -34.04885789030852",Yowie Bay
"143.641471815064,  -38.72779083108799",Apollo Bay (Vic.)
"146.55178360360537,  -38.42744229063895",Balook
"143.6153396978074,  -38.428254904670574",Barongarook
"145.03849853838824,  -37.75301164801799",Bellfield (Banyule - Vic.)
"141.92959253622337,  -38.16161173679299",Bessiebelle
"143.4727162504387,  -35.22372976001197",Beverford
"146.48647420294,  -36.53653152920718",Bobinawarrah
"145.88090597575015,  -37.01714688068159",Bonnie Doon (Vic.)
"146.3617536628616,  -36.30808913598021",Bowser
"145.91277533954332,  -38.102024752766496",Brandy Creek (Vic.)
"145.88689419561842,  -36.43963778223082",Broken Creek
"145.71896919552432,  -38.10002280830273",Bunyip
"142.26991591299654,  -36.264213729762346",Cannum
"143.71565165096996,  -37.51874946428894",Cardigan Village
"142.13592289627584,  -34.53006236309049",Carwarp
"141.38064718994085,  -37.29564064059668",Chetwynd
"145.33824824697345,  -38.499404676684144",Churchill Island
"143.40536555976183,  -37.308216308896625",Chute
"144.59965786973902,  -37.70556463259671",Cobblebank
"145.46910945604768,  -36.29457922221693",Congupna
"145.4449586338855,  -38.43326427028933",Coronet Bay
"142.31332607284747,  -38.31704469016239",Crossley
"144.77731184354434,  -37.71978478976293",Delahey
"144.99799351538638,  -37.540682621938174",Donnybrook (Vic.)
"142.23522469841652,  -36.64543453244511",Dooen
"143.50421214612544,  -38.15629806467648",Dreeite
"144.67136882372324,  -36.17560617547714",Echuca West
"144.4633716942872,  -37.185160166624904",Edgecombe
"145.25618940184222,  -37.97009843050542",Endeavour Hills
"144.54268658267478,  -37.73109465799558",Exford
"145.01577253531337,  -37.78452999360433",Fairfield (Vic.)
"146.20035875260078,  -38.67332776626257",Foster
"143.20499392176794,  -37.005613023781585",Frenchmans
"143.95470269990645,  -36.05870819578597",Gladfield (Vic.)
"145.64243115465447,  -37.83911234829154",Gladysdale
"143.49215480731468,  -38.74209539288286",Glenaire
"146.66834206717527,  -37.814018486666605",Glenmaggie
"146.21945346424383,  -36.46059915402597",Glenrowan
"143.38677169460442,  -36.54155945360324",Gowar East
"144.098084967488,  -37.116936329879614",Green Gully (Vic.)
"147.6817776662547,  -36.091970952780486",Guys Forest
"144.32411277494822,  -38.13424591940942",Herne Hill (Vic.)
"141.55931675319562,  -37.94280091125957",Hotspur
"146.68550677455977,  -37.20605377743652",Howitt Plains
"144.00117262600043,  -38.108921930742646",Inverleigh
"145.287621364235,  -36.145769625519534",Kaarimba
"141.49909068410332,  -36.83847042713913",Karnak
"144.9270188684126,  -37.7943465990387",Kensington (Vic.)
"142.9217333682039,  -38.12734305534034",Kolora
"144.07013315507385,  -35.735448584785516",Koroop
"143.91647060136063,  -36.82764677199947",Laanecoorie
"144.69243223018447,  -36.820665467538994",Ladys Pass
"145.3678189227384,  -37.755622641526436",Lilydale (Vic.)
"145.69598452464982,  -38.37621267884777",Loch
"145.31893826502994,  -36.84210620576957",Locksley (Vic.)
"143.9464172128088,  -35.99747953571236",Loddon Vale
"142.5518683784631,  -37.38737824521202",Mafeking
"143.37315414129958,  -37.38567214632376",Main Lead
"146.50882379627416,  -36.472995506401396",Markwood
"144.54107402838605,  -37.67890702450916",Melton West
"146.29601552802134,  -38.58853082859268",Mount Best
"146.15644799523034,  -36.32063470957366",Mount Bruno
"144.04089213063273,  -37.721676751472366",Mount Doran
"145.38815896365196,  -37.78727930768434",Mount Evelyn
"145.6990165983212,  -36.36501221477487",Mount Major
"144.13882358736691,  -38.100358264753474",Murgheboluc
"143.75331467172708,  -36.22490595629376",Mysia
"144.60369618538107,  -37.29961360163432",Newham
"142.86142735577081,  -38.489461045988044",Nirranda East
"142.76630367303486,  -38.47602882496922",Nullawarre
"143.6176130556248,  -38.24262567591683",Ondit
"144.4144696654058,  -36.026080464596355",Patho
"144.7518171119748,  -37.90577072309421",Point Cook
"143.2883156065932,  -38.292172449845985",Pomborneit
"141.5332031368551,  -38.36605034882542",Portland West
"144.9953006467271,  -37.877784369917045",Ripponlea
"142.75299150807632,  -34.62226251026749",Robinvale
"144.72829161212454,  -37.35231930606449",Romsey
"144.91051963396333,  -38.37177249931575",Rosebud
"145.3784306371868,  -37.91872554927469",Selby
"145.39071487456977,  -36.31946763536419",Shepparton North
"144.25502284932432,  -37.840413216155845",Staughton Vale
"145.75984375443414,  -36.87962806203561",Strathbogie
"142.11444775430684,  -37.701711593523726",Strathkellar
"147.7236671155877,  -37.25088428380619",Swifts Creek
"144.23054486891058,  -36.077039698912046",Sylvaterre
"144.05093245764306,  -35.680055608602736",Teal Point
"145.4766743099182,  -38.414171666678655",Tenby Point
"142.8788257707546,  -38.238601885240676",Terang
"143.38252516485963,  -35.76138525402576",Tittybong
"145.50283916737374,  -37.52127907694166",Toolangi
"144.5276208815179,  -36.04618063367324",Torrumbarry
"143.22339989234257,  -35.14973261222145",Towan
"145.31622044588292,  -37.879190385699026",Tremont
"144.33042992816462,  -37.38099716352193",Trentham
"142.25816564910352,  -36.354710271456796",Wallup
"142.94285399291272,  -34.82234079395257",Wandown
"142.7549752471388,  -36.086311471257524",Warmur
"143.2534562928178,  -38.75539151872446",Wattle Hill (Vic.)
"143.6055795688965,  -37.929109924351046",Werneth
"142.8193721269587,  -35.71259714693604",Willangie
"146.36567539562705,  -38.959021248776395",Wilsons Promontory
"145.5913686149157,  -38.617186885571996",Wonthaggi
"148.41435308366587,  -37.415693640191506",Yalmy
"145.81577083384025,  -36.27828266209076",Yundool
"150.89226414800166,  -23.81277364973185",Ambrose
"152.67843591388544,  -26.016218615876838",Anderleigh
"152.1182256356485,  -27.073497785094833",Anduramba
"146.86038900213688,  -19.143313684936587",Arcadia (Qld)
"149.00853032763715,  -21.117483984624993",Balnagowan
"145.66919476546562,  -16.8801788299006",Barron Gorge
"148.2681618659963,  -27.462208563981175",Begonia
"152.13334114005286,  -26.889504485914326",Benarkin
"153.05923699379042,  -27.95014491575834",Birnam (Scenic Rim - Qld)
"151.23946094464765,  -26.15856390212295",Boondooma
"151.2761224429441,  -24.44839149653078",Boyne Valley
"146.86648074827693,  -19.427190526782624",Brookhill
"152.37363689693575,  -24.859418608084628",Bundaberg East
"151.76141103571402,  -25.52207154099705",Byrnestown
"152.07256446038159,  -28.248499320693938",Canningvale
"145.6828928607682,  -16.860075822118837",Caravonica
"153.16824171431142,  -27.516569813330864",Chandler (Qld)
"148.75108081510535,  -20.473916509111998",Conway Beach
"152.64577234908168,  -26.108440796194596",Corella
"152.577330328929,  -27.149171785632884",Crossdale
"144.38484519915414,  -17.460489922011757",Crystalbrook
"152.79978304195384,  -26.984205161147113","[""D'Aguilar""]"
"150.51960729306364,  -23.393511643811692",Depot Hill
"152.06191639962242,  -27.585589200082918",Derrymore
"153.02616560021676,  -27.49556708731966",Dutton Park
"152.06804010964473,  -26.68509755590628",East Nanango
"152.11535893303136,  -24.99695497649304",Electra
"152.87058336631546,  -27.433452971041945",Enoggera Reservoir
"145.992435522672,  -17.419765485223913",Eubenangee
"150.74327271540847,  -23.044461939358122",Farnborough
"145.89483654532634,  -17.18182817313601",Fishery Falls
"143.49880159540908,  -16.389948014576735",Gamboola
"151.99619127466056,  -27.38768481533595",Geham
"145.6622939241197,  -17.375483083211215",Glen Allyn
"151.26764015532478,  -23.902699032151464",Glen Eden
"152.34991107968258,  -25.750597652579252",Glenbar
"152.3231545203501,  -24.82167359606271",Gooburrum
"152.3756060251338,  -25.099012435695236",Goodwood (Qld)
"150.41187222966454,  -26.723779260846108",Goombi
"153.02742952551355,  -27.41658911481709",Gordon Park
"151.69485686867606,  -26.47471536191656",Gordonbrook
"152.95987374768342,  -27.70105829204027",Greenbank
"141.67593860101678,  -12.41294565451152",Gulf Of Carpentaria
"150.39763855709143,  -23.672494544725296",Hamilton Creek
"151.68891483001937,  -26.172202741791402",Hivesville
"145.28290945346183,  -17.786563860396548",Innot Hot Springs
"152.67161531032275,  -27.556426225899333",Ironbark (Qld)
"153.35919482781958,  -27.785532424140605",Jacobs Well
"145.91200068013768,  -17.72412619416193",Japoonvale
"145.87496265891994,  -17.94509764209472",Jarra Creek
"146.64144108253876,  -19.256663325185837",Jensen
"153.03059959618355,  -27.403989914834533",Kedron
"152.42237939090865,  -27.476809794368272",Kentville
"153.00801805627418,  -26.65641861093233",Kiels Mountain
"150.105149667569,  -22.836235905008678",Kunwarara
"145.60758934897183,  -16.829266186401707",Kuranda
"146.16503786679036,  -25.130599061569033",Lansdowne (Qld)
"148.64988284011056,  -20.544185322841116",Lethebrook
"153.10718651168918,  -27.643798284607445",Logan Central
"153.1864245873631,  -27.68436529588257",Loganholme
"145.97311417255446,  -18.516813395376026",Long Pocket
"153.12913986172614,  -27.53992890625502",Mackenzie (Brisbane - Qld)
"153.09460252689243,  -26.44623200444361",Marcus Beach
"152.29321015457356,  -25.848278478791602",Marodian
"153.08191341896068,  -26.65283612129409",Maroochydore
"152.26710043795293,  -28.075560296557672",Maryvale (Southern Downs - Qld)
"152.65948009661932,  -26.415688886845217",Melawondi
"148.8305745651855,  -21.22312458083313",Mia Mia (Qld)
"145.91897753253144,  -17.385031525341798",Mirriwinni
"153.36074572715177,  -27.974017892791913",Molendinar
"149.73866062645143,  -26.88773051435417",Moraby
"152.3186968584683,  -27.717686168019522",Mount Berryman
"152.62228771724455,  -27.598332772409474",Mount Marrow
"151.65537437303686,  -25.18731197801023",Mount Perry
"151.1436215522428,  -24.751814494923856",Mungungo
"145.39769238851142,  -16.425331634883978",Newell
"145.97051869278076,  -17.770060271254483",No 4 Branch
"148.70481094318248,  -21.102801430401318",Owens Creek
"151.65800054262888,  -28.631184104712602",Pikedale
"151.96026040220002,  -27.26950307977766",Plainby
"151.60878550409728,  -27.137798307472732",Quinalow
"153.0553832164784,  -27.2110790240517",Rothwell
"151.8638878908411,  -24.298911935220477",Round Hill (Qld)
"142.6624829820811,  -19.8646729449825",Saxby
"146.08012018272308,  -17.95325706818809",South Mission Beach
"146.83245247805775,  -19.26019469341399",South Townsville
"145.62574341474743,  -16.890810396167392",Speewah
"152.65228660566382,  -27.373529975663754",Split Yard Creek
"140.48292710468996,  -18.668069031070512",Stokes
"151.62561717919596,  -27.656167163208167",Stoneleigh (Qld)
"152.13251876246954,  -26.813297472147788",Taromeo
"151.19174768589326,  -28.84391949164968",Texas (Qld)
"152.92096979177796,  -27.58686068410376",Wacol
"152.3589096884195,  -24.882644508767356",Walkervale
"152.82068588801565,  -27.048700636380133",Wamuran Basin
"149.4250207134204,  -26.923147732825015",Warkon
"151.67008076492118,  -26.565324694701733",Wattle Grove (Qld)
"145.93544968631565,  -17.449723941796925",Waugh Pocket
"153.04704575368436,  -27.393876298739595",Wavell Heights
"153.2407416797396,  -27.495269253056758",Wellington Point
"151.86850416464424,  -28.164422212874065",Wheatvale
"152.6541183335883,  -27.839895491176872",Wilsons Plains
"153.01859770157938,  -27.4344998089204",Wilston
"151.77485777606316,  -26.410328486709872",Wooroolin
"150.3321993134696,  -23.78832206304198",Wura
"146.60440061112467,  -19.19466933742708",Yabulu
"153.01286724085895,  -27.52883261749496",Yeerongpilly
"139.17035045738314,  -34.08640959931205",Australia Plains
"138.77336785298465,  -34.935357744305364",Basket Range
"138.71531636231396,  -32.268135379870415",Belton
"138.49944388291942,  -34.83661868560188",Birkenhead
"138.59655395957734,  -34.85923715428367",Blair Athol (SA)
"139.7493913298506,  -37.071039166128124",Boatswain Point
"140.7283713715943,  -36.43217246940338",Bordertown South
"138.58006618544198,  -34.9030217967298",Bowden
"140.59171931430288,  -37.78561583212159",Burrungule
"136.4084288162982,  -33.54570207378323",Campoona
"138.57084841992506,  -34.998463134498614",Clovelly Park
"138.95352930971285,  -35.0419234702395",Dawesley
"138.5499694673936,  -32.52526042481258",Eurelia
"138.56143763303433,  -34.868737912948745",Ferryden Park
"139.64299108891382,  -35.79460662901055",Field
"137.24656739644604,  -35.13591252015716",Foul Bay
"139.24584447434142,  -34.85196746454825",Frayville
"138.41207839736663,  -33.374547408479394",Georgetown (SA)
"138.6525028529704,  -34.896020294336864",Glynde
"138.49273658735405,  -34.36304931397948",Grace Plains
"138.92989285882481,  -35.16752573887591",Highland Valley
"138.62264545200333,  -35.282842552534674",Hope Forest
"138.68615646155635,  -35.04287364953624",Ironbank
"138.6849873560892,  -32.49538430310902",Johnburgh
"140.54194413125097,  -34.40364289528844",Katarapko
"138.65428645384,  -34.92018879437863",Kensington Park
"138.68144905405885,  -35.214878892812",Kuitpo
"135.02666846909213,  -31.623615766995623",Lake Everard
"137.30278306023112,  -28.6063116391118",Lake Eyre
"138.26181397319243,  -33.642707301737794",Lake View
"140.5917598638697,  -34.474606449751676",Loxton
"140.164727761115,  -35.184012923045366",Marama
"139.38416577519268,  -35.73356680324942",Meningie
"135.52565077241906,  -34.015948893147645",Mitchell (SA)
"138.6851661324227,  -34.83391512666964",Modbury
"138.90938499997145,  -33.548497664889524",Mount Bryan
"138.98002280175348,  -34.7187518445626",Mount Crawford
"139.442696024643,  -34.111874216369564",Mount Mary
"140.5584596922206,  -37.548432694265124",Mount Mcintyre
"140.69877303641033,  -37.94461996325375",Mount Schank
"138.33297851357096,  -28.71377958376343",Muloorina
"140.59472045685052,  -35.73000576422191",Ngarkat
"139.25920520992216,  -35.10213300546618",Northern Heights
"138.50373174028974,  -35.18267824921699",Old Noarlunga
"138.39547018370732,  -31.13118371645961",Parachilna
"138.799978157822,  -35.21843227002832",Paris Creek
"139.3093520435473,  -35.52164486409274",Poltalloch
"137.72250471726537,  -34.81676708366039",Ramsay (SA)
"139.1526201469546,  -34.02264884160252",Rocky Plain (SA)
"138.62929249398843,  -34.912204974794506",Stepney
"140.55522326314767,  -36.947217043559206",Stewart Range
"138.6473481134382,  -34.93586809971944",Tusmore
"138.88543134966224,  -33.988292554110835",Waterloo (SA)
"137.89929776737068,  -34.27431503250842",Winulta
"140.33890323344812,  -36.791678619716635",Woolumbool
"138.6871828531021,  -34.79941427189106",Wynn Vale
"138.80056135818006,  -34.68073002461641",Yattalunga (SA)
"138.74489734143944,  -33.02776840209216",Yongala
"117.71783724306356,  -32.217328174116005",Adamsvale
"115.35161547946576,  -29.532781187290638",Arrowsmith East
"115.93783234220653,  -31.917239326256812",Ashfield (WA)
"115.86527382952744,  -32.14348044349413",Atwell
"117.86615616563739,  -30.430155118621787",Beacon
"117.2934409618538,  -31.12917820561381",Benjaberring
"115.90674173645938,  -32.00513534931827",Bentley (WA)
"117.35425689948183,  -32.951273141204936",Boundain
"117.63994922509848,  -33.84964907030443",Broomehill Village
"117.73928360413075,  -32.50999594727157",Bullaring
"116.60269380776435,  -31.670837531504773",Burlong
"116.02988623051476,  -31.92828709579903",Bushmead
"114.64891602647225,  -28.86784462796532",Cape Burney
"115.78346014832687,  -31.699895082460554",Carramar (WA)
"115.75007145236263,  -30.61647617887082",Dandaragan
"116.08071979321244,  -31.92466699364356",Darlington (WA)
"113.67744531148129,  -24.867166597730126",East Carnarvon
"115.90380844433273,  -31.989338629378796",East Victoria Park
"114.71660158670704,  -27.43782368233644",Eurardy
"115.64272204885341,  -33.4276401132419",Gelorup
"121.8255153782044,  -33.623893409313744",Gibson
"122.7899782825346,  -19.0746331212381",Gingerah
"117.99777597621357,  -32.46028711632135",Gorge Rock
"115.67406192159655,  -32.75518403526527",Herron
"116.00097323133775,  -31.944668869495793",High Wycombe
"119.17887080976833,  -33.81887643648383",Jacup
"124.01045892715898,  -18.013353298465184",Jarlmadangah Burru
"120.43194449071542,  -33.77486940373119",Jerdacuttup
"115.77004467928435,  -31.737825417538357",Joondalup
"117.02528292362499,  -31.944019945682843",Kauring
"117.06359036289093,  -34.936340054584726",Kentdale
"124.14618252195497,  -16.603830899542718",Kimbolton (WA)
"126.89268953904312,  -19.103188377361207",Kupartiya
"115.77618961084025,  -32.22904249762325",Kwinana Beach
"117.99408126257714,  -27.564011065264584",Lake Austin
"118.38759398460743,  -30.960884374500676",Lake Brown
"121.3844403275062,  -33.33464415305328",Lort River
"116.66478037333991,  -30.476308419786374",Marne
"114.68642011767878,  -28.803444681122937",Meru
"116.0084409526712,  -30.615255246806726",Moora (WA)
"113.6591268373332,  -24.873728299037452",Morgantown
"116.86083778605577,  -31.918335426624772",Mount Hardey
"117.86797556382362,  -35.01765705299475",Mount Melville
"116.96576668135961,  -33.84922147606619",Muradup
"116.7045395922497,  -31.727502856738926",Muresk
"115.04447773610619,  -33.58452551467536",Naturaliste
"128.11477325940626,  -25.27010975628455",Ngaanyatjarra-Giles
"115.84539023126533,  -31.880145468168372",Nollamara
"117.49783604136138,  -31.51547116339607",North Tammin
"117.66210578511321,  -31.066678948129212",North Yelbeni
"115.70070298764972,  -32.27676756260555",Peron
"115.83049273913733,  -31.61846520812378",Pinjar
"115.89876976756734,  -32.03469189348132",Riverton (WA)
"114.4921438512515,  -28.34059300582984",Sandy Gully (WA)
"121.77103417975242,  -33.429376083753304",Scaddan
"118.25244710469589,  -34.634785683465374",South Stirling
"115.91044497311124,  -32.00002752018683",St James (WA)
"118.26466817393258,  -31.186887013332278",Talgomine
"115.76273612113624,  -29.534336792694408",Three Springs
"116.04109813583081,  -31.763945918377573",Upper Swan
"118.79496046231789,  -20.756718403173384",Wallareenya
"115.64502130132104,  -32.59943582689452",Wannanup
"115.63831372829937,  -29.296662796678916",Yandanooka
"115.85319827478413,  -31.90113312977306",Yokine
"115.4314515478585,  -33.71621002202625",Yoongarillup
"146.18216128780298,  -41.21407007822708",Abbotsham
"147.33324044197133,  -42.88999551192186",Battery Point
"146.90250213503438,  -41.74420961517517",Blackwood Creek
"147.72425398316273,  -42.88690682757439",Connellys Marsh
"147.1440695579079,  -42.719834353214516",Dromedary (Tas.)
"146.7337603267809,  -41.64014960081352",Golden Valley
"145.61911670186322,  -42.078904211655654",Gormanston
"147.37294157556167,  -43.2275455892885",Great Bay
"146.99508088118665,  -41.08238350040654",Lefroy
"147.69643283275138,  -41.21063830051162",Legerwood
"146.3530960290852,  -41.19548744283882",Miandetta (Tas.)
"146.90621433080605,  -42.87799870731982",Mount Lloyd
"145.61176240251336,  -41.23368421243447",Oonah
"147.5010361102305,  -42.76688201032987",Penna
"147.14247218026637,  -41.48422190044874",Prospect (Tas.)
"145.99209771310458,  -41.20608298743432",Riana
"146.92137920953044,  -41.41955121974567",Rosevale (Tas.)
"146.87635436458802,  -41.23262923602219",Sidmouth
"147.2855583416171,  -42.89797187761166",South Hobart
"147.52556509544831,  -41.2732299576094",South Springfield
"148.24274556458977,  -41.1616202556525",The Gardens (Tas.)
"146.5111597350572,  -42.381131877610436",Wayatinah
"145.77611261415794,  -41.153342676266355",West Ridgley
"145.28287232043974,  -40.828404998644686",Wiltshire
"145.708079544512,  -41.13042312667516",Yolla
"131.15578799372744,  -13.1678503273158",Adelaide River
"133.9306622207729,  -23.768455696329735",Amoonguna
"132.86742289507924,  -14.526615830685232",Barunga
"130.92606080128496,  -12.435090787875948",Berrimah
"131.90825118382602,  -14.515201095277199",Florina
"131.02398789089213,  -12.17996669930401",Gunn Point
"130.37557768569138,  -14.274415126749876",Nemarluk
"130.89545627733276,  -12.383521759488843",Wulagi
"131.0066953750784,  -12.511692672968898",Zuccoli
"149.0768275127464,  -35.353376342901434",Chifley (ACT)
"149.10423984152987,  -35.4592096468348",Conder
"149.11551340588795,  -35.36865694166333",Isaacs
"149.12062033953984,  -35.333405715501755",Red Hill (ACT)
"149.1247466722477,  -35.26878348083228",Turner
"149.15947539594336,  -35.23474550490275",Watson
"150.70241243637992,  -33.62243091278128",Agnes Banks
"150.83318017839932,  -34.086026267707425",Airds
"149.87502548510133,  -36.63451090504177",Angledale
"148.98068745935484,  -36.38257492900204",Arable
"152.91968849034333,  -31.025613955160193",Austral Eden
"150.4721394239117,  -34.591178115334955",Avoca (NSW)
"151.91740944974327,  -30.047094815133438",Backwater
"150.74517302305284,  -33.88196216007753",Badgerys Creek
"152.53442794701402,  -31.471079544166624",Bagnoo
"149.84291057557456,  -36.911715189837004",Bald Hills (NSW)
"148.46769386862482,  -32.88816437679565",Baldry
"150.71808747934253,  -31.004688177223358",Bective
"151.06102097737104,  -33.75295214501081",Beecroft
"150.53980641653962,  -34.084067384969714",Belimbla Park
"151.10532388968858,  -33.609550858884994",Berrilee
"149.08701363799818,  -34.533053557731556",Bevendale
"151.08002886816007,  -33.94753142338132",Beverly Hills
"150.54960690246898,  -33.448507478644665",Bilpin
"148.70900591831855,  -35.57314588930415",Bimberi
"150.13920734731613,  -29.577001282424156",Biniguy
"149.10470754419785,  -36.17878290829944",Binjura
"148.89346978667632,  -34.976010207842336",Boambolo
"151.97517829950968,  -32.47259253688456",Booral (NSW)
"150.52066025188762,  -30.602255422047843",Borah Creek
"148.84468591485617,  -33.25176305925746",Boree
"151.90332591033845,  -31.786429625210157",Bretti
"148.66473919497466,  -35.35995513618634",Brindabella
"149.14972352081162,  -33.53854798051342",Browns Creek
"152.59489927399602,  -29.823789196183366",Buccarumbi
"152.06400869423757,  -32.669879133857684",Bundabah
"148.1486951744076,  -31.786841577047678",Bundemar
"144.32801066123247,  -35.34173276227722",Burraboi
"152.73173697233042,  -28.804003354382928",Cambridge Plateau
"150.01022208609777,  -33.12827393951204",Capertee
"149.13601953361353,  -33.61658399146912",Carcoar
"145.41879161469535,  -34.42997637368686",Carrathool
"150.64412701792554,  -34.64760132671833",Carrington Falls
"151.29587237721273,  -32.291874593375255",Carrowbrook
"148.8342188329606,  -35.02920039835238",Cavan (NSW)
"152.50986722966837,  -32.35286599160996",Charlotte Bay
"153.28462765968501,  -28.31230177601397",Chillingham
"151.43965851505396,  -33.32654487918887",Chittaway Bay
"152.9443801314514,  -30.936529687020357",Clybucca
"151.29868952102584,  -33.60494398412004",Coasters Retreat
"146.22267896669428,  -35.9743358092449",Collendina
"153.03455622060326,  -28.47888437108165",Collins Creek
"152.88461038610876,  -30.682521982160793",Congarinni North
"150.88233036470803,  -34.438011268155556",Coniston
"149.1202069265638,  -36.250784833996455",Cooma (NSW)
"150.82707991861747,  -34.4440582593855",Cordeaux Heights
"150.05750527919142,  -32.72474908936866",Coxs Crown
"152.67358068625492,  -28.866174596798295",Culmaran Creek
"151.98812068750286,  -31.809435865772617",Cundle Flat
"149.15897129357637,  -30.412776653820075",Cuttabri
"151.15917702912733,  -32.55826905391057",Darlington (Singleton - NSW)
"144.85058172975857,  -35.55442192502591",Deniliquin
"151.36551392353087,  -32.65452985187995",East Branxton
"151.18820243241112,  -33.767091025453624",East Lindfield
"152.31558807987702,  -30.412297896521633",Ebor
"151.4847715310716,  -32.255814965115725",Eccleston
"152.90743191728592,  -28.585057093890402",Eden Creek
"150.3330071158737,  -29.865614969098193",Elcombe
"151.33267895231484,  -33.51461333752924",Ettalong Beach
"153.43759250480866,  -28.690198775470122",Eureka (NSW)
"149.60959781738507,  -32.51710719939779",Eurunderee
"152.98916251257805,  -28.763939772621466",Fairy Hill
"151.81320816753166,  -32.86427656146997",Fern Bay
"150.44498021510196,  -35.230490124315686",Fishermans Paradise
"151.6412665830441,  -32.8719455675844",Fletcher (NSW)
"151.18017520674198,  -33.881558677105424",Forest Lodge
"152.5326461485207,  -32.220856862563515",Forster (NSW)
"148.86671203263745,  -34.246109694759916",Frogmore
"151.96080718908746,  -32.017838183637515",Gloucester
"151.15752758069715,  -33.378843070952215",Greengrove
"149.91177149863194,  -34.60011784338162",Greenwich Park
"150.22020725683265,  -35.829354358717886",Guerilla Bay
"147.91086713730695,  -33.126161130182524",Gunningbland
"150.98232751243285,  -34.18273404440071",Helensburgh
"150.90014590441683,  -33.173544975330614",Higher Macdonald
"151.30104251274392,  -33.492316280404644",Horsfield Bay
"153.30258475087382,  -28.79125486291189",Howards Grass
"146.6249500846666,  -35.95592319857699",Howlong
"152.59336472985214,  -28.677776924211795",Joes Box
"152.69308641074383,  -31.636643911442135",Kendall
"151.22074502110942,  -33.91341566677018",Kensington (NSW)
"152.7988833937451,  -29.16050219224077",Kippenduff
"150.617175466707,  -33.51734728790619",Kurrajong Heights
"151.24108699105992,  -33.992004877301426",La Perouse
"151.79561968685817,  -29.81401192373085",Lambs Valley (Glen Innes Severn - NSW)
"152.97909772604723,  -29.8810352986824",Lanitza
"150.96367100150667,  -33.90135722721537",Lansvale
"150.94882874146114,  -33.425831054754674",Leets Vale
"153.17616710826732,  -28.772228042217385",Leycester
"153.1587117646393,  -28.534695416361462",Lillian Rock
"152.70184918194366,  -31.61029029027621",Logans Crossing
"150.897452827645,  -34.011205370779386",Long Point (Campbelltown - NSW)
"149.1623790530143,  -33.35026713729608",Lucknow (NSW)
"149.73082481919425,  -35.582645251799484",Majors Creek (NSW)
"150.63732508051348,  -34.19861060817344",Maldon (NSW)
"147.2511416016936,  -35.40122705429205",Mangoplah
"151.12570210598122,  -33.350751467008635",Mangrove Creek
"150.73500718059935,  -30.73479069789691",Manilla
"148.19117732566713,  -35.91394441828789",Maragle
"150.23172039775702,  -30.48584706931848",Maules Creek
"153.55504225032797,  -28.65925067212238",McLeods Shoot
"151.08872174805884,  -33.81669135324022",Meadowbank (NSW)
"151.0047942878294,  -34.00559884919491",Menai
"153.3117712812813,  -29.4500834080186",Micalo Island
"152.07989968925952,  -29.92334220138326",Moggs Swamp
"145.99264544270676,  -33.60393879672726",Monia Gap
"152.70515454226242,  -31.039004394539194",Mooneba
"151.20426210495765,  -33.52459048302421",Mooney Mooney
"151.6321064420939,  -32.73020496547803",Morpeth
"149.1756270622691,  -32.83257815337698",Mount Aquila
"149.20345466015794,  -36.65264210942356",Mount Cooper (NSW)
"149.99242515235767,  -33.46281471754788",Mount Lambie
"150.81007320392948,  -33.86033274330921",Mount Vernon
"147.8263334092883,  -35.84842865700855",Munderoo
"149.68733396582996,  -36.85126822258487",Myrtle Mountain
"151.54108530641955,  -33.065643424703275",Myuna Bay
"151.33518111009508,  -33.39587909687406",Narara
"149.00821040383767,  -33.30277661929888",Nashdale
"148.5624948854291,  -30.891181352196526",Nebea
"146.936617279582,  -36.060192441721185",North Albury
"149.45402987657405,  -37.28027057429074",Nungatta South
"150.98389157758913,  -33.86425922503907",Old Guildford
"152.42915529065075,  -28.695167638326016",Paddys Flat (Kyogle - NSW)
"150.11176960080869,  -34.62568267811988",Paddys River (Wingecarribee - NSW)
"149.11738810072364,  -36.82851730452379",Palarang
"146.7678789643097,  -32.010847458395766",Pangee
"150.54688947678392,  -34.96674729500958",Parma
"149.54983065241143,  -33.496043761235946",Perthville
"151.63220369984452,  -32.712654988715265",Phoenix Park
"151.06624877107896,  -31.14607845134556",Piallamore
"149.91373375121498,  -32.773745806590924",Pinnacle Swamp
"150.90528738754782,  -33.86317525406817",Prairiewood
"147.3827597957429,  -35.48138703095723",Pulletop
"146.33501804306528,  -35.92554752212448",Redlands
"151.33538186453956,  -32.46565461144633",Reedy Creek (NSW)
"149.53286803061712,  -33.42287652220162",Robin Hill
"149.20974426382801,  -36.38787270546113",Rock Flat
"151.86895914993622,  -31.889583613498104",Rookhurst
"151.18196347659799,  -33.78312525763534",Roseville
"151.1405267697678,  -33.99789109650114",Sandringham (NSW)
"151.1343568782848,  -33.99384147659491",Sans Souci
"151.35199687120493,  -33.47566490167849",Saratoga
"153.09522662534047,  -30.36862406863089",Sawtell
"150.89163344395763,  -33.60082546962661",Scheyville
"153.58936517888063,  -28.83278381830448",Skennars Head
"150.7719416569774,  -33.76099190828441",St Marys (NSW)
"153.4187512193204,  -28.4066562085906",Stokers Siding
"149.5709861132416,  -32.275841410904825",Stubbo
"151.50053952813911,  -32.47856090350726",Summer Hill (Dungog - NSW)
"152.44450598129708,  -31.889446534994974",Taree
"151.5348853507445,  -32.72802262737661",Telarah
"151.11695940441368,  -33.831618077467645",Tennyson Point
"149.96939200756006,  -35.25351771282168",Tomboye
"152.33999945246,  -32.358597383946766",Topi Topi
"152.0218514847926,  -31.985719048721997",Tugrabakh
"153.1104259312248,  -29.38618625340718",Tullymorgan
"153.16140826984935,  -29.568793781931525",Tyndale
"148.82069617657083,  -35.25264846884913",Uriarra
"150.18163628738682,  -33.48651139682785",Vale Of Clwydd
"152.95395095608313,  -30.58093739837079",Valla
"149.76968637476602,  -33.454126931357585",Walang
"150.0500832572982,  -33.42047424440525",Wallerawang
"151.4434060029675,  -33.41573852057506",Wamberal
"151.72393450116604,  -32.90493146312038",Waratah (NSW)
"152.22032048795697,  -31.79141259403228",Wherrol Flat
"151.5314445923002,  -33.115670515037856",Windermere Park
"150.91873426019268,  -31.90148712346771",Wingen
"151.8444485476359,  -31.81030226145092",Woko
"149.97935334858718,  -32.364612008277184",Wollar
"148.3365729626325,  -35.22429758669167",Wyangle
"151.33356992041823,  -33.256169439441074",Wyong Creek
"147.64888150031246,  -35.72014221443793",Yarara
"151.39421753823575,  -29.38261408058998",Yellow Dam
"150.90337025007074,  -32.999279921184666",Yengo National Park
"145.80934810708754,  -36.96202416986756",Ancona
"147.46744636786266,  -37.016306633895304",Anglers Rest
"145.20680356718208,  -37.564375056732764",Arthurs Creek
"144.33522951171201,  -36.70916004400635",Ascot (Greater Bendigo - Vic.)
"145.08032719837112,  -37.867404047906284",Ashburton
"141.82544602875967,  -37.22236289345088",Balmoral (Vic.)
"143.15045357619428,  -36.71067527754519",Beazleys Bridge
"146.2712358180178,  -38.66659582382252",Bennison
"147.66408258037066,  -36.20032204879507",Berringama
"148.64791021547035,  -37.16711423804865",Bonang
"143.95988806013173,  -36.6387640723168",Bridgewater (Vic.)
"142.19743214333653,  -37.04746261945662",Brimpaen
"144.0055948366986,  -37.51319543705757",Bullarook
"143.95162330958348,  -37.20729061095047",Campbelltown (Vic.)
"145.02492841606445,  -37.8946549225757",Caulfield South
"143.1992933298383,  -38.20178682702548",Chocolyn
"145.48744857228442,  -37.60515704646788",Chum Creek
"144.80331485956052,  -37.48724213024192",Clarkefield
"144.6464360241906,  -37.26839801616155",Cobaw
"141.96797775858258,  -38.25164314040695",Codrington (Vic.)
"141.46785800614336,  -37.19433854057843",Connewirricoo
"143.30997705606453,  -36.461323853250896",Coonooer Bridge
"143.0581461912406,  -36.15291129444374",Corack East
"145.6333280609116,  -36.41033269012405",Cosgrove South
"143.89362751265568,  -37.144078085444136",Cotswold
"143.87616165470072,  -37.36120768572881",Creswick North
"145.27766378935402,  -37.8152241891762",Croydon South
"141.57647267344154,  -37.23782781815103",Culla
"144.28414667602664,  -35.82327645082641",Daltons Bridge
"141.2890878821798,  -37.87186411021725",Dartmoor (Vic.)
"144.77613001092013,  -37.79969759075594",Derrimut
"147.88557552052814,  -37.59525343889161",Double Bridges
"141.63155058480402,  -38.2920510849932",Dutton Way
"144.7585841390707,  -36.17029892485475",Echuca
"146.5538946691096,  -36.32498953431033",Eldorado
"143.58001709883416,  -38.37111147390909",Elliminyt
"144.9003966095872,  -37.72688254201441",Essendon Fields
"143.6995300348348,  -38.51001808952514",Forrest (Vic.)
"141.9536461017801,  -37.382006536925736",Gatum
"143.2271807671091,  -38.71280905495243",Gellibrand Lower
"145.63880348626597,  -37.94496112954059",Gembrook
"143.87255026852816,  -37.21475165753247",Glengower
"144.26582175043131,  -36.79186155156382",Golden Gully
"145.95122103664178,  -36.41441667771052",Goorambat
"143.59551590592005,  -37.72759139155989",Happy Valley (Golden Plains - Vic.)
"142.67937925768157,  -37.98312094674385",Hexham (Vic.)
"141.64694494546072,  -38.13437848653451",Heywood
"146.7488891978231,  -38.26488235606472",Hiamdale
"145.19829004524144,  -38.381117989376826",Hmas Cerberus
"143.85914658963944,  -37.52926215332508",Invermay Park
"146.41586249057866,  -37.995106795053864",Jacob Creek
"145.404848776255,  -37.9004597598782",Kallista
"146.40440733702061,  -36.69483950307605",King Valley
"145.6520914878106,  -37.16330229943789",Koriella
"143.81770122151198,  -35.59433414658544",Lake Charm
"145.96767340196243,  -37.18265934600401",Lake Eildon
"144.28974901840368,  -35.91308863583227",Leitchville
"145.8756014656526,  -38.54532669712478",Leongatha South
"143.51631437848593,  -37.25822266004721",Lexton
"143.59644878641132,  -37.16816436827878",Lillicur
"146.991475941503,  -37.95749290353142",Maffra (Vic.)
"145.0654154605363,  -37.87661676789517",Malvern East
"143.74439505508565,  -37.04763378128943",Maryborough (Vic.)
"144.93283816305686,  -38.35574348862816",McCrae
"147.51191614823597,  -37.73513124099729",Melwood
"144.43569912361608,  -37.09050193427306",Metcalfe
"143.92403064097556,  -37.06460470684628",Moolort
"147.818099567156,  -37.74236801490103",Mossiface
"147.22015614201086,  -37.90510489335375",Munro
"146.68081429446457,  -36.44325875986965",Murmungee
"144.2002898119931,  -37.36747402483055",Musk
"144.60512735087786,  -36.62957279526187",Myola (Vic.)
"143.09801771045554,  -36.905500654956484",Navarre
"145.96549753665133,  -37.92374555642165",Neerim Junction
"146.89578675180348,  -37.896507072333726",Newry
"144.2803090164049,  -36.73760602862336",North Bendigo
"143.14058030538175,  -35.85768740521817",Nullawil
"143.2237777243467,  -35.22157169730665",Nyrraby
"142.10494470308694,  -38.19493374482311",Orford (Vic.)
"145.7712580091622,  -38.52877072670762",Outtrim
"146.78451273517527,  -36.60437126642152",Ovens
"145.4851576647516,  -38.073559492825304",Pakenham
"144.3778666216253,  -37.65643782724562",Pentland Hills
"143.4788166886001,  -37.67461734545827",Pittong
"144.52590205867654,  -38.02413380127356",Point Wilson
"146.92080318993737,  -36.68224170737126",Porepunkah
"143.86775393067666,  -36.4714147121195",Powlett Plains
"142.42146986974186,  -35.83345388792902",Rosebery (Vic.)
"144.81218078568904,  -38.38526313784465",Rye
"144.3229431866824,  -36.902522601735626",Sedgwick
"143.9221782206136,  -37.400023539085325",Smokeytown
"144.96232957844254,  -37.825867134331816",Southbank
"143.22674480655868,  -37.5919060952376",Stoneleigh (Vic.)
"145.23067028988063,  -37.247531284374446",Strath Creek
"145.26669596192613,  -38.49512034487434",Sunset Strip (Vic.)
"141.64953644452308,  -37.68849138915216",Tahara Bridge
"144.1912507255843,  -37.177802095743466",Tarilta
"146.156270262054,  -36.76304750278124",Tatong
"146.33747558715382,  -38.60125533568511",Toora North
"143.48671132547105,  -37.44449896874717",Trawalla
"145.6280061622026,  -38.02809321598828",Tynong North
"147.0954047364683,  -36.51996154271186",Upper Gundowring
"145.65981099563686,  -38.1378484837898",Vervale
"144.29961922073673,  -38.17762670679962",Wandana Heights
"143.71522740469018,  -38.31850081730018",Warncoort
"145.91052798670802,  -38.21442489685442",Warragul South
"143.438327016558,  -37.357057453215646",Waterloo (Vic.)
"143.75161252756345,  -35.48284388424071",Winlaton
"146.09800340047,  -36.521312709779046",Winton (Vic.)
"148.10863935593338,  -37.224986320442085",Wulgulmerang West
"145.42416461730792,  -36.16746860206419",Wunghnu
"152.3525957693902,  -25.21039304915503",Abington (Qld)
"153.00088933876043,  -27.425367529526024",Alderley
"153.10403075122258,  -26.67074201734211",Alexandra Headland
"148.18245234687151,  -26.53792025502678",Amby
"153.45320543547672,  -27.39965048559065",Amity
"152.69011482567834,  -27.88100761010089",Anthony
"151.97938868112598,  -27.49980258157141",Ballard
"145.98998766639673,  -17.552138835325103",Bamboo Creek
"150.74193056981807,  -23.107886271433998",Barlows Hill
"145.72587670262763,  -16.962267636112813",Bayview Heights
"152.81647958842004,  -25.521027381681133",Beaver Rock
"145.72217624920762,  -17.008301023692052",Bentley Park
"150.2912178980692,  -28.152363972547132",Billa Billa
"152.2666988237252,  -26.179155480814178",Black Snake
"153.35294333119097,  -28.139294857284863",Bonogin
"151.90395840440647,  -25.893801704864785",Booubyjan
"152.08497279086558,  -24.998527139514028",Bungadoo
"145.75701272444587,  -16.93723167493193",Bungalow
"148.11579029649286,  -21.632895169361504",Burton (Qld)
"150.93398013840408,  -28.27347425404845",Bybera
"146.0034187688455,  -20.593250394143492",Campaspe
"151.8519728431365,  -27.51662992305374",Charlton (Qld)
"152.2655114204001,  -25.23866687959138",Childers (Qld)
"146.9228277729606,  -26.090208463125197",Clara Creek
"152.86453396757895,  -27.326990716483856",Closeburn
"151.2915777609122,  -27.67857609857511",Condamine Plains
"153.00342272087838,  -26.35231913702982",Cooroibah
"152.90168529425364,  -26.429185874029333",Cooroy
"148.29994411884118,  -21.94742417049151",Coppabella (Qld)
"146.05409433624814,  -17.67994208045043",Cowley
"153.12258434405015,  -26.76948059983975",Currimundi
"152.83257962372673,  -27.596938333435556",Dinmore
"152.70474655908043,  -27.37020232379186",England Creek
"153.1191271863143,  -25.512504173446164",Eurong
"150.93349212031555,  -26.522183935093185",Fairyland
"152.77528131829814,  -27.65129969476652",Flinders View
"153.0122775075476,  -26.675944368197975",Forest Glen (Qld)
"151.2490234585979,  -23.842740026341236",Gladstone Central
"152.39647683552133,  -25.913074615299717",Glen Echo
"151.89263906099924,  -27.566472679039684",Glenvale
"150.3571638050946,  -28.39796740126587",Goondiwindi
"145.81617335243808,  -17.744951885887993",Gulngai
"152.5211789501599,  -25.836160130037317",Gundiah
"148.58048366929313,  -20.38231330514323",Hamilton Plains
"150.84463654229725,  -25.844021894838956",Hawkwood
"151.94616175444324,  -27.675039459747",Hodgson Vale
"144.34739092920387,  -28.80451352050656",Hungerford (Qld)
"146.0261521794931,  -17.522197001495716",Innisfail
"146.04552804922784,  -17.516122169668428",Innisfail Estate
"151.51657865629178,  -27.632100610689765",Irongate
"152.31320832243875,  -27.062712590315705",Ivory Creek
"152.93103509059625,  -27.221103494409398",Kurwongbah
"144.2061853138293,  -14.87253945353236",Lakefield
"146.39730332931913,  -27.825015605452396",Linden (Qld)
"147.43682896225326,  -24.012022401729336",Lochington
"145.38718228009856,  -16.28594793462863",Lower Daintree
"152.53602589605052,  -27.721486503699996",Lower Mount Walker
"146.30880657044713,  -18.536382417617595",Lucinda
"151.07261073498762,  -27.02830609362244",Macalister
"145.65588939973802,  -16.777948565159576",Macalister Range
"152.86017062800312,  -26.630212129911822",Mapleton
"148.9513796784206,  -21.170306160169808",Marian
"152.64053673482005,  -26.261411651357694",McIntosh Creek
"153.42151604996408,  -28.049824804088644",Mermaid Waters
"146.2805481562973,  -20.09245246514124",Millchester
"152.91038560753958,  -27.054634413175414",Moodlu
"149.01952499372166,  -26.342565121198906",Mooga
"153.1138406067114,  -26.683692466772666",Mooloolaba
"151.96213654404494,  -25.19118236319016",Morganville
"152.3893346512834,  -27.322960822326433",Mount Hallen
"148.20618969305008,  -22.89259758414281",Mount Macarthur
"152.2018216685786,  -26.586009519283266",Mount Stanley
"152.5007676886922,  -27.773765686232892",Mount Walker West
"151.34183990460153,  -25.5197257614635",Mundowran
"152.0376375811015,  -27.461894528458792",Murphys Creek (Qld)
"146.84146076966533,  -27.99635541146322",Nebine
"151.23580353791587,  -23.884036059652434",New Auckland
"148.9493711853767,  -21.232015600755727",North Eton
"152.87029697554274,  -26.725633915364398",North Maleny
"152.29573559676905,  -24.850004643988427",Oakwood (Qld)
"149.67978128379085,  -22.643754556058884",Ogmore
"152.97599488620767,  -27.56219828236717",Oxley (Qld)
"145.80189325090237,  -17.021534671213118",Packers Camp
"153.03911065907081,  -27.727663657849902",Park Ridge South
"153.07086867842293,  -26.494879644188366",Peregian Springs
"145.9514803585176,  -17.564132766553318",Pin Gin Hill
"150.7212369569419,  -24.9764668028387",Rawbelle
"149.14053563820707,  -21.086921135335256",Richmond (Mackay - Qld)
"152.8578971186367,  -27.258769846488118",Samsonvale
"148.92534324400089,  -20.92669921468994",Seaforth (Qld)
"152.95719323380084,  -27.54828111326918",Seventeen Mile Rocks
"151.24331443805482,  -24.9484928339225",Splinter Creek
"152.0632542211843,  -27.65862977087937",Stockyard (Lockyer Valley - Qld)
"151.66113210117078,  -28.85675467857117",Sundown (Southern Downs - Qld)
"151.49316229922965,  -28.501082641630333",Terrica
"150.5333977938938,  -23.381482456407333",The Common
"148.57270116698874,  -20.551324409098356",Thoopara
"153.12770328697013,  -27.473655176836306",Tingalpa
"152.68630350731596,  -25.25966438420594",Toogoom
"149.25498568104788,  -26.43288210727929",Wallumbilla North
"152.62093478805392,  -26.185110208717003",Widgee Crossing North
"149.31557450108912,  -24.012388937899313",Wooroona
"146.81800240211166,  -19.3279879845212",Wulguru
"147.57454637778113,  -19.77678122376808",Wunjunga
"137.70054115031203,  -34.14258030863441",Agery
"137.88019925989232,  -35.76986444678781",American Beach
"139.00260303639217,  -34.3037371605807",Bagot Well
"139.48114454721488,  -34.694377793617626",Black Hill (SA)
"139.31711819604573,  -34.91378788583924",Bolto
"140.073671170508,  -34.391290421728804",Boolgun
"139.77893454037425,  -34.09059417537161",Cadell
"134.23439593584183,  -32.22506955436656",Chinbingina
"134.82017758848465,  -28.89272256088807",Coober Pedy
"138.42192048941746,  -30.566445793894626",Copley (SA)
"138.5791549035224,  -34.880083226116234",Dudley Park (SA)
"138.8360631640057,  -32.68638151388373",Erskine (SA)
"138.89564836434198,  -34.53331537719402",Gomersal
"138.82141802581975,  -35.53290754752746",Goolwa South
"138.90147565045982,  -34.22413542511713",Hamilton (SA)
"137.5996025257363,  -34.083148176494255",Hamley
"138.66568703147013,  -34.89304309013465",Hectorville
"138.75239258323302,  -34.5779724222283",Hewett
"138.65278618303304,  -33.697318960273975",Hilltown
"137.71452901157025,  -33.97701137094347",Jerusalem
"134.74936745040512,  -32.2972461369008",Koolgera
"139.2429848902136,  -32.214668864739764",Koonamore
"135.6358491544597,  -33.124409406980014",Kyancutta
"139.83549935741414,  -28.983126050317896",Lindon
"138.7217764359759,  -35.04315425280747",Longwood (SA)
"138.64420451877768,  -33.03881196975827",Mannanarie
"139.5642399521218,  -35.62840915047407",Meningie East
"138.589578609356,  -34.95725328494151",Millswood
"135.04181200163447,  -33.139186115029894",Mount Damper
"139.83288596264498,  -34.5072125759471",Naidia
"137.1176639281747,  -35.86953306635263",Newland
"138.99265664463042,  -34.05701228153283",Ngapala
"138.54947159179545,  -34.955736646082656",North Plympton
"138.56567947322836,  -35.136262063915424",Onkaparinga Hills
"135.87979263283816,  -30.559945759432892",Parakylia
"137.94303266200973,  -35.72850138228336",Penneshaw
"138.55491798287932,  -34.962022677385804",Plympton
"138.47737847609628,  -35.149536938212975",Port Noarlunga
"138.05603681413612,  -32.36003710847271",Quorn
"140.69724046612328,  -34.16350754262371",Renmark West
"138.64186416349497,  -34.764026107892334",Salisbury (SA)
"139.39249428834023,  -34.44404646912145",Sandleton
"138.49645739336137,  -35.19792219310294",Seaford Heights
"138.62696196755934,  -33.89592270494785",Sevenhill
"140.78841837679462,  -37.640749749351535",Tarpeena
"139.56205070352203,  -34.89228886084626",Teal Flat
"137.92070932109647,  -33.943748969335736",Thomas Plain
"136.57820097546602,  -32.78855176654882",Uno
"138.04524481517225,  -33.40364790939925",Wandearah East
"138.67842140376067,  -34.92724342780635",Wattle Park
"140.72001146729818,  -37.62381602355383",Wepar
"135.84536239076357,  -34.5044501228431",Whites Flat
"137.53973567809544,  -33.027517445546096",Whyalla Norrie
"137.51915204443517,  -33.032859938523565",Whyalla Stuart
"137.90697119388238,  -32.62223567817758",Winninowie
"138.25971201846772,  -35.50042827513189",Wirrina Cove
"118.11331628233239,  -32.03818174946069",Ardath
"117.31335899849584,  -30.77592889117699",Badgerin Rock
"115.9088049723872,  -34.3630012720221",Beedelup
"115.71347095694411,  -32.71515189474095",Birchmont
"121.45869270078855,  -30.792055786604006",Broadwood
"116.9249614396425,  -31.75102606493072",Caljie
"117.87947516653232,  -35.011795157383446",Centennial Park (WA)
"116.10888233747494,  -34.45519276826351",Collins
"115.85384453256262,  -31.913919474604462",Coolbinia
"116.44134457194605,  -31.410841961900616",Culham
"123.07235502314784,  -30.669269295471192",Cundeelee
"117.78273459329759,  -35.021309190207305",Cuthbert
"116.67815369157024,  -20.52900390253818",Dampier Archipelago
"115.72552798385252,  -33.393245741092784",Dardanup West
"119.27680494385348,  -33.10735243514605",East Newdegate
"119.01248971668103,  -34.210326607429764",Gairdner
"115.20191033344562,  -25.050653928505945",Gascoyne Junction
"115.38451707645949,  -33.63929761949445",Geographe
"118.00354652032249,  -33.93821922465326",Gnowangerup
"113.69395084741798,  -24.887655293994374",Greys Plain
"116.77506868362292,  -31.971613457775202",Gwambygine
"114.38684115584125,  -26.48464358509848",Hamelin Pool
"121.41759139997949,  -30.440760991571324",Kanowna
"115.8811508728349,  -32.00858453661254",Karawara
"121.42646951265604,  -30.731041573356634",Karlkurla
"116.06636155844029,  -32.25411803100231",Karrakup
"115.88397373831384,  -31.98561887327901",Kensington (WA)
"121.9991474498269,  -30.369385396355735",Kurnalpi
"118.95624186620968,  -32.90354788538054",Lake Biddy
"115.73499614106073,  -33.30630770336105",Millbridge
"116.02104683492892,  -32.16383171615994",Mount Richon
"115.85350202843048,  -33.94473231780013",Nannup
"115.76898277520222,  -31.684279907642285",Neerabup
"116.80269432732534,  -20.739578523670467",Nickol
"118.46619131886294,  -33.019364797702536",North Lake Grace
"114.72907006467052,  -28.147291022504024",Ogilvie
"115.6232092086164,  -31.00449744092422",Orange Springs
"116.33568219701766,  -21.634836982629892",Pannawonica
"115.7515571464155,  -32.37340591093868",Port Kennedy
"116.50031428573037,  -33.02609232804304",Quindanning
"117.9133299736505,  -35.0126149064905",Seppings
"115.75725865990282,  -32.44401123599002",Singleton (WA)
"118.14703983220512,  -33.2334347371809",South Kukerin
"115.79228184131101,  -32.6038941178088",South Yunderup
"115.9474873484613,  -33.86795940731616",Southampton (WA)
"114.99368710988556,  -22.071395669250148",Talandji
"115.95497138251353,  -32.06001089071753",Thornlie
"125.46564019850005,  -26.00362824149995",Tjirrkarli
"115.67499088553271,  -33.31983270653275",Vittoria (WA)
"115.88662426278376,  -32.01576020152038",Waterford (WA)
"115.83373271704686,  -31.93781855098925",West Leederville
"114.29869234949592,  -24.261876564463588",Yandoo Creek
"147.23443228958092,  -42.6079818586298",Bagdad
"146.77740626371974,  -41.201390885679864",Beaconsfield (Tas.)
"148.25493061580943,  -41.794137720007136",Douglas River
"145.67938488250556,  -41.17687836184881",Henrietta
"145.47648656541844,  -41.08862034799298",Meunna
"145.49888086715114,  -41.030791265194665",Milabena
"147.89691265907274,  -41.136047269255606",Moorina (Tas.)
"147.3256294921125,  -42.92166583824496",Mount Nelson
"147.9086663419063,  -42.94369039327894",Murdunna
"147.57061177281813,  -41.09757972534615",North Scottsdale
"147.49628483657122,  -42.88599595163698",Roches Beach
"146.53745859267488,  -41.3051623142305",Sassafras (Tas.)
"146.10966674894163,  -42.75422315908222",Strathgordon
"147.1822848704576,  -41.19271790842366",Tunnel
"146.75496764053713,  -42.15041914574334",Waddamana
"147.4498430594039,  -41.192311594465195",West Scottsdale
"146.16621216209796,  -41.38836278996781",Wilmot
"137.20078422609046,  -16.92009192764904",Calvert (NT)
"136.7747246254471,  -21.000561644302923",Costello
"130.97537137898897,  -12.485982814605029",Driver
"133.56386548928316,  -13.537719487205614",Gulung Mardrulk
"131.00556852478888,  -12.44850302784725",Holtze
"131.35738507496856,  -13.19942953173866",Margaret River (NT)
"137.29871401686734,  -18.605711378587632",Nicholson (NT)
"136.8256377825309,  -15.673197912841976",Pellew Islands
"131.12148529481811,  -13.386489824359028",Robin Falls
"136.98269288371145,  -16.758491719868495",Robinson River
"131.06348564881083,  -12.068946007380436",Vernon Islands
"134.91875021100827,  -14.43556788297117",Wilton (NT)
"149.135679445323,  -35.27083274942582",Braddon
"149.088136557935,  -35.22506289095065",Lawson (ACT)
"149.1350205021127,  -35.29590630180805",Parkes (ACT)
"149.16175036711226,  -35.1889597932053",Throsby
"151.19287794516012,  -33.90984916607828",Alexandria
"147.13349580028998,  -34.093972686246644",Alleena
"151.16706132066446,  -29.311839058913886",Ashford (NSW)
"149.51711796556353,  -36.059063483726",Badja
"152.66712747052426,  -31.511746099484803",Bago
"148.93328684352915,  -32.77153828891745",Bakers Swamp
"149.57095320282303,  -34.048435673310294",Ballyroe
"152.93415361873352,  -29.3720259923186",Banyabba
"149.26681987404388,  -33.64701115688637",Barry (Blayney - NSW)
"152.96552913611507,  -31.091289172397218",Belmore River
"149.56347405878657,  -36.60521237967682",Bemboka
"149.6797908441209,  -35.54085339128222",Bendoura
"150.82168922086143,  -32.28650503340098",Bengalla
"151.1356731881784,  -33.60387642425548",Berowra Heights
"145.82770239106173,  -35.645685590882294",Berrigan
"151.3280630720381,  -33.64410041834243",Bilgola Beach
"153.05536718644638,  -30.334602817587324",Boambee
"149.73079810880398,  -32.80091950150408",Bocoble
"152.8183241509624,  -31.585502656119836",Bonny Hills
"150.8403603870102,  -34.01647991428203",Bow Bowing
"151.6903495303859,  -32.69624882797604",Brandy Hill
"150.00361549470202,  -34.62781964787575",Brayton
"151.18561694242618,  -32.46033188353625",Bridgman
"153.5062622771528,  -28.743081690310493",Brooklet
"149.98647448487338,  -35.670429943984544",Buckenbowra
"152.17883684643678,  -32.384234983847946",Bulahdelah
"153.1358604078848,  -28.768169567804303",Bungabbee
"147.2359077144637,  -33.489740466329444",Burcher
"147.35307905372937,  -35.40954798736774",Burrandana
"151.13819409951282,  -34.06111369087944",Burraneer
"152.05954780887285,  -31.786708069859507",Caffreys Flat
"152.0057692598449,  -31.85784319677625",Callaghans Creek
"151.03012468721002,  -33.81857396565536",Camellia
"151.7662699448913,  -32.91188374471169",Carrington (Newcastle - NSW)
"151.1171918940193,  -33.98672011411706",Carss Park
"149.9033455156362,  -32.840860273061054",Carwell
"151.21494646240959,  -33.786219852317124",Castle Cove
"151.23372239942518,  -33.89790995935729",Centennial Park (NSW)
"151.78348240158132,  -32.587642034996314",Clarence Town
"153.47833507793948,  -28.17365780660664",Cobaki Lakes
"143.88731128074397,  -35.44138378487942",Cobramunga
"145.85833597532076,  -34.79249838678183",Coleambally
"149.75984205810033,  -31.792687375934538",Coolah
"148.70049876050922,  -35.65313567928921",Cooleman
"149.27080560585506,  -31.315843327729574",Coonabarabran
"152.79753557714602,  -29.574587244661856",Copmanhurst
"146.50275509919692,  -34.5428685262085",Corbie Hill
"150.7998594381279,  -33.587136926086934",Cornwallis
"149.48737654897934,  -36.181803619682235",Countegany
"149.24791097290966,  -34.20827232922394",Crooked Corner
"151.6481584375058,  -33.004757976042676",Croudace Bay
"150.5574125468995,  -35.19845562739621",Cudmirrah
"150.11466165532076,  -36.16595330670469",Dalmeny
"147.67158694473514,  -32.21797667809726",Dandaloo
"152.68319639924803,  -28.969244746958797",Deep Creek (Kyogle - NSW)
"153.48169627450582,  -28.964616233748693",East Wardell
"151.3284240178473,  -31.779468671704773",Ellerston
"153.51941008109173,  -28.922167621334715",Empire Vale
"147.35071413960787,  -35.07556161256144",Estella
"148.62643799831255,  -36.13614375386428",Eucumbene
"151.59319001656246,  -33.19888625219995",Freemans
"145.75031866169599,  -34.96896268949933",Gala Vale
"149.51780439139716,  -32.442105473536046",Galambine
"147.0185628680174,  -34.80768143684747",Ganmain
"152.57609201242744,  -31.17139551597808",Gearys Flat
"151.6887133533531,  -29.722761354954798",Glen Innes
"147.4157155872577,  -29.248412855384974",Goodooga
"151.184769788448,  -33.829332499560685",Greenwich
"150.67668135302964,  -33.60262581950587",Grose Wold
"150.7335076185038,  -30.175330854759352",Gulf Creek
"150.7187545797729,  -30.24836227747786",Gundamulda
"150.95981602356835,  -33.95066816970288",Hammondville
"152.21958176287757,  -32.62852162147944",Hawks Nest
"150.99790117761756,  -33.82973658007036",Holroyd
"150.93327656058975,  -32.43214499096503",Howick (NSW)
"151.14570141230212,  -33.83057270110812",Hunters Hill
"144.14127726914256,  -32.69963156600393",Ivanhoe (NSW)
"148.84332130786728,  -36.74247822861032",Jimenbuen
"150.81841849462506,  -34.4933238666789",Kanahooka
"151.485247682866,  -33.262563757432325",Kanwal
"152.84780840107962,  -31.062089038288935",Kempsey
"151.37496166724745,  -33.522397469334734",Killcare Heights
"151.0734837184688,  -34.03309366150936",Kirrawee
"152.44042893810627,  -31.985115319769584",Kiwarrak
"147.37677238318165,  -35.140602159320494",Kooringal (NSW)
"151.17096481972067,  -33.20026125642193",Kulnura
"146.32809590519372,  -33.379063813126635",Lake Cargelligo
"152.99810643121543,  -28.96666818592565",Leeville
"152.8927827221698,  -31.3326072048591",Limeburners Creek (Port Macquarie-Hastings - NSW)
"152.4163995632355,  -30.140161237621097",Marengo (NSW)
"151.1820303320805,  -33.940055124062795",Mascot
"151.146467711845,  -32.54556708518684",McDougalls Hill
"151.87879693663476,  -32.74042495837569",Medowie
"150.28390832268906,  -34.491917494457375",Medway
"146.46522771171269,  -34.61498632827331",Merungle Hill
"141.57584934953925,  -29.894277085542857",Milparinka
"150.7623257770471,  -34.06599105381463",Mount Annan
"150.85207482625339,  -34.403193688177275",Mount Keira
"150.6348950474343,  -34.54864782556654",Mount Murray
"151.29200520962758,  -32.21052027433953",Mount Royal
"147.18219185498154,  -35.897268226993916",Mullengandra
"149.98502862423058,  -35.535237062047145",Murrengenburg
"149.12762497066953,  -35.01286970639165",Nanima
"148.92683293905742,  -32.68474112261316",Neurea
"150.25519302621626,  -33.18849422205121",Newnes
"148.67322472124056,  -32.72303267288536",North Yeoval
"147.1731196116342,  -31.544654375724015",Nyngan
"144.8439782678541,  -34.165915726826",One Tree
"149.5730091023996,  -33.46845966784563",Orton Park
"149.3359105275843,  -37.037569393958826",Paddys Flat (Snowy Monaro Regional - NSW)
"150.75453443177867,  -31.806376868766947",Pages River
"151.25157743541132,  -33.867548001802795",Point Piper
"151.58454596929965,  -33.120759834038964",Point Wolstoncroft
"153.14906401717107,  -28.378153518149958",Pumpenbil
"151.10649887591876,  -33.82612413396642",Putney
"150.64991671788616,  -32.90039785949799",Putty
"152.53571049855483,  -32.05698115845476",Red Head
"153.2108657186955,  -29.99992164853133",Red Rock
"150.66286646962564,  -33.771650946199436",Regentville
"151.01871632840596,  -33.96888550864343",Revesby Heights
"152.87050465228626,  -31.39707807793943",Riverside (NSW)
"152.2659811715374,  -28.657201041894847",Rivertree
"152.5427314020222,  -32.006792771320306",Saltwater
"150.96111659942653,  -34.26407463103343",Scarborough (NSW)
"150.79129566057063,  -33.71147166447655",Shanes Park
"148.03906358404828,  -35.39291340861473",Sharps Creek
"152.17473281054873,  -32.72559271490479",Shoal Bay (NSW)
"152.73954463390405,  -30.688835111065046",South Arm (Nambucca Valley - NSW)
"150.69885447005083,  -33.7734335832543",South Penrith
"150.91436287182037,  -31.109940505565504",South Tamworth
"153.50327211639305,  -28.281798380790853",Stotts Creek
"151.9978662055871,  -32.35728640075941",Stroud
"151.56195818672757,  -33.1117839627449",Sunshine (NSW)
"150.8794492521273,  -34.37633402812885",Tarrawanna
"153.3312070235023,  -29.343755023583093",The Freshwater
"152.93184057405747,  -28.47031921554604",The Risk
"149.42513275036345,  -33.43028934531467",The Rocks (Bathurst Regional - NSW)
"148.62201348883406,  -36.0914293743625",Tolbar
"151.68013574369039,  -29.278879977596688",Torrington (NSW)
"149.16231428091777,  -35.4273902678764",Tralee
"150.62320289275814,  -34.64810053068391",Upper Kangaroo Valley
"146.70338878508232,  -35.65254599828348",Walbundrie
"149.33287572578712,  -35.250751844086714",Wamboin
"151.9390626393751,  -32.20781796520168",Wards River
"150.67155319705594,  -31.66962549444201",Warrah
"151.23994538526156,  -33.02348119574918",Watagan
"151.20725061620976,  -33.89989963260063",Waterloo (NSW)
"146.8133159274948,  -29.179252685287455",Weilmoringle
"143.86665177639793,  -35.32891237089009",Wetuppa
"149.61730750647237,  -33.46537016703726",White Rock (NSW)
"150.84594766791716,  -33.54503668045709",Wilberforce (NSW)
"150.47050742265216,  -34.432073374540856",Willow Vale (Wingecarribee - NSW)
"150.63830500719848,  -34.91214641936792",Worrigee
"151.48678609963292,  -33.27176421124471",Wyongah
"143.66776719785452,  -37.39451040563873",Addington
"146.82916850709285,  -37.369038333947174",Arbuckle
"144.4585924067333,  -37.40541501978327",Ashbourne (Vic.)
"144.39313086421149,  -36.65283322564395",Bagshot
"146.96092748209728,  -36.18296525773314",Baranduda
"143.71533209253593,  -38.584345838348895",Barramunga
"144.10188366246055,  -37.307847102181064",Basalt (Vic.)
"145.4236163321219,  -37.99811187669491",Beaconsfield Upper
"141.04304867849226,  -36.84076741770699",Benayeo
"144.00566935371182,  -38.48443374324527",Big Hill (Surf Coast - Vic.)
"144.29810288626268,  -37.49207274286048",Blackwood (Vic.)
"144.77691266969873,  -37.43813756787425",Bolinda
"147.78962569811748,  -37.89931930696548",Boole Poole
"144.0876768757437,  -38.21797508406072",Buckley
"147.33973714254384,  -36.97776046982441",Bundara
"145.11126503412535,  -37.849699938921546",Burwood (Vic.)
"143.95292744860456,  -37.803574895294126",Cargerie
"143.58039321008656,  -36.694486602161234",Cochranes Creek
"143.5919823435204,  -38.313211691847755",Colac
"141.67511541327795,  -37.59059740808509",Coleraine
"145.07133186707048,  -36.41136705104964",Cooma (Vic.)
"144.77180824886426,  -36.678940525666064",Cornella
"143.66602260903394,  -38.03284136458981",Cressy (Vic.)
"146.02101202315035,  -38.17620395936489",Darnum
"144.70416914144155,  -37.73422729473002",Deanside
"142.71043815083834,  -36.99978857858943",Deep Lead
"148.81273575472275,  -37.10941745670422",Delegate River
"144.53508825507404,  -36.35991988773765",Diggora
"141.70736786492688,  -37.040888766727015",Douglas (Vic.)
"142.32837590341737,  -36.76560920748534",Drung
"144.1586669569276,  -37.296708439152525",Dry Diggings
"143.91423544910938,  -36.122295397183265",Durham Ox
"145.11491581735103,  -38.03751892608595",Edithvale
"143.64989221039397,  -37.26599350362497",Evansford
"146.89663128751985,  -36.09454426061047",Gateway Island
"145.83662954203726,  -37.93841433154948",Gentle Annie
"144.9496356007507,  -36.399848652580864",Girgarre
"144.29815790061932,  -37.71539114972208",Glenmore (Vic.)
"144.92668887557704,  -37.70266176997385",Glenroy (Vic.)
"143.44337680662477,  -35.490743769814756",Goschen
"145.5445409266404,  -38.41847948860689",Grantville
"142.88183696586393,  -37.166663719889534",Great Western
"146.47521372610464,  -38.370760750699155",Jeeralang
"141.2645416980536,  -36.386192247626646",Kaniva
"144.45973470629536,  -37.22741306134345",Kyneton
"145.00559813649818,  -36.28170043664948",Kyvalley
"143.95924804896654,  -37.56079462132598",Leigh Creek (Vic.)
"145.96535376645426,  -38.1236341863367",Lillico (Vic.)
"144.14289740644156,  -36.87236563999039",Lockwood South
"143.92429938004742,  -38.53379300149601",Lorne (Vic.)
"143.77581706533053,  -37.54569669423214",Lucas
"144.87419779047465,  -37.78196157517238",Maidstone
"142.88968480166605,  -36.689710502810115",Marnoo
"145.525595672729,  -36.19555303926471",Marungi
"146.1848861709902,  -37.5819344937294",Matlock
"146.44126854330875,  -36.45529390794619",Milawa
"144.06326330089468,  -35.99060933629326",Mincha
"144.92154072648316,  -37.765636735779275",Moonee Ponds
"144.24653902767207,  -37.06289960812099",Moonlight Flat (Mount Alexander - Vic.)
"145.3461375690627,  -36.39878938031484",Mooroopna
"143.87109084901644,  -37.603346978957916",Mount Clear
"145.03077900575116,  -38.275452922852274",Mount Martha
"143.86955746322772,  -37.83763640881925",Mount Mercer
"144.19168099003338,  -38.2126877352495",Mount Moriac
"143.8551546975474,  -37.58111478033145",Mount Pleasant (Vic.)
"142.30138716065755,  -34.47853967504907",Nangiloc
"143.019094060156,  -37.758886379028986",Nerrin Nerrin
"143.72100103292607,  -37.610417485851045",Nintingbool
"145.14170767218232,  -37.90476508415728",Notting Hill
"146.72765929226892,  -36.664615875000806",Nug Nug
"147.95449330847222,  -37.22311771369865",Nunniong
"147.71519720120264,  -37.911410146142806",Paynesville (Vic.)
"142.31980114987238,  -37.870840460189356",Penshurst (Vic.)
"143.33660790339087,  -38.216665697869864",Pomborneit North
"145.00786057872813,  -37.74180552763098",Preston (Vic.)
"145.9148957291775,  -37.670888490199424",Reefton (Vic.)
"144.05367287085218,  -37.436489767991745",Rocklyn
"145.1384733321178,  -38.10476593108847",Seaford (Vic.)
"144.74206725120283,  -38.34471993308141",Sorrento (Vic.)
"143.11431553328774,  -35.57471947411776",Springfield (Buloke - Vic.)
"145.6520004302279,  -38.58140265281964",St Clair (Vic.)
"144.6855995048103,  -38.177080527342056",St Leonards (Vic.)
"144.2234227706776,  -38.12202988882935",Stonehaven
"143.38516865567186,  -37.00522885507456",Tanwood
"144.6647319096626,  -37.835417599055106",Tarneit
"142.015188923322,  -37.130485560697096",Telangatuk East
"143.57311594672888,  -36.163619461577355",Terrappee
"143.37415851532703,  -35.43227094248988",Ultima East
"142.07660643565396,  -36.70130469569385",Vectis
"145.25379917525768,  -36.69930895807642",Wahring
"144.51195362012047,  -38.226342054444856",Wallington
"143.61669890697846,  -36.99561528777589",Wareek
"142.4303028695386,  -36.22516432111615",Warracknabeal
"142.3870326259087,  -37.01501610645368",Wartook
"146.3527366641641,  -37.562290277740324",Woods Point (Vic.)
"145.4112932665912,  -37.27439384501913",Yea
"142.91928724167948,  -36.59293162564705",York Plains (Vic.)
"148.71690264701203,  -20.27226406276795",Airlie Beach
"149.21876253719225,  -21.31829069073865",Alligator Creek (Mackay - Qld)
"146.93216725754797,  -19.41052090073139",Alligator Creek (Townsville - Qld)
"152.6959173871052,  -27.632080951743685",Amberley
"146.78766577824277,  -19.311090457613233",Annandale (Qld)
"153.06528550597335,  -27.429828612547063",Ascot (Brisbane - Qld)
"142.43241732647923,  -10.856065405973462",Bamaga
"152.81617265454477,  -26.192256522905144",Beenaam Valley
"152.96984231017265,  -26.950730522173753",Beerburrum
"153.17998719265532,  -28.201662123755494",Binna Burra (Qld)
"152.68120244594667,  -27.901356512834624",Blantyre
"149.77482210060424,  -26.484412699358806",Bogandilla
"152.01696953767078,  -26.074066022880263",Boonara
"146.2897350167114,  -19.980316327256734",Breddan
"152.97823222454107,  -27.32328687263479",Brendale
"153.43150261432447,  -28.027682851904217",Broadbeach
"153.13281010145494,  -26.69550936043822",Buddina
"149.667244188144,  -28.435210136471955",Bungunya
"153.43217665537804,  -28.087222886104417",Burleigh Waters
"146.04587773115514,  -17.94718912621215",Carmoo
"152.97489736147284,  -27.85909730484933",Cedar Grove
"152.11501958114022,  -26.92845294123558",Cherry Creek
"147.19832136156103,  -19.82463795911221",Clare (Qld)
"148.3776891426719,  -24.690950165956455",Consuelo
"152.20678080786382,  -25.159282023103458",Cordalba
"152.72493547619985,  -25.284973045900372",Craignish
"152.93037357255776,  -26.75569237857028",Diamond Valley
"148.41596611756032,  -20.43001400554837",Dittmer
"145.97991144694845,  -17.864137619355542",Djarawong
"145.54787685204542,  -17.29527479193904",East Barron
"145.99896354662897,  -17.896069562636622",East Feluga
"151.96852845692524,  -27.56229391558123",East Toowoomba
"152.9584457871016,  -26.466833005700842",Eumundi
"151.51916704558883,  -27.532394560139725",Evanslea
"152.8070647169461,  -26.394385854483204",Federal (Qld)
"148.74969574093336,  -20.269905662794773",Flametree
"146.00488883041854,  -17.847371803164503",Friday Pocket
"152.4781540533472,  -27.033155025533226",Fulham (Qld)
"139.78572896139144,  -19.2618930191",Gidya
"151.9731365437026,  -27.178140222387167",Glenaven
"150.4531628664701,  -23.24570500247104",Glendale (Qld)
"149.1372788715566,  -21.112600075135937",Glenella
"149.60558315226805,  -27.231248790681565",Glenmorgan
"152.91494221499747,  -25.496838525160154",Great Sandy Strait
"150.033440426673,  -27.43823159201826",Hannaford
"147.41711347163124,  -28.912526925166073",Hebel
"147.4138004773549,  -19.660925091758862",Home Hill
"146.05620258611552,  -17.9822021337877",Hull Heads
"152.97122262215277,  -27.594703498195674",Inala
"153.06393218774352,  -25.85115772376369",Inskip
"152.00519620951712,  -27.175022035123657",Jones Gully
"145.6758428975537,  -16.78884280482554",Kewarra Beach
"152.25716196760422,  -26.074172859032036",Kilkivan
"145.44544783370685,  -16.260999238634824",Kimberley (Qld)
"150.78715354669012,  -23.22602643706692",Kinka Beach
"150.54880421646573,  -23.368275154886522",Koongal
"149.23280308652045,  -21.63175395596117",Koumala
"145.68210517858145,  -17.055568582472418",Lamb Range
"151.77177926109408,  -26.236737103280632",Leafdale
"150.43656513023348,  -23.662566901509376",Limestone (Qld)
"145.715282244056,  -17.130762173605728",Little Mulgrave
"152.45917854747674,  -26.11645220922633",Lower Wonga
"151.60269895432742,  -27.06320145171516",Malling
"142.15797101806155,  -19.921439480116913",Malpas-Trenton
"151.20202805640602,  -28.01970814500117",Millwood
"146.96743333641996,  -19.501752114930756",Mount Elliot (Qld)
"151.9792534610044,  -27.537637834243814",Mount Lofty
"151.7173851995206,  -26.2339949966394",Mount Mceuen
"151.8467445114345,  -27.89571421687342",Mount Molar
"152.20636460644573,  -28.16930663610014",Mount Sturt
"152.16017416939036,  -27.688011811677022",Mount Whitestone
"152.06725321502066,  -24.5964313321824",Mullett Creek
"152.9309862635323,  -27.18674050410034",Narangba
"153.3120162022591,  -28.114041743034637",Neranwood
"147.88648304223562,  -20.930367099316506",Newlands (Qld)
"152.95730608759325,  -26.511639186617117",North Arm (Qld)
"152.79031925205467,  -27.59904854402187",North Booval
"152.7608959759068,  -27.593882753675995",North Ipswich
"144.49504469216802,  -16.765454764583236",Nychum
"151.270569532691,  -23.927522288845573","[""O'Connell (Qld)""]"
"151.71021440339317,  -27.44693107620671",Oakey
"152.81633369608545,  -26.643583499139208",Obi Obi
"150.09324105646573,  -23.80864647993143",Pheasant Creek (Qld)
"151.22102993232716,  -25.58637227217891",Riverleigh
"151.9968691050062,  -28.182565868221236",Rosehill (Qld)
"152.49552537287337,  -27.859155705906073",Rosevale (Qld)
"145.22725573247718,  -15.803600793088185",Rossville
"149.7450663802572,  -24.796090261885656",Roundstone
"153.38292472069762,  -27.673446417637624",Russell Island
"152.8538110827053,  -25.289038486390247",Scarness
"153.07823250807647,  -27.479765189092262",Seven Hills (Qld)
"150.43860105265622,  -22.6130892335842",Shoalwater (Qld)
"152.94887852102454,  -27.54452190170009",Sinnamon Park
"148.5482861714707,  -21.75587549039153",Strathfield (Qld)
"152.81695858060732,  -27.661935326046198",Swanbank
"153.04760355618987,  -27.456454406388296",Teneriffe
"152.13954532189797,  -27.196704616832225",The Bluff (Toowoomba - Qld)
"145.0371326885132,  -16.96346493027975",Thornborough
"152.351196950594,  -27.79698756045269",Thornton (Qld)
"152.95955967146327,  -25.778468225751645",Tinnanbar
"145.71433645466152,  -17.418050289666",Topaz
"152.87134214410457,  -25.291342742357784",Torquay (Qld)
"152.85120188689177,  -27.473067631109146",Upper Brookfield
"152.91769714062193,  -27.418731070801325",Upper Kedron
"151.798341208404,  -28.017929503700184",Victoria Hill
"148.2478706332951,  -26.35134757800671",Walhallow
"150.36681566100927,  -23.719222343713163",Walmul
"153.12703337899123,  -27.692378947951322",Waterford West
"150.47990659120754,  -23.38329754977527",West Rockhampton
"145.41145624092758,  -16.331920823258557",Wonga Beach
"152.49914202631743,  -25.11675757416476",Woodgate
"143.94968493072813,  -16.642086500995863",Wrotham
"152.5352380045247,  -25.591742039841442",Yerra
"140.03341999073942,  -34.96538175943399",Borrika
"139.34392455401883,  -34.226522880108924",Brownlow
"139.2750844232298,  -33.8810604379466",Bundey
"140.07035067511126,  -35.57587518529557",Carcuma
"135.80410982881708,  -34.54238008371215",Charlton Gully
"138.5956710726188,  -35.02104809046935",Eden Hills
"138.89539998026683,  -35.178688939952615",Gemmells
"138.61059986331546,  -33.87966374504645",Gillentown
"138.60344974980572,  -34.79487869728242",Green Fields
"138.76551678257647,  -33.712856412019505",Gum Creek
"138.6886847951806,  -33.80506042963565",Hill River (SA)
"138.51808281835326,  -35.038868085915674",Kingston Park
"134.974412129911,  -32.170977288350656",Kondoolka
"137.5909253693658,  -34.67315296815668",Koolywurtie
"138.69448537494168,  -35.28440879518404",Kuitpo Colony
"138.49398560650448,  -34.82665495150778",Largs Bay
"138.29693032763706,  -33.16974086148549",Laura (SA)
"138.77507753418277,  -34.79758081335512",Lower Hermitage
"140.38216623729974,  -37.04202624759362",Lucindale
"138.6073898784276,  -34.997322337835534",Lynton
"138.55350601221764,  -35.00635005166435",Marion
"139.58746755427782,  -34.57823667150965",Marks Landing
"138.71006611996364,  -35.48405942429171",Middleton (SA)
"138.80518222626222,  -32.854980280526206",Minvalara
"135.35470513110542,  -34.12341823165631",Mount Hope (SA)
"138.11760545081006,  -33.12269182300144",Nelshaby
"138.65557781024654,  -34.81180900763753",Para Hills
"137.2565279620654,  -35.781710640328",Parndana
"138.61321553857613,  -34.65839630901602",Penfield Gardens
"138.67333134647853,  -33.88350841609722",Polish Hill River
"137.78866134588796,  -32.49561118901339",Port Augusta
"137.68627689588558,  -32.60762319806008",Port Augusta West
"138.00882552439083,  -33.171144284422745",Port Pirie
"137.98716257514613,  -34.30442628094243",Price
"138.5216099820561,  -34.85208558014267",Rosewater
"139.84708890897522,  -36.87510264775428",Sandy Grove
"140.4630479708616,  -34.833113345472725",Schell Well
"138.54620959945566,  -35.03376483611188",Seacombe Heights
"138.1648789141991,  -35.61336573740611",Silverton (SA)
"138.55239372690855,  -34.7488765138651",St Kilda (SA)
"138.6225523588908,  -34.9049459647483",St Peters (SA)
"138.961484985953,  -34.52160274039919",Tanunda
"138.51339357594537,  -32.95458753648531",Tarcowie
"138.70239364690084,  -34.914350428794116",Teringie
"139.1026307968273,  -35.371598476838614",Tolderol
"137.62411640496117,  -34.51759739137854",Urania
"138.3931777255586,  -35.4295966147714",Wattle Flat (SA)
"136.22629781460734,  -33.94981076185358",Wharminda
"138.3360263533905,  -34.09182005025969",Whitwarta
"140.7668357161532,  -36.87200007303174",Wild Dog Valley (SA)
"140.84479132145444,  -37.88157340342449",Yahl
"140.89054000958612,  -34.289496558850956",Yamba (SA)
"138.64663327054245,  -32.93779037592544",Yatina
"116.00690751322242,  -33.77771241824138",Balingup
"116.80138292164963,  -20.751600228723962",Baynton (WA)
"115.946596633334,  -31.856606638177926",Bennett Springs
"117.3777365425044,  -30.906817742391986",Booralaming
"118.26011830758145,  -34.07289640339357",Borden
"120.90706459029273,  -30.995206249928536",Bullabulling
"117.39852634515753,  -33.722314573575105",Carrolup
"115.76590336487023,  -32.12209104421448",Coogee (WA)
"117.1885678125643,  -31.862874547797073",Doodenanning
"117.1207223269912,  -26.384814198810552",East Murchison
"114.11250514189346,  -21.95600572006355",Exmouth
"117.62264173564775,  -33.47183286022808",Glencoe (WA)
"116.05580273874763,  -31.902700076358744",Greenmount (WA)
"116.18672754386442,  -32.73144765683842",Inglehope
"118.1330614330414,  -33.88486865805154",Jackitup
"115.68519138720953,  -31.641751634461293",Jindalee (WA)
"115.80918949466151,  -31.9458389599846",Jolimont
"116.05476095163536,  -31.975658928035624",Kalamunda
"116.19917064616993,  -33.96041273725098",Kangaroo Gully
"115.83171840082402,  -31.962480841493594",Kings Park (WA)
"116.02961735005411,  -31.88624869054742",Midvale
"114.630386775813,  -23.85678852601318",Minilya
"115.90995613357033,  -31.887718922362385",Morley
"116.52714417772816,  -31.942504782283123",Mount Observation (WA)
"114.84150225142497,  -28.394582264225964",Naraling
"114.68215955943867,  -28.821705271195846",Narngulu
"115.83198510513014,  -32.080701887927255",North Lake
"118.12231670809074,  -34.71869852222729",Palmdale (WA)
"119.78342001169355,  -31.84333211101469",Parker Range
"116.13365222563583,  -31.866571966944523",Parkerville
"115.71537686581469,  -33.18410989677844",Parkfield
"128.54606601160577,  -17.448766278826238",Purnululu
"116.11506905459463,  -34.210792034202896",Ringbark
"114.92449681054575,  -28.351429516155715",Rockwell
"117.13030365350545,  -34.10271219617271",Ryansbrook
"115.79770157686649,  -32.06991940150984",Samson
"115.81010868034814,  -33.04926917902709",Uduc
"116.07643137576976,  -31.721918125927832",Walyunga National Park
"115.59595847430614,  -31.33376703289635",Woodridge (WA)
"114.47550987905691,  -25.61479014366749",Wooramel
"116.0129177907079,  -32.18305118200804",Wungong
"116.02987094917012,  -33.51853272014512",Yabberup
"114.32034020920624,  -28.084874801115873",Yallabatharra
"115.86630719391654,  -32.95804514155938",Yarloop
"117.45671184485342,  -32.889220135061315",Yilliminning
"147.2357743287559,  -42.81458654308245",Berriedale
"146.95942980022437,  -43.187388097418726",Cairns Bay
"146.43199281851426,  -41.61744011577788",Caveside
"146.74265452676795,  -41.5144562211019",Exton
"146.1446659047614,  -41.21779946301246",Gawler (Tas.)
"146.6092519634581,  -41.24599359693211",Harford
"147.1615575943573,  -41.47044622459688",Kings Meadows
"147.292310031187,  -42.963227467693656",Kingston (Tas.)
"147.32081706926894,  -42.98465350345773",Kingston Beach
"145.5626096188243,  -41.03112279100672",Lapoinya
"147.56672608685165,  -42.53659587712255",Levendale
"147.1232391130576,  -41.60719816738606",Longford (Tas.)
"146.2215022509543,  -41.32166618084382",Lower Wilmot
"144.1203479243727,  -39.92491532008734",Naracoopa
"144.700528340486,  -41.1335214787113",Nelson Bay (Tas.)
"146.26022004211734,  -41.37234110736645",Nowhere Else
"147.32650379604567,  -41.39899715933997",Nunamara
"147.7271973466296,  -39.65285852382381",Palana
"148.1182280102558,  -40.162770924792994",Ranga
"147.16495851283545,  -41.14050286534565",Retreat (Tas.)
"146.95176624891272,  -42.69944106196381",Rosegarland
"147.3676626455112,  -42.86620647796753",Rosny Park
"146.6889286142632,  -42.79227031167633",Styx
"146.96427389532283,  -41.26148508340986",Swan Point
"145.6147274921029,  -41.73688035671553",Tullah
"147.20755177724678,  -41.425478126752274",Waverley (Tas.)
"147.40405473379715,  -42.18830531263811",Woodbury (Tas.)
"137.83736859810483,  -20.981731787878456",Alpurrurulam
"131.05151928954945,  -12.53860051220132",Freds Pass
"130.98231790901306,  -12.49031482175103",Gray (NT)
"130.99328178070027,  -12.487599795579698",Gunn
"131.1619883791634,  -12.534130438174847",Herbert
"130.89461958058584,  -12.343058212603164",Lee Point
"130.99432506145632,  -12.527853696742424",Mitchell (NT)
"131.01963961208236,  -12.999768191238811",Rum Jungle
"131.03651628540138,  -13.184764149519669",Stapleton
"136.883810869609,  -12.247635238339893",Yirrkala
"149.11769498118375,  -35.402018793825334",Fadden
"149.1049357970144,  -35.37674622141071",Farrer
"148.92467976569304,  -35.29627155175433",Uriarra Village
"149.09670714737854,  -35.30277509857597",Yarralumla
"149.8028703903977,  -32.879029022460784",Aarons Pass
"151.38168735892526,  -32.84108864878373",Aberdare
"150.9137716595368,  -33.73359015988827",Acacia Gardens
"149.70658658173284,  -32.680479756697196",Apple Tree Flat
"153.19557612377213,  -30.068125208220998",Arrawarra Headland
"153.20366340640092,  -29.424232608103907",Ashby Island
"151.54243766083525,  -33.0223733396871",Awaba
"149.94354238445894,  -35.34333269326277",Back Creek (Queanbeyan-Palerang Regional - NSW)
"150.54962131701342,  -30.36049370968426",Barraba
"152.9254007324576,  -30.876301092252437",Barraganyatti
"150.5298954053114,  -34.68857471235516",Barrengarry
"151.67221330182645,  -33.01698834755057",Belmont North
"151.66098429218846,  -33.05333319953428",Belmont South
"153.49353003661867,  -28.710755874970143",Binna Burra (NSW)
"153.1938789382559,  -28.55012020881777",Blue Knob
"153.3553642979124,  -28.782489215324397",Boat Harbour (Lismore - NSW)
"150.64547240743414,  -34.83964018156783",Bolong
"152.27724147340913,  -28.798766490972458",Boorook
"150.62099037207358,  -33.57756334451895",Bowen Mountain
"151.14004201090796,  -31.377429646774864",Bowling Alley Point
"149.83443268144057,  -34.769806227084516",Boxers Creek
"149.97793220849124,  -32.672279663550285",Breakfast Creek
"148.79489571920266,  -31.811572166462298",Breelong
"153.34563339412767,  -29.033416300867408",Buckendoon
"151.53469669484426,  -33.22864337915264",Buff Point
"149.21116700453445,  -36.801410812149",Bukalong
"151.11235310514482,  -29.51257618518944",Bukkulla
"151.47291609021224,  -33.20303741339762",Bushells Ridge
"151.8649024812341,  -32.45123917631323",Cambra
"151.60564274642155,  -33.026304768078525",Carey Bay
"152.92154593529446,  -29.656908379721493",Carrs Creek
"152.90432651056008,  -29.65288480552112",Carrs Peninsula
"151.00757182607794,  -29.561183148167977",Cherry Tree Hill
"151.51861862653988,  -32.787672039784795",Cliftleigh
"151.22537219189712,  -33.52237002537067",Cogra Bay
"147.99427364076658,  -30.676855530201802",Conimbia
"147.9781191879803,  -33.0195105369273",Cooks Myalls
"150.7150386255649,  -34.852913110242916",Coolangatta (NSW)
"152.45507528496518,  -32.237752399301385",Coomba Park
"153.18050176423606,  -30.01554370925225",Corindi Beach
"152.73111183335263,  -31.78799812453709",Crowdy Bay
"149.8865341042185,  -32.40002911066533",Cumbo
"148.83924350270897,  -32.67321428525574",Curra Creek
"149.7874573134556,  -34.24294504345826",Curraweela
"150.90878140719587,  -34.21550555044897",Darkes Forest
"151.36176406702444,  -33.48472234967735",Davistown
"150.57989462885539,  -30.010760748224182",Dinoga
"151.51279593433375,  -33.196274593396",Doyalson
"150.8174412912776,  -31.891442829463674",Dry Creek (NSW)
"151.6836885703778,  -32.73979756147461",Duckenfield
"152.8118521624387,  -31.659736949699013",Dunbogan
"151.16856428731302,  -32.555463933842546",Dunolly (NSW)
"151.1273239040645,  -33.92471435308283",Earlwood
"146.94069218559963,  -36.0891106023322",East Albury
"151.23660177879665,  -33.87955896916286",Edgecliff
"150.85463850371028,  -33.96183078900281",Edmondson Park
"150.6642350880299,  -35.10222617197835",Erowal Bay
"150.81494200402992,  -34.455890525701314",Farmborough Heights
"149.116081159105,  -32.845561405197536",Farnham
"152.84289010845083,  -31.420956024642063",Fernbank Creek
"148.94033389735327,  -33.428203674886156",Four Mile Creek (NSW)
"151.2842742520076,  -33.776093361079276",Freshwater (NSW)
"148.57902214856446,  -30.703482528634247",Gilgooma
"151.65703474032117,  -31.82215061446833",Glen Ward
"148.09524979600903,  -33.73954575223904",Glenelg (NSW)
"150.784646180894,  -33.53207739099027",Glossodia
"150.8052505544441,  -29.550016900297145",Gragin
"150.9255616047122,  -29.793118717647015",Gum Flat
"152.86051938051227,  -31.070603795207216",Hampden Hall
"146.0261040281873,  -34.352874792218074",Hanwood
"145.43716109657734,  -33.43903863523384",Hillston
"151.5069595392144,  -30.512007020710463",Invergowrie
"150.65439115620148,  -34.800092342561356",Jaspers Brush
"149.36608108706858,  -35.89687173699827",Jerangle
"151.69319158616992,  -32.90230964782248",Jesmond
"148.6328580924695,  -36.44333044274989",Jindabyne
"152.59247901709938,  -31.86088959829581",Jones Island
"152.8573151284597,  -29.971741388563547",Kangaroo Creek
"151.39581142878396,  -33.31955887149445",Kangy Angy
"148.14531908035445,  -36.25106589290342",Khancoban
"152.98098739882573,  -28.557431455698186",Kilgra
"153.57336352820423,  -28.262828708694535",Kingscliff
"150.01724636266837,  -35.90355291883822",Kiora
"151.21585168454595,  -33.84772548571115",Kirribilli
"151.12382021965192,  -33.98272644280758",Kogarah Bay
"152.50465548520705,  -31.98711918711804",Koorainghat
"149.67837081171697,  -35.01821613348469",Lake Bathurst
"148.8767258245677,  -32.96260761779716",Larras Lee
"150.13540067911018,  -32.539296706203615",Lee Creek
"152.28537812649046,  -28.46803049330649",Legume
"151.0031737647481,  -32.42771993715348",Liddell
"153.37480645033,  -28.828365033161685",Lindendale
"152.63696687034866,  -28.334241439384225",Lindesay Creek
"152.619285359333,  -29.01555570933593",Louisa Creek
"150.92026157961908,  -33.59085244246328",Maraylya
"150.60612802988905,  -34.813609910937465",Meroo Meadow
"149.28427346624616,  -31.807030454165503",Merrygoen
"151.70080582429082,  -32.761717992417665",Millers Forest
"151.6229464835175,  -32.879253406531326",Minmi
"151.30380814412683,  -33.67595378709295",Mona Vale
"149.81736169421535,  -29.32557751808815",Moree
"147.15482665913459,  -35.6515213274749",Morven (Greater Hume Shire - NSW)
"144.57678472337344,  -33.3105129413629",Mossgiel
"152.5636050192454,  -31.827630850371772",Moto
"152.1980813740687,  -31.365863428951908",Mount Seaview
"149.0820639169475,  -29.01942479993896",Mungindi (NSW)
"149.77101395546936,  -30.219839654281397",Narrabri
"147.32085922509853,  -29.837098953593816",Narran Lake
"149.17243332066352,  -34.41688397881446",Narrawa
"152.32316105816125,  -29.72992880161608",Newton Boyd
"151.20054530590085,  -33.7953492695725",North Willoughby
"150.8732838233889,  -33.622196280055654",Oakville
"150.8846667062317,  -31.05175670400365",Oxley Vale
"142.42011598052943,  -33.42236772427686",Pooncarie
"149.92402558280477,  -31.47383045077854",Premer
"149.38861788781117,  -35.490082600733324",Primrose Valley
"149.5840095684276,  -32.575587830403826",Putta Bucca
"147.7943696420287,  -31.73453433627964",Red Hill (Warren - NSW)
"149.55511315521449,  -33.552435629864696",Rockley Mount
"152.6577540671769,  -31.286599539755397",Rollands Plains
"152.68552773081228,  -31.45762403978052",Rosewood (Port Macquarie-Hastings - NSW)
"153.2730432219733,  -28.928857046618493",Ruthven
"146.68002460609307,  -34.90686492880033",Sandigo
"150.99338280827334,  -33.97594666938508",Sandy Point (NSW)
"150.20374537438818,  -35.11664037656503",Sassafras (NSW)
"151.87046949561275,  -29.694983709271796",Shannon Vale
"151.6697311994146,  -29.157829438885216",Silent Grove
"153.03629953632822,  -30.896032703516564",South West Rocks
"146.26601329986846,  -34.50579829054397",Stanbridge
"153.00164251401608,  -29.669060048064157",Swan Creek (NSW)
"150.22766273548288,  -34.84194430864264",Tallowal
"153.2715167867407,  -29.5493680137383",Taloumbi
"151.59942387253272,  -32.95046511879288",Teralba
"153.29443644402724,  -28.614613032955738",Terania Creek
"153.28990069622526,  -28.439217644923335",Terragon
"150.9133369771853,  -34.31437701318172",Thirroul
"151.0819802344391,  -33.72391238255313",Thornleigh
"141.9844703278196,  -29.36366770283001",Tibooburra
"152.38625673752426,  -31.543917528985762",Toms Creek
"151.49681133401637,  -33.36263765813894",Toowoon Bay
"147.9825421154122,  -35.14292073307468",Tumblong
"149.24587933694738,  -32.51983057038295",Twelve Mile
"150.0099080085979,  -32.64367147687165",Upper Growee
"151.55663160539314,  -32.968975510402444",Wakefield
"143.8851581631659,  -29.58309650864571",Wanaaring
"151.43056586384137,  -33.24921013359444",Warnervale
"150.90810441468142,  -33.35020774485477",Webbs Creek
"148.66254330430093,  -35.135089392473816",Wee Jasper
"150.9862095876829,  -33.80870645933725",Westmead
"147.98365044224778,  -35.3968078144043",Westwood (NSW)
"153.00949648785618,  -29.274049146608235",Whiporie
"145.1076634000283,  -34.96965252298999",Willurah
"150.82112129094068,  -33.6090236142973",Windsor (NSW)
"152.55671278675362,  -31.100077535855725",Wittitrin
"144.5586447641785,  -35.90187857685044",Womboota
"150.6777528998358,  -34.72922079891665",Woodhill (NSW)
"152.50446264875868,  -28.62843627884726",Yabbra
"150.55877885772946,  -34.34764647072516",Yerrinbool
"143.81307707065787,  -37.39646243844491",Ascot (Ballarat - Vic.)
"145.10243866834423,  -38.02189529033005",Aspendale
"144.52703922860988,  -36.767512059464295",Axedale
"148.81480086485482,  -37.63822066075911",Bellbird Creek
"144.6826973285991,  -37.19802926332552",Benloch
"143.90018539562433,  -38.48450521969991",Benwerrin
"142.069703192824,  -34.20099627176441",Birdwoodton
"144.0315807286689,  -37.35486381112149",Blampied
"143.0925426438836,  -38.1165756514667",Bookaar
"143.69819241974912,  -36.9978593366691",Bowenvale
"145.12108267305936,  -37.70631245066191",Briar Hill
"142.07782997489616,  -38.120478233268834",Broadwater (Vic.)
"147.8321539216454,  -37.79095312671946",Bumberrah
"145.01693422108636,  -37.82949999381316",Burnley
"147.0178214524383,  -37.859195747576315",Bushy Park (Vic.)
"141.96759340696048,  -37.94574795403818",Byaduk
"145.164683325696,  -36.41206683354065",Byrneside
"142.79599524392893,  -36.92539973431978",Campbells Bridge
"143.88531443290506,  -37.58445082433874",Canadian
"143.6114810292066,  -37.781130082869716",Cape Clear
"145.6517883145903,  -38.19460180855447",Catani
"142.87664224803999,  -37.30888011450127",Cathcart (Vic.)
"142.05610058834188,  -37.49957038976018",Cavendish
"143.18324940188242,  -35.523569153901676",Chinangin
"142.90531015160641,  -35.11078802471369",Cocamba
"143.07246761357447,  -36.46591293952846",Cope Cope
"145.89401797867072,  -36.84869032934731",Creek Junction
"145.51972383113053,  -36.85271424296521",Creightons Creek
"142.86443303055046,  -38.51853252065045",Curdievale
"142.06114640608948,  -36.38757664396387",Dimboola
"141.49386950087336,  -38.098035322621314",Drumborg
"146.17796248672664,  -38.51227108815428",Dumbalk North
"143.89739651074015,  -37.7007678180036",Durham Lead
"143.56014428546104,  -38.03805238211623",Duverney
"147.23667381012976,  -36.87312490951934",Falls Creek (Vic.)
"145.71825968506386,  -37.0974775757032",Fawcett
"144.97916952150368,  -37.80084418331362",Fitzroy (Vic.)
"146.12124454337942,  -37.95954136037767",Fumina South
"142.80123369670116,  -38.29048678232407",Garvoc
"143.56471892817078,  -38.5347748745408",Gellibrand
"142.19809838315132,  -37.99192704327658",Gerrigerrup
"143.04093420370847,  -36.32165019491228",Gil Gil
"143.5570170640437,  -37.34813360996983",Glenbrae
"143.46782885086154,  -36.0541297391882",Glenloth East
"144.23224334521234,  -37.18294206813707",Glenluce
"147.27904966098097,  -38.2986151896435",Glomar Beach
"146.5705400414164,  -35.997093873008666",Gooramadda
"146.692909711234,  -38.30255898414476",Gormandale
"142.32848334423687,  -37.34626901578109",Grampians
"144.27616913109014,  -36.95106136517961",Harcourt North
"145.11826519911884,  -36.466574895642886",Harston
"145.71018833587272,  -38.24342147008488",Heath Hill
"145.04358688364727,  -37.73817343154033",Heidelberg West
"145.0811558180461,  -37.899533245047934",Hughesdale
"144.3130901635899,  -37.659535654941706",Ingliston
"144.9110252859061,  -37.687737963074056",Jacana
"144.28308868450944,  -36.71894088158303",Jackass Flat
"147.00916435792612,  -36.60166118138541",Kancoona
"143.80502596551884,  -38.63864321080026",Kennett River
"143.94550042142825,  -35.72458817041207",Kerang
"145.03414424113723,  -37.71614102372888",Kingsbury
"145.83676128034548,  -38.48395279929449",Korumburra South
"145.98490397651835,  -36.260968753956114",Lake Rowan
"143.06477172111698,  -37.00738556781557",Landsborough West
"144.4019167997695,  -38.004753030790376",Lara
"144.46886250741093,  -38.19282059699272",Leopold
"141.68362800950155,  -35.17366937939422",Linga
"144.49872993586632,  -36.27143503209426",Lockington
"146.1247652265143,  -36.58292883344394",Lurg
"146.07977055328436,  -37.057895056203584",Mansfield (Vic.)
"144.18278800470821,  -37.06910181414492",McKenzie Hill
"145.4011016628235,  -37.923199240838436",Menzies Creek
"145.6506918568639,  -37.7448184108011",Millgrove
"145.19907407860453,  -37.818101535879805",Mitcham (Vic.)
"145.54224880460993,  -36.658068597489866",Moglonemby
"144.42389997942269,  -38.18096098285508",Moolap
"143.64970161971038,  -37.0477033473684",Moonlight Flat (Central Goldfields - Vic.)
"143.7582401312176,  -37.20412715844126",Mount Glasgow
"143.84131384299985,  -37.50084719936713",Mount Rowan
"145.26999714236857,  -36.56580119613185",Murchison North
"145.58296986419245,  -36.06295039116993",Naring
"143.22221106356446,  -34.78123103460931",Narrung (Vic.)
"143.4563730976504,  -36.94539964164095",Natte Yallock
"146.004081716152,  -38.12734953798824",Nilma North
"145.176748349019,  -37.969535970565246",Noble Park
"147.24869065913848,  -36.200409456232386",Old Tallangatta
"148.51474796662103,  -37.65153023073727",Orbost
"144.468363559689,  -37.74287217152627",Parwan
"141.2709673687656,  -36.90761532989515",Patyah
"144.08209621272758,  -36.368890837864974",Pompapiel
"143.0790308655298,  -37.81997295849067",Pura Pura
"143.3064807274548,  -37.34120284249179",Raglan (Vic.)
"144.8293995269672,  -36.74439773059295",Redcastle
"145.9353907758483,  -38.06950688435913",Rokeby (Vic.)
"144.2330101002549,  -36.705614429091675",Sailors Gully
"146.99990034664373,  -37.06711118643356",Selwyn (Vic.)
"143.76750724243837,  -37.604276977407295",Smythes Creek
"142.0549582657529,  -38.249568121803705",St Helens (Vic.)
"142.77060468856325,  -37.057669240713",Stawell
"144.35577980025997,  -36.80423068709254",Strathfieldsaye
"145.8992650100756,  -38.341552082609866",Strzelecki (Vic.)
"144.83619448131338,  -37.763967766315396",Sunshine North
"145.86523289952186,  -36.53556478170734",Tarnook
"142.20331167534005,  -38.224161208534596",Tarrone
"146.17062054324978,  -38.303598094238865",Thorpdale
"145.3237405414348,  -36.49355622895836",Toolamba
"145.38356982966886,  -38.20108542370867",Tooradin
"146.5197480952066,  -38.20338449087272",Traralgon
"142.45221199911552,  -35.47086160262656",Turriff
"141.48021838975427,  -35.19005474261994",Tutye
"145.6264302660692,  -38.09523975241122",Tynong
"145.05465156155967,  -37.41965261787202",Upper Plenty
"143.8183085532236,  -36.82022415909214",Waanyarra
"142.08654404929666,  -36.530054744900525",Wail
"141.8338498056688,  -34.189162542722116",Wargan
"145.18828622681528,  -37.66727056156234",Wattle Glen
"143.6948311755577,  -38.09768082691516",Weering
"145.65305928477662,  -37.77341741286661",Wesburn
"146.63671601065403,  -36.545171450129445",Whorouly East
"145.23415503188883,  -38.480184238760756",Wimbledon Heights
"143.81309478466653,  -38.08752542956936",Wingeel
"141.38695701804372,  -37.90071976643888",Winnap
"143.98971674028334,  -36.82189452228945",Woodstock West
"142.63138730072745,  -35.680725202245064",Woomelang
"143.23230865183237,  -36.05654824046182",Wycheproof
"143.13153400689552,  -37.52620639095166",Yalla-Y-Poora
"145.09341221745655,  -37.72503762181245",Yallambie
"146.07293260217511,  -38.24984584493092",Yarragon South
"152.67538181773705,  -26.346817932860123",Amamoor
"144.23854217600632,  -17.72027236048901",Amber
"148.4141695146173,  -20.592159670012904",Andromache
"153.03247204555808,  -27.51222275847528",Annerley
"149.14563914788476,  -21.29902084868201",Balberra
"153.08210905169605,  -27.37851546116622",Banyo
"152.11983793625214,  -26.321727525397982",Barambah
"153.0760244242281,  -26.807865211674986",Baringa
"150.69576932691228,  -23.137079779781857",Barmaryee
"152.7704581079902,  -27.601099398389717",Basin Pocket
"152.72022380025294,  -26.85168922311226",Bellthorpe
"146.19239635268917,  -18.559271475263202",Bemerside
"152.71439500748227,  -25.607777506178245",Bidwill (Qld)
"153.50712570243172,  -28.1605552569716",Bilinga
"149.0466631901531,  -23.496470555840745",Bluff
"145.3934936354479,  -16.462850064406375",Bonnie Doon (Qld)
"146.00899475764393,  -17.358854657308733",Bramston Beach
"148.89496838621076,  -21.267269954456232",Brightly
"151.16737904332092,  -24.816632559478684",Bukali
"142.855206084307,  -28.59697244300568",Bulloo Downs
"152.59543939280408,  -28.265536760095614",Burnett Creek
"152.94870567071715,  -27.093947034065383",Caboolture South
"150.5007455906119,  -24.28082702213599",Callide
"138.5107982374805,  -19.61073999600066",Camooweal
"153.09905809944644,  -27.48767362637792",Carina (Qld)
"152.9486714497985,  -27.498817288061307",Chapel Hill (Qld)
"152.05966792944426,  -28.43381881946017",Cherry Gully
"152.3698897350165,  -27.425863502299983",Churchable
"152.5308627588962,  -27.430998367474075",Clarendon (Qld)
"151.86273184380693,  -27.12502387221621",Coalbank
"150.62194892651928,  -23.14684516778171",Cobraball
"152.85529304872225,  -27.622270722010626",Collingwood Park (Qld)
"152.99789095517562,  -25.985005351619797",Cooloola Cove
"145.86327933122917,  -27.686674841195796",Coongoola
"153.0367265481679,  -27.566280786038586",Coopers Plains
"152.81272052064918,  -26.340318004459164",Cooran
"151.32585624024742,  -26.804625788819376",Cooranga
"152.47976876850424,  -24.88399504149265",Coral Cove
"142.25075076212136,  -18.20759239597557",Croydon (Qld)
"152.67023502096143,  -26.3173021378028",Dagun
"150.50544398955293,  -24.342032160308566",Dakenba
"146.10417821186994,  -18.375886721101743",Damper Creek
"153.06779033111596,  -28.23227637996218",Darlington (Qld)
"148.76553963988428,  -21.099539114090636",Dows Creek
"151.9010614966959,  -27.60014285221062",Drayton
"141.77431929364764,  -27.28110166161086",Durham
"145.7314677555246,  -16.947124728788395",Earlville
"152.2919013691661,  -25.1124486455066",Farnsfield
"152.92398502164042,  -27.391141766826667",Ferny Hills
"153.33457661217034,  -27.95653507266474",Gaven
"146.00258779186805,  -17.5048414612321",Goondi
"148.49313090866775,  -20.51731117443661",Goorganga Creek
"153.01539734843786,  -27.422555078689115",Grange (Qld)
"139.38859808586213,  -18.523141479206746",Gregory (Qld)
"151.87916514500455,  -27.216734056718597",Haden
"146.80365854778842,  -19.282965229944534",Hermit Park
"153.0703919361628,  -27.51849172223655",Holland Park
"153.39583144359796,  -27.89611243291761",Hollywell
"145.1743315722823,  -15.164009091477135",Hope Vale
"151.60830818642674,  -25.569792083975983",Ideraway
"150.03733326363087,  -25.1444187899851",Isla
"149.6553126626646,  -26.44361953759275",Jackson North
"149.56334174106928,  -26.727810799234128",Jackson South
"152.65594052797275,  -27.650899631046403",Jeebropilly
"152.90933512803028,  -28.031429986593107",Josephville
"152.8190251429092,  -27.542193589887937",Karana Downs
"151.94253273731366,  -27.604221514430222",Kearneys Spring
"148.33745951558245,  -21.624948350623534",Kemmis
"152.79735914257384,  -27.263993999167567",Kobble Creek
"151.68289404151696,  -24.781773001786377",Kolonga
"150.78642278257695,  -26.122000110256447",Kragra
"153.39790623452726,  -27.946204799213337",Labrador
"152.1109127148912,  -25.558394107218316",Lakeside
"152.98600038875387,  -27.281930440729266",Lawnton
"152.65935043985613,  -26.265014415462883",Long Flat (Qld)
"152.48060220782315,  -27.07926538435036",Lower Cressbrook
"152.5730725539761,  -27.47141600073167",Lowood
"149.18216169380239,  -21.143174366839506",Mackay
"149.21417053407936,  -21.11209277823124",Mackay Harbour
"153.04521983395264,  -27.241450452739624",Mango Hill
"147.02307803640244,  -24.52254705418031",Mantuan Downs
"152.7103551891329,  -28.030671912125463",Milford
"142.07486908293276,  -12.692155086059271",Mission River
"153.29488731546493,  -27.487967793591935",Moreton Bay
"151.77241097560793,  -27.199271156745255",Mount Darry
"152.60985535920494,  -27.73036706518455",Mount Forbes
"149.5238258668499,  -22.969406254681978",Mount Gardiner
"153.07148356888445,  -27.540058310493034",Mount Gravatt
"152.0415984143578,  -27.389826551290426",Mount Luke
"151.9464607324399,  -28.74262290135015",Mount Tully
"146.0524055980233,  -17.578556728763093",Mourilyan
"145.48148850593637,  -16.599214271798296",Mowbray (Qld)
"152.74308665317474,  -26.522597407015283",Moy Pocket
"145.8983312866745,  -18.04949588167995",Murrigal
"147.5366764373409,  -24.331056019585684",Nandowrie
"153.22284400611977,  -28.221678863031613",Natural Bridge
"151.80934432179214,  -25.070878032450928",Nearum
"150.35663864644607,  -23.411060445445425",Nine Mile (Qld)
"146.5396310760912,  -25.442549760583056",Nive
"152.95595688870029,  -26.693465173200398",Palmwoods (Qld)
"146.0062190965452,  -18.691480777452792",Peacock Siding
"152.9723017561008,  -27.26495792704552",Petrie
"146.84041364100838,  -19.176143314300642",Picnic Bay
"152.8616363162969,  -26.317399277399584",Pinbarren
"151.64290231158336,  -27.736607259562295",Pittsworth
"138.33706891555624,  -21.478435646921287",Piturie
"152.80348582154028,  -28.19445211979824",Rathdowney
"150.5452212334255,  -23.21382251270211",Sandringham (Qld)
"149.28445514295808,  -21.3832917292043",Sarina Beach
"148.73260865748455,  -21.286289850554514",Septimus
"146.6135587566583,  -20.597319100762082",Seventy Mile
"150.03004176582655,  -22.343333937295228",Stanage
"144.716494196382,  -14.7686868657568",Starcke
"146.022263568758,  -17.576769624680725",Stockton (Qld)
"142.73317403438944,  -17.395013759065694",Strathmore (Qld)
"152.99055660205363,  -27.301089539713484",Strathpine
"151.94060773064163,  -26.16273419647187",Tablelands (South Burnett - Qld)
"152.23999491641223,  -28.30132453589524",Tannymorel
"151.09498731415232,  -23.731311325961464",Targinnie
"152.44043618137368,  -28.002547833512317",Tarome
"152.8518115939433,  -25.535340184126877",The Dimonds
"151.95069385960736,  -27.55978939332883",Toowoomba City
"152.21523499944297,  -28.12518460293494",Upper Freestone
"152.4934912686725,  -26.298150304015085",Upper Glastonbury
"152.710267001693,  -26.13359300511894",Veteran
"151.4562073826535,  -27.328918161574734",Wainui
"152.6554679372997,  -28.066826480676593",Wallaces Creek
"150.38328428320796,  -23.627648755986822",Walterhall
"140.92520629650332,  -22.457755657917833",Warburton (Qld)
"148.92739900462098,  -27.40541903624685",Wellesley (Qld)
"148.86964512049772,  -27.203249797690496",Weribone
"153.17311285641114,  -27.78111298768285",Wolffdene
"152.0925324670327,  -25.466875547715915",Woowoonga
"152.34216990072235,  -26.306798015812586",Wrattens Forest
"150.73640259593458,  -23.129553094058217",Yeppoon
"153.03897623837275,  -27.358327289443675",Zillmere
"138.56073677752084,  -34.85595221634107",Angle Park
"139.3116708592791,  -35.09101782481254",Avoca Dell
"139.81253828890988,  -34.67834725418204",Bakara
"138.42787108254478,  -34.14447526902562",Balaklava
"138.1273526093627,  -33.82566346217506",Barunga Gap
"135.82691031018675,  -34.68681810971018",Boston
"140.48886861966452,  -36.187562231200864",Brimbago
"138.76220626243528,  -35.23522343669727",Bull Creek (SA)
"139.04630178561752,  -35.114553988200754",Callington
"137.3032205083542,  -35.68027529033704",Cassini
"138.99566768885302,  -32.66934470395053",Cavenagh
"137.48513764205404,  -34.38788884925327",Chinaman Wells
"137.18917586261287,  -32.87048939826797",Cultana
"138.60832576881543,  -34.72219659754079",Direk
"138.57468686923724,  -34.95419928065709",Everard Park
"136.70135466261738,  -35.88012343705163",Flinders Chase
"140.4864994057147,  -30.414668136192937",Frome Downs
"140.4967841481949,  -37.8285807714658",German Creek
"135.78696941748203,  -34.68158716085271",Hawson
"140.0249881352191,  -34.17292806141472",Holder (SA)
"138.4614711485569,  -34.20512423348584",Hoskin Corner
"138.75519588948313,  -34.83070776834999",Houghton
"137.15454496043276,  -32.73968523747528",Iron Knob
"133.30517806265019,  -26.97000336059759",Iwantja
"136.91148075228446,  -35.960345008291625",Karatta
"138.90400515324527,  -34.852034618547584",Kenton Valley
"134.47839655530302,  -29.15636945006986",Mabel Creek
"133.62243360672997,  -27.299730163851482",Marla
"138.52968051010902,  -33.664103826348395",Marola
"139.69265421365455,  -34.2120439543195",Murbko
"139.26407701060697,  -35.131118173018926",Murray Bridge
"139.27661277146908,  -35.166286020119124",Murray Bridge South
"135.44471913618642,  -27.563880413786965",Oodnadatta
"138.5357744640658,  -34.8475897084242",Ottoway
"138.64437614358502,  -34.803510311530616",Para Hills West
"138.60822300218018,  -34.75705694114846",Paralowie
"136.794198162433,  -31.257170113896105",Pimba
"137.46368521537573,  -34.448626106619706",Point Pearce
"138.98601156853655,  -35.14931413571378",Red Creek
"138.94484940274427,  -34.58118029105831",Rowland Flat
"138.5210770503939,  -35.032618491269545",Seacliff
"138.67692603954848,  -34.67686035152629",Smithfield Plains
"138.56013176934422,  -34.97374003642648",South Plympton
"140.16178906279572,  -37.557942878143606",Southend
"138.3859584814649,  -35.52205411775269",Torrens Vale
"138.64452135802802,  -34.911232561557746",Trinity Gardens
"138.60645756693708,  -34.94841077668141",Unley
"138.60137655938888,  -34.96258284451497",Unley Park
"138.76082184689176,  -34.79396617904514",Upper Hermitage
"138.63855188126482,  -34.96810968166222",Urrbrae
"135.53301426401615,  -34.54787607841278",Wangary
"135.95819192877315,  -34.49379127108214",Whites River
"140.54435743284853,  -34.32072734153911",Winkie
"139.10004589066907,  -33.801571494436224",Worlds End (SA)
"115.86266343594158,  -32.167132249006514",Aubin Grove
"124.1883926183493,  -32.252058455311925",Balladonia
"115.88286996324152,  -32.157517048718354",Banjup
"115.89150882745626,  -31.909602148537818",Bedford
"116.29705463071419,  -31.299743131223774",Bindoon Training Area
"116.87836801898334,  -33.44922872285706",Bokal
"115.87664181034206,  -31.1998480152181",Boonanarring
"115.82284581246402,  -33.75787544936223",Brazier
"115.96027796890785,  -31.437781969227274",Breera
"116.85677785925185,  -20.72584986855673",Bulgarra
"118.43873857586871,  -31.399934649957068",Burracoppin
"115.64915996307833,  -33.63224711214466",Capel River
"116.46915729792883,  -30.99752585876283",Carani
"116.86836338521633,  -33.73591059474718",Changerup
"117.30613908148847,  -32.764265734130596",Commodine
"121.15346519199583,  -30.948259035752603",Coolgardie (WA)
"115.54865398738332,  -34.121809873296336",Darradup
"126.70484380094291,  -14.874713463405891",Drysdale River
"117.07338628131816,  -32.03520545414229",East Beverley
"115.63343419762785,  -33.538224720523324",Elgin (WA)
"117.72414074394689,  -35.02224642259668",Elleker
"115.05891065819837,  -28.67434270268963",Eradu
"117.4489207919323,  -32.669325364343926",Gillimanning
"114.2512907205347,  -28.188652211569373",Gregory (WA)
"115.69619257580055,  -32.543253803427206",Halls Head
"116.00531066657116,  -31.91230756497437",Hazelmere
"115.76020079584957,  -31.763185658919323",Heathridge
"115.98970607522338,  -31.80954415578235",Henley Brook
"117.20565055163733,  -31.274427976724425",Hindmarsh (WA)
"115.88767308891323,  -32.381558646831195",Hopeland (WA)
"118.47350982802499,  -20.890867444815623",Indee
"119.02483723995375,  -33.971451762418376",Jerramungup
"117.19178141716495,  -33.57318442960795",Kenmare (WA)
"115.95176644871049,  -31.978814044318295",Kewdale
"116.77120135055213,  -31.04761846986492",Konnongorring
"119.56285527320301,  -30.821953259077436",Koolyanobbing
"115.66325079151338,  -34.39310545488022",Lake Jasper
"121.27590912985062,  -31.222834845012656",Londonderry (WA)
"115.75264019165118,  -32.469487348975214",Madora Bay
"116.01125712970716,  -32.67243551840522",Marrinup
"119.41843956994639,  -31.534424994529836",Marvel Loch
"115.20045209267339,  -33.67116844708498",Marybrook
"116.48471975457839,  -33.955014169194584",Mayanup
"115.75352541010894,  -32.49658400081434",Meadow Springs
"118.99334638795551,  -31.74297550164731",Mount Hampton
"115.84892466966126,  -32.02788331627138",Mount Pleasant (WA)
"115.92794583996367,  -31.557455588236266",Muchea
"116.87746711653737,  -20.739456434128517",Mulataga
"116.71043167098321,  -31.676469185463404",Muluckine
"116.16346526989344,  -31.92331531646566",Mundaring
"116.05523343778565,  -32.48590508125807",Myara
"118.11076763724056,  -34.98462349930503",Nanarup
"125.04921313724346,  -28.010264982148556",Neale
"115.68699079556781,  -33.42761268340211",North Boyanup
"117.69993240431565,  -31.467329259950244",North Kellerberrin
"114.60890703428036,  -28.59791293289987",Oakajee
"128.15531857779968,  -17.762475797299803",Ord River
"114.81001855858558,  -29.008003019435538",South Greenough
"115.97538771369298,  -31.911764246732584",South Guildford
"115.11578966680237,  -26.441071524034232",Talisker
"115.79558403841718,  -31.719427780597016",Tapping
"116.00478329705292,  -31.758841751561295",The Vines
"115.03336777074597,  -26.950992089360277",Toolonga
"117.64835696378076,  -34.982205699138",Torbay
"115.89346369345137,  -31.97542484110247",Victoria Park
"116.21906742685206,  -30.685501882138908",Walebing
"114.6345893156001,  -28.81924978143046",Wandina
"128.21393985476794,  -15.607927079768086",Wyndham (WA)
"117.01177591404684,  -30.232483116591567",Xantippe
"117.88435171454422,  -35.00063241358894",Yakamia
"147.70616396756486,  -41.79431804662391",Avoca (Tas.)
"147.15416292014882,  -41.21388786579676",Bangor (Tas.)
"146.99498828964389,  -41.61682665168433",Bishopsbourne
"147.3807880994132,  -41.80584638955222",Cleveland (Tas.)
"146.52440516663734,  -41.58582782240836",Dairy Plains
"146.30456227964686,  -41.1820287820252",Don
"147.31683581475252,  -41.76264836205018",Epping Forest
"147.93382586252034,  -41.66133107261492",Fingal (Tas.)
"144.06925424853213,  -40.0439461796436",Grassy
"147.05994501001905,  -41.5111701216736",Hadspen
"146.93955705300706,  -43.4510793200809",Ida Bay
"147.13316552975832,  -41.42071256826085",Invermay (Tas.)
"147.81766000065576,  -43.07955651835721",Koonya
"148.20286398114075,  -40.10143538836283",Lackrana
"147.84828105518528,  -42.02160027777172",Lake Leake
"145.92588228098325,  -41.40218252078425",Loongana
"146.83889422840005,  -41.05723855618884",Low Head
"147.11787835037183,  -42.78764412873547",Malbina
"147.86015711789133,  -42.8283226708119",Marion Bay (Tas.)
"147.2319284258933,  -43.22864520021341",Middleton (Tas.)
"147.09802665639958,  -41.970397418982444",Millers Bluff
"146.0896602583437,  -41.507716742707025",Moina
"145.89633795423856,  -41.05762199875791",Montello
"146.06082105573142,  -41.38284334413281",Nietta
"147.95961289161704,  -41.099915103395354",Pioneer (Tas.)
"147.1573644582937,  -41.38081437163924",Rocherlea
"145.95954980456722,  -41.256128898589814",South Riana
"146.66375384467466,  -42.3556121704048",Strickland
"147.39600233310128,  -41.31554487948579",Targa
"147.52457635707776,  -42.257796643480674",York Plains (Tas.)
"133.81468448856552,  -23.784805691026303",Arumbera
"131.00696627209095,  -12.718831254203915",Berry Springs
"130.93202504113856,  -13.173491142079284",Camp Creek (NT)
"130.88069289422953,  -12.412385155986591",Eaton (NT)
"134.456435948902,  -22.803008499267055",Engawala
"131.08882168887183,  -18.228600085566722",Gurindji
"130.8486021104672,  -12.383629289531399",Nightcliff
"132.70090572077245,  -18.616625275109314",Tanami East
"130.97006422226943,  -25.20689192214576",Yulara
"149.02907819396805,  -35.23395816332279",ACT Remainder - Belconnen
"148.9083715979342,  -35.286320593020186",ACT Remainder - Coree
"148.83658176918152,  -35.46437157568645",ACT Remainder - Cotter River
"148.99035010724552,  -35.31406619885004",ACT Remainder - Stromlo
"149.2105302094079,  -35.341729402139414",Beard
"149.09155194266856,  -35.24548030806253",Bruce (ACT)
"149.12491232833983,  -35.42196479042136",Chisholm (ACT)
"149.12913206614766,  -35.281329376797565",City
"149.11555601657275,  -35.256640856045756","[""O'Connor (ACT)""]"`